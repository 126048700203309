import langbilling from '@/apps/billing/lang/';
export default {
	langbilling: langbilling, 
	billing: false,
	token: localStorage.getItem('access_token') || null,
	dashboard : true,
	vendorSelect: null,
	vendorOP: null,
	setupBilling:[],
	responseMail:[],
	selectBank: {},
	filterBank: {},
	textfilter: '',
	delItemSelected: [],
	templateBanking: null,
	assignExpense: {},
	customerRefresh: false,
	customerSearch: '',
	customerSearchActive: false,
	customerID: 0,
	formCustomer: false,
	showAddress2: false,
	customerByID: {},
	customerData: [],
	showSetting: {
		show_customer: true,
		show_business: true
	},
	customerCountTotal: null,
	AccountList: [],
	showSMS: 1,
	templateContract: {
		id: 0,
		action: '',
		title: '',
		alias: '',
		btnName: '',
		description: '',
	},
	asideMenu: null,
};
