<template>
	<div class="card">
		<div class="card-header text-center">
		    <h3>Crearte your website</h3>
		</div>
		<div class="card-body">
			<form class="crearteWebsite" @submit.prevent="createSite()">
				<label >Name your website <small>(Internal Use)</small>:</label>
				<div class="input-group mb-2 mr-sm-2">
					<input type="text" class="form-control" v-model="nameweb" placeholder="Enter website name" maxlength="100" minlength="3" required>
				</div>
				<label>Website Link:</label>
				<div class="input-group">
					<div class="input-group-prepend">
						<div class="input-group-text">https://marketeasites.com/w/</div>
					</div>
					<input type="text" class="form-control linkweb" v-model="linkweb" placeholder="my-link" maxlength="100" minlength="3" required @input="validUrl">
				</div>
				<div class="alert alert-warning" v-if="validLink">
					<button type="button" class="close" @click.prevent="validLink=false">
						<span aria-hidden="true">&times;</span>
					</button>
					Link is not available :(
				</div>
				<div class="alert alert-success" v-if="availableLink">
					<button type="button" class="close" @click.prevent="availableLink=false">
						<span aria-hidden="true">&times;</span>
					</button>
					Link is available!
				</div>
				<div class="alert alert-info" v-if="searchingLink">
					<b>Search:</b> Check availability...
				</div>
				<button type="submit" class="btn btn-primary btn-md mt-3 mb-2" :disabled="btnSubmit">Choose Theme</button>
				<button type="button" class="btn btn- btn-md mt-3 mb-2 ml-2" v-if="$parent.listWebs.length>0" @click="cancelCreate">Cancel</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: 'formCreateSite',
	data () {
		return {
			nameweb: '',
			linkweb: '',
			btnSubmit: false,
			validLink: false,
			availableLink: false,
			searchingLink: false,
		}
	},
    mounted() {
        var t = this;
    },
	methods: {
		cancelCreate(){
      		let t = this;
      		t.$store.commit('ModApps/Sites/set_addWeb', false);
		},
		validUrl(e){
			let t = this;
        	let str = t.linkweb;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();

            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
            t.linkweb=str;
            let timeout;
            if (t.linkweb.length>=3) {
            	t.btnSubmit = true;
            	t.searchingLink = true;
            	clearTimeout(timeout)
            	timeout = setTimeout(() => {
            		console.log('has dejado de escribir')
            		t.searchLink(timeout);
            	}, 1000)
            }
		},
		searchLink(timeout){
			let t = this;
			window.master
            .post('sites/getUrl', {
                user_id: window.master_client,
                org_id: window.localorgdata,
                url: t.linkweb
            })
            .then((response) => {
            	console.log('getUrl', response.data)
                if(response.data.r.length==0){
					t.availableLink = true;
                	t.btnSubmit = false;
                }else{
					t.validLink = true;
                }
                clearTimeout(timeout)
            })
            .catch((error) => {
                console.log(error);
				t.validLink = true;
				clearTimeout(timeout)
            });
		},
		createSite(){
			let t = this;
			if(t.nameweb.length<3 || t.linkweb.length<3){
				alert('Name and Link must have more than three characters');
			}
			else{
				t.$store.commit('ModApps/Sites/set_loadSites', true);			
				window.master
	            .post('sites/addWebsite', {
	                user_id: window.master_client,
	                org_id: window.localorgdata,
	                nameweb: t.nameweb,
	                linkweb: t.linkweb
	            })
	            .then((response) => {
	            	console.log('getUrl', response.data)
	                if(response.data.status){
						t.availableLink = false;
						t.searchingLink = false;
						t.validLink = false;
	                	t.btnSubmit = false;
	                	if(response.data.get.length>0){
	                		t.$store.commit('ModApps/Sites/set_webs', response.data.get);
	                		t.$router.push({ name: "themeSelect", params: { webid: response.data.webid } });
	                	}
	                }
	                t.$store.commit('ModApps/Sites/set_loadSites', false);
	            })
	            .catch((error) => {
	                console.log(error);
	                t.$store.commit('ModApps/Sites/set_loadSites', false);
	            });
	        }
		},
	},
	watch:{
		searchingLink: function(){
			if(this.searchingLink){
				this.availableLink=false;
				this.validLink=false;
			}
		},
		validLink: function(){
			if(this.validLink){
				this.availableLink=false;
				this.searchingLink=false;
			}
		},
		availableLink: function(){
			if(this.availableLink){
				this.validLink=false;
				this.searchingLink=false;
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.card{
	.card-header{
		box-shadow: -1px -1px 10px rgb(0,0,0,.2);
		h3{
			text-transform: uppercase;
			margin-bottom: 0px;
		}
	}
}
form.crearteWebsite{
	label{
		font-size: 16px;
		font-weight: bold;
	}
	.input-group{
		border: 1px solid rgb(0,0,0,.2);
		.input-group-prepend{
			.input-group-text{
				background: rgb(0,0,0,.04);
				padding-right: 5px;
			}
		}
		input.form-control.linkweb{
			border-left: 0px;
			padding-left: 5px;
			&:focus{
				border: 0px solid rgb(0,0,0,.2);
				border-left: 0px;
			}
		}
		&:hover{
			border: 1px solid rgb(0,0,0,.2);
		}
		&:focus{
			border: 1px solid rgb(0,0,0,.2);
		}
	}
	div.alert{
		padding: 5px 10px;
		font-weight: normal;
	}
}
</style>
