let updateEditNotes = function() {
    for (var k in this.listTaks) {
        if (this.listTaks.hasOwnProperty(k)) {
            if (this.listTaks[k].id_i == this.accitonPusher.item.job) {
                this.listTaks[k].notes = this.accitonPusher.item.notes;
                this.listTaks[k].link_drive = this.accitonPusher.item.link_drive;
            }
        }
    }
    this.reEscribirLista();
};
export default updateEditNotes;
