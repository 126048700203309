let desvanecerTR = function() {
	let t =this;
	for (let i in t.selected) {
		if(t.selected[i]) {
			for (let j in t.dataT) {
				if(t.dataT[j] && t.dataT[j].id == t.selected[i]) {
					t.dataT[j].del = true;
					let trDataID = '.table-body table tbody tr[data-id="'+t.dataT[j].id+'"]';
					let tr = document.querySelector(trDataID);	
					if(tr)
					{
						tr.classList.add('del-item');
					}										
				}
			}
		}
	}
};
export default desvanecerTR;
