<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div
                        class="page-breadcrumb d-flex justify-content-between"
                        style="margin-bottom: 10px"
                    >
                        <h2 class="page-title"><i class="fas fa-tasks"></i> Leads</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card" style="margin-bottom: 78px">
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Date time</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Source</th>
                                        <th
                                            scope="col"
                                            style="width: 100px; text-align: center"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            style="width: 90px; text-align: center"
                                        >
                                            View
                                        </th>
                                        <th scope="col" style="width: 100px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(i, index) in leadsList" :key="i.id">
                                        <th>{{ i.date }}<br />{{ i.hour }}</th>
                                        <td>
                                            <div
                                                v-if="
                                                    i.material_image != null &&
                                                    i.material_image != ''
                                                "
                                                style="
                                                    border: 1px solid #ccc;
                                                    padding: 4px;
                                                    width: 50px;
                                                    border-radius: 5px;
                                                "
                                            >
                                                <img
                                                    :src="urlbase + i.material_image"
                                                    style="
                                                        width: 100%;
                                                        height: 40px;
                                                        object-fit: cover;
                                                        cursor: pointer;
                                                    "
                                                    @click="DetailMaterial(i)"
                                                />
                                            </div>
                                            <div
                                                style="
                                                    border: 1px solid #ccc;
                                                    padding: 4px;
                                                    width: 50px;
                                                    height: 42px;
                                                    border-radius: 5px;
                                                "
                                                v-else
                                            >
                                                <i
                                                    class="fas fa-4x fa-image"
                                                    style="
                                                        width: 100%;
                                                        height: 40px;
                                                        font-size: 40px;
                                                        line-height: 30px;
                                                    "
                                                ></i>
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                @click="DetailMaterial(i)"
                                                style="color: #2196f3; cursor: pointer"
                                                >{{ i.material_name }}</span
                                            >
                                        </td>
                                        <td>{{ i.customer_name }}</td>
                                        <th></th>
                                        <td>
                                            <div class="items_order">
                                                <!-- <span class="badge badge-success">en Proceso</span> -->
                                                <div
                                                    class="action_order_btn"
                                                    style="width: 100px"
                                                >
                                                    <span
                                                        @click="activeStatus(index)"
                                                        style="cursor: pointer"
                                                    >
                                                        <span
                                                            v-if="i.status == 0"
                                                            class="badge badge-pill badge-warning"
                                                            >Pending
                                                            <i
                                                                class="fas fa-angle-down"
                                                            ></i
                                                        ></span>
                                                        <span
                                                            v-else-if="i.status == 1"
                                                            class="badge badge-pill badge-primary"
                                                            >Estimate Send
                                                            <i
                                                                class="fas fa-angle-down"
                                                            ></i
                                                        ></span>
                                                        <span
                                                            v-else-if="i.status == 2"
                                                            class="badge badge-pill badge-success"
                                                            >Acepted
                                                            <i
                                                                class="fas fa-angle-down"
                                                            ></i
                                                        ></span>
                                                        <span
                                                            v-else-if="i.status == 3"
                                                            class="badge badge-pill badge-danger"
                                                            >Rejected
                                                            <i
                                                                class="fas fa-angle-down"
                                                            ></i
                                                        ></span>
                                                    </span>
                                                    <span
                                                        class="status_list"
                                                        v-if="statusActive == index"
                                                    >
                                                        <ul
                                                            class="reset"
                                                            style="padding-left: 0"
                                                        >
                                                            <li
                                                                @click="
                                                                    statusChange(0, i.id)
                                                                "
                                                            >
                                                                <span
                                                                    class="badge badge-pill badge-warning"
                                                                    >Pending</span
                                                                >
                                                            </li>
                                                            <li
                                                                @click="
                                                                    statusChange(1, i.id)
                                                                "
                                                            >
                                                                <span
                                                                    class="badge badge-pill badge-primary"
                                                                    >Estimate Send</span
                                                                >
                                                            </li>
                                                            <li
                                                                @click="
                                                                    statusChange(2, i.id)
                                                                "
                                                            >
                                                                <span
                                                                    class="badge badge-pill badge-success"
                                                                    >Acepted</span
                                                                >
                                                            </li>
                                                            <li
                                                                @click="
                                                                    statusChange(3, i.id)
                                                                "
                                                            >
                                                                <span
                                                                    class="badge badge-pill badge-danger"
                                                                    >Rejected</span
                                                                >
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <button
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                                @click="MessagePreview(i)"
                                            >
                                                <i class="fas fa-eye"></i> View
                                            </button>
                                        </td>
                                        <td>
                                            <span class="cdropdown">
                                                <button>Actions</button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li @click="pdfView(i.id)">
                                                            <button>PDF</button>
                                                        </li>
                                                        <li @click="ConvertCustomer(i)">
                                                            <button>
                                                                Convert to Customer
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal
            name="modal-message"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">Message</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="CloseModal"
                ></i>
            </div>
            <div class="card-body">
                <div>
                    <b>Name:</b><span> {{ message.name }}</span>
                </div>
                <div>
                    <b>Last Name:</b><span> {{ message.last_name }}</span>
                </div>
                <div>
                    <b>Address:</b><span> {{ message.address }}</span>
                </div>
                <div>
                    <b>Phone:</b><span> {{ message.phone }}</span>
                </div>
                <div>
                    <b>Email:</b><span> {{ message.email }}</span>
                </div>
                <div>
                    <b>City:</b><span> {{ message.city }}</span>
                </div>
                <div>
                    <b>State:</b><span> {{ message.state }}</span>
                </div>
                <div>
                    <b>Zip:</b><span> {{ message.zip }}</span>
                </div>
                <div>
                    <b>Project details:</b><span> {{ message.comments }}</span>
                </div>
            </div>
        </modal>
        <modal
            name="modal-detail"
            width="800px"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">{{ cover_name }}</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="CloseModal2"
                ></i>
            </div>
            <div class="card-body">
                <div class="infoModal">
                    <div class="image">
                        <img :src="urlbase + material_image" />
                    </div>
                    <div class="info">
                        <span><b>Manufacturer:</b> {{ manufacturer }}</span>
                        <span><b>Orginal Name:</b> {{ material_name }}</span>
                        <span><b>Manufacturer ID #:</b> {{ manufacturer_number }}</span>
                        <nav style="margin-top: 20px">
                            <div class="nav nav-tabs" id="nav-tab1" role="tablist">
                                <a
                                    :class="[
                                        'nav-item nav-link',
                                        tabActive == 1 ? 'active' : '',
                                    ]"
                                    @click="tabActiveMethod(1)"
                                    id="nav-s-tab"
                                    data-toggle="tab"
                                    href="javascript:"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                    >Small Description</a
                                >
                                <a
                                    :class="[
                                        'nav-item nav-link',
                                        tabActive == 2 ? 'active' : '',
                                    ]"
                                    @click="tabActiveMethod(2)"
                                    id="nav-d-tab"
                                    data-toggle="tab"
                                    href="javascript:"
                                    role="tab"
                                    aria-controls="nav-contact"
                                    aria-selected="false"
                                    >Full Description
                                </a>
                                <a
                                    :class="[
                                        'nav-item nav-link',
                                        tabActive == 3 ? 'active' : '',
                                    ]"
                                    @click="tabActiveMethod(3)"
                                    id="nav-sp-tab"
                                    data-toggle="tab"
                                    href="javascript:"
                                    role="tab"
                                    aria-controls="nav-contact"
                                    aria-selected="false"
                                    >Specifications
                                </a>
                                <a
                                    :class="[
                                        'nav-item nav-link',
                                        tabActive == 4 ? 'active' : '',
                                    ]"
                                    @click="tabActiveMethod(4)"
                                    id="nav-ap-tab"
                                    data-toggle="tab"
                                    href="javascript:"
                                    role="tab"
                                    aria-controls="nav-contact"
                                    aria-selected="false"
                                    >Applications
                                </a>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div
                                :class="[
                                    'tab-pane fade',
                                    tabActive == 1 ? 'show active' : '',
                                ]"
                                id="nav-small"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 col-xl-12">
                                        <div class="form-group">
                                            <span>{{ small_description }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'tab-pane fade',
                                    tabActive == 2 ? 'show active' : '',
                                ]"
                                id="nav-desc"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 col-xl-12">
                                        <div class="form-group">
                                            <span v-html="full_description">{{
                                                full_description
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'tab-pane fade',
                                    tabActive == 3 ? 'show active' : '',
                                ]"
                                id="nav-spe"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label class=""><b>Primary Color:</b></label>
                                            {{ colors }}
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label class=""
                                                ><b>Country of origin:</b></label
                                            >
                                            {{ country }}
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label class=""><b>Pattern:</b></label>
                                            {{ pattern }}
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label class=""><b>Material Type:</b></label>
                                            {{ category }}
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label class=""
                                                ><b>Available Finish:</b></label
                                            >
                                            {{ available_finish }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'tab-pane fade',
                                    tabActive == 4 ? 'show active' : '',
                                ]"
                                id="nav-app"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 col-xl-12">
                                        <div class="form-group">
                                            <span v-html="applications">{{
                                                applications
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <v-dialog />
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
export default {
    name: "SlabsLeads",
    components: {},
    data() {
        return {
            isLoading: false,
            leadsList: [],
            urlImg: null,
            urlImg2: null,
            urlbase: null,
            statusActive: null,
            message: {
                name: "",
                last_name: "",
                address: "",
                phone: "",
                email: "",
                comments: "",
                city: "",
                state: "",
                zip: "",
            },
            cover_name: "",
            material_image: "",
            material_name: "",
            manufacturer: "",
            manufacturer_number: "",
            small_description: "",
            full_description: "",
            applications: "",
            colors: "",
            available_finish: "",
            category: "",
            pattern: "",
            country: "",
            tabActive: 1,
            countriesList: [],
        };
    },
    created() {
        this.getCountries();
        this.getLeads();
    },
    methods: {
        getLeads() {
            this.isLoading = true;
            window.SlabsV2.get("leads")
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    this.urlbase = response.data.urlbase;
                    this.urlImg = response.data.url;
                    this.urlImg2 = response.data.url2;
                    this.leadsList = response.data.result;
                })
                .catch((error) => console.log(error));
        },
        getCountries() {
            this.isLoading = true;
            window.master
                .get("paises")
                .then((response) => {
                    this.isLoading = false;
                    this.countriesList = response.data.paises;
                })
                .catch((error) => console.log(error));
        },
        ConvertCustomer(data) {
            this.$modal.show("dialog", {
                title: '<div style="text-align:center">Convert to Customer!</div>',
                text: '<div style="text-align:center">Yes! Convert now</div>',
                buttons: [
                    {
                        title: "Confirm",
                        default: true,
                        handler: () => {
                            this.ProcessConvertCustomer(data);
                        },
                    },
                    {
                        title: "Cancel",
                    },
                ],
            });
        },
        tabActiveMethod(index) {
            this.tabActive = index;
        },
        ProcessConvertCustomer(data) {
            let customer_name = data.name;
            let lastname = data.last_name;
            window.billing
                .post("customer/add", {
                    user_id: window.localuserdata,
                    firstname: customer_name,
                    lastname: lastname,
                    active: 1,
                })
                .then((response) => {
                    this.$modal.hide("dialog");
                    this.$notify({
                        group: "noti",
                        title: "Success",
                        type: "success",
                        text: "New customer added",
                    });
                })
                .catch((error) => console.log(error, (this.isLoading = false)));
        },
        MessagePreview(data) {
            console.log("data email");
            console.log(data);
            this.message.name = data.name;
            this.message.last_name = data.last_name;
            this.message.address = data.address;
            this.message.phone = data.phone;
            this.message.email = data.email;
            this.message.comments = data.comments;
            this.message.city = data.city;
            this.message.state = data.state;
            this.message.zip = data.zip;

            this.$modal.show("modal-message");
        },
        CloseModal() {
            this.$modal.hide("modal-message");
        },
        DetailMaterial(data) {
            this.cover_name = data.data.name_cover;
            this.material_image = data.material_image;
            this.manufacturer = data.manufacturer;
            this.material_name = data.material_name;
            this.manufacturer_number = data.data.manufacturer_name;
            this.full_description = data.data.full_descrip;
            this.small_description = data.data.small_descrip;
            this.colors = data.data.colors;
            this.category = data.category;
            this.pattern = data.pattern;
            this.applications = data.data.application;
            this.available_finish = data.data.available_finish;
            let pais = data.data.country_id;
            //let info = this.countriesList.filter(x => x.id === pais);
            var res = this.countriesList.find((item) => item.id === pais);
            this.country = res.nom_en;

            this.$modal.show("modal-detail");
        },
        CloseModal2() {
            this.$modal.hide("modal-detail");
        },
        activeStatus(index) {
            this.statusActive = index;
        },
        statusChange(status, id) {
            this.statusActive = null;
            window.SlabsV2.put("leads/status/" + id, {
                status: status,
            })
                .then((response) => {
                    this.getLeads();
                })
                .catch((error) => console.log(error));
        },
        pdfView(id) {},
    },
};
</script>
<style lang="scss" scoped>
.nav-link {
    padding: 0.5rem 0.5rem;
}
.infoModal {
    display: flex;
    .image {
        width: 40%;
        height: 300px;
        text-align: center;
        img {
            width: auto;
            max-width: 100%;
            height: 100%;
        }
    }
    .info {
        padding-left: 20px;
        width: 60%;
        span {
            display: block;
            b {
                margin-right: 5px;
            }
        }
    }
}
.items_order {
    display: flex;
    align-items: center;
    height: 52px;
    position: relative;
}
.action_order_btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.status_list {
    padding: 8px 7px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    width: 120px;
    font-size: 14px;
    z-index: 9;
    ul {
        li {
            display: block;
            position: relative;
            margin: 1px 0;
            padding: 5px;
            span {
                cursor: pointer;
            }
            &:hover {
            }
        }
    }
    .inprogress,
    .scheduling,
    .onhold,
    .delayed,
    .cancelled,
    .completed {
        width: 10px;
        height: 10px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        color: #fff;
    }
}
</style>
