<template>
    <BillingRestore>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Restore Invoice</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <div class="row">
                        <div class="card card-body estilosform">
                            <Loader v-if="isLoading"></Loader>
                            <div class="row contenedor-table" v-else>
                                <table
                                    class="table table-striped table-bordered table-hover"
                                    id="restoreCustomer"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">Invo #</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Rec</th>
                                            <th scope="col">Date Deleted</th>
                                            <th style="padding-right: 40px;">Restore?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in InvoicesList" :key="item.id">
                                            <td scope="row">{{ item.numdoc }}</td>
                                            <td>{{ item.fecdoc }}</td>
                                            <td>
                                                {{ item.firstname }} {{ item.lastname }}
                                                <br /><span style="color: #a9a9a9">{{
                                                    item.bussinesname
                                                }}</span>
                                            </td>
                                            <td>{{ item.invoicename }}</td>
                                            <td>${{ item.total }}</td>
                                            <td style="text-align: center">
                                                <span
                                                    v-if="item.idxstatus == 1"
                                                    class="badge badge-secondary"
                                                    style="background-color: #f89406"
                                                    >Pending</span
                                                >
                                                <span
                                                    v-else-if="item.idxstatus == 2"
                                                    class="badge badge-primary"
                                                    >Partially Paid</span
                                                >
                                                <span
                                                    v-else-if="item.idxstatus == 3"
                                                    class="badge badge-success"
                                                    >Paid</span
                                                >
                                                <span
                                                    v-else-if="item.idxstatus == 4"
                                                    class="badge badge-primary"
                                                    style="background-color: #e90404"
                                                    >Overdue</span
                                                >
                                                <span
                                                    v-else-if="item.idxstatus == 5"
                                                    class="badge badge-danger"
                                                    style="background-color: #b94a48"
                                                    >Void</span
                                                >
                                            </td>
                                            <td style="text-align: center">
                                                <span
                                                    v-if="item.rec == 1"
                                                    class="badge badge-success"
                                                    >Rec</span
                                                >
                                            </td>
                                            <td>{{ item.fecdelete }}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    class="btn btn-warning"
                                                    @click="setInvoiceRestore(item.id)"
                                                >
                                                    <i class="fas fa-share"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="deleteInvo(item.id)"
                                                    style="margin-left: 10px;"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </BillingRestore>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import BillingRestore from "@/apps/billing/Layouts/BillingRestore";
export default {
    components: {
        BillingRestore,
        Loader,
    },
    name: "RestoreInvoice",
    data() {
        return {
            isLoading: false,
            InvoicesList: [],
        };
    },
    created() {
        this.getInvoicesRestore();
    },
    methods: {
        getInvoicesRestore() {
            this.isLoading = true;
            window.billing
                .post("restore/getinvoice", { user_id: window.localuserdata })
                .then((response) => {
                    this.InvoicesList = response.data.result;
                    let e = this.InvoicesList;
                    for (var i = 0; i < e.length; i++) {
                        let fecdoc = new Date(e[i].fecdoc).toDateString();
                        let fn = fecdoc.split(" ");
                        this.InvoicesList[i].fecdoc = fn[1] + " " + fn[2] + ", " + fn[3];
                        let fecdue = new Date(e[i].fecdue).toDateString();
                        fn = fecdue.split(" ");
                        this.InvoicesList[i].fecdue = fn[1] + " " + fn[2] + ", " + fn[3];
                        let f_a = new Date();
                        fecdue = new Date(e[i].fecdue);
                        if (
                            f_a > fecdue &&
                            this.InvoicesList[i].idxstatus != 3 &&
                            this.InvoicesList[i].idxstatus != 5
                        ) {
                            this.InvoicesList[i].idxstatus = 4;
                        }
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        setInvoiceRestore(id) {
            this.$swal({
                title: "Restore this Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes restore!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .post("restore/setinvoice", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.result) {
                                this.getInvoicesRestore();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Invoice restore successfully",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        deleteInvo(id) {
            this.$swal({
                title: "Delete this Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes delete!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .post("restore/invo/del", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            this.isLoading = false;
                                this.getInvoicesRestore();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Invoice delete successfully",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                        });
                }
            });
        },
    },
};
</script>
<style lang="css" scoped>
.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
