<template>
    <div class="">
        <form @submit.prevent="selectCustomer" v-if="selectedCustomer">
            <h3>Select existing customer:</h3>
            <model-select
                :options="CustomersList"
                v-model="ValuesCustomers"
                placeholder="Choose a Customer"
                class="form-control"
            >
            </model-select>
            <div class="form-group bill_to" v-if="ValuesCustomers.value>0">
                <div>
                    <strong>Name:</strong>
                    <span>
                        {{ ValuesCustomers.customer_name }}
                    </span>
                    <span>
                        {{ ValuesCustomers.customer_lastname
                }}
                    </span>
                </div>
                <div v-if="ValuesCustomers.bussinesname!=null && ValuesCustomers.bussinesname!='' && ValuesCustomers.bussinesname!='null'">
                   
                    <strong>Bussinesname:</strong> {{ ValuesCustomers.bussinesname }}
                </div>
                <div 
                    v-if="ValuesCustomers.customer_address!=null || ValuesCustomers.city!=null ||
                        ValuesCustomers.state!=null || ValuesCustomers.code!=null || ValuesCustomers.customer_address!='' || ValuesCustomers.city!='' || ValuesCustomers.state!='' || ValuesCustomers.code!='' "
                >
                    <strong>Address: </strong>
                    <span v-if="ValuesCustomers.customer_address!=''">
                        {{ValuesCustomers.billing_address}}
                    </span>
                    <span
                        v-if="ValuesCustomers.city!=null ||
                        ValuesCustomers.state!=null ||
                        ValuesCustomers.code!=null 
                        "
                    >
                        <span v-if="ValuesCustomers.city!=null">{{ ValuesCustomers.city }}</span>
                        <span v-if="ValuesCustomers.state!=null">{{ ValuesCustomers.state }}</span>
                        <span v-if="ValuesCustomers.code!=null && ValuesCustomers.state!=null && ValuesCustomers.state!=''">, </span>
                        <span v-if="ValuesCustomers.code!=null">{{ ValuesCustomers.code }}</span>
                    </span>
                </div>
                <div v-if="ValuesCustomers.customer_phone!=null || ValuesCustomers.customer_officephone!=null">
                    <strong>Phone:</strong>
                    <span v-if="ValuesCustomers.customer_phone!=null">
                        {{ ValuesCustomers.customer_phone }}
                    </span>
                    <span v-if="ValuesCustomers.customer_officephone!=null">
                        <span v-if="ValuesCustomers.customer_phone!=null"> / </span>
                        {{ ValuesCustomers.customer_officephone }}
                    </span>                  
                </div>
                <div v-if="ValuesCustomers.email!=null">
                    <strong>Email:</strong>
                    {{ ValuesCustomers.email }}
                </div>
            </div>
            <div class="btn-group  mt-2" role="group">
                <button type="submit" class="btn btn-success pull-left">
                    Select & Continue
                </button>
                <button type="button" class="btn btnsimple pull-right" style="margin-left: 10px;" @click="selectedCustomer=!selectedCustomer">
                    Or add Customer
                </button>
            </div>
        </form>
        <form @submit.prevent="saveCustomer" v-else>
            <h3 style="margin-top: 10px;">Add Customer</h3>
            <div class="row">
                <div class="col-md-6">
                     <div class="form-group">
                        <label for="">First Name</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.firstname"
                            required
                        />
                    </div> 
                </div> 
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Last Name</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.lastname"
                        />
                    </div>                                                  
                </div>                    
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Business  Name</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.bussinesname"
                        />
                    </div>                        
                </div>                   
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Email</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.email"
                        />
                    </div>                       
                </div>                  
                <div class="col-md-6">                            
                    <div class="form-group">
                        <label for="">Cell Phone</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.mobilephone"
                        />
                    </div>                       
                </div>              
                <div class="col-md-6">                            
                    <div class="form-group">
                        <label for="">Office Phone</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.officephone"
                        />
                    </div>                       
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Address</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.address"
                        />
                    </div>
                </div>
                <div class="col-md-6">                            
                    <div class="form-group">
                        <label for="">State</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.astate"
                        />
                    </div>
                </div>
                <div class="col-md-6">                            
                    <div class="form-group">
                        <label for="">City</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.acity"
                        />
                    </div>
                </div>
                <div class="col-md-6">  
                    <div class="form-group">
                        <label for="">Zip Code</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="formcustomer.apostcode"
                        />
                    </div>
                </div>
            </div>
            <div class="btn-group float-right mt-2" role="group">
                <button type="submit" class="btn btn-success pull-right">
                    Save & Continue
                </button>
                <button type="button" class="btn btnsimple pull-right" style="margin-left: 10px;" @click="selectedCustomer=!selectedCustomer">
                    Cancel, Select Customer
                </button>
            </div>
        </form>  
    </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: { 
        ModelSelect,
    },
    name: "creatCustomer",
    data () {
        return {
            formcustomer: {
                customerId: null,
                firstname: null,
                lastname: null,
                email: null,
                address: null,
                astate: null,
                acity: null,
                mobilephone: null,
                officephone: null,
                apostcode: null,
                bussinesname:null,
                error: 0,
            },
            profile: {},
            CustomersList: [],
            ValuesCustomers: {
                text: null,
                value: 0,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
            },
            selectedCustomer: true,
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let t = this;
            t.profile = t.$parent.profile;
            if(t.$parent.step==1)
            {
                t.$parent.isLoading = true;
                console.log('init creatCustomer');
                if(t.profile.customerId > 0){
                    console.log('datos para customer', t.profile)
                    t.formcustomer.customerId= t.profile.customerId;
                    t.$parent.step = 2; // cambiar a estimate 
                    t.$parent.customerId = t.profile.customerId;
                }
                else{
                    t.autoRellenar();
                }
            }
        }, 
        autoRellenar(){
            let t = this;
            t.formcustomer.user_id = window.localorgdata;
            t.formcustomer.customerId= 0;
            t.formcustomer.firstname= t.profile.name;
            t.formcustomer.lastname= t.profile.lastname;
            t.formcustomer.email= t.profile.email;
            t.formcustomer.address= t.profile.address;
            t.formcustomer.astate= t.profile.state;
            t.formcustomer.acity= t.profile.city;
            t.formcustomer.mobilephone= t.profile.phone;
            t.formcustomer.apostcode= t.profile.zip;
            t.formcustomer.officephone= '';
            t.formcustomer.bussinesname= '';
            t.getCustomerList();
        },
        saveCustomer() {
            let t = this;
            t.$parent.isLoading = true;   
            window.billingSales
            .post("estimate/customer/add", t.formcustomer)
            .then((response) => {
                console.log(response.data);
                if (response.data.status) {
                    t.$store.commit('core/setNotiPush', {
                        show: true,
                        group: "notiPush",
                        type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                        title: 'success',
                        text: 'Successful registration.',
                        duration: 3000
                    });
                t.setCustomerID(response.data.customerId);
                }
                //t.$parent.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.$parent.isLoading = false;
            });
        },
        setCustomerID(id) {
            let t = this;
            window.master
            .post("salesProfileCustomerID", { 
                org_id: window.localorgdata,
                user_id: window.master_client,
                idSale: t.$parent.dataSales.id,
                customerId: id,
            })
            .then((response) => {
                console.log('setCustomerID', id);
                t.$parent.customerId = id;
                t.$parent.profile.customerId = id;
                t.$parent.step = 2; // cambiar a estimate   
                t.$parent.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.$parent.isLoading = false;
            });
        },
        selectCustomer(){
            let t = this;
                t.$parent.isLoading = true;
            t.setCustomerID(t.ValuesCustomers.value);   
        },
        getCustomerList() {
            let t = this;
            t.$parent.isLoading = true;
            window.billingSales
            .get("customersc/" + window.localuserdata)
            .then((response) => {
                console.log('getCustomerList');
                if (response.data.result.length>0) {
                    for (let i in response.data.result) {
                        if(response.data.result[i].id){
                            var id = response.data.result[i].id;
                            var name = response.data.result[i].firstname;
                            var billing_address =
                                response.data.result[i].billingaddress;
                            var customer_address = response.data.result[i].address;
                            var bussinesname = response.data.result[i].bussinesname;
                            var email = response.data.result[i].email;
                            var lastname = response.data.result[i].lastname;
                            var customer_phone = response.data.result[i].mobilephone;
                            var customer_officephone = response.data.result[i].officephone;
                            var city = response.data.result[i].bcity;
                            var state = response.data.result[i].bstate;
                            var code = response.data.result[i].bpostcode;
                            lastname = lastname != null ? lastname : "";
                            email = email != null ? email : "";
                            bussinesname = bussinesname != null ? bussinesname : "";
                            city = city != null ? city + "," : "";
                            state = state != null ? state : "";
                            code = code != null ? code : "";
                            customer_address = customer_address != null ? customer_address : "";
                            customer_officephone = customer_officephone != null ? customer_officephone : "";
                            let guion = '-';
                            let customer= {
                                text: name + " " + lastname + ((bussinesname!='') ? guion + bussinesname : ''),
                                value: id,
                                billing_address: billing_address,
                                bussinesname: bussinesname,
                                customer_address: customer_address,
                                customer_name: name,
                                customer_lastname: lastname,
                                customer_phone: customer_phone,
                                customer_officephone: customer_officephone,
                                email: email,
                                city: city,
                                state: state,
                                code: code,
                            };
                            t.CustomersList.push(customer);
                        }
                    }
                }
                t.$parent.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.$parent.isLoading = false;
            });
        },
    },
    watch: {
    },
    computed: {},
}
</script>
<style lang="css" scoped>
.form-group.bill_to {
    padding: 10px;
    border-left: 4px double #f0f0f0;
    margin-top: 10px;
    font-size: 20px;
}    
</style>
