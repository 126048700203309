<template>
    <div>
        <div class="card-header">
            <h3>Widget Code</h3>
        </div>
        <div class="card-body" style="min-height: 200px">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <div class="row" v-if="isCode">
                <div class="col-sm-12">
                    <textarea
                        id="codeHtml"
                        style="height: 200px; width: 100%; display: block"
                        readonly
                        onClick="this.select()"
                        v-model="codeHtml"
                    ></textarea>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary" @click="copyhtml">Copy code</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "RoofCalwidgetCode",
    components: {
        Loader,
    },
    data() {
        return {
            url: window.urlmaster + "api/roofcal/wapp/",
            user_id: window.master_client,
            org_id: window.localorgdata,
            isLoading: false,
            isCode: true,
            codeHtml: "",
        };
    },
    created() {
        this.url+= this.user_id + '/'+ this.org_id+ '/';
        this.changeTextarea();
    },
    methods: {
        changeTextarea() {
            var i = "";
            i += "<div id='appRoofCal'></div>";
            i += "<script id='appSRoofCal'>";
            i += "var d=document,appRoofCal='appRoofCal',idS='wgRoofCalS', f=new Date,";
            i += "urlRoofCal='" + this.url + "'+window.location.host,t=d.querySelector('#appSRoofCal'),";
            i += "s=d.createElement('script'); s.type='text/javascript', s.id=idS, s.defer=!0,";
            i += "s.src=urlRoofCal+'/'+f.getTime(),t.after(s);";
            i += "</" + "script>";
            this.codeHtml = i;

            // <&#60; &#62>;
        },
        copyhtml() {
            var element = document.querySelector("#codeHtml").select();
            try {
                document.execCommand("copy");
                this.$swal({
                    icon: "success",
                    title: "Code copied",
                    timer: 1000,
                });
            } catch (err) {
                console.log("unable to copy code");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
