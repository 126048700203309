<template>
	<div class="content-form-register">
		<form
				class="form-horizontal form-material"
				@submit.prevent="saveCustomer"
			>
			<div class="card-header">
				<div class="row">
					<div class="col-md-6">
						<h5 class="card-title">
							{{ NewCustomer.titleactionForm }} Customer
						</h5>
					</div>
					<div class="col-md-6">
						<div
							class="form-group d-block text-right"
							style="width: 100%; margin-bottom:0px;"
						>
							<input
								type="hidden"
								class="form-control"
								v-model="NewCustomer.idcustomer"
								name="idcustomer"
							/>
							<button
								type="submit"
								class="btn btn-success btn-lg b-block BtnSave"
                            >
                            	<i class="fas fa-save"></i> Save
                            </button>
                            <button
                            	type="button"
                            	class="btn btn-light btn-lg b-block 
                            	Btncancel"
                            	@click="MetCancelFormC2(customerID)"
                            	v-if="ShowBtncancel"
                            >
                            	<i class="fas fa-window-close"></i>
                            	Cancel
                            </button>
                            <button
								type="button"
								onclick="document.getElementById('s-f-r').style.display='block'"
								class="btn btn-light btn-lg b-block Btncancel"
								@click="MetCancelFormC"
								v-else
							>
								<i class="fas fa-window-close"></i>
								Cancel
							</button>
							<button
								type="button"
								@click="MetDeleteCustomer(customerID)"
								v-if="ShowBtnDel"
								class="btn btn-danger btn-lg b-block BtnDel"
							>
								<i class="fas fa-trash-alt"></i>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div
					class="alert alert-success"
					role="alert"
					v-if="MessageSuccefull != null"
				>
					{{ MessageSuccefull.message }}
				</div>
				<div class="bgloading" v-if="isLoading">
					<Loader></Loader>
				</div>
				<div class="row">
					<div class="col-md-12 col-lg-6">
						<div class="row">
							<div class="col-md-12 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">First Name:</label>
									<input
										type="text"
										name="firstname"
										v-model="NewCustomer.firstname"
										class="form-control"
										required
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">Last Name:</label>
									<input
										type="text"
										name="lastname"
										v-model="NewCustomer.lastname"
										class="form-control"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12">
								<div class="form-group">
									<label class="">Business Name:</label>
									<input
										type="text"
										name="bussinesname"
										v-model="NewCustomer.businessname"
										class="form-control"
									/>
								</div>
							</div>
							<div class="col-md-6 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">Active:</label>
									<select
										class="form-control"
										v-model="NewCustomer.active"
									>
										<option value="1">Active</option>
										<option value="0">Inactive</option>
									</select>
								</div>
							</div>
							<div class="col-md-6 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">Marketing Source: </label>
									<select
									class="form-control"
										name="referal"
										v-model="NewCustomer.referal"
									>
										<option value="0" selected>
											Uncategorized
										</option>
										<!-- <option value="1">
											Google Organic
										</option>
										<option value="2">
											Referal from existing client
										</option>
										<option value="3">Ads</option>
										<option value="4">Signs</option>
										<option value="5">
											Direct mail
										</option>
										<option value="6">Other</option> -->
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-6 col-xl-6">
						<div class="row">
							<div class="col-md-12 col-lg-12">
								<div class="form-group">
									<label class="">Email:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.email"
										name="email"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">Cell Phone:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.mobilephone"
										name="mobilephone"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-6">
								<div class="form-group">
									<label class="">Office Phone:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.officephone"
										name="officephone"
									/>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label class="">Website:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.website"
										name="website"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style="border-top: 1px solid #cecece;display: block;margin-bottom: 10px;margin-top: 10px;"></div>
				<div class="row">
					<div v-bind:class="[address2 ? 'col-md-6' : 'col-md-12','']">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Billing Address:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.billingaddress"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">City:</label>
									<input
										type="text"
										class="form-control"
										name="bcity"
										v-model="NewCustomer.bcity"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">State:</label>
									<input
										type="text"
										class="form-control"
										name="bstate"
										v-model="NewCustomer.bstate"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">Zip code:</label>
									<input
										type="text"
										class="form-control"
										name="bpostcode"
										v-model="NewCustomer.bpostcode"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6" v-if="address2">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="">Address:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.address"
										name="address"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">City:</label>
                                    <input
										type="text"
										class="form-control"
										v-model="NewCustomer.acity"
										name="acity"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">State:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.astate"
										name="astate"
									/>
								</div>
							</div>
							<div class="col-md-12 col-lg-12 col-xl-4">
								<div class="form-group">
									<label class="">Zip Code:</label>
									<input
										type="text"
										class="form-control"
										v-model="NewCustomer.apostcode"
										name="apostcode"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div
							class="form-group d-block"
							style="width: 100%"
						>
							<label class="">Notes:</label>
							<textarea
								class="form-control"
								id="exampleFormControlTextarea1"
								rows="3"
								v-model="NewCustomer.notes"
								name="notes"
								style="height: 200px"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from "@/core/components/shared/Loader";
export default {
	name: 'formCustomer',
	components: {
		Loader
	},
	data() {
		return {
			isLoading: false,
			MessageSuccefull: null,
			NewCustomer: {
				user_id: null,
				idcustomer: null,
				titleactionForm: "",
				firstname: null,
				lastname: null,
				businessname: null,
				active: null,
				referal: 0,
				email: null,
				officephone: null,
				mobilephone: null,
				website: null,
				billingaddress: null,
				bcity: null,
				bstate: null,
				bpostcode: null,
				address: null,
				acity: null,
				astate: null,
				apostcode: null,
				notes: null,
				error: 0,
			},
			ActionType: "",
			ShowBtnDel: false,
			ShowBtncancel: false,
			address2: false,
			registerForm: false,
			customerID: 0,
            paginador: 100,
		};
	},
	created() {
		console.log('cargando form');
		this.address2 = this.$store.getters['ModApps/billing/getShowAddress2'];
		this.registerForm = this.$store.getters['ModApps/billing/getFormCustomer'];
		this.customerID = this.$store.getters['ModApps/billing/getCustomerID'];
		if(this.customerID > 0) {
			this.getCustomerByID();
		}
		else {
			this.newAddCustomer();
		}
	},
	methods: {
		getCustomerByID() {
			this.isLoading = true;
			this.ActionType = "update";
			let r = this.$store.getters['ModApps/billing/getCustomerByID'];
			if(Object.keys(r).length == 0) {
				this.detailCustomer();
			}
			else {
				this.ShowBtnDel = true;
				this.ShowBtncancel = true;
				console.log('getCustomerByID', r);
				this.NewCustomer = {
					titleactionForm: "Edit",
					user_id: window.localuserdata,
					idcustomer: r.id,
					firstname: r.firstname,
					lastname: r.lastname,
					email: r.email,
					officephone: r.officephone,
					mobilephone: r.mobilephone,
					website: r.website,
					billingaddress: r.billingaddress,
					bcity: r.bcity,
					businessname: r.bussinesname,
					address: r.address,
					bstate: r.bstate,
					bpostcode: r.bpostcode,
					acity: r.acity,
					astate: r.astate,
					apostcode: r.apostcode,
					active: r.active,
					referal: r.referal,
					notes: r.notes,
					error: 0,
				};
			}
			this.isLoading = false;
			console.log('NewCustomer', this.NewCustomer);
		},
		detailCustomer() {
			this.isLoading = true
			let idc = this.customerID;
			console.log('detailCustomer');			
		},
		newAddCustomer () {
			if(this.registerForm) {
				this.MetClearFormCustomer();
				this.ActionType = "new";
				this.NewCustomer.titleactionForm = "New";
				this.ShowBtnDel = false;
				this.ShowBtncancel = false;
				if(this.customerID > 0) {
					this.getCustomerByID(this.customerID)
				}
			}
		},
		saveCustomer() {
			this.ShowDefaultContentCustomer = false;
			this.isLoading = true;
			console.log('send ', this.ActionType);
			let url = "customer/add";
			let idc = this.NewCustomer.idcustomer;
			if (this.ActionType == "new") { url = "customer/add";}
			if (this.ActionType == "update") {
				url = "customer/edit/" + this.NewCustomer.idcustomer;
			}
			let t = this;
			window.billing.post(url, {
				user_id: window.localuserdata,
				idcustomer: t.NewCustomer.idcustomer,
				firstname: t.NewCustomer.firstname,
				lastname: t.NewCustomer.lastname,
				businessname: t.NewCustomer.businessname,
				active: t.NewCustomer.active,
				referal: t.NewCustomer.referal,
				email: t.NewCustomer.email,
				officephone: t.NewCustomer.officephone,
				mobilephone: t.NewCustomer.mobilephone,
				website: t.NewCustomer.website,
				billingaddress: t.NewCustomer.billingaddress,
				bcity: t.NewCustomer.bcity,
				bstate: t.NewCustomer.bstate,
				bpostcode: t.NewCustomer.bpostcode,
				address: t.NewCustomer.address,
				acity: t.NewCustomer.acity,
				astate: t.NewCustomer.astate,
				apostcode: t.NewCustomer.apostcode,
				notes: t.NewCustomer.notes,
			})
			.then((response) => {
				t.MessageSuccefull = response.data;
				t.isLoading = false;
					var msj = "";
				if (t.ActionType == "new") {
					idc = response.data.customer.id;
					msj = "New customer added";
				} 
				if (t.ActionType == "update") {
					msj = "Customer updated successfully.";
				}
				t.$notify({
					group: "noti",
					title: "Success",
					type: "success",
					text: msj
				});
				console.log(msj, idc);
				t.$store.commit('ModApps/billing/setCustomerID', idc);
				t.$store.commit('ModApps/billing/setCustomerRefresh', true);
				t.MetClearFormCustomer();
				t.MetCancelFormC();
				document.getElementById('s-f-r').style.display='block'
			})
			.catch((error) => {
				console.log(error);
				t.isLoading = false;
			});
		},
		MetDeleteCustomer(id) {
			let t = this;
			this.$swal({
                title: "Delete this Customer?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                	t.isLoading = true;
					window.billing
					.delete("customer/" + id)
					.then((response) => {
						if (response.data.success) {
							console.log("deeted", id);
							t.$store.commit('ModApps/billing/setCustomerRefresh', true);
							t.$store.commit('ModApps/billing/setCustomerID', 0);
							t.$store.commit('ModApps/billing/setCustomerByID', {});
							t.$notify({
								group: "noti",
								type: "success",
								title: "Deleted",
								text: "Deleted successfull.",
							});
						} else {
							t.$notify({
								group: "noti",
								type: "error",
								title: "Error",
								text:
								"This customer have attached documents and can not be deleted.",
							});
						}
						t.isLoading = false;
						t.MetCancelFormC();
					})
					.catch(function (error) {
						console.log(error);
						t.isLoading = false;
					});
				}
			});
		},
		MetCancelFormC() {
			console.log('MetCancelFormC');
			this.$store.commit('ModApps/billing/setFormCustomer', false);
		},
		MetCancelFormC2(idc) {
			if (window.innerWidth <= 767) {
				document.getElementById("s-f-r").style.display = "block";
			}
			this.MetCancelFormC();
			this.$store.commit('ModApps/billing/setCustomerID', idc);
		},
		MetClearFormCustomer() {
			this.MessageSuccefull = null;
			this.NewCustomer = {
				idcustomer: "",
				firstname: "",
				lastname: "",
				businessname: "",
				active: "1",
				referal: "0",
				email: "",
				officephone: "",
				mobilephone: "",
				website: "",
				billingaddress: "",
				bcity: "",
				bstate: "",
				bpostcode: "",
				address: "",
				acity: "",
				astate: "",
				apostcode: "",
				notes: "",
				error: 0,
			};
		},
	},
	computed: {
		...mapGetters("ModApps/billing", { 
			getCustomerID: "getCustomerID",
		}),  
	},
	watch: {
		ActionType: function () {
			console.log('ActionType', this.ActionType);
		},
		getCustomerID: function () {  
			if (this.getCustomerID > 0) {
				this.address2 = this.$store.getters['ModApps/billing/getShowAddress2'];
				this.registerForm = this.$store.getters['ModApps/billing/getFormCustomer'];
				this.customerID = this.$store.getters['ModApps/billing/getCustomerID'];
				this.getCustomerByID();
			}
			else {
				this.newAddCustomer();
			}
		},
		
	},
};
</script>
<style lang="css" scoped>
.form-group {
    margin-bottom: 5px;
}
.Btncancel {
    background-color: #fff;
    border-color: #000;
}
.Btncancel,
.BtnSave,
.BtnDel {
    border-radius: 2px;
}
.Btncancel,
.BtnSave,
.BtnDel {
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 14px;
}
@media (max-width: 767px) {
    select.form-control {
        padding: 0px;
        width: 100%;
        margin-bottom: 10px;
    }
    span.cdropdown {
        width: 100%;
    }
    span.cdropdown .btn.btn-transaction {
        width: 100%;
    }
}
</style>
