<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting / Chart of Accounts</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-md-6"> 
                                    <button
                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                        class="btn btn-success btn-sm mr-2 float-left ctrlbtn"
                                        @click="MetAddNewBtn"
                                    >
                                        <i class="fas fa-plus"></i> Add Account
                                    </button>                                
                                </div>
                                <div class="col-md-6"> 
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="TextSearch"
                                        placeholder="Filter Chart of Accounts"
                                        style="border: 1px solid #c9c9ca;float: right;margin-bottom: 10px;"
                                    />                              
                                </div>                                
                            </div>                            
                            <div class="d-flex" style="justify-content: space-between">
                            </div>
                            <div class="table-responsive">
                                <Loader v-if="isLoading"></Loader>
                                <table v-else class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Type</th>
                                            <th scope="col">Account</th>
                                            <th scope="col" class="text-right">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in filterItems" :key="item.id">
                                            <td scope="row">
                                                {{ item.name_account_type }}
                                            </td>
                                            <td>
                                                <span
                                                    style="
                                                        padding: 2px;
                                                        background-color: #eae8e8;
                                                        height: 23px;
                                                        width: 19px;
                                                        display: inline-block;
                                                        text-align: center;
                                                        margin-right: 10px;
                                                        position: relative;
                                                    "
                                                    @click="
                                                        MetDetailcontentBtn(
                                                            item.description
                                                        )
                                                    "
                                                    v-if="item.description !== null"
                                                    ><b>+</b></span
                                                >{{ item.name_account }}
                                            </td>
                                            <td>
                                                <button
                                                    class="btn btn-secondary btn-sm float-right"
                                                    @click="
                                                        MetEditBtn(
                                                            item.id,
                                                            item.name_account,
                                                            item.account_type,
                                                            item.description
                                                        )
                                                    "
                                                    v-if="item.user_id !== 0"
                                                >
                                                    <i class="fas fa-edit"></i></button
                                                ><button
                                                    class="btn btn-danger btn-sm mr-2 float-right"
                                                    @click="MetDelBtn(item.id)"
                                                    v-if="item.user_id !== 0"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                                
                                <div v-if="NoRecord == true" class="text-center">
                                    no record
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="modal-detail" height="auto">
                <div class="card-body">{{ description_product }}</div>
            </modal>
        </slot>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formChartAccounts from '@/apps/billing/components/setting/formChartAccounts'
export default {
    components: {
        Loader,
        modalLateral,
        formChartAccounts
    },
    data() {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },            
            isLoading: false,
            ChartLists: [],
            TextSearch: "",
            FormReg: {
                btnName: "SAVE",
                Action: "Add",
                id: null,
                name: null,
                description: null,
                account_type_id: "",
            },
            description_product: "",
            NoRecord: false,
        };
    },
    mounted () {
        this.getchartaccount();
    },
    methods: {
        closeModal2() {
            this.$modal.hide("setting-ps");
        },
        MetDetailcontentBtn(desc) {
            this.$modal.show("modal-detail");
            this.description_product = desc;
        },
        closeModal() {
            this.$modal.hide("setting-chart");
        },
        getchartaccount() {
            this.isLoading = true;
            window.billing
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    this.ChartLists = response.data.result;
                    if (this.ChartLists.length == 0) {
                        this.NoRecord = true;
                    } else {
                        let e = response.data.result;
                        for (let i in e) {
                            this.ChartLists[i].value = this.ChartLists[i].id;
                            this.ChartLists[i].text = this.ChartLists[i].name_account;
                        }
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        MetEditBtn(id, name_account, account_type_id, description) {
            this.FormReg = {
                btnName: "UPDATE",
                Action: "Update",
                id: id,
                name: name_account,
                account_type_id: account_type_id,
                description: description,
            };
            let t = this
            this.m = {
                show: true,
                component: 'formChartAccounts',
                data: {FormReg:t.FormReg}
            }  
            this.$store.commit('core/setOpenModal', this.m);               
        },
        MetAddNewBtn() {
            this.FormReg = {
                btnName: "SAVE",
                Action: "Add",
                account_type_id: "",
                description: null,
                name: null,
                id: null,
            };
            let t = this
            this.m = {
                show: true,
                component: 'formChartAccounts',
                data: {FormReg:t.FormReg}
            }  
            this.$store.commit('core/setOpenModal', this.m);               
        },        
        MetDelBtn(id) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Chart?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("chart/delete", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.deleted) {
                                this.getchartaccount();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Deleted Chart Successful",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text:
                                        "It has products or services assigned to this category.",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error, (this.isLoading = false));
                        });
                }
            });
            this.isLoading = false;
        },
    },
    watch: {
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.getchartaccount();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        }
    },    
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
        filterItems: function () {
            var self = this;
            return this.ChartLists.filter(function (item) {
                return (
                    (item.name_account &&
                        item.name_account
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0) ||
                    (item.name_account_type &&
                        item.name_account_type
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0)
                );
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@media (min-width: 1279px) {
    .ctrlbtn {
        width:160px !important;
    }
    .ctrlbtnCustom {
        width:100px !important;
    }    
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}

.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
</style>
