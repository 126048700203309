let dashEditForm = function(id) {
    if (id > 0 && this.id_tags.length > 0) {
        this.$router.push({
            name: "JobOrderFormEditFromTag",
            params: { id: id, edit: "edit", tag: this.id_tags[0] },
        });
    } else if (id > 0) {
        this.$router.push({
            name: "JobsEditdash",
            params: { id: id, edit: "edit", dash: "dash" },
        });
    } else {
        this.$router.push({ name: "JobOrderFormNew" });
    }
};
export default dashEditForm;
