export default { 
	IngresoAccount(state, val){state.auth = val},
	retrieveToken(state, token) {state.token = token; },
	destroyToken(state) {
        state.token = null;
        console.log('destroyToken')
        // localStorage.clear();
        let excluir = ["manifestJSON", "versionCache"];
        Object.keys(localStorage).forEach(arr => {
            if (excluir.indexOf(arr) == -1) {
                localStorage.removeItem(arr);
                // console.log('clear', arr)
            }
        });
        //app_default
    },
	setEmailVerify(state, val){ 
        state.emailVerify = val;
        if(val){
            localStorage.setItem('emailVerify', 1);
        }else {
            localStorage.setItem('emailVerify', 0);
        }
    },
	SET_RETURN_USER(state, val){ state.return_user = val; },
	SET_DATAUSER(state, val){ state.dataUser = val;},
	set_privilegies(state, privilegies){state.privilegies = privilegies; },
	set_valSessionAndHttpApis(state, r){
        console.log('valSessionAndHttpApis', r);
		if(r.op === 1 && window.access_web == '0')
		{
            if(!r.validM)
            {
                console.log('creando nuevas variables storage');
                localStorage.setItem('access_token_m', r.token);// token master
                localStorage.setItem('master_client_data', r.master_user_id);// master_user_id
                localStorage.setItem('mark_client_data_m', r.user_id);// user_id
                localStorage.setItem('mark_client_data', r.org);// org_id
                localStorage.setItem('user_type', r.user_type);// user_type 
                localStorage.setItem('oauth_m', r.oauth);//llave de sesion                
                localStorage.setItem('start', r.d);// fecha de inicio 
                localStorage.setItem('access_web', 0);// acceso desde website o internal
                localStorage.setItem('folder_user', r.master_user_id+'/'+r.org)	
            }else{
                console.log('las variables storage ya fueron creadas');
            }
		}
        else if(r.op === 2 && localStorage.getItem('access_web') == '0'){
            // sino coninden limpiar storage recargar
            state.token = null;
            // localStorage.clear();
            let excluir = ["manifestJSON", "versionCache"];
            Object.keys(localStorage).forEach(arr => {
                if (excluir.indexOf(arr) < -1) {
                    localStorage.removeItem(arr);
                    console.log('clear', arr)
                }
            });
        }
        const token = localStorage.getItem('access_token_m'); // token master
        if(token)
        {
            window.localtoken_M = localStorage.getItem('access_token_m'); // token master
            window.master_client = localStorage.getItem('master_client_data'); // master_user_id
            window.localAccountID = localStorage.getItem('mark_client_data_m'); // user_id
            window.localuserdata = localStorage.getItem('mark_client_data'); // org_id
            window.localorgdata = localStorage.getItem('mark_client_data'); // org_id
            window.user_type = localStorage.getItem('user_type'); // user_type 
            window.getStart = localStorage.getItem('start'); // start 
            state.token = token; // retrieveToken
            state.auth = true; // IngresoAccount
            if(window.access_web == '1'){
                window.access_web = 1;
                localStorage.setItem('access_web', 1)
                console.log('login Externo');
            }
        }
	},
    setShowPopPremium(state, val) {state.popPremium = val;},
}
