let AssignCustomer = function() {
    this.editCusInvLoading = true;
    let t = this;
    let g = t.$store.getters['core/getOpenModal'].data;
    t.$store.commit("core/setLoadingModal", true);
    let url = this.apis.AssignCustomer();
    window.master
        .post(url, {
            user_id: t.user_id,
            org_id: t.org_id,
            job_id: g.id_i,
            id_customer: t.customer_id,
            id_invoice: t.invoice_id,
        })
        .then((response) => {
            let d = {
                job: g.id_i,
                id_customer: t.customer_id,
                id_invoice: t.invoice_id,
            };
            t.setCustomInvo(d);
            t.editCusInvLoading = false;
            t.$store.commit("core/setLoadingModal", false);
            t.closeModalCustomer();
        })
        .catch((error) => {
            console.log(error);
            t.editCusInvLoading = false;
        });
};
export default AssignCustomer;
