let MetDeleteInvoice = function(idinv) {
	this.closeSubmenu(idinv);
	this.isLoading = true;
	this.$swal({
		title: "Delete this Invoice?",
		text: "Are you sure?",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		confirmButtonText: "Yes, Delete it!",
	}).then((result) => {
		if (result.value) {
			window.billing
			.put("invoice/delete/" + idinv, {
				user_id: window.localuserdata,
			})
			.then((response) => {
				console.log(response.data);
				this.isLoading = false;
				if (response.data.status) {
					this.searchFilter();
					this.$notify({
						group: "noti",
						title: "Deleted!",
						type: "success",
						text: "Deleted Invoice Successful",
					});
				}
			})
			.catch((error) => console.log(error, (this.isLoading = false)));
		}
	});
	this.isLoading = false;
};
export default MetDeleteInvoice;
