import { render, staticRenderFns } from "./menuAccountR.vue?vue&type=template&id=a5b15b14&scoped=true&"
import script from "./menuAccountR.vue?vue&type=script&lang=js&"
export * from "./menuAccountR.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5b15b14",
  null
  
)

export default component.exports