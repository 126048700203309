<template>
  <div class="container-fluid form-myCard">
    <div class="row">
      <div class="col-2 ">
        <div class="menu-aside" >
          <div class="list-group" v-for="(i, index) in menu" :key="index">
            <a href="#" @click.prevent="selected=i.name"
              :class="[
                'list-group-item list-group-item-action',
                (selected==i.name) ? 'active' : ''
              ]"
            >
              <i :class="i.icon"></i>
              <span>{{ i.name }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-body" style="padding: 0px;">
          <div class="formcard">
            <h3 class="card-title">
              My Card
              <button type="button" v-if="!isLoading"
                class="btn btn-success"
                @click="setCard()"
                style="margin-left:10px;"
                :disabled="isExistSlug"
              ><i class="fas fa-save"></i></button>
            </h3>
            <div class="row text-center" v-if="isLoading">
              <Loader class="col-12"></Loader>
            </div>
            <div class="content-body" v-else>
              <div class="row" v-if="selected=='About'">
                  <div class="col-12">
                    <hr />
                    <h4>About me:</h4>
                    <div class="row">
                      <div class="col-md-6 ">
                        <label class="col-form-label">Name</label>
                        <input type="text" class="form-control" v-model="form.name">
                      </div>
                      <div class="col-md-6 ">
                        <label class="col-form-label">Last Name</label>
                        <input type="text" class="form-control" v-model="form.lastname">
                      </div>
                      <div class="col-md-12">
                        <label class="col-form-label">
                          Url:
                          <small>
                            <a href="#" @click.prevent="external_link">
                              <span v-if="card_primary==0">{{urlweb}}{{ form.slug_url }}</span>
                              <span v-else>{{urlweb}}{{ form.slug_url }}</span>
                            </a>
                          </small>
                        </label>
                        <div class="input-group">
                            <span class="input-group-text" style="font-size: 14px;padding-right: 0px;border-right: 0px;" v-if="card_primary==0">{{suf_url}}-</span>
                          <input type="text" class="form-control" v-model="form.slug_url" @change="validSlug" :disabled="(card_primary==1)" v-if="card_primary==1"/>
                          <input type="text" class="form-control" v-model="slug_name" @change="validSlug" v-else style="padding-left: 0;border-left: 0;"/>
                        </div>
                        <div class="invalid-feedback" :style="'display:'+ (isExistSlug ? 'block' : 'none')+';'">
                          Wrong url or already exist.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 ">
                        <label class="col-form-label">Position:</label>
                        <input type="text" class="form-control" v-model="form.rol">
                        <!-- <select class="form-control" v-model="form.rol">
                          <option v-for="(i, index) in roles" :key="index" :value="i.id">{{ i.name }}</option>
                        </select> -->
                      </div>
                      <div class="col-md-4 ">
                        <label class="col-form-label">Status:</label>
                        <select class="form-control" v-model="form.status">
                          <option value="1">Activo</option>
                          <option value="0">Inactivo</option>
                        </select>
                      </div>
                      <div class="col-md-4 ">
                        <label class="col-form-label">Template:</label>
                        <select class="form-control" v-model="form.template_id">
                          <option value="1">Default Light</option>
                          <option value="2">Default Dark</option>
                        </select>
                      </div>
                    </div>
                    <div class="row marginTop">
                      <div class="col-12"><h4>Organization info:</h4></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="col-form-label ">Company name:</label>
                        <input type="text" class="form-control" id="" v-model="org.company_name" required disabled="true"/>
                      </div>
                      <div class="col-md-6 ">
                        <label class="col-form-label">Address</label>
                        <input type="text" class="form-control" v-model="org.address" disabled="true"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 ">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control" v-model="org.city" disabled="true"/>
                      </div>
                      <div class="col-md-4 ">
                        <label class="col-form-label">State</label>
                        <input type="text" class="form-control" v-model="org.state" disabled="true"/>
                      </div>
                      <div class="col-md-4 ">
                        <label class="col-form-label">Zip Code</label>
                        <input type="text" class="form-control" v-model="org.zip" disabled="true"/>
                      </div>
                    </div>
                </div>
              </div>
              <div class="row" v-if="selected=='Pictures'">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <hr />
                        <h4>Thumbnail Pictures:</h4>
                      </div>
                      <div class="col-4  marginTop text-center">
                        <label for="picture">Profile Picture</label>
                        <div class="profile"
                          @click="$refs.profileImg.click()"
                          :style="(profilePreview!=null) ? 'background-image: url('+profilePreview+');' : ''"
                        >
                            <i class="fas fa-user" v-if="profilePreview==null && profileLoad==false"></i>
                            <Loader v-else-if="profileLoad"></Loader>
                        </div>
                        <input type="file" style="display:none;"
                          ref="profileImg"
                          accept="image/*"
                          @change="loadPicProfile($event)"
                        />
                      </div>
                      <div class="col-8  marginTop text-center">
                        <div class="row">
                          <div class="col-6">
                            <label for="logo">Company Logo</label>
                            <div class="logo_pic"
                              @click="$refs.logoImg.click()"
                              :style="(logoPreview!=null) ? 'background-image: url('+logoPreview+');' : ''"
                            >
                                <i class="fas fa-file-image" v-if="logoPreview==null && logoLoad==false"></i>
                                <Loader v-else-if="logoLoad"></Loader>
                            </div>
                            <input type="file" style="display:none;"
                              ref="logoImg"
                              accept="image/*"
                              @change="loadPicLogo($event)"
                            />
                          </div>
                          <div class="col-6 text-left">
                            <label>Controls Logo</label>
                            <div class="controls row">
                              <div class="col-12">
                                <label>Show Image:</label>
                                <div class="onoffswitch" style="margin-left: 30px;">
                                  <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    id="logo_active"
                                    v-model="pictures.logo_active"
                                    :value="1"
                                  />
                                  <label class="onoffswitch-label" for="logo_active" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                  </label>
                                </div>
                                <br />
                                <label>
                                  Opacity: 
                                  {{ Number(pictures.logo_opacity*100).toFixed(0) }} %
                                </label>
                                <input type="range" class="slider-range"
                                  v-model="pictures.logo_opacity"
                                  min="0" max="1" step="0.05"
                                  :disabled="!pictures.logo_active"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 marginTop">
                        <hr />
                        <h4>Cover Picture:</h4>
                      </div>
                      <div class="col-8 text-center marginTop">
                        <label for="cover">Cover Photo</label>
                        <div class="banner" 
                        :style="(coverPreview!=null && coverPreview!='null') ? 'background-image: url('+coverPreview+');background-color:'+pictures.cover_color+';color:#ddd;' : 'background-color:'+pictures.cover_color+';color:#ddd;'"
                        @click="$refs.coverImg.click()"
                        >
                          <i class="fas fa-image" v-if="coverPreview==null && coverLoad==false"></i>
                          <Loader v-else-if="coverLoad"></Loader>
                        </div>
                        <input type="file" style="display:none;"
                          ref="coverImg"
                          accept="image/*"
                          @change="loadPicCover($event)"
                        />
                      </div>
                      <div class="col-4 marginTop">
                        <label>Controls Cover</label>
                            <div class="controls row">
                              <div class="col-12">
                                <label>Show Image:</label>
                                <div class="onoffswitch" style="margin-left: 30px;">
                                  <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    id="cove_active"
                                    v-model="pictures.cover_active"
                                    :value="1"
                                  />
                                  <label class="onoffswitch-label" for="cove_active" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                  </label>
                                </div>
                                <br />
                                <label>
                                  Overlay: {{ pictures.cover_overlay }}%
                                </label>
                                <input type="range" class="slider-range"
                                  v-model="pictures.cover_overlay"
                                  min="0" max="100" step="5"
                                  :disabled="!pictures.cover_active"
                                />
                                <div class="form-group">
                                  <label>Color</label>
                                  <div class="input-group">
                                    <input type="text"
                                      class="form-control color1"
                                      v-model="pictures.cover_color"
                                      disabled
                                    />
                                    <span class="input-group-text" style="padding:0px;">
                                      <button type="button" class="btn btn-sm btn-light"
                                        v-if="!showPicker" @click="openPicker"
                                        :disabled="pictures.cover_active"
                                        :style="'background-color:'+pictures.cover_color+';padding:10px 16px;color:#fff;'"
                                      ><i class="fas fa-pen"></i></button>
                                      <button type="button" class="btn btn-sm btn-success"
                                        v-if="showPicker" @click="okPicker"
                                      >Ok</button>
                                      <button type="button" class="btn btn-sm btn-danger"
                                        v-if="showPicker" @click="cancelPicker"
                                      >X</button>
                                    </span>
                                </div>
                                  <chrome-picker v-if="showPicker"
                                    v-model="getcolor"
                                    class="getcolor"
                                  ></chrome-picker>
                              </div>
                              </div>
                            </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="selected=='Contact'">
                  <div class="col-12">
                    <hr />
                    <h4>Contact info:</h4>
                    <draggable
                      v-model="contact"
                      tag="div"
                      class="contacts"
                      v-bind="dragOptions"
                      @start="isDragging(true)"
                      @end="isDragging(false)"
                      @change="checkMove(contact)"
                      handle=".handle"
                    >
                      <div class="row" v-for="(i, index) in contact" :key="'contact'+index">
                        <div class="col-md-1">
                          <div class="btnmove handle" style="line-height: 50px;">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                          </div>
                        </div>
                        <div class="col-md-8 ">
                          <label class="col-form-label">{{ i.label }}:</label>
                          <div class="input-group">
                            <span class="input-group-text"><i :class="i.icon"></i></span>
                              <input type="text" class="form-control"
                                v-model="i.val"
                                maxlength="200"
                              />
                          </div>
                        </div>
                        <div class="col-md-2 text-center">
                          <label class="col-form-label">Show:</label>
                          <div class="onoffswitch" v-bind:class="(listPremium.includes(i.label) && !PlanPremium) ? 'disabled' : ''">
                            <div v-if="(listPremium.includes(i.label) && !PlanPremium)"
                            class="switchDisabled" @click="showPremium()"></div>
                            <input
                              type="checkbox"
                              class="onoffswitch-checkbox"
                              :id="'status-'+i.tipo+'-'+index"
                              v-model="i.status"
                              :value="1"
                              :disabled="(listPremium.includes(i.label) && !PlanPremium)"
                            />
                            <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                              <span class="onoffswitch-inner"></span>
                              <span class="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </draggable>
                    <div class="row marginTop"></div>
                    <hr />
                    <h4>Social:</h4>
                    <draggable
                      v-model="contact"
                      tag="div"
                      class="socials"
                      v-bind="dragOptions"
                      @start="isDragging(true)"
                      @end="isDragging(false)"
                      @change="checkMove(social)"
                      handle=".handle"
                    >
                      <div class="row" v-for="(i, index) in social" :key="'social'+index">
                        <div class="col-md-1">
                          <div class="btnmove handle" style="line-height: 50px;">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                          </div>
                        </div>
                        <div class="col-md-8 ">
                          <label class="col-form-label">{{ i.label }}:</label>
                          <div class="input-group">
                            <span class="input-group-text"><i :class="i.icon"></i></span>
                              <input type="text" class="form-control" v-model="i.val" maxlength="200" />
                          </div>
                        </div>
                        <div class="col-md-2 text-center">
                          <label class="col-form-label">Show:</label>
                          <div class="onoffswitch">
                            <input
                              type="checkbox"
                              class="onoffswitch-checkbox"
                              :id="'status-'+i.tipo+'-'+index"
                              v-model="i.status"
                              :value="1"
                            />
                            <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                              <span class="onoffswitch-inner"></span>
                              <span class="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
              </div>
            </div>
            <div class="row" v-if="!isLoading">
              <div class="col-md-12">
                <div class="foot-btns">
                  <button type="button" class="btn btn-ligth" @click="backListCard()">CANCEL</button>
                  <button type="button" class="btn btn-success" :disabled="isExistSlug" @click="setCard()" style="margin-left:10px;">SAVE</button>
                  <button type="button" class="btn btn-success" :disabled="isExistSlug" @click="setCard(1)" style="margin-left:10px;">SAVE & EXIT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 border-left">
        <div class="card card-body" style="padding: 0px;" v-if="!isLoading">
          <div class="row">
            <div class="col-12 ">
              <div class="device-preview">
                <div class="fondo-marco-mobile"></div>
                <div class="template-device">
                    <div class="device-content">
                      <div class="cover_picture"
                        :style="((coverPreview!=null && coverPreview!='null') && pictures.cover_active) ?
                          'background-image: url('+coverPreview+');background-color:'+pictures.cover_color+';' :
                            'background-color:'+pictures.cover_color+';'">
                            <div class="cover_picture_before" v-if="pictures.cover_active"
                            :style="'background-color: rgba(0 0 0 / '+pictures.cover_overlay+'%);'"></div>
                        <div class="logo_picture" v-if="pictures.logo_active"
                          :style="(logoPreview!=null) ?
                            'background-image: url('+logoPreview+');opacity: '+pictures.logo_opacity+';' : ''"
                        ></div>
                      </div>
                      <div class="profile_picture" :style="(profilePreview!=null) ? 'background-image: url('+profilePreview+');' : ''">
                        <i class="fas fa-user" v-if="profilePreview==null && profileLoad==false"></i>
                            <Loader v-else-if="profileLoad"></Loader>
                      </div>
                      <div class="author-info">
                          <h1 class="name">{{form.name}} {{form.lastname}}</h1>
                          <h2 class="company">{{org.company_name}}</h2>
                          <p class="rol">{{ form.rol }}</p>
                          <!-- <p class="rol">{{ getRol(form.rol)[0].name }}</p> -->
                      </div>
                      <div class="separador text-center">
                        <a href="#" class="btn-normal" style="vertical-align: bottom; font-size: 30px; color: #000;">
                          <i class="fas fa-qrcode"></i>
                        </a>
                        <a href="#" class="btn-blue btn-add-contact"
                        >
                          <i class="fas fa-user-plus" style=" margin-right: 10px;"></i>
                          ADD TO CONTACT
                        </a>
                        <a href="#" class="btn-normal" style="vertical-align: bottom;">
                          <img src="@/core/assets/img/share.svg" style="width: 25px;">
                        </a>
                      </div>
                      <div class="contact-iconos text-center">
                        <!-- contact -->
                        <div class="box-icon" v-for="(i, index) in contact" :key="'contact-preview'+index" v-if="i.status">
                          <a href="#" target="_blank" class="btn btn-sbg"> <i :class="i.icon"></i></a>
                          <p>{{ i.label }}</p>
                        </div>
                        <!-- social -->
                        <div class="box-icon" v-for="(i, index) in social" :key="'social-preview'+index" v-if="i.status">
                          <a href="#" target="_blank" class="btn btn-sbg"> <i :class="i.icon"></i></a>
                          <p>{{ i.label }}</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
import draggable from 'vuedraggable';
import { Chrome } from "vue-color";
import './form.scss';
export default {
    name: "formCard",
    components: {
      Loader,
      draggable,
      "chrome-picker": Chrome,
    },
    data () {
        return {
          isLoading: true,
          action : 'add',
          card_id: 0,
          form : {
            name : '',
            lastname : '',
            rol: 'Employee',
            slug_url : '',
            slug_name: '',
            status : '1',
            template_id : '1',
          },
          emailInvalid: false,
          urlweb : window.urlbSite,
          menu:[
            {name: 'About', icon:'fas fa-id-card'},
            {name: 'Pictures', icon:'fas fa-image'},
            {name: 'Contact', icon:'fas fa-share-alt'},
          ],
          selected: 'About',
          contact: [{
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fas fa-envelope',
            label: 'Email',
            val: '',
            status: 1,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fas fa-phone-alt',
            label: 'Call',
            val: '',
            status: 1,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fas fa-sms',
            label: 'SMS',
            val: '',
            status: 1,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fab fa-whatsapp',
            label: 'WhatsApp',
            val: '',
            status: 0,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fas fa-globe',
            label: 'Website',
            val: '',
            status: 0,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 1,
            icon: 'fas fa-store',
            label: 'Shop',
            val: '',
            status: 0,
            orden: 0,
          }],
          social: [{
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 2,
            icon: 'fab fa-instagram',
            label: 'Instagram',
            val: 'https://www.instagram.com/',
            status: 0,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 2,
            icon: 'fab fa-tiktok',
            label: 'TikTok',
            val: 'https://www.tiktok.com/',
            status: 0,
            orden: 0,
          }, {
            user_id : window.master_client,
            org_id : window.localorgdata,
            tipo: 2,
            icon: 'fab fa-facebook',
            label: 'Facebook',
            val: 'https://www.facebook.com/',
            status: 0,
            orden: 0,
          }],
          roles: [
            {id: 1, name :'Owner'},
            {id: 2, name :'President'},
            {id: 3, name :'Manager'},
            {id: 4, name :'Chief'},
            {id: 5, name :'Administrator'},
            {id: 6, name :'Supervisor'},
            {id: 7, name :'Master'},
            {id: 8, name :'Supervisor'},
            {id: 9, name :'Employee'},
            {id: 10, name :'Worker'},
            {id: 11, name :'Designer'},
            {id: 12, name :'Secretary'},
          ],
          apis: null,
          validError: false,
          isExistSlug: false,
          errorMsg: null,
          notOwner: false,
          pictures: {
            profile: null,
            logo: null,
            logo_active: false,
            logo_opacity: 0.8,
            cover: null,
            cover_active: false,
            cover_overlay: 50,
            cover_color: 'rgb(79 164 255)',
            profile_old: null,
            logo_old: null,
            cover_old: null,
          },
          getcolor: {
            rgba:'rgb(79 164 255)',
            r: 79,
            b: 164,
            g: 255,
            a: 1,
          },
          showPicker: false,
          colorPreview: 'rgb(79 164 255)',
          profilePreview: null,
          profileLoad: false,
          logoPreview: null,
          logoLoad: false,
          coverPreview: null,
          coverLoad: false,
          urlPic: null,
          suf_url: null,
          card_primary: 0,
          loadWrite: 0,
          slug_name:'',
          org: {
            company_name:'',
            address:'',
            city:'',
            state:'',
            zip:'',
          },
          PlanPremium: false,
          planActual: 1,
          listPremium: ['Shop'],
        }
    },
    created(){
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        // valid premium planID
        this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6);
        console.log('PlanPremium', this.PlanPremium);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.PlanPremium = true;
            // console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
        }
        this.getOrg();
      if(this.$route.name=='EdifromCard'){
        console.log('editId', this.$route.params.editId);
        this.action='edit';
        this.card_id = Number(this.$route.params.editId);
        this.getCardId(this.$route.params.editId);
      }else{
        this.isLoading=false;
        this.notOwner=true;
        this.getPrefijoUrl();
      }
    },
    computed: {
      dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    watch: {
      'form.name': function(){
        if(this.loadWrite==0){this.crearLink();}
      },
      'form.lastname': function(){
        if(this.loadWrite==0){this.crearLink();}
      },
      'slug_name': function(){
        let t =this;
        if(this.loadWrite==0){
          if(t.form.slug_name!=null && t.form.slug_name.length>0){
            t.form.slug_url = t.suf_url+'-'+t.slugUrl(t.slug_name);
            t.form.slug_name =t.slugUrl(t.slug_name);
            t.validSlug();
          }else{
            // t.crearLink();
            t.isExistSlug=true;
            console.log('isExistSlug', t.isExistSlug)
          }
        }
      },
      'form.slug_url': function(){
        let t =this;
        if(this.loadWrite==0){
          if(t.form.slug_url==null || t.form.slug_url.length==0){
            t.crearLink();
          }
        }
      },
      'suf_url': function(){
        if(this.loadWrite==0){this.crearLink();}
      },
      getcolor: function () {
        if (typeof this.getcolor == "object") {
          let c = this.getcolor.rgba;
          this.colorPreview = "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
          this.pictures.cover_color = this.colorPreview;
        }
      },
    },
    methods : {
      showPremium(){let t = this; t.$store.commit('core/session/setShowPopPremium', {show: true, data: []});},
      getRol(rol){
            return this.roles.filter(r => {
              if(Number(rol) == r.id){
                  return r;
              }
            })
        },
      crearLink(noValid){
        let t =this;
        if(t.card_primary==0){
          if(t.form.slug_url == null || t.form.slug_url.length<=2 || t.slug_name.length<=2 || t.slug_name.length!=t.form.name.length){
            console.log('loadWrite', t.loadWrite);
            let slug = '';
            slug+=(t.form.name.length>0) ? t.slugUrl(t.form.name) : '';
            slug+=(t.form.lastname.length>0) ? ((t.form.name.length>0) ? '-'+t.slugUrl(t.form.lastname) : t.slugUrl(t.form.lastname)) : '';
            t.form.slug_name = slug;
            t.slug_name = slug;
            t.form.slug_url = t.suf_url+'-'+slug;
            if(noValid==undefined){
              t.validSlug();
            }
          }
        }
      },
      getOrg(){
        let t = this;
        let getOrg = this.$store.getters['core/Org/orginf'];
        if(getOrg != null){
          console.log('getters FormInfo', getOrg);
          t.org.company_name = getOrg.bill_company_name;
          t.org.address = getOrg.com_address;
          t.org.city = getOrg.com_city;
          t.org.state = getOrg.com_state;
          t.org.zip = getOrg.com_zip_code;
        }
      },
      getPrefijoUrl(){
        let t = this;
        t.isLoading=true;
        let url = t.apis.pull(6);
        window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            socket_id: pusher.connection.socket_id
        })
        .then((response) => {
          console.log(url, response.data);
          if(response.data.status){
            t.suf_url=response.data.r.meta_value;
          }
          t.isLoading=false;
        }).catch((error) => {
            console.log(error);
            t.isLoading = false;
        });
      },
      external_link() {
        if(this.card_primary==1){
          window.open(this.urlweb + this.suf_url, "_blank");
        }else{
          if (this.form.slug_url.length > 0) {
            window.open(this.urlweb + this.form.slug_url, "_blank");
          }
        }
      },
      slugUrl(slug) {
          let str = slug;
          str = str.replace(/^\s+|\s+$/g, "");
          str = str.toLowerCase();

          var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
          var to = "aaaaaeeeeeiiiiooooouuuunc------";
          for (var i = 0, l = from.length; i < l; i++) {
              str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
          }
          str = str
              .replace(/[^a-z0-9 -]/g, "")
              .replace(/\s+/g, "-")
              .replace(/-+/g, "-");

          return str;
      },
      isDragging(t){
        console.log('move')
        return t;
      },
      checkMove: function(list){
        list = list.filter((r, idx) => {
          r.orden = idx;
          return r;
        });
        console.log('checkMove', list);
      },
      getCardId(id){
        let t = this;
        t.isLoading=true;
        let url = t.apis.pull(4);
        window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            id: id,
            socket_id: pusher.connection.socket_id
        })
        .then((response) => {
          console.log(url, response.data);
          if(response.data.card.id){
            t.fillForm(response.data, 0);
          }
          t.isLoading=false;
        }).catch((error) => {
            console.log(error);
            t.isLoading = false;
        });
      },
      validSlug(){
        let t = this;
        let url = t.apis.pull(5);
        t.slug_name=t.slugUrl(t.slug_name);
        window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            suf_url: t.suf_url,
            slug_url: t.form.slug_url,
            socket_id: pusher.connection.socket_id
        })
        .then((response) => {
          if(response.data.valid > 1){
            t.isExistSlug=true;
            t.validError=true;
            console.log('isExistSlug', t.isExistSlug)
          }
          else if(t.action=='edit' && response.data.valid == 1){
            if(t.card_id == response.data.get.id){
              t.isExistSlug=false;
              t.validError=false;
            }
            else{
              t.isExistSlug=true;
              t.validError=true;
              console.log('isExistSlug', t.isExistSlug)
            }
          }
          else if(response.data.valid == 1){
            if(t.card_id == response.data.get.id){
              t.isExistSlug=false;
              t.validError=false;
            }
            else{
              t.isExistSlug=true;
              t.validError=true;
              console.log('isExistSlug', t.isExistSlug)
            }
          }
          else{
            t.isExistSlug=false;
            t.validError=false;
          }
          if(t.slug_name==null || t.slug_name.length==0){
              t.isExistSlug=true;
              t.validError=true;
              console.log('isExistSlug', t.isExistSlug)
          }
        }).catch((error) => {
            console.log(error);
            t.isExistSlug=false;
            t.validError=false;
        });
      },
      setCard(redirect){
        let t = this;
        t.isLoading = true;
        let url = t.apis.set(0);
        if(t.action=='edit'){
          url = t.apis.set(1);
        }
        let formData = t.setFielsd();
        window.master.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
          console.log(url, response.data);
          if(response.data.status){
            t.card_id=response.data.card.id;
            t.action='edit';
            t.pictures.profile = null;
            t.pictures.logo = null;
            t.pictures.cover = null;
            if(redirect!=undefined){
              t.backListCard();
            }else{
              t.$notify({
                group: "noti",
                type: 'success',
                title: "Success",
                text: "Saved successfully",
                duration: 2000
              });
              if(t.action!='edit'){
              }
              t.fillForm(response.data);
            }
          }else{
            t.$notify({
              group: "noti",
              type: 'Error',
              title: "Fail",
              text: "Failed to save",
              duration: 2000
            });
          }
          t.isLoading = false;
        }).catch((error) => {
            console.log(error);
            t.isLoading = false;
            t.$swal.fire({
              icon: "error",
              title: "Error",
              showConfirmButton: false,
              timer: 2000,
            });
        });
      },
      fillForm(data, refresh=0){
        let t = this;
        let r = data.card;
        t.loadWrite=1;
        // t.org.company_name = r.company_name;
        t.form.name = r.name;
        t.form.lastname = r.lastname;
        t.form.rol = r.rol;
        t.form.status = r.status;
        t.card_primary = r.card_primary;
        t.form.template_id = r.template_id;
        t.form.slug_url = r.slug_url;
        t.suf_url = data.suf_url.meta_value;
        t.form.slug_name = r.slug_url.replace(t.suf_url+'-', '');
        t.slug_name = r.slug_url.replace(t.suf_url+'-', '');
        t.urlPic=data.url;
        if(refresh==1){t.crearLink(1);}
        if(r.pictures.length>0){
          let p =r.pictures[0];
          if(p.picture!=null && p.picture!=''){
            t.profilePreview = t.urlPic +'small/'+p.picture;
            t.pictures.profile= null;
            t.pictures.profile_old= p.picture;
          }
          if(p.logo!=null && p.logo!=''){
            t.logoPreview = t.urlPic +'small/'+p.logo;
            t.pictures.logo= null;
            t.pictures.logo_old= p.logo;
            t.pictures.logo_active= p.logo_active;
            t.pictures.logo_opacity= p.logo_opacity;
          }
          if(p.cover!=null && p.cover!=''){
            t.coverPreview = t.urlPic +'thumbs/'+p.cover;
            console.log('t.coverPreview', t.coverPreview)
            t.pictures.cover= null;
            t.pictures.cover_old= p.cover;
            t.pictures.cover_active= p.cover_active;
            t.pictures.cover_overlay= p.cover_overlay;
            t.pictures.cover_color= p.cover_color;
          }
        }
        if(r.contact.length>0){
          let contact = t.contact.filter(item => {
            r.contact.filter(i => {
              if(i.icon == item.icon){
                item.val = (i.val==null || i.val=='null') ? '' : i.val;
                item.status = i.status;
              }
            });
            return item;
          });
          console.log('contact', contact);
        }
        if(r.social.length>0){
          let social = t.social.filter(item => {
            r.social.filter(i => {
              if(i.icon == item.icon){
                item.val = (i.val==null || i.val=='null') ? '' : i.val;
                item.status = i.status;
              }
            });
            return item;
          });
          console.log('social', social);
        }
        let timer = (refresh==1) ? 3000 : 1000;
        setTimeout(() => {
          t.loadWrite=0;
          console.log('loadWrite', t.loadWrite);
        }, timer);
      },
      setFielsd(){
        let t = this;
        let formData = new FormData();
        formData.append("user_id", window.master_client);
        formData.append("org_id", window.localorgdata);
        formData.append("id", t.card_id);
        Object.keys(t.form).forEach((key, idx) =>{
          if(key!='slug_name'){
            formData.append('about['+key+']', t.form[key]);
          }
        })
        Object.keys(t.form).forEach((key, idx) =>{
          if(key=='slug_url'){
            formData.append(key, t.form[key]);
          }
        })
        Object.keys(t.contact).forEach((key, idx) =>{
          Object.keys(t.contact[key]).forEach((key2, idx2) =>{
            let val = (typeof t.contact[key][key2] == 'boolean') ? Number(t.contact[key][key2]) : t.contact[key][key2]
            formData.append('contact['+key+']['+key2+']', val);
          })
        })
        Object.keys(t.social).forEach((key, idx) =>{
          Object.keys(t.social[key]).forEach((key2, idx2) =>{
            let val = (typeof t.social[key][key2] == 'boolean') ? Number(t.social[key][key2]) : t.social[key][key2]
            formData.append('social['+key+']['+key2+']', val);
          })
        })
        Object.keys(t.pictures).forEach((key, idx) =>{
          if(key=='profile'){
            formData.append(key, t.pictures[key]);
          }
          else if(key=='logo'){
            formData.append(key, t.pictures[key]);
          }
          else if(key=='cover'){
            formData.append(key, t.pictures[key]);
          }else{
            let val = (typeof t.pictures[key] == 'boolean') ? Number(t.pictures[key]) : t.pictures[key]
            formData.append('pictures['+key+']', val);
          }
        })
        formData.append("socket_id", pusher.connection.socket_id);
        return formData;
      },
      backListCard(){
        this.$router.push({name: 'smartCard'});
      },
      openPicker(){
        this.showPicker = true;
        this.colorPreview =this.pictures.cover_color;
        localStorage.setItem('cover_color', this.pictures.cover_color);
      },
      okPicker(){
        this.showPicker =false;
        this.pictures.cover_color = this.colorPreview;
        localStorage.removeItem('cover_color');
      },
      cancelPicker(){
        this.showPicker =false;
        this.pictures.cover_color = localStorage.getItem('cover_color');
        this.colorPreview = localStorage.getItem('cover_color');
        localStorage.removeItem('cover_color');
      },
      loadPicProfile(event){
        let t = this;
        let file = event.target.files[0];
        console.log('loadPicProfile', file);
        if (file) {
          t.profileLoad=true;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            t.profilePreview = e.target.result;
            t.profileLoad=false;
            t.pictures.profile = file;
            t.$refs.profileImg.value = null;
          };
        } else {
          t.$refs.profileImg.value = null;
        }
      },
      loadPicLogo(event){
        let t = this;
        let file = event.target.files[0];
        console.log('loadPicLogo', file);
        if (file) {
          t.logoLoad=true;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            t.logoPreview = e.target.result;
            t.logoLoad=false;
            t.pictures.logo = file;
            t.pictures.logo_active=true;
            t.$refs.logoImg.value = null;
          };
        } else {
          t.$refs.logoImg.value = null;
        }
      },
      loadPicCover(event){
        let t = this;
        let file = event.target.files[0];
        console.log('loadPicCover', file);
        if (file) {
          t.coverLoad=true;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            t.coverPreview = e.target.result;
            t.coverLoad=false;
            t.pictures.cover = file;
            t.pictures.cover_active=true;
            t.$refs.coverImg.value = null;
          };
        } else {
          t.$refs.coverImg.value = null;
        }
      },
    },
}
</script>
