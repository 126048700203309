import RoofCalLayout from '@/apps/RoofCal/Layouts/RoofCalLayout.vue'
import RoofCalDash from '@/apps/RoofCal/views/RoofCalDash.vue'
import RoofCalLeads from '@/apps/RoofCal/views/RoofCalLeads.vue'
import RoofCalSetup from '@/apps/RoofCal/views/RoofCalSetup.vue'
import RoofCalZipSetup from '@/apps/RoofCal/views/setup/RoofCalZipSetup.vue'
import RoofCalQuest from '@/apps/RoofCal/views/setup/RoofCalQuest.vue'
import RoofCalLog from '@/apps/RoofCal/views/RoofCalLog.vue'
export default [
	{
		path: '/RoofCal',
		folder: RoofCalLayout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'RoofCal',
				folder: RoofCalDash
			},
			{
				path: '/RoofCal/leads',
				name:'RoofCalLeads',
				folder: RoofCalLeads
			},
			{
				path: '/RoofCal/setup',
				name:'RoofCalSetup',
				folder: RoofCalSetup
			},
			{
				path: '/RoofCal/zip',
				name:'RoofCalZipSetup',
				folder: RoofCalZipSetup
			},
			{
				path: '/RoofCal/page-traffic',
				name:'RoofCalLog',
				folder: RoofCalLog
			},
			{
				path: '/RoofCal/quest-answers',
				name:'RoofCalQuest',
				folder: RoofCalQuest
			},
		]
	}

]
