let jobstoCompleted = function(id, item) {
	// this.isLoading = true;
	let t = this;
	if(t.PlanPremium){
		window.master.post("jobs/toComplete", {
			user_id: window.master_client,
			org_id: window.localorgdata,
			id_jobs: id,
			id_invoice:item.id,
			id_customer:item.customer_id,
		})
		.then((response) => {
			console.log(response.data);
			// this.isLoading = false;
			if (response.data.status) {
				// this.searchFilter();
				this.$notify({
					group: "noti",
					type: "success",
					title: "Job Complete!",
					text: "Successful",
				});
			}else {	
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success | 2=info | 3=error | 4=loading | 5=warning
					title: "Error",
					text: "The job could not be completed, try later or try directly from the Jobs app.",
					show: true,
					duration: 20000
				});	
			}
		})
		.catch((error) => {
			// this.isLoading = false;
			console.log(error);
			this.$store.commit("core/setNotiPush", {
				group: "notiPush",
				type: '3', // 1=success | 2=info | 3=error | 4=loading | 5=warning
				title: "Error",
				text: "The job could not be completed, try later or try directly from the Jobs app.",
				show: true,
				duration: 20000
			});	
		});
	}else{
		t.llamarPopPremium();
	}
};
export default jobstoCompleted;
