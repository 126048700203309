import {variables, components, methods, watch, computed} from './js/';
export default {
	name: 'DashJobs',
	data(){
		return variables;
	},
	components,
	methods,
	watch,
	computed,
	created(){ 
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.dash;
        this.listTaks = [];
        this.listTaksResult = [];
        this.isLoading = true;
        this.init(); // inicializando valores default o globales
        this.resetContadorPaginador();
        this.validAdminApp();
        this.$store.commit("ModApps/jobs/set_typeVisor", 1);
        this.getExtrasYSettingAll().then((response) => {
            this.$forceUpdate();
            this.getJobsDash();
            //this.getJobsAllSetting();
        });
	},
    updated(){
        //console.log('update');
        this.calcAutoHeigthTask();
    },
	mounted() {
		document.styleSheets[0].addRule(
			"div.op-loading",
			"position: inherit !important"
		);
		let t = this;
		window.onscroll = () => {
			// console.log('scroll', window.pageYOffset + ' >= ' + t.heightPage);
			if (
				window.pageYOffset >= t.heightPage &&
				localStorage.getItem("eje_pagination") == 0 &&
				!t.searchActivate
				) 
			{
				// console.log('scroll t.next_page_url', t.next_page_url);
				if (t.next_page_url != null && t.next_page_url.length) {
					t.nextPage = true;
					localStorage.setItem("eje_pagination", 1);
					localStorage.setItem("maxScrollY", window.pageYOffset);
					t.getJobsDash();
				}
			}
		};
	},
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
};
