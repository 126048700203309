<template>
    <div class="page-breadcrumb">
        <div class="row">
            <div class="col-5 align-self-center text-left">
                <h4 class="page-title">Setup / {{ name }}</h4>
            </div>
            <div class="col-7 align-self-center">
                <div class="d-flex align-items-right justify-content-end">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link
                                    :to="{ name: 'JobsSetup' }"
                                    class="btn btn-info btn-sm mr-2"
                                    ><i class="fas fa-arrow-left"></i> Back</router-link
                                >
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "setupHeader",
    props: {
        name: { type: String, required: true },
    },
    data() {
        return {};
    },
};
</script>
<style lang="css" scoped></style>
