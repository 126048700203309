export default {
    SetActive(context, val){
        context.commit('Active', val);  
    },
    getListWebs(context, val){
        return new Promise((resolve, reject) => {
            window.master
            .post('sites/listIndex', {
                user_id: window.master_client,
                org_id: window.localorgdata,
            })
            .then((response) => {
                if(response.data.status){
                    context.commit('set_webs', response.data.r);
                    resolve(true);
                }else{
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                reject(false);
            });
        });
    },
}
