<template>
	<div>
		<div class="card">
			<div class="card-header">
				Add Subcontractor/Hours
			</div>
			<form @submit.prevent="actionSubTimePdfCheck">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<table border="0" width="85%">
								<tr>
									<td valign="top">Subcontractor</td>
									<td valign="top">:</td>
									<td valign="top">
										<strong>
											{{ timecheck.subcontractor_name }}
										</strong>
									</td>
								</tr>
								<tr>
									<td valign="top">Select Project (Optional)</td>
									<td valign="top">:</td>
									<td valign="top">
										- Choose a Project -
										<!-- <model-select :options="horas" v-model="fs.proyect" placeholder="- Choose a Project -"></model-select> -->
									</td>
								</tr>
								<tr>
									<td valign="top">Check Date</td>
									<td valign="top">:</td>
									<td valign="top">
										<datetime
											required
											type="datetime"
											v-model="fs.fecini"
											:format="{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											}"
											use12-hour
											value-zone="America/New_York"
											input-class="form-control jobdate"
										></datetime>
									</td>
								</tr>
								<tr>
									<td valign="top">Pay to the order of</td>
									<td valign="top">:</td>
									<td valign="top">
										{{ timecheck.subcontractor_name }}
									</td>
								</tr>
								<tr>
									<td valign="top">Amount $</td>
									<td valign="top">:</td>
									<td valign="top">{{ timecheck.payment }}</td>
								</tr>
								<tr>
									<td valign="top">Memo</td>
									<td valign="top">:</td>
									<td valign="top">
										<textarea
											name="observ_1"
											id="observ_1"
											autocomplete="off"
											rows="5"
											class="input-block-level"
											cols="60"
											v-model="fs.fromTo"
										></textarea>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div style="width: 100%; text-align: right">
						<button
							type="submit"
							class="btn btn-success"
							:disabled="isDisabled"
						>
							Open PDF
						</button>
					</div>
				</div>
			</form>
		</div>	
	</div>
</template>
<script>
export default {
	components: {
	},
	name: "SubFormTimesheetPdf",
	data () {
		return {
			isDisabled: false,
            fs: {
                user_id: window.localorgdata,
                times_id: 0,
                id: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
                fromTo: "",
                subcontractor_name: "",
                payment: 0,
                proyect: "",
            },
			timecheck: [],           
		};
	},
	created () {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.timecheck = r.timecheck;
			this.fs.id = r.fs.id
			this.fs.times_id = r.fs.times_id
        },
        actionSubTimePdfCheck() {
            window.open(
                window.urlbase +
                    "api/v2/timesheet/open-pdf-check-view/numdoc/" +
                    this.fs.user_id +
                    "/" +
                    this.fs.id +
                    "/" +
                    this.fs.times_id,
                "_blank"
            );
        },		                      
    },
    watch: { },
};
</script>
<style lang="css" scoped>
.avisoCompany {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 0px 80px;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
</style>
