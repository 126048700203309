<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting Tax</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <div class="table-responsive">
                                <Loader v-if="isLoadingStates"></Loader>
                                <table v-else class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">STATE</th>
                                            <th scope="col">TAX</th>
                                            <th scope="col">
                                                <button
                                                    class="btn btn-success btn-sm mr-2 float-right"
                                                    @click="MetAddNewTaxBtn"
                                                >
                                                    <i class="fas fa-plus"></i> New Tax
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-model="TaxesLists"
                                        tag="tbody"
                                        class="tbody"
                                        v-bind="dragOptions"
                                        @change="checkMove"
                                        @start="isDragging = true"
                                        @end="isDragging = false"
                                        handle=".handle"
                                    >
                                        <tr
                                            v-for="taxeitem in TaxesLists"
                                            :key="taxeitem.id"
                                            class="handle"
                                        >
                                            <td scope="row" style="position: relative">
                                                <span style="margin-left: 15px">{{
                                                    taxeitem.statename
                                                }}</span>
                                                <div class="btnmove">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </div>
                                            </td>
                                            <td>{{ taxeitem.name }} %</td>
                                            <td>
                                                <button
                                                    class="btn btn-secondary btn-sm float-right"
                                                    @click="
                                                        MetEditStateBtn(
                                                            taxeitem.id,
                                                            taxeitem.name,
                                                            taxeitem.state_id,
                                                            taxeitem.status
                                                        )
                                                    "
                                                >
                                                    <i class="fas fa-edit"></i></button
                                                ><button
                                                    class="btn btn-danger btn-sm mr-2 float-right"
                                                    @click="deleteTaxBtn(taxeitem.id)"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </draggable>
                                </table>
                                <div v-if="NoRecordTaxe == true" class="text-center">
                                    no record
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>

        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral> 
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Loader from '@/core/components/shared/Loader'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import StartInvoice from '@/apps/billing/components/setting/StartInvoice'
import formTaxes from '@/apps/billing/components/setting/formTaxes'
export default {
    components: {
        Loader,
        draggable,
        modalLateral,
        formTaxes
    },
    data () {
        return {
            isLoadingStates: false,
            TaxesLists: [],
            FormTax: {
                btnNameTax: "ADD",
                ActionTaxe: "AddTaxe",
                idtax: null,
                nametaxes: null,
                state_id: 0,
                status: 1,
            },
            m: {
                show: false,
                component: '',
                data: []
            },
            NoRecordTaxe: false,
        };
    },
    mounted () {
        this.getTaxes();
    },
    methods: {
        getTaxes() {
            this.isLoadingStates = true;
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.TaxesLists = response.data;
                    if (this.TaxesLists.length == 0) {
                        this.NoRecordTaxe = true;
                    }
                    this.isLoadingStates = false;
                })
                .catch((error) => console.log(error));
        },
        deleteTaxBtn(idtax) {
            console.log("delete tax");
            this.isLoadingStates = true;
            this.$swal({
                title: "Delete this Tax?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("deletetax/" + idtax, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log("delete " + response.data);
                            this.isLoadingStates = false;
                            if (!response.data.success) {
                                this.$swal.fire({
                                    icon: "warning",
                                    title: "Associated taxes",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.getTaxes();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Deleted Taxes Successful",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            }
                        })
                        .catch((error) =>
                            console.log(error, (this.isLoadingStates = false))
                        );
                }
            });
            this.isLoadingStates = false;
        },
        MetDeleteTaxBtn(idtax) {
            this.isLoadingStates = true;
            this.$swal({
                title: "Delete this Taxe?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .post("taxes/", {
                            user_id: window.localuserdata,
                            idtax: idtax,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoadingStates = false;
                            this.getTaxes();
                            this.$notify({
                                group: "noti",
                                title: "Deleted",
                                type: "success",
                                text: "Deleted Taxes Successful",
                            });
                        })
                        .catch((error) =>
                            console.log(error, (this.isLoadingStates = false))
                        );
                }
            });
            this.isLoadingStates = false;
        },
        MetEditStateBtn(idtax, name, idstate, status) {
            this.FormTax = {
                btnNameTax: "UPDATE",
                ActionTaxe: "UpdateTaxe",
                nametaxes: name,
                state_id: idstate,
                idtax: idtax,
                status: status,
            }
            let t = this
            this.m = {
                show: true,
                component: 'formTaxes',
                data: {FormTax:t.FormTax}
            }  
            this.$store.commit('core/setOpenModal', this.m);             
        },        
        MetAddNewTaxBtn() {
            this.FormTax = {
                btnNameTax: "ADD",
                ActionTaxe: "AddTaxe",
                nametaxes:"",
                state_id: 0,
                status: 1,
            }
            let t = this
            this.m = {
                show: true,
                component: 'formTaxes',
                data: {FormTax:t.FormTax}
            }  
            this.$store.commit('core/setOpenModal', this.m);             
        },
        checkMove() {
            this.TaxesLists.map((taxes, index) => {
                taxes.orderitem = index + 1;
            });
            window.billing
                .put("taxes/order/" + window.localuserdata, {
                    user_id: window.localuserdata,
                    taxesall: this.TaxesLists,
                })
                .then((response) => {
                    console.log(response.data);
                    this.getTaxes();
                })
                .catch((error) => console.log(error, (this.isLoading = false)));
        },
    },
    watch: {
        'getOpenModal': function() {
            // console.log('timesheet getOpenModal', this.getOpenModal.show);
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.getTaxes();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        }
    },    
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.btnmove {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
@media (min-width: 1279px) {
    .ctrlbtn {
        width:100px !important;
    }
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}
</style>
