<template>
    <div class="container-grid-gallery">
        <section class="grid-gallery">
            <ul>
                <li>
                    <article>
                        <header>
                            <nav>
                                <ul>
                                    <li>
                                        <button><i class="fas fa-arrows-alt"></i></button>
                                    </li>
                                </ul>
                            </nav>
                        </header>
                        <a href="https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0058.jpg" @click.prevent="$emit('openImg')" class="galley-item-img">
                            <div class="overlay">
                                <div class="gallery-zoom-img"><i class="fas fa-search-plus"></i></div>
                                <div class="gallery-info">
                                    <label for="selectCover1"><input type="radio" id="selectCover1" name="selectCover"></label>
                                    <span>Name image</span>
                                </div>
                            </div>
                            <picture>
                                <!-- <source media="(min-width:767px)" srcset="https://mainatorestoration.com/imgcms/galeria/696249477613/small/dji0058.jpg"> -->
                                <img class="gallery-image" src="https://mainatorestoration.com/imgcms/galeria/696249477613/thumbs/dji0058.jpg" alt="dji0058.jpg" width="450" height="253" loading="lazy">
                            </picture>
                        </a>
                        <footer>
                            <nav>
                                <ul>
                                    <li><button><i class="fas fa-pencil-alt"></i></button></li>
                                    <li><button><i class="fas fa-info"></i></button></li>
                                    <li><button><i class="fas fa-undo-alt"></i></button></li>
                                    <li><button><i class="fas fa-redo-alt"></i></button></li>
                                    <li><button><i class="fas fa-times"></i></button></li>
                                </ul>
                            </nav>
                        </footer>
                    </article>
                </li>
                <li>
                    <article>
                        <header>
                            <nav>
                                <ul>
                                    <li>
                                        <button><i class="fas fa-arrows-alt"></i></button>
                                    </li>
                                </ul>
                            </nav>
                        </header>
                        <a href="https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0495.jpg" @click.prevent="$emit('openImg')" class="galley-item-img">
                            <div class="overlay">
                                <div class="gallery-zoom-img"><i class="fas fa-search-plus"></i></div>
                                <div class="gallery-info">
                                    <label for="selectCover2"><input type="radio" id="selectCover2" name="selectCover"></label>
                                    <span>Name image</span>
                                </div>
                            </div>
                            <picture>
                                <!-- <source media="(min-width:767px)" srcset="https://mainatorestoration.com/imgcms/galeria/696249477613/small/dji0495.jpg"> -->
                                <img class="gallery-image" src="https://mainatorestoration.com/imgcms/galeria/696249477613/thumbs/dji0495.jpg" alt="dji0495.jpg" width="450" height="253" loading="lazy">
                            </picture>
                        </a>
                        <footer>
                            <nav>
                                <ul>
                                    <li><button><i class="fas fa-pencil-alt"></i></button></li>
                                    <li><button><i class="fas fa-info"></i></button></li>
                                    <li><button><i class="fas fa-undo-alt"></i></button></li>
                                    <li><button><i class="fas fa-redo-alt"></i></button></li>
                                    <li><button><i class="fas fa-times"></i></button></li>
                                </ul>
                            </nav>
                        </footer>
                    </article>
                </li>
                <li>
                    <article>
                        <header>
                            <nav>
                                <ul>
                                    <li>
                                        <button><i class="fas fa-arrows-alt"></i></button>
                                    </li>
                                </ul>
                            </nav>
                        </header>
                        <a href="https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0343.jpg" @click.prevent="$emit('openImg')" class="galley-item-img">
                            <div class="overlay">
                                <div class="gallery-zoom-img"><i class="fas fa-search-plus"></i></div>
                                <div class="gallery-info">
                                    <label for="selectCover3"><input type="radio" id="selectCover3" name="selectCover"></label>
                                    <span>Name image</span>
                                </div>
                            </div>
                            <picture>
                                <!-- <source media="(min-width:767px)" srcset="https://mainatorestoration.com/imgcms/galeria/696249477613/small/dji0343.jpg"> -->
                                <img class="gallery-image" src="https://mainatorestoration.com/imgcms/galeria/696249477613/thumbs/dji0343.jpg" alt="dji0343.jpg" width="450" height="253" loading="lazy">
                            </picture>
                        </a>
                        <footer>
                            <nav>
                                <ul>
                                    <li><button><i class="fas fa-pencil-alt"></i></button></li>
                                    <li><button><i class="fas fa-info"></i></button></li>
                                    <li><button><i class="fas fa-undo-alt"></i></button></li>
                                    <li><button><i class="fas fa-redo-alt"></i></button></li>
                                    <li><button><i class="fas fa-times"></i></button></li>
                                </ul>
                            </nav>
                        </footer>
                    </article>
                </li>
                <li>
                    <article>
                        <header>
                            <nav>
                                <ul>
                                    <li>
                                        <button><i class="fas fa-arrows-alt"></i></button>
                                    </li>
                                </ul>
                            </nav>
                        </header>
                        <a href="https://mainatorestoration.com/imgcms/editor/images/Depositphotos_23579053_original.jpg" @click.prevent="$emit('openImg')" class="galley-item-img">
                            <div class="overlay">
                                <div class="gallery-zoom-img"><i class="fas fa-search-plus"></i></div>
                                <div class="gallery-info">
                                    <label for="selectCover4"><input type="radio" id="selectCover4" name="selectCover"></label>
                                    <span>Name image</span>
                                </div>
                            </div>
                            <picture>
                                <!-- <source media="(min-width:767px)" srcset="https://mainatorestoration.com/imgcms/editor/images/Depositphotos_23579053_original.jpg"> -->
                                <img class="gallery-image" src="https://mainatorestoration.com/imgcms/editor/images/Depositphotos_23579053_original.jpg" alt="Depositphotos_23579053_original.jpg" width="450" height="253" loading="lazy">
                            </picture>
                        </a>
                        <footer>
                            <nav>
                                <ul>
                                    <li><button><i class="fas fa-pencil-alt"></i></button></li>
                                    <li><button><i class="fas fa-info"></i></button></li>
                                    <li><button><i class="fas fa-undo-alt"></i></button></li>
                                    <li><button><i class="fas fa-redo-alt"></i></button></li>
                                    <li><button><i class="fas fa-times"></i></button></li>
                                </ul>
                            </nav>
                        </footer>
                    </article>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
    export default {
        methods: {
        }
    }
</script>
<style lang="scss" scoped>
</style>
