<template>
    <div>
        <div class="card">
            <div class="card-header">
                Add Subcontractor/Hours
            </div>
            <form @submit.prevent="saveSubTimesheet">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Subcontractor: </label>
                            <model-select
                                :options="sub"
                                v-model="f.nameSub"
                                @input="handleOnInput"
                                placeholder="Choose a Customer"
                                class="form-control"
                            ></model-select>
                        </div>
                        <div>
                            <table
                                width="90%"
                                class="table"
                                style="margin: 10px auto 0px 0px"
                            >
                                <tbody>
                                    <tr v-for="(i, index) in f.interval" :key="index">
                                        <td width="100px">{{ i.wfecha }}</td>
                                        <td>
                                            <model-select
                                                :options="horas"
                                                v-model="i.horas"
                                                placeholder="Select"
                                            ></model-select>
                                        </td>
                                        <td>Rate</td>
                                        <td width="150px">
                                            <input
                                                type="text"
                                                v-model="i.rate"
                                                class="form-control"
                                            />
                                        </td>
                                        <td>Task</td>
                                        <td width="40%">
                                            <model-select
                                                :options="task"
                                                v-model="i.task"
                                                placeholder="Select"
                                            ></model-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style="width: 100%; text-align: right">
                        <button
                            type="submit"
                            class="btn btn-success"
                            :disabled="isDisabled"
                        >
                            <i class="fas fa-save"></i> SAVE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: {
        ModelSelect,
    },
    name: "SubFormTimesheet",
    data () {
        return {
            isDisabled:false,
            f: {
                id: 0,
                times_id: 0,
                user_id: window.localorgdata,
                billing: 1,
                nameSub: 0,
                interval: [],
            },            
            fs: {
                user_id: window.localorgdata,
                times_id: 0,
                id: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
                fromTo: "",
                subcontractor_name: "",
                payment: 0,
                proyect: "",
            },   
            sub: [],       
            horas: [],
            task: [],
            TimesheetSubList: []
        };
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.f = r.f;
            this.fs = r.fs;
            this.sub = r.sub; 
            this.horas = r.horas;
            this.task = r.task;
            this.TimesheetSubList = r.TimesheetSubList;          
        },
        saveSubTimesheet() {
            let cam;
            if (this.f.id > 0) {
                cam = false;
            } else {
                cam = this.validate();
            }
            if (cam === true) {
                this.$swal.fire({
                    icon: "warning",
                    title: "User exists",
                    showConfirmButton: false,
                    timer: 4000,
                });
            } else {
                this.$store.commit('set_isLoading', true);
                window.billing
                    .post("subtimesheet/form", this.f)
                    .then((response) => {
                        // console.log(response.data);
                        this.$store.commit('set_isLoading', false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});
                        // this.$swal.fire({
                        // 	icon: 'success',
                        // 	title: 'Successfully Save!',
                        // 	showConfirmButton: false,
                        // 	timer: 4000
                        // });
                        this.refresTimesheet();
                        this.closeModal();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$store.commit('set_isLoading', false);
                    });
            }
        },
        handleOnInput($event) {
            let sub = this.sub;
            let a = [];
            for (const i in sub) {
                if (sub[i].value === $event) {
                    a.push({ rate: sub[i].default_rate });
                }
            }
            let r = this.f.interval;
            let u = [];
            for (const i in r) {
                let horas = 0;
                let task = 0;
                if (r[i].horas !== undefined) {
                    horas = r[i].horas;
                }
                if (r[i].task !== undefined) {
                    task = r[i].task;
                }
                u.push({
                    fecha: r[i].fecha,
                    horas: horas,
                    rate: a[0].rate,
                    task: task,
                    wfecha: r[i].wfecha,
                });
            }
            this.f.interval = [];
            this.f.interval = u;
        }, 
        closeModal() {
            this.fs.times_id = 0;
            this.fs.id = 0;
            this.fs.fecini =
                (new Date().toISOString() + "").slice(0, 11) +
                new Date().toTimeString().slice(0, 8);
            this.fs.fromTo = "";
            this.fs.subcontractor_name = "";
            this.fs.payment = 0;
            this.fs.proyect = "";
            this.f.interval = this.interval;
            this.f.nameSub = 0;
        },
        refresTimesheet() {
            this.f.id = 0;
            this.f.times_id = 0;
            this.f.nameSub = 0;
            this.f.interval = [];
            this.TimesheetSubList = [];
            this.TimesheetSubListTime = [];
            this.interval = [];
            this.task = [];
            this.StotalHoras = 0;
            this.StotalPagos = 0;
        },
        validate() {
            let nameSub = this.f.nameSub;
            let y = this.TimesheetSubList;
            let r = y.length;
            let u = [];
            if (r > 0) {
                for (const i in y) {
                    if (nameSub === y[i].subcontractor_id) {
                        u.push({ Boolean: true });
                    } else {
                        u.push({ Boolean: false });
                    }
                }
                return u[0].Boolean;
            } else {
                return false;
            }
        },                                                    
    },
    watch: {      
    },
};
</script>

<style lang="css" scoped>
.avisoCompany {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 80px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
