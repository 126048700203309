import langMarketea from '@/apps/Marketea/lang/';
import urlApis from '@/apps/Marketea/apis/';
export default {
	langMarketea: langMarketea,
	urlApis: urlApis,
	access: false,
	plan_id: (localStorage.getItem('bSitePlanId')!=null) ? JSON.parse(localStorage.getItem('bSitePlanId')) : 8, //free x default
	reloadPreview: false,
	premiumProfile: false,
	planesPro: [6, 7, 10, 11, 12],
	ploadProgress: 0,
	builtForm:[],
	formBuilt:[],
	itemSalesAppointmente:(localStorage.getItem('itemSalesAppointmente')!=null) ? JSON.parse(localStorage.getItem('itemSalesAppointmente')) : [],
	editOrgUrl: 0,
	customCssStorage:[],
	desingStorage:[],
	editorStorage:[],
	asideStorage:[],
	settupStorage:[],
	bsite_navtop: (localStorage.getItem('bsite_navtop')!=null) ? JSON.parse(localStorage.getItem('bsite_navtop')) : [],
	bsite_listcard: (localStorage.getItem('bsite_listcard')!=null) ? JSON.parse(localStorage.getItem('bsite_listcard')) : [],
	bsite_templates: (localStorage.getItem('bsite_templates')!=null) ? JSON.parse(localStorage.getItem('bsite_templates')) : [],
	bsite_contact: (localStorage.getItem('bsite_contact')!=null) ? JSON.parse(localStorage.getItem('bsite_contact')) : [],
	bsite_social: (localStorage.getItem('bsite_social')!=null) ? JSON.parse(localStorage.getItem('bsite_social')) : [],
	bsite_actions: (localStorage.getItem('bsite_actions')!=null) ? JSON.parse(localStorage.getItem('bsite_actions')) : [],
	bsite_cardItem: (localStorage.getItem('bsite_cardItem')!=null) ? JSON.parse(localStorage.getItem('bsite_cardItem')) : null,
	bsite_cardUrlPic: (localStorage.getItem('bsite_cardUrlPic')!=null) ? JSON.parse(localStorage.getItem('bsite_cardUrlPic')) : null,
	bsite_suf_url: (localStorage.getItem('bsite_suf_url')!=null) ? JSON.parse(localStorage.getItem('bsite_suf_url')) : null,
	siteContent: (localStorage.getItem('siteContent')!=null) ? JSON.parse(localStorage.getItem('siteContent')) : [],
	pluginList: [],
	bmenu_listCat: [],
	bmenu_catActive: null,
	bmenu_currency: '$',
	widget_listCat: null,
	widget_categoryMain: null,
	widget_urlMedia: null,
	widget_path_base: null,
}
