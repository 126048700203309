<template>
    <div>
        <div class="card-header d-flex" style="position: relative; align-items: center">
            <span style="font-size: 18px">Tags Default:</span>
        </div>
        <Loader v-if="isLoading"></Loader>
        <div v-else>
            <form @submit.prevent="setJobsSetting">
                <div class="form-general">
                    <div
                        class="form-group"
                        style="padding: 0px;
                            padding-right: 20px;
                            margin-top: 10px;
                            margin-left: 20px;
                        ">
                        <multiselect
                                    v-model="id_tags"
                                    tag-placeholder="Search a tag"
                                    placeholder="Search a tag"
                                    label="name"
                                    track-by="id"
                                    :options="tagsList"
                                    :multiple="true"
                                    :taggable="true"
                                    class="form-control"
                                ></multiselect>
                        <br /><br />
                        <button type="submit"
                            class="btn btn-primary"
                            :disabled="btnSave">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "tagsDefault",
    components: {
        Loader,
    },
    data() {
        return {
            btnSave: false,
            isLoading: false,
            id_tags: [],
            tagsList: [],
            f: {
                user_id: window.localAccountID, // usuario segun sesion
                org_id: window.localorgdata,
                meta_value: '',
                meta_key: "tagsDefault",
            },
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.setup;        
        this.getTags();
        // console.log('tagsList', this.tagsList);
    },
    methods: {
        getTags(){
            let r = this.$store.getters["ModApps/jobs/get_menuTags"];
            this.tagsList = [];
            for (let i in r) {
                this.tagsList.push({ id: r[i].id, name: r[i].name });
            }
            this.getJobsSetting();
        },
        getJobsSetting() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            let url = this.apis.getSetting()
            window.master.post(url, t.f)
            .then((response) => {
                    let r = response.data.result;
                    console.log(r);
                    t.id_tags = [];
                    if(r && r.meta_value){
                        let tagsID = r.meta_value.split(',');
                        for (let i in t.tagsList) {
                           if(t.tagsList[i]){
                                for (let j in tagsID) {
                                    if(tagsID[j] == t.tagsList[i].id){
                                        t.id_tags.push(t.tagsList[i]);
                                    }
                                }
                            }
                        }
                    }
                    t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                    console.log(error);
                    t.$store.commit("core/setLoadingModal", false);
            });
        }, 
        setJobsSetting() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            let url = this.apis.editSetting()
            let val = [];
            for (let i in t.id_tags) {
               if(t.id_tags[i]){
                    val.push(t.id_tags[i].id);
               }
            }
            t.f.meta_value = val.toString();
            window.master.post(url, t.f)
            .then((response) => {
                    let r = response.data.result;
                    console.log(r);
                    t.$store.commit("core/setLoadingModal", false);
                    t.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '1', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                        title: "Success",
                        text: "",
                        show: true,
                        duration: 2000
                    });
                    t.$parent.close();
            })
            .catch((error) => {
                    console.log(error);
                    t.$store.commit("core/setLoadingModal", false);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
label {
    display: inline-block;
    margin-bottom: 0.1rem;
}
.form-group {
    margin-bottom: 1rem;
}
label.c-input.c-radio {
    padding: 0 10px;
}
</style>
