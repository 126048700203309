<template>
    <div class="main-nav-category">
        <nav class="nav-category">
            <ul class="menu-categores">
                <li v-for="(i, idx) in listMain" :key="idx">
                    <a :href="'#section-'+i.id" :title="i.name_cat" :data-id="i.id"
                    :class="[(menuCatActivo.id==i.id) ? 'active' : '', 'item-link-'+i.id]" @click.prevent="showmenu(i)">{{ i.name_cat }}</a>
                </li>
            </ul>
            <span class="indicador" id="indicador"></span>
        </nav>
    </div>
</template>
<script>
export default {
    props: ['listCat', 'menuCatActivo'],
    data(){
        return {
            listMain: [],
            menuActivo: null,
        };
    },
    created(){
        let t = this;
        t.menuActivo = t.menuCatActivo;
        console.log('nav created t.menuActivo', t.menuActivo);
    },
    mounted(){
        let t = this;
        t.listMain = t.listCat;
        setTimeout(() => {
            t.$emit('liOffsetWidth');
        }, 500);
    },
    watch: {
        listCat: function(newVal, oldVal){
            let t = this;
            t.listMain = newVal;
        },
        menuCatActivo: function(newVal, oldVal){
            let t = this;
            console.log('nav watch t.menuActivo', newVal);
            t.menuActivo = newVal;
            t.showmenu(t.menuActivo);
        },
        listMain(){
            let t = this;
            t.showmenu(t.menuActivo);
        },
    },
    methods: {
        showmenu(i){
            let t = this;
            t.$emit('changeCatActive', i);
            setTimeout(() => {
                t.$emit('clickItem', i.id);
            }, 100);
        },
    }
}
</script>
<style lang="scss" scoped></style>
