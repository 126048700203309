<template>
    <div class="container profile">
        <div class="row">
            <div class="col-6 offset-md-3">
                <div class="prof_title text-center">
                    <h4>Billing Information</h4>
                    <p>Manage your billing details and contact information here.</p>
                </div>
                <div class="card" style="border-top: 4px solid #a1e0ae">
                    <div class="icontri"></div>
                    <div class="card-body">
                        <form @submit.prevent="editProfile">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="">Company name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_company_name"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="">First name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_firstname"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Last name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_lastname"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Address</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_address"
                                />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputCity">City</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_city"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputCity">State</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_state"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="">Zip code</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_zip_code"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Cell Phone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="p.bill_telephone"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Website</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_website"
                                />
                            </div>
                            <button type="submit" class="btn btn-primary float-right">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Profile',
    data () {
        return {
            p: {
                user_id: window.localAccountID,
                bill_company_name: '',
                bill_firstname: '',
                bill_lastname: '',
                bill_address: '',
                bill_city: '',
                bill_state: '',
                bill_zip_code: '',
                bill_telephone: '',
                bill_website: ''
            }
        }
    },
    created () {
        this.$store.dispatch('core/session/validTypeUser').then((r) => {
            if (r) {
                this.getProfile()
            } else {
                this.$router.push({ name: 'Home' })
            }
        })
    },
    methods: {
        getProfile () {
            let url = '/billinginfo/' + window.localAccountID;
            window.master
                .get(url)
                .then((response) => {
                    const e = response.data.result
                    if (e != null) {
                        this.p = e
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        editProfile () {
            const url = '/editbillinginfo';
            window.master
                .post(url, this.p)
                .then((response) => {
                    console.log(response.data)
                    this.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                        title: "Update",
                        text: "Profile Successful.",
                        show: true,
                        duration: 5000
                    });
                })
                .catch((error) => {
                    console.log(error)
                    this.$store.commit("core/setNotiPush", {
                            group: "notiPush",
                            type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                            title: "Error",
                            text: "updating data.",
                            show: true,
                            duration: 10000
                        });
                })
        }
    }
};
</script>
<style lang="css" scoped>
.icontri {
    position: absolute;
    top: -21px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #a1e0ae;
    transform: translateX(-50%);
}
.prof_title {
    display: block;
    font-size: 14px;
}
.prof_title h4 {
    font-weight: 100;
    font-size: 24px;
    text-align: center;
}
.container.profile {
    margin-top: 60px;
}
.profile label {
    display: block;
    margin-bottom: 0.1rem;
}
</style>
