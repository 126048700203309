let addToDoList = function() {
    console.log(this.templateValue);
    if (this.templateValue > 0) {
        this.btnToDoList = true;
        let url = this.apis.addToDoList(this.templateValue)
        window.master
            .get(url)
            .then((response) => {
                let taks = response.data.taks;
                let d = this.get_todolist[0].data;
                let r = d[0].lineNew;
                for (let i in taks) {
                    let lineNew = {
                        id: taks[i].id,
                        name: taks[i].name,
                        status: 1,
                        statusList: r.statusList,
                    };
                    this.f.taks.push(lineNew);
                    this.templateValue = 0;
                }
                this.btnToDoList = false;
            })
            .catch((error) => {
                console.log(error);
                this.btnToDoList = false;
            });
    }
};
export default addToDoList;
