<template>	
	<div>
		<div class="container-progress" v-if="progress < 100 && progress > 1">
			<div class="progress">
				<div v-if="progress <= 10" class="progress-bar bg-warning" :style="'width:'+ progress+'%'" ></div>
				<div v-else-if="progress > 10 && progress <= 80" class="progress-bar bg-info" :style="'width:'+ progress+'%'" ></div>
				<div v-else class="progress-bar bg-success" :style="'width:'+ progress+'%'" ></div>
			</div>
		</div>
		<div  class="container-progress" v-else-if="loading && progress == 100">Loading...</div>
	  	<div class="op-loading" v-if="loading"></div>
  	</div>
</template>
<script>
    export default {
        props: {
            getProgress: {
                type: [Number, String],
                default: () => 0
            },
            getLoading: {
                type: Boolean,
                default: () => true
            }
        },
        data() {
            return {
            	progress: 0,
                loading: true,
            }
        },
        create(){
            this.progress=this.getProgress;
            this.loading=this.getLoading;
            console.log('create', this.loading)
        },
        watch: {
        	"getProgress": function(){
        		this.progress=this.getProgress;
        	},
            "getLoading": function(){
                console.log('getLoading', this.getLoading)
                this.loading=this.getLoading;
            }
        }
    };
</script>
<style lang="scss" scoped>
	
.container-progress {
    text-align: center;
    font-size: 16px;    
    top: 0px;
    width: 100%;
}
</style>
