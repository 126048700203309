<template>
    <div class="row">
            <div class="col-12">
                <GChart
                    :settings="{
                        packages: ['corechart',],
                    }"
                    :type="$parent.typeChart"
                    :data="$parent.chartData"
                    :options="$parent.chartOptions"
                    :resizeDebounce="500"
                ></GChart>
                <!-- <GChart
                    :settings="{packages: ['corechart']}"
                    type="AreaChart"
                    :data="$parent.chartData"
                    :options="$parent.chartOptions"
                ></GChart> -->
            </div>
        </div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
    name: "LeadsCharts",
    components: {
        GChart,
    },
};
</script>
<style scoped>
.counters{
    color: #333333;
    font-size: 14px;
}
</style>
