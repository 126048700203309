let get_todolist = function() {
	if (this.get_todolist) {
		let g = this.get_todolist[0].function;
		let d = this.get_todolist[0].data[0];
		// console.log(this.get_todolist[0]);
		if (g == "task") {
			this.listTemplate = d.listTemplate;
			let t = d.taks;
			for (const i in t) {
				let lineNew = {
					id: i,
					name: t[i].name,
					status: t[i].id_status,
					statusList: d.lineNew.statusList,
				};
				this.f.taks.push(lineNew);
			}
			// this.f.taks.push(d[0].lineNew);
		}
	}
};
export default get_todolist;
