/* eslint-disable no-console */

import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV=='development') {
if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready () {
			console.log(
				'App is being served from cache by a service worker.\n' +
				'For more details, visit https://goo.gl/AFskqB'
				)
		},
		registered () {
			console.log('Service worker has been registered.')
		},
		cached () {
			console.log('El contenido se ha almacenado en caché para su uso sin conexión.')
		},
		updatefound () {
			console.log('Se ha descargado el contenido con exito.')
			// if(localStorage.getItem('uriCache') == null){
			// 	localStorage.setItem('uriCache', 1);
			// }
		},
		updated (registration) {
			console.log('Nueva version detectada; por favor actualice.')
			document.dispatchEvent(
			    new CustomEvent('swUpdated', { detail: registration })
			 )
			if(localStorage.getItem('uriCache') == 1){
				localStorage.setItem('uriCache', 2);
				window.location.reload(true);
			}
			else if(localStorage.getItem('uriCache') == 2){
				let NewCache = localStorage.getItem('start')+'=';
				NewCache +=localStorage.getItem('oauth_m')+'&eraseCache=true';
				window.location.href = window.location.href+'?'+NewCache;
				localStorage.setItem('uriCache', NewCache);
			}
		},
		offline () {
			console.log('No internet connection found. App is running in offline mode.')
		},
		error (error) {
			console.error('Error during service worker registration:', error)
		}
	})
}else{
	console.log('register-service-worker', process.env)
}
