<template>
    <div class="row">
        <div class="col contenedor_table">
            <table class="task-list" id="table_task">
                <thead>
                    <tr>
                        <th class="check"></th>
                        <th v-for="(i, index) in $parent.desingTableTask" :key="index" :class="[i.class]+' task'" :colspan="i.colspan" :id="i.id" v-if="i.id=='name_vendor' || i.visible">
                            <div v-if="i.filter.visible" :class="['orderCell', 'order_'+i.filter.orderCell, (i.filter.orderCell>0) ? 'active': '']" @click="onEventClick(i)">
                                <i class="fas fa-sort"></i>
                                <i v-if="i.filter.orderCell==1" class="fas fa-sort-down"></i>
                                <i v-else-if="i.filter.orderCell==2" class="fas fa-sort-up"></i>
                            </div>
                            {{i.label}}
                             <div v-if="i.id!==null">
                                <div v-if="i.customClick.active" :class="['options', (i.customClick.show3puntos==true) ? 'active' : '']" >
                                    <i class="fas fa-ellipsis-h" @click="i.customClick.show3puntos=!i.customClick.show3puntos"></i>
                                </div>
                                <div v-if="i.customClick.active" :style="(i.customClick.show3puntos==true) ? 'display: flex;justify-content: end;' : 'display:none'">
                                    <ul class="menuPount">
                                        <li  v-if="i.id=='rec' || i.id=='assign' || i.id=='category' || i.id=='expense'">
                                            <label class="custom-control-label" :for="index + '-' + i.label">hide</label>
                                        </li>
                                        <li v-if="i.id=='category'" @click="$parent.adminTags()">Tag Setting</li>
                                    </ul>
                                </div>
                            </div> 
                        </th>
                    </tr>
                </thead>
                <tbody  v-if="$parent.isLoading">
                    <tr><td :colspan="$parent.desingTableTask.length+1" style="text-align: center;"><Loader></Loader></td></tr>
                </tbody>
                <tbody v-else-if="$parent.dataList.length==0">
                    <tr><td :colspan="$parent.desingTableTask.length+1" style="text-align: center;">No record</td></tr>
                </tbody>
                <!-- v-click-outside="outFocusTr" -->
                <tbody v-else  >
                    <template v-for="(item, idxTR) in $parent.dataList">
                        <tr :key="idxTR" :id="'tr-'+idxTR">
                            <td class="check">
                                <div class="rowOptions" >
                                    <div :class="['btnOptions', (item.menuRow) ? 'active' : '']" @click="activeMenuRow(item, idxTR)">
                                        <i class="fas fa-ellipsis-h" ></i>
                                    </div>
                                    <div :class="['contentRowOptions', (item.menuRow) ? 'active' : '']">
                                        <ul class="menuRowOptions">
                                            <li @click="modelPrintTask(item)">
                                                Open Task
                                            </li>
                                            <!-- <li  @click="$parent.open_task(item)">
                                                Print Task
                                            </li> -->
                                            <li @click="$parent.addTaksPop('duplicate', item)">
                                                Duplicate
                                            </li>
                                            <li @click="delTask(item, idxTR)">
                                                Delete
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {{ idxTR+1 }}
                                <!-- <input type="checkbox" :name="'check'+idxTR" :id="'check'+idxTR" @click="onActiveTr(idxTR, item)"/> -->
                            </td>
                            <template v-for="(i, idxTD) in $parent.desingTableTask" >
                                <td :key="idxTD" :class="[i.class]" :colspan="1" :id="i.id" v-if="i.id=='name_vendor' || i.visible==true">
                                    <div v-if="i.id=='id_note'" >
                                        <div class="label-id">#{{ item.idx }}</div>
                                        <div class="label-note" @click="$parent.actionNotes(item.id, item.isNote, item.note_id)">
                                            <i :class="[(item.isNote) ? 'fas fa-sticky-note active' : 'far fa-sticky-note']"></i>
                                        </div>
                                    </div>
                                    <div v-if="i.id=='due_date'" :class="[i.class]">
                                        <div class="label-days-left">
                                            <span v-if="item.status_id!=2" >
                                                <span v-if="item.days>0 && item.days > item.daysBefore" class="on_time">{{ item.days }} days left</span>
                                                <span v-else-if="item.days>0 && item.days <= item.daysBefore" class="time_running_out">{{ item.days }} days left</span>
                                                <span v-else-if="item.days==0" class="today">Due Today</span>
                                                <span v-else class="overdue">Overdue</span>
                                            </span>
                                            <span v-else class="completed">Completed</span>
                                        </div>
                                        <div class="label-date">
                                            <span :class="['view-date']">
                                                <!-- {{ item.due_date | moment("YYYY MMM DD, hh:mm A")}} -->
                                                {{ item.due_date | moment("MMM DD, YYYY")}}<br />
                                                {{ new Date(item.due_date).toLocaleTimeString("en-us", optionsTime)}}
                                            </span>
                                            <date-picker
                                                v-model="item.due_date"
                                                :default-value="dateSelected"
                                                value-type="format" 
                                                format="YYYY-MM-DD HH:mm:ss"
                                                type="datetime"
                                                :input-attr="{'data-value': item.due_date}"
                                                :show-time-panel="showTimePanel"
                                                :minute-step="5"
                                                input-class="mx-input"
                                                @open="dueDateOpen(item)"
                                                @close="closeDatePicker(item)"
                                                @input="changeDate(item)"
                                                :clearable="false"
                                                :show-second="false"
                                                :confirm="true"
                                                use12h
                                                :disabled-date="notBeforeToday"
                                                :open.sync="item.openCal>0"
                                            >
                                                <template v-slot:header="{ emit }" class="header-date-picker">
                                                    <button class="mx-btn mx-btn-text btn-today" @click="emit(new Date())">Today</button>
                                                </template>
                                                <template v-slot:footer>
                                                    <button type="button" class="mx-btn mx-btn-text" @click="toggleTimePanel" v-if="!showTimePanel">
                                                        Hours
                                                    </button>
                                                    <button type="button" class="mx-btn mx-btn-text" @click="toggleTimePanel" v-else>
                                                        Date
                                                    </button>
                                                    <!-- <button type="button" class="mx-btn mx-btn-text" @click="onResetCal(item)" v-if="resetCal">
                                                        Cancel
                                                    </button> -->
                                                    <button type="button" class="mx-btn mx-btn-text" @click="closeDatePicker(item)" >
                                                        Close
                                                    </button>
                                                </template>
                                            </date-picker>
                                        </div>
                                    </div>
                                    <div v-if="i.id=='rec'" :class="[i.class]">
                                        <div class="label-rec  d-flex " @click="recrringEdit($event, item, 30, idxTR)">
                                            <span :class="[(item.isRec) ? 'active' : 'placeholder-rec']">R</span>
                                        </div>
                                        <div class="label-periodo" v-if="item.isRec" @click="recrringEdit($event, item, 50, idxTR)">
                                            <span v-if="item.interval==2">Month</span>
                                            <span v-if="item.interval==1">Annual</span>
                                        </div>
                                        
                                    </div>
                                    <div v-if="i.id=='name_vendor'" :class="[i.class]">
                                        <div class="label-name" >
                                            <div class="edit-name" :ref="'task_name_'+item.id" >
                                                <textarea     
                                                    :class="['input-edit-name', 'task_name-'+item.id]" 
                                                    v-model="item.task_name" 
                                                    @change="changeInput('task_name', item)"
                                                    @keydown="prevenirSaltosDeLinea" 
                                                    @focusout="focusOutName(item)" 
                                                    maxlength="200"
                                                    :placeholder="(item.task_name!=null && item.task_name.length>0) ? 'Unnamed task' :'Task name'"
                                                ></textarea>
                                            </div>
                                            <div :class="['label-vendor', (i.visible) ? 'vendor_show' : 'vendor_none']">
                                                <model-select
                                                    :options="$parent.VendorList"
                                                    v-model="item.vendor_id"
                                                    placeholder="Choose a Vendor"
                                                    :class="['select-vendor', (item.vendor_id>0) ? 'selected' : '']"
                                                    @input="changeInput('vendor_id', item)"
                                                >
                                                    <template #search="{attributes, events}">
                                                        <input
                                                          class="search"
                                                          v-bind="attributes"
                                                          v-on="events"
                                                          />
                                                    </template>
                                                </model-select>
                                                <button type="button" class="btn-add-vendor" @click="modalVendor(item)">Add</button>
                                            </div>
                                        </div>
                                        <div class="label-open" @click="onActiveTr(idxTR, item)">
                                            <i class="fas fa-expand-alt"></i> 
                                            <span v-if="item.open==0">Open</span>
                                            <span v-else>Close</span>
                                        </div>
                                    </div>
                                    <!-- <div v-if="i.id=='assign'" @click="popoverAssign($event, i, item, idxTR)"> -->
                                    <div v-if="i.id=='assign'" :class="[i.class]" @click="openAssignUser(item)">
                                        <div class="label-assign" v-if="item.assing.length>0" >
                                            <template v-for="(a, aIdx) in item.assing">
                                                <!-- v-popover.left="{ name: 'infoUser-'+item.id}"  -->
                                                <span :key="aIdx" 
                                                    :class="['avatarUser',(item.assing.length>1) ? 'join-avatar' :'', (a.id==org_id)? 'bg-avatar-admin': '']"
                                                    :style="'background-color:'+a.classColor+';'"
                                                >
                                                <!-- $parent.assignUserPopover(item) $parent.modalAssignUser(item)-->
                                                    <span class="labelAvatar" >
                                                        {{a.pref_name}}
                                                    </span>
                                                    <!-- <i v-if="aIdx>0" class="fas fa-times" @click="assingUserRemove(item, a.id)"></i> -->
                                                </span>
                                            </template>
                                        </div>
                                        <div class="label-assign " v-else>
                                            <!-- bg-avatar-admin bg-avatar -->
                                            <span class="avatarUser bg-avatar-choise">
                                                <span class="labelAvatar">
                                                    <i class="fas fa-user"></i>
                                                    <span class="plus-user">+</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="i.id=='category'" :class="[i.class]">
                                        <div class="selecttags">
                                            <multiselect
                                            v-model="item.category"
                                            :class="['form-control', 'vselect'+item.id]"
                                            tag-placeholder="Add new"
                                            placeholder="Add tag"
                                            label="category_name"
                                            track-by="id"
                                            :options="$parent.CategoryList"
                                            :multiple="true"
                                            :taggable="true"
                                            :clear-on-select="false"
                                            :ref="'vselect'+item.id"
                                            select-label="Choose"
                                            deselect-label="Remove"
                                            @tag="addTag"
                                            @input="changeTags"
                                            @open="openTagSelect(item)"
                                            @search-change="SearchTag"
                                            >
                                                <template slot="tag" slot-scope="props">
                                                    <span :class="[
                                                        'multiselect__tag', 
                                                        (item.category.length<=4) ? 'wAuto' : 
                                                            (item.category.length<6) ? 'w60' : 
                                                                (item.category.length<7) ? 'w50' : 
                                                                    (item.category.length<8) ? 'w40' : 
                                                                        (item.category.length<13) ? 'w30' : 
                                                                            (item.category.length<16) ? 'w20' : ''
                                                        ]"
                                                        :title="getOptionDisplay(props.option)"
                                                    >
                                                        <span v-text="getOptionDisplay(props.option)"></span>
                                                        <i tabindex="1" @keydown.enter.prevent="removeElement(props.option, item)"  @mousedown.prevent="removeElement(props.option, item)" class="multiselect__tag-icon"></i>
                                                  </span>
                                              </template>
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div v-if="i.id=='schedule'" :class="[i.class]">
                                        <div class="btn-group" @click="changeSchedule(item)">
                                            <button type="button" >
                                                <div class="label-time closed" v-if="item.status_id==2">Closed</div>
                                                <div class="label-time" v-else-if="item.days>=0">{{item.daysBefore}} days before </div>
                                                <div class="label-time expired" v-else-if="item.status_id<2">Time has expired</div>
                                                <div class="label-date">{{ item.schedule | moment("MMM DD, hh:mm A")}}</div>
                                            </button>        
                                            <div class="btn-group" v-if="item.days>0">
                                                <button type="button" class="dropdown-toggle" ></button>
                                                <div :class="['dropdown-menu', (item.editSchedule) ? 'showEdit' : '']" :ref="'dropdown-menu-'+item.id" >
                                                    <button type="button" v-for="(i, index) in daysBefore" :key="index" :class="['dropdown-item', (item.date_schedule==i) ? 'active' : '']" @click="focusSchedule('date_schedule', item, i)">
                                                        {{i}} 
                                                        <span v-if="i==0">days</span>
                                                        <span v-else-if="i<2">day before</span>
                                                        <span v-else>days before</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="i.id=='status'" :class="[i.class]" style="height: 100%;">
                                        <div :class="[
                                                'label-status', 
                                                'status_'+item.status, 
                                                (item.status<=1 && item.days<0) ? 'uncompleted' : '',
                                            ]" 
                                        >
                                            <span v-if="item.status<=1 && item.days<0">Uncompleted</span>
                                            <span v-else-if="item.status==0">Pending</span>
                                            <span v-else-if="item.status==1">In Progress</span>
                                            <span v-else-if="item.status==2">Completed</span>
                                            <span v-else-if="item.status==3">Suspended</span>
                                            <span v-else>None</span>
                                        </div>
                                    </div>
                                    <div v-if="i.id=='attach'" :class="[i.class]" @click="modelPrintTask(item)">
                                        <i :class="['fas fa-envelope-open', (item.isAttach==1) ? 'active' : '']" ></i>
                                    </div>
                                    <div v-if="i.id=='expense'">
                                        <div class="label-amount">
                                            <input 
                                                type="text" 
                                                class="edit-amount" 
                                                v-model="item.exp_amount" 
                                                placeholder="Amount"
                                                @change="changeInput('exp_amount', item)"
                                                @keypress="$parent.validDecimal(item.exp_amount)"
                                                @click="$parent.validDecimal(item.exp_amount)"
                                                @focusout="$parent.formatUSD(item.exp_amount)"                                                
                                            />
                                        </div>
                                        <!-- <div class="label-expense">On Expense</div> -->
                                    </div>
                                </td>
                            </template>
                        </tr>
                        <tr v-if="item.open>0 && selectedTr==item.id" class="moreDetailsTr" :key="idxTR+'show'">
                            <td :colspan="$parent.desingTableTask.length+1" class="moreDetailsTd">
                                <table :class="['moreDetailsTable']">
                                    <thead>
                                        <!-- <th class="check_action"></th> -->
                                        <th class="action_name_task">Actions List:</th>
                                        <!-- <th class="date_action">Date</th> -->
                                        <th class="viewDetails">View</th>
                                        <th class="status">Status</th>
                                    </thead>
                                    <draggable
                                        v-model="item.actions"
                                        tag="tbody"
                                        class="tbody"
                                        v-bind="dragOptions"
                                        @start="isDragging(true)"
                                        @end="isDragging(false)"
                                        @change="checkMove(item, item.actions)"
                                        handle=".handle"
                                    >
                                        <template v-if="loadingAction">
                                            <tr>
                                                <td colspan="3" style="text-align: center;"><Loader></Loader></td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr v-for="(iAction, idx) in item.actions" 
                                                :key="idx" 
                                                :class="[
                                                    'trAction', 
                                                    (iAction.action_status_id==3)?'delAction':'',
                                                ]"
                                            >
                                                <!-- <td class="check_action"><input type="checkbox" /></td> -->
                                                <td class="action_name_task">
                                                    <div class="btnmove handle"><i class="fas fa-ellipsis-v"></i></div>
                                                    <input 
                                                        type="text" 
                                                        :class="['edit_name', (iAction.action_status_id==2) ? 'text-done' : '']"
                                                        v-model="iAction.accion_name" 
                                                        @change="editNameAction(item, iAction)"
                                                    />
                                                </td>
                                                <td class="viewDetails" title="View details" @click="openActionDetails(item, iAction)">
                                                    <i class="fas fa-eye"></i>
                                                </td>
                                                <td class="status">
                                                    <div :class="['action_order_btn', 'label-status', 'status_'+iAction.action_status_id]">
                                                        <span v-if="iAction.action_status_id==0">Pending</span>
                                                        <span v-else-if="iAction.action_status_id==1">In Progress</span>
                                                        <span v-else-if="iAction.action_status_id==2">Done</span>
                                                        <span v-else-if="iAction.action_status_id==3">Delete</span>
                                                        <span v-else>None</span>
                                                        <span class="status_list">
                                                            <ul class="reset " style="padding-left: 0">
                                                                <li 
                                                                    :class="['setStatus0', (iAction.action_status_id==0) ? 'active' : '']" 
                                                                    @click="changeStatusAction(item, iAction, idx, 0)"
                                                                >Pending</li>
                                                                <li 
                                                                    :class="['setStatus2', (iAction.action_status_id==2) ? 'active' : '']" 
                                                                    @click="changeStatusAction(item, iAction, idx, 2)"
                                                                >Done</li>
                                                                <li v-if="item.isActions>1"
                                                                    :class="['setStatus3']" 
                                                                    @click="changeStatusAction(item, iAction, idx, 3)"
                                                                >Delete</li>
                                                            </ul>
                                                        </span>                                          
                                                    </div>                                                
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="" style="text-align: left;" class="btnNew">
                                                    <button type="button" class="btn btn-primary" @click="addActions(item)">
                                                        <i class="fas fa-plus"></i> 
                                                        Action
                                                    </button>
                                                </td>
                                                <td colspan="" style="text-align: center;">
                                                    <button type="button" class="btn btn-light" @click="$parent.open_task(item)" style="display:block; width:80%;padding: 2px 5px; margin: auto; background-color: #fff; border-color: #ccc;">
                                                        See All
                                                    </button>
                                                </td>
                                                <td></td>
                                            </tr>                                        
                                        </template>
                                    </draggable>
                                </table>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <modal name="addVendor" :resizable="false" :draggable="true" :adaptive="true" :height="200" :width="320" @before-close="resetModal">
            <div class="card">
                <div class="card-header">
                    <h4>Add Vendor <span class="badge badge-success" v-if="itemSelected!=null">#{{itemSelected.idx}}</span></h4>
                    <button @click="closeModal" class="closeIcon"><span></span></button>
                </div>
                <div class="card-body" style="padding-top:0px;">
                    <form @submit.prevent="saveVendor" >
                        <div class="form-group">
                            <label for="inputCity">Company Name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="addVendor.company_name"
                                required
                            />
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button type="button" class="btn btn-secundary" style="margin-right: 10px;background-color:#fff;border-color:#ccc;" @click="closeModal">
                                    <i class="fas fa-times"></i> Cancel
                                </button><button type="submit" class="btn btn-success">
                                    <i class="fas fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <modal name="assignUser" :resizable="true" :draggable="true" :adaptive="true" height="auto" :width="600" @before-close="resetModal">
            <div class="card">
                <div class="card-header">
                    <h4>Assign User <span class="badge badge-success" v-if="itemAssing!=null">#{{itemAssing.idx}}</span></h4>
                    <button @click="closeModal" class="closeIcon"><span></span></button>
                </div>
                <component v-bind:is="componentAssignUser" :item="itemAssing" :userlist="$parent.userlist"></component>
            </div>
        </modal>
        <div :class="['modalPop', (showModalPop)? 'show' : 'none']" @click="modalPop();"></div>
        <!-- <popAssign ref="popAU" :item="itemAssing" :userlist="$parent.userlist"></popAssign> -->
        <recPop ref="popRec" :itemRec="itemRec"></recPop>
        <printTask ref="itemPrint" ></printTask>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import Loader from '@/core/components/shared/Loader'
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from "vue-search-select";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/index.css';
import '@/apps/billing/components/smartOffice/datepicker.scss';
import '@/apps/billing/components/smartOffice/task_table.scss';
// import popAssign from '@/apps/billing/components/smartOffice/popAssign';
import recPop from '@/apps/billing/components/smartOffice/recPop';
import draggable from 'vuedraggable'
import printTask from '@/apps/billing/components/smartOffice/printTask';
import modalAssignUser from '@/apps/billing/components/smartOffice/modalAssignUser';
export default {
    name: "task_table",
    components: {
        Loader,
        DatePicker,
        ModelSelect,
        Multiselect,
        // popAssign,
        draggable,
        recPop,
        printTask,
        modalAssignUser,
    },
    data() {
        return {
			user_id_master: window.master_client,
            user_id_current: window.localAccountID,
            org_id: window.localorgdata,
            dataList: [],
            orderCell: 0,
            selectedTr: 0,
            showTimePanel: false,
            resetCal: false,
            date_old: null,
            dateSelected: new Date(),
            addVendor: { company_name: null }, 
            showPrintTask: false,
            daysBefore: 0,
            categorySelected: null,
            assinguser_ids: [],
            itemSelected: null,
            itemRec: {},
            itemAssing: {},
            showModalPop: false,
            showAssingUser: false,
            loadingAction: false,
            showPopRec: false,
            menuRowIdx: null,
            optionsTime: {hour12: true, hour: "2-digit", minute: "2-digit"},
            componentAssignUser: null,
        };
    },
    created(){
        //this.fillDataTable();
    },
    mounted(){
        //console.log('mounted')
        let t= this;
        document.body.addEventListener("keydown", function(event) {
            //console.log(event.code, event.keyCode);
            if (event.code === 'Escape' || event.keyCode === 27) {
                // tecla Escape ...
                t.outFocusTr();
            }
        });
    },
    updated(){
        //console.log('update');
        this.calcHeigthTaskName();
    },
    directives: {
        ClickOutside
    },
    methods: {
        onActiveTr(idHtml, item){
            let t = this;
            if(document.querySelector("#tr-"+idHtml) != null){
                let e= document.querySelector("#tr-"+idHtml);
                console.log('focusTr tr', e) 
                this.outFocusTr();
                e.classList.add('activeTr');
                if(item!= undefined){
                    //this.$parent.dataList[idHtml].open =1
                    t.loadingAction=true;
                    if(item.open==0){
                        item.open =1;
                        this.selectedTr = item.id;
                        console.log('open', item.id)
                    }else{
                        item.open =0;
                        this.selectedTr = 0;
                        console.log('closed', item.id)
                    }
                    if(item.open==1){
                        window.billing
                        .post("getActionsTask", {
                            task_id: item.id,
                            user_idd: window.master_client,
                            user_id: window.localorgdata,
                            org_id: window.localorgdata,
                            oauth_m: window.oauth_m,
                        })
                        .then((response) => {
                            console.log('getActionsTask', response.data);
                            let newList = t.$parent.dataList.filter((r) => { 
                                if(r.id==item.id){
                                    r.open=item.open;
                                    r.actions = response.data.r;
                                }
                                else{
                                    r.open=0;
                                } 
                                return r; 
                            });
                            t.$parent.dataList=[];
                            t.$parent.fillData(newList, this.selectedTr);
                            t.loadingAction=false;
                        })
                        .catch((error) => {
                            console.log(error);
                            t.loadingAction=false;
                        });
                    }else{
                        let newList = t.$parent.dataList.filter((r) => { 
                            if(r.id==item.id){
                                r.open=item.open;
                            }
                            else{
                                r.open=0;
                            } 
                            return r; 
                        });
                        t.$parent.dataList=[];
                        t.$parent.fillData(newList, this.selectedTr);
                        t.loadingAction=false;
                    }
                }
            }
        },
        outFocusTr(){
            //console.log('outFocusTr tr') //activeTr
            let tr = document.querySelectorAll('#table_task tbody tr');
            tr.forEach(function(elem){
                if(elem.classList.contains('activeTr')){
                    elem.classList.remove('activeTr');
                }
            });
            this.selectedTr = 0;
        },
        changeStatus(item, status){
            item.status = status
        },
        changeStatusAction(item, i, idx, status){
            let t = this;
            if(i.action_status_id != status){
                item.actions[idx].action_status_id = status;
                i.action_status_id = status;
                t.changeItemAction(item, i);
            }
        },
        editNameAction(item, i){
            let t = this;
            if(i.accion_name!=null && i.accion_name.length > 0){
                t.changeItemAction(item, i);
            }            
        },
        openActionDetails(item, i){            
            let t = this;
            t.$parent.itemSelected = item;
            t.$parent.itemAction = i;
            t.$parent.showActions = true;
            t.showModalPop = true;
        },
        changeItemAction(itemTask, itemAction){            
            let t = this;  
            window.billing
            .put("updateActionTask", {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: itemTask.id,
                id: itemAction.id,
                accion_name: itemAction.accion_name,
                accion_descrip: itemAction.accion_descrip,
                date_action: itemAction.date_action,
                action_status_id: itemAction.action_status_id
            })
            .then((response) => {
                console.log('updateActionTask', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        dueDateOpen(item){
            item.openCal=1
            this.dateSelected=item.due_date;
            this.date_old =item.due_date;
        }, 
        notBeforeToday(date, currentValue) {
            if(currentValue==null){currentValue=[event.target.getAttribute('data-value')]}
            //console.log('notBeforeToday', currentValue[0])
            return date < new Date().setHours(0, 0, 0, 0); //no puede retroceder mas de la fecha actual
        },
        notBeforeTodayTwelveOClock(date) {
            return date < new Date(new Date().setHours(12, 0, 0, 0));
        },
        toggleTimePanel() {
            this.showTimePanel = !this.showTimePanel;
        },
        changeDate(item) {
            console.log('changeDate', item.due_date)
            this.resetCal = true;
            this.changeInput('due_date', item);
            //this.showTimePanel = true;
        },
        handleOpenChange() {
            this.showTimePanel = false;
        },
        onResetCal(item){
            item.schedule = this.date_old;
            this.closeDatePicker(item);
        },
        closeDatePicker(item) {
            item.openCal = 0;
            this.showTimePanel = false;
        },
        onEventClick(i){
            let t = this;
            if(i.filter.visible){
                if(i.filter.orderCell==0){i.filter.orderCell=1;} // desc
                else if(i.filter.orderCell==1){i.filter.orderCell=2;} // asc
                else if(i.filter.orderCell==2){i.filter.orderCell=0;} //sin filtro
                else{i.filter.orderCell=0;} //sin filtro
                t.$parent.desingTableTask.find((item) => {
                    if(i.id!=item.id){
                        item.filter.orderCell=0;
                    }
                })
                console.log('orderCell', i.filter.orderCell)
            }else{
                // otros eventClick
                if(i.id=='category'){
                    t.$parent.adminTags();
                }
            }
            //this.orderData(this.$parent.dataList, i.id);
        },
        orderData(arr, campo){
            return arr.sort((a, b) => {
               return new Date(a[campo]).getTime() > new Date(b[campo]).getTime();
            });
        },
        prevenirSaltosDeLinea(evento) {
            if (evento.keyCode === 13) {
                evento.preventDefault();
            }
        },
        calcHeigthTaskName(){    
            let t = this;
            let taskName = document.querySelectorAll('.input-edit-name');
            if(taskName!= null && taskName.length>0){
                taskName.forEach(e => {
                    //console.log('taskName', e.value)
                    t.autoHeigthTaskName(e);
                })
            }
        },
        autoHeigthTaskName(elem){
            let calcHeight = elem;
            if(calcHeight){
                let numberOfLineBreaks = (calcHeight.value.match(/\n/g) || [1]).length;
                let logitud = Math.round(calcHeight.clientWidth) / 14 + 10; //elemWidth + font-size + line-height
                let caracteres =calcHeight.value.length*14/calcHeight.clientWidth;
                numberOfLineBreaks = (numberOfLineBreaks==1) ? caracteres : numberOfLineBreaks;
                //console.log('logitud', logitud)
                // min-height + lines x line-height + padding + border
                let newHeight = 14 + numberOfLineBreaks * 10 + 0 + 0;
                calcHeight.style.height = newHeight + "px";
                if(numberOfLineBreaks>3){
                    calcHeight.style.height = calcHeight.scrollHeight + "px";
                }
            }
        },
        changeInput(campo, i){
            let t = this;
            if(campo=='task_name'){
                t.saveChangeInput(campo, i);
                let elem = document.querySelector('.task_name-'+i.id);
                t.autoHeigthTaskName(elem);
                // let timeout;
                // let task_name = i[campo];
                // localStorage.setItem('task_name', task_name)
                // clearTimeout(timeout)
                // timeout = setTimeout(() => {
                //     if(localStorage.getItem('task_name')==task_name){
                //         console.log('has dejado de escribir', task_name)
                //         i[campo]=task_name;
                //         t.saveChangeInput(campo, i);
                //     }
                //     clearTimeout(timeout)
                // }, 1000);
            }
            else if(campo=="exp_amount"){
                i[campo] = t.$parent.validDecimal(i[campo]);
                t.saveChangeInput(campo, i);    
            }
            else{
                //console.log('change', campo, i[campo])
                t.saveChangeInput(campo, i);    
            }
        },
        saveChangeInput(campo, i){
            let t = this;
            console.log('change', campo, i[campo])
            window.billing
            .post("updateTask", {
                id: i.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: campo,
                valor: i[campo],
            })
            .then((response) => {
                console.log('updateTask');
                localStorage.removeItem('task_name');
            })
            .catch((error) => {
                console.log(error)
            });
        },
        modalVendor(item){
            console.log('add vendor')
            this.itemSelected = item;
            this.$modal.show('addVendor');
        },
        openAssignUser(item){
            console.log('assignUserModal')
            this.itemAssing = item;
            this.componentAssignUser = "modalAssignUser";
            this.$modal.show('assignUser');
        },
        resetModal(){
            this.addVendor.company_name = null;
            this.assinguser_ids = [];
            this.componentAssignUser =null;
            this.itemSelected = null;
            this.itemAssing = null;
        },
        closeModal(){
            this.$modal.hide('addVendor');
            this.$modal.hide('assignUser');
        },
        saveVendor() {
            let t = this;
            window.billing
            .post("expenses/vendor/add", {
                user_id: window.localuserdata,
                company_name: t.addVendor.company_name,
            })
            .then((response) => {
                t.$parent.getVendors();
                t.closeModal();
            })
            .catch((error) => {
                console.log(error)
            });
        },
        modelPrintTask(item){
            // item.menuRow = !item.menuRow;
            this.showPrintTask = true;
            console.log('Show Print Task')
            this.itemSelected = item;
            this.showModalPop=true;
        },
        resetModelPrintTask(){
            this.showPrintTask = false;
            this.itemSelected = null;
            this.outFocusTr();
        },
        printTaskPdf(item) {
            window.open(window.urlbase + "api/v2/pdfTask/" + item.id+"/"+ item.user_id, "_blank");
        },
        closeModelPrintTask(){},
        focusOutName(i){
            let t = this;
            t.$parent.dataList.filter((r) => { 
                if(i.id!=r.id){
                    r.editName=false;
                }
            });
            i.editName=false;
            t.$parent.duplicateData(t.$parent.dataList);
            console.log('focusOutName', i.editName)
        },
        focusONName(i){
            let t = this;
            t.$parent.dataList.filter((r) => { 
                if(i.id!=r.id){
                    r.editName=false;
                }
            });
            i.editName=true;
            t.$parent.duplicateData(t.$parent.dataList);
            console.log('focusONName', i.editName)
        },
        changeSchedule(i){
            let t = this;
            t.$parent.dataList.filter((r) => { 
                if(i.id!=r.id){
                    r.editSchedule=false;
                }
            });
            i.editSchedule = !i.editSchedule;
            if(i.editSchedule){
                t.selectedTr = i.id;
                t.$parent.duplicateData(t.$parent.dataList);
               let diff = t.$parent.calcDiasDiff(new Date(), i.due_date, '-');
                t.daysBefore = [0];
                if(diff.d && diff.d>0){
                    for (var d = 1; d <= diff.d; d++) {
                        t.daysBefore.push(d);
                    }
                }
            }
        },
        focusSchedule(campo, item, val){
            let t = this;
            item[campo] = val;
            t.changeInput(campo, item)
        },
        hideDropFill() {
            let t = this;
            t.$parent.dataList.filter((r) => { 
                if(r.id==t.selectedTr){
                    r.editSchedule=false;
                    t.selectedTr=0;
                }
            });
        },
        openTagSelect(item){this.categorySelected=item; },
        changeTags(values){
            let t = this;
            let item = t.categorySelected;
            console.log('changeTags', values, item)
            let category_ids = [];
            let tags = [];
            let itemNone = null;
            tags = values.filter(r => {
                if(r.id==1){itemNone=r;}
                if(r.id>1){
                    category_ids.push(r.id);
                    return r;
                }
            });
            item.category_ids = (category_ids.length>0) ? category_ids.toString().replace('1,', '') : '';
            t.saveChangeInput('category_ids', item);
        },
        addTag (newTag) {
            let t = this;
            let id = 'new999999';
            const tag = {
                id: id,
                category_name: newTag,
            }
            console.log('new tag', tag);
            t.$parent.CategoryList.push(tag);
            let list = t.$parent.dataList;
            for (var i in list) {
                if(list[i]){
                    if(list[i].id==t.categorySelected.id){
                        t.$parent.dataList[i].category.push(tag);
                    }
                }
            }
            window.billing
            .post("addTagByUser", {
                id: t.categorySelected.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: 'category_ids',
                category_name: newTag,
            })
            .then((response) => {
                console.log('addTagByUser', response.data.tag);
                for (let r in t.$parent.CategoryList) {
                    if(t.$parent.CategoryList[r].id==id){
                       t.$parent.CategoryList[r].id = response.data.tag.id;
                        console.log('update tag ID', t.$parent.CategoryList[r]);
                    }
                };
                t.$parent.getcategory();
            })
            .catch((error) => {
                console.log(error)
            });
        },
        SearchTag(searchQuery, id){
        },
        getOptionDisplay(text){ return text.category_name; },
        removeElement: function(element, item) {
            this.categorySelected=item;
            this.$refs['vselect'+item.id][0].removeElement(element);
            console.log('removeElement', item.category)
        },
        assingUserRemove(item, assignUser_id){
          console.log('assignUser_id', assignUser_id)
        },
        popoverAssign(e, column, item, idxTR){
            let t = this;
            if(column.id=='assign'){
                if(document.querySelector("#tr-"+idxTR) != null){
                    let tr= document.querySelector("#tr-"+idxTR);
                    if(!tr.classList.contains('activeTr')){
                        t.outFocusTr();
                        tr.classList.add('activeTr');        
                    }                    
                    let position = e.target.getBoundingClientRect();
                    let up = (position.height/2);
                    t.$refs.popAU.$el.style.left=(position.left-(position.width*2))+"px";
                    let validBottom = ((window.innerHeight -(position.top+up)) >150); 
                    t.$refs.popAU.$el.style.top='initial';
                    t.$refs.popAU.$el.style.bottom='initial';
                    if(validBottom){
                        t.$refs.popAU.$el.style.top=(position.top-(up))+"px";
                    }else{
                        console.log('validBottom', (window.innerHeight -(position.top+up)));
                        t.$refs.popAU.$el.style.bottom="0px";
                    }
                    t.itemAssing = item;
                    t.showAssingUser = true;
                    t.showModalPop = true;
                    console.log('item.assing', item.assing)
                }
            }
        },
        modalPop(){
            let t = this;
            if(t.showModalPop==true){
                t.showModalPop = false;
                t.showAssingUser = false;
                t.showPopRec=false;
                t.showPrintTask = false;
                t.$parent.closeModal();
                t.itemRec={};
                if(t.menuRowIdx!=null){
                    let tr= document.querySelector("#tr-"+t.menuRowIdx);
                    if(tr != null){
                        t.$parent.dataList[t.menuRowIdx].menuRow=false;
                        t.menuRowIdx = null;
                        //tr.classList.remove('activeTr');
                    }
                }
            }
        },
        addActions(item){
            let t = this;
            t.$parent.showModalAction(item);
            t.showModalPop = true;
        },
        isDragging(t){ console.log('move'); return t; },
        checkMove: function(item, actions){
            actions = actions.filter((r, idx) => {
                r.item_order = idx;
                return r;
            });
            console.log('checkMove', actions);
            window.billing
            .put('orderActionsTask', {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: item.id,
                itemActions: actions,                		
            })
            .then((response) => {
                console.log('orderActionsTask');
            })
            .catch((error) => {
                console.log(error);
            });
        },
        recrringEdit(e, item, up, idxTR){
            let t = this;
            if(document.querySelector("#tr-"+idxTR) != null){
                let tr= document.querySelector("#tr-"+idxTR);
                if(!tr.classList.contains('activeTr')){
                    t.outFocusTr();
                    tr.classList.add('activeTr');
                }
                let position = e.target.getBoundingClientRect();
                t.$refs.popRec.$el.style.left=(position.left-(250/2))+"px";
                let validBottom = ((window.innerHeight -(position.top+up)) >200); 
                t.$refs.popRec.$el.style.top='initial';
                t.$refs.popRec.$el.style.bottom='initial';
                if(validBottom){
                    t.$refs.popRec.$el.style.top=(position.top-(up))+"px";
                }else{
                    console.log('validBottom', (window.innerHeight -(position.top+up)));
                    t.$refs.popRec.$el.style.bottom="0px";
                }
                t.itemRec=item;
                t.showPopRec=true;
                t.showModalPop=true;
            }
        },
        activeMenuRow(item, idxTR){
            let t = this;
            let tr= document.querySelector("#tr-"+idxTR);
            if(tr != null){
                if(!tr.classList.contains('activeTr')){
                    t.outFocusTr();
                    tr.classList.add('activeTr');
                }
                item.menuRow=!item.menuRow;
                t.menuRowIdx = idxTR;
                if(!item.menuRow){
                    t.showModalPop = false;
                }else{
                    t.showModalPop = true;
                }
            }
        },
        delTask(item, idxTR){
            let t= this;
            item.status_id=3;
            t.saveChangeInput('status_id', item);
            t.activeMenuRow(item, idxTR);
        },
        validActionsCompleted(actions){
            let valid = false;
            for (let i in actions) {
                if (actions[i].action_status_id==2) {
                    valid=true;                    
                }
            }
            return valid;
        },
    },
    watch:{
        "$parent.desingTableTask": function(){
            //console.log('desingTableTask', this.$parent.desingTableTask)
        },
        "$parent.dataList": function(){
            //console.log('task_table2', this.$parent.dataList)
             
        },
    },
	computed: { 
        dragOptions() {
            return {
                animation: 200,
                group: "actions",
                disabled: false,
                ghostClass: "ghost",
            };
        },
	}
};
</script>
<style lang="scss" scoped>
    .modalPop{
        position: fixed;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0);
        z-index: 997;
        opacity: 1;
        &.show{display: block;}
        &.none{display: none;}
    }
</style>
