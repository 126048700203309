<template>
	<div class="row content-filter">
		<div 
			:class="['col-sm-8', '']" 
			style="padding: 0px;">
			<div style="display: flex;"
		>
				<div class="btn-group btn-group-lg" >
					<button type="button" class="btn btn-group-sm btn-success" @click="$parent.addbtn" :disabled="$parent.savedAdd" v-if="!$parent.searchValid">
						<i class="fa fa-plus"></i>
					</button>
					<!--<button type="button" class="btn btn-group-sm btn-info" @click="$parent.getList">
						<i class="fas fa-sync-alt"></i>
					</button>-->
					<!-- <button type="button" class="btn btn-group-sm btn-danger" @click="$parent.deleteRow" :disabled="$parent.validSelected">
						<i class="fas fa-times"></i>
					</button> -->
					<!--<button type="button" v-if="$parent.searchValid" class="btn btn-group-sm btn-info" @click="$parent.activeSearch">
						<i class="fas fa-reply-all"></i>
					</button>
					<button type="button" v-else class="btn btn-group-sm btn-info" @click="$parent.activeSearch">
						<i class="fas fa-search"></i>
					</button>-->
				</div>
					
				<div class="input-group" style="margin-left: 10px;" v-if="$parent.searchValid">
					<input type="text" placeholder="Search" class="form-control" v-model="$parent.searchText">
					<div class="input-group-prepend">
						<button type="button" class="btn btn-success" @click="$parent.getList">
							<i class="fa fa-search"></i> 
						</button>
					</div>
				</div>	
			</div>
		</div>
		<div 
			:class="['col-sm-4', 'viewLogRecords']" 
			style="padding-right: 0px;"
		>
				<ul class="pagination justify-content-end">
					<li class="page-car mr-2">
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">Pag: </div>
							</div>
							<select 
								v-model="$parent.page" 
								class="form-control selectRowList" 
								v-on:change="$parent.changePage($parent.page)"
							>
								<option v-for="i in $parent.numPag" :key="i" :value="i">{{i}}</option>
							</select>
						</div>								
					</li>
					<li>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">Show: </div>
							</div>
							<select v-model="$parent.rowNum" class="form-control selectRowList">
								<option v-for="i in $parent.rowList" :key="i" :value="i">{{i}}</option>
							</select>
						</div>
					</li>
					<li >
						<span class="selected">Selected: {{ $parent.selected.length}}</span> 
						<span class="page-view">View {{ $parent.pagination.to}} of {{ $parent.pagination.total}}</span>
					</li>
				</ul>
		</div>
	</div> 
</template>
<script>
export default {
	name: 'tdynamicFilters',
	components: {
	},
	data () {
		return {
		}
	},
	created() {
		console.log('tdynamicFilters', this.$parent)
	}
}
</script>
<style lang="css" scoped>
</style>
