let searchFilterPaginador = function() {
	if (this.paginador > 0) {
		this.searchFilter();
	} else {
		this.InvoicesList = this.OldInvoicesList;
		this.isLoading = false;
		this.isFilter = false;
	}
};
export default searchFilterPaginador;
