<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">Jobs #{{ jobsNoteNum }}</h5>
                </div>
                <div class="col-5 text-right">
                    <button @click="saveNotes" class="btn btn-success" :disabled="jobsNoteBtn" v-if="typez == 0 || jobsEdicion">
                        <i class="fa fa-save"></i> SAVE
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding: 0.5em !important;max-height: 400px;min-height: 200px;overflow: hidden;overflow-y: auto;">
            <div class="row">
                <div class="col-md-12">
                    <vue-editor v-model="jobsNote" class="jobsNote" v-if="typez == 0 || jobsEdicion" :editor-toolbar="customToolbar"></vue-editor>
                    <div class="notes jobsNote" v-html="jobsNote" v-else-if="!JobsGallerySHOW"></div>
                </div>
                <!--<div class="col-md-12">
                    <div class="btn-group" v-if="typez == 0 || jobsEdicion">
                        <button type="button" class="btn btn-link" @click="editLinkDrive">
                            <i class="fab fa-google-drive"></i> Google Drive
                        </button>
                    </div>
                    <form class="form-inline" style="margin: 5px 0px 5px 10px" @submit.prevent="saveNotes" v-if="jobsLinkDriveEdit">
                        <div class="form-group row" v-if="typez == 0 || jobsEdicion">
                            <input type="text" class="form-control" placeholder="https://ejemplo.com" style="width: 290px" v-model="jobsLinkDrive" />
                        </div>
                        <button type="submit" @click="saveNotes" :disabled="jobsNoteBtn" class="btn btn-primary">
                            Save link
                        </button>
                    </form>
                    <div v-if="jobsLinkDrive != null && jobsLinkDrive.length > 0 && jobsLinkDriveEdit == false && !JobsGallerySHOW">
                        <br />
                        <h5>
                            View link:
                            <a :href="jobsLinkDrive" target="_blank">Google Drive</a>
                        </h5>
                        <br />
                    </div>
                </div>-->
            </div>
        </div>
    </div>    
</template>
<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
    name: "editNoteBox",
    components: {
        VueEditor,
    },
    data () {
        return {
            user_id: window.master_client,
            org_id: window.localorgdata,              
            typez: 0,
            jobsNoteNum: 0,
            jobsNoteID: 0,
            jobsNote: "",
            jobsNoteBtn: false,
            jobsEdicion: false,
            jobsLinkDrive: "",
            jobsLinkDriveEdit: false,
            JobsIFGallery: 0,
            JobsGalleryList: [],
            JobsGalleryURL: "",
            JobsGallerySHOW: false, 
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],                     
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.jobBox;              
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data.g;
            let t = this    
            t.jobsNoteNum = r.jobsNoteNum
            t.jobsNoteID = r.jobsNoteID
            t.jobsNote = r.jobsNote
            t.jobsEdicion = r.jobsEdicion
            t.jobsLinkDrive = r.jobsLinkDrive
            t.JobsIFGallery = r.JobsIFGallery
            t.JobsGalleryList = r.JobsGalleryList
            t.JobsGalleryURL = r.JobsGalleryURL
            t.JobsGallerySHOW = r.JobsGallerySHOW
            t.typez = r.typez
        },
        editLinkDrive() {
            this.jobsLinkDriveEdit = !this.jobsLinkDriveEdit;
        },  
        saveNotes() {
            this.jobsNoteBtn = true;
            let url = this.apis.editNotes()
            window.master
                .post(url, {
                    user_id: this.user_id,
                    org_id: this.org_id,
                    id: this.jobsNoteID,
                    notes: this.jobsNote,
                    link_drive: this.jobsLinkDrive,
                })
                .then((response) => {
                    this.$notify({
                        group: "noti",
                        type: "success",
                        title: "Success",
                        text: "Updated note",
                    });
                    this.jobsNoteBtn = false;
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {
                        action: 'saved',
                        methods:'editNoteBox'                       
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.jobsNoteBtn = false;
                });
        },                            
    }
}
</script>
<style lang="scss" scoped></style>
