<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-5 align-self-center">
                        <h4 class="page-title">ePrint Setup</h4>
                    </div>
                    <div class="col-md-7 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>   
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-2">
                                            HP ePrint1 email
                                        </div> 
                                        <div class="col-md-4">
                                            {{ FormEPrint.email1 }}
                                        </div> 
                                        <div class="col-md-2">
                                            <button @click="SendTestePrint(1)" :disabled="FormEPrint.email1==null">
                                                <i class="fas fa-print"></i> ePrint1 test
                                            </button>
                                        </div>             
                                    </div>   
                                </div> 
                                <div class="col-md-12" style="margin-top:20px">
                                    <div class="row">
                                        <div class="col-md-2">
                                            HP ePrint2 email
                                        </div> 
                                        <div class="col-md-4">
                                            {{ FormEPrint.email2 }}
                                        </div> 
                                        <div class="col-md-2">
                                            <button @click="SendTestePrint(2)" :disabled="FormEPrint.email2==null">
                                                <i class="fas fa-print"></i> ePrint2 test
                                            </button>
                                        </div>             
                                    </div>        
                                </div>                           
                            <!-- <table class="table" style="width: 450px">
                                <tr>
                                    <td style="width: 130px">HP ePrint email</td>
                                    <td style="width: 200px">{{ FormEPrint.email1 }}</td>
                                    <td style="width: 30px">
                                        <button @click="SendTestePrint(1)">
                                            <i class="fas fa-print"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 130px">HP ePrint email 2</td>
                                    <td style="width: 200px">{{ FormEPrint.email2 }}</td>
                                    <td style="width: 30px">
                                        <button @click="SendTestePrint(2)">
                                            <i class="fas fa-print"></i>
                                        </button>
                                    </td>
                                </tr>
                            </table> -->
                        <div class="col-md-12 text-left" style="margin-top:20px">
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="MetUpdateEprint"
                            >
                                <i class="fas fa-edit"></i> Edit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import EprintSettings from '@/apps/billing/components/setting/EprintSettings'
export default {
    components: {
        modalLateral,
        EprintSettings,
    },
    data() {
        return {
            isLoading: false,
            FormEPrint: {
                action: "update",
                user_id: null,
                email1: "",
                email2: "",
            },
            m: {
                show: false,
                component: '',
                data: []
            }            
        }
    },
    created() {
        this.getEprintemail();
    },
    methods: {
        SendTestePrint(email) {
            let t = this;
            let sendTest = false;
            console.log(email);
            if(email==1 && t.FormEPrint.email1!=null){sendTest = true;}
            if(email==2 && t.FormEPrint.email2!=null){sendTest = true;}
            if(!sendTest){   
                t.$store.commit('core/setNotiPush', {
                    show: true,
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    title: 'Warning',
                    text: 'You must provide an email for ePrint'+email+', otherwise you cannot send proofs.',
                    duration: 10000
                });
            }else{
                t.isLoading = true;
                window.billing
                .put("eprinttest/" + window.localuserdata, {
                    email: email,
                    email1: t.FormEPrint.email1,
                    email2: t.FormEPrint.email2,
                })
                .then((response) => {
                    console.log(response.data.success);
                    if (response.data.success) {
                        t.$notify({
                            group: "noti",
                            title: "UPDATED",
                            type: "success",
                            text: "Successfully sent",
                        });
                    } else {
                        t.$notify({
                            group: "noti",
                            title: "ERROR",
                            type: "error",
                            text: "An error has occurred",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.$notify({
                        group: "noti",
                        title: "ERROR",
                        type: "error",
                        text: "An error has occurred",
                    });
                });
            }
        },
        MetUpdateEprint() {
            this.m = {
                show: true,
                component: 'EprintSettings',
                data: {}
            }  
            this.$store.commit('core/setOpenModal', this.m);              
        },
        getEprintemail() {
            this.isLoading = true;
            window.billing
                .get("setting/" + window.localuserdata)
                .then((response) => {
                    this.FormEPrint.email1 = response.data.email1.meta_value;
                    this.FormEPrint.email2 = response.data.email2.meta_value;
                })
                .catch((error) => console.log(error));
        },
    },
    watch: {
        'getActionRefresh': function() {
            console.log('getActionRefresh', this.getActionRefresh)
            let methods = this.getActionRefresh.action;
            if (methods === 'getEprint') {
                this.getEprintemail();
            }
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let data = this.getActionRefresh.data; 
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        accitonPusher: function () {
            if (this.accitonPusher != undefined) {
                let p = this.accitonPusher;
                if (p.action == "eprint-refresh" && window.localorgdata == p.org_id) { 
                    console.log('eprint-push-refresh')
                    this.getEprintemail();
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { 
            getOpenModal: "getOpenModal", 
            getActionRefresh: "getActionRefresh", 
            accitonPusher: "get_accitonPusher",
        }),
    },
};
</script>
<style lang="scss" scoped>
.estilosform {
    .col-form-label {
        color: #a5a5a5;
        padding-bottom: 0;
        font-size: 13px;
    }
}
.marginTop {
    margin-top: 7px;
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
