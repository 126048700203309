// @ts-nocheck
let SendPDFbySMS = function(item, idc, type) {
    let t = this;
    t.closeSubmenu(item.id);
    if(t.PlanPremium){
        this.$store.commit("core/setNotiPush", {
            group: "notiPush",
            type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
            title: "Sending sms",
            text: "Loading...",
            show: true,
            duration: 500
        });        
        let obj = {id: item.id, idNumdoc: item.numdoc, icCustomer: item.customer_id}
        window.billing
        .post("shellpdf/" + idc, {
            trans: 0,
            pag: t.paginador,            
            user_id: window.localuserdata, 
            org_id: window.localorgdata,           
            oauth_m: window.localStorage.getItem("oauth_m"),
            AccountID: window.master_client,
            app_name: window.localStorage.getItem('nameApp'),
            app_id: window.localStorage.getItem('appIDactual'),
            id:item.id,
            type:type,
            parameters:JSON.stringify(obj),            
        })
        .then((response) => {
            let r = response.data.status; 
            if(r == false){
                t.$notify({
                    group: "noti",
                    title: "Error",
                    type: "error",
                    text: "You must add mobile phone",
                });
            } else {                
                t.$notify({
                    group: "noti",
                    title: "Send PDF by SMS",
                    type: "success",
                    text: "Send PDF by SMS Successful",
                });    
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }else{
        t.llamarPopPremium();
    }
};
export default SendPDFbySMS;
