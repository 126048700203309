import { render, staticRenderFns } from "./orgUrl.vue?vue&type=template&id=1e8b9c20&scoped=true&"
import script from "./orgUrl.vue?vue&type=script&lang=js&"
export * from "./orgUrl.vue?vue&type=script&lang=js&"
import style0 from "./orgUrl.vue?vue&type=style&index=0&id=1e8b9c20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8b9c20",
  null
  
)

export default component.exports