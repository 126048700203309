<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-5 align-self-center">
				<h3 class="page-title">Setup / Create Form</h3>
			</div>
			<div class="col-7 align-self-center">
				<div class="d-flex align-items-right justify-content-end">
					<router-link
						:to="{ name: 'MSetup' }"
						class="btn btn-info btn-sm mr-2 router-link-active"
						style="border: 1px solid black;"
						> <i class="fas fa-arrow-left"></i>  Back
					</router-link>
				</div>
			</div>
		</div>	
		<div class="row">
			<div class="card-body">
				<button type="button" class="btn btn-info" style="width: 100px; margin-bottom: 10px;" @click="addNewForm">
					<i class="fa fa-plus"></i> Add new
				</button>								
				<table class="table table-bordered table-inverse table-hover">
					<thead>
						<tr>
							<th class="text-center" style="width: 50px;">ID</th>
							<th>Name</th>
							<th>subject</th>
							<th>Email (sender)</th>
							<th class="text-center">Status</th>
							<th style="width:100px;"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="isLoading"><td colspan="6"><Loader></Loader></td> </tr>
						<tr v-else-if="listForms.length == 0">
							<td colspan="6" class="text-center">No records</td> 
						</tr>
						<tr v-else v-for="(i, index) in listForms" :key="index">
							<td class="text-center" style="width: 50px;">{{i.id}}</td>
							<td>{{i.name}}</td>
							<td>{{i.subject}}</td>
							<td>{{i.email}}</td>
							<td class="text-center">
								<span class="badge badge-pill badge-success" v-if="i.active">Active</span>
								<span class="badge badge-pill badge-danger" v-else>Inactive</span>
							</td>
							<td style="width:100px;">
								<span class="cdropdown">
									<button class="btn btn-sm mr-2">
									Actions
									</button>
									<label>
									<input type="checkbox" />
									<ul>
										<li @click="editForm(i)"> Edit</li>
										<li @click="sendNotiTest(i)"> Send email Test</li>
										<li @click="sendNotiTestsms(i)" v-if="statusSMS==1" :class="!PlanPremium ? 'disabled' : ''"> Send SMS Test</li>
										<li @click="duplicarForm(i)"> Duplicate</li>
										<li @click="ModalBox('copycode', i.id)"> Copy Code</li>
										<li @click="deleteRow(i)"> delete</li>
									</ul>
									</label>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

        <modal name="modal-setting" height="auto" @before-close="ClosedModal">
            <div class="card">
                <button @click="closeModal" class="closeIcon"><span></span></button>
                <component :is="SettingBox" :form_id="idSelected"></component>
            </div>
        </modal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
import copycode from "@/apps/Marketea/components/FormsCopyCode.vue";
export default {
	name: 'listForms',
	components: {
		Loader,
		copycode,
	},
	data () {
		return {
			isLoading: false,
			listForms: [],
			SettingBox: "",
			idSelected: 0,
			statusSMS: 0,
            PlanPremium: false,
            PlanPro: false,
            planActual: 8,
			validForm: false,
            appIDactual: localStorage.getItem('appIDactual') || 0,
            planesPro: [6, 7, 10, 11, 12],
            limitForPlan: {
                free: 1,
                standard: 1,
                team: 5,
                pro: 5,
                premium: 10,
                sponsored: 10,
                google: 10,
                bOrganic: 10,
            },
		}
	},
	created() {
		this.init()
	},
	methods: {
		init(){
            // this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6);
			this.planesPro = this.$store.getters['ModApps/Marketea/get_planesPro'];
            this.PlanPremium = (this.planesPro.indexOf(this.$store.getters['core/apps/getPlanIdApp'])>-1);
            this.PlanPro = (this.$store.getters['core/apps/getPlanIdApp']==10);
            console.log('PlanPremium', this.PlanPremium);
            this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
            if(this.planActual==7){
                this.PlanPremium = true;
                console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
            }
            this.appIDactual = this.$store.getters['core/apps/getAppIDactual'];
            let apisForm = this.$store.getters['ModApps/Marketea/urlApis'];
            this.apis = apisForm.Form;
			if(this.validPlan()){
				this.getListForms();
				this.resetEditForm();
			}
		},
		validPlan(){
			let t = this;
			t.validForm = false;
			for (const e in t.getModulesMenu) {
                if (Object.hasOwnProperty.call(t.getModulesMenu, e)) {
                    let menuPlan = t.getModulesMenu[e];
					if(menuPlan.module_key=='forms' || menuPlan.module_key=='listForms'){
						console.log('isForm = ', menuPlan.module_key)
						t.validForm = true;
                    }
                }
            }
			if(t.validForm){
				if(t.PlanPro && t.listForms.length >=2){
					t.showMsgError(2);
					return false;
				}
				else if(t.PlanPremium && t.listForms.length >=5){
					t.showMsgError(2);
					return false;
				}else{return true;}
			}else{
				t.showMsgError(1);
				return false;
			}
		},
        showPopPremium(){
            let t = this;
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        showMsgError(n){
        	let t= this;
        	let msj ='';
        	let titulo ='';
        	if(n==2){
				titulo ='Maximum forms limit.';
				msj = 'Log limit allowed. Please Contact us to receive a support: <b>774-287-3353</b> or <b>support@marketea.com</b>.';
			}
        	else{
				titulo ='This feature is available for PRO or PREMIUM USERS.';
				msj = 'Update your plan now';
			}
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: titulo,
                text: msj,
                duration: 10000
            });
            if(n==1){
				// t.showPopPremium();
            }
        },
		addNewForm() {
			let t = this;
			let validCreate = t.validPlan();
			if(validCreate){
				t.isLoading = true;
				window.master.post(t.apis.add(), {
					org_id: window.localorgdata
				})
				.then((response) => {
					let e = response.data;
					if (e.status) {
						t.$notify({
							group: "noti",
							type: "success",
							title: "Saved",
							text: "Successful registration",
						});
					}
					t.getListForms();
				})
				.catch((error) => {
					console.log(error);
					t.isLoading = false;
				});
			}
		},
		duplicarForm(i){
			let t = this;
			let validCreate = t.validPlan();
			if(validCreate){
				t.isLoading = true;
				window.master.post(t.apis.duplicarForm(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					id: i.id,
				})
				.then((response) => {
					console.log(response.data);
					let e = response.data;
					if (e.status) {
						t.$notify({
							group: "noti",
							type: "success",
							title: "Saved",
							text: "Successful registration",
						});
					}
					t.getListForms();
				})
				.catch((error) => {
					console.log(error);
					t.isLoading = false;
				});
			}
		},
		deleteRow(i) {
			let t =this;
				t.$swal({
					title: "Delete Item?",
					text: "You are sure to delete the selected records?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					confirmButtonText: "Yes, Delete it!",
				}).then((result) => {
					if (result.value) {
						window.master.post(this.apis.del(), {
							id: i,
							user_id: window.master_client,
							org_id: window.localorgdata,
						})
						.then((response) => {
							t.getListForms();
						})
						.catch((error) => {
							console.log(error);
						});
					}
				});
		},
        ModalBox(nom, id) {
            this.SettingBox = nom;
            this.idSelected = id;
            this.$modal.show("modal-setting");
        },
        closeModal() {
            this.$modal.hide("modal-setting");
        },
        ClosedModal() {
            this.SettingBox = "";
        },
		resetEditForm() {
			this.$store.commit("ModApps/Marketea/set_builtForm", []);
			this.$store.commit("ModApps/Marketea/set_asideStorage", []);
			this.$store.commit("ModApps/Marketea/set_editorStorage", []);
			this.$store.commit("ModApps/Marketea/set_desingStorage", []);
			this.$store.commit("ModApps/Marketea/set_customCssStorage", []);
		},
		editForm(i)	{
			this.$router.push({ name: 'BuilderFormsOLD', params: {id: i.id} });
		},
		getListForms() {
			this.isLoading = true;
			window.master.post(this.apis.get(), {
				org_id: window.localorgdata,
			})
			.then((response) => {
				let e = response.data;
				console.log(response.data);
				if (e.status) {
					this.listForms = e.r;
					this.statusSMS = e.statusSMS;
				}
				this.isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				this.isLoading = false;
			});
		},
		sendNotiTest(i) {
			this.$swal({
				title: 'Send test to',
					input: 'email',
					inputLabel: 'Your email address',
					inputPlaceholder: 'Enter your email address'
			}).then((result) => {
				//console.log(result);
				if(result.isConfirmed && result.value.length>0){
					this.isLoading = true;
					window.master.post(this.apis.sendtest(), {
						form_id: i.id,	
						email_test: result.value		
					})
					.then((response) => {
						let e = response.data;
						console.log(response.data);
						this.$notify({
							group: "noti",
							type: "success",
							title: "Success",
							text: "Notification email sent",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
				}
			});
		},
		sendNotiTestsms(i) {
			let t= this;
			if(!t.PlanPremium){t.showMsgError(1);}
			else{
				this.$swal({
					title: 'Send test SMS to',
						input: 'text',
						inputLabel: 'Your phone number',
						inputPlaceholder: 'Enter your phone number'
				}).then((result) => {
					//console.log(result);
					if(result.isConfirmed && result.value.length>0){
						this.isLoading = true;
						window.master.post(this.apis.sendtestsms(), {
							form_id: i.id,
							phone_test: result.value
						})
						.then((response) => {
							let e = response.data;
							console.log(response.data);
							this.$notify({
								group: "noti",
								type: "success",
								title: "Success",
								text: "Notification SMS sent",
							});
							this.isLoading = false;
						})
						.catch((error) => {
							console.log(error);
							this.isLoading = false;
						});
					}
				});
			}
		},
	},
    watch: {
        getModulesMenu: function(){
            let t = this;
            console.log('watch getModulesMenu', t.getModulesMenu)
            t.estructuraMenu();
        }
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
        ...mapGetters("core/apps/", { getModulesMenu: "getModulesMenu" })
    },
}
</script>
<style lang="css" scoped>
</style>
