<template>
    <div>
        <div
            id="main-wrapper"
            data-navbarbg="skin6"
            data-theme="light"
            data-layout="vertical"
            :data-sidebartype="showAside ? 'min' : 'full'"
            data-boxed-layout="full"
        >
            <Header-Partial></Header-Partial>
            <div v-if="access_billing">
                <Aside-Partial
                    v-if="getHeader"
                    @activarLateral="showAside = $event"
                ></Aside-Partial>
                <div
                    v-if="getHeader"
                    class="page-wrapper"
                    v-bind:class="{ show: showAside }"
                >
                    <router-view></router-view>
                    <footer-partial></footer-partial>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPartial from "@/core/views/Header";
import FooterPartial from "@/core/views/Footer";
import AsidePartial from "@/apps/billing/views/Aside";
export default {
    name: "Billing",
    components: {
        HeaderPartial,
        AsidePartial,
        FooterPartial,
    },
    data() {
        return {
            showAside: false,
            isLoading: true,
            permiso_billing: false,
            permiso_header: false,
        };
    },
    created() {
        let validSession = this.$store.getters["core/session/loggedIn"];
        this.permiso_header = this.$store.getters["core/getHeader"];
        this.permiso_billing = this.$store.getters["ModApps/billing/access_billing"];
        let statusBilling = localStorage.getItem('statusBilling');
        console.log("obteniendo permiso de sesion master");
        if (validSession && this.permiso_header && statusBilling==1) {
            // console.log('bien validAccess');
            this.validAccess();
        }
        // this.validLink();
    },
    methods: {
        validLink() {
            let link = this.$router.currentRoute.name;
            if (link == "Timesheet") {
                this.link = true;
            } else {
                this.link = false;
            }
        },
        validAccess() {
            let t = this;
            t.$store.commit("set_isLoading", true);
            // console.log("set_isLoading true");
            if (t.permiso_billing) {
                t.$store
                    .dispatch("ModApps/billing/updateDataCompany", window.localorgdata)
                    .then((response) => {
                        console.log("updateDataCompany", response);
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log("error al actualizar data Org");
                        t.loginApp(true);
                        //t.$router.push({ name: 'Home' });
                    });
                //comprobacion de session en segundo plano, de estar mal mandara actualizar las credenciales
                t.$store
                    .dispatch("ModApps/billing/validarSession")
                    .then((response) => {
                        if (!response.data.tokenValid) {
                            t.loginApp(true);
                        } else {
                            const accessBilling = {
                                login: response.data.tokenValid,
                                token: window.localtoken,
                            };
                            // console.log('llamando updateDataCompany');
                            t.getDataCompany(accessBilling);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log("error validarSession");
                        // t.loginApp(true);
                        // t.$router.push({ name: 'Home' });
                    });
            } else {
                t.$store.dispatch("core/apps/validAccess").then((response) => {
                    let AppBilling = response;
                    if (AppBilling) {
                        // si tiene Billing asignado entonces validar acceso
                        // valido si ya habia iniciado sesion o no
                        if(localStorage.getItem('validTokenBilling')){
                            // si existe token valido actualizo datos de company
                            const accessBilling = {
                                login: true,
                                token: window.localtoken,
                            };
                            console.log('llamando updateDataCompany');
                            t.getDataCompany(accessBilling);
                        } else {
                             // de no existir comprobador de token envio solicitud de login
                            t.$store.dispatch("ModApps/billing/validTokenBilling")
                            .then((response) => {
                                //console.log(response.data);
                                if (response.data.tokenValid) {
                                    //console.log('validTokenBilling');
                                    let scopes = JSON.parse(response.data.success.scopes);
                                    const accessBilling = {
                                        login: response.data.tokenValid,
                                        token: window.localtoken,
                                    };
                                    console.log('llamando updateDataCompany');
                                    t.getDataCompany(accessBilling);
                                } else {
                                    t.loginApp(false);
                                }
                            });
                        }
                    } else {
                        const accessBilling = { login: false, token: false };
                        t.$store.dispatch(
                            "ModApps/billing/access_billing",
                            accessBilling
                        );
                        //console.log('sino volver a home');
                        t.$router.push({ name: "Home" });
                    } //sino volver a home
                });
            }
        },
        loginApp(refreshB) {
            this.$store
                .dispatch("ModApps/billing/retrieveTokenBilling", {
                    loginBIlling: true,
                })
                .then((response) => {
                    //console.log(response.data);
                    //console.log('iniciando session billing');};
                    if (refreshB) {
                        window.location.reload(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log("error al iniciar sesion billing");
                    this.$router.push({ name: "Home" });
                });
        },
        getDataCompany(accessBilling){
            this.$store
            .dispatch("ModApps/billing/updateDataCompany", window.localorgdata)
            .then((response) => {
                this.$store.dispatch("ModApps/billing/access_billing", accessBilling);
                this.$store.commit("set_isLoading", false);
                this.$store.dispatch("core/apps/getPlanApp").then(r => {
                    console.log('getPlanApp', r)
                    this.getAppStatus();
                })
            })
            .catch((error) => {
                console.log(error);
                console.log("error al actualizar data Org");
                this.loginApp(true);
                //this.$router.push({ name: 'Home' });
            });
        },
        getAppStatus(){
            let s = this.$store.getters["core/apps/getAppStatus"];
            let planActual = this.$store.getters["core/apps/getPlanIdApp"];
            // console.log('planActual', planActual)
            // planActual < 3 exluir sponsore y plan free
            if(s.paymentStatus != 1 && planActual < 3){
                let msj = this.$store.getters["core/apps/getMsgPaymentAlert"];
                this.$store.commit('core/setNotiPush', {
                    title: msj.title,
                    text: msj.msg,
                    show: true,
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    duration: 15000
                });
            }
        }
    },
    watch: {
        showAside: function () {
            if (
                this.$router.currentRoute.name == "Billing" ||
                this.$router.currentRoute.name == "Default"
            ) {
                this.$store.dispatch("ModApps/billing/setShowAside", this.showAside);
            }
        },
        getHeader: function () {
            // console.log("getHeader ", this.getHeader);
            this.permiso_header = this.getHeader;
            this.permiso_billing=this.$store.getters["ModApps/billing/access_billing"];
            this.validAccess();
        },
    },
    computed: {
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" }),
        ...mapGetters("core/apps", {
            getAppIDactual: "getAppIDactual",
            listApps: "appAll",
        }),
        ...mapGetters("ModApps/billing", { access_billing: "access_billing" }),
    },
};
</script>
<style scoped>
#main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
    z-index: 1;
    margin-left: 160px;
}
</style>
