<template>
    <div class="card">
        <div class="card-header">
            <h3>Link Google Drive <i class="fab fa-google-drive"></i></h3>
        </div>
        <div class="card-body" style="padding-top: 0;">
            <div class="row">
                <Loader class="loading_drive" v-if="isLoading" :getProgress="loadProgress"></Loader>
                <div class="col-12">
                    <form class="input-group mb-3" @submit.prevent="saveFile()">
                        <input type="text" class="form-control" placeholder="Name Folder (Optional)" style="width: 30%;" v-model="name"/>
                        <input type="text" class="form-control" placeholder="Link Google Drive (Required)" required style="width: 60%;" v-model="file_name"/>
                        <span class="input-group-text" style="padding:0;">
                            <button class="btn btn-success"><i class="fa fa-save"></i></button>
                        </span>
                    </form>
                    <button class="btn btn-info btnUploadLink" v-if="file_name" @click="openLink(file_name)">Open Link <i class="fab fa-google-drive" style="margin-left: 10px;"></i></button>
                    <div class="alert alert-info" v-if="!file_name">
                        <p style="margin-bottom:0;">Share a Google Drive link</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    data() {
        return {
            item: null,
            type: 2, // solo google-drive
            name: '',
            file_name: null,
            loadProgress: 0,
            isLoading: false,
            fileID: null,
        };
    },
    created() {
			let t = this;
			t.item = t.$store.getters['core/getOpenModal'].data;
            t.getLinkDrive();
    },
    methods : {
        getLinkDrive() {
            let t = this;
            t.isLoading= true;
            window.master.post("getSaleFilesLinks", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sale_id: t.item.id,
                type: t.type,
            })
            .then((response) => {
                console.log('getSaleFilesLinks', response.data);
                t.isLoading= false;
                if(response.data.get){
                    t.file_name = response.data.get.file_name;
                    t.name = response.data.get.name;
                    t.fileID = response.data.get.id;
                }else{
                    t.fileID = 0;
                    t.file_name =null;
                    t.name =null;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
            });
        },
        saveFile() {
            let t = this;
            t.isLoading= true;
            window.master.post("setSalesfilesLinks", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sale_id: t.item.id,
                type: t.type,
                name: t.name,
                file_name: t.file_name,
            })
            .then((response) => {
                console.log('getSaleFilesLinks', response.data);
                t.isLoading= false;
                t.getLinkDrive();
                t.$parent.close();
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
            });
        },
        delLinkDrive(){
            let t = this;
            t.$swal({
				title: "Delete link?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete!",
			}).then((result) => {
				if (result.value) {
                    t.isLoading= true;
                    window.master.post("delSaleFilesLinks", {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        sale_id: t.item.id,
                        type: t.type,
                        id: t.fileID,
                    })
                    .then((response) => {
                        console.log('delSaleFilesLinks', response.data);
                        t.getLinkDrive()
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading= false;
                    });
                }
            });
        },
        openLink(link) {
            window.open(link, "_blank");
        },
    }
}
</script>
<style lang="scss" scoped>
.loading_drive{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0 0 0 / 25%);
    .container-progress{
        top: 0px;
        width: 80%;
        .progress{
            height: 10px;
        }
    }
}
.btnUploadLink{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
}
</style>
