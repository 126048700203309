<template>
	    <div class="card">
        <div class="card-header">
            <h4 >Forgot Password</h4>
        </div>
        <form @submit.prevent="sendForgot" autocomplete="off">
            <div class=" card-body">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-row" >
                            <div class="form-group col-md-12">
                                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                    <b>Error: </b>{{messageError}}
                                </div>
                                <div class="alert alert-success" role="alert" v-if="messageSuccess">
                                    <b>Successful change, you can login again.</b>
                                </div>
                                <div class="password-input">
                                    <span class="showpassord icon-eye" action="hide" >
                                        <i class="fas fa-lock"></i>
                                    </span>
                                    <input id="code" type="number" maxlength="4" class="form-control code" v-model="code" name="code" placeholder="****" required  autocomplete="new-password2">
                                </div>
                            </div>
                            <div class="form-group col-md-12" style="margin-bottom: 0px;">
                                <!-- <label for="password">Password</label> -->
                                <div class="password-input passsecurity">
                                    <span class="showpassord icon-eye" >
                                    	<i class="fas fa-shield-alt"></i>
                                    </span>
                                    <password
                                            v-model="password"
                                            placeholder="New password"
                                        />
                                    <!-- <password id="password" :type="typepassword" class="form-control" v-model="password" name="password" placeholder="New password"  autocomplete="current-password" required> -->
                                </div>
                            </div>
                            <div class="form-group col-md-12" style="margin-bottom: 0px;">
                                <!-- <label for="password">Password</label> -->
                                <div class="password-input">
                                    <span class="showpassord icon-eye" action="hide" @click="shoPass">
                                        <i class="fas fa-eye" v-if="showpassword"></i>
                                        <i class="fas fa-eye-slash" v-else></i>
                                    </span>
                                    <input id="password2" :type="typepassword" class="form-control" v-model="password2" name="password2" placeholder="Confirm password"  autocomplete="current-password" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="card-footer">
                <div v-if="!isLoading">
                    <button type="submit" class="btn btn-success btn-login">Change</button>
                    <button type="button" class="btn btn-sing creat-account" @click="backLogin">Go to login </button>
                </div>
                <div v-else>
                    <Loader></Loader>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Password from 'vue-password-strength-meter'
import Loader from '@/core/components/shared/Loader'
import '@/core/assets/css/forgot.css'
export default {
    name: 'formLogin',
    components: {
		Loader,
		Password
    },
    data () {
        return {
            code: null,
            messageError: null,
            password: null,
            password2: null,
            isLoading: false,
            showpassword: false,
            typepassword: 'password',
            urlPage: window.urlweb,
            formLogin: true,
            messageSuccess: false
        }
    },
    created() {
        document.addEventListener('load', function() {
            console.log('load');
        });
    },
    methods: {
    	backLogin() {
    		this.$router.push({name: "Login"});
    	},
        sendForgot() {
            let t = this;
            if(t.password != t.password2) {
            	t.messageError= 'Error, passwords do not match.';
            }
            else if(t.password.length <= 5) {
            	t.messageError= 'Error, the fields are mandatory and larger than 5 characters.';
            }
            else {
            	t.isLoading = true;
            	window.masterApiv2.post("changepass", {
                	pass: t.password,
                	token: t.code,
	            })
	            .then(response => {
	                t.password = '';
	                t.password2 = '';
	                t.code = '';
	                t.isLoading = false;
	                if(!response.data.status){
	                    t.messageError= 'Error, the token is not correct or was already used.';
	                    t.messageSuccess = false;
	                } else {
	                    t.messageSuccess = true;
	                    t.messageError= null;
	                }
	            })
	            .catch(error => {
	                console.log(error);
	                t.isLoading = false;
	                t.messageError= 'Error de conexion';
	                t.messageSuccess = false;
	            });	
            }            
        },
        shoPass() {
            this.showpassword = !this.showpassword;
            // console.log('password show ', this.showpassword);
            if(this.showpassword){ this.typepassword = true;}
            else{ this.typepassword= 'password';}
        }
    },
    watch: {
    	"code": function() {
    		if(this.code.length > 4) {
    			let c = this.code.split('');
    			c.splice(4);
    			this.code = c.toString().replaceAll(',', '');
    		}
    	}
    }
};
</script>
<style lang="css" scoped>
@import '../../assets/css/forgot.css';
.card {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;
    border-radius: 2px;
    margin-top:40px;
}
.card-header {
    padding: 15px 13px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
.card-header h4 {
    margin-bottom: 0px;
    color: #555;
}
.card-footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
button.btn.btn-success.btn-login {
    padding: 2px 20px;
    border-color: #0d83dd;
    background-color: #0d83dd;
    box-shadow:none;
}
.btn-sing {
    padding: 2px 10px;
    color: #1f1f1f ;
    background-color: #01972000 ;
    border-color: #1f1f1f ;
    border-radius: 2px;
    float: right;
}
.creat-account{
    border-color: #aaa ;
}
.form-control.code {
	font-size: 24px;
	letter-spacing: 10px;
	padding-top: 10px;
}
.form-control {
    border: 1px solid #bdbdbd;
    background-color: #fbfbfb !important;
    height: 50px;
}
.form-control:focus {
    background-color: #fbfbfb !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus 
input:-internal-autofill-selected
{
    -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
    background-color: #fbfbfb !important;
}
.showpassord{
    height: 50px;
    border-left: 1px solid;
    z-index: 1;
}
@media (max-width: 1190px){
    .btn.btn-success.btn-login,
    .btn.btn-sing.creat-account {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 40px;
        font-size: 20px;
        float: initial;
    }
}
@media (max-width: 767px){
    .card {
        max-width: 330px;
        margin: auto;
    }
}
@media (max-width: 350px){
    .card {
        max-width: 300px;
        margin: auto;
    }
}

</style>
