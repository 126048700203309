<template>
	<div class="card">
		<div class="card-body">
			<form @submit.prevent="changePass">
				<div class="row">
					<div class="col-12">
						<label for="">Password</label>
						<div class="passsecurity">
							<password v-model="f.newPassword" placeholder="********"/>
						</div>
					</div>
					<div class="col-12">
						<label for="">Confirm</label>
						<input type="password" class="form-control"  v-model="f.rePassword" placeholder="********">
					</div>                          
					<div class="col-12">
						<label for="inputEmail4" style="display: block;margin-top: 23px;"><span style="color:#5198d4;"></span></label>
						<button class="btn btn-success" style="margin-right: 10px;" type="submit">SAVE</button> 
						<button type="button" class="btn" @click="closeModal()">CANCEL</button>
					</div>              
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import password from 'vue-password-strength-meter'
export default {
	name: 'ChanguePass',
		components: {
		password,
	},
	// props:{
	// 	accountID:{type: Number, required:true}
	// },
	data () {
		return {
			accountID: 0,
			isLoading: false,
			user_id: window.master_client,
			org_id: window.localorgdata,  
			f: {
				newPassword:'',
				rePassword:'',
				account_id:'',
			}, 		     
		}
	},
	created(){
		this.init();
	},
	methods: {
		init() {
			let g = this.$store.getters['core/getOpenModal'];
			this.accountID = g.data.account_id;
		},
		closeModal(){
			this.f.newPassword = '';
			this.f.rePassword = '';        
			this.$store.commit("core/setCloseModal", true);
		},     
		changePass(){
			this.f.account_id = this.accountID;
			if(this.f.newPassword !== this.f.rePassword) {
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'Password and Confirm, they must be the same.',
					duration: 10000
				});
			}
			else if(this.f.newPassword.length < 6) {
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'the password must have more than 6 characters.',
					duration: 10000
				});
			}else{
				this.$store.commit('core/setLoadingModal', true);
				window.master.post('/user/changePass', {
					id : this.f.account_id,
					new_password: this.f.newPassword,
					new_confirm_password: this.f.rePassword,
				}).then((response) => {
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '1', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Update',
						text: 'Password Successful.',
						duration: 3000
					});
					this.$store.commit("core/setCloseModal", true);
					this.f.newPassword = '';
					this.f.rePassword = ''; 
					this.f.account_id = '';            
					console.log(response.data);
					this.$modal.hide('cPass');
				}).catch((error) => {
					console.log(error);
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit("core/setCloseModal", true);
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '3', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Error',
						text: 'updating data.',
						duration: 10000
					});
				}); 
			}
		},	
	},
	watch:{},
	computed: {}
};
</script>
<style scoped>

.passsecurity{
	position: relative;
}
.Password{
	max-width: 100%!important;
}
.Password__field {
		background-color: #fff!important;
		border: 1px solid #d8d8d8!important;
		border-radius: 2px!important;
		box-sizing: border-box!important;
		font-size: 14px!important;
		padding: 9px!important;
		width: 100%!important;
}
.Password__strength-meter{
			margin: 0px auto 0px!important;
}
.icontri{
		position: absolute;
		top: -21px;
		left: 50%;
		display: block;
		width: 0;
		height: 0;
		border-right: 10px solid transparent;
		border-top: 10px solid transparent;
		border-left: 10px solid transparent;
		border-bottom: 10px solid #a1e0ae;
		transform: translateX(-50%);
}
	.prof_title{
		display:block;
		font-size: 14px;
	}
	.prof_title h4{
		font-weight: 100;
		font-size: 24px;
		text-align:center;
	}
	.segurity{
		margin-top:60px;
	}
	.segurity label{
		display:block;
		margin-bottom: .1rem;
	}
</style>
