<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-5 align-self-center">
                        <h4 class="page-title">Organization Information</h4>
                    </div>
                    <div class="col-md-7 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                <form
                    @submit.prevent="MetCompanyUpdate"
                    enctype="multipart/form-data"
                    id="form1"
                >
                    <div class="row">
                        <div class="col-md-8">
                            <div class="card card-body estilosform">
                                <div
                                    class="alert alert-success"
                                    role="alert"
                                    v-if="showvalidateform"
                                >
                                    A <b>4</b> digit code was sent to your email. Do not
                                    forget to check your spam folder, if it does not
                                    arrive in 5 minutes, resend.
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Organization Information</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            v-model="FormCompanyInfo.companyinfo"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Contact Name</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.contactName"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Last Name</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.lastname"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >E-mail</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <div
                                            style="display: flex; align-items: start"
                                            class="dmain"
                                        >
                                            <div
                                                style="max-width: 200px; min-width: 52%"
                                                class="emarea"
                                            >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="FormCompanyInfo.email"
                                                    :disabled="disableeditemail"
                                                />
                                                <small
                                                    >Your email must be verified before to
                                                    send Notifications</small
                                                >
                                            </div>
                                            <div
                                                style="
                                                    display: flex;
                                                    align-items: start;
                                                    margin-left: 10px;
                                                    flex-wrap: wrap;
                                                    position: relative;
                                                "
                                                v-if="showvalidateform"
                                                class="bformv"
                                            >
                                                <div style="width: 100%">
                                                    <input
                                                        type="text"
                                                        class="form-control inputcode"
                                                        max="4"
                                                        v-model="
                                                            FormCompanyInfo.email_verify_code_enter
                                                        "
                                                        placeholder="xxxx"
                                                        style="
                                                            font-size: 20px;
                                                            height: 32px;
                                                            width: 70px;
                                                        "
                                                    />
                                                </div>
                                                <small style="width: 100%"
                                                    >Enter the <b>4</b> digit code that
                                                    was sent to your email
                                                    <a
                                                        href="javascript:"
                                                        @click="verifyemail"
                                                        >(Resend Code)</a
                                                    ></small
                                                >
                                                <button
                                                    type="button"
                                                    class="btn btn-success btn-sm btnv"
                                                    style="
                                                        margin-right: 5px;
                                                        position: absolute;
                                                        right: 95px;
                                                    "
                                                    :disabled="btndisable"
                                                    @click="ValidateBtn"
                                                >
                                                    Verify Now
                                                </button>
                                            </div>
                                            <button
                                                type="button"
                                                class="btn btn-info btn-sm"
                                                style="margin-right: 10px"
                                                @click="verifyemail"
                                                v-if="showvalidatebtn"
                                            >
                                                Verify email
                                            </button>
                                            <div
                                                v-if="
                                                    FormCompanyInfo.bill_email_verify == 1
                                                "
                                            >
                                                <span
                                                    style="
                                                        color: #00a759;
                                                        padding-left: 4px;
                                                    "
                                                >
                                                    Email Verified
                                                    <i class="fas fa-check"></i
                                                ></span>
                                            </div>
                                            <button
                                                type="button"
                                                class="btn btn-sm"
                                                style="margin-right: 10px"
                                                @click="disabledEmail"
                                                v-if="
                                                    FormCompanyInfo.bill_email_verify == 1
                                                "
                                            >
                                                Edit
                                            </button>
                                        </div>
                                        <span
                                            v-if="emailInvalid"
                                            class="alert alert-danger"
                                            style="padding: 5px"
                                        >
                                            Please enter a valid email format
                                        </span>
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Address</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.address"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label">City</label>
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.city"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label">State</label>
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.state"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Zip Code</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.zipcode"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Telephone</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.telephone"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Website</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormCompanyInfo.website"
                                        />
                                    </div>
                                    <div class="col-md-3 text-right marginTop">
                                        <label for="" class="col-form-label"
                                            >Time zone</label
                                        >
                                    </div>
                                    <div class="col-md-9 marginTop">
                                        <select
                                            class="form-control"
                                            v-model="FormCompanyInfo.timezone_id"
                                            :disabled="editZone"
                                        >
                                            <option
                                                :value="i.id"
                                                v-for="(i, index) in timezonesList"
                                                v-bind:key="index"
                                            >
                                                {{ i.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-12">
                                        <div
                                            style="
                                                margin-top: 15px;
                                                display: flex;
                                                justify-content: flex-end;
                                            "
                                        >
                                            <button
                                                type="submit"
                                                class="btn btn-success"
                                                style="margin-right: 10px"
                                                :disabled="btnsubmit"
                                            >
                                                UPDATE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card-header">ORGANIZATION’S LOGO</div>
                            <div class="card card-body">
                                <div class="logoapp">
                                    <div v-if="Showlogo" style="margin-top: 10px">
                                        <img
                                            v-bind:src="preview"
                                            id="logoPreview"
                                            @click="$refs.logo.click()"
                                            style="width: 100%; max-width: 600px"
                                            alt="Logo"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="delLogo"
                                            style="width: 100%; text-align: center"
                                        >
                                            <i class="fa fa-trash"></i> Delete
                                        </button>
                                    </div>
                                </div>
                                <div class="btnupload">
                                    <input
                                        type="file"
                                        style="display: none"
                                        ref="logo"
                                        accept="image/*"
                                        @change="filelogo"
                                    />
                                    <button
                                        type="button"
                                        class="btn btn-dark"
                                        @click="$refs.logo.click()"
                                    >
                                        <i class="fas fa-upload"></i> File browser
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </slot>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            btndisable: true,
            FormCompanyInfo: {
                action: "UPDATE",
                id: null,
                companyinfo: null,
                contactName: null,
                lastname: null,
                email: null,
                bill_email_verify: 0,
                bill_email_verify_code: null,
                email_verify_code_enter: null,
                address: null,
                address2: null,
                city: null,
                state: null,
                zipcode: null,
                telephone: null,
                cellphone: null,
                website: null,
                logo: null,
                dellogo: false,
                timezone_id: 1,
            },
            showvalidateform: false,
            showvalidatebtn: false,
            disableeditemail: false,
            Showlogo: false,
            validlogo: false,
            preview: "",
            logoOLD: null,
            emailInvalid: false,
            oldemail: null,
            btnsubmit: false,
            timezonesList: [],
            editZone: false,
        };
    },
    created() {
        this.getCompanyInfo();
        this.timezones();
    },
    methods: {
        timezones() {
            window.master
                .get("timezones")
                .then((response) => {
                    this.timezonesList = response.data.result;
                })
                .catch((error) => console.log(error));
        },
        disabledEmail() {
            this.disableeditemail = !this.disableeditemail;
            if(!this.disableeditemail) {
                this.FormCompanyInfo.bill_email_verify =0;
            }else {
                this.FormCompanyInfo.bill_email_verify =1;
            }
        },
        ValidateBtn() {
            this.isLoading = true;
            window.master
                .post("verifyemailorgnow", {
                    org_id: window.localuserdata,
                    code: this.FormCompanyInfo.email_verify_code_enter,
                })
                .then((response) => {
                    this.isLoading = false;
                    console.log(response.data);
                    this.showvalidateform = false;
                    this.showvalidateform = false;
                    if (response.data.status) {
                        this.FormCompanyInfo.email_verify_code_enter = null;
                        this.getCompanyInfo();
                        this.$notify({
                            group: "noti",
                            title: "UPDATED",
                            type: "success",
                            text: "This email has been successfully verified.",
                            duration: 10000,
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "UPDATED",
                            type: "error",
                            text: "The code is not correct, try again",
                            duration: 15000,
                        });
                        this.getCompanyInfo();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        verifyemail() {
            this.isLoading = true;
            window.master
                .post("verifyemailorg", {
                    org_id: window.localuserdata,
                    id: this.FormCompanyInfo.id,
                    org_name: this.FormCompanyInfo.companyinfo,
                    email: this.FormCompanyInfo.email,
                })
                .then((response) => {
                    console.log(response.data);
                    this.showvalidateform = true;
                    this.showvalidatebtn = false;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        delLogo() {
            this.FormCompanyInfo.dellogo = true;
            this.FormCompanyInfo.logo = null;
            this.Showlogo = false;
            this.validlogo = false;
            this.preview = "";
            this.$refs.logo.value = "";
            console.log(this.Showlogo, "logo ");
            if (this.logoOLD != null) {
                this.borrarLogo();
            }
        },
        borrarLogo() {
            this.isLoading = true;
            window.master
                .post("borrarlogo", { org_id: window.localuserdata, logo: this.logoOLD })
                .then((response) => {
                    if (response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "UPDATED",
                            type: "success",
                            text: "Update Successful",
                        });

                        this.logoOLD = null;
                        this.getCompanyInfo();
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        filelogo(event) {
            this.FormCompanyInfo.logo = event.target.files[0];
            this.Showlogo = true;
            this.validlogo = true;
            this.FormCompanyInfo.dellogo = false;
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                console.log(this.Showlogo, "logo ");
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#logoPreview").src = e.target.result;
                    this.preview = e.target.result;
                };
            }
        },
        getCompanyInfo() {
            this.isLoading = true;
            window.billing
                .get("companyinfo/" + window.localuserdata)
                .then((response) => {
                    console.log("info org actual ");
                    console.log(response.data);
                    //debugger;
                    if (response.data.status) {
                        this.FormCompanyInfo.id = response.data[0].id;
                        this.FormCompanyInfo.companyinfo =
                            response.data[0].bill_company_name;
                        this.FormCompanyInfo.contactName =
                            response.data[0].com_contact_name;
                        this.FormCompanyInfo.lastname = response.data[0].com_lastname;
                        this.FormCompanyInfo.email = response.data[0].bill_email;
                        this.FormCompanyInfo.bill_email_verify =
                            response.data[0].bill_email_verify;
                        this.FormCompanyInfo.bill_email_verify_code =
                            response.data[0].bill_email_verify_code;
                        this.FormCompanyInfo.address = response.data[0].com_address;
                        this.FormCompanyInfo.city = response.data[0].com_city;
                        this.FormCompanyInfo.state = response.data[0].com_state;
                        this.FormCompanyInfo.zipcode = response.data[0].com_zip_code;
                        this.FormCompanyInfo.telephone = response.data[0].com_telephone;
                        this.FormCompanyInfo.website = response.data[0].com_website;
                        this.FormCompanyInfo.logo =
                            window.urlmaster + response.data[0].logo;
                        this.Showlogo = response.data[0].Showlogo;
                        this.preview = window.urlmaster + response.data[0].logo;

                        if (
                            (response.data[0].bill_email_verify == 0 &&
                                response.data[0].bill_email_verify_code == "") ||
                            response.data[0].bill_email_verify_code == null
                        ) {
                            this.showvalidatebtn = true;
                        } else if (
                            (response.data[0].bill_email_verify == 0 &&
                                response.data[0].bill_email_verify_code !== "") ||
                            response.data[0].bill_email_verify_code !== null
                        ) {
                            this.showvalidateform = true;
                            this.showvalidatebtn = false;
                        }
                        if (response.data[0].bill_email_verify == 1) {
                            this.disableeditemail = true;
                            this.showvalidateform = false;
                        }
                        if (this.Showlogo) {
                            this.logoOLD = window.urlmaster + response.data[0].logo;
                        }
                        if (response.data[0].bill_email_verify == 1) {
                            this.showvalidatebtn = false;
                        }
                        this.editZone = true;
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text: "Error",
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        MetCompanyUpdate() {
            var self = this;
            self.isLoading = true;
            var f = new FormData();
            console.log("data a recorrer", self.FormCompanyInfo);
            for (let prop in self.FormCompanyInfo) {
                console.log(prop, self.FormCompanyInfo[prop]);
                if (prop != "logo") {
                    if (self.FormCompanyInfo[prop] == null) {
                        f.append(prop, "");
                    } else {
                        f.append(prop, self.FormCompanyInfo[prop]);
                    }
                }
            }
            if (this.disableeditemail) {
                var emailchange = "0"; // disabled active
            } else {
                emailchange = "1"; // change email
            }
            f.append("user_id", window.localuserdata);
            f.append("emailchange", emailchange);
            console.log("formData", f);
            let re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (!re.test(this.FormCompanyInfo.email)) {
                self.emailInvalid = true;
                self.isLoading = false;
                console.log("formato de email invalid");
            } else {
                self.emailInvalid = false;
                window.master
                    .post("updateorg", f)
                    .then((response) => {
                        console.log(response.data);
                        //this.getCompanyInfo();
                        if (response.data.status) {
                            this.oldemail = response.data.emailold;
                            if (
                                self.FormCompanyInfo.logo !== null &&
                                self.validlogo == true
                            ) {
                                console.log("subir logo");
                                this.subirLogo();
                            } else {
                                this.$notify({
                                    group: "noti",
                                    title: "UPDATED",
                                    type: "success",
                                    text: "Update Successful",
                                });

                                self.isLoading = false;

                                this.showvalidateform = false;
                                this.getCompanyInfo();
                                //this.showvalidatebtn = false
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        self.isLoading = false;
                    });
            }
        },
        subirLogo() {
            if (this.FormCompanyInfo.action == "UPDATE") {
                var self = this;
                var f = new FormData();
                console.log("data a recorrer", self.FormCompanyInfo);
                for (let prop in self.FormCompanyInfo) {
                    console.log(prop, self.FormCompanyInfo[prop]);
                    if (
                        prop == "logo" &&
                        self.FormCompanyInfo[prop] !== null &&
                        self.validlogo == true
                    ) {
                        f.append(
                            prop,
                            self.FormCompanyInfo[prop],
                            self.FormCompanyInfo[prop].name
                        );
                    }
                }
                f.append("validlogo", self.validlogo);
                f.append("user_id", window.localuserdata);
                console.log("formData", f);
                window.master
                    .post("subirlogo/" + window.localuserdata, f)
                    .then((response) => {
                        if (response.data.status) {
                            this.$notify({
                                group: "noti",
                                title: "UPDATED",
                                type: "success",
                                text: "Update Successful",
                            });

                            this.getCompanyInfo();
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                    });
            }
        },
    },
    watch: {
        "FormCompanyInfo.email_verify_code_enter": function () {
            this.btndisable = false;
        },
        "FormCompanyInfo.email": function () {
            //this.showvalidateform = false
            if(!this.disableeditemail){
                this.showvalidatebtn = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.btnv {
    @media (max-width: 1090px) {
        right: 25px !important;
    }
}
.bformv {
    @media (max-width: 900px) {
        display: block !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 1024px) {
    .btnv {
        right: 19px !important;
    }
}
@media (min-width: 1366px) {
    .btnv {
        right: 70px !important;
    }
}
@media (min-width: 1500px) {
    .btnv {
        right: 43% !important;
    }
}
@media (max-width: 900px) {
    .dmain {
        display: block !important;
    }
    .emarea {
        min-width: 100% !important;
    }
    .inputcode {
        width: 172px !important;
    }
}
.estilosform {
    .col-form-label {
        color: #a5a5a5;
        padding-bottom: 0;
        font-size: 13px;
    }
}
.marginTop {
    margin-top: 7px;
}
.logoapp {
    width: 230px;
    border: 1px solid#ccc;
    padding: 8px;
    margin: 0 auto;
    img {
        width: 100%;
    }
}
.btnupload {
    width: 230px;
    margin: 0 auto;
    margin-top: 15px;
    button {
        width: 100%;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
