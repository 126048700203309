<template>
	<div class="card">
		<div class="card-header">
			<h5 class="card-title">
				Edit Tags 
				<span class="badge badge-pill badge-info">{{idJob}}</span>
			</h5>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="from-group col-md-10">
					<multiselect
					v-model="$parent.$parent.$parent.tags"
					class="form-control"
					tag-placeholder="Add this as new tag"
					placeholder="Search or add a tag"
					label="name"
					track-by="id"
					:options="$parent.$parent.$parent.tagsList"
					:multiple="true"
					:taggable="true"
					@tag="addTags"
					></multiselect>
				</div>
				<div class="from-group col-md-2">
					<button type="button" class="btn btn-success" @click="$parent.$parent.$parent.saveTags">
						<i class="fa fa-save"></i> Save
					</button>
				</div>
				{{ $parent.$parent.$parent.tags }}
			</div>
		</div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
	name: 'assignTags',
	components: {
		Multiselect,
	},
	data () {
		return {
			idJob: 0,
			setTag: {
                name: "",
                user_id: window.master_client,
                org_id: window.localorgdata,
            },
			apis: null,
		}
	},
	created() {
		console.log('list tags parent ', this.$parent)
		console.log('get_editTags.idJob', this.$parent.$parent.$parent.get_editTags.idJob)
		this.idJob = this.$parent.$parent.$parent.get_editTags.idJob;
		let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.setup;
	},
	methods:{
		addTags(newTag){
			let t = this;
			t.setTag.name = newTag;
			console.log(newTag)
			let url = t.apis.addTags();
            t.$store.commit("core/setLoadingModal", true);
			window.master.post(url, t.setTag)
			.then((response) => {
				console.log(response.data);
				t.$store.dispatch("ModApps/jobs/update_menuTags");
				t.$store.commit("core/setCloseModal", {
					action: 'saved',
					metodo:'returnTags'
				});
				t.$store.commit("core/setLoadingModal", false);
				t.$parent.$parent.$parent.tagsList.push({ id: response.data.id, name: newTag });
				t.$parent.$parent.$parent.tags.push({ id: response.data.id, name: newTag });
				t.$parent.$parent.$parent.saveTags();
				// t.$parent.close();
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
			});
		}
	}
};
</script>
<style lang="css" scoped>
</style>
