<template>
  <div>
    <div id="main-wrapper" >
      <HeaderReseller></HeaderReseller>
      <AsideReseller></AsideReseller>
        <div v-if="getHeader" class="page-wrapper" v-bind:class="{ sinmenu: sinmenu }" @click="ShowHiddenAside">
          <router-view></router-view>
        </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HeaderReseller from '@/core/views/Reseller/HeaderR'
  import AsideReseller from "@/core/views/Reseller/AsideReseller";
  export default {
    name:'DefaultLayoutR',
    components:{
      HeaderReseller,
      AsideReseller,
    },
    data(){
      return {
        show : false,
        accessAccount : false,
        sinmenu: true,
      }
    },
    created(){
      console.log('sinmenu', this.sinmenu)
    },
    methods: {
      ShowHiddenAside() {
          this.$store.commit("core/reseller/ActiveLateral", true);
      },
    },
    computed: {
      ...mapGetters('core/session', {access: 'loggedInResller'}),
      ...mapGetters('core', {getHeader: 'getHeader'}),
      ...mapGetters("core/reseller", ["lateral"]),
    },
    watch: {
        lateral: function () {
          this.sinmenu = this.lateral;
          console.log('sinmenu', this.sinmenu)
        },
    },
  };
</script>
<style lang="css" scoped>
  .page-wrapper {
    margin-left: 180px;
    margin-top: 50px;
    z-index: initial !important;
  }
  .page-wrapper.sinmenu {
    margin-left: 0px !important;
  }
  @media(max-width: 767px){
    .page-wrapper, .page-wrapper.sinmenu {
      margin-left: 0px !important;
    }
  }
</style>
