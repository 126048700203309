import { render, staticRenderFns } from "./tableResumenTotales.vue?vue&type=template&id=4c914838&scoped=true&"
import script from "./tableResumenTotales.vue?vue&type=script&lang=js&"
export * from "./tableResumenTotales.vue?vue&type=script&lang=js&"
import style0 from "./tableResumenTotales.vue?vue&type=style&index=0&id=4c914838&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c914838",
  null
  
)

export default component.exports