<template>
    <div>
        <div class="card">
            <div class="card-header">Add Product & Service</div>
            <div class="card-body">
                <form @submit.prevent="MetSAddProdService">
                    <div class="form-row">
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Account: 
                            </span>
                            <select class="form-control" v-model="FormAddPS.account_id">
                                <option value="0">--Select--</option>
                                <option
                                    v-for="item in filtroTypeofAccount"
                                    :key="item.id"
                                    v-bind:value="item.id"
                                >
                                    {{ item.name_account }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12">
                            <span id="" class="form-text text-muted">Name:</span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormAddPS.productname"
                                required
                                ref="nameProd"
                            />
                        </div>
                        <div class="col-12">
                            <span id="" class="form-text text-muted">Quantity:</span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormAddPS.quantity"
                                required
                            />
                        </div>
                        <div class="col-12">
                            <span id="" class="form-text text-muted">Unit Cost:</span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormAddPS.unitcost"
                                @keyup="checkVatId"
                            />
                            <p class="error" style="color: red" v-if="FormAddPS.vatError">
                                {{ FormAddPS.vatErrorMsg }}
                            </p>
                        </div>
                        <div class="col-12">
                            <span id="" class="form-text text-muted"> Description:</span>
                            <textarea
                                name=""
                                id=""
                                cols="10"
                                rows="2"
                                class="form-control form-text w100"
                                style="width: 100%"
                                v-model="FormAddPS.description"
                            ></textarea>
                        </div>
                        <div class="col-12">
                            <span id="" class="form-text text-muted"> Apply Tax:</span>
                            <select class="form-control" v-model="FormAddPS.applytax">
                                <option value="0">0.00% -None- (NN)</option>
                                <option
                                    v-for="item in TaxesLists"
                                    :key="item.id"
                                    v-bind:value="item.id"
                                >
                                    {{ item.name }}% {{ item.statename }}
                                </option>
                            </select>
                        </div>

                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">.</span>
                            <button
                                type="submit"
                                class="btn btn-success float-right"
                                :disabled="isDisabled"
                            >
                                {{ FormAddPS.btnName }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>    
    </div>
</template>
<script>
export default {
    components: { },
    name: "formProducts",
    data () {
        return {
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 44,
                quantity: 1,
                unitcost: 1,
                description: null,
                applytax: 0,
                status: 0,
            }, 
            TypeofAccount: [],
            TaxesLists: [],
            formList: {
                items: [],
            },
            ProdServiceLists: [],
        }
    },
    created () {
        this.init()
    },
    mounted(){
        this.$refs.nameProd.focus();
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data; 
            this.TaxesLists = r.TaxesLists
            this.TypeofAccount = r.TypeofAccount 
            this.FormAddPS = r.FormAddPS
            this.FormAddPS.productname=null;
            this.FormAddPS.account_id=44;
            this.FormAddPS.quantity=1;
            this.FormAddPS.unitcost=1;
            this.FormAddPS.description=null;
            this.FormAddPS.applytax=0;
            this.formList = r.formList;
        },
        MetSAddProdService() {
            if (this.FormAddPS.unitcost === 0) {
                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
                return false;
            }
            if (this.FormAddPS.Action === "Add") {
                if (this.FormAddPS.account_id === 0) {
                    this.$notify({
                        group: "noti",
                        title: "Error",
                        type: "error",
                        text: "You must select an Account.",
                    });
                } else {
                    this.$store.commit("core/setLoadingModal", true);
                    window.billing
                        .post("product-service/add", {
                            user_id: window.localuserdata,
                            account_id: this.FormAddPS.account_id,
                            productname: this.FormAddPS.productname,
                            quantity: this.FormAddPS.quantity,
                            unitcost: this.FormAddPS.unitcost,
                            description: this.FormAddPS.description,
                            applytax: this.FormAddPS.applytax,
                            status: 1,
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.status) {
                                let idTaxOpc = 0;
                                for (let i in this.TaxesLists) {
                                    if (
                                        this.TaxesLists[i].id === this.FormAddPS.applytax
                                    ) {
                                        idTaxOpc = this.TaxesLists[i];
                                    }
                                }
                                let unitcost = parseFloat(response.data.unitcost).toFixed(2);
                                let quantity = parseFloat(response.data.quantity).toFixed(2);
                                let model = {
                                    value: response.data.ProductId,
                                    text: response.data.productname,
                                    prodname: response.data.productname,
                                    unitcost: unitcost,
                                    descripcion: response.data.description,
                                    qty: quantity,
                                    taxOpc: idTaxOpc,
                                    applytax: this.FormAddPS.applytax,
                                };
                                // this.ProdServiceLists.push(model);
                                // this.formList.items[
                                //     this.FormAddPS.index_modal
                                // ].options = this.ProdServiceLists;
                                // this.formList.items[
                                //     this.FormAddPS.index_modal
                                // ].model = model;
                                // console.log("nueva linea", this.formList.items[this.FormAddPS.index_modal]);
                                let t = this
                                this.$store.commit("core/setLoadingModal", false);
                                this.$store.commit("core/setCloseModal", {
                                    action: 'saved',
                                    // formList:t.formList.items[this.FormAddPS.index_modal],
                                    model:model,
                                    methods:'products'                       
                                });         
                                this.$store.commit("core/setActionRefresh", {
                                    action: 'products',
                                    data: {
                                        // formList:t.formList.items[this.FormAddPS.index_modal],
                                        model:model,
                                    }
                                });                         
                            }
                            this.FormAddPS.account_id = 44;
                            this.FormAddPS.productname = null;
                            this.FormAddPS.quantity = 1;
                            this.FormAddPS.unitcost = 1;
                            this.FormAddPS.description = null;
                            this.FormAddPS.applytax = 0;
                            this.FormAddPS.status = 1;
                            this.$notify({
                                group: "noti",
                                title: "Created",
                                type: "success",
                                text: "Add Product Successful",
                            });
                            this.$parent.close();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$store.commit("core/setLoadingModal", false);
                        });
                }
            }          
        },
        validDecimal(num) {
            let vatIdRegex = new RegExp(
                /^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:.([0-9]{0,3}))?$/
            );
            let value = 0.0;
            if (vatIdRegex.test(num)) {
                if (num.indexOf(".") > -1) {
                    value = num;
                } else {
                    value = num + ".00";
                }
            }
            return Number(value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "");
        },
        checkVatId() {
            let vatIdRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);
            if (this.FormAddPS.unitcost === "") {
                this.FormAddPS.vatError = true;
                this.FormAddPS.vatErrorMsg = "Enter positive number.";
            } else if (!vatIdRegex.test(this.FormAddPS.unitcost)) {
                this.FormAddPS.vatError = true;
                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
            } else {
                this.FormAddPS.vatError = false;
            }
        },             
    },
    watch: {
    },
    computed: {
        filtroTypeofAccount() {
            return this.TypeofAccount.filter(function (u) {
                return u.account_type === 3 || u.account_type === 5;
            });
        },
        isDisabled: function () {
            if (this.FormAddPS.unitcost === 0) {
                return true;
            } else {
                return false;
            }
        },               
    },
}
</script>
