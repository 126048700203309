var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"taks"},[_c('div',{staticClass:"row"},[(_vm.displayBox.prefijo == 'imagen')?_c('div',{staticClass:"col8"},[(_vm.displayBox.urls_imagenTh)?_c('img',{class:[
                    'img-responsive imagen',
                    _vm.displayBox.orientacion == 1 ? 'horizontal' : 'vertical' ],attrs:{"src":_vm.displayBox.urls_imagenTh},on:{"click":function($event){return _vm.openFile(
                        _vm.displayBox.urls_imagenLg,
                        _vm.displayBox.orientacion,
                        _vm.displayBox.prefijo
                    )}}}):_c('img',{staticClass:"img-responsive horizontal",attrs:{"src":require("@/core/assets/no-image.png")}})]):(_vm.displayBox.prefijo == 'slider')?_c('div',{staticClass:"col8"},[(_vm.displayBox.urls_imagenTh)?_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.displayBox.archivosTH),function(i,index){return _c('swiper-slide',{key:index},[_c('img',{class:['img-responsive horizontal'],attrs:{"src":i},on:{"click":function($event){return _vm.openFile(
                                _vm.displayBox.archivosLG[index],
                                1,
                                'imagen'
                            )}}})])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_c('img',{staticClass:"img-responsive horizontal slider",attrs:{"src":require("@/core/assets/no-image.png")}})],1):_c('div',{staticClass:"col8"},[_c('img',{class:[
                    'img-responsive video',
                    _vm.displayBox.orientacion == 1 ? 'horizontal' : 'vertical' ],attrs:{"src":_vm.displayBox.urls_poster},on:{"click":function($event){return _vm.openFile(
                        _vm.displayBox.urls_video,
                        _vm.displayBox.orientacion,
                        _vm.displayBox.prefijo
                    )}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }