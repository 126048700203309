<template>
    <div class="main-nav-category">
        <nav class="nav-category">
            <ul class="menu-categores">
                <li class="btn-control"><a class="btn-add" @click.prevent="$emit('clickAction', {name: 'categories', data: []})"><i class="fa fa-cog"></i></a></li>
                <li v-for="(i, idx) in listMain" :key="idx">
                    <a :href="'#section-'+i.id" :title="i.name_cat" :data-id="i.id"
                    :class="[(getCatActive.id==i.id) ? 'active' : '', 'item-link-'+i.id]" @click.prevent="showmenu(i)">{{ i.name_cat }}</a>
                </li>
            </ul>
            <span class="indicador" id="indicador"></span>
        </nav>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            listMain: [],
        };
    },
    created(){
        let t = this;
        // console.log('created getListCat', t.getListCat);
    },
    mounted(){
        let t = this;
        t.listMain = t.$store.getters['ModApps/Marketea/get_bmenu_listCat'];
        setTimeout(() => {
            t.$emit('liOffsetWidth');
        }, 500);
    },
    watch: {
        getListCat: function(newVal, oldVal){
        let t = this;
        t.listMain = newVal;
        // console.log('newVal', newVal);
        // console.log('oldVal', oldVal);
        },
        listMain(){
            let t = this;
            t.showmenu(t.getCatActive);
        }
    },
    computed: {
        ...mapGetters("ModApps/Marketea/", {
            getListCat: "get_bmenu_listCat",
            getCatActive: "get_bmenu_catActive",
        }),
    },
    methods: {
        showmenu(i){
            let t = this;
            t.categoryActive = i;
            t.setStore('bmenu_catActive', t.categoryActive);
            setTimeout(() => {
                t.$emit('clickItem', i.id);
            }, 100);
        },
        setStore(key, val){
            let t = this;
            t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key, val});
        },
    }
}
</script>
<style lang="scss" scoped></style>
