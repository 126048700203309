<template>
	<div v-bind:class="['lateral-maquetador', { min: activarLateral }]">
		<div v-bind:class="['menu-maquetador', { min: activarLateral }]">
			<div class="botonera">
				<button class="btn btn-success">Save</button>
			</div>
		</div>		
        <div class="btnAsideAction" @click="ShowHiddenAside">
            <i v-bind:class="[
            	'fas fa-chevron-left',
            	{ min: activarLateral }
            ]"
            ></i> 
            <span v-if="activarLateral"> Max</span>
            <span v-else> Min</span>
        </div>
        <div class="btnBackDash" @click="backToDash">
        	<i class="fa fa-arrow-left"></i> Back to Dash
        </div>
	</div>
</template>
<script>
export default {
	name: 'lateralMaquetador',
	data () {
		return {
            activarLateral: false,
		}
	},
	methods: {
		backToDash() {
            this.$router.push({ name: 'listPages' });
        },
		ShowHiddenAside() {
			this.activarLateral = !this.activarLateral;
			this.$emit("activarLateral", this.activarLateral);
		},
	}
}
</script>
<style lang="css" scoped>
.lateral-maquetador{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 300px;
	height: calc(100% - 50px);
	margin-top: 50px;
	z-index: 100;
	border: 0px;
	border-right: 1px solid #000;
	overflow: hidden;
    transition: all 0.5s ease-out;
}
.lateral-maquetador.min {
    width: 60px;
}
menu-maquetador
.menu-maquetador{
	display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 0px solid red;
    height: calc(100% - 58px);
    padding: 5px;
    background-color: #fff;
}
.btnAsideAction {
	width: 100%;
    left: 0;
    top: calc(100% - 58px);
}
.btnBackDash {
	display: block;
	width: 100%;
	position: absolute;
	left: 0;
	top: calc(100% - 29px);
	height: 29px;
	background-color: #F23D3D;
	cursor: pointer;
	padding: 4px;
	line-height: 21px;
	text-align: center;
	color: #fff;
}
.btnBackDash:hover {
	cursor: pointer;
}
.lateral-maquetador.min .btnAsideAction i:before {
    content: "\f054";
}
.botonera{
	display: flex;
    border-bottom: 1px solid #000;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 5px;
	border-bottom: 1px solid #000;
}
</style>
