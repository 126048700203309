let updateGalleryCover = function(item) {
    let t = this;
    let job_id = item.job;
    let cover_id = item.cover;
    console.log('updateGalleryCover', job_id, cover_id);
    t.JobsGalleryList = [];
    for (let i in t.listTaks) {
        if (t.listTaks.hasOwnProperty(i)) {
            if (t.listTaks[i].id_i == job_id) {
                console.log('Job encontrado:', job_id);
                let images = t.listTaks[i].galleryList;
                let newList = [];
                for (let prop in images) {
                    if (images.hasOwnProperty(prop)) {
                        if(images[prop].id==cover_id){
                            images[prop].cover=1;
                            console.log('new cover ', images[prop].id, images[prop].name);
                        }else{
                            images[prop].cover=0;
                        }
                        newList.push(images[prop]);
                    }
                }
                t.JobsGalleryList = newList;
                t.listTaks[i].galleryList = newList;
            }
        }
    }
    t.reEscribirLista();
};
export default updateGalleryCover;
