// incluir todo los archivos js del directorio actual
const requireRutas = require.context('.', true, /\.js$/)
// variable para almacenar los path
const rutas = []; // todas las rutas de apps encontradas
const excluir = ['./routerIndex.js', './storeIndex.js', './_app_example', '_app_example']
// correr arreglo
var count = 0;
requireRutas.keys().forEach(fileName => {
  // excluir archivos
  if (excluir.indexOf(fileName) > -1) return
  // remplazar ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  // asignando valor del grupo app y su folder router
  const [appName, imported] = path.split('/')
  // solo si es router incluir
  if (appName !== '_app_example' && imported === 'router') {
    let path = fileName.replace(/(\.\/|\.js)/g, '')
    let [appName, imported, indexRouter] = path.split('/')
    // console.log('indexRouter', indexRouter)
    if(indexRouter === 'index')
    {
      // debugger;
      let modRutas = requireRutas(fileName).default
      if(modRutas.length > 0)
      {
        // debugger;
        for (let i in modRutas) {
          if (modRutas[i]) {
            rutas[count] = modRutas[i];
            count++;
          }
        }
      }
    }
  }
})
export default rutas;
