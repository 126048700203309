<template>
    <div class="main_site">
        <draggable tag="article" class="main_content"
            :list="siteContent" v-bind="dragSections" @start="isDragging(true)" @end="isDragging(false)" handle=".moveItem"
            group="sections" @change="saveMove" @add="addItem">
            <section v-for="(i, idx) in siteContent" :key="idx"
                :class="[
                    'row rows_site',
                    (i.class) ? i.class : '',
                    (siteContent.length == (idx + 1)) ? 'lastSection' : 'section-custom', (siteContent.length == (idx + 1)) ? 'firstSection' : ''
                ]"
                :id="'section-main' + idx"
                :style="cssSection(idx)"
            >
                <rowOpts v-if="rowOptsIdx==idx" :data="rowOptsIdx" @close="closeRowOpts($event)" @saveOpts="changeOpts($event)"></rowOpts>
                <div v-if="isBgImg(idx)" :style="bgImage(idx)"><div :style="bgImageOpacity(idx)"></div></div>
                <div class="btnsOpcSect before row">
                    <div class="col-12 text-left" style="padding:0px;">
                        <div class="optEdicion left">
                            <ul>
                                <li class="moveItem"><i class="fas fa-ellipsis-v"></i></li>
                                <!-- <li class="nameItem"></li> -->
                                <li @click="openRowOpts(idx)" v-if="i.modeDark==undefined"><i class="fas fa-cog"></i></li>
                                <li @click="modeDark(idx)" v-else><i class="fas fa-adjust"></i></li>
                                <li v-if="siteContent.length>1 && idx>0" @click="delSection(i, idx)" ><i class="fas fa-trash-alt"></i></li>
                                <!-- <li @click="addSectionBeforeAfter(i, idx, 'before')"><i class="fas fa-plus-circle"></i></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-for="(col_item, col_idx) in i.columns" :key="col_idx"
                :class="[
                    'columns_site',
                    (i.columns.length > 1 && (i.columns.length%2)==0) ? 'col-6' :
                        (((col_idx+1) - i.columns.length)==0) ? 'col-12' : 'col-6',
                    (col_item.class) ? col_item.class : ''
                    ]"
                >
                <draggable :list="col_item.elemts"
                    v-bind="dragWidgets"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    handle=".moveItemWidget"
                    tag="div"
                    group="Widgets"
                    class="widget_container"
                >
                    <div v-for="(elemt_item, elemt_idx) in col_item.elemts" :key="elemt_idx"
                        class="widgetItem" :id="'widgetItem-'+idx+'-'+col_idx+'-'+elemt_idx"
                    >
                        <div v-if="elemt_item.widget == 'text'" :style="textColor(idx)" class="widget_text">
                            <VueEditor v-model="elemt_item.text" :editorOptions='editorOptions'
                            :editor-toolbar="customToolbar" @text-change="editChange" class="widget_elemts_site widget_elemt_text" />
                        </div>
                        <div v-else-if="elemt_item.widget == 'image'" class="widget_image">
                            <img :src="elemt_item.url" class="widget_elemts_site widget_elemt_image"
                                :alt="elemt_item.text" />
                        </div>
                        <div v-else-if="elemt_item.widget == 'link'" class="widget_link_btn">
                            <a :href="elemt_item.url" class="widget_elemts_site widget_elemt_link">{{ elemt_item.text }}</a>
                        </div>
                        <div v-else-if="elemt_item.widget == 'iframe'" class="widget_iframe">
                            <div class="widget_elemts_site widget_elemt_iframe">
                                <iframe class="widget_elemt_iframe_embed"
                                :src="elemt_item.url"
                                :title="elemt_item.text"
                                frameborder="0"
                                ></iframe>
                            </div>
                        </div>
                        <div v-else-if="elemt_item.widget == 'videoYB'" class="widget_videoYB">
                            <div class="widget_elemts_site widget_elemt_iframe">
                                <iframe class="widget_elemt_iframe_youtube"
                                    :src="'https://www.youtube.com/embed/'+elemt_item.url+'?si=hAJI53Ootkl2qBuH'"
                                    :title="elemt_item.text"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </div>
                        <div v-else-if="elemt_item.widget == 'project'" class="widget_project">
                            <swiper class="swiper projectWidgetSlider" :options="swiperOption" :autoplay="swiperOption.autoplay">
                                <swiper-slide v-for="(p, pindex) in sliderProject" :key="pindex" :class="'swiper-slide-item'" >
                                    <img src="@/core/assets/img/no-image.png" v-bind:class="['img_cover_project']" v-if="p.coverImage==null || p.coverImage==''"/>
                                    <img :src="urlImgProject+p.coverImage+'/600/100'" v-bind:class="['img_cover_project']" v-else/>
                                    <div class="swiper-slide-content-project">
                                        <h4 class="pjt-title"><span>{{p.name}}</span></h4>
                                        <p class="pjt-text">
                                            <span class="location">{{p.location}}</span>
                                            {{p.space}} |
                                            {{p.bedrooms}} |
                                            {{p.baths}} |
                                            {{p.lot_size}}<br>
                                        </p>
                                        <p class="pjt-text"><button type="button" @click="ShowdModal('detailspjt', p)" class="btn-pjt">View Project</button></p>
                                    </div>
                                </swiper-slide>
                                <div class="swiper-button-prev" slot="button-prev"></div>
                                <div class="swiper-button-next" slot="button-next"></div>
                            </swiper>
                        </div>
                        <div v-else-if="elemt_item.widget == 'bMenu'" class="widget_bMenu">
                            <widget-bMenu :modeDark="i.modeDark"></widget-bMenu>
                        </div>
                        <div v-else-if="elemt_item.widget == 'accordion'" :style="textColor(idx)" class="widget_elemts_site widget_accordion">
                            <draggable tag="ul" class="accordion-list" :list="elemt_item.items"
                                @start="isDragging(true)" @end="isDragging(false)" handle=".accordion_moveItem" group="accordions">
                                <li v-for="(aItem, aIdx) in elemt_item.items" :key="aIdx" class="accordion_item">
                                    <div class="accordion_container">
                                        <span class="accordion_delete_item" @click="accodionDelItem(elemt_item.items, aIdx)"><i class="fas fa-times-circle"></i></span>
                                        <div :class='["accordion_title", "expand", (aItem.open==1) ? "open" : "closed"]' @click="accodionOpen(elemt_item.items, aIdx)">
                                            <span class="accordion_moveItem"><i class="fas fa-ellipsis-v"></i></span>
                                            <VueEditor v-model="aItem.title" :editorOptions='editorOptions' :editor-toolbar="customToolbarMin" @text-change="editChange" class="widget_elemts_site widget_elemt_text widget_elemt_title" />
                                        </div>
                                        <div :class='["accordion_content", (aItem.open==1) ? "open" : "closed"]'>
                                            <VueEditor v-model="aItem.text" :editorOptions='editorOptions' :editor-toolbar="customToolbarMin" @text-change="editChange" class="widget_elemts_site widget_elemt_text" />
                                        </div>
                                    </div>
                                </li>
                            </draggable>
                            <span class="accordion_add_item" @click="accodionAddItem(elemt_item.items)"><i class="fas fa-plus-square"></i></span>
                        </div>
                        <div class="row btn-add-widget" >
                            <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: flex-end;">
                                <div class="optEdicion">
                                    <ul>
                                        <li class="icon" v-if="col_item.elemts.length>1" @click="delWidget(idx, col_idx, elemt_idx)">
                                            <i class="fas fa-minus-square"></i>
                                        </li>
                                        <li class="icon"
                                            v-if="col_item.elemts.length==(elemt_idx+1)"
                                            @click="openAddWidget(idx, col_idx)"
                                        ><i class="fas fa-plus-circle"></i></li>
                                        <li class="icon moveItemWidget" v-if="col_item.elemts.length>1"><i class="fas fa-ellipsis-v"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <addWidgets
                        v-if="rowWidgetIdx==idx && colWidgetIdx==col_idx"
                        @exitAddWidget="closeAddWidget($event)"
                        @setAddWidget="saveAddWidget($event)"
                    ></addWidgets>
                </draggable>
                </div>
                <div class="btnsOpcSect after row" v-if="siteContent.length == (idx + 1)">
                    <div class="col-md-12 text-right" style="padding:0px;display: flex;justify-content: flex-end;">
                        <div class="optEdicion">
                            <ul>
                                <li @click="addSectionBeforeAfter(i, idx, 'after')"
                                    style="padding: 10px;line-height: 1;border-radius: 50%;"
                                >
                                    <i class="fas fa-plus-circle"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </draggable>
        <div class="newSection row" v-if="siteContent.length == 0">
            <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: center;">
                <div class="optEdicion">
                    <ul>
                        <li @click="ShowdModal('addSections', null)"><i class="fas fa-plus-circle"></i>Add</li>
                    </ul>
                </div>
            </div>
        </div>
        <modal name="miniSiteOptions" class="miniSiteOptions" draggable=".window-header" width="100%" height="auto" @before-close="beforeClose">
            <div class="card">
                <button @click="CloseModal" class="closeIcon"><span></span></button>
                <component :is="nameComp" :data="dataModal" @actionModal="jobsModal($event)"></component>
            </div>
        </modal>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
import draggable from 'vuedraggable'
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import "./popPreview.scss";
// @ts-ignore
import rowOpts from "@/apps/Marketea/components/minisite/site_desing/services/optSection.vue";
// @ts-ignore
import addSections from "@/apps/Marketea/components/minisite/site_desing/services/addSections.vue";
// @ts-ignore
import addWidgets from "@/apps/Marketea/components/minisite/site_desing/services/addWidgets.vue";
// @ts-ignore
import widgetIframeYB from "@/apps/Marketea/components/minisite/site_desing/services/widgetIframeYB.vue";
// @ts-ignore
import detailspjt from "@/apps/Marketea/components/minisite/site_desing/plugins/detailsProjectsWS.vue";
// @ts-ignore
import widget_bMenu from "@/apps/Marketea/components/minisite/site_desing/plugins/bMenu/widget_bMenu.vue";
// @ts-ignore
import widgetIframeCode from "@/apps/Marketea/components/minisite/site_desing/services/widgetIframeCode.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: "popPreview",
    components: {
        VueEditor,
        draggable,
        rowOpts,
        addSections,
        addWidgets,
        widgetIframeYB,
        widgetIframeCode,
        Swiper,
        SwiperSlide,
        detailspjt,
        'widget-bMenu': widget_bMenu,
    },
    data() {
        return {
            siteContent: [],
            editorOptions: { theme: 'bubble' },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "clean"], // remove formatting button
            ],
            customToolbarMin: [
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ color: [] }],
                ["link", "clean"], // remove formatting button
            ],
            miniId: '0',
            name: null,
            defaultMiniSite: null,
            rowOptsIdx: null,
            nameComp: null,
            dataModal: null,
            rowWidgetIdx: null,
            colWidgetIdx: null,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false
                },
            },
            sliderProject: [],
            urlImgProject: window.urlmaster+'api/v2/projects/previewImgProject/'+window.master_client+'/'+ window.localorgdata+'/',
        };
    },
    created() {
        this.siteContent = this.$store.getters['ModApps/Marketea/get_bsite_minisite']
        console.log('siteContent', this.siteContent)
        console.log('created miniId', this.$route.params.miniId)
        localStorage.removeItem('sliderProject')
        this.getWidgets();
    },
    watch: {
        "$route.params.miniId": function(){
            console.log('watch miniId', this.$route.params.miniId)
        },
        getMiniSite: function () {
            let t = this;
            if (t.getMiniSite != null && t.getMiniSite != 'null') {
                console.log('getMiniSite updated!')
                setTimeout(() => {
                    t.siteContent = t.getMiniSite;
                    t.getWidgets();
                }, 200);
            } else {
                t.siteContent = [];
            }
            console.log('siteContent', t.siteContent)
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getMiniSite: "get_bsite_minisite",
        }),
        dragSections() {
            return {
                animation: 200,
                group: "sections",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        dragWidgets() {
            return {
                animation: 200,
                group: "widgets",
                disabled: false,
                ghostClass: "ghostw",
            };
        },
    },
    methods: {
        showEdit(idx, col_idx, elemt_idx){
            if(!this.siteContent[idx].columns[col_idx].elemts[elemt_idx].editShow){
                this.siteContent[idx].columns[col_idx].elemts[elemt_idx].editShow=true;
            }else{
                this.siteContent[idx].columns[col_idx].elemts[elemt_idx].editShow=false;
            }
            // localStorage.setItem('siteContent', JSON.stringify(this.siteContent))
            // this.siteContent =JSON.parse(localStorage.getItem('siteContent'));
            this.saveChange();
        },
        isDragging(t) {
            console.log('move', t) // t = true or false
            return t;
        },
        log: function (evt) {
            console.log('log', evt)
        },
        saveMove(){
            let t= this;
            t.siteContent.forEach((e, idx) => {
                t.siteContent[idx].item_orden=idx;
            });
            t.saveChange();
        },
        addItem: function (evt) {
            console.log('addItem', evt);
        },
        moveSection(i, idx, move){
            console.log('moveSection', i, idx, move);
            this.closeRowOpts();// reiniciar
        },
        addSectionBeforeAfter(i, idx, b_a){
            console.log('addSectionBeforeAfter', i, idx, b_a);
            this.closeRowOpts();// reiniciar
            // this.siteContent.push(this.defaultMiniSite);
            // localStorage.setItem('siteContent', JSON.stringify(this.siteContent))
            this.ShowdModal('addSections', null);
        },
        insertSections(payload){
            let t = this;
            let insert= payload;
            insert.id= 0;
            insert.item_orden=t.siteContent.length;
            t.siteContent.push(insert);
            console.log(t.siteContent.length, t.siteContent[(t.siteContent.length-1)])
            t.saveChange();
        },
        delSection(i, idx){
            let t = this;
            t.$swal({
                title: "Delete Section!!",
                text: "Your content will be lost after deleting, do you want to continue?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    console.log('delSection', i, idx);
                    let elem = document.querySelector("#section-main"+idx);
                    elem.style.overflow = 'hidden';
                    let bgCurrent = '#d7d7d7';
                    let efectoKeyframes = [
                        { height: elem.offsetHeight },
                        { background: bgCurrent },
                        { height: '50px' },
                    ];
                    let optionsTiming = {
                        duration: 500,
                        iterations: 1,
                    };
                    elem.animate(efectoKeyframes, optionsTiming);
                    t.closeRowOpts();// reiniciar
                    setTimeout(function(){
                        elem.removeAttribute('style');
                        t.siteContent.splice(idx, 1);
                        t.saveChange();
                    }, 500);
                }
            });
        },
        editChange(delta, oldDelta, source){
            if(delta!=oldDelta && localStorage.getItem('iframeState')=='ready'){
                // console.log('editChange', delta, oldDelta, source)
                this.saveChange();
            }
        },
        changeOpts(payload){
            this.siteContent[this.rowOptsIdx].opts = payload;
            this.saveChange();
        },
        saveChange(){
            console.log('iframeState', localStorage.getItem('iframeState'))
            if(localStorage.getItem('iframeState')=='ready'){
                console.log('saveChange');
                this.$store.commit('ModApps/Marketea/set_bsite_minisite', this.siteContent);
                localStorage.setItem('save_pending', 1);
            }
        },
        cssSection(idx){
            let s = '';
            if(this.siteContent[idx].opts){
                let o = this.siteContent[idx].opts;
                if(o.bgColor){s+='background-color:'+o.bgColor+';';}
                if(o.height){
                    if(o.height!='auto' && parseInt(o.height)>0){
                        s+='height:'+o.height+'px;';
                        s+='overflow: hidden;overflow-y: auto;';
                    }
                }
                if(o.p){
                    console.log('padding', o.p)
                    if(o.p.t){s+='padding-top:'+o.p.t+'px;';}
                    if(o.p.b){s+='padding-bottom:'+o.p.b+'px;';}
                    if(o.p.l){s+='padding-left:'+o.p.l+'px;';}
                    if(o.p.r){s+='padding-rigth:'+o.p.r+'px;';}
                }
                if(o.columnAlignV){
                    if(o.columnAlignV!='normal'){
                        s+='align-items:'+ o.columnAlignV+ ';';
                    }
                }
                if(o.columnAlignH){
                    if(o.columnAlignH!='normal'){
                        s+='justify-content:'+ o.columnAlignH+ ';';
                    }
                }
            }
            console.log('cssSection', s)
            return s;
        },
        isBgImg(idx){
            let valid=false;
            if(this.siteContent[idx].opts){
                let o = this.siteContent[idx].opts;
                if(o.bgImage!=null && o.bgImage!='null'){
                    if(o.bgImage.length>0){
                        valid=true;
                    }
                }
            }
            return valid;
        },
        bgImage(idx){
            let s='';
            if(this.siteContent[idx].opts){
                let o = this.siteContent[idx].opts;
                if(this.isBgImg(idx)){
                    let urlImg= window.urlmaster+'media/'+window.master_client;
                    urlImg+='/'+window.localorgdata+'/bsite/minisite/';
                    console.log(urlImg+o.bgImage)
                    s+='background-image: url('+urlImg+o.bgImage+');';
                    s+='background-repeat: no-repeat;';
                    s+='background-size: cover;';
                    s+='background-position: center;';
                    s+='position:absolute;';
                    s+='top: 0;left: 0;z-index:1;';
                    s+='width:100%;height:100%;';
                }
            }
            return s;
        },
        bgImageOpacity(idx){
            let s='';
            if(this.siteContent[idx].opts){
                let o = this.siteContent[idx].opts;
                if(this.isBgImg(idx)){
                    s+='position:absolute;';
                    s+='top: 0;left: 0;z-index:1;';
                    s+='width:100%;height:100%;';
                    if(o.opacity){s+='opacity:'+o.opacity+';';}
                    if(o.bgColor){s+='background-color:'+o.bgColor+';';}
                }
            }
            return s;
        },
        textColor(idx){
            let s='';
            if(this.siteContent[idx].opts){
                let o = this.siteContent[idx].opts;
                let c = o.color;
                s+='color:'+c+';';
                if(this.isBgImg(idx)){
                    s+='z-index: 2;';
                }
            }
            return s;
        },
        modeDark(idx){
            if(this.siteContent[idx].modeDark==0){this.siteContent[idx].modeDark=1;}
            else{this.siteContent[idx].modeDark=0;}
            this.$store.commit('ModApps/Marketea/set_bsite_minisite', this.siteContent);
        },
        openRowOpts(idx) {this.rowOptsIdx=idx; console.log('rowOptsIdx', this.rowOptsIdx)},
        closeRowOpts(val){this.rowOptsIdx=null;},
        openAddWidget(idx, col_idx) {
            if(this.rowWidgetIdx==null){
                this.rowWidgetIdx=idx;
                this.colWidgetIdx=col_idx;
            }else{
                this.closeAddWidget();
            }
        },
        closeAddWidget($event){
            this.rowWidgetIdx=null;
            this.colWidgetIdx=null;
        },
        saveAddWidget(payload){
            let t = this;
            console.log('saveAddWidget', payload)
            if(payload.widget=='text'){
                t.siteContent[t.rowWidgetIdx].columns[t.colWidgetIdx].elemts.push(payload);
                t.saveChange();
                t.closeAddWidget();
            }
            if(payload.widget=='accordion'){
                t.siteContent[t.rowWidgetIdx].columns[t.colWidgetIdx].elemts.push(payload);
                t.saveChange();
                t.closeAddWidget();
            }
            else if(payload.widget=='videoYB'){
                t.ShowdModal('widgetIframeYB', payload.url);
            }
            else if(payload.widget=='iframe'){
                t.ShowdModal('widgetIframeCode', payload.url);
            }
        },
        delWidget(idx, col_idx, elemt_idx) {
            let t = this;
            t.$swal({
                title: "Delete Widget!!",
                text: "Your content will be lost after deleting, do you want to continue?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let elem = document.querySelector("#widgetItem-"+idx+'-'+col_idx+'-'+elemt_idx);
                    console.log('del widget', elem);
                    let heightResta = elem.offsetHeight/10;
                    elem.style.overflow = 'hidden';
                    let bg1 = '#d7d7d7';
                    let bg2 = '#f2f4f5';
                    let bgCurrent = '#d7d7d7';
                    elem.style.height = elem.offsetHeight - heightResta + 'px';
                    let efectoKeyframes = [
                        { height: elem.style.height },
                        { background: bgCurrent },
                        { height: '50px' },
                    ];
                    let optionsTiming = {
                        duration: 800,
                        iterations: 1,
                    };
                    elem.animate(efectoKeyframes, optionsTiming);
                    t.closeAddWidget();// reiniciar
                    setTimeout(function(){
                        elem.removeAttribute('style');
                        t.siteContent[idx].columns[col_idx].elemts.splice(elemt_idx, 1);
                        t.saveChange();
                    }, 500);
                }
            });
        },
        ShowdModal(n, data) {
            this.nameComp = n;
            this.dataModal=data;
            this.$modal.show("miniSiteOptions");
        },
        beforeClose(){this.nameComp=null; this.dataModal=null;},
        CloseModal(){this.$modal.hide("miniSiteOptions")},
        jobsModal(payload){
            let t = this;
            if(payload!=null && payload.action=='videoYB'){
                t.siteContent[t.rowWidgetIdx].columns[t.colWidgetIdx].elemts.push({
                    widget: 'videoYB',
                    text: "YouTube video player",
                    url: payload.url,
                });
                t.saveChange();
                t.closeAddWidget();
            }
            else if(payload!=null && payload.action=='iframe'){
                t.siteContent[t.rowWidgetIdx].columns[t.colWidgetIdx].elemts.push({
                    widget: 'iframe',
                    text: "code embed",
                    url: payload.url,
                });
                t.saveChange();
                t.closeAddWidget();
            }
            else if(payload!=null && payload.action=='insertNewSection'){
                t.insertSections(payload.val);
            }
            t.CloseModal();
        },
        getWidgets(){
            let t =this;
            t.siteContent.filter(s =>{
                    s.columns.filter(c =>{
                        c.elemts.filter(e =>{
                            if(e.widget=='project'){
                                t.getWidgetProject(e.filtro, e.status);
                            }
                        });
                    });
                });
        },
        getWidgetProject(filtro, status){
            console.log('getProject', filtro);
            let t = this;
            t.sliderProject=[];
            if(localStorage.getItem('sliderProject')==null){
                let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
                let apis = urlApis.Projects;
                let urlService = apis.get(0); //getAll
                if(status>0){
                    console.log('fillStatus', status)
                    urlService = apis.get(1); // getByStatus
                }
                // @ts-ignore
                window.master.post(urlService, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    status: status,
                    rows: 10,
                })
                .then((response) => {
                    console.log(urlService, response.data);
                    localStorage.setItem('sliderProject', JSON.stringify(response.data.list.data));
                    t.sliderProject = response.data.list.data;
                    console.log('then sliderProject', t.sliderProject);
                }).catch((error) => {
                    console.log(error);
                });
            }else{
                let s = JSON.parse(localStorage.getItem('sliderProject'));
                t.sliderProject = s;
                console.log('local sliderProject', t.sliderProject);
            }
        },
        accodionOpen(items, idx){
            for (const i in items) {if (items[i]) { items[i].open=0;}}
            items[idx].open=1;
        },
        accodionDelItem(items, idx){
            items.splice(idx, 1);
        },
        accodionAddItem(items){
            items.push({
                open: 0,
                title: "<h3>Lorem ipsum dolor sit amet.</h3>",
                text: "<p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
            });
        },
    },
    mounted(){
        // console.log('mounted')
        let t = this;
        var qlEditor = document.querySelectorAll('#app .widget_elemts_site.widget_elemt_text .ql-bubble .ql-editor ');
        if(qlEditor){
            qlEditor.forEach(element => {
                element.addEventListener('click', function(e){
                    // console.log('click in', this)
                    // ql-tooltip
                    let qlTooltip = this.nextSibling.nextSibling.classList.contains('ql-hidden');
                    console.log('qlTooltip', qlTooltip);
                    if(qlTooltip){
                        this.nextSibling.nextSibling.classList.toggle('ql-hidden');
                    }
                });
            });
        }
    },
}
</script>
<style lang="scss" scoped>
/* diseño base*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

@import "~vue2-editor/dist/vue2-editor.css";
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
