<template>
	<div>
		<div class="card-header">Set Primary Account</div>
		<div class="card-body">
			<form @submit.prevent="changeOrgPrimary"  id="form1">
				<div class="row">
					<div class="col-12">
						<div class="estilosform">
							<div class="row">
								<div class="col-md-12 text-left marginTop">
									<h4 class="">
										Select the primary organization of the account:
									</h4>
								</div>
								<div class="form-group col-md-8">
									<select class="form-control" v-model="org_id">
										<option :value="i.id" v-for="(i,index) in organizationslist" v-bind:key="index">{{i.bill_company_name}}</option>
									</select>
								</div>
								<div class="col-md-2">
									<div style="display: flex;justify-content: flex-end;">
										<button type="submit" class="btn btn-success " style="margin-right:10px;">Change</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'changeOrg',
		data () {
			return {
				org_id: 0,
				org_id_old: 0,
				organizationslist: []
			}
		},
		created() {
			let valOrg = this.$store.getters['core/Org/getVarChangeOrg'];
			this.org_id = valOrg.org_id
			this.org_id_old = valOrg.org_id_old
			this.organizationslist = this.$store.getters['core/Org/orgAll'];
		},
		methods: {
			changeOrgPrimary() {
				this.$store.commit("set_isLoading", true);
				this.$store.dispatch('core/Org/changeOrgPrimary', this.org_id)
				.then((response) => {
					this.$store.commit("set_isLoading", false);
					if (!response) {
						this.$store.commit('core/setNotiPush', {
							show: true,
                            group: "notiPush",
                            type: '3', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Error',
							text: 'Could not change organization.',
                            duration: 10000
						});
					} else {
						this.$store.commit("core/setCloseModal", false);
						this.$store.commit('core/setNotiPush', {
							show: true,
                            group: "notiPush",
                            type: '1', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Success',
							text: 'Successful change, reloading ...',
                            duration: 3000
						});
					}
				}).catch((error) => { 
					console.log(error);
					this.$store.commit("core/setLoadingModal", false);
				});
			},
		}
	};
</script>
<style lang="css" scoped>
</style>
