var jobs = "/jobs/";
export default {  
	get(){
		return jobs+"getJobsDash";
	},
	getRol(){
		return jobs+"getJobsDashRol";
	},
	addItemTask(){
		return jobs+"addItemTask";
	},
	addToDoList(id){
		return jobs+"getTemplateByID/" + id;
	},

	AssignCustomer(){
		return jobs+"editCusInv";
	},
	changeColor(){
		return jobs+"setJobsColor";
	},
	dashEditTask(){
		return jobs+"dashEditTask";
	},
	del(id){
		return jobs+"delJobs/" + window.master_client + "/" + window.localorgdata + "/" + id;
	},	
	deleteAllIMG(){
		return jobs+"delImgAllGallery";
	},
	delTags(){
		return jobs+"delTags";
	},
	delTask(){
		return jobs+"delItemTask";
	},
	delTsgs(id){
		return jobs+"getJobsDel/" + window.master_client + "/" + window.localorgdata + "/" + id;
	},
	editItemTask(){
		return jobs+"editItemTask";
	},
	formToDo(){
		return jobs+"addtodo";
	},
	getDataBilling(){
		return jobs+"getDataBilling";
	},	

	getExtrasYSettingAll(){
		return jobs+"getJobsExtrasSetting";
	},
	getJobsAllSetting(){
		return jobs+"getjobsallsetting";
	},
	getJobsColor(){
		return jobs+"getJobsColor/" + window.master_client + "/" + window.localorgdata;
	},	
	jobsPdf(id_invoice){
		return window.urlbase + "api/v2/invoice/pdf/" + id_invoice + "/" + window.localAccountID + "/" + localStorage.getItem("oauth_m");
	},	
	
	jobstoCompleted(){
		return jobs+"toComplete";
	},
	newAddRefresh(){
		return jobs+"getJobInfoByID";
	},
	saveNotes(){
		return jobs+"editNotes";
	},	

	editTags(){
		return jobs+"editTags";
	},
	uploadIMG(){
		return jobs+"uploadIMG";
	},	
	editCamp(){
		return jobs+"editItemTask";
	},		
};
