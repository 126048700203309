<template>
    <div class="card">
        <div class="card-header"><i class="fas fa-user"></i> OAuth client</div>
        <div class="card-body">
            <div v-if="isLoading"><Loader></Loader></div>
            <div v-else>
                <form @submit.prevent="form">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="f.email"
                                />
                            </div>
                            <div class="form-group">
                                <label for="">ID</label>
                                <input type="text" class="form-control" v-model="f.ids" />
                            </div>
                            <div class="form-group">
                                <label for="">Location ID</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="f.locationId"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="btn-group float-right mt-2" role="group">
                        <button type="submit" class="btn btn-success pull-right">
                            <i class="fas fa-save"></i> Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "OauthClientForm",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            f: {
                id: 0,
                email: "",
                ids: "",
                locationId: "",
                user_id: window.master_client,
                org_id: window.localorgdata,
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let apisForm = this.$store.getters["ModApps/Marketea/urlApis"];
            this.apis = apisForm.google;
            this.get();
        },
        form() {
            this.isLoading = true;
            let url = this.apis.add();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error, (this.isLoading = false));
                });
        },
        get() {
            this.isLoading = true;
            let url = this.apis.get();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.r !== null) {
                        let r = response.data.rs;
                        this.f.ids = r.ids;
                        this.f.email = r.email;
                        this.f.id = r.id;
                        this.f.locationId = r.locationId;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error, (this.isLoading = false));
                });
        },
    },
};
</script>
