<template>
    <div class="card">
        <div class="card-header window-header">
            <h4 style="margin-bottom: 0px;"> Insert Section</h4>
            <button @click="$parent.close()" class="closeIcon"><span></span></button>
        </div>
        <div class="card-body">
            <div class="row" v-if="viewActive==1">
                <div class="col-12">
                    <ul class="list-group list-group-horizontal">
                        <li class="list-group-item" @click="insertText()">
                            <span class="label">Text</span>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#555555" stroke="none"><path d="M361 4464 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3020 0 -3106 45 -173 178 -307 350 -352 52 -13 309 -15 2195 -15 2330 0 2184 -4 2302 56 71 36 159 123 197 193 65 122 61 25 61 1671 0 1311 -2 1503 -15 1555 -45 172 -179 305 -352 350 -85 22 -4309 21 -4392 -1z m4365 -330 c15 -11 37 -33 48 -48 21 -27 21 -28 21 -1526 0 -1498 0 -1499 -21 -1526 -11 -15 -33 -37 -48 -48 l-27 -21 -2139 0 -2139 0 -27 21 c-15 11 -37 33 -48 48 -21 27 -21 31 -24 1499 -1 810 0 1486 3 1504 7 40 49 91 90 109 25 11 404 13 2157 11 l2127 -2 27 -21z"/><path d="M1055 3506 c-41 -18 -83 -69 -90 -109 -4 -18 -5 -118 -3 -224 3 -210 5 -218 72 -267 27 -21 28 -21 1526 -21 1498 0 1499 0 1526 21 68 51 69 53 69 294 0 241 -1 243 -69 294 -27 21 -30 21 -1514 23 -1221 2 -1492 0 -1517 -11z"/><path d="M1055 2546 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26 -8 497 -10 1514 -8 1473 3 1477 3 1504 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -30 21 -1514 23 -1221 2 -1492 0 -1517 -11z"/><path d="M1055 1906 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8 353 -10 1034 -8 989 3 997 3 1024 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -35 21 -1034 23 -822 2 -1013 0 -1037 -11z"/></g></svg>
                        </li>
                        <li class="list-group-item" @click="insertAccordion()">
                            <span class="label">Accordion</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#555555"><path d="M16 128h416c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H16C7.2 32 0 39.2 0 48v64c0 8.8 7.2 16 16 16zm480 80H80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zm-64 176H16c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16z"/></svg>
                        </li>
                        <li :class="['list-group-item', isDisabled_Project ? 'disabled' : '']" @click="listProject()">
                            <span class="label">Project</span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 68.4 88.08" enable-background="new 0 0 68.4 88.08" xml:space="preserve">
                                <path fill="#555555" d="M0.24,21.6c0.065-0.013,0.13-0.015,0.196-0.007c0.626,0.622,0.427,1.422,0.428,2.156  C0.878,36.166,0.874,48.583,0.874,61c0,6.067,0.006,12.138-0.008,18.205c-0.002,0.66,0.145,1.376-0.423,1.921l-0.103,0.002  L0.24,81.12C0.24,61.28,0.24,41.44,0.24,21.6z"/>
                                <path fill="#555555" d="M0.443,81.126c0.013-0.197,0.036-0.396,0.036-0.596c0.002-19.407,0.001-38.814,0-58.222  c0-0.239-0.027-0.477-0.042-0.716c0.487-4.4,3.54-7.149,7.96-7.165c1.64-0.006,3.298-0.154,4.914,0.042  c1.687,0.205,0.515,1.641,0.76,2.481c0.145,0.495,0.354,1.329-0.783,1.286c-1.637-0.062-3.279-0.022-4.918-0.014  c-2.674,0.014-4.128,1.461-4.141,4.164c-0.018,3.559-0.004,7.118-0.004,10.677c0,15.556,0,31.112,0.001,46.668  c0,0.838-0.046,1.686,0.355,2.465c0.832,1.617,2.207,2.145,3.959,2.14c9.638-0.026,19.275-0.013,28.912-0.013  c7.639,0,15.276,0.003,22.915-0.002c3.094-0.002,4.451-1.363,4.451-4.451c0.001-19.075,0.002-38.15,0-57.226  c0-3.063-1.381-4.421-4.486-4.424c-1.52-0.002-3.04-0.025-4.559,0.012c-0.611,0.014-0.813-0.154-0.836-0.798  c-0.11-3.009-0.133-3.008,2.816-3.008c1.04,0,2.08-0.009,3.119,0.002c4.442,0.049,7.722,3.312,7.732,7.756  c0.021,7.878,0.006,15.756,0.006,23.633c0,11.357,0.002,22.715-0.001,34.072c-0.001,5.104-3.105,8.201-8.22,8.202  c-17.315,0.002-34.632,0.003-51.947-0.001C4.007,88.092,1.061,85.519,0.443,81.126z"/>
                                <path fill="#555555" d="M35.218,25.21c3.938-0.033,6.833,3.655,5.843,7.529c-0.228,0.885-0.218,1.365,0.692,1.877  c1.776,0.998,2.33,3.109,1.337,4.889c-1.866,3.345-3.837,6.635-5.715,9.975c-0.578,1.027-1.021,0.178-1.513,0  c-0.625-0.226-0.841-0.504-0.413-1.221c1.843-3.083,3.607-6.213,5.4-9.326c0.219-0.379,0.455-0.743,0.447-1.216  c-0.014-0.94-0.703-1.343-1.403-1.599c-0.649-0.236-0.688,0.521-0.895,0.871c-2.221,3.788-4.43,7.583-6.584,11.409  c-0.422,0.748-0.725,0.779-1.432,0.356c-2.913-1.737-5.858-3.425-8.815-5.088c-0.643-0.361-0.81-0.613-0.391-1.327  c2.69-4.576,5.314-9.191,7.985-13.779C31.013,26.413,32.992,25.229,35.218,25.21z"/>
                                <path fill="#555555" d="M12.412,70.679c0-3.521,0.003-6.789-0.005-10.059c-0.001-0.389,0.19-0.688,0.367-0.996  c2.572-4.46,5.159-8.912,7.712-13.383c0.36-0.631,0.632-0.811,1.33-0.391c2.496,1.504,5.027,2.947,7.566,4.377  c0.571,0.321,0.694,0.574,0.342,1.179c-2.643,4.513-5.25,9.045-7.864,13.573c-0.203,0.351-0.423,0.646-0.8,0.854  C18.27,67.38,15.492,68.949,12.412,70.679z"/>
                                <path fill="#555555" d="M34.679,77.276c-6.681,0-13.358,0.005-20.038-0.004c-1.793-0.002-2.913-1.197-2.517-2.668  c0.281-1.041,0.969-1.638,2.07-1.668c0.919-0.023,1.84-0.01,2.759-0.01c12.438,0,24.877,0,37.315,0c0.398,0,0.802-0.018,1.198,0.014  c1.165,0.092,2.037,0.994,2.058,2.104c0.019,1.056-0.771,2.015-1.854,2.192c-0.391,0.063-0.798,0.037-1.196,0.037  C47.877,77.276,41.277,77.276,34.679,77.276z"/>
                                <path fill="#555555" d="M40.436,68.72c-4.397,0-8.795,0.004-13.193-0.002c-1.473-0.002-2.081-0.621-2.1-2.105  c-0.021-1.535,0.638-2.25,2.107-2.252c8.795-0.004,17.591-0.004,26.387,0c1.462,0.002,2.122,0.728,2.1,2.262  c-0.022,1.479-0.63,2.095-2.107,2.097C49.231,68.724,44.834,68.72,40.436,68.72z"/>
                                <path fill="#555555" d="M34.561,0.24c0.348,0.457,0.879,0.232,1.315,0.329c3.762,0.83,6.152,4.078,5.726,7.935  c-0.08,0.726,0.175,0.793,0.755,0.789c2.357-0.019,4.716-0.016,7.073-0.005c1.633,0.008,2.168,0.535,2.172,2.136  c0.009,3.157,0.009,6.314,0,9.471c-0.004,1.58-0.576,2.146-2.176,2.146c-9.949,0.004-19.9,0.004-29.852,0  c-1.553,0-2.113-0.539-2.12-2.064c-0.015-3.237-0.017-6.475,0.001-9.711c0.008-1.431,0.536-1.962,1.956-1.975  c2.397-0.021,4.795-0.012,7.193-0.001c0.53,0.002,0.847,0.012,0.813-0.752c-0.2-4.401,1.916-7.102,6.19-8.031  c0.188-0.041,0.405-0.015,0.472-0.266C34.24,0.24,34.4,0.24,34.561,0.24z M34.498,5.045c-1.414,0.013-2.576,1.191-2.565,2.602  c0.009,1.405,1.196,2.578,2.604,2.57c1.395-0.008,2.585-1.215,2.576-2.61C37.104,6.207,35.904,5.032,34.498,5.045z"/>
                            </svg>
                        </li>
                        <li :class="['list-group-item', isDisabled_bMenu ? 'disabled' : '']" @click="insert_bMenu()">
                            <span class="label">bMenu</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416 512" height="4.5rem" fill="#555555"><path d="M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8 .9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z"/></svg>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" v-if="viewActive==2">
                <div class="col-12 text-center">
                    <h3>Widget Projects Slider</h3>
                    <input type="radio" class="btn-check" name="options-base" id="option0" >
                    <label class="btn btn-secondary" for="option0" @click.prevent="selectedFilter(0)">{{opts[0]}}</label>
                    <input type="radio" class="btn-check" name="options-base" id="option1" >
                    <label class="btn btn-secondary" for="option1" @click.prevent="selectedFilter(1)">{{opts[1]}}</label>
                    <input type="radio" class="btn-check" name="options-base" id="option2" >
                    <label class="btn btn-secondary" for="option2" @click.prevent="selectedFilter(2)">{{opts[2]}}</label>
                    <input type="radio" class="btn-check" name="options-base" id="option3" >
                    <label class="btn btn-secondary" for="option3" @click.prevent="selectedFilter(3)">{{opts[3]}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    components: {
    },
    data(){
        return {
            viewActive: 1,
            text: {
                opts:{
                    columnAlignH: 'normal',
                    columnAlignV: 'normal',
                },
                columns: [
                    {
                        elemts: [
                            {
                                widget: 'text', // or image, link
                                text: "<h2>Lorem ipsum dolor sit amet.</h2><p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                url:'',
                            }
                        ]
                    },
                ]
            },
            dropdownStatusVal: 'Any status',
            opts: [
                "Any status",
                "Current",
                "Completed",
                "To Be Built",
            ],
            filter: 'all',
            projectW: {
                opts:{
                    columnAlignH: 'normal',
                    columnAlignV: 'normal',
                },
                class: 'project_section',
                columns: [
                    {
                        class: 'project_column',
                        elemts: [
                            {
                                widget: 'project', // or image, link
                                filtro: 'all',
                                status: 0,
                                text: "",
                                url:'',
                            }
                        ]
                    },
                ]
            },
            bMenuSection: {
                opts:{
                    columnAlignH: 'normal',
                    columnAlignV: 'normal',
                },
                class: 'bMenu_section',
                modeDark: 0,
                columns: [
                    {
                        class: 'bMenu_column',
                        elemts: [
                            {
                                widget: 'bMenu', // or image, link
                            }
                        ]
                    },
                ]
            },
            isDisabled_Project: true,
            isDisabled_bMenu: true,
            accordion: {
                opts:{
                    columnAlignH: 'normal',
                    columnAlignV: 'normal',
                },
                columns: [
                    {
                        elemts: [
                            {
                                widget: 'accordion', // or image, link
                                items : [
                                    {
                                        open: 1,
                                        title: "<h3>Lorem ipsum dolor sit amet.</h3>",
                                        text: "<p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                    },
                                    {
                                        open: 0,
                                        title: "<h3>Lorem ipsum dolor sit amet.</h3>",
                                        text: "<p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                    },
                                    {
                                        open: 0,
                                        title: "<h3>Lorem ipsum dolor sit amet.</h3>",
                                        text: "<p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
        };
    },
    watch: {
        getModulesMenu: function(){
            let t = this;
            console.log('watch getModulesMenu', t.getModulesMenu)
            t.isDisabled_Project = t.getPlugin('ProjectsAll');
            t.isDisabled_bMenu = t.getPlugin('bsite_bmenu');
        }
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
        ...mapGetters("core/apps/", { getModulesMenu: "getModulesMenu" })
    },
    created(){
        let t = this;
        t.isDisabled_Project = t.getPlugin('ProjectsAll');
        t.isDisabled_bMenu = t.getPlugin('bsite_bmenu');
    },
    methods : {
        insertText(){
            let t = this;
            t.$emit('actionModal', {action:"insertNewSection", val: t.text});
        },
        listProject(){
            let t= this;
            if(!t.isDisabled){t.viewActive=2;}
        },
        selectedFilter(idx){
            let t = this;
            if(idx==0){t.filter='all';}
            if(idx==1){t.filter='current';}
            if(idx==2){t.filter='completed';}
            if(idx==3){t.filter='toBeBuilt';}
            console.log('selectedFilter', t.filter, idx)
            t.insertProject(t.filter, idx);
        },
        insertProject(type, status){
            let t = this;
            t.projectW.columns[0].elemts[0].filtro = type;
            t.projectW.columns[0].elemts[0].status = status;
            t.$emit('actionModal', {action:"insertNewSection", val: t.projectW});
        },
        insert_bMenu(){
            let t = this;
            t.$emit('actionModal', {action:"insertNewSection", val: t.bMenuSection});
        },
        getPlugin(nameKey){
            let t = this;
            let valid = null;
            console.log('getPlugin', nameKey)
            valid = t.getModulesMenu.find(menu => {
                return (menu.plugins==1 && menu.subMenu.length>0) ? menu.subMenu.find(subMenu => {return (subMenu.module_key==nameKey) ? t.searchPlugin(subMenu) : false;}) : false;
            });
            console.log('valid Plugin', (valid!=null && valid!=undefined), 'valid is', valid)
            return (valid==null || valid==undefined);
        },
        searchPlugin(plugin){
            let t= this;
            console.log('is = ', plugin)
            let payload = {id: plugin.id, slug: plugin.module_key};
            return new Promise((resolve, reject) => {
                t.$store.dispatch("ModApps/Marketea/search_plugins", payload)
                .then(p => {
                    if(p){resolve(true);}
                    else{resolve(false);}
                });
            });
        },
        insertAccordion(){
            let t = this;
            t.$emit('actionModal', {action:"insertNewSection", val: t.accordion});
        },
    }
}
</script>
<style lang="scss" scoped>
.list-group {
    &.list-group-horizontal{
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        flex-wrap: wrap;
        flex-direction: row;
    }
    li.list-group-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            background-color: #eee;
            cursor: pointer;
        }
        svg {
            width: 4rem;
        }
        span.label{
            font-size: 14px;
            color: #000;
        }
    }
}
.window-header{cursor: move;}
input.btn-check {
    opacity: 0;
}
</style>
