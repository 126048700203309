<template>
    <div>
        <div class="row" v-if="!isLoading">
            <div class="alert alert-light col-md-4" style="padding: 0rem 0.8rem">
                <div class="row" style="border: 1px solid #ccc;padding: 5px;">
                    <label class="text-success col-md-3 col-sm-3 tlabel" style="padding: 0.4em 0">
                        Income:
                    </label>
                    <h3 class="col-md-9 col-sm-9 text-left">
                        <span class="badge badge-light total_ipad">
                            ${{totalIncome | formatMoney}}
                        </span>
                    </h3>
                    <div class="col-md-12" style="padding: 0px">
                        <GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartInv" :options="OpInvoicesChart"></GChart>
                    </div>                    
                </div>
            </div>
            <div class="alert alert-light col-md-4" style="padding: 0rem 0.8rem">
                <div class="row" style="border: 1px solid #ccc;padding: 5px;">
                    <label class="text-danger col-md-4 col-sm-3 tlabel" style="padding: 0.4em 0">
                        Expenses:
                    </label>
                    <h3 class="col-md-8 col-sm-9 text-left">
                        <span class="badge badge-light total_ipad">
                            ${{totalExpenses | formatMoney}}
                        </span>
                    </h3>            
                    <div class="col-md-12" style="padding: 0px">
                        <GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartExp" :options="optionsChartExp"></GChart>
                    </div>
                </div>
            </div>
            <div class="alert alert-light col-md-4" style="padding: 0rem 0.8rem">
                <div class="row" style="border: 1px solid #ccc;padding: 5px;">
                    <label class="text-info col-md-4 col-sm-3 tlabel" style="padding: 0.4em 0">
                        Unaccepted:
                    </label>
                    <h3 class="col-md-8 col-sm-9 text-left">
                        <span class="badge badge-light total_ipad">
                            $ {{totalUnaccepted | formatMoney}}
                        </span>
                    </h3>                  
                    <div class="col-md-12" style="padding: 0px">
                        <GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartPro" :options="optionsChartPro"></GChart>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GChart } from 'vue-google-charts'
export default {
	name: 'ResumenTransac',
    components: {
        GChart,
    },
	data () {
		return {
            isLoading: true,
            selectBank: {
                account_type: 1,
                bank_name: "",
                account_number: "1234",
            },
            dataChartInv: [["Invoice", "Total"]],
            OpInvoicesChart: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#5ac146"],
                hAxis: { title: "Income", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            dataChartExp: [["Expenses", "Amount"]],
            optionsChartExp: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#fa5838"],
                hAxis: { title: "Expenses", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            dataChartPro: [["Unaccepted", "Unaccepted"]],
            optionsChartPro: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#0065df"],
                hAxis: { title: "Unaccepted", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            totalIncome: 0,
            totalExpenses: 0,
            totalUnaccepted: 0,	
		}
	},
    created () {
        let getFilter = this.$store.getters['ModApps/billing/getFilterBank'];
        if(Object.keys(getFilter).length > 0) {
            this.init(getFilter.filter)
        }
    },	
    methods: {
        init(filter) {
            this.isLoading = true
            let getSelectBank = this.$store.getters['ModApps/billing/getSelectBank'];
            // console.log(getSelectBank);
            this.selectBank = getSelectBank;
            this.filtrosResumen(filter);        
		},
        filtros() {
            this.filtrosResumen(2);
        },
        filtrosResumen(filter) {
            this.filterSelected = filter;
            let id = this.$route.params.idb
            this.listResumen(id, this.filterSelected);
        },
        listResumen(ids, filtro) {
            let id = this.$route.params.idb
            let t = this;
            window.billing
                .get("banking/transacresumen/" + id + "/" + filtro)
                .then((response) => {
                    let resumen = response.data.resumen;
                    t.totalIncome = 0;
                    t.totalExpenses = 0;
                    t.totalUnaccepted = 0;
                    t.dataChartInv = [];
                    t.dataChartExp = [];
                    t.dataChartPro = [];
                    t.dataChartInv = [["Income", "Total"]];
                    t.dataChartExp = [["Expenses", "Amount"]];
                    t.dataChartPro = [["Unaccepted", "Unaccepted"]];
                    for (let i in resumen) {
                        t.totalIncome += parseFloat(resumen[i].income);
                        t.dataChartInv.push([
                            resumen[i].nom,
                            parseFloat(resumen[i].income),
                        ]);

                        t.totalExpenses += parseFloat(resumen[i].expense);
                        t.dataChartExp.push([
                            resumen[i].nom,
                            parseFloat(resumen[i].expense) * -1,
                        ]);

                        t.totalUnaccepted += parseFloat(resumen[i].unaccep);
                        t.dataChartPro.push([
                            resumen[i].nom,
                            parseFloat(resumen[i].unaccep) * -1,
                        ]);
                    }
                    if (isNaN(t.totalIncome)) {
                        t.totalIncome = 0;
                    }
                    if (isNaN(t.totalExpenses)) {
                        t.totalExpenses = 0;
                    }
                    if (isNaN(t.totalUnaccepted)) {
                        t.totalUnaccepted = 0;
                    }
                    if (!isNaN(t.totalUnaccepted) && t.totalUnaccepted === 0) {
                        t.dataChartPro.push(["", t.totalUnaccepted]);
                    }
                    if (!isNaN(t.totalIncome) && t.totalIncome === 0) {
                        t.dataChartInv.push(["", 0]);
                    }
                    if (!isNaN(t.totalExpenses) && t.totalExpenses === 0) {
                        t.dataChartExp.push(["", 0]);
                    }
                    t.isLoading = false;
                    console.log('load', t.isLoading);
                    //console.log('dataChartInv', t.dataChartInv);
                    //console.log('dataChartExp', t.dataChartExp);
                    //console.log('dataChartPro', t.dataChartPro);
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false
                });
        },						
	},
    watch: {
		getFilterBank: function () {
			this.init(this.getFilterBank.filter);
		},
		getTextfilter: function () {
			// this.init(this.getFilterBank.filter);
		},
        'getActionRefresh': function() {
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                if(this.getActionRefresh.data.baking === true){
                    let getFilter = this.$store.getters['ModApps/billing/getFilterBank'];
                    if(Object.keys(getFilter).length > 0) {
                        this.init(getFilter.filter)
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh",
        }),
		...mapGetters("ModApps/billing", { 
			getFilterBank: "getFilterBank",
			getTextfilter: "getTextfilter"
		}),        
    }
};
</script>
<style lang="css" scoped>
</style>
