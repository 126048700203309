<template>
    <div class="suscription">
        <modal
            name="modal_suscription"
            class="modal_suscription"
            draggable=".window-header"
            height="auto"
            @before-close="beforeClosedModal"
        >
            <div class="card">
                <div class="card-header window-header">
                    <h4>Select a Subscription Plan</h4>
                    <button type="button"
                        @click="ClosedModal()"
                        @touchstart="ClosedModal()"
                        class="closeIcon" id="closeSus"
                    ><span></span></button>
                </div>
                <div class="window-body" v-if="!isLoading">
                    <div class="columns" v-for="(i, idx) in plans" :key="idx">
                        <ul :class="['list_planes', (your_plan==i.id) ? 'active' : '', (your_plan==0 && favorito==i.id) ? 'favorito' : '']">
                            <li class="header-plan"
                                :style="((i.id==6) ? 'color:#000;' : '') +'background-color:'+ ((i.id==8) ? '#6c757d':
                                    (i.id==5) ? '#137eff' :
                                        (i.id==9) ? '#ff8720' :
                                            (i.id==10) ? '#5ac146' :
                                                (i.id==6) ? '#ffbc34' : '#000')+';'
                                "
                            >{{ i.name }}</li>
                            <li class="price-amount">$ {{i.cost}} / Monthly</li>
                            <li class="services">
                                <span class="name-service"># Smart Cards:</span>
                                <span class="badge badge-info" v-if="i.id==8">1</span>
                                <span class="badge badge-info" v-if="i.id==5">1</span>
                                <span class="badge badge-info" v-if="i.id==9">5</span>
                                <span class="badge badge-info" v-if="i.id==10">10</span>
                                <span class="badge badge-info" v-if="i.id==6">10</span>
                            </li>
                            <li class="services">
                                <span class="name-service"># MiniSite:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-info" v-if="i.id==5">1</span>
                                <span class="badge badge-info" v-if="i.id==9">1</span>
                                <span class="badge badge-info" v-if="i.id==10">2</span>
                                <span class="badge badge-info" v-if="i.id==6">2</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Premium Profile:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-success" v-if="i.id==5">YES</span>
                                <span class="badge badge-success" v-if="i.id==9">YES</span>
                                <span class="badge badge-success" v-if="i.id==10">YES</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Google Metal Card:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">YES</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">SMS lead Noti:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-danger" v-if="i.id==10">NO</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Landing page (soon):</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-info" v-if="i.id==10">1</span>
                                <span class="badge badge-info" v-if="i.id==6">4</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Website:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">Full</span>
                                <span class="badge badge-warning" v-if="i.id==6">Premiun</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Google Integration:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">YES</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Lead Management:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">YES</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Sales Workflow:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">YES</span>
                                <span class="badge badge-success" v-if="i.id==6">YES</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Forms:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">3</span>
                                <span class="badge badge-success" v-if="i.id==6">5</span>
                            </li>
                            <li class="services">
                                <span class="name-service">Onetime Setup:</span>
                                <span class="badge badge-danger" v-if="i.id==8">NO</span>
                                <span class="badge badge-danger" v-if="i.id==5">NO</span>
                                <span class="badge badge-danger" v-if="i.id==9">NO</span>
                                <span class="badge badge-success" v-if="i.id==10">$990</span>
                                <span class="badge badge-success" v-if="i.id==6">$1.500</span>
                            </li>
                            <li class="change-plan">
                                <button type="button" v-if="your_plan==i.id" class="btn-sus active">
                                    <span>
                                        Your Plan
                                        <i class="fas fa-check-circle"></i>
                                    </span>
                                </button>
                                <button type="button" v-else :class="['btn-sus', (planCost>i.cost) ? 'disabled' : '']"
                                @click="changePlan(i.id, i.app_id)" :disabled="(planCost>i.cost)"
                                >
                                    <span v-if="your_plan==0">
                                        Sign Up
                                    </span>
                                    <span v-else>
                                        Change
                                        <i class="far fa-circle"></i>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="window-body" v-else>
                    <Loader class="loading" :getProgress="ploadProgress"></Loader>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import "@/core/components/suscriptionApp/modal_suscription.css";
export default {
    name: 'bsite_sus',
    components: {
        Loader
    },
    data() {
        return {
            isLoading: false,
            plans: [],
            your_plan: 0,
            favorito: 0,
            ploadProgress: 0,
            planCost: 0,
        };
    },
    created(){},
    mounted(){
    },
    methods : {
        ClosedModal() {
            console.log('ClosedModal')
            this.$modal.hide("modal_suscription");
        },
        beforeClosedModal(){
            this.nameCompSus = null;
        },
        opendModal(name) {
            let t = this;
            t.nameCompSus = name;
            t.$modal.show("modal_suscription");
        },
        getYourPlan(app_id, name){
            let t = this;
            let url = window.urlmaster + 'api/v3/getYourPlan';
            t.ploadProgress=0;
            t.isLoading=true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("app_id", app_id);
            axios.post(url, formData, {
                headers: {
                    common: {Authorization: 'Bearer ' + window.localtoken_M},
                    post: { 'Content-Type': 'application/json' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor((progressEvent.loaded*100 / progressEvent.total));
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){t.ploadProgress=scanPer;}
                }
            })
            .then((response) => {
                console.log(url, response.data);
                t.plans = response.data.listPlanes;
                t.your_plan = response.data.your_plan;
                t.favorito = response.data.favorito;
                for (let index = 0; index < t.plans.length; index++) {
                    if(t.plans[index] && t.plans[index].id==t.your_plan){
                        t.planCost = t.plans[index].cost;
                    }
                }
                t.ploadProgress=100;
                t.isLoading=false;
            }).catch((error) => {
                console.log(error);
                t.isLoading=false;
            });
        },
        changePlan(plan_id, app_id){
            let t = this;
            let url = window.urlmaster + 'api/v3/setPlanbSite';
            t.ploadProgress=0;
            t.isLoading=true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("app_id", app_id);
            formData.append("plan_id", plan_id);
            formData.append("planes", plan_id);
            axios.post(url, formData, {
                headers: {
                    common: {Authorization: 'Bearer ' + window.localtoken_M},
                    post: { 'Content-Type': 'application/json' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor((progressEvent.loaded*100 / progressEvent.total));
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){t.ploadProgress=scanPer;}
                }
            })
            .then((response) => {
                console.log(url, response.data);
                t.ploadProgress=100;
                t.isLoading=false;
                t.your_plan = plan_id;
                t.favorito = 0;
                t.$store.commit('core/setNotiPush', {
                    show: true,
                    group: "notiPush",
                    type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                    title: 'Succes!',
                    text: 'Plan Upgrade',
                    duration: 2000
                });
                t.$store.commit("core/SET_HEADER", true);
                setTimeout(function(){
                    localStorage.removeItem('getDataSession');
                    t.$store.dispatch("core/session/getDataSessionLogin")
					.then((response) => {
						window.location.reload(true);
					});
                }, 1000);
                this.ClosedModal();
            }).catch((error) => {
                console.log(error);
                t.isLoading=false;
                t.$store.commit('core/setNotiPush', {
                    show: true,
                    group: "notiPush",
                    type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                    title: 'Error',
                    text: 'Failed plan update',
                    duration: 2000
                });
            });
        },
    },
    watch: {
        showSuscription: function(newVal, oldVal){
            console.log('showSuscription', newVal);
            if(newVal!=null){
                let name = null;
                if(newVal.show){
                    this.opendModal(name);
                    if(newVal.app_id==8){
                        name = 'bsite_sus';
                        this.getYourPlan(newVal.app_id, name);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            showSuscription: "get_showSuscription",
        }),
    }
}
</script>
<style lang="scss" scoped>
.columns {
    float: left;
    width: 20%;
    padding: 8px;
    padding-top: 0;
}

/* Style the list */
.list_planes {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-top: 10px;
}

/* Add shadows on hover */
.list_planes:hover, .list_planes.active, .list_planes.favorito {
    margin-top: 0px;
    box-shadow: 0 8px 12px 0 rgb(0 0 0 / 50%);
}
.list_planes li {
    border-bottom: 1px solid #eee;
    padding: 2px;
    text-align: center;
}
.list_planes li.price-amount {
    background-color: #eee;
    font-size: 20px;
}
/* Pricing header-plan */
.list_planes li.header-plan {
    background-color: #111;
    color: #fff;
    font-size: 25px;
}
.list_planes li.change-plan {
    background-color: #fff;
}
.list_planes li.price-amount,
.list_planes li.header-plan,
.list_planes li.change-plan {
    padding: 20px;
}

.list_planes li.services {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 10px;
}
.btn-sus {
    background-color: #fff;
    color: #555;
    border: none;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    border: 1px solid #ddd;
}
.btn-sus:focus {
    outline: none;
}
.btn-sus.active {
    background-color: #04AA6D;
    color: #fff;
}
.btn-sus:hover {
    cursor: pointer;
    background-color: #04AA6D;
    color: #fff;
}
.btn-sus.active:hover {cursor: no-drop}
@media only screen and (max-width: 767px) {
    .columns {
        width: 100%;
    }
}
</style>
