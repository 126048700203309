<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title">
                        <i class="fas fa-chart-line"></i> Report Recurring Invoices
                    </h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-3">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    @click="getInvoicesFilter"
                                                    style="width: inherit;min-width: 80px;"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button>
                                            </div>                               
                                            <div class="col-md-9">
                                                <select
                                                    class="form-control"
                                                    v-model="intervalselect"
                                                >
                                                    <option value="0">All</option>
                                                    <!-- <option value="1" >Weekly</option>
                                                    <option value="2" >Biweekly</option> -->
                                                    <option value="3">Monthly</option>
                                                    <option value="4">Quarterly</option>
                                                    <option value="5">Semiannual</option>
                                                    <option value="6">Annual</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divB" class="col-md-3">
                                        <div class="form-group row">
                                            <!--<div class="col-md-6">
                                                <div class="form-group row">
                                                    <div class="col-md-4">
                                                        <label for="staticEmail2" class="">
                                                            <span style="">Status:</span>    
                                                        </label>                                                      
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="basic_dropdown" style="margin-bottom: 10px;">
                                                            <button
                                                                class="btn-status"
                                                                @click="btnstatus()"
                                                            >
                                                                Status <i class="fas fa-caret-down"></i>
                                                            </button>
                                                            <ul
                                                                class="reset dropdStatus"
                                                                v-if="statusdropd"
                                                            >
                                                                <li>
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            id="check12"
                                                                            @click="checkAll()"
                                                                            v-model="isCheckAll"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            for="check12"
                                                                            ><b>All</b></label
                                                                        >
                                                                    </div>
                                                                </li>
                                                                <li
                                                                    v-for="(item, index) in statusdata"
                                                                    :key="index"
                                                                >
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            :id="index"
                                                                            v-bind:value="item.value"
                                                                            v-model="statuslist"
                                                                            @change="updateCheckall()"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            :for="index"
                                                                            >{{ item.name }}</label
                                                                        >
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                        </div>
                                    </div>
                                    <div id="divC" class="col-md-6">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>                                                        
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->                           
                            <div class="col-md-12">
                                <div class="contenedor-table">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        id="invList"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 60px">
                                                    Rec #
                                                </th>
                                                <th scope="col" style="width: 200px">
                                                    Customer
                                                </th>
                                                <th scope="col">Interval</th>
                                                <th scope="col" style="width: 105px">
                                                    Start Date
                                                </th>
                                                <th scope="col" style="width: 150px">
                                                    Name
                                                </th>
                                                <!--
                                                <th scope="col" style="width: 105px">
                                                    End Date
                                                </th>
                                                <th scope="col" style="width: 70px">
                                                    Terms
                                                </th><th
                                                    scope="col"
                                                    style="
                                                        text-align: center;
                                                        width: 50px;
                                                    "
                                                >
                                                    Status
                                                </th>-->
                                                <th
                                                    scope="col"
                                                    style="
                                                        text-align: right;
                                                        width: 120px;
                                                    "
                                                >
                                                    Total
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="
                                                        text-align: right;
                                                        width: 120px;
                                                    "
                                                >
                                                    Total Annual
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="
                                                        text-align: right;
                                                        width: 120px;
                                                    "
                                                >
                                                    Total Month
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalAnn | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalMonth | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>

                                            <tr
                                                v-for="(item, index) in InvoicesList"
                                                :key="index"
                                            >
                                                <td scope="row">
                                                    {{
                                                        item.numdoc
                                                            .toString()
                                                            .padStart(5, "0000")
                                                    }}
                                                </td>
                                                <td>
                                                    {{ item.firstname }} {{ item.lastname
                                                    }}<br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.bussinesname }}</span
                                                    >
                                                </td>
                                                <td>{{ intervalText[item.interval] }}</td>
                                                <td>{{ item.startdate }}</td>
                                                <td>{{ item.invoicename }}</td>
                                                <!--<td>{{ item.enddate }}</td>
                                                <td>{{ item.termText }}</td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.activorec == 1"
                                                        class="badge badge-success"
                                                        >Active</span
                                                    >
                                                    <span
                                                        v-else-if="item.activorec == 0"
                                                        class="badge badge-danger"
                                                        >Inactive</span
                                                    >
                                                </td>-->
                                                <td style="text-align: right">
                                                    ${{ item.total | formatMoney }}
                                                </td>
                                                <td style="text-align: right">
                                                    ${{ item.total2 | formatMoney }}
                                                </td>
                                                <td style="text-align: right">
                                                    ${{ item.totalMonth | formatMoney }}
                                                </td>
                                            </tr>
                                            <tr v-if="noresult">
                                                <td colspan="6">
                                                    <div
                                                        style="
                                                            text-align: center;
                                                            color: red;
                                                        "
                                                    >
                                                        No result
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalAnn | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalMonth | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "Report",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            noresult: false,
            statusdropd: false,
            isCheckAll: false,
            statusdata: [
                { value: 1, name: "Active" },
                { value: 0, name: "Inactive" },
            ],
            statuslist: [1],
            showModal: false,
            PaymentsReceived: [],
            dateFrom: new Date().toISOString(),
            dateTo: new Date().toISOString(),
            thisYear: null,
            thisMonth: null,
            InvoicesList: [],
            intervalText: [],
            dataInterval: null,
            SelectStatus: 0,
            intervalselect: 0,
            TermsList: [],
        };
    },
    created() {
        this.getTermsList();
        this.getInvoicesFilter();
    },
    mounted() {
        this.setTimeoutResponsive()
    },
    methods: {
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }                                          	
            } 
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
            }
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }                                             	
            } 
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }                                            	
            }
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        },        
        filterInvoiceRec() {},
        /* getInvoices() {
        	this.isLoading = true;
        	window.billing.get('invoicesrec/' + window.localuserdata)
        	.then((response) => {
        		console.log('invoices list');
        		console.log(response.data.result);
        		this.InvoicesList = response.data.result;
        		let e = this.InvoicesList;
        		for (var i = 0; i < e.length; i++)
            {
        			let d= new Date(e[i].startdate);
        			let sd = (d+'').split(' ');
        			this.InvoicesList[i].startdate= sd[1]+' '+sd[2]+', '+sd[3];
        			if(e[i].enddate != '' && e[i].enddate!= null){
        				d = new Date(e[i].enddate);
        				sd = (d+'').split(' ');
        				this.InvoicesList[i].enddate= sd[1]+' '+sd[2]+', '+sd[3];;
        			}else{this.InvoicesList[i].enddate='';}
             	this.intervalText[1]='Weekly';
      				this.intervalText[2]='Biweekly';
      				this.intervalText[3]='Monthly';
      				this.intervalText[4]='Quarterly';
      				this.intervalText[5]='Semiannual';
      				this.intervalText[6]='Annual';
           		for (let prop in this.TermsList) {
           			if(e[i].terms_id == this.TermsList[prop].id)
           			{
           				this.InvoicesList[i].termText = this.TermsList[prop].name;
           			}
           		}
              if(e[i].terms_id ==0){this.InvoicesList[i].termText='Due on Receipt';}
                   	
            }
            console.log(this.InvoicesList);
            this.isLoading = false;
          }).catch((error) => console.log(error));
        }, */
        getInvoicesFilter() {
            this.isLoading = true;
            window.billing
                .get(
                    "report/invoicesrec/" +
                        window.localuserdata +
                        "/" +
                        this.intervalselect +
                        "/" +
                        this.statuslist
                )
                .then((response) => {
                    this.statusdropd = false;
                    console.log("invoices list");
                    console.log(response.data);
                    this.InvoicesList = response.data.result;
                    let e = this.InvoicesList;
                    for (var i = 0; i < e.length; i++) {
                        let d = new Date(e[i].startdate);
                        let sd = (d + "").split(" ");
                        this.InvoicesList[i].startdate =
                            sd[1] + " " + sd[2] + ", " + sd[3];
                        if (e[i].enddate != "" && e[i].enddate != null) {
                            d = new Date(e[i].enddate);
                            sd = (d + "").split(" ");
                            this.InvoicesList[i].enddate =
                                sd[1] + " " + sd[2] + ", " + sd[3];
                        } else {
                            this.InvoicesList[i].enddate = "";
                        }
                        /* 	this.intervalText[1]='Weekly';
      				this.intervalText[2]='Biweekly'; */
                        this.intervalText[3] = "Monthly";
                        this.intervalText[4] = "Quarterly";
                        this.intervalText[5] = "Semiannual";
                        this.intervalText[6] = "Annual";
                        for (let prop in this.TermsList) {
                            if (e[i].terms_id == this.TermsList[prop].id) {
                                this.InvoicesList[i].termText = this.TermsList[prop].name;
                            }
                        }
                        if (e[i].terms_id == 0) {
                            this.InvoicesList[i].termText = "Due on Receipt";
                        }
                        let total2 = 0;
                        if (e[i].interval == 3) {
                            total2 = e[i].total * 12;
                        }
                        if (e[i].interval == 4) {
                            total2 = e[i].total * 4;
                        }
                        if (e[i].interval == 5) {
                            total2 = e[i].total * 2;
                        }
                        if (e[i].interval == 6) {
                            total2 = e[i].total * 1;
                        }
                        e[i].total2 = total2;

                        // monthly / 1 
                        let tMonth;
                        //debugger;
                        if (e[i].interval == 3) {
                            tMonth = total2 / 12;
                        }
                        // quarterly / 3
                        if (e[i].interval == 4) {
                            tMonth = total2 / 12;
                        }
                        // semiannual / 6
                        if (e[i].interval == 5) {
                            tMonth = total2 / 12;
                        }// annual / 12
                        if (e[i].interval == 6) {
                            tMonth = total2 / 12;
                        }
                        e[i].totalMonth = tMonth;
                    }
                    console.log(this.InvoicesList);
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        getTermsList() {
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    console.log("terms");
                    console.log(response.data);
                    this.TermsList = response.data;
                })
                .catch((error) => console.log(error));
        },
        /*  generarFiles(type){
       self = this;
        this.$swal({
            title: "Export",
            text: "Export Report as "+type+" (Microsoft Excel)",
            icon: "info",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: 'Cancel',
            confirmButtonText: "Export",
            showLoaderOnConfirm: true,
            preConfirm: () => {
            window.billing.post('report/generatefileinvoicerec',{
                          user_id: window.localuserdata,
                          intervalselect: this.intervalselect,
                          statuslist: this.statuslist,
                          filetype : type,
                          })
                .then(response => {
                  console.log('export excel inv rec')
                    console.log(response.data);
                    self.statusdropd = false
                    self.isLoading = false
                    window.open(window.urlbase+'files/'+response.data.result);
                    
                })
                .catch(error => {
                  self.isLoading = false
                  self.$swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
          .then((result) => {
          if (result.value) {
             this.$swal.fire({
              allowOutsideClick: false,
              text: `Successfully downloaded`,
              confirmButtonText: "Close",
              showLoaderOnConfirm: true,
                preConfirm: () => {
                 this.DeleteFile();
                },
            })
          }
        }) 
        }, */
        DeleteFile() {
            let name = "paymentsreceived";
            window.billing
                .get("report/deletefilepaymentr/" + window.localuserdata + "/" + name)
                .then((response) => {
                    console.log(response.data.result);
                })
                .catch((error) => console.log(error));
        },
        generarFile(type) {
            let user_id = window.localuserdata;
            let uri =
                "report/export/invoicesrec/pdf/" +
                user_id +
                "/" +
                this.intervalselect +
                "/" +
                this.statuslist +
                "/" +
                type;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        ChangeSelectPrimary() {
            console.log();
        },
        ChangeSelectStatus() {},
    },
    computed: {
        total: function () {
            if (!this.InvoicesList) {
                return 0;
            }
            return this.InvoicesList.reduce(function (total, value) {
                return total + Number(value.total);
            }, 0);
        },
        totalAnn: function () {
            if (!this.InvoicesList) {
                return 0;
            }
            return this.InvoicesList.reduce(function (total, value) {
                return total + Number(value.total2);
            }, 0);
        },
        totalMonth: function () {
            if (!this.InvoicesList) {
                return 0;
            }
            return this.InvoicesList.reduce(function (total, value) {
                return total + Number(value.totalMonth);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style-type: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
#invList thead tr th:nth-child(2),
#invList tbody tr td:nth-child(2) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(3),
#invList tbody tr td:nth-child(3) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(4),
#invList tbody tr td:nth-child(4) {
    min-width: 105px;
}
#invList thead tr th:nth-child(5),
#invList tbody tr td:nth-child(5) {
    min-width: 105px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
#invList thead tr th:nth-child(11),
#invList tbody tr td:nth-child(11) {
    min-width: 85px;
    width: 85px;
}
@media (max-width: 1100px) {
    #invList thead tr th:nth-child(1),
    #invList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    #invList thead tr th:nth-child(2),
    #invList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(3),
    #invList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(4),
    #invList tbody tr td:nth-child(4) {
        min-width: 60px;
        width: 60px;
    }
    #invList thead tr th:nth-child(5),
    #invList tbody tr td:nth-child(5) {
        min-width: 60px;
        width: 60px;
    }
    #invList thead tr th:nth-child(6),
    #invList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(7),
    #invList tbody tr td:nth-child(7) {
        min-width: 150px;
        width: 150px;
    }
    #invList thead tr th:nth-child(8),
    #invList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(9),
    #invList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(10),
    #invList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    #invList thead tr th:nth-child(11),
    #invList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}

</style>
