<template>
	<div>
		<div class="page-breadcrumb">
			<div class="row">
				<div class="col-5 align-self-center"></div>
				<div class="col-7 align-self-center">
					<div class="d-flex align-items-center justify-content-end">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<!-- <a href="#"  class="btn btn-success"><i class="fas fa-plus"></i> Create New</a> -->
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card" style="background: transparent">
						<div class="card-header">
							<div class="row">
								<div class="col-md-6">
									<router-link
										:to="{ name: 'PaymentsReceivedNew' }"
										class="btn btn-success"
									>
										<i class="fas fa-plus"></i> Add Payments
									</router-link>
								</div>
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-8">
											<input
												type="text"
												class="form-control"
												placeholder="Search"
												v-model="inputFilter"
												v-on:keyup="searchFilter"
											/>
										</div>
										<div class="col-md-2">
											<div
												class="basic_dropdown"
												v-click-outside="hideBtnstatus"
											>
												<button
													class="btn-status"
													@click="btnstatus()"
												>
													Status
													<i class="fas fa-caret-down"></i>
												</button>
												<ul
													class="reset dropdStatus"
													v-if="statusdropd"
												>
													<li>
														<div
															class="custom-control custom-checkbox"
														>
															<input
																type="checkbox"
																class="custom-control-input"
																id="check12"
																@click="checkAll()"
																v-model="isCheckAll"
															/>
															<label
																class="custom-control-label"
																for="check12"
																><b>All</b></label
															>
														</div>
													</li>
													<li
														v-for="(
															item, index
														) in statusdata"
														:key="index"
													>
														<div
															class="custom-control custom-checkbox"
														>
															<input
																type="checkbox"
																class="custom-control-input"
																:id="index"
																v-bind:value="item.value"
																v-model="statuslist"
																@change="searchFilter"
															/>
															<label
																class="custom-control-label"
																:for="index"
																>{{ item.name }}</label
															>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<div class="col-md-2">
											<div class="input-group">
												<select 
													class="form-control "
													v-model="rowNum" 
												><option :value="100">Display</option>
													<option v-for="i in rowList" :key="i" :value="i">{{i}}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div
									class="contenedor-payments"
									style="min-height: calc(100vh - 230px)"
								>
									<table class="table table-striped">
										<thead>
											<tr>
												<th scope="col">Payment #</th>
												<th scope="col">Date</th>
												<th scope="col">Name</th>
												<th
													scope="col"
													style="text-align: center"
												>
													Invoice
												</th>
												<th
													scope="col"
													style="text-align: center"
												>
													Amount
												</th>
												<th
													scope="col"
													style="text-align: center"
												>
													Status
												</th>
												<th
													scope="col"
													style="text-align: center"
												>
													Email
												</th>
												<th scope="col"></th>
											</tr>
										</thead>
										<tbody v-if="isLoading">
											<tr>
												<td colspan="8" class="text-center">
													<Loader ></Loader>
												</td>
											</tr>
										</tbody>
										<tbody v-else-if="PaymentListFilter.length > 0">
											<tr
												v-for="item in PaymentListFilter"
												:key="item.id"
											>
												<th scope="row">
													{{
														item.idx
															.toString()
															.padStart(4, "000")
													}}
													<button 
														v-if='item.notes > 0'
														@click="actionNotes(item.id, item.memo)"                                                    
														type="button" 
														title="Edit Notes"                                                        
														class="btn btn-warning"
														style="float: right; padding: 0px 3px; font-size: 10px; height: 16px;">
														<i class="far fa-sticky-note"></i>
														<span class="textIcon" style="display: none;"> Edit Note</span>
													</button>
													<button
														v-else
														@click="actionNotes(item.id, item.memo)"                                                    
														type="button" 
														title="Edit Notes" 
														class="btn btn-secundary"
														style="float: right; padding: 0px 3px; font-size: 10px; height: 16px;">
														<i class="far fa-sticky-note"></i>
														<span class="textIcon" style="display: none;"> Edit Note</span>
													</button>                                                                                                        
												</th>
												<td>{{ item.fecha }}</td>
												<td>
													{{ item.firstname }}
													{{ item.lastname }}
													<br />
													<span style="color: #a9a9a9">{{
														item.bussinesname
													}}</span>
												</td>
												<td style="text-align: center">
													{{ item.numdoc }}
												</td>
												<td style="text-align: center">
													$ {{ item.price | formatMoney }}
												</td>
												<td style="text-align: center">
													<span
														v-if="item.status == 1"
														class="badge badge-success"
														>Accepted</span
													>
													<span
														v-if="item.status == 0"
														class="badge badge-danger"
														style="background-color: #f70d0d"
														>Void</span
													>
												</td>
												<td style="text-align: center">
													<button
														style="
															border: 0;
															background: transparent;
															cursor: pointer;
															font-size: 13px;
														"
														@click="
															actionEmail(
																item.id,
																item.email,
																item.idx
																	.toString()
																	.padStart(4, '000')
															)
														"
													>
														{{ item.numenv }}
														<i
															class="fas fa-envelope"
															style="
																color: #f89406;
																font-size: 18px;
															"
														></i>
													</button>
												</td>
												<td style="width: 85px">
													<span class="cdropdown">
														<button>Actions</button>
														<label>
															<input type="checkbox" />
															<ul>
																<li
																	@click="
																		actionEdit(
																			item.id
																		)
																	"
																>
																	<button>
																		<i
																			class="fas fa-edit"
																		></i>
																		Edit
																	</button>
																</li>
																<li
																	v-if="
																		item.status == 1
																	"
																	@click="
																		anularPago(
																			item.id,
																			item.invoice_id
																		)
																	"
																>
																	<button>
																		<i
																			class="fas fa-trash-alt"
																		></i>
																		Void
																	</button>
																</li>
																<li
																	@click="
																		elimPago(
																			item.id,
																			item.invoice_id,
																			item.img1,
																			item.img2
																		)
																	"
																>
																	<button>
																		<i
																			class="fas fa-times"
																		></i>
																		Delete
																	</button>
																</li>
															</ul>
														</label>
													</span>
												</td>
											</tr>
										</tbody>
										<tbody v-else>
											<tr>
												<td colspan="8">
													<div class="text-center d-block w-100 p20" >
														<div class="text-center" style="padding: 80px">
															<span style="padding-bottom: 30px"
																>No record
															</span>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
										<tfoot >
											<tr>
												<td colspan="8">
													<ul 
														class="pagination justify-content-between" 
														style="borde: 1px solid blue"
													>
														<li>
															<div class="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-text">Show: </div>
																</div>
																<select 
																	class="form-control selectRowList"
																	v-model="rowNum" 
																>
																	<option v-for="i in rowList" :key="i" :value="i">{{i}}</option>
																</select>
															</div>
														</li>
														<li class="page-car">
															<div class="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-text">Pag: </div>
																</div>
																<select 
																	v-model="page" 
																	class="form-control selectRowList" 
																	v-on:change="changePage(page)"
																>
																	<option v-for="i in numPag" :key="i" :value="i">{{i}}</option>
																</select>
															</div>  
														</li>
														<li>
															<span class="page-view">View {{ pagination.to}} of {{ pagination.total}}</span>
														</li>
													</ul>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- modal -->
		<modalLateral>
			<component v-bind:is="m.component" :items="mailPayment"></component>
		</modalLateral>         
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import modalEmail from '@/apps/billing/components/modalEmail'
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
export default {
	name: "Payment",
	components: {
		Loader,
		modalEmail,
		NotesAdmin,
		modalLateral
	},
	data () {
		return {
		   m: {
				show: false,
				component: '',
				data: []
			},            
			isLoading: false,
			PaymentList: [],
			mailPayment: {
				isLoading: false,
				id: null,
				IDitem: null, // numdoc
				email: null,
				EmailCheck: false,
				view: "payment",
			},
			SettingBox: "",
			emailsp: [],
			inputFilter: "",
			OldPaymentsList: [],
			invStatus: 0,
			isFilter: false,
			isCheckAll: true,
			statusdata: [
				{ value: "1", name: "Accepted" },
				{ value: "0", name: "Void" },
			],
			statuslist: [1, 0],
			statusdropd: false,			
			paginador: 0,
			offset: 4,
			page: 1,
			pagination: {
				current_page: 1,
				last_page: 1,
				to: 1,
				total: 1,
			},
			query: "",
			rowNum: 100,
			rowList: [100, 200, 300, 500],
			numPag: [1],
            validEprint: false,
		};
	},
	created () {
		this.searchFilter();
	},
	directives: {
		ClickOutside,
	},
	methods: {
		numPagList() {
			if(this.pagination.total == 1) {
				this.numPag = [1];
			} else{
				this.numPag = [];
				for (var i = 1; i <= this.pagination.last_page; i++) {
					this.numPag.push(i);
				}
			}
		},
		changePage(page) {
			this.page = page;
			this.query = '';
			if(typeof this.page != 'number') { 
				this.page= 1; 
				console.log('no es number');
			}
			else if(this.page > 1){
				this.query = "?page=" + page;
			}
			this.pagination.current_page = page;
			this.searchFilter();
		},
		btnstatus() {
			this.statusdropd = !this.statusdropd;
		},
		hideBtnstatus() {
			this.statusdropd = false;
		},
		checkAll: function () {
			this.isCheckAll = !this.isCheckAll;
			this.statuslist = [];
			if (this.isCheckAll) {
				for (var key in this.statusdata) {
					this.statuslist.push(this.statusdata[key].value);
				}
				this.searchFilter();
			}
		},
		updateCheckall: function () {
			if (this.statuslist.length === this.statusdata.length) {
				this.isCheckAll = true;
			} else {
				this.isCheckAll = false;
			}
		},
		searchFilter() {
			let t = this;
			t.isLoading = true;
			t.PaymentList = [];
			window.billing.post("filterpayments" + t.query, {
					user_id: window.localuserdata,
					filter: t.inputFilter,
					status: t.statuslist,
					pag: t.paginador,
					rowNum: t.rowNum,
			})
			.then((response) => {
				//console.log('filter ',response.data.result);
				t.validEprint = (response.data.ePrint && response.data.ePrint.meta_value);
				t.pagination = response.data.result;
				let e = response.data.result.data;
				let listFilter = [];
				for (let i in e) {
					let fecha = new Date(e[i].fecha).toDateString().slice(4, 15);
					let fecha_new = fecha.split(" ");
					e[i].fecha =
						fecha_new[0] + " " + fecha_new[1] + ", " + fecha_new[2];
					listFilter.push(e[i]);
				}
				console.log('listFilter ', listFilter);
				t.PaymentList = listFilter;
				console.log('PaymentList ', t.PaymentList);
				t.isFilter = true;
				t.isLoading = false;
				t.numPagList();
			})
			.catch((error) => {
				t.isLoading = false;
				console.log(error);
			});
		},
		actionEmail(id, email, numdoc) {
			this.mailPayment.idinv = id; // id de payment
			this.mailPayment.IDitem = numdoc;
			this.mailPayment.email = email;
            this.mailPayment.validEprint = this.validEprint;
			let t = this;
			this.m = {
				show: true,
				component: 'modalEmail',
				data: {mailPayment:t.mailPayment}
			}  
			this.$store.commit('core/setOpenModal', this.m);            
		},
		actionNotes(id, notes){
			let edit = false
			if (notes !== null && notes !== "" && notes !== "null"){
				edit = true
			}    
			let t = this;
			this.m = {
				show: true,
				component: 'NotesAdmin',
				data: {
					id:id, 
					type:"Payment",
					edit: edit,
					transac_type: "Payment",
					idNote: id
				}
			}             
			this.$store.commit('core/setOpenModal', this.m);  
		},
		sendMailPayment(item) {
			if (item.DataItem.send === 1) {
				item.DataItem.send = 0;
				this.$store.commit("core/setCloseModal", {
					action: 'sendMailPayment',                     
				});
				this.getlist();
				this.$notify({
					group: "noti",
					title: "Success",
					type: "success",
					text: "Send Mail!",
				});
				this.$store.commit("ModApps/billing/SetResponseMail", null);
			}
		},
		ClosedModal() {
			this.SettingBox = "";
		},
		getlist() {
			this.searchFilter();
			/*this.isLoading = true;
			window.billing
				.get("payments/" + window.localuserdata)
				.then((response) => {
					console.log(response.data.result);
					this.PaymentList = response.data.result;
					let e = this.PaymentList;
					for (var i = 0; i < e.length; i++) {
						let fecha = new Date(e[i].fecha).toDateString().slice(4, 15);
						let fecha_new = fecha.split(" ");
						this.PaymentList[i].fecha =
							fecha_new[0] + " " + fecha_new[1] + ", " + fecha_new[2];
					}
					this.OldPaymentsList = this.PaymentList;
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
				});*/
		},
		actionEdit(id) {
			this.$router.push({ name: "PaymentsReceivedNew", params: { idpay: id } });
		},
		anularPago(id, idi) {
			this.isLoading = true;
			this.$swal({
				title: "Void this Payment?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Void it!",
			}).then((result) => {
				if (result.value) {                                                
					window.billing
						.put("payments/anular/" + id, {
							user_id: window.localuserdata,
							invoice_id: idi,
							org_id: localStorage.getItem("mark_client_data"),
							user_idd: localStorage.getItem("mark_client_data_m"),
							typeUser: localStorage.getItem("user_type"),
							oauth: localStorage.getItem("oauth_m"),
						})
						.then((response) => {
							console.log(response.data);
							this.isLoading = false;
							if (response.data.status) {
								this.getlist();
								this.$notify({
									group: "noti",
									title: "Void!",
									type: "success",
									text: "Void Payment Successful",
								});
							}
						})
						.catch((error) => {
							this.isLoading = false;
							console.log(error);
						});
				}
			});
			this.isLoading = false;
		},
		elimPago(id, idi, img1, img2) {
			this.isLoading = true;
			this.$swal({
				title: "Delete this Payment?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					window.billing
						.put("payments/trash/" + id, {
							user_id: window.localuserdata,
							invoice_id: idi,
							folder_user: localStorage.getItem("folder_user"),
							org_id: localStorage.getItem("mark_client_data"),
							user_idd: localStorage.getItem("mark_client_data_m"),
							typeUser: localStorage.getItem("user_type"),
							oauth: localStorage.getItem("oauth_m"),
						})
						.then((response) => {
							console.log(response.data);
							if (response.data.status) {
								this.getlist();
								this.elimIMG(img1, img2);
							} else {
								this.isLoading = false;
							}
						})
						.catch((error) => {
							this.isLoading = false;
							console.log(error);
						});
				}
			});
			this.isLoading = false;
		},
		elimIMG(img1, img2) {
			console.log("imgs", img1, img2);
			let cont = 0;
			let form = {};
			form.org_id = window.localuserdata;
			form.folder = "payments";
			form.delIMG = "img";
			if (img1 !== null && img1 !== "" && img1 !== "null") {
				cont++;
				form["img" + cont] = img1;
			}
			if (img2 !== null && img2 !== "" && img2 !== "null") {
				cont++;
				form["img" + cont] = img2;
			}
			form.contDel = cont;
			if (cont > 0) {
				window.master
					.post("deleteimgs", form)
					.then((response) => {
						console.log(response.data.imgs);
						this.$notify({
							group: "noti",
							title: "Delete!",
							type: "success",
							text: "Delete Payment Successful",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			} else {
				this.$notify({
					group: "noti",
					title: "Delete!",
					type: "success",
					text: "Delete Payment Successful",
				});
				this.isLoading = false;
			}
		},
	},
	watch: {
		rowNum: function(val){
			if(this.rowNum > this.pagination.total || this.rowNum >(this.pagination.total - this.pagination.to)){
				this.changePage(1); // retornamos a la pagina 1
			} else {
				this.searchFilter();
			}
		},
		'getOpenModal': function() {
			if (!this.getOpenModal.show) {
				if (Object.keys(this.getOpenModal.data).length > 0) {
					// this.getlist();
					let t = this.getOpenModal.data;
					if (t.methods === 'invoNote') {
						let id = t.id
						let value = t.value
						let memo = t.memo
						let g = this.PaymentList
						let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                                g[i].memo = memo;
                            } 
                            a.push(g[i]);
                        }
						this.PaymentList = a
					}
				}
				this.m = {
					show: false,
					component: '',
					data: {}
				}
			}
		},    
		'getActionRefresh': function() {
			if (Object.keys(this.getActionRefresh.data).length > 0) {
					// this.getlist();
					let t = this.getActionRefresh.data;
					if (t.methods === 'invoNote') {
						let id = t.id
						let value = t.value
						let memo = t.memo
						let g = this.PaymentList
						let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                                g[i].memo = memo;
                            } 
                            a.push(g[i]);
                        }
						this.PaymentList = a
					}
				}
		},      
		getResponseMail: function () {
			console.log("getResponseMail", this.getResponseMail);
			if (this.getResponseMail && this.getResponseMail.fEventBus === "payment-email") {
				this.sendMailPayment(this.getResponseMail);
			}
		},
	},
	computed: {
		...mapGetters("core", { 
			getOpenModal: "getOpenModal",
			getActionRefresh: "getActionRefresh",
		}),
		...mapGetters("ModApps/billing", { getResponseMail: "getResponseMail" }),
		PaymentListFilter(){
			return this.PaymentList;
		},
	},
};
</script>
<style lang="scss" scoped>
.contenedor-payments table {
	width: 100%;
	min-width: 767px;
	margin-bottom: 250px;
}
.dropdStatus {
	position: absolute;
	width: 130px;
	height: auto;
	background-color: #fff;
	z-index: 9;
	margin: 0;
	border: 1px solid #ccc;
	padding: 10px;
	list-style: none;
	list-style-type: none;
}
.dropdStatus li {
	text-align: left;
}
.dropdStatus li label {
	text-align: left;
	display: block;
}
.btn-status {
	font-size: 13px;
	letter-spacing: 0.5px;
	color: #5d5b5b !important;
	border-radius: 0;
	border: 1px solid #dcdcdc;
	background-color: #fff;
	padding: 7px;
}
tbody tr:nth-of-type(odd) {
	background-color: #e9ecef;
}
tbody tr:nth-of-type(even) {
	background-color: #fff;
}
@media (max-width: 767px) {
	.contenedor-payments {
		max-width: 767px;
		overflow: hidden;
		overflow-x: scroll;
	}
}
.contenedor-payments tbody tr td .badge {
	width: 90%;
}
</style>
