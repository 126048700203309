<template>
    <div>
        <!-- footer class="footer text-center">All Rights Reserved by <a href="https://Marketea.com">Marketea</a></footer -->
    </div>
</template>
<script>
export default {
    name: 'Aside'
};
</script>
