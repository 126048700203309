<template>
	<div>
		<header-principal></header-principal>
		<div v-if="accessApp">
            <!-- <component 
                v-if="getHeader" 
                v-bind:is="component"
                @activarLateral="showAside = $event"
            ></component> -->
			<div 
                v-if="getHeader" 
                v-bind:class="[
                    'page-wrapper', 
                    {show: showAside}, 
                    {'asideSites-active':maquetador}
                ]" 
            >
				<router-view></router-view>
			</div>
		</div>
        <div id="loading" v-if="loading"><div class="op-loading"></div></div>
		<footer-principal></footer-principal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import FooterPrincipal from "@/core/views/Footer";
export default {
    name: "SitesLayout",
    components: {
        HeaderPrincipal,
        FooterPrincipal,
    },
    data() {
        return {
            showAside: false,
            maquetador: false,
            component: 'maside',
            loading: false,
        };
    },
    created() {
        this.loading=true;
        if (this.loggedIn && this.getHeader) {
            //console.log('si hay login M');
            this.validAccess();
        }
    },
    methods: {
        validAccess() {
            let t = this;
            t.$store.dispatch("core/apps/validAccess").then((response) => {
                let App = response;
                if (App) {
                    //si tiene Sites asignado entonces permitir acceso
                    console.log("Accesso permitido");
                    t.$store.dispatch("ModApps/Sites/getListWebs", true).then((response) => {
                        console.log("get sites");
                        t.$store.dispatch("ModApps/Sites/SetActive", App);
                        t.loading=false;
                    })
                    .catch((error) => {
                        console.log(error);
                        t.$store.dispatch("ModApps/Sites/SetActive", false);
                        t.loading=true;
                    });
                    let url = t.$router.currentRoute.name;
                    //t.asideMaquetador(url);
                } else {
                    t.$store.dispatch("ModApps/Sites/SetActive", App);
                    console.log("Accesso Negado");
                    t.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
        asideMaquetador(url){
            console.log('url layout', url);
            if (url == 'maquetador' || url == 'editMaquetador') {
                this.maquetador= true;
                this.component = 'asideSites';
            }
            else{
                this.maquetador= false;
                this.component = 'maside';
            }
        },
    },
    watch: {
        getHeader: function () {
            //console.log('getHeader ', this.getHeader);
            this.validAccess();
        },
        $route(to, from) {
            // this.$router.currentRoute.name
            let url = to.name;
            //this.asideMaquetador(url);
        },
        accessApp: function(){
            if(!this.accessApp){
                this.loading=true;
            }
        },
        loadSites: function(){
            this.loading=this.loadSites;
        }
    },
    computed: {
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" }),
        ...mapGetters("ModApps/Sites", { accessApp: "accessApp", loadSites: "get_loadSites" }),
    },
};
</script>
<style scoped lang="css">
.header {
  top: 0;
}
.page-wrapper {
   margin-top: 40px;
   margin-left: 0px;
}
.asideSites-active{
  margin-left: 0px !important; 
}
</style>
