<template>
    <div class="template_container">
        <div class="cover_container">
            <div class="cover_picture" :style="$parent.isBackgroundCover($parent.item)">
                <div class="cover_video" v-if="$parent.item.pictures[0].cover_v_active">
                    <video id="cover_video_player" loop muted="muted" playsinline="true" autoplay :key="'video-'+$parent.item.id"
                    :style="$parent.positionVideo($parent.item)">
                        <source :src="$parent.srcCoverVideo($parent.item)" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                <div class="cover_picture_before"
                    v-if="$parent.item.pictures[0].cover_active"
                    :style="'background-color: rgba(0 0 0 / '+$parent.item.pictures[0].cover_overlay+'%);'"
                ></div>
                <div class="edit edit-cover" @click="$parent.$emit('setModal', 'editCover')"><i class="fas fa-pen"></i> Cover</div>
                <!-- <div class="logo_picture" :style="isLogo(item)"></div> -->
                <!-- <div class="edit edit-logo" @click="$emit('setModal', 'editLogo')"><i class="fas fa-pen"></i> Edit Logo</div> -->
            </div>
        </div>
        <div class="profile">
            <div class="profile_picture" :style="$parent.isProfile($parent.item)">
                <i class="fas fa-user" v-if="$parent.isProfile($parent.item)==''"></i>
                <Loader v-if="$parent.profileLoad" class="profileLoad"></Loader>
                <div class="edit edit-profile" @click="$parent.editPicProfile()"><i class="fas fa-pen"></i></div>
            </div>
            <div class="author-info">
                <div class="edit edit-info" @click="$parent.$emit('setModal', 'editInfo')"><i class="fas fa-pen"></i></div>
                <h1 class="name">{{$parent.item.name}} {{$parent.item.lastname}}</h1>
                <p class="rol">{{$parent.item.rol}}</p>
                <h2 class="company">{{$parent.orginf.com_company_name}}</h2>
            </div>
        </div>
        <div class="add_contact_share_qr">
            <a href="#Show_Qr" @click.prevent="$parent.linkVoid()" class="btn show_Qr hvr-float" aria-label="show or Hide code Qr">
                <i class="fas fa-qrcode"></i>
            </a>
            <div class="section-btnsActions">
                <div class="edit edit-btnsActions" @click="$parent.edit_btnsActions()"><i class="fas fa-pen"></i></div>
                <div class="list-btnsActions" v-if="$parent.premiumProfile && $parent.item.btns_action!=null && $parent.item.btns_action.length>0">
                    <template v-for="(b, idxBtns) in $parent.item.btns_action">
                        <a :key="idxBtns"
                            v-if="b.status==1 && idxBtns==0"
                            href="#"
                            @click.prevent="$parent.edit_btnsActions()"
                            class="btn-blue btn-action hvr-shrink"
                            :aria-label="b.label"
                            :style="customBgBtn(b.style)"
                        >
                            {{b.label}}
                        </a>
                    </template>
                </div>
                <a v-else href="#" @click.prevent="$parent.edit_btnsActions()" class="btn-blue btn-action hvr-shrink" aria-label="ADD TO CONTACT">
                    ADD TO CONTACT
                </a>
            </div>
            <a href="#Share_link" @click.prevent="$parent.linkVoid()" class="btn share_link hvr-float" aria-label="Share" >
                <img :src="$parent.urlweb+'img/share.svg'" style="width: 25px;" alt="icon_share" width="25" height="32"/>
            </a>
        </div>
        <div :class="['contact-iconos', 'text-center', 'edit-contact']">
            <div class="box-icon" v-for="c in $parent.item.find_me_at" :key="c.id" v-show="c.status==1">
                <div class="button-icon hvr-sweep-to-bottom">
                    <a href="#" @click.prevent="$parent.editContact()" :class="'btn btn-sbg '" >
                        <i :class="c.icon"></i>
                        <p>{{$parent.helperLabel(c)}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"htmlTemplate1",
    methods: {
        customBgBtn(style){
            let t = this;
            let d = t.$parent.style_default;
            let s = '';
            let css = (style==null || style=='null' || style=='' || style==='[object Object]') ? d : ((typeof style === 'object') ? style : JSON.parse(style));
            if(css!=''){
                s+='background: '+css.bgColor+';';
                s+='border-color: '+css.bgColor+';';
                s+='color: '+css.txtColor+';'
            }
            // console.log('style', s);
            return s;
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('../scss/efectsThemes.scss');
@import url('../scss/baseTemplate.scss');
@import url('../scss/classic_grid.scss');
</style>
