/*rutas de Banking*/
import Banking from '@/apps/billing/views/Banking';
import BankingNew from '@/apps/billing/views/BankingNew';
import BankingTransac from '@/apps/billing/views/BankingTransac';
export default [
  {
    path: 'banking/',
    name: 'Banking',
    folder: Banking,
  },
  {
    path: 'banking/new',
    name: 'BankingNew',
    folder: BankingNew,
  },
  {
    path: 'banking/new/:idb',
    name: 'BankingEdit',
    folder: BankingNew,
  },
  {
    path: 'banking/transac/:idb',
    name: 'BankingTransac',
    folder: BankingTransac,
  }
];
