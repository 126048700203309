<template>
    <div class="card">
        <div class="card-header">
            <h3>Attach PDF <i class="fas fa-file-pdf"></i></h3>
        </div>
        <div class="card-body" style="padding-top: 0;">
            <div class="row">
                <Loader class="loading_pdf" v-if="isLoading" :getProgress="loadProgress"></Loader>
                <div class="col-12">
                    <button class="btn btn-info btnUploadPdf" v-if="!pdfsrc" @click="$refs.filepdf.click()">Upload Document <i class="fas fa-file-pdf" style="margin-left: 10px;"></i></button>
                    <button class="btn btn-danger btnUploadPdf" v-else @click="delFilePdf()">Delete Document <i class="fas fa-file-pdf" style="margin-left: 10px;"></i></button>
                    <input type="file"
                        id="filepdf"
                        ref="filepdf"
                        accept=".pdf"
                        style="display: none;"
                        @change="loadPdf"
                    />
                </div>
                <div class="col-12" v-if="pdfsrc">
                    <iframe :src="pdfsrc" style="width: 100%;height: 80vh; border: none;">
                        Oops! an error has occurred.
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    data() {
        return {
            item: null,
            type: 1, // solo pdf
            name: '',
            file_name: null,
            fileUpload: null,
            loadProgress: 0,
            isLoading: false,
            pdfsrc: null,
            fileID: null,
        };
    },
    created() {
			let t = this;
			t.item = t.$store.getters['core/getOpenModal'].data;
            t.getFilePdf();
    },
    methods : {
        getFilePdf() {
            let t = this;
            t.isLoading= true;
            window.master.post("getSaleFilesLinks", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sale_id: t.item.id,
                type: t.type,
            })
            .then((response) => {
                console.log('getSaleFilesLinks', response.data);
                t.isLoading= false;
                if(response.data.get){
                    t.pdfsrc = response.data.urlPdf+'/'+response.data.get.file_name;
                    t.fileID = response.data.get.id;
                }else{
                    t.fileID = 0;
                    t.pdfsrc =null;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
            });
        },
        loadPdf(event){
            let t = this;
            t.isLoading= true;
            let target = event.target;
            console.log('change', event);
            t.inputFileStatus=1;
            let pdf = target.files[0];
            if(pdf){
                t.fileUpload=pdf;
                console.log('loadPdf', pdf);
                t.saveFile();
            }
        },
        saveFile() {
            let t = this;
            t.isLoading= true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("sale_id", t.item.id);
            formData.append("type", t.type);
            formData.append("name", t.name);
            formData.append("file_name", t.file_name);
            formData.append("file", t.fileUpload);
            let url = window.urlmaster + 'api/v3/setSalesfilesLinks'
            t.loadProgress=0;
            axios.post(url, formData, {
                headers: {
                common: {Authorization: 'Bearer ' + window.localtoken_M},
                post: {'Content-Type': 'multipart/form-data'}
            },
            onUploadProgress: (progressEvent) => {
                let scanPer = Math.floor(
                    (progressEvent.loaded*100 / progressEvent.total)
                );
                t.loadProgress=scanPer;
            }
            })
            .then((response) => {
                console.log('setSalesfilesLinks', response.data);
                if(response.data.status){
                    t.getFilePdf();
                    t.$parent.close();
                }
                t.isLoading= false;
                t.$refs.filepdf.value = null;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
                t.$refs.filepdf.value = null;
            });
        },
        delFilePdf(){
            let t = this;
            t.$swal({
				title: "Delete document PDF?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete!",
			}).then((result) => {
				if (result.value) {
                    t.isLoading= true;
                    window.master.post("delSaleFilesLinks", {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        sale_id: t.item.id,
                        type: t.type,
                        id: t.fileID,
                    })
                    .then((response) => {
                        console.log('delSaleFilesLinks', response.data);
                        t.getFilePdf()
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading= false;
                    });
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.loading_pdf{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0 0 0 / 25%);
    .container-progress{
        top: 0px;
        width: 80%;
        .progress{
            height: 10px;
        }
    }
}
.btnUploadPdf{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
}
</style>
