<template>
    <div>
        <div class="card-header">
            <h3>Show "New Estimate"</h3>
        </div>
        <form @submit.prevent="setBtn" id="form1" class="" >
            <div class="card-body">
                <div class="bgloading" v-show="isLoading" style="padding-top: 0;"><Loader></Loader></div>
                <div class="row">
                <div class="form-group col-sm-12">
                  <div class="alert alert-info" role="alert">
                    <p style="margin-bottom: 0;">Show button to make a new estimate when finished.</p>
                  </div>
                </div>
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.btnNew" :value="1"> Show
                      </label>
                  </div>
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.btnNew" :value="0"> None
                      </label>
                  </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm">SAVE</button>
            </div>
        </form>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalNewBtn',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        btnNew: 0,
      },
      apis: {},
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getBtn();
  },
  methods: {
    getBtn(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.getNewBtn(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        if(response.data.status){
          t.f = response.data.r;
        }
        t.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    setBtn(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.setNewBtn(), t.f)
      .then((response) => {
        t.getBtn();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
