<template>
    <div>
        <div class="card-header">
            <h3>Email Sender</h3>
        </div>
        <form @submit.prevent="setSender" id="form1" class="" >
            <div class="card-body">
                <div class="bgloading" v-show="isLoading" style="padding-top: 0;"><Loader></Loader></div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Name: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.name"
                        required="true"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Email: </label>
                    <input
                        type="email"
                        class="form-control"
                        v-model="f.email"
                        required="true"
                    />
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm">SAVE</button>
            </div>
        </form>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalSender',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        name: '',
        email: ''
      },
      apis: {},
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getSender();
  },
  methods: {
    getSender(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.getSender(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        t.f = response.data.r;
        t.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    setSender(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.setSender(), t.f)
      .then((response) => {
        t.getSender();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    resetForm(){
      this.f.id = 0;
      this.f.name = '';
      this.f.email = '';
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
