<template>
	<div class="card ">
		<div class="card-header">
			<h3>Questions and Answers</h3>
		</div>
		<div class="card-body" style="padding-top: 0px;">
			<div class="form-group">
				<label for="title">Question: </label>
				<textarea v-model="quest.name" class="form-control" required="true" style="height: 60px;" maxlength="150" @change="editNameDesc()"></textarea>
			</div>
			<div class="form-group" >
				<label for="description">Description: <small>(Optional)</small></label>
				<textarea v-model="quest.descrip" class="form-control" style="height: 80px;" maxlength="200" @change="editNameDesc()"></textarea>
			</div>
			<div class="form-group" v-if="action=='edit' && listAnswers.length>1">
				<label :for="'questMultiple'+quest.id">
					Multiple choices:  
					<small> (select multiple options)</small>
				</label>
				<div class="onoffswitch" style="display: inline-block;top: 8px;margin: 0px 5px;z-index: 1;">
					<input
						type="checkbox"
						class="onoffswitch-checkbox"
						:id="'questMultiple'+quest.id"
						:checked="quest.multiple"
						@change="multiplQuest"
					/>
					<label class="onoffswitch-label" :for="'questMultiple'+quest.id">
						<span class="onoffswitch-inner"></span>
						<span class="onoffswitch-switch"></span>
					</label>
				</div>
			</div>
			<div class="table-responsive" >
				<form @submit.prevent="AddQ">
				<table class="table table-striped mb-0">
					<thead>
						<tr>
							<td colspan="2">
									<div class="input-group">
										<input type="text" class="form-control" placeholder="Add new answer" v-model="nomItem" required maxlength="150">
									</div>
							</td>
							<td style="width: 120px;">
								<button 
									class="btn btn-success btn-md" 
									:disabled="(quest.name.length == 0 || nomItem.length == 0)"
								>
									Save answer
								</button>
							</td>
						</tr>
					</thead>
				</table>
				</form>
				<table class="table table-striped" >
					<thead>
						<tr>
							<th valign="middle">Answers</th>
							<th valign="middle" class="text-center">Checked</th>
							<th valign="middle" class="text-right">
							</th>
						</tr>
					</thead>
					<tbody v-if="listAnswers.length==0">
						<tr><td colspan="3" class="text-center">No record</td></tr>
					</tbody>
					<draggable
						v-else
						v-model="listAnswers"
						tag="tbody"
						class="tbody"
						v-bind="dragOptions"
						@change="checkMoveAnswers"
						@start="isDragging = true"
						@end="isDragging = false"
						handle=".handle"
					>
						<tr
							v-for="(i, index) in listAnswers"
							:key="index"
						>
							<td scope="row" style="position: relative">
								<div class="btnmove handle">
									<i class="fas fa-ellipsis-v"></i>
								</div>
								<span style="margin-left: 15px">
									<input type="text" class="item-edit form-control" :placeholder="'Answers'+index" v-model="i.name" @change="editItem(i)">
								</span>
							</td>
							<td class="text-center">
								<div class="onoffswitch" v-if="quest.multiple==1 && listAnswers.length>1">
									<input
										type="checkbox"
										class="onoffswitch-checkbox"
										:id="i.id"
										:checked="i.checked"
										@change="changeCheck(i)"
									/>
									<label class="onoffswitch-label" :for="i.id">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div>
								<span 
									v-else-if="quest.multiple==0 && listAnswers.length==1"
									class="badge badge-secondary"
								>
									Default
								</span>
								<div v-else class="custom-control custom-radio">
									<input type="radio" 
								  		class="form-control" 
								  		:checked="i.checked" 
								  		v-model="i.check" 
								  		:value="1"
								  		@change="checkDefault(i)"
								  	>
								</div>
							</td>
							<td class="text-right">
								<button class="btn btn-danger btn-sm" @click="deleteItem(i)">
									<i class="fas fa-times"></i>
								</button>
							</td>
						</tr>
					</draggable>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
	name: 'RoofCalAnswers',   
	components: {
		draggable,
	},
	data () {
		return {
			listAnswers: [],
			NoRecord: false,
			viewAnswers: false,
			nomItem: '',
			apis: {},
			action: 'add',
			quest: {
				id: 0,
				name: '',
				descrip: '',
				multiple: 0,
				orderItem: 0,
				status: 1,
			},
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4] }],
				["bold", "italic", "underline", "strike"], 
				["link"],
				["clean"] // remove formatting button
			],  
		}
	},

	created(){
        let getData = this.$store.getters['core/getOpenModal'].data;
        this.init(getData);
	},
	methods: {
		init(getData){
			let t = this;
        	t.quest.id = getData.id;
        	t.quest.name = getData.name;
        	t.quest.descrip = getData.descrip;
        	t.quest.multiple = getData.multiple;
        	t.quest.orderItem = getData.orderItem;
        	t.quest.status = getData.status;
        	if(getData.answers.length>0){
        		t.listAnswers = this.modelarStatus(getData.answers);
        	}else{
        		t.listAnswers = getData.answers;
        	}
        	t.action = getData.action;
        	t.nomItem='';
    	},
		modelarStatus(list) {
			let t = this;
			for (let j in list) {
				if(list[j]) {
					list[j].checked = (list[j].check>0);
				}
			}
			return list;
		},
		AddQ(){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			compPadre.nomQuest = t.quest.name
			compPadre.descripQuest = t.quest.descrip
			compPadre.multiple = t.quest.multiple
			compPadre.orderItem = t.quest.orderItem
			compPadre.status = t.quest.status
			compPadre.answers = t.nomItem;
			if(t.action=='add'){
				console.log('add Quest');
				compPadre.addQuest();
			}else{
				console.log('add Answers');
				compPadre.idQues = t.quest.id;
				//agregar mas answers
				compPadre.addAnswers();
			}
		},	
		editNameDesc(){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			if(t.action=='edit'){
				compPadre.editQuest(t.quest);
			}
		},
		multiplQuest(){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			if(t.action=='edit'){
				compPadre.changeMultiplQuest(t.quest);
			}
		},
		checkMoveAnswers(){
			let t = this;
			t.listAnswers.map((item, index) => {
				item.orderItem = index + 1
			});
			let compPadre = t.$parent.$parent.$parent;
			compPadre.checkMoveAnswers(t.listAnswers);
		},	
		editItem(i){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			compPadre.editAnswers(i);
		},
		changeCheck(i){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			compPadre.changeCheckAnswers(i);
		},
		deleteItem(i){
			let t = this;
			let compPadre = t.$parent.$parent.$parent;
			compPadre.deleteAnswers(i);
		},
		checkDefault(i){
			let t = this;
			let itemSelected = i;
			let newList=[];
			t.listAnswers.map((item, index) => {
				newList.push(item);
			});
			newList.map((item, index) => {
				if(item.id==i.id){
					item.checked = true;
					item.check = 1;
					itemSelected = item;
				}
				else{
					item.checked = false;
					item.check = 0;
				}
			});
			t.listAnswers=[];
			t.listAnswers=newList;
			let compPadre = t.$parent.$parent.$parent;
			compPadre.changeCheckAnswersDefault(itemSelected);
		},
	}, 
	watch: {
		getOpenModal: function(){
			if(this.getOpenModal.show){
				let getData = this.getOpenModal.data;
				console.log('refrescando datos del form')
				this.init(getData);
			}
		},
	}, 
	computed: {
		...mapGetters("core", { getOpenModal: "getOpenModal" }),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},
}
</script>
<style lang="scss" scoped>
.table tbody tr:nth-of-type(odd) { 
    background-color: rgb(0 0 0 / 5%);
}
.table tbody tr:nth-of-type(even) { 
    background-color: rgb(0 0 0 / 5%); 
}
.btnmove {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #057121;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 10px;
    padding-left: 5px;
    background-color: #04b653;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Off";
    padding-right: 10px;
    background-color: #eeeeee;
    color: #222;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #ffffff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 33px;
    border: 1px solid #057121;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
.item-edit.form-control {
	width: calc(100% - 15px);
	border:0px solid transparent;
	box-shadow: none;
	background-color: #fff;
	display: inline-block;
}
.btn-light{
	background-color: #fff;
	border-color: #ccc;
}
.btn-group.btn-group-lg {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.btn-group.btn-group-lg button.btn.btn-light {
    width: 100%;
    max-width: 300px;
}

button.btn.btn-light.active {
    font-weight: bold;
}
</style>
