<template>
	<div>
		<button 
			type="button" 
			class="btn btn-info" 
			@click="getTypeDesign" 
			v-if="listTypeDesign.length==0"
			style="margin-bottom:20px;"
		>
			<i class="fa fa-plus"></i> Add new
		</button>
		<button 
			type="button" 
			class="btn btn-light" 
			@click="cancelar" 
			v-if="listTypeDesign.length>0"
			style="margin-bottom:20px;"
		>
			<i class="fa fa-times"></i> Cancel
		</button>	
		<div class="row justify-content-center" v-show="isLoading"><Loader></Loader></div>
		<div class="row" v-if="listTypeDesign.length>0">
			<div class="col-xs-6 col-sm-4 col-md-3" v-for="(i, index) in listTypeDesign" :key="index">
				<div class="thumbnail">
					<img src="@/core/assets/no-image.png" alt="screenshots">
					<div class="caption">
						<h3 class="h3">{{i.name}}</h3>
						<p>{{i.decrip}}</p>
						<p class="botones">
							<button class="btn btn-success" @click="form">
								Install
							</button>
							<button type="button" class="btn btn-default bmd-modalButton" >
								Live demo
							</button>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";  
export default {
	name: 'listDesign',
	components: {
		Loader,
	},
	data () {
		return {
			listTypeDesign: [],
			isLoading:false,
			apis:{},
			f:{
                id: 0,
                user_id: window.localAccountID,
                org_id: window.localorgdata,
                name: "Title Landing Page",
				title: "Title Landing Page",
                keywords: "",
                description: "lorem Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                logo: 0,
                style: 0,							
			},
		}
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			let apisForm = this.$store.getters["ModApps/Marketea/urlApis"];
			this.apis = apisForm.landingPage;
			            console.log(this.$parent);
		},
        form() {
            this.isLoading = true;
            let url = this.apis.add();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
						this.cancelar()
						this.$parent.get()
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
		getTypeDesign(){
			this.listTypeDesign = [];
			this.isLoading = true;
			this.$parent.showlist = false;
			let url = this.apis.getdesing();
			window.master.get(url)
			.then((response) => {
				console.log(response);
				let r = response.data.r;
				this.listTypeDesign = r;
				this.isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				this.isLoading = false;
				this.$parent.showlist = true;
			});        	
        },
        cancelar(){
        	this.listTypeDesign = [];
			this.$parent.showlist = true;
			this.listTypeDesign = [];
        },
    }
};
</script>
<style lang="css" scoped>
.thumbnail {
	border: 1px solid #ccc;
	border-radius: 6px;
	padding: 4px;
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: 1px 1px 2px #ccc;
	background-color: #fafafa;
	margin-bottom: 20px;
}
.thumbnail:hover {
	box-shadow: 2px 2px 4px #ccc;
	background-color: #fff;	
}
.thumbnail img {
	width: 100%;
}

.thumbnail h3 {
	font-size: 24px;
}

.thumbnail p {
	font-size: 14px;
}
.thumbnail .botones {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-around;
}
button.btn.btn-light {
    background-color: #fff;
    border-color: #ccc;
}
</style>
