<template>
    <div class="template_container">
        <div class="cover_container">
            <div class="cover_picture" :style="$parent.isBackgroundCover($parent.item)">
                <div class="cover_video" v-if="$parent.item.pictures[0].cover_v_active">
                    <video id="cover_video_player" loop muted="muted" playsinline="true" autoplay :key="'video-'+$parent.item.id"
                    :style="$parent.positionVideo($parent.item)">
                        <source :src="$parent.srcCoverVideo($parent.item)" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                <div class="cover_picture_before"
                    v-if="$parent.item.pictures[0].cover_active"
                    :style="'background-color: rgba(0 0 0 / '+$parent.item.pictures[0].cover_overlay+'%);'"
                ></div>
                <div class="edit edit-cover" @click="$parent.$emit('setModal', 'editCover')"><i class="fas fa-pen"></i> Cover</div>
            </div>
        </div>
        <div class="infoContainer">
            <div class="add_contact_share_qr text-center">
                <a href="#Show_Qr" @click.prevent="$parent.linkVoid()" class="btn show_Qr hvr-float" aria-label="show or Hide code Qr">
                    <i class="fas fa-qrcode"></i>
                </a>
                <div class="profile">
                    <div class="profile_picture" :style="$parent.isProfile($parent.item)">
                        <i class="fas fa-user" v-if="$parent.isProfile($parent.item)==''"></i>
                        <Loader v-if="$parent.profileLoad" class="profileLoad"></Loader>
                        <div class="edit edit-profile" @click="$parent.editPicProfile()"><i class="fas fa-pen"></i></div>
                    </div>
                </div>
                <a href="#Share_link" @click.prevent="$parent.linkVoid()" class="btn share_link hvr-float" aria-label="Share" >
                    <img :src="$parent.urlweb+'img/share.svg'" alt="icon_share" width="25" height="32"/>
                </a>
            </div>
            <div class="author-info">
                <div class="edit edit-info" @click="$parent.$emit('setModal', 'editInfo')"><i class="fas fa-pen"></i></div>
                <h1 class="name">{{$parent.item.name}} {{$parent.item.lastname}}</h1>
                <p class="rol">{{$parent.item.rol}}</p>
                <h2 class="company">{{$parent.orginf.com_company_name}}</h2>
            </div>
            <div class="section-btnsActions">
                <div class="edit edit-btnsActions" @click="$parent.edit_btnsActions()"><i class="fas fa-pen"></i></div>
                <div class="list-btnsActions" v-if="$parent.premiumProfile && $parent.item.btns_action!=null && $parent.item.btns_action.length>0">
                    <template v-for="(b, idxBtns) in $parent.btns_action($parent.item.btns_action)">
                        <a href="#"
                            :key="idxBtns"
                            @click.prevent="$parent.edit_btnsActions()"
                            :class="[
                                'btn-action hvr-shrink',
                                (idxBtns==0 && $parent.btns_action($parent.item.btns_action).length>1) ? 'btnFirst' :
                                    (idxBtns==1) ? 'btnSecond' :
                                        (idxBtns==2) ? 'btnThird' : ''
                            ]"
                            :aria-label="b.label"
                            :style="customBgBtn(b.style)"
                        >
                            {{b.label}}
                        </a>
                    </template>
                </div>
                <a v-else href="#" @click.prevent="$parent.edit_btnsActions()"
                    class="btn-action hvr-shrink btn-action-width-full" aria-label="ADD TO CONTACT">
                    ADD TO CONTACT
                </a>
            </div>
        </div>
        <div class="contact-iconos menuData text-center edit-contact">
            <div class="box-icon" v-for="c2 in $parent.item.find_me_at" :key="c2.id" v-show="c2.status==1">
                <div :class="'button-icon  '+c2.label.toLowerCase()">
                    <a href="#" @click.prevent="$parent.editContact()" :class="'btn btn-sbg'">
                        <i :class="c2.icon"></i>
                    </a>
				</div>
                <p>{{$parent.helperLabel(c2)}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"htmlTemplate3",
    data(){
        return {
            style: {bgColor: "#0e51ca", txtColor: "#ffffff"}
        }
    },
    methods: {
        customBgBtn(style){
            let t = this;
            let d = t.style;
            let s = '';
            let css = (style==null || style=='null' || style=='' || style==='[object Object]') ? d : ((typeof style === 'object') ? style : JSON.parse(style));
            if(css!=''){
                s+='background: '+css.bgColor+';';
                s+='border-color: '+css.bgColor+';';
                s+='color: '+css.txtColor+';'
            }
            // console.log('style', s);
            return s;
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('../scss/efectsThemes.scss');
@import url('../scss/baseTemplate.scss');
@import url('../scss/jaglow.scss');
</style>
