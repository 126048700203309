let saveNotes = function() {
    this.jobsNoteBtn = true;
    let url = this.apis.saveNotes()
    window.master
        .post(url, {
            user_id: this.user_id,
            org_id: this.org_id,
            id: this.jobsNoteID,
            notes: this.jobsNote,
            link_drive: this.jobsLinkDrive,
        })
        .then((response) => {
            this.$notify({
                group: "noti",
                type: "success",
                title: "Success",
                text: "Updated note",
            });
            this.jobsNoteBtn = false;
            this.getJobsDash();
        })
        .catch((error) => {
            console.log(error);
            this.jobsNoteBtn = false;
        });
};
export default saveNotes;
