import apisDash from './apisDash';
import apisJobBox from './apisJobBox';
import apisJobFilter from './apisJobFilter';
import apisJobList from './apisJobList';
import apisSetup from './apisSetup';
import apisViewDash from './apisViewDash';
export default {  
	dash : apisDash,
	jobBox : apisJobBox,
	jobFilter : apisJobFilter,
	jobList : apisJobList,
	setup : apisSetup,
	viewDash : apisViewDash,
} 
