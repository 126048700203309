<template>
    <div>
        <header-principal></header-principal>
        <div v-if="accessJobs">
            <headerjobs></headerjobs>
            <asidejobs v-if="getHeader"></asidejobs>
            <div v-if="getHeader" class="page-wrapper" @click="ShowHiddenAside">
                <router-view></router-view>
            </div>
        </div>
        <footer-principal></footer-principal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import FooterPrincipal from "@/core/views/Footer";
import headerjobs from "@/apps/jobs/views/headerJobs";
import Asidejobs from "@/apps/jobs/views/AsideJobs";
export default {
    name: "JobsLayout",
    components: {
        HeaderPrincipal,
        FooterPrincipal,
        headerjobs,
        Asidejobs,
    },
    data() {
        return {};
    },
    created() {
        if (this.loggedIn && this.getHeader) {
            this.validAccess();
        }
    },
    methods: {
        validAccess() {
            this.$store.dispatch("core/apps/validAccess").then((response) => {
                let AppJobs = response;
                if (AppJobs) {
                    //si tiene Jobss asignado entonces permitir acceso
                    this.$store
                        .dispatch("core/session/privilegies_session")
                        .then((response) => {
                            console.log("Accesso permitido", response);
                            this.$store.dispatch("ModApps/jobs/SetActiveJobs", AppJobs);
                            //this.$store.dispatch("ModApps/jobs/update_JOPending", 0);
                        });
                } else {
                    this.$store.dispatch("ModApps/jobs/SetActiveJobs", AppJobs);
                    console.log("Accesso Negado");
                    this.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
        ShowHiddenAside() {
            this.$store.commit("ModApps/jobs/ActiveLateral", true);
        },
    },
    watch: {
        getHeader: function () {
            //console.log('getHeader ', this.getHeader);
            this.validAccess();
        },
    },
    computed: {
        ...mapGetters("ModApps/jobs", {
            accessJobs: "access_Jobs",
            showAside: "lateral",
        }),
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" }),
    },
};
</script>
<style scoped lang="scss">
.header {
    top: 0;
}
.page-wrapper {
    margin-left: 0px;
    padding-top: 40px;
    margin-top: 50px;
    z-index: initial !important;
}
</style>
