let changeStatus = function(i) {
	let t =this;
	var status = 0;
	if (i.status == 1) {
		status = 0;
	} else {
		status = 1;
	}
	i.status = status; 
	window.master.post(t.apis.editStatus(), i)
	.then((response) => {
		console.log('editStatusBntCall', response.data, i)
		for (let j in t.dataT) {
			if(t.dataT[j] && t.dataT[j].id == i.id) {
				t.dataT[j] = i;
				t.dataTOLD[j] = i;		
			}
		}
	})
	.catch((error) => {
		console.log(error);
			// t.isLoading = false;
		});
};
export default changeStatus;
