<template>
    <div class="header_card">
        <ul class="menu_card">
            <li class="item_card">
                <router-link :to="{ name: 'smartCard' }" class="link_card" exact-active-class="active">My
                    Cards</router-link>
            </li>
            <li class="item_card">
                <router-link v-if="premiumProfile" :to="{ name: 'minisite' }" class="link_card" exact-active-class="active">Mini Site</router-link>
                <a class="link_card disabled" v-else>Mini Site</a>
            </li>
            <li class="item_card">
                <router-link v-if="premiumProfile" :to="{ name: 'emailSignature' }" class="link_card" exact-active-class="active">Email Signature</router-link>
                <a class="link_card disabled" v-else>Email Signature</a>
            </li>
            <li class="item_card">
                <router-link :to="{ name: 'bSiteSetting' }" class="link_card" exact-active-class="active"><i class="fa fa-cog fa-lg"></i></router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
            isDisabled: false,
            premiumProfile: false,
            planesPro: [6, 7, 10, 11, 12],
            planActual: 1,
        };
    },
    created() {
        let t = this;
        t.premiumProfile = (t.$store.getters['core/apps/getPlanIdApp'] != 8);
        t.planActual = t.$store.getters['core/apps/getPlanIdApp'];
        if(t.planActual==7){
            t.premiumProfile = true;
        }
    },
}
</script>
