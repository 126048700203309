<template>
    <div>
        <div class="card-header">
            <h3>Step: {{typeStep[type]}}</h3>
        </div>
        <form @submit.prevent="setTitleDes" id="form1" class="" >
            <div class="card-body">
                <div class="bgloading" v-show="isLoading" style="padding-top: 0;"><Loader></Loader></div>
                <div class="row">
                  <div class="form-group  col-md-12">
                      <label for="title">Title: </label>
                      <vue-editor
                          id="title"                        
                          :editor-toolbar="customToolbar"
                          v-model="f.title"
                          style="width: 100%; height: 120px; "
                          required="true"
                      ></vue-editor><br><br>
                  </div>
                  <div class="form-group  col-md-12" v-if="type!=3 && type!=4">
                      <label for="description">Description: </label>
                      <vue-editor
                          id="description"                        
                          :editor-toolbar="customToolbar"
                          v-model="f.description"
                          style="width: 100%; height: 120px; "
                          required="true"
                      ></vue-editor><br><br>
                  </div>
                  <div class="form-group col-md-4" v-if="type==0">
                      <label for="textBtn">Text Btn: </label>
                      <input
                        type="text"
                        id="textBtn"
                        class="form-control"
                        v-model="f.textBtn"
                        required="true"
                        maxlength="20"
                      />
                  </div>
                  <div class="form-group col-md-8" v-if="type==0">
                      <label for="msjError">Alert Error: </label>
                      <input
                        type="text"
                        id="msjError"
                        class="form-control"
                        v-model="f.msjError"
                        required="true"
                        maxlength="20"
                      />
                  </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success ">SAVE</button>
            </div>
        </form>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
import { VueEditor } from 'vue2-editor'
export default {
  name: 'RoofCalTitleDes',
  components: {
    Loader,
    VueEditor
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        id: 0,
        title: '',
        description: '',
        typeStep: '',
        textBtn: '',
        msjError: '',
      },
      apis: {},
      type: 0,
      typeStep: [
        'Zip Code',
        'Address',
        'Other Details',
        'Data User',
        'Thank you'
      ],
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4] }],
          ["bold", "italic", "underline", "strike"], 
          ["link"],
          ["clean"] // remove formatting button
      ],  
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.type = this.$store.getters['ModApps/RoofCal/gettypeStep'];
    this.f.typeStep = this.typeStep[this.type];
    this.getTitleDes();
  },
  methods: {
    getTitleDes(){
      let t = this;
      t.isLoading = true;
      window.master.post(t.apis.getTitleDes(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
        typeStep: t.typeStep[t.type]
      })
      .then((response) => {
        t.f.id = response.data.r.id;
        t.f.title = response.data.r.title;
        t.f.description = response.data.r.description;
        t.f.textBtn = response.data.r.textBtn;
        t.f.msjError = response.data.r.msjError;
        t.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    setTitleDes(){
      let t = this;
      t.isLoading = true;
      window.master.post(t.apis.setTitleDes(), t.f)
      .then((response) => {
        t.getTitleDes();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    resetForm(){
      this.f.id = 0;
      this.f.title = '';
      this.f.description = '';
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-body {overflow: auto;height: 80vh;}
</style>
