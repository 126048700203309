// @ts-nocheck
// datos locales y token
const manifestJSON = require('../../public/manifest.json');
console.log('manifestJSON', manifestJSON)
localStorage.setItem('manifestJSON', JSON.stringify(manifestJSON)); //manifestJSON
window.getDataSession = localStorage.getItem('getDataSession'); //token master
window.localtoken_M = localStorage.getItem('access_token_m'); //token master
window.localtoken = localStorage.getItem('access_token'); // token billing
window.localtoken_P = localStorage.getItem('access_token_p'); // token project
window.localAccountID = localStorage.getItem('mark_client_data_m');// user_id
window.localuserdata = localStorage.getItem('mark_client_data'); // org_id
window.localorgdata = localStorage.getItem('mark_client_data'); // org_id
window.master_client = localStorage.getItem('master_client_data'); // master_user_id
window.user_type = localStorage.getItem('user_type'); // user_type
window.getStart = localStorage.getItem('start'); // start
window.oauth_m = localStorage.getItem('oauth_m'); // oauth_m
window.accountSMS = localStorage.getItem('accountSMS'); // accountSMS
window.localtoken_slabs = localStorage.getItem('access_token_slabs') || null; 
if(!localStorage.getItem('access_web')){localStorage.setItem('access_web', 0)}
window.access_web = localStorage.getItem('access_web') || '0'; 
window.emailVerify = true;
localStorage.setItem('versionCache', manifestJSON._version);
window.localtoken_r = localStorage.getItem('access_token_r'); // token reseller
window.resellerId = localStorage.getItem('resellerId'); // reseller_user_id
window.oauth_r = localStorage.getItem('oauth_r'); // oauth_r
if(localStorage.getItem('getDataSession') && localStorage.getItem('mark_client_data')==JSON.parse(localStorage.getItem('getDataSession')).principal.id)
{
	let r = JSON.parse(localStorage.getItem('getDataSession'));
	window.OrgPlan = r.plan;
}else{
	window.OrgPlan = 0;
}
