<template>
	<div class="card">
        <form @submit.prevent="salesSetSOW">
            <div class="card-header">
				<div class="row">
					<div class="col-2 text-left">
						<button type="submit" class="btn btn-success">
							<i class="fas fa-save"></i> SAVE
						</button>
					</div>
					<div class="col-8 text-left">
                        <h3 class="page-title">
                            Scope of Work <small>( SOW <i class="fas fa-file-alt"></i> )</small>
                        </h3>
					</div>
				</div>
            </div>
            <div class="card-body scopeOfWork">
				<div class="form-group">
					<vue-editor v-model="f.sow" :editor-toolbar="customToolbar"></vue-editor>
				</div>
            </div>
        </form>
	</div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import './scopeOfWork.scss'
export default {
	name: 'scopeOfWork',
	components: {
		VueEditor
	},
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				sow: '',
			},
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],
		}
	},
	created(){
		this.init();
	},
	methods:{
		init(){
			let t = this;
            t.$store.commit("core/setLoadingModal", false);
			let d = t.$store.getters['core/getOpenModal'].data;
			t.f.sales_id= d.id;
			t.f.sow= d.sow;
            console.log('salesGetSOW', t.f)
			// t.salesGetSOW();
		},
		salesGetSOW(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('salesGetSOW', t.f)
            .then((response) => {
                console.log('salesGetSOW', response.data)
                if(response.data.r){
                    t.f.sow=response.data.r.sow;
                }
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		salesSetSOW(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
            console.log('salesSetSOW', t.f)
			window.master.post('salesSetSOW', t.f)
            .then((response) => {
                console.log('response', response.data);
                t.$store.commit("core/setLoadingModal", false);
                t.$notify({
                    group: "noti",
                    type: "success",
                    title: "Successfully saved",
                    text: "Scope of Work",
                });
                t.$parent.close();
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		}
	}
}
</script>
<style lang="css" scoped>
</style>
