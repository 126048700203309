export default {
	urlApis(state){ return state.urlApis; },
	langMarketea(state){ return state.langMarketea; },
	accessApp(state){ return state.access; },
	planAppId(state){ return state.plan_id; },
	get_builtForm(state){ return state.builtForm;},
	get_formBuilt(state){ return state.formBuilt;},
	get_premiumProfile(state){ return state.premiumProfile;},
	get_itemSalesAppointmente(state){ return state.itemSalesAppointmente;},
	get_editOrgUrl(state){ return state.editOrgUrl;},
	get_customCssStorage(state){ return state.customCssStorage;},
	get_desingStorage(state){ return state.desingStorage;},
	get_editorStorage(state){ return state.editorStorage;},
	get_asideStorage(state){ return state.asideStorage;},
    get_settupStorage(state){ return state.settupStorage; },
	get_bSiteValStorage: (state) => (stateKey) => {return state[stateKey];},
	get_bsite_listcard(state){return state.bsite_listcard},
	get_bsite_cardItem(state){return state.bsite_cardItem},
	get_bsite_cardID: (state) => (id) => {return state.bsite_listcard.find(list => list.id === id)},
	get_bsite_cardUrlPic(state){return state.bsite_cardUrlPic},
	get_reloadPreview(state){return state.reloadPreview},
    getFielsd(state){
		let t = state.bsite_cardItem;
		let noInclude=["created_at", "updated_at", "pictures", "contact", "social", "user_id", "org_id", "find_me_at", "btns_action", "temp_opts"];
		let formData = new FormData();
		if(t!=null){
			formData.append("user_id", window.master_client);
			formData.append("org_id", window.localorgdata);
			formData.append("id", t.id);
			Object.keys(t).forEach((key, idx) => {
				if(noInclude.indexOf(key)==-1){
					formData.append('about['+key+']', t[key]);
				}
				if(key=='slug_url'){
					formData.append(key, t[key]);
				}
			});
			// t.contact.forEach((elem, idx) => {
			// 	Object.keys(elem).forEach((key, idx2) =>{
			// 		let val = (typeof elem[key] == 'boolean') ? Number(elem[key]) : elem[key];
			// 		if(noInclude.indexOf(key)==-1){
			// 			formData.append('contact['+idx+']['+key+']', val);
			// 		}
			// 	})
			// });
			// t.social.forEach((elem, idx) => {
			// 	Object.keys(elem).forEach((key, idx2) =>{
			// 		let val = (typeof elem[key] == 'boolean') ? Number(elem[key]) : elem[key];
			// 		if(noInclude.indexOf(key)==-1){
			// 			formData.append('social['+idx+']['+key+']', val);
			// 		}
			// 	})
			// });
			t.find_me_at.forEach((elem, idx) => {
				Object.keys(elem).forEach((key, idx2) =>{
					let val = (typeof elem[key] == 'boolean') ? Number(elem[key]) : elem[key];
					if(noInclude.indexOf(key)==-1){
						formData.append('find_me_at['+idx+']['+key+']', val);
					}
				})
			});
			t.btns_action.forEach((elem, idx) => {
				Object.keys(elem).forEach((key, idx2) =>{
					let val = (typeof elem[key] == 'boolean') ? Number(elem[key]) : elem[key];
					if(noInclude.indexOf(key)==-1){
						formData.append('btns_action['+idx+']['+key+']', val);
					}
				})
			});
			if(t.pictures!=null && t.pictures.length>0){
				Object.keys(t.pictures[0]).forEach((key, idx2) =>{
					let elem = t.pictures[0];
					if(noInclude.indexOf(key)==-1){
						if(key=='profile'){
							formData.append(key, elem[key]);
						}
						else if(key=='logo'){
							formData.append(key, elem[key]);
						}
						else if(key=='cover'){
							formData.append(key, elem[key]);
						}else if(key=='cover_video'){
							formData.append(key, elem[key]);
						}else{
							let val = (typeof elem[key] == 'boolean') ? Number(elem[key]) : elem[key]
							formData.append('pictures['+key+']', val);
						}
					}
				});
			}
			formData.append("pusher_id", pusher.connection.socket_id);
		}
		return formData;
    },
	get_bsite_suf_url(state){ return state.bsite_suf_url;},
	get_ploadProgress(state){ return state.ploadProgress;},
	get_bsite_minisite(state){ return state.siteContent},
	get_pluginList(state){ return state.pluginList},
	get_planesPro(state){ return state.planesPro},
	get_isPremiumProfile(state, rootState){
		return (state.planesPro.indexOf(parseInt(localStorage.getItem('planIdApp')))>-1);
	},
	get_bmenu_listCat(state){ return state.bmenu_listCat.sort(function (a, b) {return a.orden - b.orden;});},
	get_bmenu_catActive(state){ return state.bmenu_catActive;},
	get_bmenu_currency(state){ return state.bmenu_currency;},
	get_widget_listCat(state){ return state.widget_listCat;},
	get_widget_categoryMain(state){ return state.widget_categoryMain;},
	get_widget_urlMedia(state){ return state.widget_urlMedia;},
	get_widget_path_base(state){ return state.widget_path_base;},
}
