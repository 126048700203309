<template>
    <div class="card-body">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                User List:
                <ul class="list-group">
                    <template v-for="(i, index) in options">
                        <li :class="['list-group-item d-flex align-items-center', (i.assign) ? 'active' : '']" :key="index" >
                            <span class="badge badge-secondary"
                                :style="'margin-right: 20px;background-color:'+i.classColor+';'"
                                @click="showColor(index)"
                                @touchstart="showColor(index)"
                            >{{i.pref_name}}</span>
                            <color-picker
                                v-if="showColorIdx==index"
                                v-model="i.classColor"
                                class="colorAvatarUser"
                                @input="changeColor($event, i)"
                                :palette="colors"
                                ></color-picker>
                            <span class="username" @touchstart="showColorIdx=null"  @click="showColorIdx=null" style="width: 70%;">
                                {{i.firstname}} {{i.lastname}}
                                <br />
                                <span class="email">{{i.email}}</span>
                            </span>
                            <span class="badge badge-info btn-action" @click="toggleAssign(i)" @touchstart="toggleAssign(i)" v-if="!i.assign">Add</span>
                            <span class="badge badge-danger btn-action" @click="toggleAssign(i)" @touchstart="toggleAssign(i)" v-else>Remove</span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { Compact, Material } from "vue-color";
export default {
    name: "modalAssignUser",
    components: {
        "color-picker": Compact,
    },
    props: {
		item: {
			type: Object, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: () => {},
		},
		userlist: {
			type: Array, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: () => [],
		},
	},
	data () {
		return {
            org_id: window.localorgdata,
			userIdAssign: [],
			options: [],
            colors: [],
            showColorIdx: null,
            validTypeUser: false,
		}
	},
	created(){
        this.colors = this.$store.getters['core/get_paletaDeColores'];
		this.init();
	},
    methods : {
        init(){
			let t = this;
			//console.log('item', t.item);
			t.userIdAssign =[];
			t.item.assing.filter(r => {
				//if(r.id != t.org_id){}
                t.userIdAssign.push(r);
			});
            t.fillOptions();
            t.$store.dispatch('core/session/validTypeUser').then((r) => {
                t.validTypeUser = r;
            });
		},
        fillOptions(){
            let t=this;
			//console.log('userlist', t.userlist);
			if(t.userlist.length>0){
				t.options=[];
				t.userlist.filter(r => {
                    let valid = t.userIdAssign.filter(e => {
					    if(r.id == e.id){ return e;}
				    });
                    r.assign= (valid.length>0);
                    t.options.push(r);
                });
			}
        },
        toggleAssign(i){
            let t=this;
            if(!i.assign){t.assignUser(i);}else{t.removeUser(i);}
        },
        assignUser(i){
            let t=this;
            t.userIdAssign.push(i);
            t.fillOptions();
            t.saveAssign(t.userIdAssign);
        },
        removeUser(i){
            let t=this;
            let newList =t.userIdAssign.filter(r => {
                if(r.id != i.id){ return r;}
            });
            t.userIdAssign = newList;
            t.fillOptions();
            t.saveAssign(t.userIdAssign);
        },
		saveAssign(values){
			console.log('saveAssign', values)
			let t = this;
			let campo = 'assing_users'
			let valor = [];
			values.filter(r => {
				valor.push(r.id)
			})
			let valorString=valor.toString();
			console.log('change', campo, valorString)
			window.billing
			.post("updateTask", {
				id: t.item.id,
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
				campo: campo,
				valor: valorString,
			})
			.then((response) => {
				console.log('updateTask');
			})
			.catch((error) => {
				console.log(error);
			});
		},
        showColor(index){
            let t= this;
            if(t.validTypeUser){
                t.showColorIdx=index;
            }
        },
        changeColor(e, i){
            let t=this;
            t.showColorIdx=null;
            let color = e.hex;
            let url = "/users/setColor";
            console.log('color', e)
            if(t.validTypeUser){
                window.master.post(url, {
                    color: color,
                    account_id: i.account_id,
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                })
                .then((response) => {
                    let r = response.data;
                    if(!response.status){
                        t.$notify({
                            group: "noti",
                            type: "error",
                            title: "Error",
                            text: 'Failed to save',
                        });
                    }else{
                        t.showColorIdx=null;
                        i.classColor = color;
                        t.pushRefresh('taskUpdate-refresh');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: error,
                    });
                });
            }
        },
        pushRefresh(push_action){
            let t=this;
            let payload = {
                id: t.item.id,
                push_action: push_action,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
            };
            t.$store.dispatch('ModApps/billing/pushActionTask', payload);
        },
    }
}
</script>
<style lang="scss" scoped>
.card-body {
 height: calc(100vh - 150px);
 overflow-x:hidden;
 overflow-y:auto;
}
.list-group-item{
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    color: #555;
    border: 1px solid rgba(0, 0, 0, 0.125);
    span.username{
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
        span.email{font-size: 14px;font-weight: normal;}
    }
    &.active {
        z-index: 2;
        color: #000;
        background-color: #ddd;
        border-color: #ddd;
    }
    &:hover {
        z-index: 1;
        color: #000;
        background-color: #ccc;
        border-color: #ccc;
    }
    .btn-action{
        position: absolute;
        right: 15px;
        width: 70px;
        &:hover{ cursor: pointer;}
    }
}
</style>
