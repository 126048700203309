<template>
    <div class="main_mini_site">
        <div class="row" v-if="isLoading"><Loader class="col-12"></Loader></div>
        <div class="row list_mini_site" v-else>
            <div class="col-12" v-if="list.length>0">
                <div class="row" >
                    <div class="col-xs-12 col-sm-6 col-lg-3 col-xxl-3" v-for="(i, idx) in list" :key="idx">
                        <div class="card text-center itemMiniSite" @click="openMiniSite(i)">
                            <div class="card-header text-white " ><h4 class="card-title">{{ i.name }}</h4></div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="img-row">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
                                        </div>
                                    </div>
                                    <div class="col-12 text-row"><p></p><p></p><p></p><p></p></div>
                                </div>
                            </div>
                            <div class="card-footer text-white " ><h4 class="card-title">Edit</h4></div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-3 col-xxl-3 text-center">
                        <div class="content_add" v-if="list.length<=3">
                            <span class="addNew" @click="openModal('formAddMiniSite')">+ Add MiniSite</span>
                        </div>
                        <div class="content_add" v-else>
                            <span class="addNew disabled" @click="openModal('formAddMiniSite')">+ Add MiniSite</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" v-else>
                <div class="content_add">
                    <span class="addNew" @click="openModal('formAddMiniSite')">+ Add MiniSite</span>
                </div>
            </div>
        </div>
        <pop-site
            v-if="itemMiniSite!=null"
            :ShowMiniSite="openPopUp"
            :itemPreview="itemMiniSite"
            @closeMiniSite="closePop($event)"
        ></pop-site>
        <modal name="modal-addMiniSite" class="modal-addMiniSite" draggable=".window-header" width="300px" height="140px" @before-close="beforeClosedModal">
            <div class="card">
                <button @click="ClosedModal" class="closeIcon"><span></span></button>
                <component :is="nameComp" @refreshList="emitList($event)"></component>
            </div>
        </modal>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import popMiniSite from "@/apps/Marketea/components/minisite/popMiniSite.vue";
// @ts-ignore
import formAddMiniSite from "@/apps/Marketea/components/minisite/formAddMiniSite.vue";
export default {
    name:"listminisite",
    components: {
        Loader,
        'pop-site': popMiniSite,
        formAddMiniSite,
    },
    data(){
        return {
            isLoading: true,
            urlImage: window.urlmaster+'images/',
            list: [],
            itemMiniSite: null,
            name: 'Alex Herrera',
            company: 'Marketea Inc',
            apis: null,
            nameComp: null,
            openPopUp: false,
            defaultMiniSite: [
                {
                    columns: [
                        {
                            elemts: [
                                {
                                    widget: 'text', // or image, link
                                    text: "<h2>Lorem ipsum dolor sit amet.</h2><p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                    url:'',
                                }
                            ]
                        },
                    ]
                },
            ],
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        localStorage.setItem('siteContent', JSON.stringify([]));
        localStorage.setItem('siteName', null);
        t.getAll();
    },
    methods:{
        getAll(noLoad){
            let t = this;
            if(noLoad==undefined){
                t.isLoading=true;
            }
            let url = t.apis.pull(7); // getAllMiniSite
            let payload = new FormData();
            payload.append("user_id", window.master_client);
            payload.append("org_id", window.localorgdata);
            payload.append("pusher_id", pusher.connection.socket_id);
            window.master.post(url, payload)
            .then((response) => {
                console.log(url, response.data);
                t.list = response.data;
                t.isLoading=false;
            }).catch((error) => {
                console.log(error);
                t.isLoading=false;
            });
        },
        emitList(val){
            console.log('emitList', val)
            this.getAll();
        },
        getID(id){
            let t = this;
            let service = t.apis.pull(8); // getItemMiniSite
            let url = window.urlmaster + 'api/v3/bSite/'+service;
            let payload = new FormData();
            payload.append("user_id", window.master_client);
            payload.append("org_id", window.localorgdata);
            payload.append("id", id);
            payload.append("pusher_id", pusher.connection.socket_id);
            t.itemMiniSite =0;
            setTimeout(() => {
                t.openPopUp = true;
            }, 200);
            window.master.post(service, payload, {
                headers: { "Content-Type": "multipart/form-data" }
            }).then((response) => {
                console.log(url, response.data);
                if(response.data.sections.length>0){
                    let s = response.data.sections;
                    let sections =[];
                    for (const i in s) {
                        if (Object.hasOwnProperty.call(s, i)) {
                            let itemSection = JSON.parse(s[i].section_json);
                            itemSection.id=s[i].id;
                            itemSection.item_orden=s[i].item_orden;
                            sections.push(itemSection);
                        }
                    }
                    console.log('sections', sections);
                    t.$store.commit('ModApps/Marketea/set_bsite_minisite', sections);
                }
                if(response.data.item!=null){
                    // t.$store.commit('ModApps/Marketea/set_bsite_minisite', JSON.parse(response.data.item.html_value));
                    t.itemMiniSite =id;
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        ClosedModal() {
            this.$modal.hide("modal-addMiniSite");
        },
        beforeClosedModal(){
            this.nameComp = null;
        },
        ShowdModal(n) {
            this.nameComp = n;
            this.$modal.show("modal-addMiniSite");
        },
        openModal(value){
            console.log('openModal', value);
            this.ShowdModal(value);
        },
        pusherActions(p){
            console.log('pusherActions', p)
            if(p.action=='refreshMiniSite'){
                this.getAll(1);
            }
        },
        openMiniSite(item){
            let t = this;
            if(item==undefined){
                t.$store.commit('ModApps/Marketea/set_bsite_minisite', t.defaultMiniSite);
                t.itemMiniSite ='0';
                setTimeout(() => {
                    t.openPopUp = true;
                }, 200);
            }
            else{
                localStorage.setItem('siteName', item.name);
                t.getID(item.id);
            }
        },
        closePop(){
            let t = this;
            if(localStorage.getItem('save_pending')!=null && localStorage.getItem('save_pending')=='1'){
                t.$swal({
                    title: "You have unsaved changes!!",
                    text: "Are you sure you want to exit?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Exit",
                }).then((result) => {
                    if (result.value) {
                        t.closePopUpPrevew();
                    }
                });
            }else{
                t.closePopUpPrevew();
            }
        },
        closePopUpPrevew(){
            let t = this;
            t.openPopUp =false;
            localStorage.setItem('siteContent', JSON.stringify([]));
            localStorage.removeItem('iframeState');
            setTimeout(() => {
                t.itemMiniSite =null;
            }, 200);
        },
    },
    watch:{
        itemMiniSite: function(){
            console.log('itemMiniSite', this.itemMiniSite)
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined && t.getPusher != null) {
                if(t.getPusher.modulo!=null && t.getPusher.modulo == "bsite"){
                    let p = t.getPusher.data;
                    if(p.org_id == window.localorgdata){
                        // leer acciones
                        t.pusherActions(p);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            getPusher: "get_accitonPusher",
        }),
    },
}
</script>
<style lang="scss" scoped>
.list_mini_site{
    display: block;
    width: 100%;
    position: relative;
    padding: 10px 10px;
    height: calc(100vh - 80px);
    overflow: hidden;
    overflow-y: auto;
    .card.itemMiniSite{
        padding: 0px;
        margin: auto;
        max-width: 280px;
        &:hover{
            cursor: pointer;
            box-shadow: 0px 7px 3px 0px rgb(0 0 0 / 15%);
        }
        .card-title{margin: 0px;}
        .card-header, .card-footer{
            background: #031099;
            margin: 0px;
            border: 1px solid #031099;
        }
        .card-header{
            padding: 5px 10px;
        }
        .card-body{
            margin: 0px;
            padding: 10px 20px;
            border:1px solid #ddd;
        }
        .card-footer{padding: 10px 20px;}

    }
    .img-row{
        width: 100%;
        height: 80px;
        border: 0px solid #ddd;
        margin: 1%;
        background-color: #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 2px;
        svg{
            height: 5em;
            fill:#ddd;
        }
    }
    .img-col{
        width: 100%;
        max-width: 150px;
        height: 100px;
        border: 0px solid #ddd;
        margin: auto;
        background-color: #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 2px;
        svg{
            height: 5em;
            fill:#ddd;
        }
    }
    .text-row{
        p{
            width: 100%;
            height: 10px;
            background-color: #ccc;
            border: 0px solid #ddd;
            margin: 10px 3px;
        }
    }
    .content_add{
        border: 1px dashed #02ac1e;
        margin: 20px auto 20px auto;
        display: block;
        width: 100%;
        max-width: 350px;
        padding: 25px 15px;
        border-radius: 4px;
    }
    .addNew{
        color: #ddd;
        border: 1px solid #02ac1e;
        background: #02ac1e;
        padding: 8px 15px;
        font-size: 16px;
        border-radius: 20px;
        z-index: 4;
        margin: 10px auto 20px auto;
        display: block;
        width: 100%;
        max-width: 150px;
        &:hover{
            cursor: pointer;
            border-color: #fcd602;
            color: #000;
            background-color: rgba(0 0 0 / 20%);
        }
    }
}
</style>
