<template>
	<div class="row">
		<addSection></addSection>
	</div>
</template>
<script>
import addSection from "./addSection";
export default {
	name: 'visualizador',
	components: {
		addSection
	},
	data () {
		return {
		}
	}
}
</script>
<style lang="css" scoped>
</style>
