<template>
    <div class="container-fluid" style="padding-top: 10px">
		<div class="row">
			<div class="col-5 align-self-center">
				<h3 class="page-title">Setup / Edit Alert</h3>
			</div>
			<div class="col-7 align-self-center">
				<div class="d-flex align-items-right justify-content-end">
					<router-link
						:to="{ name: 'MSetup' }"
						class="btn btn-info btn-sm mr-2 router-link-active"
						style="border: 1px solid black;"
						> <i class="fas fa-arrow-left"></i>  Back
					</router-link>
				</div>
			</div>
		</div>	    
        <div class="row">
            <div class="col-md-5">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-5 align-self-center">
                                <h4 class="page-title">
                                    <i class="fas fa-user-edit"></i> Edit Alert
                                </h4>                                
                            </div>
                            <div class="col-7 align-self-center">
                            </div>
                        </div>
                    </div>
                    <div class="card-body cuerpo-form">
                        <div
                            v-if="formLoading"
                            class="d-flex align-items-center justify-content-center bgloading"
                        >
                            <Loader></Loader>
                        </div>
                        <div class="form-group">
                            <label>Form name</label>
                            <ModelSelect 
                                v-model="f.form_id"
                                :options="listForms" 
                                placeholder="Filter by name"
                                class="form-control"
                                @input="getAlert"
                            ></ModelSelect>
                        </div>
                        <form v-if="f.form_id>0" @submit.prevent="setForm">
                            <div class="form-group">
                                <label>Alert Success</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="f.aSuccess"
                                    required
                                />
                            </div>
                            <div class="form-group">
                                <label>Alert Error</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="f.aError"
                                    required
                                />
                            </div>
                            <div class="form-group">
                                <label>Redirect on submit</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Url /</span>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="page-uri"
                                        v-model="f.urlResponse"
                                        @keyup="UrlResponse"
                                    />
                                    <!-- <div class="input-group-prepend">
										<span class="input-group-text">
											<input type="radio" name="open_top" v-model="f.open_top" value="0" :disabled="checkr"> 
											Internal
										</span>
									</div>
									<div class="input-group-prepend">
										<span class="input-group-text">
											<input type="radio" name="open_top" v-model="f.open_top" value="1" :disabled="checkr"> 
											External
										</span>
									</div> -->
                                </div>
                            </div>
                            <div class="form-group">
                                <label id="d" style="margin-right: 10px"
                                    >Enable Redirection</label
                                >
                                <input
                                    type="checkbox"
                                    for="d"
                                    v-model="f.activeResponse"
                                    :disabled="check"
                                />
                            </div>
                            <div class="form-group">
                                <button
                                    type="submit"
                                    class="btn btn-success"
                                    :disabled="btnsave"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { ModelSelect } from 'vue-search-select'
export default {
    name: "AlertForm",
    components: {
        Loader,
        ModelSelect,
    },
    data() {
        return {
            isLoading: false,
            formLoading: false,
            btnsave: false,
            user_id: window.master_client,
            org_id: window.localorgdata,
            check: true,
            checkr: true,
            f: {
                aSuccess: "",
                aError: "",
                urlResponse: "",
                activeResponse: "",
                user_id: window.master_client,
                org_id: window.localorgdata,
                form_id: 0,
                open_top: "",
            },
            listForms: [],
        };
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let apisForm = this.$store.getters['ModApps/Marketea/urlApis'];
            this.apis = apisForm.Form;
            this.getListForms();
        },        
        getListForms() {
            let t = this;
            t.formLoading = true;
            window.master.post(this.apis.get(), {
                org_id: window.localorgdata
            })
            .then((response) => {
                let e = response.data;
                if (e.status) {
                    for (let j in e.r) {
                        if(e.r[j]) {
                            t.listForms.push({value: e.r[j].id, text: e.r[j].name});
                        }
                    }
                }
                t.formLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.formLoading = false;
            });
        },
        UrlResponse() {
            if (this.f.urlResponse.length > 0) {
                this.check = false;
                this.checkr = false;
                this.f.activeResponse = 0;
            } else {
                this.check = true;
                this.checkr = true;
                this.f.activeResponse = 0;
            }
        },
        setForm() {
            this.btnsave = true;
            this.formLoading = true;
            let url = this.apis.setAlert();
            if (this.f.activeResponse == true) {
                this.f.activeResponse = 1;
            } else {
                this.f.activeResponse = 0;
            }
            window.master
                .post(url, this.f)
                .then((response) => {
                    console.log(response.data);
                    this.btnsave = false;
                    this.formLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.btnsave = false;
                    this.formLoading = false;
                });
        },
        getAlert() {
            this.formLoading = false;
            let url = this.apis.getAlert();
            window.master
                .post(url, {
                    org_id: this.org_id,
                    user_id: this.user_id,
                    form_id: this.f.form_id
                })
                .then((response) => {
                    let e = response.data.result;
                    this.f.aSuccess = e.success_text;
                    this.f.aError = e.error_text;
                    if (e.uri_response != null) {
                        if (e.uri_response.length > 0) {
                            this.check = false;
                        } else {
                            this.check = true;
                        }
                    } else {
                        this.check = true;
                    }
                    this.f.urlResponse = e.uri_response;
                    this.f.activeResponse = e.active_uri;
                    if (e.org_id > 0 && e.user_id > 0) {
                        this.f.org_id = e.org_id;
                        this.f.user_id = e.user_id;
                        this.f.open_top = 1;
                        this.checkr = false;
                    } else {
                        this.f.org_id = this.org_id;
                        this.f.user_id = this.user_id;
                        this.f.open_top = 1;
                        this.checkr = true;
                    }
                    this.btnsave = false;
                    this.formLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.btnsave = false;
                    this.formLoading = false;
                });
        },
    },
};
</script>
