<template>
	<div class="row content-filter">
		<div 
			:class="[$parent.searchValid ? 'col-sm-10' : 'col-sm-8', '']" 
			style="padding: 0px;">
			<div style="display: flex;"
		>
				<div class="btn-group btn-group-lg" >
					<button type="button" class="btn btn-group-sm btn-success" @click="$parent.addAds" :disabled="$parent.savedAdd" v-if="!$parent.searchValid">
						<i class="fa fa-plus"></i>
					</button>
					<button type="button" class="btn btn-group-sm btn-info" @click="$parent.getAds" v-if="!$parent.searchValid">
						<i class="fas fa-sync-alt"></i>
					</button>
					<button type="button" class="btn btn-group-sm btn-danger" @click="$parent.deleteRow" :disabled="$parent.validSelected">
						<i class="fas fa-times"></i>
					</button>
					<button type="button" v-if="$parent.searchValid" class="btn btn-group-sm btn-info" @click="$parent.activeSearch">
						<i class="fas fa-reply-all"></i>
					</button>
					<button type="button" v-else class="btn btn-group-sm btn-info" @click="$parent.activeSearch">
						<i class="fas fa-search"></i>
					</button>
				</div>
				<span class="label-date" v-if="$parent.searchValid">
					<b>From:</b>
				</span>
				<span class="inputDateFilter" v-if="$parent.searchValid">
					<datetime 
					type="date" 
					v-model="$parent.dateFrom" 
					format="MMM dd, yyyy" 
					input-class="form-control" 
					placeholder="From:"
					value-zone="America/New_York"></datetime>
				</span>
				<span class="label-date" v-if="$parent.searchValid">
					<b>To:</b>
				</span>
				<span class="inputDateFilter" v-if="$parent.searchValid">
					<datetime type="date" v-model="$parent.dateTo" format="MMM dd, yyyy" input-class="form-control"
					value-zone="America/New_York"></datetime>
				</span>
				<multiselect v-if="$parent.searchValid"
					v-model="$parent.filterMarkSource"
					placeholder="Mark. Source"
					label="text"
					track-by="value"
					:options="$parent.optionsMarkSource"
					:multiple="true"
					:taggable="true"
					class="form-control"
				></multiselect>
				<multiselect v-if="$parent.$parent.searchValid"
					v-model="$parent.filterCTAChannel"
					placeholder="CTA Channel"
					label="text"
					track-by="value"
					:options="$parent.optionsCTAChannel"
					:multiple="true"
					:taggable="true"
					class="form-control"
				></multiselect>
				<ModelSelect v-if="$parent.searchValid"
					v-model="$parent.filterCampign"
					:options="$parent.optionsCampign" 
					placeholder="Campign"
					class="form-control"
					@input="$parent.constructorGroupName($parent.filterCampign, 'filter')"
				></ModelSelect>
				<ModelSelect v-if="$parent.searchValid" 
					v-model="$parent.filterGroupName"
					:options="$parent.filtersOtpsGroupName" 
					placeholder="Group"
					class="form-control"
				></ModelSelect>
			</div>
		</div>
		<div 
			:class="[$parent.searchValid ? 'col-sm-2' : 'col-sm-4', 'viewLogRecords']" 
			style="padding-right: 0px;"
		>
				<ul class="pagination justify-content-end">
					<li class="page-car mr-2">
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">Pag: </div>
							</div>
							<select 
								v-model="$parent.page" 
								class="form-control selectRowList" 
								v-on:change="$parent.changePage($parent.page)"
							>
								<option v-for="i in $parent.numPag" :key="i" :value="i">{{i}}</option>
							</select>
						</div>								
					</li>
					<li>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">Show: </div>
							</div>
							<select v-model="$parent.rowNum" class="form-control selectRowList">
								<option v-for="i in $parent.rowList" :key="i" :value="i">{{i}}</option>
							</select>
						</div>
					</li>
					<li v-if="!$parent.searchValid">
						<span class="selected">Selected: {{ $parent.selected.length}}</span> 
						<span class="page-view">View {{ $parent.pagination.to}} of {{ $parent.pagination.total}}</span>
					</li>
				</ul>
		</div>
	</div> 
</template>
<script>
import Multiselect from 'vue-multiselect'
import { ModelSelect } from 'vue-search-select'
export default {
	name: 'tdynamicFilters',
	components: {
		ModelSelect,
		Multiselect,
	},
	data () {
		return {
		}
	},
	created() {
		//console.log('tdynamicFilters', this.$parent)
	}
}
</script>
<style lang="css" scoped>
</style>
