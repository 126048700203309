<template>
    <div>
        <div class="card-header">
            Add Tags
        </div>
        <div class="card-body">
            <form @submit.prevent="form">
                <div class="form-general">
                    <div
                        class="form-group"
                        style="
                            padding: 0px;
                            padding-right: 20px;
                            margin-top: 10px;
                            margin-left: 20px;
                        "
                    >
                        <label class="c-input c-radio"> Tags Name </label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="f.name"
                            v-focus
                        />
                        <input type="hidden" v-model="f.id" />
                        <br /><br />
                        <button
                            type="submit"
                            class="btn btn-primary"
                            :disabled="btnSave"
                        >
                            Save
                        </button>
                        <a
                            class="btn search"
                            @click="cancel()"
                            style="margin-left: 20px;border: 1px solid;"
                        >
                            Cancel
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name: "formTagsModal",
    data() {
        return {
            btnSave: false,  
            f: {
                id: "",
                name: "",
                user_id: window.master_client,
                org_id: window.localorgdata,
            },            
        };
    },
    created() {
        this.init();
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },    
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.setup;       
            let g = this.$store.getters['core/getOpenModal']
            if (g.data.id !== undefined) {
                this.f.id = g.data.id;
                this.f.name = g.data.name;   
            }         
        },        
        form() {
            if (this.f.name == "") {
                this.$swal({
                    title: "Tags Empty",
                    text: "Tags Empty",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                });
            } else {
                this.btnSave = true;
                let url = this.apis.addTags()
                if (this.f.id > 0) {
                    url = this.apis.editTags()
                }
                window.master
                    .post(url, this.f)
                    .then((response) => {
                        console.log(response.data);
                        this.btnSave = false;
                        this.$store.dispatch("ModApps/jobs/update_menuTags");
                        this.$store.commit("core/setCloseModal", {
                            action: 'saved',
                            metodo:'returnTags'
                        });
                        this.$parent.close();
                    })
                    .catch((error) => {
                        this.btnSave = false;
                        console.log(error);
                    });
            }
        },  
        cancel(){
            this.$store.commit("core/setCloseModal", {});
            this.$parent.close();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
