let grip_taks_divisor = function(n) {
    let columns = 1;
    if (n >= 1500) {
        columns = 5;
    } else if (n >= 1100) {
        columns = 4;
    } else if (n >= 850) {
        columns = 3;
    } else if (n >= 601) {
        columns = 2;
    } else {
        columns = 1;
    }
    return columns;
};
export default grip_taks_divisor;
