<template>
    <div class="container-fluid mainList">
        <menuHeader @newAdd="openModal($event)" @setFilter="getFilter($event)" @open="openModal($event)"></menuHeader>
        <div class="body_list">
            <div class="bgLoaging" v-show="isLoading">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <article class="list_items row">
                <section class="item_row col-xs-12 col-md-8 offset-md-2 col-lg-6  offset-lg-0" v-for="(i, idx) in dataList" :key="idx">
                    <div class="card">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="card-title">
                                    <span>{{ i.name }}</span>
                                </h4>
                            </div>
                            <div class="col-7">
                                <div class="card-header">
                                    <div class="card-img-overlay text-center">
                                        <p class="card-text">
                                            <a href="#" @click.prevent="ShowdModal('detailsProject', i)"><i class="fas fa-arrow-right"></i></a>
                                        </p>
                                    </div>
                                    <div class="card-img">
                                        <img :src="pathRootUrl+i.coverImage+'/600/100'" alt="Card image cap" v-if="i.coverImage!=null && i.coverImage!='null' && i.coverImage!=''">
                                        <img src="@/core/assets/img/no-image.png" alt="Card image cap" v-else>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="card-body">
                                    <h5 class="card-title text-success">Price – ${{ i.price }}</h5>
                                    <p class="card-text">
                                        <strong>Location:</strong> {{ i.location }}<br>
                                        <strong>Space:</strong> {{ i.space }}<br>
                                        <strong>Bedrooms:</strong> {{ i.bedrooms }}<br>
                                        <strong>Baths:</strong> {{ i.baths }}<br>
                                        <strong>Lot Size:</strong> {{ i.lot_size }}
                                    </p>
                                    <p class="card-text">
                                        <a href="#" @click.prevent="ShowdModal('detailsProject', i)" class="btn btn-link">
                                            Details <i class="fas fa-arrow-right"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <ul>
                                <li @click="ShowdModal('editProject', i)"><i class="fas fa-edit"></i></li>
                                <li @click="ShowdModal('featuresProject', i)"><i class="fas fa-file-alt"></i></li>
                                <li @click="ShowdModal('videoProject', i)"><i class="fas fa-video"></i></li>
                                <li><i class="fas fa-images"></i></li>
                                <li @click="ShowdModal('mapProject', i)"><i class="fas fa-map-marked-alt"></i></li>
                                <li @click="ShowdModal('detailsProject', i)"><i class="fas fa-eye"></i></li>
                                <li @click="deletePtj(i.id, idx)"><i class="fas fa-trash"></i></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="item_row col-xs-12 col-md-8 offset-md-2" v-if="!isLoading && dataList.length==0">
                    <div class="card text-center">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="card-title">
                                    No record
                                </h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text">
                                <a href="#" @click.prevent="openModal('newProject')" class="btn btn-link">
                                    Add New Project <i class="fas fa-arrow-right"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                </section>
            </article>
        </div>
        <button
			@click="openModal('newProject')"
			class="btn btn-info"
			id="addProjectFixed"
            title="New project"
		>
			<i class="fa fa-plus"></i>
		</button>
        <modal name="modal_projects" class="modal_projects" draggable=".window-header" height="auto" @before-close="beforeClosedModal">
            <div class="card">
                <button @click="ClosedModal" class="closeIcon"><span></span></button>
                <component :is="nameComp" @actionReturn="JobsModal($event)" :dataModal="dataComp"></component>
            </div>
        </modal>
    </div>
</template>
<script>
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
import menuHeader from "./menuHeader.vue";
import newProject from "./newProject.vue";
import editProject from "./editProject.vue";
import featuresProject from "./featuresProject.vue";
import videoProject from "./videoProject.vue";
import mapProject from "./mapProject.vue";
import detailsProject from "./detailsProject.vue";
import widgetPage from "./widgetPage.vue";
import widgetSlider from "./widgetSlider.vue";
import "./scss_indexProjects.scss";
export default {
    components: {
        Loader,
        menuHeader,
        newProject,
        editProject,
        featuresProject,
        videoProject,
        mapProject,
        detailsProject,
        widgetPage,
        widgetSlider,
    },
    data(){
        return {
            apiProgess: 20,
            isLoading: false,
            nameComp: null,
            dataComp: null,
            apis: null,
            dataList: [],
            urlImg: null,
            pathRootUrl: window.urlmaster+'api/v2/projects/previewImgProject/'+window.master_client+'/'+ window.localorgdata+'/',
        };
    },
    created(){
        let t = this;
        let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
        t.apis = urlApis.Projects;
        t.getList()
    },
    methods : {
        ClosedModal() {
            this.$modal.hide("modal_projects");
        },
        beforeClosedModal(){
            this.nameComp = null;
            this.dataComp = null;
        },
        ShowdModal(n, data) {
            this.nameComp = n;
            this.dataComp = (data!=undefined) ? data : null;
            this.$modal.show("modal_projects");
        },
        openModal(value){
            console.log('openModal', value);
            this.ShowdModal(value);
        },
        JobsModal(payload){
            let t= this;
            console.log('JobsModal', payload)
            if(payload.action == 'onlyCloseModal'){
                t.ClosedModal();
            }
            else if(payload.action == 'refreshList'){
                t.getList(false);
                t.ClosedModal();
            }
            else if(payload.action == 'onlyRefreshList'){
                t.getList(false);
            }
        },
        getFilter(payload){
            let t= this;
            console.log('getFilter', payload)
            t.getList(true, payload);
        },
        getList(load, status){
            let t = this;
            let payLoad = new FormData();
            // @ts-ignore
            payLoad.append("user_id", window.master_client);
            // @ts-ignore
            payLoad.append("org_id", window.localorgdata);
            // @ts-ignore
            // eslint-disable-next-line no-undef
            payLoad.append("pusher_id", pusher.connection.socket_id);
            let urlService = t.apis.get(0); //getAll
            if(status!=undefined && status!=0){
                urlService = t.apis.get(1); // getByStatus
            }
            if(load==undefined || load==true){t.isLoading = true;}
            // @ts-ignore
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                pusher_id: pusher.connection.socket_id,
                status: status,
                rows: 20,
            }, {
                headers: {
                    common: {
                    // @ts-ignore
                    Authorization: 'Bearer ' + window.localtoken_M
                    },
                    post: { 'Content-Type': 'multipart/form-data' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                // @ts-ignore
                // console.log(urlService, response.data);
                if(response.data.list.total>0){
                    t.dataList = response.data.list.data;
                    t.urlImg = response.data.urlImg;
                }else{
                    t.dataList =[];
                }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        deletePtj(id, index){
            let t =this;
            t.$swal({
                title: "Delete Item?",
                text: "You are sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let urlService = t.apis.set(5); // deleteProject
                    window.master.post(urlService, {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        id: id,
                        pusher_id: pusher.connection.socket_id,
                    }, {
                        headers: {
                            common: {
                            // @ts-ignore
                            Authorization: 'Bearer ' + window.localtoken_M
                            },
                            post: { 'Content-Type': 'multipart/form-data' }
                        },
                        onUploadProgress: (progressEvent) => {
                            // console.log('onUploadProgress', progressEvent)
                            let scanPer = Math.floor(
                                (progressEvent.loaded*100 / progressEvent.total)
                            );
                            // console.log('scanPer', scanPer);
                            if(scanPer < 99){
                                t.apiProgess = scanPer;
                            }
                        }
                    })
                    .then((response) => {
                        t.apiProgess =100;
                        // @ts-ignore
                        console.log(urlService, response.data);
                        if(response.data.status){
                            t.dataList.splice(index, 1);
                        }
                        t.isLoading = false;
                    }).catch((error) => {
                        console.log(error);
                        t.apiProgess =0;
                        t.isLoading = false;
                    });
                }
            });
        },
    },
    watch: {
        "$router.currentRoute.name": function(){
            this.getList();
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrapper > .container-fluid.mainList{
    margin-top: 0px;
    padding: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    .body_list{
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
        height: calc(100vh - 80px);
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        background: #fff;
        .bgLoaging {
            position: fixed;
            top: 80px;
            left: 0;
            right: 0;
            width: 100%;
            margin: auto;
            height: calc(100vh - 80px);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9;
            background-color: rgba(255, 255, 255, 0.342);
        }
        aside.menu_aside {
            position: relative;
            background-color: #fff;
            max-width: 250px;
            width: 25%;
            padding: 10px;
            height: calc(100vh - 100px);
            overflow: hidden;
            overflow-y: auto;
            ul.list_group{
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-bottom: 0;
                li.list_group_item {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    padding: 0.75rem 1.25rem;
                    margin-bottom: -1px;
                    background-color: #fff;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    &.active{
                        color: #fff;
                        background-color: #7460ee;
                    }
                    &:hover{
                        background-color: #ddd;
                        cursor: pointer;
                        &.active{
                            color: #fff;
                            background-color: #5f4cd8;
                        }
                    }
                    span.name_item{
                        width: 90%;
                    }
                    span.badge{width: 10%;}
                }
                li.item_category {
                    border:0px;
                    text-align: left;
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    h5{ font-weight: bold;}
                }
            }
        }
        article.list_items {
            position: relative;
            width: 100%;
            background-color: #fff;
            padding: 10px;
            padding-top: 0px;
            height: calc(100vh - 100px);
            overflow: hidden;
            overflow-y: auto;
            align-content: flex-start;
            section.item_row{
                position: relative;
                background-color: #fff;
                width: 100%;
                .card{
                    margin-bottom: 50px;
                    .card-header {
                        position: relative;
                        padding: 0px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-left: 0px;
                        background-color: transparent;
                        overflow: hidden;
                        max-height: 300px;
                        border: 0px solid #000;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        // box-shadow: 4px 0px 3px rgb(0 0 0 / 11%);
                        .card-img-overlay a {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0;
                            font-size: 24px;
                            z-index: 2;
                            &:hover {
                                cursor: pointer;
                                opacity:1;
                                background-color: rgba(0 0 0 / 25%);
                                color:#fff;
                            }
                        }
                        .card-img{
                            width: 100%;
                            height: 100%;
                            margin: 0%;
                            position: relative;;
                            z-index: 1;
                            max-height: 250px;
                            overflow: hidden;
                            box-shadow: -8px -2px 7px 0px rgba(0, 0, 0, 0.3);
                            img{
                                width: 100%;
                                height: auto;
                                border: 1px solid #dde3e7;
                                border-right: 0px;
                            }
                        }

                    }
                    h4.card-title{
                        color: #d93025;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                        border-bottom: 5px double #dde3e7;
                        text-transform: capitalize;
                        font-weight: bold;
                        font-size: 30px;
                        span{
                            display: block;
                            width: 100%;
                            max-width: 500px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    .card-body {
                        padding: 0px;
                        padding-top: 10px;
                        h5.card-title{
                            margin-bottom: 0px;
                            color: #000;
                        }
                        p.card-text {
                            margin-top: 10px;
                            margin-bottom: 0px;
                            color: #000;
                        }
                        a.btn.btn-link {
                            border: 1px solid #d93025;
                            border-radius: 20px;
                            background: #d93025;
                            color: #fff;
                            font-weight: bold;
                            font-size: 16px;
                            text-decoration: none;
                            width: 100%;
                            max-width: 200px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            display: inline-block;
                            vertical-align: middle;
                            -webkit-transform: perspective(1px) translateZ(0);
                            transform: perspective(1px) translateZ(0);
                            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                            position: relative;
                            -webkit-transition-property: color;
                            transition-property: color;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            &:before {
                                content: "";
                                position: absolute;
                                z-index: -1;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: #ac1d13;
                                border-radius: 20px;
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                                -webkit-transform-origin: 0 50%;
                                transform-origin: 0 50%;
                                -webkit-transition-property: transform;
                                transition-property: transform;
                                -webkit-transition-duration: 0.3s;
                                transition-duration: 0.3s;
                                -webkit-transition-timing-function: ease-out;
                                transition-timing-function: ease-out;
                            }
                            &:hover:before{
                                transform: scaleX(1);
                                webkit-transform: scaleX(1);
                            }
                        }
                    }
                    .card-footer {
                        position: relative;
                        display: flex;
                        width: 100%;
                        padding: 0px;
                        background: #ddd;
                        margin-top: 10px;
                        ul {
                            position: relative;
                            margin: 0px;
                            padding: 0px;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                            width: 100%;
                            list-style: none;
                            li {
                                position: relative;
                                margin: 0px;
                                padding: 5px 10px;
                                display: flex;
                                border-right: 1px solid #eee;
                                &:first-child {
                                    border-left: 1px solid #eee;
                                }
                                &:hover {
                                    cursor: pointer;
                                    background: #ccc;
                                    &:focus {
                                        background: #aaa;
                                    }
                                }
                                &:focus {
                                    background: #aaa;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #addProjectFixed {
            position: fixed;
            bottom: 20px;
            right: 15px;
            border-radius: 20px;
            padding: 10px 15px;
            background-color: #d93025;
            border-color: #979797;
            z-index: 1;
        }
}
@media (max-width: 575px){
    .page-wrapper > .container-fluid.mainList {
        .body_list{
            article.list_items {
                section.item_row {
                    .card {
                        .card-header{
                            max-height: 290px;
                            height: 290px;
                            img.card-img {
                                width: auto;
                                height: 100%;
                            }
                        }
                        .card-footer {
                            ul {
                                li {
                                    padding: 5px 10px;
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
