let openMenuA = function(e, i){
	let list = document.querySelectorAll('.menuActions ul.dropdown-menu');
	let item = document.querySelector('.menuActions .btn-'+i);
	let table = document.querySelector('#table-grid');
	let ul = item.nextSibling;
	if(ul.classList.contains('open')) {
		ul.classList.remove('open');	
	}
	else {
		ul.classList.add('open');
		// console.log('open', true);
		//calcular si mostrar hacia riba o hacia bajo el sub menu
		let positionY = (table.offsetHeight - item.getBoundingClientRect().top) - ul.offsetHeight;
		if(positionY < ul.offsetHeight && !ul.classList.contains('isTop')) {
			ul.classList.add('isTop');
			// console.log('isTop', true);
		}
	}
	if(list.length > 1) {
		this.isCloseMenuAction = i;
		this.closeAll(this.isCloseMenuAction);
	}
};
export default openMenuA;
