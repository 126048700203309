let checkAll = function() {
	this.isCheckAll = !this.isCheckAll;
	this.statuslist = [];
	if (this.isCheckAll) {
		for (var key in this.statusdata) {
			this.statuslist.push(this.statusdata[key].value);
		}
		this.searchFilter();
	}
};
export default checkAll;
