<template>
	<div>
		<dropdown-menu
			v-model="show"
			:right="right"
			:hover="hover"
		>
			<button class="btnDrop">
				<i 
					class="fas fa-user-circle iusericon" 
					v-if="user_type==0" 
					:data-avatar="dataUser.avatar"
				></i>
				<div v-else class="imgUserAvatar" :data-avatar="dataUser.avatar">
					<img 
						v-if="dataUser.avatar!=null && dataUser.avatar!='' "
						v-bind:src="dataUser.url+dataUser.avatar" 
						id="UserAvatar"
					/>
					<span v-else class="UserAvatarDefault">
						{{ avatarDefault }}
					</span>
				</div>
			</button>
			<div slot="dropdown" class="menu-account">
				<li 
					class="dropdown-item accactive" 
					v-if="orgdata && user_type==0" 
					style="width:250px;padding: .25rem 1.5rem;"
				>
					{{orgdata.com_company_name}}
					<router-link :to="{name:'Organizations'}" class="subMenuOrg">My Organizations</router-link>
					<a @click="AddOrganization" class="subMenuOrg">Add New</a>
				</li>
				<li 
					class="dropdown-item " 
					v-if="user_type==2" 
					style="width:250px; padding: 0.50rem 1.5rem 0.60rem 1.5rem;" 
					@click="UsersEditPerfil(dataUser.account_id)"
				>
					{{dataUser.name}}
				</li>
				<li 
					class="dropdown-item"  
					style="width:250px;"  
					v-if="user_type==0"
				>
					<router-link :to="{name:'Profile'}" >My Account</router-link>
				</li>
				<!--<li 
					class="dropdown-item"  
					style="width:250px;"  
					v-if="user_type==0"
				>
					<router-link :to="{name:'requestSMSnoti'}" >Noti SMS</router-link>
				</li>-->
				<li 
					class="dropdown-item"
					style="width:250px;"
					v-if="user_type==0"
				>
					<router-link :to="{name:'Users'}" >Employees or Users</router-link>
				</li>
				<li 
					class="dropdown-item"
					style="width:250px;"
					v-if="user_type==0"
				>
					<router-link :to="{name:'Security'}" >Password or Security</router-link>
				</li>
				<li class="dropdown-item"  style="width:250px;">
					<router-link :to="{name:'SecurityInvite'}" v-if="user_type==2">Security</router-link>
				</li>                
				<li class="dropdown-item" style="width:250px;cursor:pointer;padding-top:8px;margin-top:11px;border-top:1px solid #ececec;" @click="logout">
					<a ><span class="logout">Sign Out</span></a>
				</li>
			</div>
		</dropdown-menu>
		<lateral-add-org v-show="m.component=='addOrg'">
			<component v-bind:is="m.component"></component>
		</lateral-add-org>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import DropdownMenu from '@innologica/vue-dropdown-menu'
	import addOrg from '@/core/components/Account/Org/addEditOrg';
	import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
import { set } from 'vue';
	export default {
		name: 'menuAccount',
		components: {
			DropdownMenu,
			addOrg,
			'lateral-add-org': modalLateral,
		},
		props: {
			triggerAddOrg: {
				required: true,
				type: Number,
				default: 0,
			},
		},
		data () {
			return {
				show : false,
				right: true,
				hover: false,
				avatarDefault:'',
				user_type : window.user_type,
				timezonesList: [],
				m: {
					show: false,
					component: '',
					data: []
				},
				formInfo: {},
				showPremium: false,
			}
		},
		created(){
			this.crearAvatar();
		},
		methods:{
			showPopPremium(i) {
				// console.log('getShowPopPremium', i)
				this.$notify({group: "notiPushPremium", duration: 100000});
			},
			UsersEditPerfil(id) {
				console.log(id)
				this.$router.push({name:'UsersEditPerfil', params:{id:id, edit:'edit', profile:'profile'}});
			},
			logout() {
				this.$router.push({ name: 'Logout' });
			},
			AddOrganization() {
				console.log('AddOrganization', window.OrgPlan)
				/*if(window.OrgPlan <=1){
					this.$notify({group: "notiPushPremium", duration: 100000});
				}
				else{*/
					this.m = {
						show: true,
						component: 'addOrg',
						data: {
							title: 'ADD'
						}
					};
					this.$store.commit('core/Org/set_formInfo', this.formInfo);
					console.log('send modal ', this.m)
					this.$store.commit("core/setOpenModal", this.m);
				/*}*/
			},
			crearAvatar(){
				if ((this.dataUser.name != null && this.dataUser.name.length > 0)) {
					this.avatarDefault = (this.dataUser.name.trim()[0]+'').toUpperCase();
				}
				if (this.dataUser.lastname != null && this.dataUser.lastname.length > 0) {
					this.avatarDefault += (this.dataUser.lastname.trim()[0]+'').toUpperCase();
				}
			},
		},
		watch: {
			'getOpenModal': function() {
				if(!this.getOpenModal.show){
					this.showPremium=false;
					// let e = {show: false, data: []};
					// this.$store.commit('core/session/setShowPopPremium', e);
				}
			},
			'getShowPopPremium': function() {
				if(this.getShowPopPremium.show){
					this.showPopPremium(this.getShowPopPremium.data);
				}
			},
			'dataUser': function() {
				console.log('dataUser = ', this.dataUser)
				this.avatarDefault = '';
				this.crearAvatar();
			},
			'avatarDefault': function() {
				if(this.avatarDefault.length == 0){
					this.crearAvatar();
				}
			},
			triggerAddOrg: function(){this.getEventAddOrg = this.triggerAddOrg},
			getEventAddOrg: function(newVal, old){
				// console.log('getEventAddOrg', this.getEventAddOrg)
				if(newVal>old){
					this.AddOrganization();
				}
			},
		},
		computed: {
			...mapGetters('core', {getOpenModal: 'getOpenModal'}),
			...mapGetters('core/session', {
				dataUser : 'getDataUser', 
				getShowPopPremium : 'getShowPopPremium'
			}),
			...mapGetters('core/Org', {orgdata : 'orginf'}),
			getEventAddOrg: {
				get(){
					return this.triggerAddOrg;
				},
				set(val){
					return val
				},
			}
		}
	};
</script>
<style lang="css" scoped>
</style>
