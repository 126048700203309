<template>
    <div>
        <div class="card-header d-flex" style="position: relative; align-items: center">
            <span style="font-size: 18px">Counter Options</span>
        </div>
        <Loader v-if="isLoading"></Loader>
        <div>
            <form @submit.prevent="add" v-if="isLoading == false">
                <div class="form-general">
                    <div
                        class="form-group"
                        style="
                            padding: 0px;
                            padding-right: 20px;
                            margin-top: 10px;
                            margin-left: 20px;
                        "
                    >
                        <label
                            class="c-input c-radio"
                            v-for="(i, index) in Counters"
                            v-bind:key="index"
                        >
                            <input
                                type="radio"
                                v-model="counter_id"
                                :value="i.id"
                                :checked="counter_id == i.id"
                            />
                            {{ i.name }}
                        </label>
                        <br /><br />
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "CounterOptions",
    components: {
        Loader,
    },
    data() {
        return {
            btnSave: false,
            isLoading: false,
            user_id: window.master_client,
            org_id: window.localorgdata,
            counter_id: 0,
            Counters: [],
        };
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.setup;       
            this.getJobsCounte();
        },        
        getJobsCounte() {
            this.isLoading = true;
            let url = this.apis.getCounte()
            window.master
                .get(url)
                .then((response) => {
                    let r = response.data.result;
                    for (let i in r) {
                        if (r[i].status == 1) {
                            this.counter_id = r[i].id;
                        }
                    }
                    this.Counters = r;
                    console.log("getJobsCounte", response.data);
                    this.isLoading = false;
                    this.$parent.close();
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        add() {
            this.$store.commit("core/setLoadingModal", true);
            let url = this.apis.add()
            window.master
                .post(url, {
                    user_id: this.user_id,
                    org_id: this.org_id,
                    counter_id: this.counter_id,
                })
                .then((response) => {
                    console.log(response.data);
                    this.btnSave = false;
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'});
                })
                .catch((error) => {
                    this.btnSave = false;
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
label {
    display: inline-block;
    margin-bottom: 0.1rem;
}
.form-group {
    margin-bottom: 1rem;
}
label.c-input.c-radio {
    padding: 0 10px;
}
</style>
