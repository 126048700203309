<template>
	<div class="card">
		<div class="card-header">{{f.title}} ORGANIZATION</div>
		<div class="card-body" style="padding-top:0px;">
			<form @submit.prevent="saveOrg"  enctype="multipart/form-data" id="form1">
				<div class="row">						
					<div class="col-md-12 marginTop">
						<label class="col-form-label ">Name Organization</label>
						<input type="text" class="form-control" id=""  v-model="f.companyname" required>
					</div>					
					<div class="col-md-6 marginTop">
						<label class="col-form-label">Name</label>
						<input type="text" class="form-control" v-model="f.firstname">
					</div>
					<div class="col-md-6 marginTop">
						<label class="col-form-label">Last Name</label>
						<input type="text" class="form-control" v-model="f.lastname">
					</div>
					<div class="col-md-12 marginTop">
						<label class="col-form-label">Address</label>
						<input type="text" class="form-control" v-model="f.address">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">City</label>
						<input type="text" class="form-control" v-model="f.city">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">State</label>
						<input type="text" class="form-control" v-model="f.state">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Zip Code</label>
						<input type="text" class="form-control" v-model="f.zipcode">
					</div>
					<div class="col-md-12 marginTop">
						<label class="col-form-label">Website</label>
						<input type="text" class="form-control" v-model="f.website">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Telephone</label>
						<input type="text" class="form-control" v-model="f.telephone">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">E-mail</label>
						<input type="text" class="form-control" v-model="f.email">
						<span v-if="emailInvalid" class="alert alert-danger"  style="padding: 5px">
							Please enter a valid email format
						</span>
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Time zone</label>
						<select class="form-control" v-model="f.timezone_id"  :disabled="editZone">
							<option :value="i.id" v-for="(i,index) in timezonesList" v-bind:key="index">{{i.name}}</option>
						</select>
					</div>										
					<div class="col-md-12">
						<div style="margin-top:15px;display: flex;justify-content: flex-end;">
							<button type="submit" class="btn btn-success " style="margin-right:10px;">SAVE</button>
						</div>
					</div>						
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	name: 'addEditOrgs',
	data () {
		return {
			f : {
				user_id :0,
				id :0,
				title : 'ADD',
				action : 'add',
				companyname : '',
				firstname : '',
				lastname : '',
				address : '',
				city : '',
				state : '',
				zipcode : '',
				telephone : '',
				email : '',
				website : '',
				reseller_id:0,
				timezone_id:0,
			},
			emailInvalid: false,
			editZone : false,
			timezonesList: []						
		}
	},
	created() {
		let data = this.$store.getters['core/getOpenModal'].data;
		let email = this.$store.getters['core/getOpenModal'].data.bill_email
		let id = this.$store.getters['core/getOpenModal'].data.id
		this.f.title = 'Update'
		this.f.action = 'update'
		this.f.reseller_id = data.reseller_id
		this.f.id = id
		this.f.user_id = data.user_id			
		this.f.companyname = data.bill_company_name
		this.f.firstname = data.bill_firstname
		this.f.lastname = data.bill_lastname
		this.f.address = data.bill_address
		this.f.city = data.bill_city
		this.f.state = data.bill_state
		this.f.zipcode = data.bill_zip_code
		this.f.telephone = data.bill_telephone
		this.f.email = email
		this.f.website = data.bill_website
		this.f.timezone_id = data.time_zone_id
		// console.log(this.f);
		this.timezones();
	},
	methods: {
		timezones(){
			window.reselleR2.get('timezones')
			.then((response) => {
				this.timezonesList = response.data.result;
			}).catch((error) => console.log(error));
		},					
		saveOrg(){
			if (this.f.telephone.length == 0) {
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "phone empty.",
					show: true,
					duration: 5000
				});			
				this.$store.commit('core/setLoadingModal', false);
				return false
			}
			if (this.f.email.length == 0) {
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "email empty.",
					show: true,
					duration: 5000
				});
				this.$store.commit('core/setLoadingModal', false);
				return false
			}				
			let t = this;
			let re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
			if (!re.test(t.f.email)) {
				t.emailInvalid=true;
				console.log('formato de email invalid');
			} else {
				t.emailInvalid=false;
				t.$store.commit('core/setLoadingModal', true);
				let url ='updateOrganization';
				console.log('url', url);
				window.reselleR2.post(url, t.f)
				.then((response) => {
					t.$store.commit('core/setLoadingModal', false);
					t.$store.commit("core/setCloseModal", {action: 'saved', method: 'refresh-editOrg'});                         
				}).catch((error) => {
					console.log(error);
					t.$store.commit('core/setLoadingModal', false);
				});
			}
		}
	}
};
</script>
<style lang="css" scoped>
	.form-control {
		border: 1px solid #aaa;
	}
</style>
