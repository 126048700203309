<template>
	<svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M7.526 7.866c-.026.08-.026.179-.026.376v3.516c0 .197 0 .296.026.376a.53.53 0 0 0 .34.34c.08.026.179.026.376.026h3.516c.197 0 .296 0 .376-.026a.53.53 0 0 0 .34-.34c.026-.08.026-.179.026-.376V8.242c0-.197 0-.296-.026-.376a.53.53 0 0 0-.34-.34c-.08-.026-.179-.026-.376-.026H8.242c-.197 0-.296 0-.376.026a.53.53 0 0 0-.34.34z" fill="#373F4A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0 4c0-.62 0-.929.041-1.188A3.333 3.333 0 0 1 2.811.04C3.072 0 3.382 0 4 0h12c.62 0 .929 0 1.188.041a3.333 3.333 0 0 1 2.771 2.77C20 3.072 20 3.382 20 4v12c0 .62 0 .929-.041 1.188a3.333 3.333 0 0 1-2.77 2.771C16.928 20 16.618 20 16 20H4c-.62 0-.929 0-1.188-.041a3.333 3.333 0 0 1-2.771-2.77C0 16.928 0 16.618 0 16V4zm4 .947C4 4.424 4.424 4 4.947 4h10.106c.523 0 .947.424.947.947v10.106a.947.947 0 0 1-.947.947H4.947A.947.947 0 0 1 4 15.053V4.947z" fill="#373F4A"></path></svg>
</template>
<script>
export default {
  name: 'iconSquareUp',
  data () {
    return {
    }
  }
}
</script>
<style lang="css" scoped>
</style>
