<template>
	<div class="container-fluid">
		<div class="card">
			<div class="bgloading" v-show="Loading"><Loader></Loader></div>
			<div class="card-header">
				<div class="row">
					<div class="col-md-3">
						<router-link
						:to="{ name: 'WriteaCheckNew' }"
						class="btn btn-success"
						>
							<i class="fas fa-plus"></i> Add a Check
						</router-link>
					</div>
					<div class="col-md-6">
						<input
							type="text"
							class="form-control"
							v-model="searchCheckList"
							placeholder="Filter Check"
							style="width: 100%; border: 1px solid #c9c9ca"
						/>
					</div>
					<div class="col-md-3" style="display: none">
						<div
							style="
							position: relative;
							width: 120px;
							display: block;
							"
						>
							<div style="display: block; position: relative">
								Vendors:
								<span
									style="
									background: #9eced6;
									width: 10px;
									height: 10px;
									position: absolute;
									right: 0;
									top: 5px;
									display: block;
									"
								></span>
							</div>
							<div style="display: block; position: relative">
								SubContractors:
								<span
									style="
									background: #f3dc99;
									width: 10px;
									height: 10px;
									position: absolute;
									right: 0;
									top: 5px;
									display: block;
									"
								></span>
							</div>
						</div>
					</div>
				</div>
			</div>
				<Loader v-if="isLoading"></Loader>
				<div v-else class="row" style="padding:0px 15px;">
					<table
						class="table table-striped"
						v-if="CheckList.length > 0"
						style="margin-bottom: 150px"
					>
						<thead>
							<tr>
								<th scope="col">ID #</th>
								<th scope="col">Exp. ID</th>
								<th scope="col">Bank</th>
								<th scope="col">Link</th>
								<th scope="col">Check Date</th>
								<th scope="col">Check #</th>
								<th scope="col">Pay to</th>
								<th scope="col">Memo</th>
								<th scope="col">Amount</th>
								<th
									scope="col"
									style="text-align: center"
								>
									Status
								</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="item in filterCheckList"
								:key="item.id"
								:class="[
								item.expense_id > 0
								? 'table-success'
								: '',
								]"
							>
								<th scope="row">
									{{
										item.idx
										.toString()
										.padStart(4, "000")
									}}
								</th>
								<td>
									<span v-if="item.numdoc > 0">
										{{
											item.numdoc
											.toString().padStart(4, "000")
										}}
									</span>
								</td>
								<td>
									{{ item.bankname }}
									{{ item.banknumber }}
								</td>
								<td>
									<div v-if="item.status >= 2">
										<button
											title="Edit Linkage"
											class="btn btn-success btn-xs"
											v-if="item.expense_id > 0"
											@click="
											modalEditTransac(
											item.id,
											item.expense_id
											)
											"
										>
											<i class="fas fa-link"></i>
										</button>
										<button
											title="Linkage"
											class="btn btn-info btn-xs"
											v-else
											@click="
											modalEditTransac(
											item.id,
											item.expense_id
											)
											"
											style="
											color: #fff;
											background-color: #737d8c;
											"
										>
											<i class="fas fa-unlink"></i>
										</button>
									</div>
								</td>
								<td>{{ item.check_date }}</td>
								<td>{{ item.check_number }}</td>
								<td>{{ item.pay_to }}</td>
								<td>{{ item.memo }}</td>
								<td>${{ item.amount | formatMoney }}</td>
								<td style="text-align: center">
									<span
										style="
										color: #fff;
										background-color: #ff7500;
										"
										class="badge badge-warning"
										v-if="item.status == 1"
									>
										Created
									</span>
									<span
										class="badge badge-info"
										v-if="item.status == 2"
										@click="convertToExp(item.id)"
									>
										Printed
									</span>
									<span
										class="badge badge-success"
										v-if="item.status == 3"
									>
										Linked
									</span>
									<span
										class="badge badge-danger"
										v-if="item.status == 4"
									>
										Void
									</span>
								</td>
								<td style="width: 85px">
									<span class="cdropdown">
										<button>Actions</button>
										<label>
											<input type="checkbox" />
											<ul>
												<li
													@click="
													actionEdit(
													item.id
													)
													"
												>
													<button>
														<i
														class="fas fa-edit"
														></i>
														Edit
													</button>
												</li>
												<li
													@click="
													convertToExp(
													item.id
													)
													"
													v-if="
													item.status ==
													1 ||
													item.status == 2
													"
												>
													<button>
														<i
														class="far fa-file-alt"
														></i>
														Convert To
														Expenses
													</button>
												</li>
												<li
													@click="
													actionPdf(item.id)
													"
													>
													<button>
														<i
														class="fas fa-file-pdf"
														></i>
														Open PDF
													</button>
												</li>
												<li
													@click="
													validPrinted(
													item.id,
													item.idx
													)
													"
													v-if="
													item.status != 4
													"
												>
													<button>
														<i
														class="fas fa-print"
														></i>
														ePrint
													</button>
												</li>
												<li
													v-if="
													item.status ==
													2 ||
													item.status == 3
													"
													@click="
													anularPago(
													item.id,
													item.expense_id
													)
													"
												>
													<button>
														<i
														class="fas fa-trash-alt"
														></i>
														Void
													</button>
												</li>
												<li
													v-if="
													item.status == 1
													"
													@click="
													elimPago(item.id)
													"
												>
													<button>
														<i
														class="fas fa-times"
														></i>
														Delete
													</button>
												</li>
											</ul>
										</label>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="text-center d-block w-100 p20" v-else>
						<div class="text-center" style="padding: 30px">
							<div
								class="text-center"
								style="padding: 30px"
							>
								<span>
									It's time to create your first
									Check!
								</span><br />
								<router-link
									:to="{ name: 'WriteaCheckNew' }"
									class="btn btn-success"
									style="margin-top: 7px"
								>
									Create New Check
								</router-link>
							</div>
						</div>
					</div>
				</div>
		</div>
		<!-- modal -->
		<modalLateral>
			<component v-bind:is="m.component"></component>
		</modalLateral>         
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import ClickOutside from 'vue-click-outside'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import assignCheckExp from '@/apps/billing/components/writecheck/assignCheckExp'
export default {
	name: "WriteaCheck",
	components: {
		Loader,
		modalLateral,
		assignCheckExp
	},
	data () {
		return {
			isLoading: false,
			Loading: false,
			CheckList: [],
			modal: {
				title: "",
			},
			move: true,
			modalError: false,
			modalErrorMjs: "",
			transac_id: 0,
			listExpensesLoading: false,
			listExpenses: [],
			Exp_id: 0,
			checkITEM: 0,
			unlink: false,
			send_print: 0,
			searchCheckList: "",
			searchExpList: "",
			m: {
				show: false,
				component: '',
				data: []
			}            
		};
	},
	created () {
		this.getlist();
		this.getEprint();
	},
	mounted () {},
	directives: {
		ClickOutside,
	},
	methods: {
		getEprint() {
			let self = this;
			this.isLoading = true;
			window.billing
			.get("writecheck/valideprint/" + window.localuserdata)
			.then((response) => {
				this.send_print = response.data.result;
			})
			.catch((error) => console.log(error));
		},
		modalEditTransac(id, exp) {
			console.log("check_id=" + id);
			this.checkITEM = id;
			this.Exp_id = exp;
			this.modal.title = "SELECT EXPENSES";
			let t = this;
			this.m = {
				show: true,
				component: 'assignCheckExp',
				data: {
					modal:t.modal,
					searchExpList:t.searchExpList,
					modalError: t.modalError,
					modalErrorMjs: t.modalErrorMjs,
					transac_id:t.transac_id,
					Exp_id:t.Exp_id,
					unlink:t.unlink,					
					checkITEM:t.checkITEM, 					
					listExpensesLoading:t.listExpensesLoading,
				}
			}  
			this.$store.commit('core/setOpenModal', this.m);
		},
		convertToExp(id) {
			this.isLoading = true;
			this.$swal({
				title: "Convert to Expense this Check?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, convert!",
			}).then((result) => {
				if (result.value) {
					window.billing
					.post("writecheck/converttoexpense", {
						user_id: window.localuserdata,
						id: id,
					})
					.then((response) => {
						console.log(response.data);
						this.isLoading = false;
						if (response.data.status) {
							this.getlist();
							this.$notify({
								group: "noti",
								title: "Convert to Expense!",
								type: "success",
								text: "Successful",
							});
						}
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
				}
			});
			this.isLoading = false;
		},
		validPrinted(id, idx) {
			if (this.send_print === 1) {
				this.actionPrint(id, idx);
			} else {
				this.$swal({
					title:
					"You don't have Eprint configured, you want to convert check to printed anyway?",
					text: "Are you sure?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					confirmButtonText: "Yes, change to printed!",
				}).then((result) => {
					if (result.value) {
						this.send_print = 0;
						this.actionPrint(id, idx);
					}
				});
			}
		},
		actionPrint(id, idx) {
			let numdoc = idx.toString().padStart(4, "000");
			this.Loading = true;
			this.$swal({
				title: "Convert to printed check?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes",
				html: `<label>
				<input type="checkbox" value="1" id="sendCheck">
				<span class="swal2-label">send to printer check</span>
				</label>`,
			}).then((result) => {
				if (result.value) {
					let sendCheck = document.querySelector("#sendCheck").checked ? 1 : 0;
					window.billing
					.post("printcheck", {
						user_id: window.localuserdata,
						id: id,
						numdoc: numdoc,
						send_print: this.send_print,
						sendCheck: sendCheck,
					})
					.then((response) => {
						this.Loading = false;
						console.log(response.data);
						if (response.data.status) {
							this.$notify({
								group: "noti",
								title: "Check",
								type: "success",
								text: "Successfully printed",
							});
							this.getlist();
						} else {
							this.$notify({
								group: "noti",
								title: "Check",
								type: "error",
								text: "Error printed",
							});
						}
					})
					.catch((error) => {
						this.Loading = false;
						console.log(error);
						this.$notify({
							group: "noti",
							title: "Check",
							type: "error",
							text: "Error printed",
						});
					});
				} else {
					this.Loading = false;
				}
			});
		},
		actionPdf(id) {
			window.open(window.urlbase + "api/v2/writeacheck/pdf/" + id, "_blank");
		},
		getlist() {
			this.isLoading = true;
			window.billing
			.get("writecheck/" + window.localuserdata)
			.then((response) => {
				console.log(response.data);
				this.CheckList = response.data.result;
				this.isLoading = false;
			})
			.catch((error) => {
				this.isLoading = false;
				console.log(error);
			});
		},
		actionEdit(id) {
			this.$router.push({ name: "WriteaCheckNew", params: { idw: id } });
		},
		anularPago(id, edi) {
			this.isLoading = true;
			this.$swal({
				title: "Void this Check?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Void it!",
			}).then((result) => {
				if (result.value) {
					window.billing
					.post("writecheck/anular", {
						id: id,
						expense_id: edi,
					})
					.then((response) => {
						console.log(response.data);
						this.isLoading = false;
						if (response.data.status) {
							this.getlist();
							this.$notify({
								group: "noti",
								title: "Void!",
								type: "success",
								text: "Void Check Successful",
							});
						}
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
				}
			});
			this.isLoading = false;
		},
		elimPago(id) {
			this.isLoading = true;
			this.$swal({
				title: "Delete this Check?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					window.billing
					.post("writecheck/del", {
						id: id,
					})
					.then((response) => {
						console.log(response.data);
						this.isLoading = false;
						if (response.data.status) {
							this.getlist();
							this.$notify({
								group: "noti",
								title: "Delete!",
								type: "success",
								text: "Delete Check Successful",
							});
						}
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
				}
			});
			this.isLoading = false;
		},
	},
	watch: {
		'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
					let datos = this.getOpenModal.data;
					if(datos.refresh) {
						this.getlist();
					} 
                }                 
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
		'getActionRefresh': function() {
			if (Object.keys(this.getActionRefresh.data).length > 0) {
				let datos = this.getActionRefresh.data;
				if(datos.refresh) {
					this.getlist();
				}
			}
        },
	},
	computed: {
		...mapGetters("core", {
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh",
        }),
		filterCheckList: function () {
			let self = this;
			return this.CheckList.filter(function (i) {
				return (
					(i.check_date &&
						i.check_date
						.toLowerCase()
						.indexOf(self.searchCheckList.toLowerCase()) >= 0) ||
					(i.bankname &&
						i.bankname
						.toLowerCase()
						.indexOf(self.searchCheckList.toLowerCase()) >= 0) ||
					(i.banknumber &&
						i.banknumber
						.toLowerCase()
						.indexOf(self.searchCheckList.toLowerCase()) >= 0) ||
					(i.pay_to &&
						i.pay_to
						.toLowerCase()
						.indexOf(self.searchCheckList.toLowerCase()) >= 0) ||
					(i.memo &&
						i.memo
						.toLowerCase()
						.indexOf(self.searchCheckList.toLowerCase()) >= 0) ||
					(i.idx && i.idx.indexOf(self.searchCheckList) >= 0) ||
					(i.check_number &&
						i.check_number.indexOf(self.searchCheckList) >= 0) ||
					(i.amount && i.amount.indexOf(self.searchCheckList) >= 0)
					);
			});
		},
	},
};
</script>
<style lang="css" scoped>
table tbody tr td .badge {
    width: 90%;
}
</style>
