<template>
    <div :class="['recPop', ($parent.showPopRec) ? 'show' : 'none']">
        <div class="card">
            <div class="card-header">
                <h4>
                    Recurring 
                    <span class="badge badge-success" v-if="f.task_id>0">#{{f.idx}}</span>
                </h4>
                <button @click="$parent.modalPop" class="closeIcon"><span></span></button>
            </div>
            <div class="card-body" style="padding-top:10px;">
                <form class="row">
                    <div class="form-group col-sm-12">
                        <div class="input-group">
                            <label for="rec" style="margin-right: 15px;">Active:</label>
                            <div class="btn-switch" >
                                <div class="onoffswitch" >
                                    <input 
                                        type="checkbox" 
                                        id="recActive" 
                                        class="onoffswitch-checkbox" 
                                        v-model="activeRec"
                                        @change="saveRec"
                                    />
                                    <label class="onoffswitch-label" for="recActive">
                                        <span class="onoffswitch-inner"></span>
                                        <span class="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="interval">Every: </label>
                        <div class="input-group" style="display: inline-block;margin-left:15px;width: 70%;top: 2px;">
                            <label class="radio-inline" >
                                <input type="radio" v-model="f.interval" value="1" @change="saveRec" /> Year
                            </label>    
                            <label class="radio-inline" style="padding-left: 25px;">
                                <input type="radio" v-model="f.interval" value="2" @change="saveRec" /> Month
                            </label>
                        </div>
                    </div>
                    <Loader v-if="isLoading" class="isLoading"></Loader>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/Loader.vue';
    export default {
        name: "recPop",
        components: {
            Loader,
        },
        props: {
            itemRec: {
                type: Object,
                required: true,
                default: () => {},
            },
        },
        data() {
            return {
                isLoading: false,
                f: {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    idx: 0,
                    task_id: 0,
                    interval: 1,
                    rec: 1,
                },
                activeRec: false,
            }
        },
        watch: {
            "itemRec": function(){
                if(this.itemRec!=null){
                    if(this.itemRec.id){
                        this.f.idx=this.itemRec.idx;
                        this.f.task_id=this.itemRec.id;
                        this.f.interval=(this.itemRec.interval==0) ? 1 : this.itemRec.interval;
                        this.f.rec=this.itemRec.rec;
                        this.activeRec=(this.itemRec.rec>0);
                    }else{
                        this.f.idx=0;
                        this.f.task_id=0;
                        this.f.interval=1;
                        this.f.rec=0;
                        this.activeRec=false;
                    }
                }
            }
        },
        methods: {
            saveRec(){
                let t = this;
                if(t.f.task_id>0){
                    console.log('saveRec', t.f);
                    t.f.rec = (t.activeRec) ? 1 : 0;  
                    t.isLoading=true;
                    let url="setRurringTask";
                    window.billing
                    .post(url, t.f)
                    .then((response) => {
                        t.isLoading=false;
                        console.log(url);
                    })
                    .catch((error) => {
                        t.isLoading=false;
                        console.log(error);
                    });
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
.recPop{
    position: absolute;
    width: 250px;
    left: 25%;
    z-index: 999;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    &.show{
        display: block;
    }
    &.none{
        display: none;
    }
    .onoffswitch-inner:before {
        content: "Yes";
        padding-left: 10px;
        background-color: #489a38;
        color: #FFFFFF;
    }
    .onoffswitch-inner:after {
        content: "No";
        padding-right: 10px;
        background-color: #d65353; 
        color: #fff;
        text-align: right;
    }
}
.isLoading {
	position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(255 255 255 / 46%);
    z-index: 1;
}
</style>
