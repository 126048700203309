let accitonPusher = function() {
	let t= this;
	if (t.accitonPusher != undefined) {
		let p = t.accitonPusher;
		if (p.action && p.action == 'refresh-invo') {
			t.searchFilter();
			if(p.saved == 'SquarePay' && p.oauth === window.oauth_m) {
				t.invoIdPusher = p.invoice_id;
				t.$notify({
					group: "noti",
					title: "Payment Square Up",
					type: "success",
					text: "Successful registration",
				});
			}
		}
		if (p.action && p.action == 'opAddi-refresh'&& p.org_id == window.localorgdata && p.oauth != window.oauth_m) {
			if(p.data.opAddi){
				let opAddi = p.data.opAddi;
				console.log('opAddi-refresh', opAddi)
				t.valOpAddi = opAddi.split(',');
			}
		}
		if (p.action && p.action == 'cols-refresh'&& p.org_id == window.localorgdata && p.oauth != window.oauth_m) {
			if(p.data.columnas){
				let cols = p.data.columnas;
				console.log('cols-refresh', cols)
				t.showCols(cols);
			}
		}
		if (p.action && p.action == 'add-payment' && p.org_id == window.localorgdata) {
			t.refreshListByPayment(p.data)
		}
		if (t.accitonPusher.action == "update-status" && p.org_id == window.localorgdata) {
			console.log('cambio de status jobs ', t.accitonPusher)
			t.refreshListByJobs(t.accitonPusher);
		}
		if (t.accitonPusher.action == "jobs-assign-invo" && p.org_id == window.localorgdata) {
			console.log('nuevo jobs asignado ', t.accitonPusher)
			t.refreshListByJobs(t.accitonPusher);
		}
		if (t.accitonPusher.action == "delete-job" && p.org_id == window.localorgdata) {
			console.log('delete-job jobs ', t.accitonPusher)
			t.refreshListByJobs(t.accitonPusher);
		}
		if (t.accitonPusher.action == "edit-custInvo" && p.org_id == window.localorgdata) {
			console.log('edit-custInvo', t.accitonPusher)
			t.trueRefreshListByJobs(t.accitonPusher);
		}
		if (t.accitonPusher.action == "invo-complete" && p.org_id == window.localorgdata) {
			console.log('invo-complete', t.accitonPusher)
			t.trueRefreshListByJobs(t.accitonPusher);
		}
		if (t.accitonPusher.action == "serviceSMS" && p.org_id == window.localorgdata) {
			// console.log('serviceSMS', t.accitonPusher)
			t.ActiveNotibySMS(t.accitonPusher);
		}
		if (t.accitonPusher.action == "void-invoice" && p.org_id == window.localorgdata) {
			// console.log('serviceSMS', t.accitonPusher)
			t.refreshListByVoid(t.accitonPusher);
		}
        if(p.action == "showSMS" && window.master_client == p.user_id && window.localorgdata == p.org_id){
            t.getkeyVal('showSMS');
        }
        if(p.action == "JobsInt" && window.master_client == p.user_id && window.localorgdata == p.org_id){
            t.JobsInt = p.data.meta_value;
        }
		if (p.action == 'note-Invoice'&& p.org_id == window.localorgdata && p.oauth != window.oauth_m) {
			console.log('pusher ',p.action)
			t.searchFilter(1);
		}
		setTimeout(() => {
			t.forceUpdate();
		}, 5e3);
	}
};
export default accitonPusher;
