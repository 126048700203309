<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" id="card">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 style="margin-top: 5px; margin-left: 5px">
                                        <strong>Print All Checks</strong>
                                    </h4>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-success"
                                        style="
                                            float: right;
                                            color: white;
                                            margin-right: 5px;
                                        "
                                        @click="actionPrint"
                                    >
                                        <i class="fas fa-print"></i>
                                        Print Check
                                    </button>
                                    <button
                                        class="btn btn-success"
                                        style="
                                            float: right;
                                            color: white;
                                            margin-right: 5px;
                                        "
                                        @click="actionPdf"
                                    >
                                        <i class="fas fa-file-pdf"></i>
                                        Open PDF Check
                                    </button>
                                    <button
                                        class="btn btn-dafaull"
                                        style="
                                            float: right;
                                            background: #343a40;
                                            color: white;
                                            margin-right: 5px;
                                        "
                                        @click="back"
                                    >
                                        <i class="fas fa-arrow-left"></i>
                                        Back to Timesheet List
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" v-if="isLoading">
                            <Loader></Loader>
                        </div>
                        <div class="card-body" v-else>
                            <div
                                class="row"
                                v-for="(item, index) in subtime"
                                :key="index"
                            >
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                    <table border="0" width="85%">
                                        <tr>
                                            <td valign="top">Subcontractor</td>
                                            <td valign="top">:</td>
                                            <td valign="top">
                                                <strong>
                                                    {{ item.subcontractor_name }}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">
                                                Select Project (Optional)
                                            </td>
                                            <td valign="top">:</td>
                                            <td valign="top">- Choose a Project -</td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Check Date</td>
                                            <td valign="top">:</td>
                                            <td valign="top">
                                                <datetime
                                                    required
                                                    type="date"
                                                    v-model="f.fecini"
                                                    :format="{
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    }"
                                                    use12-hour
                                                    value-zone="America/New_York"
                                                    input-class="form-control jobdate"
                                                ></datetime>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Pay to the order of</td>
                                            <td valign="top">:</td>
                                            <td valign="top">
                                                {{ item.subcontractor_name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Amount $</td>
                                            <td valign="top">:</td>
                                            <td valign="top">{{ item.amount }}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Memo</td>
                                            <td valign="top">:</td>
                                            <td valign="top">
                                                <textarea
                                                    name="observ_1"
                                                    id="observ_1"
                                                    autocomplete="off"
                                                    v-model="observ"
                                                    rows="5"
                                                    class="input-block-level"
                                                    cols="80"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" valign="top">
                                                <hr
                                                    style="
                                                        background-color: #7cb1ee;
                                                        margin-top: -1px;
                                                    "
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Loader from "@/core/components/shared/Loader";
export default {
    name: "TimesheetAllChecks",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            isDisabled: false,
            f: {
                id: 0,
                times_id: 0,
                user_id: window.localorgdata,
                billing: 1,
                nameSub: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
            },
            user_id: window.localorgdata,
            TimesheetList: [],
            subtime: [],
            observ: "",
        };
    },
    created() {
        this.getTimesheet();
        this.get();
    },
    methods: {
        actionPdf() {
            let id = this.$route.params.id;
            window.open(
                window.urlbase + "api/v2/timesheet/pdf/check/" + this.user_id + "/" + id,
                "_blank"
            );
        },
        actionPrint() {
            let id = this.$route.params.id;
            this.isLoading = true;
            let url = "/timesheet/ePrinttimesheetPDFAllCheck";
            let data = {
                user_id: this.user_id,
                id: id,
                eprint: 1,
                sub_id: 0,
            };
            window.billing
                .post(url, data)
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    let status = response.data.status;
                    let error = response.data.error;
                    let message = response.data.message;
                    if (status == false && error == true) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                    if (status == true && error == false) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "success",
                            text: message,
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send!",
                        type: "error",
                        text: "Send Error",
                    });
                });
        },
        get() {
            this.isLoading = true;
            this.f.times_id = this.$route.params.id;
            window.billing
                .post("/subtimesheet/get", this.f)
                .then((response) => {
                    this.subtime = response.data.result.subtime;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        getTimesheet() {
            this.isLoading = true;
            this.f.times_id = this.$route.params.id;
            window.billing
                .post("timesheet/get", this.f)
                .then((response) => {
                    this.TimesheetList = response.data.result;
                    let obs = this.TimesheetList;
                    this.observ = "From: " + obs[0].froms + " To: " + obs[0].tos;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        back() {
            this.$router.push({ name: "Timesheet" });
        },
    },
    watch: {},
    computed: {},
};
</script>
<style lang="scss" scoped>
input.vdatetime-input.form-control.jobdate {
    width: 0px !important;
}
.form-control {
    width: 0px !important;
}
.botonera-filtros {
    padding: 0px 15px;
    display: flex;
    width: 100%;
}
.btn-add-invo,
.btn-sync {
    margin-right: 10px;
}
.btn-add-invo {
    width: 10%;
    max-width: 115px;
    min-width: 115px;
}
.btn-sync {
    width: 4px;
    max-width: 35px;
    min-width: 35px;
}
.filtros {
    width: 70%;
    margin-left: 16%;
}
@media (min-width: 1320px) {
    .mobile_display {
        justify-content: flex-end;
    }
}
.uno {
    width: 550px;
    margin-right: 10px;
}
.dos {
    width: 70px;
    margin-right: 10px;
}
.tres {
    width: 102px;
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 10%;
        width: 76%;
    }
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 5%;
        width: 81%;
    }
}
@media (max-width: 1100px) {
    .filtros {
        max-width: 924px;
    }
    .uno {
        width: 400px;
        margin-right: 10px;
    }
}
@media (max-width: 1000px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 300px;
    }
}
@media (max-width: 875px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
    .botonera-filtros {
        display: block;
    }
    .btn-add-invo,
    .btn-sync {
        display: inline-block;
        margin-bottom: 10px;
    }
    .filtros {
        margin-left: 0%;
        margin-bottom: 10px;
        width: 100%;
        padding-left: 15px;
    }
}
.btn-filter-search:hover {
    cursor: pointer;
}

.tres button.btn,
.cuatro button.btn {
    padding: 7px 10px;
}

.cdropdown ul li {
    padding: 3px 10px;
}
.cdropdown ul li button {
    font-size: 0.76rem;
}
#card-invoice {
    background: transparent;
}
.dropdStatus {
    list-style: none;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    @media (max-width: 767px) {
        right: 0;
    }
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.scroll-right {
    display: none;
}

.btnDropActions {
    border-color: #ccc;
    padding: 0;
}
.btnDropActions label input {
    display: none;
}
.btnDropActions label:hover {
    cursor: pointer;
}
.btnDropActions label {
    padding: 0.375rem 0.5rem;
    margin-bottom: 0.1rem;
    font-size: 12px;
}
.textAction {
    font-size: 12px;
}
.dropdown-item.action-item {
    padding: 3px 5px 3px 10px;
}

/* ************************************* */

.invoList tbody tr td {
    font-size: 0.775rem;
}

.invoList thead tr th:nth-child(1),
.invoList tbody tr td:nth-child(1) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(2),
.invoList tbody tr td:nth-child(2) {
    min-width: 92px;
    width: 7.937877480586713%;
}
.invoList thead tr th:nth-child(3),
.invoList tbody tr td:nth-child(3) {
    min-width: 103px;
    width: 8.886971527178602%;
}
.invoList thead tr th:nth-child(4),
.invoList tbody tr td:nth-child(4) {
    min-width: 200px;
    width: 17.25625539257981%;
}
.invoList thead tr th:nth-child(5),
.invoList tbody tr td:nth-child(5) {
    min-width: 178px;
    width: 15.35806729939603%;
}
.invoList thead tr th:nth-child(6),
.invoList tbody tr td:nth-child(6) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(7),
.invoList tbody tr td:nth-child(7) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(8),
.invoList tbody tr td:nth-child(8) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(9),
.invoList tbody tr td:nth-child(9) {
    min-width: 73px;
    width: 6.298533218291631%;
}
.invoList thead tr th:nth-child(10),
.invoList tbody tr td:nth-child(10) {
    min-width: 40px;
    width: 3.451251078515962%;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 71px;
    width: 6.125970664365833%;
}
.invoList thead tr th:nth-child(12),
.invoList tbody tr td:nth-child(12) {
    min-width: 110px;
    width: 9.490940465918896%;
}

.invoList thead {
    width: 100%;
    display: block;
    background: #fff;
}
.invoList tbody {
    width: 100%;
    display: block;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 290px;
}
.invoList thead tr,
.invoList tbody tr {
    display: block;
}
.invoList thead tr th {
    display: inline-block;
    height: 34px;
}
.invoList tbody tr td {
    display: inline-block;
    height: 50px;
}
.table-bordered th,
.table-bordered td {
    border: 0px solid transparent !important;
}

.contenedor-invoice table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 70px;
    width: 70px;
}
table.table.table-striped.table-bordered.table-hover.invoList {
    border: 0px;
}
.contenedor-invoice table {
    width: 100%;
    min-width: 1180px;
}
.contenedor-invoice {
    padding: 0px 10px 0px 10px;
    overflow-x: auto;
    width: calc(100vw - 170px);
}
@media (max-width: 1230px) {
    .scroll-right {
        display: block;
    }
}
@media (max-width: 1100px) {
    .invoList thead tr th:nth-child(1),
    .invoList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    .invoList thead tr th:nth-child(2),
    .invoList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(3),
    .invoList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(4),
    .invoList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    .invoList thead tr th:nth-child(5),
    .invoList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    .invoList thead tr th:nth-child(6),
    .invoList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(7),
    .invoList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    .invoList thead tr th:nth-child(8),
    .invoList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(9),
    .invoList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(10),
    .invoList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    .invoList thead tr th:nth-child(11),
    .invoList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-invoice {
        max-width: 924px;
    }
}
.invoList tbody tr:nth-of-type(odd) {
    background-color: #e9ecef;
}
.invoList tbody tr:nth-of-type(even) {
    background-color: #fff;
}
</style>
