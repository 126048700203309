let filterTags = function() {
    this.cam.tagsModalName = this.tagsModalName
    this.cam.taksID = this.taksID
    this.cam.idJobs = this.listTaks
    this.cam.user_id_assign = this.user_id_assign
    this.cam.f.id = this.tagsModalId
    this.cam.f.name = this.tagsModalName
    this.cam.id = this.tagsModalId  
    this.cam.id_tags = this.id_tags
    //console.log('enviando data tags', this.cam);
    this.$store.commit('ModApps/jobs/setDataFilterTagsUrl', this.cam);
};
export default filterTags;
