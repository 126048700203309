let contruirSelect = function(data, prop){
	let a = [];
	for (let i in data) {
		if(data[i]) {
			let n = {}
			for (let j in prop) {
				if(prop[j]) {
					if(Object.keys(data[i]).indexOf(prop[j]) > -1) {
						if(prop[j] == 'id') {
							n.value = data[i].id;
						}
						if(prop[j] == 'name') {
							n.text = data[i].name;
						}
						if(prop[j] == 'id_campaign') {
							n.id_campaign = data[i].id_campaign;
						}
					}
				}
			}
			if(Object.keys(n).length > 0) {
				a.push(n);
			}
		}
	}
	// console.log('a', a)
	return a;
};
export default contruirSelect;
