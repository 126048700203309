<template>
	<div class="container">
		<div class="row">
			<div class="col-md-10 align-self-center">
				<h3 class="page-title">
					Custom Step's
					<button 
						type="button" 
						class="btn btn-success"
						@click="btnNewQuest()"
					>Add</button>
				</h3>
			</div>
			<div class="col-md-2 align-self-center">
				<div class="d-flex align-items-right justify-content-end">
					<router-link :to="{ name: 'RoofCalSetup' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: 120px;">
						<i class="fas fa-arrow-left"></i>
						Back to Setup
					</router-link>   
				</div>
			</div>
			<div class="col-md-12">
				<listquest :list="listMain" :load="isLoading"></listquest>
			</div>
		</div>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
//import draggable from 'vuedraggable'
import listquest from "@/apps/RoofCal/components/RoofCalListQuest.vue";
import lisanswers from "@/apps/RoofCal/components/RoofCalAnswers.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
export default {
	name: 'RoofCalQuest',
	components: {
		//draggable,
		listquest,
		lisanswers,
		modalLateral,
	},
	data () {
		return {
			listMain: [],
			isLoading: false,
			idQues: 0,
			nomQuest: '',
			descripQuest: '',
			multiple: 0,
			orderItem: 0,
			status: 1,
			answers: '',
			statusList: [1],
			apis: {},
			m: {
				show: false,
				component: '',
				data: []
			}, 
		}
	},
	created(){
        let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
        this.apis = urlApis.setup;
		this.init(0);
	},
	methods: {
		init(load){
			let t = this;
			if(!load){t.isLoading=true;}
			t.listMain=[];
			window.master.post(t.apis.getServ(), {
      			user_id: window.master_client,
      			org_id: window.localorgdata,
      			statusList: t.statusList
			})
			.then((response) => {
				// console.log(response.data);
				let list = response.data.list;
				t.modelarStatus(list, load);
				t.isLoading=false;
			})
			.catch((error) => {
				console.log(error);
				t.isLoading=false;
			});
		},
		modelarStatus(list, load) {
			let t = this;
			for (let j in list) {
				if(list[j]) {
					list[j].checked = (list[j].status>0);
				}
			}
			t.listMain = list;
			if(load==2){
				t.refreshItemSelected();
			}
		},
		refreshItemSelected(){
			let t = this;
			let getData = t.$store.getters['core/getOpenModal'].data;
			if(getData.id){
				if(getData.id>0){
					t.listMain.map((item, index) => {
						if(item.id == getData.id){
							console.log('refreshItemSelected');
							t.openLateral(item);
						}
					});
				}
			}
		},
		openLateral(item){
			this.m= {
				show: true,
				component: 'lisanswers',
				data: {
					id: item.id,
					name: item.name,
					descrip: item.descrip,
					answers: item.answers,
					multiple: item.multiple,
					orderItem: item.orderItem,
					status: item.status,
					action: 'edit'
				}
			};
			this.$store.commit('core/setOpenModal', this.m);
		},
		btnNewQuest(){
			this.m= {
				show: true,
				component: 'lisanswers',
				data: {
					id: 0,
					name: '',
					descrip: '',
					answers: [],
					multiple: this.multiple,
					orderItem: this.orderItem,
					status: this.status,
					action: 'add'
				}
			};
			this.$store.commit('core/setOpenModal', this.m);
		},
		addQuest() {
			let t= this;
			if(t.nomQuest.length > 0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post(t.apis.createServ(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					name: t.nomQuest,
					descrip: t.descripQuest,
					multiple: t.multiple,
					answers: t.answers,
				})
				.then((response) => {
					if(response.data.status) {
						let e = response.data;
						t.m= {
							show: true,
							component: 'lisanswers',
							data: {
								id: e.serv_id,
								name: t.nomQuest,
								descrip: t.descripQuest,
								multiple: t.multiple,
								orderItem: t.orderItem,
								status: t.status,
								answers: [e.res],
								action: 'edit'
							}
						};
						t.init(1);
						t.$store.commit('core/setOpenModal', t.m);
						t.$store.commit("core/setLoadingModal", false);
						t.nomQuest = '';
						t.descripQuest = '';
						t.multiple = 0;
					}
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});	
			}
		},
		editQuest(i){
			let t = this;
			if(i.name.length>0) {
				window.master.post(t.apis.updateServ(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					data: [i],
				})
				.then((response) => {
					console.log(response.data);
					t.init(1);
				})
				.catch((error) => {
					console.log(error);
				});
			}
		},	
		deleteQuest(id) {
			let t =this;
			t.$swal({
				title: "Delete Item?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					if(id>0) {
						window.master.post(t.apis.deleteServ(), {
							user_id: window.master_client,
							org_id: window.localorgdata,
							id: id,
						})
						.then((response) => {
							t.init(0);
						})
						.catch((error) => {
							console.log(error);
						});
					}
				}
			});
		},	
		hideQuest(id){
			let t =this;
			t.$swal({
				title: "Send to trash?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Remove it!",
			}).then((result) => {
				if (result.value) {
					if(id>0) {
						window.master.post(t.apis.hideServ(), {
							user_id: window.master_client,
							org_id: window.localorgdata,
							id: id,
						})
						.then((response) => {
							t.init(0);
						})
						.catch((error) => {
							console.log(error);
						});
					}
				}
			});
		},
		changeMultiplQuest(i){
			let t = this;
			if (i.checked) {
				i.multiple = 0;
				i.checked = false;
			} else {
				i.multiple = 1;
				i.checked = true;
			}
			window.master.post(t.apis.updateServ(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				data: [i],
			})
			.then((response) => {
				console.log(response.data);
				t.init(1);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		checkMoveQuest(list) {
			let t = this;
			window.master.post(t.apis.updateServ(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				data: list,
			})
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
		}, 
		addAnswers() {
			let t= this;
			if(t.answers.length > 0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post(t.apis.createAnswers(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					idQues: t.idQues,
					answers: t.answers,
				})
				.then((response) => {
					if(response.data.status) {
						let e = response.data;
						t.m= {
							show: true,
							component: 'lisanswers',
							data: {
								id: e.serv_id,
								name: t.nomQuest,
								descrip: t.descripQuest,
								multiple: t.multiple,
								orderItem: t.orderItem,
								status: t.status,
								answers: e.answers,
								action: 'edit'
							}
						};
						t.init(1);
						t.$store.commit('core/setOpenModal', t.m);
						t.$store.commit("core/setLoadingModal", false);
						t.nomQuest = '';
						t.descripQuest = '';
						t.multiple = 0;
					}
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});	
			}
		}, 
		checkMoveAnswers(list) {
			let t = this;
			window.master.post(t.apis.updateAnswers(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				data: list,
			})
			.then((response) => {
				console.log(response.data);
				t.init(1);
			})
			.catch((error) => {
				console.log(error);
			});
		}, 
		editAnswers(i){
			let t = this;
			if(i.name.length>0) {
				window.master.post(t.apis.updateAnswers(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					data: [i],
				})
				.then((response) => {
					console.log(response.data);
					t.init(0);
				})
				.catch((error) => {
					console.log(error);
				});
			}
		}, 
		changeCheckAnswers(i){
			let t = this;
			if (i.checked) {
				i.check = 0;
				i.checked = false;
			} else {
				i.check = 1;
				i.checked = true;
			}
			window.master.post(t.apis.updateAnswers(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				data: [i],
			})
			.then((response) => {
				console.log(response.data);
				t.init(1);
			})
			.catch((error) => {
				console.log(error);
			});
		}, 
		changeCheckAnswersDefault(i){
			let t = this;
			if (i.checked) {
				i.check = 0;
				i.checked = false;
			} else {
				i.check = 1;
				i.checked = true;
			}
			window.master.post(t.apis.checkDefault(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				data: i,
			})
			.then((response) => {
				console.log(response.data);
				t.init(2);
			})
			.catch((error) => {
				console.log(error);
			});
		}, 	
		deleteAnswers(i) {
			let t =this;
			t.$swal({
				title: "Delete Item?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					t.elimAnswers(i);
				}
			});
		},	
		elimAnswers(item){
			let t = this;
			if(item.id>0) {
				window.master.post(t.apis.deleteAnswers(), item)
				.then((response) => {
					t.init(2);
				})
				.catch((error) => {
					console.log(error);
				});
			}
		},
	}, 
	computed: {
		...mapGetters("core", { getOpenModal: "getOpenModal" }),
	}
}
</script>
<style lang="css" scoped>
</style>
