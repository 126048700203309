let changeColor = function(idx, id) {
    this.$store.commit("core/setNotiPush", {
        group: "notiPush",
        type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
        title: "Change Color",
        text: "Loading...",
        show: true,
        duration: 500
    }); 
    let t = this;
    let url = this.apis.changeColor()
    window.master
        .post(url, {
            org_id: window.localorgdata,
            job_id: this.listTaks[idx].id,
            id_color: id,
        })
        .then((response) => {
            t.listTaks[idx].id_color = id;
            t.reEscribirLista();
        })
        .catch((error) => {
            console.log(error);
        });
};
export default changeColor;
