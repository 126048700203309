<template>
	<div class="card">
		<div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h3 class="card-title">Tag Setting</h3>
                </div>
            </div>
        </div>
		<div class="card-body">
			<ul class="list-group list-group-flush" v-if="CategoryList.length>0">
				<li class="list-group-item">
					
					<form @submit.prevent="save">
						<div class="input-group">
							<input type="text" class="form-control" placeholder="Tag Name" v-model="category_name" :disabled="btndisabled">
							<div class="input-group-prepend">
								<button type="submit" class="btn btn-info" :disabled="btndisabled">Add New</button>
							</div>
						</div>
					</form>
				</li>
				<li class="list-group-item">
					<div class="input-group">
						<div style="width:85%;font-weight:bold;">Tag Name</div>
						<div class="input-group-prepend" style="width:15%;text-align:right;font-weight:bold;display:block;">
							Status
						</div>
					</div>
				</li>
				<li class="list-group-item" v-for="(i, idx) in CategoryList" :key="idx">
					<div class="input-group">
						<input type="text" v-model="i.category_name" class="form-control" @change="changeTag(i, 'name')" placeholder="Edit tag name" >
						<div class="input-group-prepend">
							<button type="button" class="btn btn-success" :disabled="btndisabled" @click="changeTag(i, 'status')"  v-if="i.active==1">Active</button>
							<button type="button" class="btn btn-danger" :disabled="btndisabled" @click="changeTag(i, 'status')" v-else>Inactive</button>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'listTags',
	data () {
		return {
			CategoryList: [],
			category_name: null,
			category_id: 0,
			btndisabled: false,
			category_status: 1,
		}
	},
	created(){
		this.getTagByUserId(1);
	},
	methods:{
		getTagByUserId(load){
			let t = this;
			if(load!=undefined){t.$store.commit("core/setLoadingModal", true);}
			window.billing
			.post("getTagByUserId", {
				user_id: window.localorgdata,
			})
			.then((response) => {
				console.log('getTagByUserId', response.data);
				let r = response.data;
				t.CategoryList=[];
				if(r.list.length>0){
					t.CategoryList=r.list;
				}
				t.btndisabled=false;
				t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
				t.btndisabled=false;
			});
		},
		setTags(action){
			let t = this;
			t.btndisabled=true;
			window.billing
			.post("setTag", {
				id: t.category_id,
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
				action: action,
				category_name: t.category_name,
				category_status: t.category_status
			})
			.then((response) => {
				console.log('setTag', action, response.data.tag);
				t.category_name= null;
				t.category_id=0;
				t.category_status=1;
				t.getTagByUserId();
			})
			.catch((error) => {
				console.log(error);
				t.btndisabled=false;
			});
		},
		save(){
			let t = this;
			t.setTags('add');
		},
		changeTag(item, type){
			let t = this;
			t.category_id=item.id;
			t.category_name= item.category_name;
			t.category_status= item.active;
			if(type=='status'){
				t.category_status = (item.active==1) ? 0 : 1;
			}
			t.setTags('edit');
		},
	},
	watch: {
		"accitonPusher": function(){
			let t = this;
			let p = t.accitonPusher;
			console.log('listTags accitonPusher');
			if (p!=undefined && p!=null){
				if(p.org_id==window.localorgdata){	
					if(p.action=='task-tag-refresh'){
						console.log('listTags pusher');
					}
				}
			}
		},
	},
	computed: {    
		...mapGetters("core", {
			accitonPusher: "get_accitonPusher",
		}),
	}
}
</script>
<style lang="css" scoped>
</style>
