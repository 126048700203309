<template>
	<div>
		<div class="card">
			<div class="card-header">
				{{ modal.title }}
			</div>
			<div class="card-body" >
				<form @submit.prevent="saveVendor" v-if="modal.vendor">
					<div class="form-group">
						<label for="inputCity">Company Name</label>
						<input
							type="text"
							class="form-control"
							v-model="addVendor.company_name"
							required
						/>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<button type="button" class="btn btn-secundary" style="margin-right: 10px;background-color:#fff;" @click="cancleVendor">
								<i class="fas fa-times"></i> Cancel
							</button><button type="submit" class="btn btn-success">
								<i class="fas fa-save"></i> Save
							</button>
						</div>
					</div>
				</form>
				<form @submit.prevent="saveAssing" v-if="modal.assing">
					<div
						class="form-group col-12 "
						style="margin: 20px 0px"
					>
						<model-select
							required
							:options="modal.AccountList"
							v-model="form.Account"
							placeholder="Choose a Account"
						>
						</model-select>
					</div>
					<div class="form-group d-flex">
						<div
							class="custom-control custom-radio custom-control-inline"
						>
							<input
								type="radio"
								id="customRadioInline2"
								name=""
								class="custom-control-input"
								value="2"
								v-model="form.typeOption"
							/>
							<label
								class="custom-control-label"
								for="customRadioInline2"
								>Subcontrator</label
							>
						</div>
						<div
							class="custom-control custom-radio custom-control-inline"
						>
							<input
								type="radio"
								id="customRadioInline1"
								checked
								name=""
								class="custom-control-input"
								value="1"
								v-model="form.typeOption"
							/>
							<label
								class="custom-control-label"
								for="customRadioInline1"
							>
								Vendor</label
							>
						</div>
					</div>
					<div
						class="form-group col-12 vendorList"
						v-if="form.typeOption == 1"
					>
						<span style="position: absolute; right: 5px; top: -21px">
							<button
								type="button"
								class="btnlink"
								@click="addVendorNew"
							>
								<i class="fas fa-plus"></i> New Vendor
							</button>
						</span>
						<model-select
							required
							:options="VendorList"
							v-model="form.Vendor"
							placeholder="Choose a Vendor"
						>
						</model-select>
					</div>
					<div
						class="form-group col-12 vendorList"
						v-if="form.typeOption == 2"
					>
						<!-- <span style="position: absolute; right: 5px; top: -21px;">
							<button type="button" class="btnlink" @click="addVendorNew">
								<i class="fas fa-plus"></i> New Subcontrator
							</button>
						</span> -->
						<model-select
							required
							:options="SubcontractorList"
							v-model="form.subcontractor_id"
							placeholder="Choose a Subcontrator"
						>
						</model-select>
					</div>
					<div class="form-group col-12" v-if="masivo==0">
						<label for="inputCity">Date</label>
						<datetime
							input-class="form-control"
							required
							v-model="inputFechaEdit"
							disabled
						></datetime>
					</div>
					<div class="form-group col-12" v-if="masivo==0">
						<label for="inputCity">Amount</label>
						<input
							type="text"
							class="form-control"
							required
							v-model="form.amount"
							disabled
						/>
					</div>
					<div class="form-group col-12" v-if="masivo==0">
						<label class="control-label">
							<span class="text-dashed-underline"
								>Description
							</span>
						</label>
						<textarea
							class="form-control"
							required
							v-model="form.description"
						>
						</textarea>
					</div>
					<div class="col-12 text-right">
						<button type="submit" class="btn btn-success">
							<i class="fas fa-save"></i> Save
						</button>
						<button
							type="button"
							class="btn btn-danger"
							@click="delUnacceptedExpense(form.id)"
							style="margin-left: 10px"
							v-if="masivo==0"
						>
							<i class="fas fa-times"></i> Delete
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { ModelSelect } from "vue-search-select";
export default {
	components: {
		ModelSelect
	},
	name: "assignExpense",
	data () {
		return {
			isDisabled: false,
			addVendor: { company_name: null },	
            form: {
                id: 0,
                Vendor: 0,
                subcontractor_id: 0,
                Account: 0,
                description: null,
                amount: 0,
                date: new Date().toISOString().slice(0, 10),
                typeOption: 1,
            },
            modal: {
                title: "",
                vendor: false,
                assing: false,
                isLoading: false,
				AccountList: [],
            },	
			VendorList: [],
			SubcontractorList: [],
            inputFechaEdit: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            masivo: 0,
		}
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            this.modal = g.data;
            // console.log('modal AccountList', this.modal.AccountList)
            this.form = this.modal.f;
            this.masivo = this.modal.f.masivo;
            if(this.masivo==0){
            	this.inputFechaEdit = this.modal.f.date;
            }
            this.getVendors();
            this.getSubcontractors();
        },

        saveAssing() {
            this.modal.isLoading = true;
            this.modal.assing = false;
            this.$store.commit("core/setLoadingModal", true);
            if (this.form.Vendor === 0 && this.form.subcontractor_id === 0) {
                this.$notify({
                    group: "noti",
                    title: "Error",
                    type: "error",
                    text: "Vendor or Subcontractor is required.",
                });
                this.modal.isLoading = false;
                this.modal.assing = true;
                this.$store.commit("core/setLoadingModal", false);
            } else {
                window.billing.post("banking/assing/" + window.localuserdata, {
                    	Vendor: this.form.Vendor,
                    	subcontractor_id: this.form.subcontractor_id,
						Account: this.form.Account,
						id: this.form.id,
						type: this.form.typeOption,
						typeOption: this.form.typeOption,
						description: this.form.description,
						amount: this.form.amount,
						masivo: this.masivo,
                    })
                    .then((response) => {
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created successfully",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});
                    })
                    .catch((error) => {
                    	console.log(error);
                    	this.$store.commit("core/setLoadingModal", false);
                    	this.$store.commit("core/setCloseModal", {});
                    	this.$notify({
		                    group: "noti",
		                    title: "Error",
		                    type: "error",
		                    text: "saved.",
		                });
                    });
            }
        },
        delUnacceptedExpense(id) {
            let selected = id;
            this.$store.commit('ModApps/billing/setDelItemSelected', selected);
        },
        formatearFechaEdit() {
            this.inputFechaEdit = new Date(
                (new Date(this.inputFechaEdit).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        getSubcontractors() {
            var self = this;
            window.billing
                .get("employees/" + window.localuserdata)
                .then((response) => {
                    console.log("sub", response.data);
                    if (response.data.success) {
                        let d = response.data.result;
                        let data = [];
                        for (let i in d) {
                            data.push({
                                value: d[i].id,
                                text: d[i].firstname + " " + d[i].lastname,
                            });
                        }
                        this.SubcontractorList = data;
                    }
                })
                .catch((error) => console.log(error));
        },
        getVendors(load) {
            var t = this;
            this.isLoading = true;
            window.billing
                .get("vendors/" + window.localuserdata)
                .then((response) => {
                    if (response.data.success) {
                        let Vendor = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].company_name;
                            let n = response.data.result[item].firstname;
                            if (n !== null && n !== "") {
                                t = t + " - " + n;
                            }
                            Vendor.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        t.VendorList = Vendor;
                    }
                    t.isLoading = false;
					if(load==1){
						this.$store.commit("core/setLoadingModal", false);
					}
                })
                .catch((error) => console.log(error));
        },
        addVendorNew() {
            this.modal.title = "Add Vendor";
            this.modal.assing = false;
            this.modal.vendor = true;
            this.addVendor.company_name = null;
        },
        cancleVendor() {
            this.modal.title = "Assign Expense";
            this.modal.assing = true;
            this.modal.vendor = false;
        },
        saveVendor() {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post("expenses/vendor/add", {
                    user_id: window.localuserdata,
                    company_name: this.addVendor.company_name,
                })
                .then((response) => {
					this.form.Vendor = response.data.id;
					this.getVendors(1);
					this.cancleVendor();
					//this.$store.commit("core/setCloseModal", {action: 'saved', expense: true, form: this.form.Vendor});
                })
                .catch((error) => {
					this.$store.commit("core/setLoadingModal", false);
					console.log(error)
				});
        },						                      
    },
    watch: {
        inputFechaEdit: function () {
            this.formatearFechaEdit();
            this.modal.f.date = this.inputFechaEdit;
        },
    }
};
</script>
<style lang="css" scoped>
.vendorList{

}
</style>
