var suf = "projects/";
export default {
	get(idx){
        let urls = [
            suf+'getAll',
            suf+'getByStatus',
            suf+'getById',
            suf+'getBySlugUrl',
            suf+'getWithFeatures',
            suf+'getWithVideo',
            suf+'getWithGallery',
            suf+'getWithMap',
            suf+'ifExistsSlug',
        ]
		return urls[idx];
	},
	set(idx){
        let urls = [
            suf+'createProject',
            suf+'editProject',
            suf+'editCover',
            suf+'setOptions',
            suf+'setGallery',
            suf+'deleteProject',
        ]
		return urls[idx];
	},
};
