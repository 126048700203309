/*rutas de Reports*/
import BillingReport from '@/apps/billing/views/Report';
import ReportCustomer from '@/apps/billing/views/report/Customer';
import ReportInvoice from '@/apps/billing/views/report/Invoices';
import ReportTaxes from '@/apps/billing/views/report/Taxes';
import ReportEstimates from '@/apps/billing/views/report/Estimates';
import ReportPaymentsReceived from '@/apps/billing/views/report/PaymentsReceived';
import ReportRecurringInvoices from '@/apps/billing/views/report/RecurringInvoices';
import ProfitandLoss from '@/apps/billing/views/report/ProfitandLoss';
import ExpensesbyAccount from '@/apps/billing/views/report/ExpensesbyAccount';
import ExpensesReport from '@/apps/billing/views/report/ExpensesReport';
import SubcontractorReport from '@/apps/billing/views/report/SubcontractorReport';
import VendorsReport from '@/apps/billing/views/report/VendorsReport';
import accountTypeInvoice from '@/apps/billing/views/report/accountTypeInvoice';
export default [
	{
		path: 'reports',
		name: 'BillingReport',
		folder: BillingReport,
	},
  {
    path: 'report/customers',
    name: 'ReportCustomer',
		folder: ReportCustomer,
  },
  {
    path: 'report/invoices',
    name: 'Reportinvoices',
		folder: ReportInvoice,
  },
  {
    path: 'report/taxes',
    name: 'ReportTaxes',
		folder: ReportTaxes,
  },
  {
    path: 'report/estimates',
    name: 'ReportEstimates',
		folder: ReportEstimates,
  },
  {
    path: 'report/payments-received',
    name: 'ReportPaymentsReceived',
		folder: ReportPaymentsReceived,
  },
  {
    path: 'report/recurring-invoices',
    name: 'ReportRecurringInvoices',
		folder: ReportRecurringInvoices,
  },
  {
    path: 'report/profit-loss',
    name: 'ProfitandLoss',
		folder: ProfitandLoss,
  },
  {
    path: 'report/expenses-by-account',
    name: 'ExpensesbyAccount',
		folder: ExpensesbyAccount,
  },
  {
    path: 'report/expenses',
    name: 'ExpensesReport',
		folder: ExpensesReport,
  },
  {
    path: 'report/subcontractor',
    name: 'SubcontractorReport',
		folder: SubcontractorReport,
  },
  {
    path: 'report/vendors',
    name: 'VendorsReport',
		folder: VendorsReport,
  },
  {
    path: 'report/account-type-invoice',
    name: 'accountTypeInvoice',
    folder: accountTypeInvoice,
  }
];
