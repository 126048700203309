let addAds = function() {
	let n = [{
		id: 0,
		user_id: window.master_client,
		org_id :window.localorgdata,
		name: 'Btn new',
		tlf: '(555) 555-5555',
		link: 'tel:5555555555',
		status: 0,
		new: true,
	}];
	let add = n.concat(this.dataT);
	this.dataT = add;
	let t =this;
	t.savedAdd = true;
	// console.log('savedAdd', t.savedAdd)
	window.master.post(t.apis.add(), n[0])
	.then((response) => {
		if(response.data.status) {
			for (let i in t.dataT) {
				if(t.dataT[i] && t.dataT[i].id == 0) {
					t.dataT[i].id = response.data.r.id;
					t.dataT[i].new = false;
				}
			}
			t.dataTOLD = t.fillInData(t.dataT);
			// console.log('savedAdd', t.savedAdd)
		}
		t.savedAdd = false;
		t.selectAll=false;
	})
	.catch((error) => {
		console.log(error);
		t.savedAdd = false;
	});
};
export default addAds;
