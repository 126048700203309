// recorrer las rutas
const requireRutas = require.context('.', true, /\.js$/)
export default ({
  validRutas: function (ruta) {
    let nameFolder = '';
    let namesImport = '';
    if (Object.keys(ruta).length > 0) {
      Object.keys(ruta.router).forEach(function (itemRuta, idx) {
        if (itemRuta === 'name') {
          nameFolder = ruta.router[itemRuta]; 
          namesImport = ruta.router[itemRuta]; 
        } 
        if (itemRuta !== 'namespaced' && itemRuta !== 'name') {
          let path = nameFolder + '/router/' + itemRuta;
          console.log('namesImport', namesImport, 'itemRuta', path);
          // Vue.component('' + namesImport + '', path);
          // import { namesImport } from '/@' + path;
          namesImport = requireRutas('../' + path).default
          let nuevaRuta = namesImport;
          console.log('nuevaRuta', nuevaRuta);
        }
      });
    }
  },
  route: function (path, folder, name, meta, children) {
    // debugger
    // console.log('path', path, 'folder', folder, 'name', name, 'meta', meta, 'children', children);
    return {
      name: name,
      path,
      meta,
      component: folder,
      children: (children !== undefined) ? children.map(r => this.childRoute(r.path, r.folder, r.name)) : []
    } 
  },
  childRoute: function (path, folder, name) {
    //console.log('childRoute path', path, 'name', name, 'folder', folder);
    return {
      name: name,
      path,
      component: folder
    }
  }
})
