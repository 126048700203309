<template>
	<div v-show="listAvailableApps!=null && listAvailableApps.length>0">
		<div class="title_app_home" style="margin: 30px 0px  10px 0px !important;">
            Available Apps<br>
            <small style="font-size: 12px;">Its time to INSTALL your prefered App! Just click on install below.</small>
        </div>
        <div class="main_apps_home install">
			<card-app @clickAction="AssignApp($event)" typeEnter="1" :items="listAvailableApps"></card-app>
			<!-- <template v-for="(i, index) in listAvailableApps">
				<div class="item_home install" v-bind:key="index" v-bind:ref="i" @click="AssignApp(i)" v-if="i.visible==0">
					<img class="iconapp" alt="billing" v-if="i.id==1" src="@/core/assets/img/icon_billing.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="sites" v-else-if="i.id==2" src="@/core/assets/img/icon_sites.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="project" v-else-if="i.id==3" src="@/core/assets/img/icon_project.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="jobs" v-else-if="i.id==4" src="@/core/assets/img/icon_jobs.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="calculator" v-else-if="i.id==5"  src="@/core/assets/img/icon_calculator.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="slabs" v-else-if="i.id==7"  src="@/core/assets/img/icon_slabs.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="marketea" v-else-if="i.id==8"  src="@/core/assets/img/icon_bsite_white.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="display" v-else-if="i.id==10"  src="@/core/assets/img/icon_display.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="dimenu" v-else-if="i.id==11"  src="@/core/assets/img/icon_dimenu.svg">
					<img class="iconapp" v-bind:class="{disabled: OrgPlan}" alt="Marketea" v-else src="@/core/assets/img/icon_auxiliar.svg">
					<span v-if="i.id==8" class="bsite" style="text-transform: initial;">bSite</span>
					<span v-else>{{ i.name }}</span>
					<div class="btnapps">
						<button v-if="emailVerify" class="btn btn-success btn-sm btn_custom2" >INSTALL</button>
					</div>
				</div>
			</template> -->
        </div>
        <modalLateral v-show="m.component=='InstallApps'">
			<InstallApps :dataApp="dataApp"></InstallApps>
        </modalLateral>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import cardApp from "@/core/components/Home/cardApp";
import InstallApps from "@/core/components/Home/InstallApps";
import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
export default {
	name: 'AppsAvailable',
	components: {
		'card-app': cardApp,
		InstallApps,
		modalLateral
	},
	data () {
		return {
			org_id: window.localorgdata,
			listAvailableApps: [],
			name: null,
			app_id : null,
			description : null,
			iconapp : null,
			dataApp: {},
			OrgPlan : (window.OrgPlan > 1),
			m: {
				show: false,
				component: '',
				data: []
			}
		}
	},
	created () {
        this.OrgPlan = (this.$store.getters['core/Org/getPlanId'] > 1);
		this.getAppsList();
	},
	methods: {
		getAppsList() {
			this.listAvailableApps = this.$store.getters['core/apps/getListAvailableApps'];
		},
		AssignApp(i) {
			//console.log('click en app bloqueada', i)
			//if(!this.OrgPlan){
			//	this.$notify({group: "notiPushPremium", duration: 100000});
			//}else {
				this.m = {
					show: true,
					component: 'InstallApps',
					data: {
						title: 'INSTALL',
						type: 'apps',
						name: i.name,
						app_id: i.id,
						description: i.description
					}
				};
				this.dataApp = this.m.data;
				// this.$store.commit("core/setOpenModal", this.m);
				//si es jobs y so no es premium
				if(i.id==4 && window.OrgPlan != 2){
					let msj = 'Upgrade to Smart office premium in order to use “Jobs”. ';
					msj+='Contact support@marketea.com or Call 774-287-3353.'
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '5', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'This App is available just for Premium!',
						text: msj,
						duration: 15000
					});
					this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
				}
				else if(i.id==1){
					this.$store.commit("core/setOpenModal", this.m);
				}
				else if(i.id==8){
					let appData = {
						app_id: i.id,
						show: true,
						infoApp: {
							app_id: i.id,
							name: i.name,
							description: i.description
						},
						planes: [],
					}
					this.$store.commit("core/set_showSuscription", appData);
				}
			//}
		},
	},
	watch: {
		'appsorg': function() {
			this.getAppsList();
		},
        getPlanId: function () {
            this.OrgPlan = (this.getPlanId > 1);
        },
	},
	computed: {
		...mapGetters('core/apps', {appsorg: 'applistorgs'}),
		...mapGetters('core/apps', {organizationslist : 'applistorgs'}),
		...mapGetters('core/session', {emailVerify : 'getEmailVerify'}),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
	},
};
</script>
<style lang="css" scoped>
	.main_apps_home .item_home img.iconapp.disabled,
	.main_apps_home .item_home:hover img.iconapp.disabled{
		opacity: 0.4 !important
	}
</style>
