var RoofCal = "/roofcal/";
export default {  
	get(){
		return RoofCal+"getList";
	},
	status(){
		return RoofCal+"changeStatus";
	},
	edit(){
		return RoofCal+"updateAds";
	},
	del(){
		return RoofCal+"delAds";
	},
};
