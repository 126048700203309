let closeSubmenu = function(id) {	
	let t = this;
	setTimeout(function(){
		let submenus = document.querySelector('input.submenu-'+id);
		if(submenus && submenus.checked){
			submenus.checked = false;
			console.log('closeSubmenu', submenus.classList[1], submenus.checked)
		}	
		for (let i in t.InvoicesListResult) {
			if(t.InvoicesListResult[i]){
				if(t.InvoicesListResult[i].id == id && t.InvoicesListResult[i].actions){
					t.InvoicesList[i].actions = false;
					console.log('closeSubmenu', t.InvoicesList[i])
				}
			}
		}
		t.InvoicesListResult = t.InvoicesList;
	}, 200);
};
export default closeSubmenu;
