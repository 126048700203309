<template>
	<div>
		<div class="page-breadcrumb">
			<div class="row">
				<div class="col-md-8 align-self-center">
					<h4 class="page-title"><i class="fas fa-chart-line"></i> Accounts Type Invoice</h4>
				</div>
				<div class="col-md-4 align-self-center">
					<div class="d-flex align-items-center justify-content-end">
						<router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">	
				<div class="col-md-12">
					<div class="card card-body">		
						<!-- start botoneras ajustados a responsive -->
						<div class="col-md-12">
							<div class="row">
								<div id="divA" class="col-md-3">
									<div class="form-group row">
										<div class="col-md-3">
											<button type="button" class="btn btn-success" @click="acounttypeinvo()" style="width: inherit;min-width: 80px; margin-bottom: 10px;">
												<i class="fas fa-search"></i> Filter
											</button>
										</div>                               
										<div class="col-md-9">
											<select class="form-control" v-model="SelectOne" @change="ChangeSelectPrimary">
												<option value="1">This Year</option>
												<option value="2">Last Year</option>
												<option value="3">This Month</option>
												<option value="4">Calendar</option>
											</select>
										</div>
									</div>
								</div>
								<div id="divS" class="col-md-4" v-if="SelectOne == 4">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<label class="col-sm-3 col-form-label"><b>From:</b></label>
												<div class="col-sm-9">
													<datetime
														type="date"
														v-model="dateFrom"
														format="MMM dd, yyyy"
														input-class="form-control"
														value-zone="America/New_York"
													></datetime>            
												</div>
											</div>                               
										</div>
										<div class="col-md-6">   
											<div class="form-group row">
												<label class="col-sm-3 col-form-label"><b>To:</b></label>
												<div class="col-sm-9">
													<datetime
														type="date"
														v-model="dateTo"
														format="MMM dd, yyyy"
														input-class="form-control"
														value-zone="America/New_York"
													></datetime>           
												</div>                                             
											</div>
										</div>
									</div>
								</div>
								<div id="divB" class="col-md-3">
									<div class="form-group row">
										<div class="col-md-6">
										</div>
										<div class="col-md-6">
										</div>
									</div>
								</div>
								<div id="divC" v-if="SelectOne != 4" class="col-md-6">
									<div style="display: flex; justify-content: flex-end">
										<span class="cdropdown" style="margin-bottom: 10px;">
											<button class="btn btn-transaction">
												Export As
											</button>
											<label>
												<input type="checkbox" />
												<ul class="text-left">
													<li @click="generarFile('pdf')">
														<button><strong>PDF</strong></button>
													</li>
												</ul>
											</label>
										</span>                                           
									</div>
								</div>   
								<div id="divC" v-else class="col-md-2">
									<div style="display: flex; justify-content: flex-end">
										<span class="cdropdown" style="margin-bottom: 10px;">
											<button class="btn btn-transaction">
												Export As
											</button>
											<label>
												<input type="checkbox" />
												<ul class="text-left">
													<li @click="generarFile('pdf')">
														<button><strong>PDF</strong></button>
													</li>
												</ul>
											</label>
										</span>                                       
									</div>
								</div>                                                                        
							</div>
						</div>
						<!-- end botoneras ajustados a responsive -->						
						<div class="col-md-12">
							<div class="table-responsive">
								<table class="table table-striped">
									<thead>
										<tr>
											<th scope="col">Account Type</th>
											<th scope="col">Account Name</th>
											<th scope="col" class="text-right">
												Sub Total
											</th>
											<th scope="col" class="text-right">
												Taxes
											</th>
											<th scope="col" class="text-right">
												Total
											</th>
										</tr>
									</thead>
									<tbody  v-if="isLoading">
										<tr><td class="text-center" colspan="5"><Loader></Loader></td></tr>
									</tbody>
									<tbody v-else-if="!isLoading && !NoRecord">
										<tr>
											<td></td>
											<td></td>
											<td class="text-right">
												<b>${{ subTotal | formatMoney }}</b>
											</td>
											<td class="text-right">
												<b>${{ taxTotal | formatMoney }}</b>
											</td>
											<td class="text-right">
												<b>${{ total | formatMoney}}</b>
											</td>
										</tr>
										<tr v-for="item in AccountLists" :key="item.id">
											<td scope="row">
												{{ item.type }}
											</td>
											<td>
												{{ item.name_account }}
											</td>
											<td class="text-right">
												${{ item.subTotalProd | formatMoney}}
											</td>
											<td class="text-right">
												${{ item.taxProd | formatMoney}}
											</td>
											<td class="text-right">
												${{ item.totalProd | formatMoney}}
											</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td class="text-right">
												<b>${{ subTotal | formatMoney }}</b>
											</td>
											<td class="text-right">
												<b>${{ taxTotal | formatMoney }}</b>
											</td>
											<td class="text-right">
												<b>${{ total | formatMoney}}</b>
											</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr><td class="text-center" colspan="5">No record</td></tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
// import { ModelSelect } from "vue-search-select";
export default {
	name: 'accountTypeInvoice',
	components: {
		Loader,
		// ModelSelect
	},
	data () {
		return {
			isLoading: false,
			AccountLists: [],
			TextSearch: "",
			NoRecord: false,
			typeFilter: 1,
			date: '2021',
			date2: '2021',
            account_id: "",
            Accounts: [],
			name: '',
			statusdata: [
				{ value: "1", name: "Pending" },
				{ value: "2", name: "Partially Paid" },
				{ value: "3", name: "Paid" },
				{ value: "4", name: "Overdue" },
				{ value: "5", name: "Void" },
			],
			statuslist: [1, 2, 3, 4, 5],
			SelectOne: 1,
			dateFrom: new Date(
				(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
				).toISOString(),
			dateTo: new Date(
				(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
				).toISOString(),            
			statusdropd: false,
			taxeCheck: false,
			isCheckAll: true,
		}
	},
	created() {
		this.acounttypeinvo();
	},
    mounted() {
        this.setTimeoutResponsive()
    },	
	methods: {		
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            let divS = document.getElementById("divS");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }  
            } 
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                              	
            }
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
        },
        ChangeSelectPrimary() {
            this.setTimeoutResponsive()
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        }, 		
		acounttypeinvo() {
			this.isLoading = true;
			let t = this;
			window.billing.post("report/acounttypeinvo", {
				org_id: window.localorgdata,
				typeFilter: this.typeFilter,
				date: this.SelectOne,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				account_id: this.account_id,
				idxstatus: this.statuslist,
				name: this.name,
			})
			.then((response) => {
				t.AccountLists = response.data.r.list;
				if(t.AccountLists.length == 0) {
					t.NoRecord = true;
				}
				t.isLoading = false;
				if(t.Accounts.length == 0) {
					// t.getchartaccount();
				}
			})
			.catch((error) => console.log(error));
		},		
		getchartaccount() {
			window.billing.post("report/accountincome", { 
				org_id: window.localorgdata
			}).then((response) => {
				let r = response.data.r;
				if (r.length > 0) {
					let AccountList = [{ value: "", text: "Alls Accounts" }];
					for (let item in r) {
						let t = r[item].name_account;
						AccountList.push({
							value: r[item].id,
							text: t,
						});
					}
					this.Accounts = AccountList;
				}
			})
			.catch((error) => console.log(error));
		},
		generarFile(type) {
            let user_id = window.localorgdata;
            let date = this.SelectOne;
            let idxstatus = this.statuslist;
            let uri = "report/accountsinvo/pdf/" + user_id + "/" + date;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
	},
	/*watch: {
		"account_id": function() {
			let self = this;
			if(this.account_id == '') {
				this.filterItems = this.AccountLists
			}
			else if(this.account_id > 0) {
				for (let i in this.AccountLists) {
					if (this.AccountLists[i]) {
						if (this.AccountLists[i].account_id == this.account_id) {
							this.filterItems = this.AccountLists[i];
						}
					}
				}
			}
			else {
				this.filterItems = [];
			}
		}
	},*/
	computed: {
		total: function () {
			let t = 0;
			let list = this.AccountLists.filter(function (item) {
				t+= parseFloat(item.totalProd);
			});
			return t;
		},
		taxTotal: function () {
			let t = 0;
			let list = this.AccountLists.filter(function (item) {
				t+= parseFloat(item.taxProd);
			});
			return t;
		},
		subTotal: function () {
			let t = 0;
			let list = this.AccountLists.filter(function (item) {
				t+= parseFloat(item.subTotalProd);
			});
			return t;
		},
		filterItems: function () {
			let self = this;
			return this.AccountLists.filter(function (item) {
				if(item.account_id == self.account_id) {
					return item;
				} else {
					return [];
				}
			});
		},
	}
};
</script>
<style lang="css" scoped>
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
