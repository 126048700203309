<template>
    <div>
        <div class="card-header window-header">
            <div class="col-12 d-flex justify-content-start">
                <h3>Organization Setting</h3>
                <button type="button"
                    class="btn btn-success btn-sm"
                    style="height: 30px;margin-left: 10px; padding: 2px 5px;line-height: 1;"
                    @click="UpdatePrefijo()"
                >
                        Save
                </button>
            </div>
        </div>
        <div v-if="isLoading" class="window-body">
            <Loader></Loader>
        </div>
        <form @submit.prevent="UpdatePrefijo" id="form1" v-else class="window-body">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs">
                            <li class="nav-item" v-for="(i, idx) in tab" :key="'item'+idx">
                                <a href="#" @click.prevent="choose_tab(i)"
                                    :class="['nav-link', (tabSelected==i.val) ? 'active' : '']"
                                >
                                    {{ i.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row marginTop" v-if="tabSelected==1">
                    <div class="col-md-12">
                        <label class="col-form-label ">Company name:</label>
                        <input type="text" class="form-control" id="" v-model="company_name" required />
                    </div>
                    <div class="col-md-6">
                        <label class="col-form-label">Telephone</label>
                        <input type="text" class="form-control" v-model="org.phone" />
                    </div>
                    <div class="col-md-6">
                        <label class="col-form-label">E-mail</label>
                        <input type="text" class="form-control" v-model="org.email" />
                    </div>
                    <div class="col-md-12">
                        <label class="col-form-label">Address</label>
                        <input type="text" class="form-control" v-model="org.address" />
                    </div>
                    <div class="col-md-4 ">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control" v-model="org.city" />
                    </div>
                    <div class="col-md-4 ">
                        <label class="col-form-label">State</label>
                        <input type="text" class="form-control" v-model="org.state" />
                    </div>
                    <div class="col-md-4 ">
                        <label class="col-form-label">Zip Code</label>
                        <input type="text" class="form-control" v-model="org.zip" />
                    </div>
                    <div class="col-md-12">
                        <label class="col-form-label">Website</label>
                        <input type="text" class="form-control" v-model="org.website" />
                    </div>
                </div>
                <div class="row marginTop" v-if="tabSelected==2">
                    <div class="col-md-12">
                        <label class="col-form-label">Organization Url:</label>
                        <div class="input-group">
                            <span class="input-group-text" 
                                style="font-size: 14px;padding-right: 0px;border-right: 0px;"
                            >{{urlweb}}</span>
                            <input type="text" class="form-control"
                                v-model="slug_url" 
                                @change="validSlug"
                                style="padding-left: 0;border-left: 0;"
                                required
                            />
                        </div>
                        <div class="invalid-feedback" :style="'display:' + (isExistSlug ? 'block' : 'none') + ';'">
                            Url exists.
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit"
                    class="btn btn-success btn-lg"
                    style="padding: 10px 35px; border-radius: 20px;"
                    :disabled="isExistSlug">Save</button>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "orgUrl",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: false,
            apis: null,
            company_name: null,
            slug_url: null,
            slug_url_old: null,
            urlweb : window.urlbSite,
            tab:[{
                name:'Info',
                val:'1',
            },
            {
                name:'Url',
                val:'2',
            }],
            tabSelected: '1',
            premiumProfile: false,
            planActual: 1,
            org: {
                company_name:'',
                address:'',
                city:'',
                state:'',
                zip:'',
                phone:'',
                email:'',
                website:'',
            },
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        this.pictures = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        if(this.pictures!=null && this.pictures.cover_v_active){
            this.tabSelected='2';
            console.log('tabSelected', this.tabSelected)
        }
        if(localStorage.getItem('edirUrl')!=null && localStorage.getItem('edirUrl')==1){
            this.tabSelected=2;
        }
        this.getPrefijoUrl();
        this.getOrg();
    },
    watch: {
        'slug_url': function () {
            this.validSlug();
        },
        'company_name': function () {
            this.crearLink();
        },
    },
    methods: {
        choose_tab(i){
            let t = this;
            // if(i.val==2 && t.premiumProfile){
            //     t.tabSelected=i.val;
            // }
            // else if(i.val==2 && !t.premiumProfile){
            //     t.showPremium();
            // }
            // else{
            //     t.tabSelected=i.val;
            // }
            t.tabSelected=i.val;
        },
        getOrg() {
            let t = this;
            let getOrg = this.$store.getters['core/Org/orginf'];
            if (getOrg != null) {
                console.log('getters FormInfo', getOrg);
                t.company_name = getOrg.com_company_name;
                t.org.company_name = t.company_name;
                t.org.address = getOrg.com_address;
                t.org.city = getOrg.com_city;
                t.org.state = getOrg.com_state;
                t.org.zip = getOrg.com_zip_code;
                t.org.phone = getOrg.com_telephone;
                t.org.email = getOrg.com_email;
                t.org.website = getOrg.com_website;
            }
        },
        getPrefijoUrl() {
            let t = this;
            t.isLoading = true;
            let url = t.apis.pull(6);
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                pusher_id: pusher.connection.socket_id
            })
                .then((response) => {
                    console.log(url, response.data);
                    if (response.data.status) {
                        t.slug_url = response.data.r.meta_value;
                        t.slug_url_old = response.data.r.meta_value;
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
        },
        crearLink(noValid) {
            let t = this;
            let slug = t.slugUrl(t.company_name);
            t.slug_url = slug;
            if (noValid == undefined) {
                t.validSlug();
            }
        },
        slugUrl(slug) {
            let str = slug;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();
            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
            return str;
        },
        validSlug() {
            let t = this;
            let url = t.apis.pull(5);
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                slug_url: t.slug_url,
                pusher_id: pusher.connection.socket_id
            })
            .then((response) => {
                if (response.data.valid > 1) {
                    t.isExistSlug = true;
                } else {
                    t.isExistSlug = false;
                }
            }).catch((error) => {
                console.log(error);
                t.isExistSlug = false;
            });
        },
        UpdatePrefijo(){
            let t = this;
            let url = t.apis.set(2);
            if(!t.isExistSlug){
                t.isLoading = true;
                window.master.post(url, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    company_name: t.company_name,
                    address: t.org.address,
                    city: t.org.city,
                    state: t.org.state,
                    zip: t.org.zip,
                    phone: t.org.phone,
                    email: t.org.email,
                    website: t.org.website,
                    slug_url: t.slug_url,
                    slug_url_old: t.slug_url_old,
                    pusher_id: pusher.connection.socket_id
                })
                .then((response) => {
                    console.log(url, response.data);
                    if(response.data.status){
                        if(response.data.org_update){
                            localStorage.setItem("nomCompany", t.company_name)
                            localStorage.removeItem('getDataSession');
                            t.$store
                            .dispatch("core/session/getDataSessionLogin")
                            .then((response) => {
                                // console.log('getDataSession', response);
                                t.$store.commit("core/SET_HEADER", true);
                            })
                            .catch((error) => {
                                console.log("getDataSession", error);
                                // t.$store.commit("set_isLoading", false);
                                t.$store.commit("core/SET_HEADER", false);
                            });
                        }
                        t.$parent.close()
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
            }
        },
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;}
.marginTop{margin-top: 20px;}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #4F5467;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.window-body{
    height: calc(100vh - 45px);
    height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 3em;
    width: 100%;
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .row{
        width: 100%;
    }
    .card-footer.d-flex.justify-content-center {
        position: absolute;
        width: 100%;
        height: 73px;
        bottom: 5px;
        left: 0px;
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
</style>
