<template>
    <div>
        <div class="row">
            <div class="page-breadcrumb d-flex justify-content-between">
                <h2 class="page-title"><i class="fas fa-tasks"></i> Dashboard Slabs</h2>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Slabs",
    data() {
        return {
            isLoading: false,
        };
    },
};
</script>
