<template>
	<div class="card-header" style="padding: 0.25rem 1.25rem; display: flex;">
		<h3 style="margin-bottom: 0px;" class="title">
			<strong  style="font-size: 32px;"> {{ title }}</strong>
		</h3>
		<!-- <a href="#" class="btn btnadd" @click.prevent="$parent.newProject()">+ Add Project</a> -->
		<form class="form-inline" @submit.prevent="searchSales">
		  <div class="input-group">
		    <input type="text" class="form-control" id="search" placeholder="Search projects">
		    <div class="input-group-prepend">
		      <button type="submit" class="btn "><i class="fas fa-search"></i></button>
		    </div>
			</div>
		  <div class="input-group" style="margin-left:10px;">
			<label for="uncompleted" style="margin-right:10px;">Trash</label>
			  <div class="onoffswitch">
				<input
					type="checkbox"
					class="onoffswitch-checkbox"
					id="uncompleted"
					v-model="$parent.uncompleted"
					:value="8"
					@change="$parent.uncompletedActive()"
				/>
				<label class="onoffswitch-label" for="uncompleted" >
					<span class="onoffswitch-inner"></span>
					<span class="onoffswitch-switch"></span>
				</label>
			</div>
		  </div>
		</form>
	</div>
</template>
<script>
export default {
	name: 'title-btn-search',
	props: {
		stype: {
			default: 'retail'
		}
	},
	data () {
		return {
			title: this.stype =='retail' ? 'Sales for Retail' : 'Sales for Insurance Claims'
		}
	},
	methods: {
		searchSales(){return false;}
	},
}
</script>
<style lang="css" scoped>
	.form-inline{
		margin-left: 20px;
	}
	.title{color: #031099;}
	a.btn.btnadd {
		margin-left: 20px;
	    background-color: #031099;
	    color:#fff;
	    border-radius: 24px;
	    padding: 10px 20px;
		font-weight: bold;
	}
	.btnsimple{
	    border: 1px solid rgb(0 0 0 / 25%);
	    padding: 3px 5px;
	}
	.btnsimple:hover{
		cursor: pointer;
	}
	input#search {
	    min-width: 300px;
	}
	/*checkbox*/

.onoffswitch {
    position: relative;
    width: 65px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #057121;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "Show";
    padding-right: 10px;
    padding-left: 5px;
    background-color: #04b653;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Hide";
    padding-right: 10px;
    background-color: #eeeeee;
    color: #222;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #ffffff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 38px;
    border: 1px solid #057121;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
</style>
