<template>
	<div v-bind:class="['barTools', navEditor ? 'noneBar' : 'show']">
    <div class="navtools">    
      <div class="btn-group-vertical" role="group" aria-label="Vertical button group">
        <button type="button" class="btn btn-outline-secondary active">
          <span class="iconMenu"><i class="fas fa-tachometer-alt"></i></span>
          <span>Dashboard</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="far fa-dot-circle"></i></span>
          <span>Leads</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="fas fa-comments"></i></span>
          <span>Reviews</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="fas fa-briefcase"></i></span>
          <span>GMB</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="fas fa-photo-video"></i></span>
          <span>Gallery</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="far fa-newspaper"></i></span>
          <span>Pages</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="fas fa-mail-bulk"></i></span>
          <span>Email</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
          <span class="iconMenu"><i class="fas fa-search-dollar"></i></span>
          <span>SEO</span>
        </button>
        <button type="button" class="btn btn-outline-secondary">
            <span class="iconMenu"><i class="fas fa-cogs"></i></span>
            <span>Setting</span>
        </button>
        <!-- <div class="btn-group" role="group">
          <button  type="button" class="btn btn-outline-secondary dropdown-toggle" @click="showSetting=!showSetting">
            <span class="iconMenu"><i class="fas fa-cogs"></i></span>
            <span>Setting</span>
          </button>
          <ul v-bind:class="['dropdown-menu', showSetting ? 'show': '']" style="">
            <li><a class="dropdown-item" href="#">Setting option</a></li>
            <li><a class="dropdown-item" href="#">Setting option</a></li>
          </ul>
        </div> -->
      </div>    
    </div>
  </div>
</template>
<script>
export default {
  name: 'barTools',
  data() {
    return {
      navEditor: true,
      showSetting: false,
    }
  },
  created(){
    this.navEditor = this.$parent.navEditor;
  },
  watch: {
    "$parent.navEditor": function () {
      this.navEditor = this.$parent.navEditor;
    }
  }
};
</script>
<style scoped lang="scss">
.barTools{
  width: 100%;
  max-width: 98px;
  height: 0vh;
  background-color: #3a3633;
  position: absolute;
  top: 40px;
  left: 0px;
  transition: 2s all ease-in-out;
}
.barTools.noneBar{display: none !important;}
.barTools.show{
  height: calc(100vh - 80px);
}
.navtools .btn-group-vertical{
  width: 100%;
}
li.nav-item {
  border-right: 0px solid;
  border-top: 0px solid;
  border-bottom: 1px solid;
  border-color: #484545;
}
.navtools .btn-group-vertical button.btn.btn-outline-secondary{
  padding: 5px 7px;
  border-radius: 0px;
  background: transparent;
  border-color: #484545;
  border-top: 0px solid;
  border-bottom: 1px solid #484545;
  border-left: 5px solid #111;
  border-right: 0px solid;
  color: #888;
  margin-bottom: 1px;
  box-shadow: none;
  &.active{
    color: #fff;
    background: #666; 
    border-color: #484545;
    border-left: 5px solid #031099;
    .iconMenu{
      color: #fff;
    }
  }
  &:hover{
    color: #fff;
    background: #666;
    border-color: #484545;
    border-left: 5px solid #031099;
    .iconMenu{
      color: #fff;
    }
  }
  .iconMenu{
    display: block;
    font-size: 18px;
    color:#888;
  }
}
</style>
