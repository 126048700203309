let actionPay = function(id, numdoc, total, customer_id, cname, clname, cbname, balance, item) {
	this.closeSubmenu(id);
	this.$store.commit("core/setCloseModal", {});
	this.m = {};
	this.form.id = id;
	this.form.method_id = 12;
	this.form.invoice_id = id;
	this.form.numdoc = numdoc;
	this.form.price = null;
	this.form.customer_id = customer_id;            
	this.totalinvoice = balance;
	this.previewcustomername = cname;
	this.previewcustomerlastname = clname;
	this.previewcustomerbusinessname = cbname;
	this.previewtotal = total;
	this.previewbalance = balance;
	let t = this;
	this.m = {
		show: true,
		bill: true,
		action: 'actionPay',
		component: 'formPayment',
		data: {
			item:item,
			form:t.form, 
			totalinvoice:t.totalinvoice,
			previewcustomername:t.previewcustomername,
			previewcustomerlastname:t.previewcustomerlastname,
			previewcustomerbusinessname:t.previewcustomerbusinessname,
			previewtotal:t.previewtotal,
			previewbalance:t.previewbalance,
			PaymentMethodsList:t.PaymentMethodsList
		}
	};
	this.$store.commit('core/setOpenModal', this.m);
};
export default actionPay;
