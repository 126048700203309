<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full" data-boxed-layout="full">
        <div class="page-wrapper homepage">
            <slot>
                <div class="bgloading" v-if="isLoading"><Loader></Loader></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 hidden-xs hidden-sm">
                            <infoApp></infoApp>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 formloginhome">
                            <formActivation></formActivation>
                        </div>
                    </div>
                </div>
            </slot> 
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/Loader'
    import formActivation from '@/core/components/Login/formActivation'
    import infoApp from '@/core/components/Login/infoApp'
    import { mapGetters } from 'vuex'
    export default {
        name: 'LoginLayout',
        components: {
            Loader,
            infoApp,
            formActivation
        },
        data () {
            return {
                isLoading: false,
                urlPage: window.urlweb
            }
    },
    created () {
        this.$store.commit('set_isLoading', false);
        // this.$store.dispatch('core/demo')
        this.validSesion()
    },
    methods: {
        validSesion(){
            this.isLoading= true;
            this.$store.dispatch('core/session/validToken')
            .then(response => {
                //console.log(response.data);
                let r = response.data;
                console.log('validToken login ', r);
                if(r.tokenValid)
                {
                    this.$store.dispatch('core/session/SET_EMAIL_VERIFY', r.emailVerify);
                    this.loginExterno();
                }
                else
                {
                    console.log('no hay entrada al sistema ', r.tokenValid);
                    this.isLoading= false;
                    // debugger;
                    this.$store.commit('core/session/destroyToken');
                    if(!localStorage.getItem('access_web')){localStorage.setItem('access_web', 0)}
                }
            });            
        },
        loginExterno()
        {
            let client_Org = localStorage.getItem('mark_client_data');
            let client_M = localStorage.getItem('mark_client_data_m');
            let token_M = localStorage.getItem('access_token_m');
            let oauth_M = localStorage.getItem('oauth_m');
            this.isLoading = true;
            if(oauth_M != null && client_Org !=null && client_M != null && token_M != null)
            {
                let t = this;
                this.$store.dispatch('core/session/retrieveToken', {validM: true})
                .then(response => {
                    console.log('sesion master ', response);
                    //llamar privilegios de la cuenta            
                    t.$store.commit('set_isLoading', true);
                    t.getPrivilegios();
                })
                .catch((error) => {
                    //console.log(error);
                    console('error al iniciar sesion', error);
                    t.logout();
                });
            }
            else
            {
                console.log('no hay variables', oauth_M, client_Org, client_M, token_M);
                this.logout();
            }
        },
        getPrivilegios(){
            let t = this;
            t.$store.dispatch('core/session/privilegies_session').then(response => {
                console.log('privilegies_session', response);
                if(t.$store.getters['core/session/loggedIn'] && response){
                    t.$router.push({ name: 'Home' });
                }else{
                    console.log('sesion corructa loggedIn', t.$store.getters['core/session/loggedIn']);
                    t.logout();
                }
            })
            .catch((error) => {
                //console.log(error);
                console('error privilegies_session', error);
                t.logout();
            });
        },
        logout() {
            this.$store.dispatch('core/session/destroyToken')
            .then(response => {
                console.log('destroyToken', response);
                this.isLoading= false;
            })
            .catch(error => {
                console.log('destroyToken', error);
                this.isLoading= false;
            });
        }
    },
    watch: {
        'get_isLoading': function() {
            this.isLoading = this.get_isLoading;
            if(this.get_isLoading) {
                this.$notify({
                    group: 'noti',
                    type: 'success',
                    title: 'Accessing',
                    text: 'redirecting...'
                });
            }
        }
    },
    computed: {
        ...mapGetters({get_isLoading: 'get_isLoading'}),
  }
  };
</script>
<style lang="scss" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .container {
        max-width: 1000px;
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        background-color: #f0f2f5;
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 50px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 500px;
        }   
    }
</style>
