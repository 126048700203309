<template>
	<div class="row">
		<div class="col-12"  style="margin-top: 20px;padding-left: 0px; padding-right: 0px;">
			<div class="container-table">
				<table class="table table-bordered table-inverse table-hover">
					<thead>
						<tr>
							<th style="width: 8%;min-width: 80px;max-width: 80px;" class="text-center"><span v-if="retail">Timer/Notes</span><span v-else>Timer/Id</span></th>
							<th style="width: 10%;min-width: 90px;" class="text-center">Media</th>
							<th style="width: 15%;" >Project</th>
							<th style="width: 7%;min-width: 70px;" class="text-center">Sales Rep.</th>
							<th style="width: 10%;min-width: 135px;" v-show="show_SCD">Source/CTA/Date</th>
							<th style="width: 8%;min-width: 110px;" class="text-center">Qualification</th>
							<th v-show="retail" style="width: 10%;min-width: 115px;" class="text-center">Appointment</th>
							<th v-show="retail" style="width: 10%;min-width: 135px;" class="text-center">Estimate</th>
							<th v-show="!retail" style="width: 10%;min-width: 90px;" class="text-center">Inspection</th>
							<th v-show="!retail" style="width: 10%;min-width: 90px;" class="text-center">Contract</th>
							<th v-show="!retail" style="width: 8%;min-width: 80px;" class="text-center">Closing Claim</th>
							<th v-show="retail" style="width: 10%;min-width: 110px;">Closing</th>
							<th v-show="retail=='no'" style="width: 10%;min-width: 90px;" class="text-center claim_accepted_or_reject">Accept./Reject.</th>
							<th v-show="retail" style="width: 10%;min-width: 100px;" class="text-center">Status</th>
							<th v-show="!retail && $parent.indexTabs!=4 && $parent.indexTabs!=5" style="width: 10%;min-width: 100px;" class="text-center">Status</th>
						</tr>
					</thead>
					<tbody v-if="$parent.isLoading">
						<tr><td :colspan="retail ? (show_SCD ? '9' : '9') : (show_SCD ? '9' : '10')" class="text-center"><Loader></Loader></td></tr>
					</tbody>
					<tbody v-else-if="$parent.listSales.length==0">
						<tr><td :colspan="retail ? (show_SCD ? '9' : '9') : (show_SCD ? '9' : '10')" class="text-center">No record.</td></tr>
					</tbody>
					<tbody v-else>
						<tr
							v-for="(i, index) in $parent.listSales" :key="index"
							v-if="$parent.tabsList[$parent.indexTabs].value.includes(i.status.toString()) || $parent.tabsList[$parent.indexTabs].value.includes(i.status_old.toString())"
						>
							<td class="timer_notes_ids" style="width: 8%;min-width: 80px;max-width: 80px;">
								<div class="text-center ">
									<span class="badge badge-light" style="font-size: 12px; margin-bottom:10px;" v-if="i.perfil.lead_id>0">Lead #{{i.perfil.lead_id}}</span>
									<span class="badge badge-light" style="font-size: 12px; margin-bottom:10px;" v-else>Added</span>
									<span class="badge badge-light" style="margin-bottom:10px;"><span style="font-size: 12px;">Sale</span> <span style="font-size: 18px;">#{{i.id}}</span></span>
								</div>
								<div class="diffdays">
									<span class="nrodiff" v-if="i.diff_days<=365">{{i.diff_days}}</span>
									<span class="nrodiff" v-else>+365</span>
									<span class="labeldiff">days ago</span>
								</div>
								<div v-show="retail" @click="$parent.openModal('notes', i)" style="cursor: pointer;" class="text-center showTootlip">
									{{i.notes}}
									<i class="fas fa-comment-alt" v-if="i.notes==0"></i> 
									<i class="fas fa-comment-alt active" v-else></i> 
									<span class="addNotes" style="font-size:10px;">(Add)</span>
									<span class="tooltip">Comments or Notes</span>
								</div>
								<div class="btnsimple" style="font-size: 10px;text-align: center;" @click="$parent.openPop(i, 'Source/CTA/Date')">
									Source/CTA/Date
								</div>
							</td>
							<td class="text-center media" style="padding:0;vertical-align: top;border:0px;padding-top: 5px;">
								<div class="previewImg" v-if="!i.cover" @click="$parent.openModal('gallery', i)">
									<i class="fa fa-image"></i>
								</div>
								<div v-else class="previewImg" @click="$parent.openModal('gallery', i)">
									<img :src="$parent.url+'small/'+i.cover.name" :alt="'cover'+i.id" />
								</div>
								<div class="files_drive justify-content-center text-center" style="margin-top: 10px;" v-show="retail">
									<span :class="['showTootlip', 'filesDrive', (i.link_drive==1) ? 'active' : '']" @click="$parent.openModal('adjuntarLinkFiles', i)" style="font-size: 1.5rem;">
										<i class="fab fa-google-drive"></i>
									</span>
								</div>
							</td>
							<td class="info_profile">
								<div class="profile_info" style="cursor: pointer;" @click="$parent.openModal('profile', i)">
									<div><strong style="color: #031099;">{{i.title}}</strong></div>
									<div>
										<span >{{i.perfil.name}}</span>
										<span v-if="i.perfil.lastname"> {{i.perfil.lastname}}</span>
									</div>
									<div>{{i.perfil.phone}}</div>
									<div>
										<span v-if="i.perfil.address">{{i.perfil.address}}</span>
										<span v-if="i.perfil.city"> {{i.perfil.city}}</span>
										<span v-if="i.perfil.state">, {{i.perfil.state}}</span>
										<span v-if="i.perfil.zip"> {{i.perfil.zip}}</span>
									</div>
								</div>
								<div class="files_drive" v-show="retail">
									<span :class="['filePdf', 'showTootlip', (i.link_pdf==1) ? 'active' : '']" @click="$parent.openModal('adjuntarPdf', i)">
										<i class="fas fa-file-pdf"></i>
										<span class="tooltip">Attach PDF</span>
									</span>
									<span v-show="retail" :class="['btnsimple', 'filesSOW', 'showTootlip', (typeof i.sow ==='string' && i.sow!='null' && i.sow.length>0) ? 'active' : '']" @click="$parent.openModal('scopeOfWork', i)">
										Scope of Work 
										<!-- <i class="fas fa-file-alt"></i> -->
										<span class="tooltip">SOW</span>
									</span>
								</div>
							</td>
							<td class="text-center seller">
								<div class="sellerAssign" @click="$parent.openModal('sellerAssign', i)">
									<div class="label-assign" v-if="i.seller_ids && i.seller">
										<div  :class="['avatarUser', (i.seller.avatar!=null) ? 'avatarImg' : '']" :style="(i.seller.avatar==null) ? 'background-color:'+i.seller.classColor+';' : ''"
										>
											<img v-if="i.seller.avatar" :src="i.seller.avatar" class="imgAvatar">
											<span class="labelAvatar" v-else>
												{{i.seller.pref_name}}
											</span>
										</div>
										<div class="text-center">
											{{i.seller.firstname}}<br>{{ i.seller.lastname }}
										</div>
									</div>
									<div class="label-assign " v-else>
										<!-- bg-avatar-admin bg-avatar -->
										<span class="avatarUser bg-avatar-choise">
											<span class="labelAvatar">
												<i class="fas fa-user"></i>
												<span class="plus-user">+</span>
											</span>
										</span>
									</div>
								</div>
							</td>
							<td class="source_cta_date text-center" v-show="show_SCD"> <!-- @click="$parent.openPop(i, 'Source/CTA/Date')" -->
								<div v-if="i.source">
									<span v-if="!i.source.show_img">
										{{i.source.name}}
									</span>
									<span v-else>
										<img :src="i.source.image" alt="icon" class="icoMark">
									</span>
								</div>
								<div v-else></div>
								<div v-if="i.cta">
									<span v-if="!i.cta.show_img">
										{{i.cta.name}}
									</span>
									<span v-else>
										<img :src="i.cta.image" alt="icon" class="icoMark">
									</span>
								</div>
								<div v-else></div>
								<div>
									{{i.project_showdate}}<br>
									{{i.project_showtime}}
								</div>
							</td>
							<td class="text-center quality">
								<div v-if="isStatus(i, [2, 3, 4, 5, 6, 7, 9, 10, 11])">
									<div class="startOpen" >
										<span v-if="i.quality_points==0">
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
										</span>
										<span v-else-if="i.quality_points==1">
											<i class="fas fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>	
										</span>
										<span v-else-if="i.quality_points==2">
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>	
										</span>
										<span v-else-if="i.quality_points==3">
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="far fa-star"></i>
											<i class="far fa-star"></i>	
										</span>
										<span v-else-if="i.quality_points==4">
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="far fa-star"></i>	
										</span>
										<span v-else>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
											<i class="fas fa-star"></i>
										</span>
										<input 
											type="range" 
											name="starPoints" 
											v-model="i.quality_points" 
											min="0" 
											max="5" 
											step="1" 
											class="slider-range" 
											:id="'starPoints-'+i.quality_id" 
											@input="$parent.qualityPoints(i)"
										/>
									</div>
									<div  class="reportOpen" >
										<span
											class="btnsimple"
											:style="!i.quality_report_IsNull ? 'background-color:#6a7a8c;border-color:#aeaeae;color:#fff;' : ''"
											@click="$parent.openModal('openReport', i)"
										>
											<span v-if="retail">Report</span>
											<span v-else>Details</span>
										</span>
									</div>
								</div>
							</td>
							<td class="text-center appointment" v-show="retail">
								<div v-if="i.status >= 2">
									<span class="btnsimple btnInactivo" 
										v-if="i.quality_points==0"
									>
										Add meeting 
									</span>
									<span class="btnsimple" 
										@click="$parent.openModal('appointment', i)"
										v-else-if="i.appointment.length==0"
									>
										Add meeting 
									</span>
									<ul class="listmeet" v-else
										style="cursor: pointer"
										@click="$parent.openModal('appointment', i)"
									>
										<li v-for="(a, idx) in i.appointment" :key="idx">
											<span>{{a.date}}</span>
											<span>{{a.hour}}</span>
										</li>
									</ul>
								</div>
							</td>
							<td class="estimate" v-show="retail">
								<div v-if="i.status >= 4">
									<div v-if="$parent.accessBillingApp" class="text-center">
										<div v-if="i.est_id>0">
											<span class="btnsimple" @click="$parent.actionPdf(i.est_id)" style="width: 68%; display: inline-block; text-align: center; margin: 1%;cursor: pointer;">
												<span class="badge badge-dark">Nro. #{{i.numdoc}}</span> <br>
												<span class="badge badge-success" v-if="i.estimate!=null"><strong>$</strong> {{ i.estimate.total | formatMoney }}</span>
											</span>
											<span v-if="i.status <= 5" class="btnsimple" @click="OpenControlEstimate(i)" style="width: 28%; display: inline-block; text-align: center; margin: 1%;cursor: pointer;">
												<i class="fa fa-edit"></i>
											</span>
											<div style="margin-top: 5px;">
												<div>Send by: </div>
												<span :class="['btnsimple sendBy', (i.estimate.send_email>0) ? 'send_email' : '']" @click="$parent.OpenSendEmail(i)">
													<span class="badge badge-light sendNro" >{{ i.estimate.send_email }}</span>
													<i class="fas fa-envelope"></i>
												</span>
												<span :class="['btnsimple sendBy', (i.estimate.send_sms>0) ? 'send_sms' : '']" @click="$parent.OpenSendSMS(i)">
													<span class="badge badge-light sendNro">{{i.estimate.send_sms}}</span>
													<i class="fas fa-sms"></i>
												</span>
											</div>
											<!-- <div v-else-if="i.status == 5" style="margin-top: 5px;">
												Sent by:
												<span v-if="i.est_send_by==1"  style="text-align: center; margin-top: 5px; font-size: 20px;" title="Mail message">
													<i class="fas fa-envelope" style="color: #f89406;"></i>
												</span>
												<span v-if="i.est_send_by==2" style="text-align: center; margin-top: 5px; font-size: 24px;" title="SMS text">
													<i class="fas fa-sms" style="color: #137eff;"></i>
												</span>
												<span style="width: 100%; display: block; text-align: center; margin-top: 5px;">
													P2P
												</span>
											</div> -->
										</div>
										<div v-else>
											<span  class="btnsimple" @click="$parent.openPopFull('estimateBilling', i)" style="display:block;margin-bottom: 20px;">
												Create New
											</span>
											<span  class="btnsimple" @click="$parent.modalChosseEstimate('estimateSelectOne', i, $parent.estimates_ids)" style="display:block;">
												Select One
											</span>
										</div>
									</div>
									<div v-else  class="text-center">
										<span class="badge badge-danger">Not apply</span>
									</div>
								</div>
							</td>
							<td class="inspection" v-show="!retail">
								<div v-if="isStatus(i, [6, 7, 9, 10, 11])">
									<div class="inspSales" @click="$parent.openModal('inspSales', i)">
										<div class="label-assign" v-if="i.insp_users && i.insp">
											<div  :class="['avatarUser', (i.insp.avatar!=null) ? 'avatarImg' : '']" :style="(i.insp.avatar==null) ? 'background-color:'+i.insp.classColor+';' : ''"
											>
												<img v-if="i.insp.avatar" :src="i.insp.avatar" class="imgAvatar">
												<span class="labelAvatar" v-else>
													{{i.insp.pref_name}}
												</span>
											</div>
											<div class="text-center">
												{{i.insp.firstname}} {{ i.insp.lastname }}
											</div>
										</div>
										<div class="label-assign " v-else>
											<!-- bg-avatar-admin bg-avatar -->
											<span class="avatarUser bg-avatar-choise">
												<span class="labelAvatar">
													<i class="fas fa-user"></i>
													<span class="plus-user">+</span>
												</span>
											</span>
										</div>
									</div>
									<div class="files_drive"  style="margin-bottom: 10px;">
										<span
											class="btnsimple"
											:style="!i.insp_report_IsNull ? 'background-color:#6a7a8c;border-color:#aeaeae;color:#fff;' : ''"
											@click="$parent.openModal('inspReportSales', i)"
										>
											<span>Report</span>
										</span>
										<span :class="['showTootlip', 'filesDrive', (i.link_drive==1) ? 'active' : '']" @click="$parent.openModal('adjuntarLinkFiles', i)" style="font-size: 1.5rem;">
											<i class="fab fa-google-drive"></i>
										</span>
										<span :class="['filePdf', 'showTootlip', (i.link_pdf==1) ? 'active' : '']" @click="$parent.openModal('adjuntarPdf', i)" style="font-size: 1.5rem;">
											<i class="fas fa-file-pdf"></i>
											<span class="tooltip">Attach PDF</span>
										</span>
									</div>
								</div>
							</td>
							<td class=" text-center contract" v-show="!retail">
								<div v-if="isStatus(i, [6, 7, 9, 11])">
									<!-- <div :class="['btnsimple']" @click="$parent.openModal('SalesContract', i)" style="margin-bottom: 10px;">
										<span v-if="i.contract==0">Insurance Info</span>
										<span v-else><i class="far fa-edit"></i> Insurance</span>
									</div> -->
									<div v-if="i.contract==0 || i.type_contract=='default'" :class="['btnsimple']" @click="$parent.openModal('contractUploadPdf', i)">
										<span>Upload Contract</span>
									</div>
									<div v-else-if="i.contract>0 && i.type_contract!='default'" :class="['btnsimple', 'showTootlip']" @click="$parent.openModal('contractUploadPdf', i)" style="height: auto;">
										<div><i class="far fa-eye"></i> Contract</div>
									</div>
									<!--<div v-if="i.contract>0 && i.type_contract=='default'">
										<div v-if="i.contract>0 && i.contract_status==1">
											<div :class="['btnsimple', 'showTootlip']" @click="$parent.openModal('SalesContract', i)" style="height: auto;">
												<span style="font-size: 12px; color:#000; margin-right: 5px;">Status:</span>
												<span :class="[
													'badge',
													(i.contract_status==1) ? 'badge-primary' :
														(i.contract_status==2) ? 'badge-success' : 'badge-danger'
												]" style="font-size:13px;">
													<span v-if="i.contract_status==1">Peding</span>
													<span v-if="i.contract_status==2">Singed</span>
													<span v-if="i.contract_status==3">Reject</span>
													<span v-if="i.contract_status==4">Void</span>
												</span>
											</div>
										</div>
										<div v-else :class="['btnsimple', 'showTootlip']" @click="$parent.openModal('contractUploadPdf', i)" style="height: auto;">
											<i class="far fa-eye"></i> Contract {{ i.contract_status }}
											<span class="tooltip">Open Contract</span>
										</div>
									</div>-->
								</div>
							</td>
							<td class="closing_claim" v-show="!retail">
								<div v-if="isStatus(i, [6, 7, 11])">
									<!-- <div v-if="i.contract>0 && i.contract_status>0 && i.contract_status<2 && i.type_contract=='default'" style="margin-top:10px;">
										<div :class="['btnsimple sendBy', 'send_sms', 'showTootlip']" @click="$parent.OpenSmsContract(i)" style="border-radius: 0px; padding-bottom:0; width:100%; text-align: left;">
											<span style="font-size: 11px; color:#000; margin-right: 5px;">Send by:</span>
											<span class="badge badge-light sendNro" style="position:relative; top:-4px;left: -3px;">{{i.count_sent_sms}}</span>
											<i class="fas fa-sms"></i>
											<span class="tooltip">Request Signature</span>
										</div>
									</div> -->
									<div class="inCloseing" style="margin-top:10px;" v-if="i.contract_status==1">
										<div class="btnsimple text-center" @click="$parent.setStatusContract(i, 2)">
											Accept
										</div>
										<div class="btnsimple text-center" style="margin-top:10px;" @click="$parent.setStatusContract(i, 3)">
											Reject
										</div>
									</div>
									<div v-else class="Closed">
										<span v-if="i.contract_status == 2" class="badge badge-success" style="width: 100%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: 10px auto;">
											Accepted
										</span>
										<span v-if="i.contract_status == 3" class="badge badge-danger" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: 10px auto;cursor:pointer;">
											Rejected
										</span>
									</div>
								</div>
								<div @click="$parent.openModal('notes', i)" style="cursor: pointer; margin-top: 10px;" class="text-center showTootlip">
									{{i.notes}}
									<i class="fas fa-comment-alt" v-if="i.notes==0"></i> 
									<i class="fas fa-comment-alt active" v-else></i> 
									<span class="addNotes">(Add)</span>
									<span class="tooltip">Comments or Notes</span>
								</div>
							</td>
							<td class="closing" v-show="retail">
								<div v-if="i.status == 5">
									<span class="btnsimple" @click="$parent.OpenClosing(i, 3)" style="width: 100%; display: block; text-align: center; margin-top: 5px;">
										Accepted
									</span>
									<!-- <span class="btnsimple" @click="$parent.OpenClosing(i, 1)" style="width: 100%; display: block; text-align: center; margin-top: 5px;">
										SOLD
									</span> -->
									<span class="btnsimple" @click="$parent.OpenClosing(i, 2)" style="width: 100%; display: block; text-align: center; margin-top: 5px;">
										Rejected
									</span>
								</div>
								<div v-if="i.status == 6">
									<!-- <span class="badge badge-success" @click="$parent.OpenClosing(i, 1)" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;cursor:pointer;background-color: #7460ee;">
										SOLD
									</span> -->
									<span class="badge badge-success" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;">
										Accepted
									</span>
								</div>
								<div v-if="i.status == 7">
									<span class="badge badge-danger" @click="$parent.OpenClosing(i, 2)" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;cursor:pointer;">
										Rejected
									</span>
									<span class="btnsimple" @click="$parent.statusChange(5, i.id, i.perfil.lead_id, 4)" style="margin: 15px auto;width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;cursor:pointer;">
										Re-Open Sale
									</span>
								</div>
							</td>
							<td class="claim_accepted_or_reject" v-show="retail=='no'">
								<div v-if="isStatus(i, [7, 11])">
									<span v-if="i.status == 11" class="badge badge-success" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;">
										Accepted
									</span>
									<span v-if="i.status == 7" class="badge badge-danger" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;">
										Rejected
									</span>
								</div>
							</td>
							<td style="width: 100px;" class="status_retail" v-show="retail">
								<span v-if="isStatus(i, [6, 7]) && retail" class="badge badge-dark" style="width: 80%;display: block;text-align: center;padding: 3px 15px;border-radius: 10px;font-size: 12px;margin: auto;">
									Finished
								</span>
								<div class="btnShowStatus"
									v-else-if="isStatus(i, [1, 2, 3, 4, 5, 8])"
                                    @click="$parent.activeStatus(i.id)"
                                    style="cursor: pointer"
                                >
									<span :class="'nameStatus status'+i.status"
									    v-for="(s, sidx) in status" 
                                        :key="sidx" 
                                        v-if="i.status == s.key"
                                    >
                                    	{{s.name}}
                                    	<i class="fas fa-chevron-down chevron" style="margin-left:5px;" v-if="i.est_id==0 || i.status<4"></i>
                                    </span>
                                    <span
                                        class="status_list"
										v-if="i.est_id==0 && $parent.statusActive == i.id"
                                    >
                                        <ul class="reset" style="padding-left: 0">
                                    		<li 
                                            	v-if="i.status==8"
                                            	data-name="Retrieve"
                                            	@click="$parent.statusChange(i.status_old, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Retrieve sale</span>
                                            </li>
                                    		<li 
                                        		v-else-if="i.status==$parent.tabsList[$parent.indexTabs].value"
                                            	data-name="uncompleted"
                                            	@click="$parent.statusChange(8, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Trash</span>
                                            </li>
                                            <li 
                                            	v-if="$parent.tabsList[$parent.indexTabs].value==1"
                                            	data-name="delete"
                                            	@click="$parent.statusChange(0, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Delete</span>
                                            </li>
                                        </ul>
                                    </span>
                                </div>
							</td>
							<td style="width: 100px;" class="status_insurance" v-show="!retail && $parent.indexTabs!=4 && $parent.indexTabs!=5">
								<div class="btnShowStatus"
									v-if="isStatus(i, [1, 2, 3, 4, 5, 8, 9, 10])"
                                    @click="$parent.activeStatus(i.id)"
                                    style="cursor: pointer"
                                >
									<span :class="'nameStatus status'+i.status"
									    v-for="(s, sidx) in status" 
                                        :key="sidx" 
                                        v-if="i.status == s.key"
                                    >
                                    	{{s.name}} 
                                    	<i class="fas fa-chevron-down chevron" style="margin-left:5px;" v-if="i.est_id==0 || i.status<4"></i>
                                    </span>
                                    <span
                                        class="status_list"
										v-if="i.est_id==0 && $parent.statusActive == i.id"
                                    >
                                        <ul class="reset" style="padding-left: 0">
                                    		<li 
                                            	v-if="i.status==8"
                                            	data-name="Retrieve"
                                            	@click="$parent.statusChange(i.status_old, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Retrieve sale</span>
                                            </li>
                                    		<li 
                                        		v-else-if="i.status==$parent.tabsList[$parent.indexTabs].value"
                                            	data-name="uncompleted"
                                            	@click="$parent.statusChange(8, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Trash</span>
                                            </li>
                                            <li 
                                            	v-if="$parent.tabsList[$parent.indexTabs].value==1"
                                            	data-name="delete"
                                            	@click="$parent.statusChange(0, i.id, i.perfil.lead_id)" 
                                            >
                                                <span class="">Delete</span>
                                            </li>
                                        </ul>
                                    </span>
                                </div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<modal
            name="control_estimate"
            width="200"
            height="200"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">Edit Estimate</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
					@click="CloseControlEstimate()"
                ></i>
            </div>
            <div class="card-body">
				<div class="btnsimple text-center" @click="EditEstimate()" style="margin-top:10px;">Edit</div>
				<div class="btnsimple text-center" @click="chooseEstimate()" style="margin-top:10px;">Pick a New one</div>
			</div>
		</modal>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import ClickOutside from 'vue-click-outside'
export default {
	name: 'tableSales',
    components: {
        Loader,
    },
	props: {
		stype: {
			default: 'retail'
		}
	},
	data () {
		return {
			status: [],
			statusChange: [
				{"key":"8", "name":'Uncmpltd.'},
				{"key":"0", "name":'Delete'},
			],
			retail: this.stype =='retail' ? true : false,
			show_SCD: false,
			itemDataForEstimate: null,
		}
	},
	created(){
		let t = this;
		if(t.stype=='insurance'){
			t.status= [
				{"key":"1", "name":'Lead'},
				{"key":"2", "name":'Qual.'},
				{"key":"9", "name":'Contract'},
				{"key":"5", "name":'Closing'},
				{"key":"6", "name":'Sold'},
				{"key":"7", "name":'Rejected'},
				{"key":"8", "name":'Trash'},
				{"key":"0", "name":'Delete'},
			];
		}else{
			t.status= [
				{"key":"1", "name":'Lead'},
				{"key":"2", "name":'Qual.'},
				{"key":"3", "name":'Appt.'},
				{"key":"4", "name":'Estimate'},
				{"key":"5", "name":'Closing'},
				{"key":"6", "name":'Sold'},
				{"key":"7", "name":'Rejected'},
				{"key":"8", "name":'Trash'},
				{"key":"0", "name":'Delete'},
			];
		}
	},
	directives: {ClickOutside},
	methods: {
        hideBtnstatus(){
        	this.$parent.statusActive = false;
        },
        nameStatusOld(status_old){
        	let t = this;
        	let old = t.status.filter(r => {
        		if(r.key==status_old){
        			return r;
        		}
        	});
        	return (old.length>0) ? old[0].name : 'old';
        },
		OpenControlEstimate(i) {
        	let t = this;
        	t.itemDataForEstimate= i;
            t.$modal.show("control_estimate");
        },
        CloseControlEstimate() {
        	let t =this;
            t.$modal.hide("control_estimate");
            t.itemDataForEstimate=null;
        },
		EditEstimate(){
			let t = this;
			t.$parent.openPopFull('estimateBilling', t.itemDataForEstimate);
			setTimeout(() => {t.CloseControlEstimate();}, 500);
		},
		chooseEstimate(){
			let t = this;
			t.$parent.modalChosseEstimate('estimateSelectOne', t.itemDataForEstimate, t.$parent.estimates_ids);
			setTimeout(() => {t.CloseControlEstimate();}, 500);
		},
		isStatus(i, permit){
			return permit.indexOf(i.status) > -1 || permit.indexOf(i.status_old) > -1;
		}
	}
}
</script>
<style lang="scss" scoped>
	.container-table tbody tr:nth-of-type(odd) { 
		background-color: #fff;
	}
	.container-table tbody tr:nth-of-type(even) { 
		background-color: #eee;	
	}
	.container-table tbody tr:hover{
		background-color: #fafafa;
	}
	td.media {
		display: flex;
		flex-direction: column;
	}
	.icoMark{
    	height: 20px;
	}
	.previewImg {
	    max-width: 150px;
	    width: 95%;
	    overflow: hidden;
	    height: 100px;
	    display: flex;
	    margin: auto;
	    flex-direction: column;
    	justify-content: center;
    	align-items: center;
    	border: 0px solid #aeaeae;
	}

	.previewImg .fa-image {font-size: 50px;color: #aeaeae;}
	.previewImg img {
    	width: 100%;
    	height: 100px;
		object-fit: cover;
    	margin-left: 0px
	}
	.files_drive{
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: auto;
	}
	.filePdf { font-size: 1.5rem; padding: 5px; color: #aeaeae;}
	.filesDrive { font-size: 2rem; padding: 5px; color: #aeaeae;}
	.filesSOW { font-size: 0.8rem; padding: 5px; color: #aeaeae;height: 26px;}
	.fileContract { font-size: 0.8rem; padding: 5px; color: #aeaeae;height: 26px;}
	.previewImg:hover .fa-image, .filePdf:hover,
	.filesDrive:hover, .filesSOW:hover,
	.fileContract:hover, .fileContract:hover
	{ color: #6a7a8c; cursor: pointer;}
	.filePdf.active, .filePdf.active:hover,
	.fileContract.active, .fileContract.active:hover,
	.filesDrive.active, .filesDrive.active:hover{ color: rgb(19, 126, 255);}
	.filesSOW.active , .filesSOW.active:hover { background-color: rgb(19, 126, 255); color:#fff;}
	.badge-check{
		background-color: #031099;
		color: #fff;
	}
	.addNotes{
		padding-left: 10px;
	}
	.fa-comment-alt{
		color: #aeaeae;
	}
	.fa-comment-alt.active{
		color: #ffc107;
	}
	.diffdays{
		position: relative;
		display: flex;
		width: 100%;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		text-align: center;
	}
	.diffdays .nrodiff{
		display: inline-block;
		font-size: 24px;
		margin-right: 5px;
	}
	.diffdays .labeldiff{
		display: inline-block;
		width: 60px;
		line-height: 1.1;
		font-size: 14px;
	}
	.btnsimple{
	    border: 1px solid rgb(0 0 0 / 25%);
	    padding: 3px 5px;
	}
	.btnsimple:hover{
		cursor: pointer;
	}
	.btnsimple.sendBy{width: 48%; display: inline-block; text-align: center; margin: 1%;cursor: pointer;position: relative;font-size: 20px; }
	.btnsimple.sendBy.send_email{padding-left: 15px;color: #f89406;}
	.btnsimple.sendBy.send_sms{padding-left: 15px;color: #137eff;}
	.btnsimple.sendBy .sendNro {
		position: absolute;
		padding: 1px 4px;
		top: 3px;
		left: 5px;
		font-size: 14px;
		font-family: monospace;
		background-color: #fff;
		color:#000;
		border: 1px solid #eee;
		border-radius: 8px;
	}
	.listmeet{
		list-style: none;
		margin-bottom: 0px;
		padding-inline-start: 0px;
		margin-block-start: 0px;
	}
	.listmeet li:nth-child(1){
		border-bottom: 1px solid rgb(0 0 0 / 25%);
	}
	.listmeet li{
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.listmeet span{
		display: block;
		font-size: 12px;
	}
	/*raiting star*/
	.startOpen{
		font-size: 14px;
		color: #fc8505;
		position: relative;
	}
	.reportOpen{
		position: relative;
		margin-top: 20px;
	}
	.startOpen span i {
	    margin-right: 3px;
	}
	.startOpen span i:last-child{
	    margin-right: 0px;
	}
	.startOpen span i.far.fa-star {
	    color: rgb(0 0 0 / 25%);
	}
	input.slider-range {
		position: absolute;
		left: -5%;
		top: -5px;
		width: 110%;
		height: 30px;
		color: transparent;
		-webkit-appearance: none;
		background: transparent;
	}

	input.slider-range:focus {
		outline: none;
	}

	input.slider-range::-webkit-slider-runnable-track {
		width: 100%;
		height: 8.4px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: none;
		background: transparent;
		border-radius: 1.3px;
		border: 0px solid transparent;
	}

	input.slider-range::-webkit-slider-thumb {
		box-shadow: none;
		border: 0px solid transparent;
		height: 36px;
		width: 16px;
		border-radius: 3px;
		background: transparent;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -14px;
	}

	input.slider-range:focus::-webkit-slider-runnable-track {
		background: transparent;
	}

	input.slider-range::-moz-range-track {
		width: 100%;
		height: 8.4px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: none;
		background: transparent;
		border-radius: 1.3px;
		border: 0px solid transparent;
	}

	input.slider-range::-moz-range-thumb {
		box-shadow: none;
		border: 0px solid transparent;
		height: 36px;
		width: 16px;
		border-radius: 3px;
		background: transparent;
		cursor: pointer;
	}

	input.slider-range::-ms-track {
		width: 100%;
		height: 8.4px;
		cursor: pointer;
		animate: 0.2s;
		background: transparent;
		border-color: transparent;
		border-width: 16px 0;
		color: transparent;
	}

	input.slider-range::-ms-fill-lower {
		background: transparent;
		border: 0px solid transparent;
		border-radius: 2.6px;
		box-shadow: none;
	}

	input.slider-range::-ms-fill-upper {
		background: transparent;
		border: 0px solid transparent;
		border-radius: 2.6px;
		box-shadow: none;
	}

	input.slider-range::-ms-thumb {
		box-shadow: none;
		border: 0px solid transparent;
		height: 36px;
		width: 16px;
		border-radius: 3px;
		background: transparent;
		cursor: pointer;
	}

	input.slider-range:focus::-ms-fill-lower {
		background: transparent;
	}

	input.slider-range:focus::-ms-fill-upper {
		background: transparent;
	}
	.btnInactivo{
		background: rgb(246 246 246);
		color: #bebebe;
	}
	$colorDark: #555;
	$colorWhite: #fff;
	$colorAll: #f2f4f5;
	$colores: (
		'0': #6c757d,
		'1': #6c757d,
		'2': #fc8505,
		'3': #031099,
		'4': #0065df,
		'5': #ffc107,
		'6': #7460ee,
		'7': #fa5838,
		'8': #C32B0D,
		'9': #031099,
		'10': #0065df,
	);
	@function getColor($m, $v) {
	    @return map-get($m, $v);
	}
	.btnShowStatus {
		border: 0px solid rgb(0 0 0 / 25%);
		padding: 0px;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		justify-content: center;
		span.nameStatus {
			padding: 2px 15px; 
			border-radius: 10px;
			font-size: 12px;
		}
		.status_list {
			padding: 5px 3px;
			position: absolute;
			left: -7px;
			top: 25px;
			background-color: #fff;
			width: 120px;
			font-size: 14px;
			z-index: 9;
	    	border: 1px solid #ddd;
			ul {
	    		margin-bottom: 0px;
	    		text-align: center;
				li {
					display: block;
					position: relative;
					margin: 1px 0;
					padding: 5px;
					text-align: center;
					span {
						cursor: pointer;
					}
					&:hover {
						background: #eee;
					}
				}
			}
		}
		@for $i from 0 through 10{  // Bucle que va de 0 a 10
			.nameStatus.status#{$i}{        // en caso afirmativo creara la clase .spacer-xx
				@if($i==5){    // y por cada numero comprobará si es multiple de 10
					color: $colorDark; // color
				}
				@else{
					color: $colorWhite; // color
				}
				background-color: getColor($colores, #{$i});
			}
		}
	}
	td.seller{ padding-left: 0px; padding-right: 0px;}
	.sellerAssign, .inspSales{
		width: 100%;
		position: relative;
		.label-assign{
			position: relative;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-content: center;
			justify-content: center;
			align-items: center;
			i.fa-user-circle{
				font-size: 20px;
				padding: 0px 1px;
				margin: auto;
				color:#ddd;
			}
			i.fa-user-circle.active{
				color:#555;
			}
			.avatarUser{
				width: 48px;
				height: 48px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0px;
				border: 1px solid #ccc;
				position: relative;
				z-index: 1;
				border-radius: 50%;
				font-size: 20px;
				font-weight: 900;
				background-color: #c4c4c4;
				color: #fff;
				text-transform: uppercase;
				&.avatarImg {
					background-color: transparent;
					border: 0px;
				}
				&.bg-avatar-choise{
					background-color: #ddd;
					color:#bbb;
					.plus-user{
						position: relative;
						right: -1px;
					}
				}
				span.labelAvatar{
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					letter-spacing: -1px;                        
				}
				img.imgAvatar {
					width: 48px;
					height: 48px;
					object-fit: cover;
				}
			}
		}
		&:hover{cursor: pointer;}
	}
</style>
