<template>
	<div class="contenedor-home">
        <div class="container">
			<div class="row">
				<h3 class="card-title" style="margin-top: 10px;">Dashboard</h3>
			</div>
			<div class="row" >
	            <div class="col-md-4" style="padding: 0rem 0.8rem">
	                <div class="row alert alert-success box-total" >
	                    <label class="text-dark col-md-12 col-sm-12 tlabel" style="padding: 0.4em 0">
	                        Accounts:
	                    </label>
	                    <h3 class="col-md-12 col-sm-12 text-left">
	                    	<loader v-if="isLoading"></loader>
	                        <span v-else class="badge badge-light total_ipad">
	                            {{totalAccount}}
	                        </span>
	                    </h3>
	                </div>
	            </div>
	            <div class="col-md-4" style="padding: 0rem 0.8rem">
	                <div class="row alert alert-primary box-total" >
	                    <label class="text-dark col-md-12 col-sm-12 tlabel" style="padding: 0.4em 0">
	                        Organizations:
	                    </label>
	                    <h3 class="col-md-12 col-sm-12 text-left">	                    	
	                    	<loader v-if="isLoading"></loader>
	                        <span v-else class="badge badge-light total_ipad">
	                            {{totalOrgs}}
	                        </span>
	                    </h3>
	                </div>
	            </div>
	            <div class="col-md-4" style="padding: 0rem 0.8rem">
	                <div class="row alert alert-danger box-total" >
	                    <label class="text-dark col-md-12 col-sm-12 tlabel" style="padding: 0.4em 0">
	                        Suspended Accounts:
	                    </label>
	                    <h3 class="col-md-12 col-sm-12 text-left">
	                    	<loader v-if="isLoading"></loader>
	                        <span v-else class="badge badge-light total_ipad">
	                            {{totalsuspend}}
	                        </span>
	                    </h3>
	                </div>
	            </div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
	name: 'homeR',
		components: {
			Loader
		},	
		data () {
		return {
			isLoading: false,
			totalAccount: 0,
			totalOrgs: 0,
			totalsuspend: 0,
		}
	},
	created(){
		// this.$store.commit("core/reseller/ActiveLateral", false);
		this.getdash();
	},
    methods: {
		getdash() {
			let t = this;
			t.isLoading = true;
			let url = "/getResumentDash/"+window.resellerId;
			window.reselleR2.get(url)
			.then((response) => {
				let r = response.data;
				console.log(r);
				t.totalAccount = r.accounts;
				t.totalOrgs = r.orgs;
				t.totalsuspend = r.suspended;
				t.isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				t.isLoading = false;
			});
    	}
    }
}
</script>
<style lang="css" scoped>
    @import '../../assets/css/homeStyles.css';
    .box-total{
		border: 1px solid #ccc;
		padding: 20px 5px;
		margin-top: 10px;
    }
    .tlabel{
    	font-size: 18px;
    }
</style>
