<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4 style="margin-bottom: 0px;">
                Location:
                <button type="button"
                    class="btn btn-success btn-sm"
                    :disabled="isDisabled"
                    style="margin-left: 10px;"
                    @click="setUrlMap()"
                >
                        Save
                </button>
            </h4>
		</div>
        <div class="row">
            <div class="col-md-12 " s>
                <label class="col-form-label">Paste the code HTML of your map here, 
                    <a href="https://maps.google.com/" target="_blank">or get one</a>
                </label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="codeHTML" @input="extraerSRC">
                    <span class="input-group-text"
                        style="cursor:pointer;"
                        @click="codeHTML=null"
                    >Clear</span>
                </div>
            </div>
            <div class="col-12" style="margin-top: 20px;">
                <template v-if="urlMapSrc!=null">
                    <iframe :src="urlMapSrc"
                        width="100%"
                        height="350"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </template>
                <iframe :src="urlMapDefault"
                    v-else
                    width="100%"
                    height="350"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
        <div class="row" style="width: 100%;margin-top: 40px;">
            <div class="col-12 text-center" style="">
                <button type="button"
                    class="btn btn-success btn-lg"
                    @click="setUrlMap()"
                    :disabled="isDisabled"
                    style="width: 100px;border-radius: 20px;"
                >
                Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"editMapIframe",
    data(){
        return {
            cardItem: null,
            codeHTML : null,
            urlMapSrc : null,
            urlMapDefault : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1517337.2149921788!2d-73.00297119894064!3d42.03140120496237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3656de973bffd%3A0x45c6d03655830d1c!2sMassachusetts%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sco!4v1702055332819!5m2!1sen-419!2sus',
            apis: null,
            isDisabled: true,
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        t.cardItem = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        t.urlMapSrc = t.getMapa(t.cardItem);
    },
    methods: {
        getMapa(item){
            let mapa = null;
            if(item.temp_opts && item.temp_opts.length>0){
                for (const key in item.temp_opts) {
                    if (Object.hasOwnProperty.call(item.temp_opts, key)) {
                        if(item.template_id==item.temp_opts[key].template_id){
                            if(item.temp_opts[key].meta_key=='map' && item.temp_opts[key].meta_value!=null && item.temp_opts[key].meta_value.length>0){
                                mapa = item.temp_opts[key].meta_value;
                            }
                        }
                    }
                }
            }
            return mapa;
        },
        extraerSRC(){
            let t = this;
            console.log('event input', t.codeHTML)
            setTimeout(() => {
                let iframe = t.codeHTML.split(' ');
                for (const i in iframe) {
                    if (iframe[i].indexOf('src="')>-1){
                        t.urlMapSrc= iframe[i].slice(5, iframe[i].length-1);
                    }
                }
                console.log('urlMapSrc', t.urlMapSrc)
            }, 200);
        },
        setUrlMap(editUrl){
            let t = this;
            if(t.urlMapSrc!=null && t.urlMapSrc.length){
                t.isDisabled = true;
                let formData = new FormData();
                // @ts-ignore
                formData.append("user_id", window.master_client);
                // @ts-ignore
                formData.append("org_id", window.localorgdata);
                formData.append("id", t.cardItem.id);
                formData.append("template_id", t.cardItem.template_id);
                formData.append("meta_key", 'map');
                formData.append("meta_value", t.urlMapSrc);
                formData.append("pusher_id", pusher.connection.socket_id);
                t.$store.dispatch('ModApps/Marketea/setTempOpts', formData)
                .then(r => {
                    console.log('setOptionsTemplate', r);
                    t.isDisabled = false;
                    setTimeout(() =>{
                        t.$parent.close();
                    }, 400);
                })
                .catch(error => {
                    console.log(error);
                    t.isDisabled = false;
                    t.$notify({
                        group: "noti",
                        type: 'Error',
                        title: "Fail",
                        text: "Failed to save",
                        duration: 2000
                    });
                });
            }
        },
    },
    watch: {
        codeHTML: function(){
            console.log('watch codeHTML', this.codeHTML)
            if(this.codeHTML!=null && this.codeHTML.length>0){ this.isDisabled = false;}
            else{ this.isDisabled = true;}
        }
    },
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.card-header h4{font-size:24px;}
.col-form-label, .form-control, .input-group-text{
    font-size:18px;
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
</style>
