<template>
    <div class="container-fluid" style="padding-top: 10px">
        <div v-if="isLoading" class="d-flex align-items-center justify-content-center">
            <Loader></Loader>
        </div>
        <div v-else class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-5 align-self-center">
                                <h4 class="page-title">
                                    <i class="fas fa-user-edit"></i> Edit Form
                                </h4>
                            </div>
                            <div class="col-7 align-self-center">
                                <div class="d-flex align-items-right justify-content-end">
                                    <nav aria-label="breadcrumb">
                                        <ol
                                            class="breadcrumb"
                                            style="padding: 0; margin: 0"
                                        >
                                            <li class="breadcrumb-item">
                                                <router-link
                                                    :to="{ name: 'listForms' }"
                                                    class="btn btn-secondary btn-sm mr-2"
                                                >
                                                    <i class="fas fa-arrow-left"></i> Back
                                                </router-link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="load" class="card-body cuerpo-form">
                        <Loader></Loader>
                    </div>
                    <div v-else class="card-body cuerpo-form">
                        <asideForm></asideForm>
                    </div>
                    <div class="card-footer"></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <form @submit.prevent="setForm(1)">
                        <div
                            class="btn-group btn-group-lg"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                @click="menuTab(1)"
                                v-bind:class="[{ active: editarForm }, 'btn btn-light']"
                            >
                                Edit Form
                            </button>
                            <button
                                type="button"
                                @click="menuTab(2)"
                                v-bind:class="[
                                    { active: editarDesigns },
                                    'btn btn-light',
                                ]"
                            >
                                Designs
                            </button>
                            <button
                                type="button"
                                @click="menuTab(3)"
                                v-bind:class="[
                                    { active: editaCustomCSS },
                                    'btn btn-light',
                                ]"
                            >
                                Custom Css
                            </button>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12 align-self-center">
                                    <div
                                        class="d-flex align-items-right justify-content-end"
                                    >
                                        <nav aria-label="breadcrumb">
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editarForm"
                                            >
                                                <li class="breadcrumb-item">
                                                    <span
                                                        class="cdropdown bg-info content_create_inputs"
                                                    >
                                                        <button class="btn btn-sm mr-2">
                                                            <i class="fas fa-plus"></i>
                                                            New
                                                        </button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                                <li
                                                                    @click="
                                                                        createInput(1)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_input"
                                                                    ></i>
                                                                    Input Text
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(2)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_textarea"
                                                                    ></i>
                                                                    Textarea
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(3)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fas fa-caret-square-down"
                                                                    ></i>
                                                                    Dropdown
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(4)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_radio"
                                                                    ></i>
                                                                    Radio button
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(5)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fas fa-check-square"
                                                                    ></i>
                                                                    Checkbox
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </li>
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-success btn-sm mr-2"
                                                        :disabled="btnsave"
                                                    >
                                                        <i class="fas fa-save"></i> Save
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="editarCampo"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-sm mr-2"
                                                        :disabled="btnsave"
                                                        @click="delInput"
                                                    >
                                                        <i class="fas fa-trash"></i>
                                                        Delete
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="editarCampo"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary btn-sm mr-2"
                                                        :disabled="btnsave"
                                                        @click="cancelInput"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                        cancel
                                                    </button>
                                                </li>
                                            </ol>
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editarDesigns"
                                            >
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-sm"
                                                        @click="refreshStyle(1)"
                                                    >
                                                        <i class="fa fa-save"></i> Save
                                                        design
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="styleOld != null"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary btn-sm mr-2"
                                                        @click="cancelDesign"
                                                        style="margin-left: 10px"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                        cancel
                                                    </button>
                                                </li>
                                            </ol>
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editaCustomCSS"
                                            >
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-sm"
                                                        @click="refreshStyle(2)"
                                                    >
                                                        <i class="fa fa-save"></i> Save
                                                        custom css
                                                    </button>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body lateral-config">
                            <div class="cuerpo-lateral">
                                <div v-if="editarForm">
                                    <editorForm></editorForm>
                                </div>
                                <div v-if="editarDesigns">
                                    <designForm></designForm>
                                </div>
                                <div v-if="editaCustomCSS">
                                    <customCss></customCss>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import editorForm from "@/apps/Marketea/components/builder/editorForm";
import designForm from "@/apps/Marketea/components/builder/designForm";
import customCss from "@/apps/Marketea/components/builder/customCss";
import asideForm from "@/apps/Marketea/components/builder/asideForm";
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "BuilderForms",
    components: {
        Loader,
        editorForm,
        designForm,
        customCss,
        asideForm,
    },
    data() {
        return {
            isLoading: false,
            load: false,
            designs: [],
            form: [],
            btnsave: false,
            inputs: [],
            customCSS: "",
            editinput: [],
            inputActive: null,
            editarCampo: false,
            restoreInput: false,
            formConfig: false,
            addInput: [],
            styleOld: [],
            resf: false,
            editarForm: true,
            editarDesigns: false,
            editaCustomCSS: false,
            cre: true,
            moveInput: [],
        };
    },
    created() {
        if(!this.$route.params.id) {
            this.$notify({
                group: "noti",
                type: "error",
                title: "Not Found!",
                text: "Form does not exist.",
            });
            this.$router.push({ name: 'listForms' });
        }
    },
    methods: {
        init() {
            let apisForm = this.$store.getters['ModApps/Marketea/urlApis'];
            this.apis = apisForm.Form;
            this.getListForms();
        },           
        setForm(m) {
            let e = this.$store.getters["ModApps/Marketea/get_formBuilt"];
            // console.log(this.cre)
            if (this.cre == true) {
                this.inputs = [];
                let a = this.$store.getters["ModApps/Marketea/get_asideStorage"];
                let cc = a.length;
                if (cc > 0) {
                    if (a[0].inputs != undefined) {
                        this.inputs.push(a[0].inputs);
                    } else {
                        this.inputs = a[0].data[0].inputs;
                    }
                } else {
                    this.inputs = JSON.parse(e.result.inputs);
                }
            } else {
                this.load = true;
            }

            this.btnsave = true;
            let url = this.apis.update();
            let error = 0;
            var l = [];
            if (this.moveInput.length > 0) {
                l = this.moveInput;
                this.inputs = this.moveInput;
                this.load = true;
            } else {
                l = this.inputs;
            }
            for (let i in l) {
                if (l[i].campo_id.length == 0) {
                    error++;
                    this.inputs[i].campo_error = "error";
                } else {
                    this.inputs[i].campo_id = this.slugUrl(this.inputs[i].campo_id);
                    // this.inputs[i].campo_name = this.inputs[i].campo_name;
                }
            }
            if (error > 0) {
                this.$notify({
                    group: "noti",
                    type: "error",
                    title: "input Error",
                    text: "ID empty!!",
                });
                this.btnsave = false;
            } else {
                /*if (m == 4) {
                    this.form = [];
                    this.designs = [];
                    this.customCSS = [];
                    let g = this.$store.getters["ModApps/Marketea/get_editorStorage"];
                    let c = g.length;
                    if (c > 0) {
                        this.form = g[0].form;
                    } else {
                        this.form = e.result;
                    }

                    let k = this.$store.getters["ModApps/Marketea/get_desingStorage"];
                    let co = k.length;
                    if (co > 0) {
                        this.designs = k[0].designs[0];
                        this.load = true;
                    } else {
                        this.designs = e.s;
                    }

                    let o = this.$store.getters["ModApps/Marketea/get_customCssStorage"];
                    let cot = o.length;
                    if (cot > 0) {
                        this.customCSS = o[0].customCSS;
                        this.load = true;
                    } else {
                        if (e.s.custom_code != null) {
                            this.customCSS = e.s.custom_code;
                        } else {
                            this.customCSS = "";
                        }
                    }
                }*/
                window.master
                    .post(url, {
                        id: this.form.id,
                        user_id: window.localuserdata,
                        org_id: window.localuserdata,
                        subject: this.form.subject,
                        name: this.form.name,
                        class: this.form.class,
                        recaptcha: this.form.recaptcha,
                        key_public: this.form.key_public,
                        key_secert: this.form.key_secert,
                        enctype: this.form.enctype,
                        style: this.form.style,
                        inputs: JSON.stringify(this.inputs),
                        designs: this.designs,
                        custom_code: this.customCSS,
                    })
                    .then((response) => {
                        if (this.form.org_id == 0) {
                            this.form.id = response.data.result.id;
                            this.form.user_id = window.localAccountID;
                            this.form.org_id = window.localorgdata;
                        }
                        if (m == 1 || m == 4) {
                            this.$notify({
                                group: "noti",
                                type: "success",
                                title: "Saved",
                                text: "Successful registration",
                            });
                            let t = [];
                            t.push({
                                resf: "form_updateForm",
                            });
                            this.arrays("form_updateForm", t);
                        }
                        if (m == 2) {
                            this.editinput = [];
                            this.inputActive = null;
                            this.editarCampo = false;
                            this.restoreInput = false;
                            this.formConfig = true;
                            this.addInput = [];
                        }
                        localStorage.setItem("restore", JSON.stringify(this.inputs));
                        this.styleOld = response.data.s;

                        this.btnsave = false;
                        let data = [];
                        data.push({
                            resf: this.btnsave,
                        });
                        this.arrays("getForms", data);
                        this.load = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.btnsave = false;
                        this.load = false;
                    });
            }
        },
        slugUrl(str) {
            str = str.replace(/^\s+|\s+$/g, "-");
            str = str.toLowerCase();
            var buscar =
                // eslint-disable-next-line no-useless-escape
                "ÃÀÁÄÂẼÈÉËÊÌÍÏÎÕÒÓÖÔÙÚÜÛÑãàáäâẽèéëêìíïîõòóöôùúüûñç·/,:;*@#&%¡?!+.{}[]\_´~'<>";
            var re =
                "aaaaaeeeeeiiiiooooouuuunaaaaaeeeeeiiiiooooouuuunc                         ";
            for (var i = 0, l = buscar.length; i < l; i++) {
                str = str.replace(buscar.charAt(i), re.charAt(i));
            }
            str = str.replace(/^\s+|\s+$/g, "");
            return str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
        },
        menuTab(t) {
            if (t == 1) {
                this.editarForm = true;
                this.editarDesigns = false;
                this.editaCustomCSS = false;
            }
            if (t == 2) {
                this.editarForm = false;
                this.editarDesigns = true;
                this.editaCustomCSS = false;
            }
            if (t == 3) {
                this.editarForm = false;
                this.editarDesigns = false;
                this.editaCustomCSS = true;
            }
        },
        refreshStyle(d) {
            if (d == 1) {
                this.arrays("SaveDesign", 1);
            } else {
                this.arrays("SaveCustomCss", 1);
            }
        },
        showLabel() {
            this.labelConfig = !this.labelConfig;
            this.campoConfig = false;
            this.formConfig = false;
        },
        showCampo() {
            this.campoConfig = !this.campoConfig;
            this.labelConfig = false;
            this.formConfig = false;
        },
        cancelDesign() {
            let e = localStorage.getItem("restore_btn_styleForm");
            var styleForm = JSON.parse(e);
            this.arrays("cancelDesign", styleForm);
        },
        cancelInput() {
            this.editarCampo = false;
            this.arrays("cancelInput", 1);
        },
        delInput() {
            this.$swal({
                title: "Delete this Input?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let d = JSON.stringify(this.inputs[this.inputActive]) + ",";
                    let i = JSON.stringify(this.inputs);
                    let r = i.replace(d, "");
                    this.inputs = JSON.parse(r);
                    this.arrays("delInput", this.inputs);
                    this.setForm(2);
                }
            });
        },
        createInput(t) {
            this.arrays("createInput", t);
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/Marketea/set_builtForm", k);
        },
    },
    watch: {
        get_builtForm: function () {
            if (this.get_builtForm) {
                let g = this.$store.getters["ModApps/Marketea/get_builtForm"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "edit") {
                    this.inputActive = r.index;
                    this.inputs = r.inputs;
                    this.form = r.form;
                    this.designs = r.designs[0];
                    this.customCSS = r.custom_code;
                    this.editarCampo = true;
                    this.cre = false;
                }
                if (fun == "moveInput") {
                    this.moveInput = r.inputs;
                    this.designs = r.designs[0];
                    this.customCSS = r.custom_code;
                    this.form = r.form;
                    this.setForm(0);
                    this.cre = false;
                }
                if (fun == "RSaveDesign") {
                    this.designs = [];
                    this.load = true;
                    this.inputs = r.inputs;
                    this.designs = r.designs[0];
                    this.customCSS = r.custom_code;
                    this.form = r.form;
                    this.setForm(1);
                    this.cre = true;
                }
                if (fun == "RSaveCustomCss") {
                    this.load = true;
                    this.inputs = r.inputs;
                    this.designs = r.designs;
                    this.customCSS = r.custom_code;
                    this.form = r.form;
                    this.setForm(1);
                    this.cre = true;
                }
                if (fun == "RcreateInput") {
                    console.log(r);
                    this.inputActive = r.index;
                    this.inputs = r.inputs;
                    this.form = r.form;
                    this.designs = r.designs[0];
                    this.customCSS = r.custom_code;
                    this.editarCampo = true;
                    this.cre = false;
                }
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_builtForm"]),
    },
};
</script>
<style lang="scss" scoped>
.error {
    border: 1px solid #ff0000 !important;
}
.content_create_inputs {
    position: relative;
    ul {
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            position: relative;
            width: 49%;
            cursor: pointer;
            background-color: #f1f1f1;
            border: 1px solid #ccc;
            margin: 8px auto;
            padding: 5px 9px;
            &:hover {
                background-color: #eceaea;
                cursor: pointer;
            }
            .icon_radio {
                position: relative;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #7d7d7d;
                display: inline-block;
                vertical-align: middle;
                &:before {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: #a7a7a7;
                    border-radius: 50%;
                }
            }
            .icon_textarea {
                width: 20px;
                height: 15px;
                display: inline-block;
                vertical-align: middle;
                background-color: #fffbfb;
                border: 1px solid #a0a0a0;
            }
            .icon_input {
                width: 20px;
                height: 8px;
                display: inline-block;
                vertical-align: middle;
                background-color: #fffbfb;
                border: 1px solid #a0a0a0;
            }
        }
    }
}
.form-control {
    border-color: #a9a9a9;
    margin-bottom: 0.5rem;
}
.editForm button {
    box-shadow: none;
    border: 1px solid #222223;
    padding: 10px 20px;
}
button.btn {
    border: 0;
    padding: 5px 10px;
}
.cuerpo-form {
    min-height: 300px;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    max-height: 75vh;
}
.card-header.label-header {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #000;
    margin: 5px 00px;
    font-weight: bold;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
}
.lateral-config {
    height: 75vh;
    .add_inputs {
        display: flex;
        justify-content: flex-end;
    }
}
.cuerpo-lateral {
    display: block;
    min-height: 300px;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    max-height: 75vh;
}
.card-header.label-header span {
    padding-left: 10px;
}
label.c-input.c-radio {
    padding: 0px 10px;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: "";
    padding-right: 0rem;
}
.cdropdown {
    margin-right: 10px;
    padding: 0px 5px;
    font-size: 0.76563rem;
    line-height: 1.5;
}
.cdropdown > a,
.cdropdown > button {
    background-color: #137eff;
    color: #fff;
}
.cdropdown input[type="checkbox"]:checked + ul {
    width: 150px;
}
.cdropdown > a:before,
.cdropdown > button:before {
    border-top: 5px solid #fff;
}
.delOption {
    padding: 8px 10px;
    line-height: 01.5;
    height: 35px;
    border: 0px;
}
.han-nohover {
    display: block !important;
    padding-top: 6px !important;
    text-align: center;
    height: 85% !important;
    border-radius: 0px !important;
    top: 0px !important;
    cursor: move !important;
}
.opcionsInputs {
    padding-left: 20px;
}
.editForm label.c-input.c-radio {
    display: block;
}
button.btn.btn-light {
    background-color: #fff;
    border: 1px solid #6c757d;
    margin-bottom: 0px;
    border-radius: 2px;
    display: inline-block !important;
    width: 33.333% !important;
}
button.active.btn.btn-light {
    background-color: #f7f7f7 !important;
    border-color: #222223 !important;
    border-bottom: 0px;
}
.btn-group.btn-group-lg {
    display: block;
}
.editarDesigns label.c-input.c-radio {
    display: block;
    font-size: 20px;
}
.labelColor {
    display: block;
    width: 100%;
}
.boxcolor {
    width: 60%;
    display: inline-block;
    border-radius: 0;
    height: 33px;
    float: left;
}
button.btn.btn-light.btn-xs.btn-color {
    padding: 5px 10px !important;
    border-radius: 0 !important;
    display: inline-block !important;
    height: 33px;
    float: left;
    border: 1px solid #ccc !important;
    width: 20px !important;
}
.campoColor {
    margin-bottom: 50px;
}
button.btn.btn-light.btn-xs.btn-color.muestra {
    padding: 5px 16px !important;
}
.btn-color {
    padding: 9px 15px !important;
    border-radius: 0px !important;
}
.btn-color:focus {
    box-shadow: none;
}
</style>
