<template>
    <div class="container">
        <div class="prof_title">
            <h4>Employee List</h4>
            <p style="text-align: center">
                Add employee and assign them to your organizations.
            </p>
        </div>
        <div class="card" style="border-top: 4px solid #a1e0ae">
            <div class="icontri"></div>
            <div class="card-body">
                <div class="row" style="padding-bottom: 20px">
                    <div class="col-md-3">
                        <button 
                            v-if="continueAdd"
                            class="btn btn-success disabled"
                            style="margin-left: 10px"
                            type="button"
                            @click="validAddUser"
                        >
                            <i class="fas fa-plus"></i> Add New
                        </button>
                        <router-link
                            v-else
                            :to="{ name: 'UsersForm' }"
                            class="btn btn-success"
                            style="margin-left: 10px"
                        >
                            <i class="fas fa-plus"></i> Add New
                        </router-link>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-5"></div>
                            <div class="col-5">
                                <input
                                    type="text"
                                    class="form-control search"
                                    v-model="search"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="msjError" style="padding-top: 20px;">
                        <div class="alert alert-warning alert-dismissible fade show">
                          <strong>Warning!</strong> {{messagetext}}
                          <button type="button" class="close" @click="close_alert">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="content-table-user-list"
                            style="
                                padding-left: 20px;
                                padding-bottom: 70px;
                                margin-bottom: 130px;
                            "
                        >
                            <div v-if="showColorIdx!=null" class="colorAvatarUser">
                                <button class="closeIcon"
                                @click="closeColor"
                                @touchstart="closeColor"
                                style="width: 30px;position: relative;background: #fff;border-top-left-radius: 5px;border-top-right-radius: 5px;"
                                ><span></span></button>
                                <color-picker
                                v-model="selectedItem"
                                @input="changeColor($event)"
                                :palette="colors"
                                ></color-picker>
                            </div>
                            <table class="table table-striped" id="usersList">
                                <thead>
                                    <tr>
                                        <th scope="col" class="avatar">#</th>
                                        <th scope="col" class="name">Name</th>
                                        <th scope="col" class="last">Last Name</th>
                                        <th scope="col" class="username">Username</th>
                                        <th scope="col" class="role">Role
                                            <span class="badge badge-light addRole"
                                            @click="ModalBox('RolesSetting',0,0)">Add+</span>
                                        </th>
                                        <th scope="col" class="org">Organizations</th>
                                        <th scope="col" class="status">Status</th>
                                        <th scope="col" class="action">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(i, index) in filterItems" :key="index">
                                        <td class="text-center" style="width:60px;">
                                            <span class="badge badge-info Pointer" style="margin-bottom:5px;">#{{ i.account_id }}</span><br>
                                            <img v-if="i.imgAvatar && i.avatar!=null && i.avatar!=''" :src="i.ruta + i.avatar" width="40px" class="avatar"/>
                                            <span v-else @click="showColor($event, index, i)">
                                                <span class="avatarDefault" :style="i.color ? 'background-color:'+i.color+';' : ''">{{ i.pref_name+i.pref_lastname }}</span>
                                            </span>
                                        </td>
                                        <td class="name">
                                            <input
                                                type="text"
                                                v-model="i.name"
                                                v-bind:class="[
                                                    i.name ? 'custom_mini' : '',
                                                    'form-control editables',
                                                ]"
                                                @change="editCamp(i)"
                                            />
                                        </td>
                                        <td class="last">
                                            <input
                                                type="text"
                                                v-model="i.lastname"
                                                v-bind:class="[
                                                    i.lastname ? 'custom_mini' : '',
                                                    'form-control editables',
                                                ]"
                                                @change="editCamp(i)"
                                            />
                                        </td>
                                        <td class="username">
                                            <input
                                                type="text"
                                                v-model="i.username"
                                                v-bind:class="[
                                                    i.username ? 'custom_mini' : '',
                                                    'form-control editables email',
                                                ]"
                                                @change="editCamp(i)"
                                            />
                                        </td>
                                        <td class="role">
                                            <select class="form-control editables roles" 
                                                v-model="i.rol"
                                                @change="editCamp(i)"
                                            >
                                                <option value="0">Uncategorized</option>
                                                <option value="9991">Sales Rep</option>
                                                <option value="9992">Inspector</option>
                                                <option value="9993">Supervisor</option>
                                                <option value="9994">Estimator</option>
                                                <option value="9995">Admin</option>
                                                <option value="9996">Project Manager</option>
                                                <option value="9997">KTD</option>
                                                <option value="9997">KTD-Inspector</option>
                                                <option v-for="(i, index) in listRoles" :key="index" :value="i.id">{{i.name}}</option>
                                            </select>
                                        </td>
                                        <td class="org">
                                            <span
                                                class="badge badge-light tagsOrg"
                                                v-for="(t, indez) in i.user_lists"
                                                :key="indez"
                                            >
                                                <span
                                                    v-if="
                                                        t.user_id_secondary ===
                                                        i.account_id
                                                    "
                                                    @click="
                                                        ModalBox(
                                                            'AssignApps',
                                                            i.account_id,
                                                            t.org_id
                                                        )
                                                    "
                                                    >{{ t.bill_company_name }}
                                                </span>
                                                <button
                                                    class="btn btn-danger del-org"
                                                    @click="delOrg(t.id)"
                                                >
                                                    <i class="fa fa-times"></i
                                                    ><span class="tooltip">Delete</span>
                                                </button>
                                            </span>
                                        </td>
                                        <td class="status">
                                            <span
                                                class="status"
                                                v-if="isLoading == true && Sid == index"
                                                ><Loader></Loader
                                            ></span>
                                            <span class="status" v-else>
                                                <span
                                                    v-if="i.status == 1"
                                                    class="badge badge-success Pointer"
                                                    @click="
                                                        changeStatus(
                                                            i.id,
                                                            i.account_id,
                                                            i.status,
                                                            index
                                                        )
                                                    "
                                                    >Active</span
                                                >
                                                <span
                                                    v-else
                                                    class="badge badge-secondary Pointer"
                                                    @click="
                                                        changeStatus(
                                                            i.id,
                                                            i.account_id,
                                                            i.status,
                                                            index
                                                        )
                                                    "
                                                    >Inactive</span
                                                >
                                            </span>
                                        </td>
                                        <td class="action">
                                            <span class="cdropdown">
                                                <button><span>Actions</span></button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li
                                                            @click="
                                                                UsersFormEdit(
                                                                    i.account_id
                                                                )
                                                            "
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fas fa-edit"
                                                                ></i>
                                                                Edit Profile
                                                            </button>
                                                        </li>
                                                        <li
                                                            @click="
                                                                ModalBox(
                                                                    'ChangePass',
                                                                    i.account_id
                                                                )
                                                            "
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fas fa-lock"
                                                                ></i>
                                                                Change Password
                                                            </button>
                                                        </li>
                                                        <li
                                                            @click="
                                                                ModalBox(
                                                                    'AssingOrgs',
                                                                    i.account_id,
                                                                    0
                                                                )
                                                            "
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fa fa-lg fa-building"
                                                                ></i>
                                                                Assign Organizations
                                                            </button>
                                                        </li>
                                                        <li
                                                            @click="
                                                                ModalBox(
                                                                    'AssignApps',
                                                                    i.account_id,
                                                                    0
                                                                )
                                                            "
                                                        >
                                                            <button>
                                                                <i class="fas fa-th"></i>
                                                                Assign App
                                                            </button>
                                                        </li>
                                                        <li @click="delUser(i)">
                                                            <button>
                                                                <i
                                                                    class="fas fa-times"
                                                                ></i>
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="!filterItems.length">
                                        <td colspan="10">
                                            <p align="center">NO RECORD</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalLateral 
            v-show="m.component=='ChangePass' || 
            m.component=='AssignApps' || 
            m.component=='AssingOrgs' ||
            m.component=='RolesSetting'"
        >
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader.vue'
import AssingOrgs from '@/core/components/Account/Users/AssingOrgs.vue'
import AssignApps from '@/core/components/Account/Users/AssignApps.vue'
import ChangePass from '@/core/components/Account/Users/ChangePass.vue'
import RolesSetting from '@/core/components/Account/Users/RolesSetting.vue'
import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
import { mapGetters } from 'vuex'
import { Compact } from "vue-color";
export default {
    name: 'List',
    components: {
        Loader,
        AssingOrgs,
        AssignApps,
        ChangePass,
        RolesSetting,
        modalLateral,
        "color-picker": Compact,
    },
    data () {
        return {
            isLoading: false,
            Sid: 0,
            user_id: window.master_client,
            org_id: window.localorgdata,
            userlist: [],
            search: '',
            SettingBox: '',
            account_id: 0,
            orgid: 0,
            m: {
                show: false,
                component: "",
                data: [],
            },
            OrgPlan : (window.OrgPlan > 1),
            msjError: false,
            messagetext: null,
            listRoles: [],
            colors: [],
            showColorIdx: null,
            selectedItem: null,
        }
    },
    created () {
        console.log('orgplan', this.OrgPlan)
        this.$store.dispatch('core/session/validTypeUser').then((r) => {
            if (r) {
                this.colors = this.$store.getters['core/get_paletaDeColores'];
                this.userslist()
            } else {
                this.$router.push({ name: 'Home' })
            }
        })
    },
    methods: {
        validAddUser(){
            if(this.userlist.length>=1 && !this.OrgPlan){
                this.msjError=true;
                this.messagetext="Your plan include 1 Users. Upgrade to Premium to get up to 10 Users.";
            }
            else if(this.OrgPlan && this.userlist.length>=10){
                this.msjError=true;
                this.messagetext="Maximum users allowed 10.";
            }else{this.msjError=false; this.messagetext=null;}
        },
        getRoles () {
            let t = this;
            window.master.post('user/getroles', {
                user_id: t.user_id
            })
            .then((response) => {
                t.listRoles = response.data.roles;
            })
            .catch((error) => {
                console.log(error);
            })
        },
        close_alert(){this.msjError=false;},
        llamarPopPremium() {
            this.msjError=true;
            //this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
        },
        delOrg (id) {
            console.log(id)
            this.$swal({
                title: 'Delete Organization?',
                text: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.value) {
                    window.master
                        .get('user/assignorg/delete/' + id + '/' + this.user_id)
                        .then((response) => {
                            if (response.data.status) {
                                this.userslist();
                                this.$store.commit('core/setNotiPush', {
                                    show: true,
                                    group: "notiPush",
                                    type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                                    title: 'Update',
                                    text: 'Organization Deleted.',
                                    duration: 5000
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
        },
        delUser (data) {
            console.log(data)
            this.$swal({
                title: 'Delete User?',
                text: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.value) {
                    window.master
                        .get('user/invited/delete/' + data.account_id)
                        .then((response) => {
                            if (response.data.status) {
                                this.userslist();
                                this.$store.commit('core/setNotiPush', {
                                    show: true,
                                    group: "notiPush",
                                    type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                                    title: 'Update',
                                    text: 'User Deleted.',
                                    duration: 5000
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
        },
        ModalBox (nom, id, org) {
            // console.log(nom, id, org)
            this.SettingBox = nom
            this.account_id = id
            this.orgid = org
            //this.$modal.show('modal-box')
            //document.styleSheets[0].addRule('.vm--modal', 'top: 60px !important;')
            let title = '';
            if(nom == 'ChangePass') { title = 'Change Password';}
            if(nom == 'AssignApps') { title = 'Assign App';}
            if(nom == 'AssingOrgs') { title = 'Assing Orgs';}
            if(nom == 'RolesSetting') { title = 'Roles Setting';}
            this.m = {
                show: true,
                component: nom,
                data: {
                    title: title,
                    account_id: id,
                    orgid: org,
                },
            };
            this.$store.commit("core/setOpenModal", this.m);
        },
        closeModalBox () {
            this.$modal.hide('modal-box')
        },
        ClosedModal () {
            this.SettingBox = ''
            this.account_id = 0
            this.orgid = 0
        },
        UsersFormEdit (id) {
            // console.log(id)
            this.$router.push({
                name: 'UsersFormEdit',
                params: { id: id, edit: 'edit' }
            })
        },
        editCamp (b) {
            if (b.name === '' || b.lastname === '' || b.username === '') {
                this.$store.commit('core/setNotiPush', {
                    show: true,
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    title: 'Warning',
                    text: 'It is Empty.',
                    duration: 10000
                });
                this.userslist()
            } else {
                window.master
                    .post('/user/editCamp', {
                        id: b.id,
                        account_id: b.account_id,
                        firstname: b.name,
                        lastname: b.lastname,
                        username: b.username,
                        rol: b.rol
                    })
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        changeStatus (id, accountIid, status, index) {
            this.Sid = index
            this.isLoading = true
            let sta = 0
            if (status == '0') {
                sta = 1
            }
            const url = '/user/editUserStatus'
            window.master
                .post(url, {
                    account_id: accountIid,
                    user_id: parseInt(this.user_id),
                    id: id,
                    status: sta
                })
                .then((response) => {
                    this.userslist()
                    console.log(response.data)
                    this.isLoading = false
                    this.Sid = 0
                })
                .catch((error) => {
                    this.isLoading = false
                    this.Sid = 0
                    console.log(error)
                })
        },
        userslist(){
            let t = this;
            window.master
                .get('users/' + t.user_id)
                .then((response) => {
                    // console.log(response.data)
                    // t.userlist = response.data.result;
                    t.userlist = response.data.rs
                    t.listRoles = response.data.roles
                    t.isloaing = false
                    if (t.$route.params.user_id) {
                        console.log('usuario agregado', t.$route.params.user_id);
                        let organizacion_id = t.userlist.find(el => el.account_id == t.$route.params.user_id).user_lists[0].org_id;
                        // console.log('org', m, t.$route.params.user_id, t.userlist)
                        t.ModalBox(
                            'AssignApps',
                            t.$route.params.user_id,
                            organizacion_id
                        )
                    }
                })
                .catch((error) => console.log(error))
        },
        showColor(e, index, i){
            let evt = e;
            // console.log(evt)
            let t= this;
            t.showColorIdx=index;
            t.selectedItem=i;
            setTimeout(() => {
                document.querySelector('.colorAvatarUser').style.top=evt.layerY+'px'
                document.querySelector('.colorAvatarUser').style.left=evt.layerX+'px'
                document.querySelector('.colorAvatarUser').style.opacity=1;
            }, 200);
        },
        closeColor(){
            let t= this;
            document.querySelector('.colorAvatarUser').style.opacity=0;
            t.showColorIdx=null;
            t.selectedItem=null;
        },
        changeColor(e){
            let t=this;
            let color = e.hex;
            t.filterItems[t.showColorIdx].color=color;
            let url = "/users/setColor";
            let data = {
                color: color,
                account_id: t.filterItems[t.showColorIdx].account_id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
            };
            // console.log('color', e, data)
            window.master.post(url, data)
            .then((response) => {
                let r = response.data;
                if(!response.status){
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: 'Failed to save',
                    });
                    t.filterItems[t.showColorIdx].color=t.selectedItem.color;
                }
                t.closeColor()
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error:",
                    text: error,
                });
            });
        },
    },
    watch: {
        getReturnUsers: function () {
            if (this.getReturnUsers) {
                this.userslist()
                this.closeModalBox()
                this.$store.commit('core/session/SET_RETURN_USER', false)
            }
        },
        userlist: function(){
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "actuUsersRoles" && t.getPusher.data.user_id == window.master_client){
                    console.log('getPusher', t.getPusher);
                    t.getRoles();
                }
            }
        },
    },
    computed: {
        ...mapGetters('core/session', ['getReturnUsers']),
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher"
        }),
        filterItems: function () {
            var self = this
            return this.userlist.filter(function (item) {
                // console.log('item', item)
                if (self.search.length > 0) {
                    if (
                        item.name &&
                        item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                    ) {
                        return item
                    }else if (
                        item.username &&
                        item.username.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                    ) {
                        return item
                    }
                    //  else if (
                    //     item.email &&
                    //     item.email.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                    // ) {
                        // return item
                    // }
                    else {
                        for (const i in item.user_lists) {
                            // console.log('i', item.user_lists[i])
                            if (
                                item.user_lists[i].bill_company_name
                                    .toLowerCase()
                                    .indexOf(self.search.toLowerCase()) >= 0
                            ) {
                                return item
                            }
                        }
                    }
                } else {
                    return item
                }
            })
        },
        isDisabled: function () {
            if (this.f.newPassword === null || this.f.newPassword === '') {
                return true
            } else if (this.f.rePassword === null || this.f.rePassword === '') {
                return true
            } else {
                return false
            }
        },
        continueAdd: function(){
            if(this.userlist.length>=1 && !this.OrgPlan){
                return true;
            }
            else if(this.OrgPlan && this.userlist.length>=10){
                return true;
            }else{ return false; }
        }
    },
    // mounted: function () {
    //     this.ModalBox(
    //         'AssingOrgs',
    //         i.account_id,
    //         0
    //     );
    // },
};
</script>
<style lang="scss" scoped>
.icontri {
    position: absolute;
    top: -21px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #a1e0ae;
    transform: translateX(-50%);
}
.prof_title {
    display: block;
    font-size: 14px;
    margin-top: 60px;
}
.prof_title h4 {
    font-weight: 100;
    font-size: 24px;
    text-align: center;
}
.profile {
    margin-top: 60px;
}
.profile label {
    display: block;
    margin-bottom: 0.1rem;
}
span.avatarDefault {
    width: 40px;
    height: 40px;
    display: flex;
    margin: 0px auto;
    border: 1px solid #ccc;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 900;
    background-color: #5ac146;
    color: #fff;
}
img.avatar {
    width: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
}
#usersList tbody tr td.name {
    width: 100px;
}
#usersList tbody tr td.last {
    width: 100px;
}
#usersList tbody tr td.username {
    width: 200px;
}
#usersList tbody tr td.role {
    width: 180px;
}
#usersList tbody tr td.action {
    width: 92px;
}
#usersList tbody tr td.status {
    width: 50px;
}
.tagsOrg:hover {
    cursor: pointer;
    padding-right: 20px;
}
.tagsOrg {
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #cccccc;
    color: #555;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
}
.Pointer:hover {
    cursor: pointer;
}
.form-control.editables {
    border: 0px;
    box-shadow: none;
    background: transparent;
    font-size: 0.8rem;
    padding-left: 0px;
}
.form-control.editables.roles {
    font-size: 0.73rem;
    padding-right: 0px;
}
.nameA {
    width: 25% !important;
    text-align: right;
    display: inherit !important;
    padding: 0px !important;
    margin-right: 3px;
}
.nameB {
    width: 25% !important;
    text-align: left !important;
    display: inherit !important;
    padding: 0px !important;
}
.email {
    text-align: left !important;
}
.cmini.custom_mini {
    color: #000;
}
.table tbody tr:hover {
    background-color: #f1ffff;
}
.table tbody tr td:hover {
    background-color: #fff;
}
span.status .op-loading {
    height: 20px;
}
span.status .op-loading:after {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top-color: #37344f;
}
.del-org {
    padding: 0px 2px;
    font-size: 10px;
    position: absolute;
    top: 4px;
    right: 2px;
    display: none;
}
.tagsOrg:hover .del-org {
    display: block;
}
.del-org {
    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}
.tooltip {
    background-color: #333;
    font-size: 12px;
    padding: 5px 10px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 5px;
    transform: translateX(-50%);
    width: auto;
    z-index: 15;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 6px 7.5px;
        border-color: transparent transparent #333 transparent;
        position: absolute;
        left: 50%;
        margin-left: -7.5px;
        top: -6px;
    }
}
.search {
    border: 1px solid #555;
}
@media (max-width: 767px) {
    .content-table-user-list {overflow-x: auto;}
}
.addRole{
    color: #42b1ff;
}
.addRole:hover{
    cursor: pointer;
}
.colorAvatarUser{
    position: absolute;
    z-index: 99;
    opacity: 0;
}
</style>
