let grid_taks_generator = function(divisor) {
    let i = document.querySelectorAll(".item-taks");
    let cont = 0;
    let columns = 0;
    let row = 1;
    let v = this;
    v.topMax = 0;
    i.forEach(function (item, index) {
        let w = item.offsetWidth;
        let h = item.offsetHeight;
        if (columns >= divisor) {
            columns = 0;
            row++;
        }
        let l = w * columns;
        let t = 0;
        if (row > 1) {
            t = i[index - divisor].offsetHeight;
        }
        if (row > 2) {
            t =
                parseInt(i[index - divisor].style.top.replace("px", "")) +
                parseInt(i[index - divisor].offsetHeight);
        }
        //debugger;
        if (v.topMax < t) {
            v.topMax = t;
            item.classList.add("maxTop");
        }
        item.style.top = t + "px";
        item.style.left = l + "px";
        cont++;
        columns++;
    });
    if (localStorage.getItem("eje_pagination") == 1) {
        localStorage.setItem("eje_pagination", 0);
        this.heightPage =
            document.body.scrollHeight -
            parseInt(localStorage.getItem("maxScrollY"));
    }
    if (v.nextPage) {
        v.nextPage = false;
        console.log('nextPage', v.nextPage);
    }
};
export default grid_taks_generator;
