let trueRefreshListByJobs = function(pusher) {	
    if(this.accessJobsApp) {
        let d = pusher.item;
        let id_customer = d.id_customer
        let id_invoice = d.id_invoice
        let job = d.job
        let list = this.InvoicesList
        for (const i in list) {
            if (list[i].id === id_invoice) {
                this.InvoicesList[i].job.id = 0
                this.InvoicesList[i].job.id_status = 0                        
                this.InvoicesList[i].job.statusName='';                    
                if (id_customer > 0) {
                    this.InvoicesList[i].job.id = job;
                    this.InvoicesList[i].job.id_status = d.job_status;
                    if(d.job_status == 1){ this.InvoicesList[i].job.statusName='Pending'; }
                    if(d.job_status == 2){ this.InvoicesList[i].job.statusName='In progress'; }
                    if(d.job_status == 4){ this.InvoicesList[i].job.statusName='Completed'; }                    					
                }                
            }             
        }      
    }
};
export default trueRefreshListByJobs;
