<template>
	<div class="card">
		<div class="card-header">
			<span style="font-size: 18px;" v-if="dataSales.numdoc != null && dataSales.numdoc!=''">
					Edit Estimate # {{ dataSales.numdoc }}
			</span>
			<span style="font-size: 18px;" v-else>
				New Estimate
			</span>
            <i
                class="fas fa-times"
                style="
                    position: absolute;
                    top: 9px;
                    right: 11px;
                    font-size: 24px;
                    cursor: pointer;
                "
                @click="$parent.CloseModalPopFull()"
            ></i>
		</div>
		<div class="card-body">
			<section class="content-est">				
				<div class="row" v-if="isLoading">
					<Loader style="margin: auto;"></Loader>
				</div>
				<div class="row" v-show="!isLoading">
					<div class="col-md-12">
						<creatCustomer v-show="step==1"></creatCustomer>
						<crearEst v-show="step==2"></crearEst>
					</div>
				</div>
			</section>
		</div>
		
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import creatCustomer from "@/apps/Marketea/components/Sales/creatCustomer.vue";
import crearEst from "@/apps/Marketea/components/Sales/crearEst.vue";
export default {
	name: 'estimateBilling',
    components: {
        Loader,
        creatCustomer,
        crearEst,
    },
	props: {
		dataSales: {
			type: Object, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: () => {}
		},
	},
	data () {
		return {
			isLoading: false,
			step: 1,
			customerId: 0,
			profile: {},
		}
	},
	created(){
		console.log('dataSales', this.dataSales);
		this.initEst();
	},
    methods: {
    	initEst(){
    		let t = this;
    		t.step=1;
    		t.profile = t.dataSales.perfil;
    		console.log('init estimateBilling');
    	},
    }
}
</script>
<style lang="css" scoped>
	.content-est{
		position: relative;
    	height: calc(100vh - 150px);
    	overflow-y: auto;
    	overflow-x: hidden;
    	width: 100%;
    	max-width: 1270px;
    	padding: 10px;
		margin: auto;
	}
	.modal-full.show .card {
    	margin-left: 170px;
    	box-sizing: content-box;
    	padding: 10px;
    	margin-top: 40px;
	}
</style>
