<template>
	<div>
		<div class="title_app_home">
			My Apps<br>
			<small style="font-size: 12px;" >(Click to enter) </small>
		</div>
		<div  v-if="viewApps" class="viewApps">
			<div class="main_apps_home">
				<card-app @clickAction="actionApp($event)" typeEnter="2" :items="newListApps" :appDefault="selectedAppDefault"></card-app>
				<!-- <div
					v-for="(i,index) in newListApps"
					v-bind:key="index"
					class="item_home"
					v-if="i.assingStatus!=0 && i.statusApp!=3"
				>
					<span class="app_default">
						<label>
							<span>Default</span>
							<input type="radio" :value="i.id" :checked="selectedAppDefault==i.id" @change="setAppDefault(i.id,index)"/>
						</label>
					</span>
					<div class="item_app" @click="linkApps(i.id,index)">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="billing" v-if="i.id==1" src="@/core/assets/img/icon_billing.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="project" v-else-if="i.id==3" src="@/core/assets/img/icon_project.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="sites" v-else-if="i.id==2" src="@/core/assets/img/icon_sites.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="jobs" v-else-if="i.id==4"  src="@/core/assets/img/icon_jobs.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="calculator" v-else-if="i.id==5"  src="@/core/assets/img/icon_calculator.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="slabs" v-else-if="i.id==7"  src="@/core/assets/img/icon_slabs.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="marketea" v-else-if="i.id==8"  src="@/core/assets/img/icon_bsite_white.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="display" v-else-if="i.id==10"  src="@/core/assets/img/icon_display.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="dimenu" v-else-if="i.id==11"  src="@/core/assets/img/icon_dimenu.svg">
						<img v-bind:class="['iconapp', (i.statusApp==2) ? 'disabled ' : '']" alt="Marketea" v-else src="@/core/assets/img/icon_auxiliar.svg">
						<span v-if="i.id==8" class="bsite">bSite</span><span v-else>{{ i.name }}</span>
					</div>
				</div> -->
			</div>
		</div>
		<div v-if="newListApps!=null && newListApps.length==0" class="text-center" style="padding-top:0px;">
			<div class="alert alert-warning" v-if="user_type==0" style="max-width:750px; margin: auto; padding: 5px 0 0;">
				<h3 style="font-size: 18px;margin: 0;">
					This Organization doesn’t have any application yet,
					<br>it is time to install your favorite application in the list below!
					<br>
					<i class="fa fa-chevron-down"></i>
				</h3>
			</div>
			<div v-else class="alert alert-warning">
				<h3 style="font-size: 18px;margin: 0;">There are no organizations assigned to this account, contact your system administrator</h3>
			</div>
		</div>
	</div>
</template>
<script>
	import cardApp from "@/core/components/Home/cardApp";
	import { mapGetters } from 'vuex'
	export default {
		name: 'Applications',
		components: {
			'card-app': cardApp,
		},
		data () {
			return {
				listApps: [],
				newListApps: [],
				viewApps: true,
				iconapp : null,
				user_type : window.user_type,
				statusPlanBilling: 1,
				nameDefaultApp: 'app_default'+window.localorgdata,
				selectedAppDefault: 0,
			}
		},
		created() {
			let t= this;
			//t.listadoApps();
			t.listApps = t.$store.getters['core/apps/applistorgs'];
			t.statusPlanBilling = localStorage.getItem('statusBilling');
			console.log('statusPlanBilling', t.statusPlanBilling);
            setTimeout(function(){
                if(localStorage.getItem('appStart')){
                    console.log('redireccionando al app = ', localStorage.getItem('appStart'));
                    let app_id = localStorage.getItem('appStart');
					localStorage.setItem('appDefault', app_id);
                    t.linkApps(app_id, 0);
                }else{
					console.log('fallo appStart', localStorage.getItem('appStart'))
					t.getAppDefault();
				}
            }, 500);
		},
		methods: {
			getAppDefault(){
				let t = this;
				if(sessionStorage.getItem(t.nameDefaultApp)!=null){
					let app_default = JSON.parse(sessionStorage.getItem(t.nameDefaultApp));
					// console.log('getAppDefault', app_default);
					let valid = false;
					t.newListApps.filter(r => {
						if(r.id==app_default.app_id){
							valid = true;
						}
					});
					if(valid){
						t.selectedAppDefault=app_default.app_id;
						if(localStorage.getItem('appDefault')==null){
							console.log('redirect app_default');
							localStorage.setItem('appDefault', app_default.app_id);
							t.linkApps(app_default.app_id, app_default.index);
						}else{
							console.log('NO redirect app_default');
						}
					}
				}
			},
			actionApp($event){
				let t = this;
				if($event.nameAction=='setAppDefault'){
					console.log($event.nameAction)
					t.setAppDefault($event.app_id, $event.index);
				}
				if($event.nameAction=='linkApps'){
					t.linkApps($event.app_id, $event.index);
				}
			},
			setAppDefault(app_id, index){
				let app_default= {app_id: app_id, index: index};
				this.selectedAppDefault=app_id;
				sessionStorage.setItem(this.nameDefaultApp, JSON.stringify(app_default));
				console.log(sessionStorage.getItem(this.nameDefaultApp))
			},
			listadoApps() {
				let org = localStorage.getItem('mark_client_data');
				if (org > 0) {
					this.$store.commit('set_isLoading', true);
					window.master.get('getapp/' + org)
					.then(response => {
						let r = response.data.r; // listado de app disponibles 
						if (r.length > 0) { this.viewApps = true; }
						else { this.viewApps = false; }
						this.listApps = r;
						this.$store.commit('set_isLoading', false);
						// console.log('app instaladas',this.listApps);
					})
					.catch(error => {
						console.log(error);
						this.$store.commit('set_isLoading', false);
					});
				}
			},
			msjErrorAccessApp() {
				let msj = this.$store.getters["core/apps/getMsgAccessDeneid"];
				this.$store.commit("core/setAlertSimple", {
					icon: 'error',
					title: msj.title,
					text: msj.msg,
					showConfirmButton: false,
					timer: 4000,
					show: true
				});
			},

			linkApps(app_id, index) {
				//if(window.OrgPlan > 1 || this.appsorg[index].id == 1){
					if (!this.appsorg[index]) {
						console.log('error acceso')
						this.msjErrorAccessApp();
					}
					else if (!this.appsorg[index].privilegies) {
						console.log('error privilegies')
						this.msjErrorAccessApp();
					}
					else
					{
						let continuar = true;
						this.$store.dispatch("core/apps/getAppStatus", this.appsorg[index].id).then(r => {
							console.log('getAppStatus', r);
							if(r.appStatus < 1){
								continuar = false; // cambiar a false para bloquear acceso
								let name = this.appsorg[index].name;
								let msj = this.$store.getters["core/apps/getMsgAccessSuspended"];
								let msg = msj.msg.replace('[nameApp]', name);
								this.$store.commit('core/setNotiPush', {
									title: msj.title,
									text: msg,
									show: true,
									group: "notiPush",
									type: '5', // 1=success|2=info|3=error|4=loading|5=warning
									duration: 15000
								});
							}
							if(continuar){
								console.log('linkApps', this.appsorg[index]);
								this.$store.commit('set_isLoading', true);
								let app = {app_id, index};
								this.$store.dispatch('core/apps/linkApp', app).then(r => {
									console.log(r);
									if (r.status) {
										console.log('eliminar appStart');
										localStorage.removeItem('appStart');
										localStorage.setItem('nameApp', r.nomApp);
										this.$router.push({name: r.nomApp });
									}
								})
								.catch(error => {
									console.log(error);
								});
							}
						});
					}
				//}else{
				//	this.$notify({group: "notiPushPremium", duration: 100000});
				//}
			},
		},
		watch: {
			'appsorg': function() {
				this.listApps = this.appsorg;
				console.log('app instaladas', this.listApps);
			},
			listApps: function(){
				this.newListApps = this.listApps;
			},
	        accitonPusher: function () {
	            let t = this;
	            if (t.accitonPusher != undefined) 
	            {
	            	if(t.accitonPusher.action === "statusBilling")
	            	{
                    	let data = t.accitonPusher.data;
                    	if(window.localorgdata == data.org_id && window.localAccountID == data.user_id){
							t.statusPlanBilling = data.statusBilling
							localStorage.setItem('statusBilling', data.statusBilling);
							console.log('accitonPusher statusBilling', t.statusPlanBilling);
                    	}
                	}
            	}
            },
		},
		computed: {
        	...mapGetters('core/apps', {appsorg: 'applistorgs'}),
			...mapGetters('core/Org', {statusBilling: 'getStatusBilling'}),
	        ...mapGetters("core", {accitonPusher: "get_accitonPusher"}),
		}, 
	};
</script>
<style lang="css" scoped>
	.main_apps_home .item_home {

	}
	.main_apps_home .item_home img.iconapp.disabled {
    	opacity: 0.4;
	}
	.main_apps_home .item_home span.bsite {
	    text-transform: initial;
	}
	.main_apps_home .item_home span.app_default label {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
	}

	.main_apps_home .item_home span.app_default {
		width: 80%;
	}

	.main_apps_home .item_home span.app_default label span {
		font-size: 12px;
		padding: 0;
		width: auto;
		margin-right: 5px;
	}

	.main_apps_home .item_home:hover span.app_default, 
	.main_apps_home .item_home:hover span.app_default label span {
		transform: initial;
	}
</style>
