import {variables, components, methods, watch, computed} from './tdynamic_js';
export default {
	name: 'tdynamic',
	data(){
		return variables;
	},
	components,
	methods,
	watch,
	computed,
	created(){ 
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.apisAds;
		this.getAds();
	},
	mounted() {
		this.iniMenuAction();	
	},
}
