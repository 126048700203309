<template>
    <div class="card">
        <div class="card-header"><h4>Activation</h4></div>
        <form @submit.prevent="activation">
            <div class=" card-body">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                    <b>Error: </b>{{messageError}}
                                </div>
                                <div class="alert alert-success" role="alert" v-if="messageSuccess != null">
                                    <b>Success: </b>{{messageSuccess}} <a v-if="btn" @click="login" style="color:blue; cursor: pointer;">Go to login </a>
                                </div>                                
                            </div>
                            <div class="form-group col-md-12" style="margin-bottom: 0px;">
                                <div class="password-input" v-if="messageSuccess == null">
                                    <span class="showpassord icon-eye" action="hide" @click="shoPass">
                                        <i class="fas fa-eye" v-if="showpassword"></i>
                                        <i class="fas fa-eye-slash" v-else></i>
                                    </span>
                                    <input id="password" 
                                    :type="typepassword" 
                                    class="form-control"
                                    v-model="userCredenctial.code" 
                                    name="code" placeholder="Code"  
                                    autocomplete="new-code" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="card-footer">
                <div v-if="!isLoading">
                    <button type="submit" @click="activation" class="btn btn-success btn-login" v-if="messageSuccess == null">Activate</button>
                    <!-- <a class="btn btn-sing creat-account" :href="urlPage + 'login'" target="_blank">Go to login </a> -->
                    <a @click="login" class="btn btn-sing creat-account" style="margin: 0px 10px auto;">Go to login </a>
                </div>
                <div v-else>
                    <Loader></Loader>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'formLogin',
    components: {
            Loader
    },
    data () {
        return {
            btn:false,
            messageError: null,
            messageSuccess: null,
            userCredenctial: {
                code: ''
            },
            isLoading: false,
            showpassword: false,
            typepassword: 'password',
            urlPage: window.urlweb
        }
    },
    created(){
        let varsGets =this.libGlobal.getQueryVarsUrl();
        if(varsGets.length > 0){
            console.log('varsGets', varsGets);  
            if(varsGets[0].getKey == 'c'){
                this.userCredenctial.code = varsGets[0].getVal;
                this.activation();
            }
        }
    },
    methods: {
        login(){
            this.$router.push({ name: 'Login' });
        },
        activation(){
            this.isLoading = true;
            this.messageError=null;
            this.messageSuccess=null;
            this.$store.dispatch('core/session/activation', {
                code: this.userCredenctial.code
            })
            .then(response => {
                this.isLoading = false;
                if(!response || !response.data.status){
                    this.messageError= 'incorrect code';
                }else{
                    this.messageSuccess= 'Your email was Verified!';
                    this.btn = true
                    console.log('redirec interno home');   
                }
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.messageError= 'Error de conexion';
            })
        },
        shoPass(){
            this.showpassword = !this.showpassword;
            if(this.showpassword){ this.typepassword = true;}
            else{ this.typepassword= 'password';}
        }
    }
};
</script>

<style lang="css" scoped>
.card {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;
    border-radius: 2px;
    margin-top:40px;
}
.card-header {
    padding: 15px 13px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
.card-header h4 {
    margin-bottom: 0px;
    color: #555;
}
.card-footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
button.btn.btn-success.btn-login {
    padding: 2px 20px;
    border-color: #0d83dd;
    background-color: #0d83dd;
    box-shadow:none;
}
.btn-sing {
    padding: 2px 10px;
    color: #1f1f1f ;
    background-color: #01972000 ;
    border-color: #1f1f1f ;
    border-radius: 2px;
    float: right;
}
.creat-account{
    border-color: #aaa ;
}
form label {
    display: block;
    margin-bottom: 6px;
    line-height: 19px;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    text-align: left;
    white-space: normal;
}
.form-control {
    border: 1px solid #bdbdbd;
    background-color: #fbfbfb !important;
    height: 50px;
}
.form-control:focus {
    background-color: #fbfbfb !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus 
input:-internal-autofill-selected
{
    -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
    background-color: #fbfbfb !important;
}
.showpassord{
    height: 50px;
    border-left: 1px solid;
}
.note {
    margin-top: 6px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}
.note a {
    font-size: 13px;
}
@media (max-width: 1190px){
    .btn.btn-success.btn-login,
    .btn.btn-sing.creat-account {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 40px;
        font-size: 20px;
        float: initial;
    }
}
@media (max-width: 767px){
    .card {
        max-width: 330px;
        margin: auto;
    }
}
@media (max-width: 350px){
    .card {
        max-width: 300px;
        margin: auto;
    }
}
</style>
