<template>
	<div class="row">
		<div class="col-6 text-left">
			<div class="row">
				<div class="icono-banking">
					<i
						v-if="selectBank.account_type == 1"
						class="fa fa-university fa-2x"
					></i>
					<i
						v-if="selectBank.account_type == 0"
						class="fas fa-money-check-alt fa-2x"
					></i>
				</div>
				<div class="details-banking">
					<strong
						class="text-info"
						style="font-size: 16px"
					>
					{{ selectBank.bank_name }}
				</strong>
				<br>
				<small>
					****{{ selectBank.account_number.slice(-4) }}
				</small>
			</div>
		</div>
	</div>
	<div class="col-6 text-right" style="margin-bottom: 10px">
		<button class="btn btn-info btn-sm mr-2" @click="backList()">
			<i class="fas fa-arrow-left"></i> Back to list
		</button>
	</div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'HeaderTransac',	         
	data () {
		return {
			selectBank: {
				account_type: 1,
				bank_name: "",
				account_number: "1234",
			}
		}
	},
	created() {
		this.selectBank = this.$store.getters['ModApps/billing/getSelectBank'];
	},
	methods: {
		backList() {
			let selectBank = {}
			selectBank.id = 0;
			selectBank.account_type = 0;
			selectBank.bank_name = null;
			selectBank.account_number = 0;
			selectBank.list = true;
			selectBank.tablas = false;
			selectBank.unaccepted = true;
			selectBank.accepted = false;
			this.$store.commit("ModApps/billing/SetSelectBank", selectBank);
			this.$router.push({ name: "Banking" });
		}
	}
};
</script>
<style lang="css" scoped>
.icono-banking {
    float: left;
    width: 100%;
    max-width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
}
.details-banking {
    float: left;
    width: 100%;
    max-width: 180px;
    height: 40px;
    display: inline-block;
}
</style>
