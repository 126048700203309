<template>
	<div class="container-fluid" id="content-customer">
		<div class="row">
			<div
				class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
				id="s-f-r"
				style="padding: 0px"
			>
				<div class="card box_custom search-box">
					<headList></headList>
					<searchList></searchList>
					<div id="demo"
						:class="[
						'comment-widgets srollclientes mobile_display',
						showCustomer == true ? 'show_customer' : '',
						]"
					>
						<bodyList></bodyList>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-8 col-xl-9 right-contenendor">
				<div class="card">
					<div class="content-default-customer"
						@statusContent="EmitMethodStatus"
						v-if="ShowDefaultContentCustomer"
					>
						<counterCustomer></counterCustomer>
					</div>
                    <div class="content-detail-customer" v-if="detailcontent">
						<dataCustomer></dataCustomer>
                    </div> 					                  
					<div class="content-form-register" v-if="registerForm">
						<formCustomer></formCustomer>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import counterCustomer from '@/apps/billing/components/customer/counterCustomer'
import dataCustomer from '@/apps/billing/components/customer/dataCustomer'
import headList from '@/apps/billing/components/customer/list/headList'
import searchList from '@/apps/billing/components/customer/list/searchList'
import bodyList from '@/apps/billing/components/customer/list/bodyList'
import formCustomer from '@/apps/billing/components/customer/formCustomer'
export default {
	name: "CustomerPage",
	components: {
        counterCustomer,
        dataCustomer,
        headList,
        searchList,
        bodyList,
        formCustomer,
	},
	data () {
		return {
			isLoading: false,
            showCustomer: false,
            ShowDefaultContentCustomer: true,
            registerForm: false,
            customerDetail: null,
            detailcontent: false,          
            show_customer: true,   
			customerID: 0,       
		};		
	},
	created () {
		this.$store.commit('ModApps/billing/setCustomerCountTotal', null);
	},
	mounted () {
	},
	directives: {
	},
	methods: {
		EmitMethodStatus() {
			this.ShowDefaultContentCustomer = true;
		},
		showformregister() {
			this.ShowDefaultContentCustomer = false;
			this.detailcontent = false;
			this.registerForm = true;
			if (window.innerWidth <= 767) {
				document.getElementById("s-f-r").style.display = "none";
			}
		},
		showDetails(){
			this.isLoading = true
			this.detailcontent = true
			this.ShowDefaultContentCustomer = false
			this.registerForm = false
			let t = this
			setTimeout(function () {
				t.isLoading = false
			}, 1000);			
		}
	},
	computed: {
		...mapGetters("ModApps/billing", { 
			getCustomerID: "getCustomerID",
			getFormCustomer: "getFormCustomer",
			getShowSetting: "getShowSetting",
			getCustomerSearch: "getCustomerSearch",
			getResponseMail: "getResponseMail",
		}),
	},
	watch: {
		getShowSetting: function () {
			this.show_customer = this.getShowSetting.show_customer;
		},
		getFormCustomer: function () {
			if(this.getFormCustomer) {
				this.showformregister();
			}
			else if(this.getCustomerID > 0) {
				this.showDetails();
			}
			else {
				this.detailcontent = false;
				this.registerForm = false;
				this.ShowDefaultContentCustomer = true;
			}
		},
		getCustomerID: function () { 
			if(this.getCustomerID > 0) {
				this.detailcontent = true;
				this.ShowDefaultContentCustomer = false;
				this.registerForm = false;
				this.showDetails();
			}
			else if(this.getCustomerID == 0 && this.getFormCustomer) {
				this.showformregister();
			}
			else {
				this.ShowDefaultContentCustomer = true;
				this.detailcontent = false;
				this.registerForm = false;
			}
		},
		getCustomerSearch: function () {			
			let element = document.getElementById("demo");
			if (this.getCustomerSearch.length > 0) {
				element.classList.remove("mobile_display");				
			} else {
				element.classList.add("mobile_display");				
			} 
		},
		getResponseMail: function () {
			let item = this.getResponseMail;
			if (item.DataItem.send == 1) {
				item.DataItem.send = 0;
				this.$store.commit("core/setCloseModal", {
					action: 'sendMailInvoice',
				});
				this.$notify({
					group: "noti",
					title: "Success",
					type: "success",
					text: "Send Mail!",
				});
				this.$store.commit("ModApps/billing/SetResponseMail", null);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
#content-customer {
	margin-top: 5px;
	margin-left: -5px;
	padding-top: 0px;
	padding-left: 15px;
	padding-right: 10px;
	background: #fff;
}
.right-contenendor {
	padding: 0px;
	border-left: 1px solid #c9cbcc;
}
@media (min-width: 1366px){
	.srollclientes{
		height: 70vh;
	}
}
@media (min-width: 1450px){
    .srollclientes{
		height: 77vh;
	}
}
@media (max-width: 767px) {
	.mobile_display {
		display: none !important;
		position: absolute;
		left: 0;
		z-index: 9;
		width: 100%;
		height: 100vh;
	}
	.show_customer {
		display: block !important;
	}
}
</style>
