var bSite = "/bSite/";
export default {
	pull(idx){
        let urls = [
            'getAll',
            'getAllActive',
            'urlslug',
            'getSlugActive',
            'getId',
            'getValidSlug',
            'getSufUrl',
            'getAllMiniSite',
            'getItemMiniSite',
            'getPlugins',
            'getNotiForm',
        ]
		return urls[idx];
	},
	set(idx){
        let urls = [
            'createCard',
            'editCard',
            'setSufUrl',
            'setCover',
            'setPicProfile',
            'setContacts',
            'changeStatusCard',
            'set_find_me_at',
            'set_btns_action',
            'deleteCard',
            'rotateImg',
            'setOptionsTemplate',
            'setMiniSite',
            'uploadImgMiniSite',
            'delImgMiniSite',
            'setNotiForm',
            'setAutoResponder',
        ]
		return urls[idx];
	},
};
