let editCols = function() {		
	let t = this;
	//t.isLoading = true;
	window.billing.post("/columnasInvoice", {
		user_idd: window.master_client,
		org_id: window.localorgdata,
		typeUser: window.user_type,
		user_id: window.localorgdata,
		oauth: window.oauth_m,
		columnas: this.cols.toString()
	})
	.then((response) => {
		console.log('columnasInvoice', response.data.r);
		t.showCols(response.data.r.meta_value);
		//this.searchFilter(1);
		if(response.data.r){
			let cols = response.data.r.meta_value;
			console.log('setting_columnas', cols)
			// this.showCols(cols);
		}
	})
	.catch((error) => {
		console.log(error);
		t.isLoading = false;
	});
};
export default editCols;
