<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full" data-boxed-layout="full">
        <div class="page-wrapper homepage">
            <slot>
                <div class="bgloading" v-if="isLoading"><Loader></Loader></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 hidden-xs hidden-sm">
                            <infoApp></infoApp>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 formloginhome">
                            <ConfirmPingSMS v-if="pingSMS"></ConfirmPingSMS>
                            <formLogin v-else></formLogin>
                        </div>
                    </div>
                </div>
            </slot> 
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/Loader'
    import infoApp from '@/core/components/Reseller/Login/infoApp'
    import formLogin from '@/core/components/Reseller/Login/formLogin'
    import ConfirmPingSMS from '@/core/components/Reseller/Login/ConfirmPingSMS'
    import { mapGetters } from 'vuex'
    export default {
        name: 'LoginLayout',
        components: {
            Loader,
            infoApp,
            formLogin,
            ConfirmPingSMS,
        },
        data () {
            return {
                isLoading: false,
                urlPage: window.urlweb,
                pingSMS: false,
                registrarTlf: false,
            }
    },
    created () {
        this.$store.commit('set_isLoading', false);
        localStorage.removeItem('disconnected');
        this.validSesion();
    },
    methods: {
        validSesion(){
            let t = this;
            t.isLoading= true;
            t.$store.dispatch('core/reseller/getSessionReseller')
            .then(response => {
                console.log(response);
                if(response){
                    let r = response.data;
                    console.log('validToken login ', r);
                    if(r.tokenValid)
                    {
                        console.log('redireccionando OrgbyReseller');
                        t.$store.commit('set_isLoading', true);
                        localStorage.setItem('ui', 'Reseller');
                        t.$router.push({ name: 'OrgbyReseller' });
                    }
                    else
                    {
                        console.log('no hay entrada al sistema ', r.tokenValid);
                        t.isLoading= false;
                        // debugger;
                        if(localStorage.getItem('oauth_m')!=null){
                            t.$router.push({ name: 'Login' });
                        }
                        else {
                            t.logout();
                            if(!localStorage.getItem('access_web')){localStorage.setItem('access_web', 0)}
                            if(r.sms){
                                localStorage.setItem('loginSMS', 1);
                            }else{localStorage.setItem('loginSMS', 0);}
                            let validLogin = false;
                            if(localStorage.getItem('ui')==null || localStorage.getItem('ui')=='Login'){
                                localStorage.setItem('ui', 'Reseller');
                                validLogin = true;
                            }
                            else if(localStorage.getItem('ui')=='Reseller'){
                                validLogin = true;
                            }
                            if(!validLogin){
                                console.log('ui', localStorage.getItem('ui'));
                            }
                        }
                    }
                }else{
                    console.log('eliminar datos antiguos');
                    t.logout();
                }
                t.isLoading= false;
            })
            .catch((error) => {
                //console.log(error);
                console.log('error al iniciar sesion', error);
                t.isLoading= false;
                t.logout();
            });            
        },
        logout() {
            this.$store.dispatch('core/reseller/destroyTokenReseller')
            .then(response => {
                console.log('destroyToken', response);
                this.isLoading= false;
            })
            .catch(error => {
                console.log('destroyToken', error);
                this.isLoading= false;
            });
        }
    },
    watch: {
        'get_isLoading': function() {
            this.isLoading = this.get_isLoading;
            if(this.get_isLoading) {
                this.$notify({
                    group: 'noti',
                    type: 'success',
                    title: 'Accessing',
                    text: 'redirecting...'
                });
            }
        }
    },
    computed: {
        ...mapGetters({get_isLoading: 'get_isLoading'}),
  }
  };
</script>
<style lang="scss" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .bgloading {
        padding-top: 200px;
        align-items: initial !important;
    }
    .container {
        max-width: 1000px;
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        background-color: #f0f2f5;
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 50px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 500px;
        }   
    }
</style>
