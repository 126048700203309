let jobstoCompleted = function(id) {
    let t = this;
    let url = t.apis.jobstoCompleted()
    window.master.post(url, {
        user_id: window.master_client,
        org_id: window.localorgdata,
        id_jobs: id,
    })
    .then((response) => {
        console.log(response.data);
        t.$notify({
            group: "noti",
            type: "success",
            title: "Job Complete!",
            text: "Successful",
        });
        let jobstoCompleted = response.data.s.r2.id;
        if (id > 0) {
            //comprobar idx sea autentico
            for (let j in this.listTaks) {
                if (this.listTaks.hasOwnProperty(j)) {
                    if (this.listTaks[j].id_i == id && jobstoCompleted == id) {
                        let idx = j;
                        if (t.$router.currentRoute.name != "JobsComppleted") {
                            t.listTaks.splice(idx, 1);
                            t.reEscribirLista(idx);
                            t.starGrid();
                        }
                    }
                }
            }
        }
        t.$store.commit("ModApps/jobs/set_editTask", {});
        let tag_id = t.id_tags.length > 0 ? t.id_tags[0] : 0;
        t.update_JOPending(tag_id);
    })
    .catch((error) => {
        console.log(error);
        t.$store.commit("core/setNotiPush", {
            group: "notiPush",
                    type: '3', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Error",
                    text: "The job could not be completed, try later or try directly from the Jobs app.",
                    show: true,
                    duration: 20000
                }); 
    });
};
export default jobstoCompleted;
