var jobs = "/jobs/";
export default {  
	getTaks(){
		return jobs+"jobstaskassinguser";
	},
	getUsers(){
		return jobs+"users/" + window.localAccountID;
	}, 
	addJobs(){
		return jobs+"addJobsTaskAssingUser";
	},  
	getSetting(){
		return jobs+"getjobssetting";
	},         
};
