let deleteItems = function() {
	let t =this;
	t.selectAll=false;
	for (let i in t.selected) {
		if(t.selected[i]) {
			for (let j in t.dataT) {
				if(t.dataT[j] && t.dataT[j].id == t.selected[i]) {
					t.dataT.splice(j, 1);
				}
			}
		}
	}
	t.selected=[];
	if(t.dataT.length > 0) {
		t.dataTOLD = t.fillInData(t.dataT);
	} else {
		t.dataTOLD =[];
	}
};
export default deleteItems;
