<template>
    <div class="card">
        <div class="card-header">
            <h4 v-if="$parent.registrarTlf">Register Phone</h4>
            <h4 v-else>Confirm SMS</h4>
        </div>
        <form @submit.prevent="sendform" v-if="$parent.registrarTlf">
            <div class=" card-body">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                    <b>Error: </b>{{messageError}}
                                </div>
                                <!-- <label for="email">E-mail</label> -->
                                <div class="password-input">
                                    <span class="showpassord " action="hide" >
                                        <i class="fas fa-phone"></i>
                                    </span>
                                    <input id="phone" type="text" class="form-control" name="phone" v-model="register.phone" placeholder="Phone" autocomplete="phone" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="card-footer">
                <div v-if="!isLoading">
                    <button type="submit" class="btn btn-success btn-login">Register</button>
                    <button type="button" class="btn btn-sing creat-account" @click="backLogin">Go to login </button>
                </div>
                <div v-else>
                    <Loader></Loader>
                </div>
            </div>
        </form> 
        <form @submit.prevent="sendConfirmCode" v-else>
            <div class=" card-body">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                    <b>Error: </b>{{messageError}}
                                </div>
                                <div class="alert alert-info" role="alert" v-if="messageInfo != null">
                                    <b>Info: </b>{{messageInfo}}
                                </div>
                                <!-- <label for="email">E-mail</label> -->
                                <div class="password-input">
                                    <span class="showpassord " action="hide" >
                                        <i class="fas fa-user-lock"></i>
                                    </span>
                                    <input id="code" type="text" class="form-control" name="code" v-model="codeSMS" placeholder="Code" autocomplete="code" required maxlength="4">
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div> 
            </div>
            <div class="card-footer">
                <div v-if="!isLoading">
                    <button type="submit" class="btn btn-success btn-login">Confirm</button>
                    <button type="button" class="btn btn-sing creat-account" @click="backLogin">Go to login </button>
                </div>
                <div v-else>
                    <Loader></Loader>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'ConfirmPingSMS',
    components: {
        Loader,
    },
    data () {
        return {
            messageError: null,
            messageInfo: 'enter the code sent by SMS to your registered number.',
            isLoading: false,
            urlPage: window.urlweb,
            codeSMS: '',
            register: {
                phone: '',
                user_idR: 0,
            },
        }
    },
    methods: {
        backLogin(){
            let t = this;
            t.isLoading= true;
            t.$store.dispatch('core/reseller/destroyTokenReseller')
            .then(response => {
                console.log('destroyTokenReseller', response);
                t.isLoading= false;
                t.$parent.registrarTlf = false;
                t.$parent.pingSMS = false;
            })
            .catch(error => {
                console.log('destroyToken', error);
                t.isLoading= false;
                t.$parent.registrarTlf = false;
                t.$parent.pingSMS = false;
            });
        },
        sendConfirmCode() { 
            // debugger;
            let t =this;
            t.messageInfo=null;
            t.isLoading = true;
            t.$store.dispatch('core/session/confirmSMS', {
              code: t.codeSMS,
            })
            .then(response => {
                t.isLoading = false;
                if(!response){
                    //t.registrarTlf= true;
                    t.messageError= 'Error code';
                }else{
                    console.log('var Logged In'); 
                    t.$store.commit('set_isLoading', true);
                    t.$store.commit('core/reseller/setLoggedInResller');
                    window.top.location.replace(window.self.location.href);
                }
            }).catch((error) => {
                console.log(error);
                t.isLoading = false;
                t.messageError= 'Error de conexion';
            })
        },
        registerSMS() {
            let t = this;
            t.isLoading = true;
            t.messageError=null;
            console.log('u', t.register.user_idR)
            t.$store.dispatch('core/session/registerSMS', {
              user_id: t.register.user_idR,
              phone: t.register.phone,
            })
            .then(response => {
                t.isLoading = false;
                if(!response.status){
                    t.registrarTlf= true;
                    t.messageError= 'Error register';
                }else{
                    console.log('redirec interno home'); 
                    t.$parent.registrarTlf = false;
                    t.$parent.pingSMS = false;
                }
            }).catch((error) => {
                console.log(error);
                t.isLoading = false;
                t.messageError= 'Error de conexion';
            })
        },
    }   
};
</script>

<style lang="css" scoped>
input#code {
    font-size: 20px;
    letter-spacing: 8px;
    font-family: monospace;
}
.card {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;
    border-radius: 2px;
    margin-top:40px;
}
.card-header {
    padding: 15px 13px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
.card-header h4 {
    margin-bottom: 0px;
    color: #555;
}
.card-footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
button.btn.btn-success.btn-login {
    padding: 2px 20px;
    border-color: #0d83dd;
    background-color: #0d83dd;
    box-shadow:none;
}
.btn-sing {
    padding: 2px 10px;
    color: #1f1f1f ;
    background-color: #01972000 ;
    border-color: #1f1f1f ;
    border-radius: 2px;
    float: right;
}
.creat-account{
    border-color: #aaa ;
}
form label {
    display: block;
    margin-bottom: 6px;
    line-height: 19px;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    text-align: left;
    white-space: normal;
}
.form-control {
    border: 1px solid #bdbdbd;
    background-color: #fbfbfb !important;
    height: 50px;
}
.form-control:focus {
    background-color: #fbfbfb !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus 
input:-internal-autofill-selected
{
    -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
    background-color: #fbfbfb !important;
}
.showpassord{
    height: 50px;
    border-left: 1px solid;
}
.note {
    margin-top: 6px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}
.note a {
    font-size: 13px;
}
@media (max-width: 1190px){
    .btn.btn-success.btn-login,
    .btn.btn-sing.creat-account {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 40px;
        font-size: 20px;
        float: initial;
    }
    .Ajust {
        width: 74% !important; 
        margin-top: -13px !important;
    }      
}
@media (min-width: 1190px){
    .Ajust {
        width: 90px !important;
        margin-right: 15px !important;
        margin-top: -42px !important;
        position: absolute !important;
        right: 1px !important;
    }      
}

@media (max-width: 767px){
    .card {
        max-width: 330px;
        margin: auto;
    }
}
@media (max-width: 350px){
    .card {
        max-width: 300px;
        margin: auto;
    }
}
</style>
