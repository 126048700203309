let getJobsDash = function() {
    let tags = this.get_menuTags;
    let t = this.$route.params.id;
    for (let i in tags) {
        if (parseInt(t) == parseInt(tags[i].id)) {
            this.tagsModalName = tags[i].name;
            this.tagsModalId = tags[i].id;
        }
    }
    if (this.$route.params.id != undefined) {
        this.id_tags = [];
        this.id_tags.push(parseInt(this.$route.params.id));
    }
    if (this.id_tags.length > 0) {
        document.styleSheets[0].addRule(
            ".alertx.alert-successx.alertTrue",
            "display: block !important;"
        );
    }
    let url = this.apis.get() + this.query;
    if (this.typez == 2) {
        url = this.apis.getRol() + this.query;
    }
    let billing = 0;
    let b = this.$store.getters.applistorgs;
    for (const i in b) {
        if (b[i].name === "Billing" && b[i].privilegies == true) {
            billing = 1;
        }
    }
    let id_status = [1, 2];
    if (this.privilegies_dash == 1) {
        billing = 1;
    }
    if (this.$router.currentRoute.name == "JobsComppleted") {
        id_status = [4];
        let getFilterTags = this.$store.getters['ModApps/jobs/getDataFilterTagsUrl'];
        if(getFilterTags.id>0){
            this.id_tags = [getFilterTags.id];
        }
    }
    let v = this;
    if (
        this.next_page_url != null &&
        this.next_page_url.length > 0 &&
        this.nextPage
    ) {
        url = this.next_page_url;
    } else {
        this.listTaksResult = [];
        this.listTaks = [];
    }
    this.isLoading = true;  
    let redirectTagsDefault = false;
    if(!this.$store.getters["ModApps/jobs/getSeeTagsDefault"] && this.tagsDefaulttagsID.length>0 && this.id_tags.length == 0){
        for (let i in this.tagsDefaulttagsID) {
            if(this.tagsDefaulttagsID[i]){
                this.id_tags.push(this.tagsDefaulttagsID[i]);
            }
        }
        if(this.id_tags.length>1){
        }
        else {
            // si es una sola enviamos a la ruta de tag
            redirectTagsDefault = true;
            this.$router.push({ 
                name: 'JobsTags', 
                params: { 
                    id: this.id_tags[0], 
                    tags: "tags" 
                } 
            });
        }
        this.$store.commit("ModApps/jobs/setSeeTagsDefault", true);
    } 
    if(!redirectTagsDefault){
        window.master
        .post(url, {
            user_id_assign: window.localAccountID,
            org_id: window.localorgdata,
            user_id: window.master_client,
            id_tags: this.id_tags,
            order: this.sortBy,
            billing: 1,
            status: id_status,
        })
        .then((response) => {
            let e = response.data.lista.data; //mostrar data
            // this.listTaks = this.dataJobDash(e);
            this.listCustomer = response.data.cusList;
            //let c = response.data.result;
            //debugger;
            if (response.data.tagsAdmin) {
                v.editorTag = true;
                v.editarTags = response.data.tagsAdmin;
                v.btnAddJobs = false;
                if (v.id_tags.length > 0) {
                    for (let i in v.editarTags) {
                        if (v.editarTags[i].tags_id == v.id_tags[0]) {
                            v.btnAddJobs = true;
                        }
                    }
                }
            }
            let list = v.dataJobDash(e, v.editorTag, v.editarTags); //filter
            v.next_page_url = response.data.lista.next_page_url;
            // console.log('v.next_page_url', v.next_page_url);
            if (v.nextPage) {
                for (let j in list) {
                    let valid = true;
                    for (let k in v.listTaksResult) {
                        if (v.listTaksResult.hasOwnProperty(k)) {
                            if (v.listTaksResult[k].id_i == list[j].id_i) {
                                valid = false;
                            }
                        }
                    }
                    //debugger;
                    if (valid) {
                        v.listTaks.push(list[j]);
                    }
                }
            } else {
                v.listTaks = list;
            }
            v.reEscribirLista();
            v.isLoading = false;
            let tag_id = v.id_tags.length > 0 ? v.id_tags[0] : 0;
            if(tag_id == 0 && this.$router.currentRoute.name == "JobsComppleted") {
                let getFilterTags=this.$store.getters['ModApps/jobs/getDataFilterTagsUrl'];
                if(getFilterTags.id>0){
                    tag_id = [getFilterTags.id];   
                }
            } else {
                v.filterTags()
            }
            v.update_JOPending(tag_id);
            v.starGrid();
            v.footerData()
        })
        .catch((error) => {
            this.isLoading = false;
            console.log(error);
        });
    }
};
export default getJobsDash;
