<template>
	<div></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'alertsConfirm',
	data () {
		return {
		}
	},
	created() {
		let init = this.$store.getters['core/getAlertSimple'];
		//console.log('created alertsConfirm', init);
		if(init.show){
			this.alertSimple(init);
		}
	},
	methods: {
		alertSimple (i) {
			this.$swal.fire({
				icon: i.icon,
				title: i.title,
				text: i.text,
				showConfirmButton: i.showConfirmButton,
				timer: i.timer
			});
			this.$store.commit("core/setAlertSimpleHide");
		},
		alertConfirm(i){
			/*return new Promise((resolve, reject) => {
				this.$swal({
					title: i.title,
					text: i.text,
					icon: i.icon,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					confirmButtonText: "Yes, Delete it!",
				}).then((result) => {
					this.$store.commit("core/setAlertConfirmHide");
					resolve(result);
				});
			});*/
		}
	},
	watch: {
		'getAlertSimpleShow': function() {
			// console.log('watch alertsConfirm', this.getAlertSimpleShow);
			if (this.getAlertSimpleShow) {
				let g = this.$store.getters['core/getAlertSimple'];
				this.alertSimple(g);
			}
		},
		'getAlertConfirmShow': function() {
			// console.log('watch alertsConfirm', this.getAlertConfirmShow);
			if (this.getAlertConfirmShow) {
				let g = this.$store.getters['core/getAlertConfirm'];
				this.alertConfirm(g);
			}
		}
	},
	computed: {
		...mapGetters("core", { 
			getAlertSimpleShow: "getAlertSimpleShow", 
			getAlertConfirmShow: "getAlertConfirmShow" 
		})
	}
};
</script>
