<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-5 align-self-center">
				<h3 class="page-title">Setup / Edit Notificacions</h3>
			</div>
			<div class="col-7 align-self-center">
				<div class="d-flex align-items-right justify-content-end">
					<router-link
						:to="{ name: 'MSetup' }"
						class="btn btn-info btn-sm mr-2 router-link-active"
						style="border: 1px solid black;"
						> <i class="fas fa-arrow-left"></i>  Back
					</router-link>
				</div>
			</div>
		</div>	   	
		<div class="row">
			<div class="col-6">  
				<div class="card">
					<div class="card-header">
						<h3>Notificacions</h3>
						<!-- <router-link
							:to="{ name: 'Setup' }"
							class="btn btn-info btn-sm mr-2 router-link-active"
							style="display: block;position: absolute;margin-top: -35px;right: 10px;border: 1px solid black;"
							> <i class="fas fa-arrow-left"></i>  Back
						</router-link>						 -->
					</div>
					<div class="bgloading" v-show="isLoading"><Loader></Loader></div>
					<form @submit.prevent="UpdateSetting" id="form1" class="">
						<div class="card-body">

	                        <div class="form-group">
	                            <label>Form name</label>
	                            <ModelSelect 
	                                v-model="form_id"
	                                :options="listForms" 
	                                placeholder="Filter by name"
	                                class="form-control"
	                                @input="getSetting"
	                            ></ModelSelect>
	                        </div>
							<div v-if="form_id>0" class="form-check" style="margin-left: 11px; padding: 12px 10px">
								<input
								class="form-check-input"
								type="checkbox"
								value=""
								id="defaultCheck1"
								v-model="accept_notification"
								/>
								<label class="form-check-label" for="defaultCheck1">
									Receive Email Notifications
								</label>
							</div>
							<div v-if="form_id>0" class="form-group">
								<label for="exampleFormControlInput1">Email </label>
								<input
								type="email"
								class="form-control"
								id="exampleFormControlInput1"
								v-model="email1"
								/>
							</div>
							<div v-if="form_id>0" class="form-group">
								<label for="exampleFormControlInput2">Email copy 2</label>
								<input
								type="email"
								class="form-control"
								id="exampleFormControlInput2"
								v-model="email2"
								/>
							</div>
							<div v-if="form_id>0" class="form-group">
								<label for="exampleFormControlInput3">Email copy 3</label>
								<input
								type="email"
								class="form-control"
								id="exampleFormControlInput3"
								v-model="email3"
								/>
							</div>
						</div>
						<div class="card-footer d-flex justify-content-end">
							<button type="submit" v-if="form_id>0" class="btn btn-success btn-sm">UPDATE</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Loader from "@/core/components/shared/Loader.vue";
	import { mapGetters } from "vuex";
	import { ModelSelect } from 'vue-search-select'
	export default {
		name: "FormsNotificacions",
		components: {
			Loader,
			ModelSelect,
		},
		data() {
			return {
				isLoading: false,
				email1: null,
				email2: null,
				email3: null,
				accept_notification: false,
				org_email: null,
				listForms: [],
				form_id: 0,
			};
		},
		created() {
			this.init();
		},
		mounted() {
			this.email1 = this.org_email;
		},
		methods: {	
			init(){
				let apisForm = this.$store.getters['ModApps/Marketea/urlApis'];
				this.apis = apisForm.Form;							
				this.getListForms();
			},		
			getListForms() {
				this.isLoading = true;
				window.master.post(this.apis.get(), {
					org_id: window.localorgdata
				})
				.then((response) => {
					let e = response.data;
					if (e.status) {
						for (let j in e.r) {
							if(e.r[j]) {
								this.listForms.push({value: e.r[j].id, text: e.r[j].name});
							}
						}
					}
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.isLoading = false;
				});
			},
			getSetting() {
				this.isLoading = true;
				window.master.get(this.apis.getSetting(this.form_id))
				.then((response) => {
					this.rest()
					if (response.data.status) {												
						if (response.data.result.email1 == null) {
							this.email1 = this.org_email;
						} else {
							this.email1 = response.data.result.email1;
						}
						this.email2 = response.data.result.email2;
						this.email3 = response.data.result.email3;
						let accept = response.data.result.accept_notification;
						this.accept_notification = accept == 1;
					}
					this.isLoading = false;
				})
				.catch((error) => console.log(error));
			},
			UpdateSetting() {
				this.isLoading = true;
				var accept = this.accept_notification == true ? 1 : 0;
				window.master
				.post(this.apis.setNoti(), {
					org_id: window.localuserdata,
					user_id: window.master_client,
					email1: this.email1,
					email2: this.email2,
					email3: this.email3,
					accept_notification: accept,
					form_id: this.form_id,
				})
				.then((response) => {
					this.$swal.fire({
						icon: "success",
						title: "Updated",
						showConfirmButton: false,
						timer: 2000,
					});
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.isLoading = false;
				});
			},
			rest() {
				this.email1 = ''
				this.email2 = ''
				this.email3 = ''
			},
		},
		computed: {
		},
	};
</script>

<style lang="scss" scoped></style>
