let filterjobsItems = function() {
	// this.isLoading = true;
	let list = [];
	let self = this;
	//if(this.search!=''){
		list = this.listTaksResult.filter(function (item) {
			if (item.id && item.id.indexOf(self.search.toLowerCase()) >= 0) {
				return item;
			} else if (
				item.customer_name &&
				item.customer_name.toLowerCase().indexOf(self.search.toLowerCase()) >=
				0
				) {
				return item;
			} else if (
				item.businessname &&
				item.businessname.toLowerCase().indexOf(self.search.toLowerCase()) >=
				0
				) {
				return item;
			} else {
				for (let i in item.taks) {
					if (
						item.taks[i].name
						.toLowerCase()
						.indexOf(self.search.toLowerCase()) >= 0
						) {
						return item;
				}
			}
		}
	});
	// this.isLoading = false;
	let d = new Date();
	return list;
};
export default filterjobsItems;
