let get_searchJobs = function() {
	let get_searchJobs = this.get_searchJobs;
	this.searchActivate = get_searchJobs[0].boolean;
	if (get_searchJobs[0].boolean == true && get_searchJobs[0].all == false) {
		// busca una sola coincidencia
		this.listTaksResult = get_searchJobs[0].data;
		this.search = "";
		this.starGrid();
	} 
	else if (get_searchJobs[0].boolean == true && get_searchJobs[0].all == true) 
	{
		// busca todas las coincidencia
		this.listTaksResult = get_searchJobs[0].data;
		this.search = "";
		this.starGrid();
	} else {
		// si el buscador esta vacio muestra los 20 registro por default
		this.getJobsDash();
	}
};
export default get_searchJobs;
