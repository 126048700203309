<template>
    <div class="container-fluid myCards">
        <navTopbar></navTopbar>
        <div class="body_card">
            <div class="row" v-if="isLoading">
                <div class="col-12 text-center">
                    <Loader></Loader>
                </div>
            </div>
            <div class="row" :style="(isLoading) ? 'display:none;' : ''">
                <div class="col-12 ">
                    <list-card
                        @setLoad="getLoadingList($event)"
                        @createCardModal="openModal($event)"
                        @setModalDirect="openModal($event)"
                        :refreshList="refreshListPusher"
                        :refreshItem="refreshItemPusher"
                        :refreshItemStatus="refreshItemStatusPusher"
                        v-model="refreshPusher"
                    ></list-card>
                    <preview-card
                        v-model="refreshPusher"
                        @hidePreview="clearItemSelected($event)"
                        @setModal="openModalUrl($event)"
                        @editOrgInfo="openModalOrgInfo($event)"
                    ></preview-card>
                </div>
            </div><!-- modal popup-->
            <modal name="modal-editProfile" class="modal-editProfile" draggable=".window-header" height="auto" @before-close="beforeClosedModal">
                <div class="card">
                    <button @click="ClosedModal" class="closeIcon"><span></span></button>
                    <component :is="nameComp"></component>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import navTopbar from "@/apps/Marketea/components/bSite/navTopbar.vue";
// @ts-ignore
import ListCard from "@/apps/Marketea/components/bSite/printList.vue";
// @ts-ignore
import previewCard from "@/apps/Marketea/components/bSite/previewCard.vue";
// @ts-ignore
import editCover from "@/apps/Marketea/components/bSite/editCard/editCover.vue";
// @ts-ignore
import editPicProfile from "@/apps/Marketea/components/bSite/editCard/editPicProfile.vue";
// @ts-ignore
import editLogo from "@/apps/Marketea/components/bSite/editCard/editLogo.vue";
// @ts-ignore
import editInfo from "@/apps/Marketea/components/bSite/editCard/editInfo.vue";
// @ts-ignore
import editContact from "@/apps/Marketea/components/bSite/editCard/editContact.vue";
// @ts-ignore
import editTemplate from "@/apps/Marketea/components/bSite/editCard/editTemplate.vue";
// @ts-ignore
import editBtnsActions from "@/apps/Marketea/components/bSite/editCard/editBtns_action.vue";
// @ts-ignore
import createCard from "@/apps/Marketea/components/bSite/createCard.vue";
// @ts-ignore
import orgUrl from '@/apps/Marketea/components/bSite/orgUrl'
// @ts-ignore
import editMapIframe from "@/apps/Marketea/components/bSite/editCard/editMapIframe.vue";
import editSchedule from "@/apps/Marketea/components/bSite/editCard/editSchedule.vue";
// @ts-ignore
import './index.scss';
export default {
    name: "index",
    components: {
        Loader,
        navTopbar,
        'list-card': ListCard,
        'preview-card': previewCard,
        editCover,
        editPicProfile,
        editLogo,
        editInfo,
        editContact,
        editTemplate,
        editBtnsActions,
        createCard,
        orgUrl,
        editMapIframe,
        editSchedule,
    },
    data () {
        return {
            isLoading: true,
            nameComp: null,
            refreshPusher: 0,
            refreshListPusher: 0,
            refreshItemPusher: null,
            refreshItemStatusPusher: null,
            apis: null,
        }
    },
    created(){
        this.clearItemSelected();
    },
    watch: {
        cardItemSelected: function(){
            this.itemSelected=this.cardItemSelected;
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined && t.getPusher != null) {
                if(t.getPusher.modulo!=null && t.getPusher.modulo == "bsite"){
                    let p = t.getPusher.data;
                    if(p.org_id == window.localorgdata){
                        // leer acciones
                        t.pusherActions(p);
                    }
                }
            }
        },
        editOrgUrl: function(){
            let t = this;
            if(t.editOrgUrl!=null && t.editOrgUrl>0){
                console.log('editOrgUrl', t.editOrgUrl)
                setTimeout(() => {
                    t.openModal('orgUrl');
                }, 200);
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            getPusher: "get_accitonPusher",
        }),
        ...mapGetters("ModApps/Marketea", {
            getListcard: "get_bsite_listcard",
            cardItemSelected: "get_bsite_cardItem",
            editOrgUrl: "get_editOrgUrl",
        }),
        itemSelected: {
            get: function () {
                let t = this;
                return t.cardItemSelected;
            },
            set: function (v) {
                return v;
            }
        },
    },
    methods: {
        getLoadingList(value){
            console.log('getLoadingList', value);
            this.isLoading=value;
        },
        // @ts-ignore
        clearItemSelected(value){
            let payload = {key: 'bsite_cardItem', val: null};
            this.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload)
        },
        ClosedModal() {
            this.$modal.hide("modal-editProfile");
        },
        beforeClosedModal(){
            this.nameComp = null;
            localStorage.removeItem('edirUrl');
        },
        ShowdModal(n) {
            this.nameComp = n;
            this.$modal.show("modal-editProfile");
        },
        openModal(value){
            console.log('openModal', value);
            this.ShowdModal(value);
        },
        openModalUrl(value){
            localStorage.setItem('edirUrl', 1);
            console.log('openModal', value);
            this.ShowdModal(value);
        },
        openModalOrgInfo(value){
            console.log('openModal', value);
            this.ShowdModal(value);
        },
        getID(p){
            let t = this;
            let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
            t.apis = urlApis.bSite;
            let service = t.apis.pull(4); // getId
            let url = window.urlmaster + 'api/v3/bSite/'+service;
            if(p.itemID && p.itemID>0){
                let payload = new FormData();
                payload.append("user_id", window.master_client);
                payload.append("org_id", window.localorgdata);
                payload.append("id", p.itemID);
                payload.append("pusher_id", pusher.connection.socket_id);
                window.master.post(service, payload).then((response) => {
                    console.log(url, response.data);
                    if(response.data.card){
                        if(p.action=='newCard'){
                            t.$store.commit('ModApps/Marketea/addNewCard', response.data.card);
                            if(p.pusher_id==pusher.connection.socket_id){
                                let payload = {key: 'bsite_cardItem', val: response.data.card};
                                this.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload)
                            }
                        }
                        else if(p.action=='refreshCard'){
                            //para si mismo
                            if(p.pusher_id==pusher.connection.socket_id){
                                t.$store.commit('ModApps/Marketea/refreshCard', response.data.card);
                            }else{
                                //para otros
                                t.$store.commit('ModApps/Marketea/refreshPusherList', response.data.card);
                                t.$store.commit('ModApps/Marketea/refreshPusherItem', response.data.card);
                            }
                        }
                        setTimeout(() => {
                            t.refreshPusher++;
                        }, 1000);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        pusherActions(p){
            let t = this;
            console.log('refreshList', p)
            console.log('pusher', pusher.connection.socket_id)
            if(p.action=='refreshCard' || p.action=='newCard'){t.getID(p);}
            else if(p.action=='refreshList'){
                if(p.pusher_id==pusher.connection.socket_id){
                    t.refreshItemPusher = p.card;
                }else{t.refreshItemPusher=null;}
                t.refreshListPusher++;
                setTimeout(() => {
                    t.refreshPusher++;
                }, 1000);
            }
            else if(p.action=='changeStatusCard'){
                if(p.pusher_id!=pusher.connection.socket_id){
                    console.log('changeStatusCard', p)
                    t.refreshItemStatusPusher = p.card;
                }
                setTimeout(() => {
                    t.refreshPusher++;
                }, 1000);
            }
        },
    }
}
</script>
