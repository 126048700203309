let pagesNumber = function() {	
	if (!this.pagination.to) {
		return [];
	}
	let from = this.pagination.current_page - this.offset;
	if (from < 1) {
		from = 1;
	}
	let to = from + this.offset * 2;
	if (to >= this.pagination.last_page) {
		to = this.pagination.last_page;
	}
	let pagesArray = [];
	for (let page = from; page <= to; page++) {
		pagesArray.push(page);
	}
	return pagesArray;
};
export default pagesNumber;
