<template>
    <div class="formTemplate">
        <Loader v-if="isLoading"></Loader>
        <form @submit.prevent="MetSAddContractT">
            <div class="form-row">
                <div class="col-10">
                    <div class="form-row">
                        <div class="col-6">
                            <span id="" class="form-text">Title:</span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="DataForm.title"
                                required
                            />
                        </div>
                        <div class="col-6">
                            <span id="" class="form-text"
                                >Reference title: (no visible)</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="DataForm.alias"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <span id="" class="form-text"><br></span>
                    <button type="submit" class="btn btn-success float-right">
                        {{ DataForm.btnName }}
                    </button>
                </div>
                <div class="col-12">
                    <span id="" class="form-text"> Description:</span>
                    <vue-editor
                        id="editor"
                        v-model="DataForm.description"
                        style="width: 100%"
                        :editor-toolbar="customToolbar"
                    />
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import { VueEditor } from 'vue2-editor'
export default {
    components: { 
        Loader,
        VueEditor,
    },
    name: "formTemplates",
    data () {
        return {
            isLoading: false,
            m: {
                show: false,
                component: '',
                data: []
            },
            DataForm: {
                btnName: "ADD",
                action: "Add",
                id: null,
                title: null,
                alias: null,
                description: null,
            },  
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],                                 
        }
    },
    created () {
        this.init();
    },
    methods: {
        init() {
            let g = this.$store.getters['ModApps/billing/gettemplateContract'];  
            this.DataForm.btnName = g.btnName;
            this.DataForm.action = g.action;
            this.DataForm.id = g.id;
            this.DataForm.title = g.title;
            this.DataForm.alias = g.alias;
            this.DataForm.description = g.description;
        },        
        MetSAddContractT() {
            this.isLoading= true;
            if (this.DataForm.action == "Add") {
                window.billing
                    .post("contracttemplate/add", {
                        user_id: window.localuserdata,
                        title: this.DataForm.title,
                        alias: this.DataForm.alias,
                        description: this.DataForm.description,
                    })
                    .then((response) => {
                        this.isLoading = false;
                        console.log(response.data);
                        this.DataForm.title = null;
                        this.DataForm.alias = null;
                        this.DataForm.description = null;
                        this.NoRecord = false;
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created Template Successful",
                        });
                        this.$store.commit("ModApps/billing/settemplateContract", {action: 'refresh'});
                        this.$parent.$parent.CloseModal();
                    })
                    .catch((error) => {
                        this.isLoading= false;
                        console.log(error, (this.isLoading = false))
                    });
            }
            if (this.DataForm.action == "Update") {
                this.isLoading= true;
                window.billing
                    .put("contracttemplate/edit/" + this.DataForm.id, {
                        user_id: window.localuserdata,
                        id: this.DataForm.id,
                        title: this.DataForm.title,
                        alias: this.DataForm.alias,
                        description: this.DataForm.description,
                    })
                    .then((response) => {
                        this.DataForm.alias = null;
                        this.DataForm.title = null;
                        this.DataForm.description = null;
                        this.DataForm.btnName = "ADD";
                        this.DataForm.action = "Add";
                        this.NoRecord = null;
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated Template Successful",
                        });
                        this.isLoading= false;
                        this.$store.commit("ModApps/billing/settemplateContract", {action: 'refresh'});   
                        this.$parent.$parent.CloseModal();                       
                    })
                    .catch((error) => {
                        this.isLoading= false;
                        console.log(error, (this.isLoading = false))
                    });
            }
        },
    },
    watch: { },
    computed: {},
};
</script>

<style lang="css" scoped>
.formTemplate{
    height: 500px;
    overflow-y: auto;
    padding-bottom: 100px;
}
</style>
