<template>
	<div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">
						Sales #{{ f.sales_id }} - Appointments
					</h5>
                </div>
            </div>
        </div>
		<div class="card-body">
			<div v-if="editNoteId>0" class="popEditNote">
				<form class="formNote" @submit.prevent="saveEditNote">
					<div class="campo_note">
						<div style="display: flex;justify-content: flex-end;gap: 10px;margin-bottom: 5px;">
							<!-- <datetime
								type="date"
								v-model="meeting_date"
								value-zone="America/New_York"
								input-class="form-control"
								format="MMM dd, yyyy"
								:minute-step="10"
								placeholder="Select date"
								use12-hour
								required
							></datetime> -->
							<div class="label_meeting_date">
								<span class="view_meeting_date">
									<span v-if="meeting_date">{{ meeting_date | moment("MMM DD, YYYY")}}</span>
								</span>
								<date-picker
									v-model="meeting_date"
									:default-value="new Date()"
									type="date"
									:input-attr="{'data-value': meeting_date}"
									input-class="mx-input"
									:clearable="false"
									:show-second="false"
									:confirm="true"
								></date-picker>
							</div>
							<!-- <datetime
								type="time"
								v-model="meeting_hour"
								value-zone="America/New_York"
								input-class="form-control"
								:minute-step="10"
								:max-datetime="maxTime"
								:min-datetime="'06:00'"
								placeholder="Select time"
								use12-hour
								required
							></datetime> -->
							<div class="label_meeting_hour">
								<span class="view_meeting_hour">
									<span v-if="meeting_hour">{{ convertTo12HourFormat(meeting_hour)}}</span>
								</span>
								<date-picker
									v-model="meeting_hour"
									value-type="format"
									format="HH:mm"
									type="time"
									:input-attr="{'data-value': meeting_hour}"
									:minute-step="5"
									input-class="mx-input"
									:clearable="false"
									:show-second="false"
									:confirm="true"
									use12h
								></date-picker>
							</div>
						</div>
						<textarea v-model="note" class="form-control" :placeholder="(note==null) ? 'Edit note' : ''"></textarea>
					</div>
					<div class="btns_note">
						<button type="button" class="btn btn-ligth" @click="closeEditNote">Cancel</button>
						<button type="submit" class="btn btn-info">Save</button>
					</div>
				</form>
			</div>
			<div class="container-comments">
				<ul v-if="list.length>0" class="listComments">
					<li v-for="(i, idx) in list" :key="idx">
						<div :class="['comments-content', (i.status==0) ? 'meeting_cancelled' : '']">
							<button type="button" class="btn btn-sm btn-edit-meet" title="Edit note" @click="openEditNote(i)" v-if="i.status>0">
								<i class="fas fa-edit"></i>
							</button>
							<button type="button" class="btn btn-sm btn-del-meet" title="Edit note" @click="cancelMeeting(i)" v-if="i.status>0">
								<i class="fas fa-window-close"></i>
							</button>
							<div v-if="i.status==0" class="meeting_cancelled_icon"><i class="fas fa-ban"></i></div>
							<div class="comment">
								<b>Date/Time: </b>
								<span>{{i.date}} {{i.hour}}</span>
									<!-- {{ new Date(i.hour).toLocaleTimeString("en-us", optionsTime)}} -->
							</div>
							<div class="comment note" @dblclick="openEditNote(i)">
								<pre v-if="i.note!=null"><b>Report: </b> {{i.note}}</pre>
								<pre v-else><b>Report: </b> Scheduled meeting.</pre>
							</div>
							<div class="by">By: {{i.name}} {{i.last}} | Phone: {{i.tlf}}</div>
						</div>
					</li>
				</ul>
				<div v-else style="padding-top: 20px;">
					<div class="alert alert-info" role="alert">
						No meetings scheduled.
					</div>
				</div>
			</div>
			<form @submit.prevent="saveAppointment">
				<table class="table table-bordered" style="margin-bottom: 0px;">
					<thead>
						<tr>
							<th colspan="3" class="text-center thheader">
								Add Meeting
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="width: 25%;">
                                <!-- <datetime
                                    type="date"
                                    v-model="inputFecha"
                                    value-zone="America/New_York"
                                    input-class="form-control"
                                    format="MMM dd, yyyy"
                                    :minute-step="10"
									placeholder="Select date"
                                    use12-hour
                                    required
                                ></datetime> -->
								<!-- :min-datetime="minDatetime" -->
								<div class="label_meeting_date">
									<span class="view_meeting_date">
										<span v-if="f.meeting_date">{{ f.meeting_date | moment("MMM DD, YYYY")}}</span>
                                    </span>
									<date-picker
										v-model="f.meeting_date"
                                        :default-value="new Date()"
										type="date"
										:input-attr="{'data-value': f.meeting_date}"
										input-class="mx-input"
										placeholder="Select date"
										:clearable="false"
										:show-second="false"
										:confirm="true"
									></date-picker>
                                </div>
							</td>
							<td style="width: 25%;">
                                <!-- <datetime
                                    type="time"
                                    v-model="f.meeting_hour"
                                    value-zone="America/New_York"
                                    input-class="form-control"
                                    :minute-step="10"
                                    :max-datetime="maxTime"
                                    :min-datetime="'06:00'"
                                    placeholder="Select time"
                                    use12-hour
                                    required
                                ></datetime> -->
								<div class="label_meeting_hour">
									<span class="view_meeting_hour">
										<!-- {{ item.due_date | moment("MMM DD, YYYY")}}<br /> -->
                                        <span v-if="f.meeting_hour">{{ convertTo12HourFormat(f.meeting_hour)}}</span>
                                    </span>
									<date-picker
										v-model="f.meeting_hour"
										value-type="format"
										format="HH:mm"
										type="time"
										:input-attr="{'data-value': f.meeting_hour}"
										:minute-step="5"
										placeholder="Select time"
										input-class="mx-input"
										:clearable="false"
										:show-second="false"
										:confirm="true"
										use12h
									></date-picker>
                                </div>
							</td>
							<td style="width: 50%;">
								<input type="text" class="form-control" v-model="f.author" placeholder="Author" disabled="true">
							</td>
						</tr>
						<tr>
							<td colspan="2" rowspan="3" style="width: 100%;">
								<textarea v-model="f.note" class="form-control" style="height: 80px;" placeholder="Report:" maxlength="300"></textarea>
							</td>
						</tr>
						<tr>
							<td>
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="checkbox" id="noti" v-model="f.noti" :value="1" :disabled="(profile.tlf=='' || noVendor)">
									<label class="form-check-label" for="noti">
										Notify me by text
										<small v-if="profile.tlf==''">(
											<span style="color:red;">
												Cell phone empty
											</span>
										)</small>
										<small v-if="noVendor">(
											<span style="color:red;">
												Without Sales Rep.
											</span>
										)</small>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<td class="text-left">
								<button type="submit" class="btn btn-success" style="width: 100%;">Save</button>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import './appointment.scss';
export default {
	name: 'appointment',
	components: {
		DatePicker,
	},
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				sellerUserId: window.localAccountID,
				typeSellerUser: 0,
				meeting_date: '',
				meeting_hour: '',
				note: '',
				author: '',
				noti: 0,
				"action": 'actListSalesTabs',
				"tab": 2, // index de appointment
			},
			minDatetime: new Date().toISOString().slice(0, 11)+'06:00',
			maxTime: '20:00',
			profile: {},
			list: [],
            inputFecha: '',
			sales_id: 0,
			editNoteId: 0,
			note: null,
			meeting_date: null,
			meeting_hour: null,
			status: null,
			optionsTime: {hour12: true, hour: "2-digit", minute: "2-digit"},
			noVendor: false,
		}
	},
	created(){
		let t = this;
		t.$store.commit("core/setLoadingModal", true);
		let appID = t.$store.getters["core/apps/getAppIDactual"];
		let appRolUser = t.$store.getters["core/apps/get_appRolUser"];
		let validAdminApp = appRolUser.filter(r => {
			if(appID==r.app_id && r.rol==1){
				return r;
			}
		});
		t.$store.dispatch('core/session/validTypeUser').then((admin) => { console.log('validTypeUser', admin)})
		t.init();
		t.f.typeSellerUser = (!admin) ? 2 : 0;
		let admin = (window.master_client==t.f.sellerUserId); // owner
		t.getProfile(admin).then((r) => {
			t.getAppointment();
		})
	},
	methods:{
		convertTo12HourFormat(time24) {
			// Extraer la hora y los minutos del tiempo en formato de 24 horas
			let [hour, minute] = time24.split(':').map(Number);
			// Determinar si es AM o PM
			let period = hour >= 12 ? 'PM' : 'AM';
			// Convertir la hora al formato de 12 horas
			hour = hour % 12 || 12; // La hora '0' debería convertirse en '12'
			// Asegurarse de que los minutos tengan dos dígitos
			minute = minute < 10 ? `0${minute}` : minute;
			// Devolver la hora formateada
			return `${hour}:${minute} ${period}`;
		},
        formatearFecha() {
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        getProfile (admin) {
			let t = this;
        	return new Promise((resolve, reject) => {
                window.master
                .post('userProfile', {
                	user_id: t.f.sellerUserId,
                	admin: admin
                })
                .then((response) => {
                    t.profile = response.data.profile
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error)
                    resolve(false);
                })
            });
        },
		init(){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			console.log('sales', d)
			t.f.user_id= window.master_client;
			t.f.org_id= window.localorgdata;
			t.f.sales_id= d.id;
			t.f.sellerUserId= (d.seller_ids && d.seller_ids !== '' && d.seller_ids!== 'null') ? d.seller_ids : window.localAccountID;
			t.noVendor = (!d.seller_ids || d.seller_ids === '' || d.seller_ids=== 'null');
			console.log('noVendor', t.noVendor, (!d.seller_ids || d.seller_ids === '' || d.seller_ids=== 'null'))
			console.log('sales_id', t.f.sales_id)
			t.f.pusher_id= pusher.connection.socket_id;
			document.querySelector('body').classList.add('overflowYhide');
		},
		getAppointment(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			t.$store.commit('ModApps/Marketea/set_itemSalesAppointmente', null);
			window.master
            .post('getAppointmentSales', t.f)
            .then((response) => {
                console.log('getAppointment', response.data)
                t.list=response.data.get;
				t.$store.commit('ModApps/Marketea/set_itemSalesAppointmente', t.list);
				setTimeout(function(){
					let scroll=document.querySelector(".container-comments");
					if(scroll){
						//scroll.scrollTop=scroll.scrollHeight;
					}
					t.f.author = t.profile.name +" "+ t.profile.last;
					t.$store.commit("core/setLoadingModal", false);
				}, 200);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveAppointment(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('setAppointmentSales', t.f)
            .then((response) => {
                console.log('setAppointmentSales', response.data);
                t.$store.commit("core/setLoadingModal", false);
                t.close();
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		close(){
			this.f.meeting_date= '';
			this.f.meeting_hour= '';
			this.f.note= '';
			this.f.noti= 0;
			this.$parent.close();
			//this.$store.commit("core/setCloseModal", true);
		},
		openEditNote(i){
			this.sales_id = i.sales_id;
			this.editNoteId = i.id;
			this.note = i.note;
			this.meeting_date = i.meeting_date
			this.meeting_hour = i.meeting_hour
			this.status = i.status
		},
		closeEditNote(){
			this.sales_id = 0;
			this.editNoteId = 0;
			this.note = null;
			this.meeting_date = null
			this.meeting_hour =null
			this.status =null
		},
		saveEditNote(){
			let t = this;
			for(let i in t.list){
				if(t.list[i]){
					if(t.list[i].id==t.editNoteId){
						t.list[i].note = t.note;
						t.list[i].meeting_date = t.meeting_date;
						t.list[i].meeting_hour = t.meeting_hour;
						t.list[i].status = t.status;
					}
				}
			}
			window.master
            .post('editNoteAppointmentSales', {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: t.sales_id,
				id: t.editNoteId,
				note: t.note,
				meeting_date : t.meeting_date,
				meeting_hour : t.meeting_hour,
				status : t.status,
			})
            .then((response) => {
                console.log('editNoteAppointmentSales', response.data);
				t.closeEditNote()
            })
            .catch((error) => {
                console.log(error);
            });
		},
		cancelMeeting(i){
			let t = this;
			t.$swal({
                title: "Cancel meeting?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Cancel it!",
            }).then((result) => {
                if (result.value) {
					for(let idx in t.list){
						if(t.list[idx]){
							if(t.list[idx].id==i.id){
								t.list[idx].status = 0;
							}
						}
					}
					window.master
					.post('editNoteAppointmentSales', {
						user_id: window.master_client,
						org_id: window.localorgdata,
						sales_id: i.sales_id,
						id: i.id,
						note: i.note,
						meeting_date : i.meeting_date,
						meeting_hour : i.meeting_hour,
						status : 0,
					})
					.then((response) => {
						console.log('editNoteAppointmentSales', response.data);
					})
					.catch((error) => {
						console.log(error);
					});
                }
            });
		}
	},
	watch: {
        inputFecha: function () {
            this.formatearFecha();
            this.f.meeting_date = this.inputFecha;
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "actAppointment" && t.getPusher.data.org_id == window.localorgdata && t.getPusher.data.sales_id==t.f.sales_id){
                    console.log('actAppointment', t.getPusher);
                    t.getAppointment();
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher"
        }),
    }
}
</script>
<style lang="scss" scoped>
.table-bordered th, .table-bordered td {
    border: 0px solid #dee2e6;
}
.container-comments {
	position: relative;
    display: block;
    width: 100%;
    height: calc(100vh - 310px);
    overflow: hidden;
	overflow-y: auto;
}
ul.listComments {
list-style: none;
margin-bottom: 0px;
padding-inline-start: 0px;
margin-block-start: 0px;
}

.comments-content {
	position: relative;
    padding: 10px;
    padding-left: 35px;
    margin-right: 20px;
    border: 1px solid #e4daff;
    background: rgb(228 218 255 / 49%);
    border-radius: 10px;
    margin-bottom: 20px;
	&.meeting_cancelled{
		padding-left: 35px;
		border: 1px solid #e64949;
		background: rgb(248, 230, 227);
	}
}

.date, .by {
    font-size: 10px;
    color: #bebebe;
}
.comment {
    font-size: 14px;
}	
.comment span{
    font-size: 12px;
}	
.thheader{
	border-bottom: 1px solid #bebebe !important;
    background-color: #fff;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-weight: bold;
}
table.table.table-bordered {
    box-shadow: -1px -1px 4px 1px rgb(0 0 0 / 15%);
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: 0px;
}
.comment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.comment span {margin-left: 10px;}
.comment.note{
	b{display: contents;}
	pre {
		font-size: 14px;
		color: #6a7a8c;
		display: flex;
		font-family: "ProximaNova", sans-serif;
		margin-left: 0px;
		margin-bottom: 0px;
		white-space: break-spaces;
	}
}
.btn-edit-meet{
	position: absolute;
	left: 5px;
	top: 5px;
	background-color: transparent;
	color:#555;
}
.btn-del-meet{
	position: absolute;
	right: 10px;
	background-color: transparent;
	color:#555;
}
.meeting_cancelled_icon{
	position: absolute;
	right: 10px;
	background-color: transparent;
	color:#c03b3b;
	font-size: 2rem;
	opacity: 0.5;
}
.popEditNote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    padding-top: 50px;
	form.formNote{
		width:100%;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.campo_note {
			width: 90%;
			textarea.form-control {
				height: 210px;
				margin-bottom: 10px;
			}
		}
		.btns_note {
			width: 90%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			button.btn-info {
				margin-left: 20px;
			}
		}
	}
}
.label_meeting_date, .label_meeting_hour {
	position: relative;
}
span.view_meeting_date {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}span.view_meeting_hour {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
</style>
