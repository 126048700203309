let actionNotes = function(id, notes) {
	let edit = false
	if (notes > 0){
		edit = true
	}
	let t = this;
	this.m = {
		show: true,
		bill: true,
		component: 'NotesAdmin',
		data: {
			id:id, 
			type:"Invoice",
			edit: edit,
			transac_type: "Invoice",
			idNote: id
		}
	};
	this.$store.commit('core/setOpenModal', this.m); 
};
export default actionNotes;
