<template>
	<div class="container-fluid">
		<listBanking></listBanking>
	</div>
</template>
<script>
import listBanking from '@/apps/billing/components/banking/listBanking'
export default {
	components: {
		listBanking
	},
	name: "Banking",
};
</script>
<style lang="scss" scoped>
</style>
