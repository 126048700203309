<template>
	<div><Loader></Loader></div>
</template>
<script>
	import Loader from '@/core/components/shared/Loader'
	export default {
		name: 'Logout',
		components: {
			Loader
		},
		created () {
			this.logout();
		},
		methods: {
			logout()
			{
				console.log('salir');
				this.$store.dispatch('core/session/destroyToken')
				.then(response => {
					console.log(response);
					this.redirectLogin();
				})
				.catch(error => {
					console.log(error);
					this.redirectLogin();
				});
			},
			redirectLogin () {
				//debugger;
				if (window.access_web == 1) { window.location.href=window.urlbaselogin; }
				else { this.$router.push({ name: 'Login' }); }
			}
		}
	};
</script>
