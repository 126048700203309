<template>
    <div style="display: block;">
        <div class="row">
            <div class="col-12">
                <div style="background-color: rgba(0, 0, 0, 0.03);padding: 10px;">
                    <div class="bgloading" v-if="isloading">
                        <Loader></Loader>
                    </div>                
                    <form @submit.prevent="UpdatePrivilegies">
                        <div class="row" v-if="app_id==1">
                            <div
                                v-for="(grupo, index) in privilegiesList"
                                :key="index"
                                class="col-md-3"
                                style="padding:5px;"
                                v-if="index!=1 && index!=5"
                            >
                                <ul class="list-group list-group-flush list-priv">
                                    <li class="list-group-item">
                                        <span><b>{{ grupo.name_group }}</b></span>
                                    </li>
                                    <li
                                        v-for="(i, idx) in grupo.list"
                                        :key="idx"
                                        class="list-group-item d-flex justify-content-between"
                                    >
                                        <label
                                            class="form-check-label"
                                            :for="'defaultCheck' + i.id"
                                        >
                                            {{ i.name }}
                                        </label>
                                        <span>
                                            <input
                                                @click="status(i, index, idx)"
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selected"
                                                :value="i.id"                                                
                                                :id="'defaultCheck' + i.id"
                                            />                                           
                                        </span>
                                    </li>
                                </ul>

                                <ul v-if="index==0" class="list-group list-group-flush list-priv" style="margin-top: 20px;">
                                    <li class="list-group-item">
                                        <span><b>{{ privilegiesList[1].name_group }}</b></span>
                                    </li>
                                    <li
                                        v-for="(i, idx) in privilegiesList[1].list"
                                        :key="idx"
                                        class="list-group-item d-flex justify-content-between"
                                    >
                                        <label
                                            class="form-check-label"
                                            :for="'defaultCheck' + i.id"
                                        >
                                            {{ i.name }}
                                        </label>
                                        <span>
                                            <input
                                                @click="status(i, 0, idx)"
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selected"
                                                :value="i.id"                                                
                                                :id="'defaultCheck' + i.id"
                                            />                                           
                                        </span>
                                    </li>
                                </ul>
                                <ul v-if="index==4" class="list-group list-group-flush list-priv" style="margin-top: 20px;">
                                    <li class="list-group-item">
                                        <span><b>{{ privilegiesList[5].name_group }}</b></span>
                                    </li>
                                    <li
                                        v-for="(i, idx) in privilegiesList[5].list"
                                        :key="idx"
                                        class="list-group-item d-flex justify-content-between"
                                    >
                                        <label
                                            class="form-check-label"
                                            :for="'defaultCheck' + i.id"
                                        >
                                            {{ i.name }}
                                        </label>
                                        <span>
                                            <input
                                                @click="status(i, 0, idx)"
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selected"
                                                :value="i.id"                                                
                                                :id="'defaultCheck' + i.id"
                                            />                                           
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div
                                v-for="(grupo, index) in privilegiesList"
                                :key="index"
                                class="col-md-3"
                                style="padding:5px;"
                            >
                                <ul class="list-group list-group-flush list-priv">
                                    <li class="list-group-item">
                                        <span><b>{{ grupo.name_group }}</b></span>
                                    </li>
                                    <li
                                        v-for="(i, idx) in grupo.list"
                                        :key="idx"
                                        class="list-group-item d-flex justify-content-between"
                                    >
                                        <label
                                            class="form-check-label"
                                            :for="'defaultCheck' + i.id"
                                        >
                                            {{ i.name }}
                                        </label>
                                        <span>
                                            <input
                                                @click="status(i, index, idx)"
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selected"
                                                :value="i.id"                                                
                                                :id="'defaultCheck' + i.id"
                                            />                                           
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <label for="selectAlls">
                                Select All 
                                <input type="checkbox" id="selectAlls" :checked="selectAll" @click="selectAlls()" style="position: relative; top: 3px; margin-left: 10px;">
                            </label>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-success"
                            style="padding: 2px 5px; margin-top: 10px"
                        >
                            Save
                        </button>     
                        <a
                            @click="cancelClose()"
                            class="btn btn"
                            style="padding: 2px 10px;margin-top:10px;background-color:#fff;margin-left: 10px;border-color: #ccc;"
                        >
                            Cancel
                        </a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'Privilegies',
    components: {
        Loader
    },
	props:{
		app_id:{type: Number, required:true},
		org_id:{type: Number, required:true},
        account_id:{type: Number, required:true},
        index:{type: Number, required:true},
        item:{type: Object, required:true},
	},    
    data () {
        return {
            isloading: false,
            userDetail: null,
            appDetail: null,
            selected: [],
            privilegiesList: [],
            modulos: [],
            selectAll: false,
            update: [],
            del: []
        }
    },
    created () {
        if (this.$store.getters.getUserType === 2) {
            this.$router.push({ name: 'Home' })
        }
    },
    mounted () {
        if (this.account_id) {
            console.log('account_id load ' + this.account_id)
            this.usersById(this.account_id)
            this.GetPrivilegies(this.account_id)
        }
        if (this.app_id) {
            console.log('app_id load ' + this.app_id)
            this.AppById(this.app_id)
        }
    },
    methods: {
        cancelClose(){
            this.$parent.showContent = false;
            console.log('rol', this.item.rol)
            if(this.item.rol!=1){
                if (this.modulos.length != this.selected.length) {
                    console.log('modulos', this.modulos.length)
                    console.log('selected', this.selected.length)
                    this.item.admin=false;
                }
            }else{this.item.admin=true;}
        },
        validIsAdmin(){
            let t = this;
            let appItem = t.item;
            console.log('validIsAdmin', appItem.rol)
            if(appItem.rol==1){
                t.selectAll = true;
                const b = t.modulos;
                const r = [];
                for (const i in b) {
                    r.push(b[i].id);
                }
                t.selected = r;
                t.update = r;
                t.allBoolean(true);
                if(appItem.admin){
                    console.log('guardar como admin');
                    this.$parent.convertToAdmin(this.$parent.appslistbyorg[this.index], this.index, 1);
                }
            }

        },
        status(i, index, subindex) {
            if (i.authorized) {
				this.privilegiesList[index].list[subindex].authorized = false
			} else {
				this.privilegiesList[index].list[subindex].authorized = true
			}
        },
        selectAlls () {
            if (this.selectAll === false) {
                this.selectAll = true
                const b = this.modulos
                const r = []
                for (const i in b) {
                    r.push(b[i].id)
                }
                this.selected = r
                this.update = r
                this.allBoolean(true)
            } else {
                this.selectAll = false
                this.selected = []
                this.update = []
                this.allBoolean(false)
            }
            //console.log('selectAlls', this.selectAll, 'update', this.update)
        },
        allBoolean(bool) {
            let o = []
            let p = []
            let active = []
            let inactive = []

            let a = this.privilegiesList
            for (let i in a) {
                o.push(a[i].list)
            }
            for (let j in o) {    
                let e = o[j]
                for (let i in e) {
                    if (bool != 9) {
                        this.privilegiesList[j].list[i].authorized = bool
                    }                    
                    if (e[i].authorized) {
                        active.push(e[i].authorized)
                    } else {
                        inactive.push(e[i].authorized)
                    }
                    p.push(e[i].authorized)
                }
            } 
            let data = {p:p, active:active, inactive:inactive}
            //console.log('allBoolean', bool, data)
            return data;    
        },
        roles() {
            let data = this.allBoolean(9)
            let p = data.p.length
            let active = data.active.length
            let inactive = data.inactive.length
            let admin = 0
            if (active == p) {
                admin = 1
            }
            return admin;
        },
        UpdatePrivilegies () {
            this.isloading = true;
            if (this.update.length > 0 && this.modulos.length != this.selected.length) {
                const b = this.modulos
                const f = this.selected
                const r = []
                for (const i in b) {
                    if (f[i] !== b[i].id) {
                        r.push(b[i].id)                        
                    }
                }
                this.del = r
            }
            let admin = this.roles()
            let b = this.del
            let y = []
            let idApps = [13, 14]
            /*for (let i in b) {
                if (idApps.indexOf(b[i])) {
                    y.push(b[i])
                } 
                if (idApps.indexOf(b[i]) > -1 && this.selected.length==0) {
                    this.selected.push(b[i])
                }                                
            } */
            let index = this.index
            if (this.modulos.length == this.selected.length) {
               //guardar como admin
                console.log('guardar como admin');
                this.$parent.convertToAdmin(this.$parent.appslistbyorg[index], index, 1);
            } else {
                this.$parent.desactiveadmin(this.app_id, false);
                console.log(index);
                console.log('admin', this.$parent.appslistbyorg[index].admin);
                window.master
                .post('updateprovilegies', {
                    org_id: this.org_id,
                    account_id: this.userDetail.account_id,
                    data_apps_ids: this.selected,
                    del: this.del,
                    admin:admin,
                    app_id:this.app_id,
                })
                .then((response) => {
                    // console.log('data responde update privilegies')
                    // console.log(response.data);
                    this.isloading = false
                    this.$parent.showContent = false
                    this.$notify({
                        group: 'noti',
                        title: 'Update!',
                        type: 'success',
                        text: 'Successful Update'
                    })
                    this.selected = []
                    this.del = []
                    if (this.account_id) {
                        this.usersById(this.account_id)
                        this.GetPrivilegies(this.account_id)
                    }
                    this.$parent.$parent.close()
                })
                .catch((error) => {
                    this.isloading = false
                    console.log(error)
                })
            }    
        },
        usersById (accountId) {
            this.isloading = true
            window.master
                .get('user/' + window.localAccountID + '/' + accountId)
                .then((response) => {
                    this.userDetail = response.data.user_detail
                    this.isloading = false
                })
                .catch((error) => console.log(error))
        },
        GetPrivilegies (accountId) {
            this.isloading = true
            window.master
                .get(
                    'privilegies/' +
                        this.org_id +
                        '/' +
                        accountId +
                        '/' +
                        this.app_id
                )
                .then((response) => {
                    // console.log('result modules all')
                    // console.log(response.data);
                    this.privilegiesList = []
                    const grupos = response.data.g
                    const modulos = response.data.result
                    this.modulos = modulos
                    const b = this.modulos
                    const q = this.modulos.length
                    let a = 0
                    let z = 0
                    for (let i = 0; i < b.length; i++) {
                        if (b[i].authorized === true) {
                            a = a + 1
                        } else {
                            z = z + 1
                        }
                    }
                    if (a === q) {
                        this.selectAll = true
                    } else {
                        this.selectAll = false
                    }
                    for (const i in grupos) {
                        // console.log(grupos[i])
                        const line = {
                            name_group: grupos[i].name_group,
                            list: []
                        }
                        for (const j in modulos) {
                            if (modulos[j].group_id === grupos[i].group_id) {
                                line.list.push(modulos[j])
                            }
                        }
                        // console.log('line', line);
                        this.privilegiesList.push(line)
                    }
                    // console.log('privilegiesList',this.privilegiesList)
                    this.selected = []
                    for (var i = 0; i < this.privilegiesList.length; i++) {
                        for (const j in this.privilegiesList[i].list) {
                            if (this.privilegiesList[i].list[j].authorized === true) {
                                this.selected.push(this.privilegiesList[i].list[j].id)
                            }
                        }
                    }
                    this.validIsAdmin();
                    // si esta vacio guardar dash por defecto
                    if (this.selected.length == 0) {
                        this.UpdatePrivilegies()
                    }
                    else{
                        this.isloading = false
                    }
                })
                .catch((error) => console.log(error))
        },
        AppById (appId) {
            // console.log('load metohd')
            window.master
                .get('app/' + appId)
                .then((response) => {
                    this.appDetail = response.data.result
                    // console.log(response.data)
                })
                .catch((error) => console.log(error))
        }
    },
    watch: {
        selected: function(){
            this.selectAll=(this.selected.length == this.modulos.length);
            this.update = this.selected;
        },
    },
};
</script>
<style lang="scss" scoped>
.list-priv {
    .list-group-item {
        padding: 3px;
    }
}
.icontri {
    position: absolute;
    top: -21px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #a1e0ae;
    transform: translateX(-50%);
}
.prof_title {
    display: block;
    font-size: 14px;
}
.prof_title h4 {
    font-weight: 100;
    font-size: 24px;
    text-align: center;
}
.profile {
    margin-top: 60px;
}
.profile label {
    display: block;
    margin-bottom: 0.1rem;
}
li.list-group-item label.form-check-label {font-size: 12px;}

.form-check-input {
    margin-left: -0.70rem;
}
</style>
