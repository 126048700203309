<template>
    <div class="row printList">
        <div class="col-xs-12 col-sm-6 col-lg-3 col-xxl-3" v-if="listFilter.length==0">
            <div class="card card-body text-center item-card" id="createNew">
                <div class="banner" style="background-color: rgb(79, 164, 255);"></div>
                <div class="profile">
                    <i class="fas fa-user"></i>
                </div>
                <div class="about">
                    <h4 class="name">Create New Card</h4>
                </div>
                <button type="button" class="btn btnadd2" style="margin-top: 10px;" @click="createCard">+ Add</button>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-3 col-xxl-3" v-for="(i, index) in listFilter" :key="index">
            <div class="card card-body item-card" v-bind:class="[(itemSelected!=null && itemSelected.id==i.id) ? 'active' : '', (i.card_primary==1) ? 'card_primary' : '']">
                <span class="cdropdown">
                    <button class="btn btn-cog-item">
                        <i class="fa fa-cog"></i>
                    </button>
                    <label>
                    <input type="checkbox" />
                    <ul>
                        <li v-if="i.card_primary==1" @click="editCardActionDirect(i, 'orgUrl')">Organization Info</li>
                        <li  @click="editCardActionDirect(i, 'editInfo')">Employee Info</li>
                        <!-- <li  @click="editCardActionDirect(i, 'orgUrl')">Image Cover</li> -->
                        <!-- <li  @click="editCardActionDirect(i, 'orgUrl')">Image profile</li> -->
                        <!-- <li  @click="editCardActionDirect(i, 'orgUrl')">Find me at</li> -->
                        <li  @click="editCardActionDirect(i, 'editTemplate')">Template</li>
                        <li v-if="i.card_primary==0" @click="delCard(i)">Delete</li>
                    </ul>
                    </label>
                </span>
                <div class="banner" :style="isBackgroundCover(i)">
                    <div class="cover_picture_before" v-if="i.pictures !=null && i.pictures.length>0 && i.pictures[0].cover_active"
                        :style="'background-color: rgba(0 0 0 / '+i.pictures[0].cover_overlay+'%);'"></div>
                    <!-- <div class="logo_picture" v-if="i.pictures !=null && i.pictures.length>0 && i.pictures[0].logo_active"
                        :style="isLogo(i)"
                    ></div> -->
                </div>
                <div class="profile" :style="isProfile(i)">
                    <i class="fas fa-user" v-if="isProfile(i)==''"></i>
                </div>
                <div class="onoffswitch">
                    <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        :id="'activeProfile'+i.id"
                        :value="1"
                        v-model="i.status"
                        @change="changeStatus(i)"
                    />
                    <label class="onoffswitch-label" :for="'activeProfile'+i.id" >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
                <div class="about">
                    <div v-if="i.template_id<4">
                        <h4 class="name">{{i.name}} {{i.lastname}}</h4>
                        <p class="rol">{{i.rol}}</p>
                        <p class="company">{{org.company_name}}</p>
                    </div>
                    <h4 v-else class="name" style="margin-bottom: 20px;">{{org.company_name}}</h4>
                </div>
                <div class="btns">
                    <button type="button" class="btn" @click="editCard(i)">
                        <i class="fas fa-pen"></i>
                        <span>Edit Card </span>
                    </button>
                    <button type="button" class="btn" @click="external_link(i.slug_url)">
                        <i class="fas fa-external-link-alt"></i>
                        <span>Profile</span>
                    </button>
                </div>
            </div>
        </div>
        <button
			@click="createCard()"
			class="btn btn-info"
			id="addCardFixed"
			v-if="listFilter.length>0"
            title="New Card"
		>
			<i class="fa fa-plus"></i>
		</button>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
export default {
    name: 'printList',
    props:{
        refreshList: {
            type: Number,
            default: 0,
        },
        refreshItem: {
            default: null,
        },
        refreshItemStatus: {
            default: null,
        }
    },
    data(){
        return {
            isLoading: true,
            search: null,
            apis: null,
            listData: [],
            roles: [
                {id: 1, name :'Owner'},
                {id: 2, name :'President'},
                {id: 3, name :'Manager'},
                {id: 4, name :'Chief'},
                {id: 5, name :'Administrator'},
                {id: 6, name :'Supervisor'},
                {id: 7, name :'Master'},
                {id: 8, name :'Supervisor'},
                {id: 9, name :'Employee'},
                {id: 10, name :'Worker'},
                {id: 11, name :'Designer'},
                {id: 12, name :'Secretary'},
            ],
            urlweb : window.urlbSite,
            urlPic: null,
            PlanPremium: false,
            planActual: 1,
            org: {
                company_name:'',
                address:'',
                city:'',
                state:'',
                zip:'',
            },
            updateKey: 0,
            limitForPlan: {
                free: 1,
                standard: 1,
                team: 5,
                pro: 5,
                premium: 10,
                sponsored: 10,
                google: 10,
                bOrganic: 10,
            },
            premiumProfile: false,
            planesPro: [],
        }
    },
    created(){
        this.init();
    },
    watch: {
        getListcard: function (val, oldVal) {
            if(val!=null && val.length>0){
                this.listFilter = val;
            }
        },
        isLoading: function(){
            this.$emit('setLoad', this.isLoading);
        },
        refreshList: function(val, oldVal){
            if(val > oldVal){
                this.getAllRecords(this.refreshItem);
            }
        },
        refreshItemStatus: function(val, oldVal){
            if(val != oldVal && val!=null){
                let t = this;
                t.getAllRecords(val, 0);
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getListcard: "get_bsite_listcard",
            getCardItem: "get_bsite_cardItem",
        }),
        listFilter: {
            get: function () {
                let t = this;
                return t.getListcard;
            },
            set: function (v) {
                return v;
            }
        },
        itemSelected(){
            let t = this;
            return t.getCardItem;
        },
    },
    methods: {
        init(){
            let t = this;
            t.planesPro = t.$store.getters['ModApps/Marketea/get_planesPro'];
            let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
            t.apis = urlApis.bSite;
            // valid premium planID
            t.PlanPremium = (t.planesPro.indexOf(t.$store.getters['core/apps/getPlanIdApp'])>-1);
            console.log('PlanPremium', t.PlanPremium);
            t.planActual = t.$store.getters['core/apps/getPlanIdApp'];
            t.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
            if(t.planActual==7){
                t.PlanPremium = true;
                console.log('Plan sponsored, dando permiso de premium', t.PlanPremium);
            }
            t.getOrg();
            t.getAllRecords();
            localStorage.removeItem('edirUrl')
        },
        getOrg() {
            let t = this;
            let getOrg = this.$store.getters['core/Org/orginf'];
            if (getOrg != null) {
                console.log('getters FormInfo', getOrg);
                t.org.company_name = getOrg.com_company_name;
                t.org.address = getOrg.com_address;
                t.org.city = getOrg.com_city;
                t.org.state = getOrg.com_state;
                t.org.zip = getOrg.com_zip_code;
            }
        },
        getAllRecords(cardPusher, load){
            let t = this;
            if(load==undefined){
                t.isLoading=true;
            }
            window.master.post(t.apis.pull(0), {
                user_id: window.master_client,
                org_id: window.localorgdata,
                search: t.search,
                socket_id: pusher.connection.socket_id
            })
            .then((response) => {
                console.log(t.apis.pull(0), response.data);
                let payload = {
                    key: 'bsite_listcard',
                    val: response.data.r
                };
                t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload);
                t.urlPic=response.data.url;
                payload = {key: 'bsite_cardUrlPic', val: t.urlPic};
                t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload)
                payload = {key: 'bsite_suf_url', val: response.data.suf.meta_value};
                t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload)
                if(cardPusher!=undefined && cardPusher!=null && cardPusher.id){
                    payload = null;
                    response.data.r.filter(i => { if(i.id==cardPusher.id){payload = i;}});
                    let itemSelected = t.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
                    if(payload!=null && itemSelected!=null){
                        if(itemSelected.id == payload.id){
                            t.$store.commit('ModApps/Marketea/refreshPusherItem', payload);
                        }
                    }
                }
                t.isLoading=false;
            }).catch((error) => {
                console.log(error);
                t.isLoading = false;
            });
        },
        getRol(rol){
            return this.roles.filter(r => {
                if(Number(rol) == r.id){
                    console.log('getRol', rol, r.name)
                    return r;
                }
            })
        },
        updateList(listUpdate){
            let t = this;
            let list=listUpdate;
            let newList=[];
            let oldList=[];
            t.listData.filter(r => { oldList.push(r) });
            if(oldList.length>0){
                for (const key in oldList) {
                    if (Object.hasOwnProperty.call(oldList, key)) {
                        const keys1 = Object.keys(oldList[key]);
                        if(Object.hasOwnProperty.call(list, key)){
                            const keys2 = Object.keys(list[key]);
                            let newItem = {};
                            if(keys2.length>keys1.length){
                                newItem=list[key];
                            }
                            else if(keys2.length==keys1.length){
                                newItem=oldList[key];
                                for (let i = 0; i < keys1.length; i++) {
                                    if(list[key][keys2[i]] != oldList[key][keys1[i]]){
                                        // console.log('campo actualizado', keys2[i], 'valor', list[key][keys2[i]])
                                        newItem[keys1[i]]=list[key][keys2[i]];
                                    }
                                }
                            }
                            else{
                                for (let i = 0; i < keys1.length; i++) {
                                    if(oldList[key][keys2[i]]){
                                        if(keys2.indexOf(keys1[i])==-1){
                                            // console.log('campo eliminado', keys1[i], 'valor', list[key][keys1[i]])
                                        }else{
                                            newItem[keys2[i]]=list[key][keys2[i]];
                                        }
                                    }
                                }
                            }
                            newList.push(newItem);
                        }
                    }
                }
            }
            else{newList=list;}
            t.listData=newList;
            t.updateKey++;
        },
        showPremium(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 15000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        createCard(){
            let t= this;
            let valid = false;
            let limit = false;
            console.log('planActual', t.planActual)
            if(t.planActual==5 && t.listFilter.length < t.limitForPlan.standard){valid = true;}
            if(t.planActual==6 && t.listFilter.length < t.limitForPlan.premium){valid = true;}
            if(t.planActual==7 && t.listFilter.length < t.limitForPlan.sponsored){valid = true;}
            if(t.planActual==8 && t.listFilter.length < t.limitForPlan.free){valid = true;}
            if(t.planActual==9 && t.listFilter.length < t.limitForPlan.team){valid = true;}
            if(t.planActual==10 && t.listFilter.length < t.limitForPlan.pro){valid = true;}
            if(t.planActual==11 && t.listFilter.length < t.limitForPlan.google){valid = true;}
            if(t.planActual==12 && t.listFilter.length < t.limitForPlan.bOrganic){valid = true;}
            // if(!t.PlanPremium){}
            if(!valid){
                if(!t.premiumProfile){
                    t.showPremium();
                }else{
                    t.$store.commit('core/setNotiPush', {
                        show: true,
                        group: "notiPush",
                        type: '2', // 1=success|2=info|3=error|4=loading|5=warning
                        title: "Can't generate any more cards.",
                        text: 'Reached the allowed limit, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                        duration: 15000
                    });
                }
            }
            else{t.$emit('createCardModal', 'createCard');}
            // t.$emit('createCardModal', 'createCard');
        },
        editCard(i){
            let payload = {key: 'bsite_cardItem', val: i};
            this.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload)
        },
        external_link(slug_url){
            if (slug_url!=null && slug_url.length > 0) {
            window.open(this.urlweb + slug_url, "_blank");
            }
        },
        isBackgroundCover(i){
            let t= this;
            let bg = '';
            if(i.pictures !=null && i.pictures.length>0){
                if(i.pictures[0].cover!=null && i.pictures[0].cover_active){
                    bg='background-image: url('+t.urlPic+'thumbs/'+i.pictures[0].cover+');';
                    bg+='background-color:'+i.pictures[0].cover_color+';';
                }else{
                    bg='background-color:'+i.pictures[0].cover_color+';';
                }
            }
            return bg;
        },
        isLogo(i){
            let t= this;
            let logo = '';
            if(i.pictures !=null && i.pictures.length>0){
                if(i.pictures[0].logo!=null){
                    logo='background-image: url('+t.urlPic+'small/'+i.pictures[0].logo+');';
                    logo+='opacity: '+i.pictures[0].logo_opacity+';';
                }
            }
            return logo;
        },
        isProfile(i){
            let t= this;
            let profile = '';
            if(i.pictures!=null && i.pictures.length>0){
                if(i.pictures[0].picture!=null){
                    profile='background-image: url('+t.urlPic+'small/'+i.pictures[0].picture+');';
                    profile+='background-position-y: '+i.pictures[0].pic_positionY+'%;';
                    profile+='background-position-x: '+i.pictures[0].pic_positionX+'%;';
                    profile+='background-size: '+i.pictures[0].pic_zoom+'%;';
                }
            }
            return profile;
        },
        editCardActionDirect(i, modal){
            let payload = {key: 'bsite_cardItem', val: i};
            this.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload);
            this.$emit('setModalDirect', modal);
        },
        changeStatus(i){
            let t = this;
            console.log('changeStatus', i.id, i.status);
            t.$store.dispatch('ModApps/Marketea/changeStatusCard', i)
            .then(r => {
                console.log('changeStatusCard', r);
            })
            .catch(error => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        delCard(i){
            let t =this;
            t.$swal({
                title: "Delete Card?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.$store.dispatch('ModApps/Marketea/deleteCard', i)
                    .then((response) => {
                        if(response.status){
                            t.$notify({
                                group: "noti",
                                type: "success",
                                title: "Success",
                                text: "successfully removed!",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1600px){
    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}
</style>
