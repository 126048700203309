let activeTR = function(id) {
	let t = this;
	let elem = document.querySelector('#tr-' + id);
	let s = t.selected;
	let list = t.InvoicesList;
	let idx;
	let item = {};
	for (const i in s) { if(s[i] == id) {idx = i;}}
	for (const i in list) { if(list[i].id == id) {item = list[i];}}
	// if(t.selected.indexOf(id) > -1) {
	if(t.selected == id) {
		//borrar checked
		// t.selected.splice(idx, 1);
		t.selected = 0;
		t.itemSelected = {id: 0};
		if(!elem.classList.contains('active')){
			elem.classList.add('active');
		}
	} else { 
		// agregar checked
		// t.selected.push(id);
		t.selected = id;
		t.itemSelected = item;
		if(elem.classList.contains('active')){
			elem.classList.remove('active');
		}
	}
};
export default activeTR;
