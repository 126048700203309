let accitonPusher = function() {
	if (this.accitonPusher != undefined) {
		console.log("jobs pucher", this.accitonPusher.action);
		let p = this.accitonPusher;
		let validToken = "Bearer " + window.localtoken_M;
		let action = p.action;
		let rutaName = this.$router.currentRoute.name;
		if (action && p.token != validToken)
		{
			console.log("ejecutar action jobs");
			if(window.localorgdata==p.org_id){
				if (action == "jobs-assign-invo" && this.typez == 0 && rutaName=="Jobs") 
				{
					this.newAddRefresh(p.item, 0);
				}
				if (action == "update-status" || action =="invo-complete") {
					this.updateStatusTask();
				}
				if (action == "del-img") {
					this.dashDelImg();
				}
				if (action == "edit-tags") {
					this.setTags(p.item);
				}
				if (action == "del-tag") {
					this.dashDelTag(p.item);
				}
				if (action == "assign-users") {
					this.updateJobsAssign();
				}
				if (action == "edit-notes") {
					this.updateEditNotes();
				}
				if (action == "update-gallery") {
					this.updateGallery();
				}
				if (action == "update-gallery-cover") {
					this.updateGalleryCover(p.item);
				}
				if (action == "update-color") {
					this.updateColor();
				}
				if (action == "delete-job") {
					this.updateDeleteJob();
				}
				if (action == "update-name-task") {
					this.updateNameTask(p.item);
				}
				if (action == "manage-tasks") {
					this.updateManageTasks();
				}
				if (action == "edit-custInvo") {
					this.setCustomInvo(p.item);
				}
			}else{
				// console.log('no org');
			}
		}
		if (action && action == "jobs-assign-invo" && 
			p.token == validToken && p.invo == 1 && 
			this.typez == 0 && rutaName == "Jobs")
		{
			if(window.localorgdata==p.org_id){
				this.newAddRefresh(p.item, 0);
			}else{
				// console.log('no org');
			}
		}
	}
};
export default accitonPusher;
