let actionVoid = function(idinv) {
	this.closeSubmenu(idinv);
	this.isLoading = true;
	this.$swal({
		title: "Void this Invoice?",
		text: "You will not be able to recover it",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		confirmButtonText: "Yes, Void it!",
		html: `<label>
		<input type="checkbox" value="1" id="sendNotiVoid">
		<span class="swal2-label">Send notification</span>
		</label>`,
	}).then((result) => {
		if (result.value) {
			let send = document.querySelector("#sendNotiVoid").checked ? 1 : 0;
			window.billing
			.put("invoice/void/" + idinv, {
				user_id: window.localuserdata,
				send: send,
				oauth: localStorage.getItem("oauth_m"),
				user_idd: localStorage.getItem("mark_client_data_m"),
				org_id: localStorage.getItem("mark_client_data"),
				typeUser: localStorage.getItem("user_type"),
			})
			.then((response) => {
				console.log(response.data);
				this.isLoading = false;
				if (response.data.status) {
					// this.searchFilter(); 
					this.refreshListByVoid(response.data.data);
					this.$notify({
						group: "noti",
						title: "Void!",
						type: "success",
						text: "Void Invoice Successful",
					});
				}else {	
					this.$store.commit("core/setNotiPush", {
	                    group: "notiPush",
	                    type: '3', // 1=success | 2=info | 3=error | 4=loading | 5=warning
	                    title: "Error",
	                    text: "The invoice could not be voided as you can have active payments, check payment list and void before voiding this invoice.",
	                    show: true,
	                    duration: 20000
	                });	
				}
			})
			.catch((error) => {
				this.isLoading = false;
				console.log(error);
			});
		}
	});
	this.isLoading = false;
};
export default actionVoid;
