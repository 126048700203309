<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4>Profile Picture:</h4>
		</div>
        <div class="col-sm-6 marginTop">
            <div class="profile">
                <div class="profile_picture" :style="isProfile(pic)">
                    <i class="fas fa-user" v-if="isProfile(pic)==''"></i>
                    <Loader v-if="profileLoad" class="profileLoad"></Loader>
                    <div class="edit edit-profile" @click="$refs.profileImg.click()"><i class="fas fa-pen"></i></div>
                </div>
                <input type="file" style="display:none;"
                    ref="profileImg"
                    accept="image/*"
                    @change="loadPicProfile($event)"
                />
            </div>
        </div>
        <div class="col-sm-6 ">
            <div class="row">
                <div class="col-12 text-center" style="margin-top: 5px;">
                    <button type="button" @click="rotateImage()"
                        class="btn btn-info"
                        style="border-radius:10px; padding: 8px 12px; background-color: #0075ff;"
                    >
                        Rotate Image
                        <i class="fas fa-redo"></i>
                    </button>
                </div>
                <div class="col-12 ">
                <label>
                        PositionY : {{ pic.pic_positionY }}%
                    </label>
                    <input type="range"
                        v-model="pic.pic_positionY"
                        min="0" max="100" step="1"
                        style="width: 100%;"
                        @change="setPicProfile()"
                    />
                </div>
                <div class="col-12 marginTop">
                    <label>
                        PositionX : {{ pic.pic_positionX }}%
                    </label>
                    <input type="range"
                        v-model="pic.pic_positionX"
                        min="0" max="100" step="1"
                        style="width: 100%;"
                        @change="setPicProfile()"
                    />
                </div>
                <div class="col-12 marginTop">
                    <label>
                        Zoom : {{ pic.pic_zoom }}%
                    </label>
                    <input type="range"
                        v-model="pic.pic_zoom"
                        min="100" max="200" step="1"
                        style="width: 100%;"
                        @change="setPicProfile()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name:"editPicProfile",
    components:{
        Loader,
    },
    data () {
        return {
            // @ts-ignore
            urlweb : window.urlbSite,
            profileLoad: false,
            profileDefault: {
                picture: null,
                pic_positionY: 50,
                pic_positionX: 50,
                pic_zoom: 100,
            },
        }
    },
    created(){
        this.pic = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        console.log('template_id', this.pic.template_id)
    },
    methods: {
        isProfile(i){
            let t= this;
            let profile = '';
            if(i.picture!=null){
                profile='background-image: url('+t.getUrlPic+'thumbs/'+i.picture+');';
                profile+='background-position-y: '+i.pic_positionY+'%;';
                profile+='background-position-x: '+i.pic_positionX+'%;';
                profile+='background-size: '+i.pic_zoom+'%;';
                localStorage.setItem('picture_old', i.picture)
            }
            return profile;
        },
        loadPicProfile(event){
            let t = this;
            let file = event.target.files[0];
            console.log('loadPicProfile', file);
            if (file) {
                t.profileLoad=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                // @ts-ignore
                reader.onload = function (e) {
                // t.profilePreview = e.target.result;
                t.pic.picture = file;
                t.setPicProfile()
                };
            } else {
                // @ts-ignore
                t.$refs.profileImg.value = null;
            }
        },
        setPicProfile(){
            let t = this;
            t.profileLoad=true;
            let formData = new FormData();
            // @ts-ignore
            formData.append("user_id", window.master_client);
            // @ts-ignore
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.pic.about_id);
            formData.append("picture_old", localStorage.getItem('picture_old'));
            formData.append("picture", t.pic.picture);
            formData.append("pic_positionY", t.pic.pic_positionY);
            formData.append("pic_positionX", t.pic.pic_positionX);
            formData.append("pic_zoom", t.pic.pic_zoom);
            t.$store.dispatch('ModApps/Marketea/editPicProfile', formData)
            .then(r => {
                console.log('setPicProfile', r)
                if(r.status){
                    if(r.upload){
                        t.pic.picture=r.filename;
                    }
                }
                // @ts-ignore
                t.$refs.profileImg.value = null;
                t.profileLoad=false;
            })
            .catch(error => {
                console.log(error);
                // @ts-ignore
                t.$refs.profileImg.value = null;
                t.profileLoad=false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        rotateImage(){
            let t = this;
            t.profileLoad=true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.pic.about_id);
            formData.append("picture", t.pic.picture);
            formData.append("field", "picture");
            formData.append("angle", 90);
            formData.append("pusher_id", pusher.connection.socket_id);
            t.$store.dispatch('ModApps/Marketea/rotateImage', formData)
            .then(r => {
                console.log('setPicProfile', r)
                if(r.upload){
                    t.pic.picture=r.filename;
                }
                t.profileLoad=false;
            })
            .catch(error => {
                console.log(error);
                t.profileLoad=false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
    },
    watch: {
        getCardItem: function(){
            if(this.getCardItem!=null){
                this.pic=this.getCardItem;
            }
        },
        getCardUrlPic: function(){
            this.getUrlPic=this.getCardUrlPic;
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getCardItem: "get_bsite_cardItem",
            getCardUrlPic: "get_bsite_cardUrlPic",
        }),
        getUrlPic: {
            get: function () {
                let t = this;
                return t.getCardUrlPic;
            },
            set: function (v) {
                return v;
            }
        },
        pic: {
            get: function () {
                let t = this;
                if(t.getCardItem!=null){
                    let p= t.getCardItem.pictures;
                    localStorage.setItem('picture_old', (p.length>0) ? p[0].picture : null);
                    return (p.length>0) ? p[0] : t.profileDefault;
                }
                return t.profileDefault;
            },
            set: function (v) {
                return v;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.marginTop{margin-top: 20px;}
.window-header{cursor: move;}

.profile{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 460px;
}
.profile_picture{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    height: 200px;
    width: 200px;
    border: 5px solid #ddd;
    border-radius: 50%;
    margin-left: 15px;
    background-color: #f2f4f5;
    font-size: 70px;
    color: #aaa;
    .profileLoad {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 39%);
        border-radius: 50%;
        z-index: 5;
    }
}

.edit {
    position: absolute;
    color: #fff;
    border: 1px dashed #fcd602;
    padding: 2px 5px;
    font-size: 16px;
    border-radius: 4px;
    z-index: 4;
    &:hover{
        cursor: pointer;
        border-color: #fcd602;
        background-color: rgba(0 0 0 / 20%);
    }
    &.edit-profile{
        z-index: 4;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border: 1px dashed transparent;
        opacity: 0.6;
        i{
            color: #fcd602;
        }
        &:hover{
            opacity: 1;
            color: #fcd602;
            font-size: 20px;
            border: 1px dashed #fcd602;
        }
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
</style>
