let deleteAllIMG = function() {
    let t = this;
    this.$swal({
        title: "Delete all image?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
        if (result.value) {
            t.isLoadingImgNotes = true;
            let url = this.apis.deleteAllIMG()
            window.master
                .post(url, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    job_id: t.jobsNoteID,
                })
                .then((response) => {
                    if (response.data.status) {
                        for (let j in t.listTaks) {
                            if (t.listTaks.hasOwnProperty(j)) {
                                if (t.listTaks[j].id_i == t.jobsNoteID) {
                                    t.listTaks[j].gallery = 0;
                                    t.listTaks[j].galleryList = [];
                                    t.JobsIFGallery = t.listTaks[j].gallery;
                                    t.JobsGalleryList = t.listTaks[j].galleryList;
                                    console.log(
                                        "delImgAllGallery",
                                        t.listTaks[j].galleryList
                                    );
                                }
                            }
                        }
                        t.reEscribirLista();
                    }
                    t.isLoadingImgNotes = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoadingImgNotes = false;
                });
        }
    });
};
export default deleteAllIMG;
