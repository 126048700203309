<template>
    <div>
        <div class="card-header">
            <h3>Text message customization</h3>
        </div>
        <form @submit.prevent="setTextSms" id="form1" class="" >
            <div class="card-body">
                <div class="bgloading" v-show="isLoading" style="padding-top: 0;"><Loader></Loader></div>
                <div class="form-group">
                    <label for="message">Message: <small>Avoid deleting or editing the text between the %%</small></label>
                    <textarea
                        id="message"       
                        class="form-control"                 
                        :editor-toolbar="customToolbar"
                        v-model="f.message"
                        style="width: 100%; height: 300px;"
                        required="true"
                    ></textarea><br>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success ">SAVE</button>
            </div>
        </form>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalTextSms',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        id: 0,
        message: '',
      },
      apis: {},
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4] }],
          ["bold", "italic", "underline", "strike"], 
          ["link"],
          ["clean"] // remove formatting button
      ],  
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getTextSms();
  },
  methods: {
    getTextSms(){
      let t = this;
      t.isLoading = true;
      window.master.post(t.apis.getTextSms(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        t.f.id = response.data.r.id;
        t.f.message = response.data.r.message;
        t.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    setTextSms(){
      let t = this;
      t.isLoading = true;
      window.master.post(t.apis.setTextSms(), t.f)
      .then((response) => {
        t.getTextSms();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
