<template>
	<div class="row" style="padding: 0px 20px;">
		<div class="col-md-4" style="padding: 0rem 0.8rem">
			<div class="row" style="border: 1px solid #ccc;padding: 5px;">
				<label class="text-success col-md-3 col-sm-3 tlabel" style="padding: 0.4em 0">
					Lorem Ipsum:
				</label>
				<h3 class="col-md-9 col-sm-9 text-left">
					<span class="badge badge-light total_ipad">
						{{totalIncome}}
					</span>
				</h3>
				<div class="col-md-12" style="padding: 0px">
					<GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartInv" :options="OpInvoicesChart"></GChart>
				</div>                    
			</div>
		</div>
		<div class="col-md-4" style="padding: 0rem 0.8rem">
			<div class="row" style="border: 1px solid #ccc;padding: 5px;">
				<label class="text-info col-md-4 col-sm-3 tlabel" style="padding: 0.4em 0">
					Lorem Ipsum:
				</label>
				<h3 class="col-md-8 col-sm-9 text-left">
					<span class="badge badge-light total_ipad">
						 {{totalUnaccepted}}
					</span>
				</h3>                  
				<div class="col-md-12" style="padding: 0px">
					<GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartPro" :options="optionsChartPro"></GChart>
				</div>
			</div>
		</div>
		<div class="col-md-4" style="padding: 0rem 0.8rem">
			<div class="row" style="border: 1px solid #ccc;padding: 5px;">
				<label class="text-danger col-md-4 col-sm-3 tlabel" style="padding: 0.4em 0">
					Lorem Ipsum:
				</label>
				<h3 class="col-md-8 col-sm-9 text-left">
					<span class="badge badge-light total_ipad">
						{{totalExpenses}}
					</span>
				</h3>            
				<div class="col-md-12" style="padding: 0px">
					<GChart :settings="{packages: ['corechart'], }" type="AreaChart" :data="dataChartExp" :options="optionsChartExp"></GChart>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { GChart } from 'vue-google-charts'
export default {
	name: 'chartAds',
	components: {
		GChart
	},
	data () {
		return {
			isLoading: true,
			dataChartInv: [
			["Lorem ipsum", "Total"], 
			['Jan', 20],
			['Feb', 10],
			['Mar', 30],
			['May', 50],
			['Jun', 40],
			['Jul', 70],
			['Aug', 60],
			['Sep', 80],
			['Oct', 100],
			['Nov', 105],
			['Dec', 150],
			],
			OpInvoicesChart: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#5ac146"],
                hAxis: { title: "Lorem ipsum", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            dataChartExp: [
            ["Lorem ipsum", "Amount"],            	
            ['Jan', 200], 
            ['Feb', 100],
            ['Mar', 30],
            ['May', 50],
            ['Jun', 40],
            ['Jul', 70],
            ['Aug', 60],
            ['Sep', 80],
            ['Oct', 100],
            ['Nov', 105],
            ['Dec', 150],
            ],
            optionsChartExp: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#fa5838"],
                hAxis: { title: "Lorem ipsum", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            dataChartPro: [
            ["Lorem ipsum", "Amount"],
            ['Jan', 2], 
            ['Feb', 10],
            ['Mar', 3],
            ['May', 5],
            ['Jun', 4],
            ['Jul', 7],
            ['Aug', 6],
            ['Sep', 20],
            ['Oct', 10],
            ['Nov', 15],
            ['Dec', 10],
            ],
            optionsChartPro: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#0065df"],
                hAxis: { title: "Lorem ipsum", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "40" },
            },
            totalIncome: 715,
            totalExpenses: 985,
            totalUnaccepted: 92,
		}
	}
}
</script>
<style lang="css" scoped>
</style>
