<template>
    <div class="container segurity">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="prof_title">
                    <h4><i class="fas fa-sms"></i> Notis SMS</h4>
                    <p>
                        Activate and configure SMS notifications to improve the user experience, obtaining faster and more direct responses.
                    </p>
                </div>
                <div class="card" style="border-top: 4px solid #a1e0ae">
                    <div class="icontri"></div>
                    <div class="card-body">
                        <div v-if="isLoading"><Loader></Loader></div>
                        <form v-else @submit.prevent="sendRequestNotiSMS">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="">Submit request for activation and approval of phone number.</label>
                                    <div class="input-group ">
                                        <input
                                                type="text"
                                                class="form-control"
                                                v-model="f.phoneNoti"
                                                placeholder="+1 555-555-5555 "
                                                :disabled="(edit)"
                                            />
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-warning" v-if="status==1">
                                                Pending
                                            </button>
                                            <button type="button" class="btn btn-success" v-else-if="status==2">
                                                Activo
                                            </button>
                                            <button type="button" class="btn btn-danger" v-else>
                                                Inactivo
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <button
                                type="submit"
                                class="btn btn-primary float-right"
                                :disabled="isDisabled"
                            >
                                Send Request
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'requestSMSnoti',
    components: {
        Loader
    },
    data () {
        return {
            isLoading: false,
            f: {
                user_id: window.master_client,
                org_id: window.localorgdata,
                phoneNoti: ''
            },
            status: 0,
            orginf: {},
            edit: false,
        }
    },
    created () {
        let t = this;
        t.$store.dispatch('core/session/validTypeUser').then((r) => {
            if (!r) {
                t.$router.push({ name: 'Home' })
            }
            else{
                t.orginf = t.$store.getters['core/Org/orginf'];
                t.getNumSMS();
            }
        })
    },
    methods: {
        getNumSMS(){
            let t = this;
            t.isLoading = true
            window.master.post('getAccountsSMS', {
                user_id: t.f.user_id,
                org_id: t.f.org_id,
                app_id: 0
            })
            .then((response) => {
                if(response.data.status){
                 t.f.phoneNoti = response.data.r.tlf;
                 t.status = response.data.r.status;
                 if(response.data.r.status > 0){
                    t.edit = true;
                 }
                } else {
                    t.f.phoneNoti = t.orginf.com_telephone;   
                }
                t.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false;
                t.f.phoneNoti = t.orginf.com_telephone;
            });
        },
        sendRequestNotiSMS () {
            let t = this;
            t.isLoading = true;
            let url = 'AddAccountsSMS';
            if(t.edit){
                url = 'editAccountsSMS';
            }
            window.master.post(url, {
                user_id: t.f.user_id,
                org_id: t.f.org_id,
                app_id: 0,
                type_pack: 1,
                tlf: t.f.phoneNoti,
                status: 1
            })
            .then((response) => {
                if (response.data.status) {
                    t.f.CurrentPassword = ''
                    t.f.newPassword = ''
                    t.f.rePassword = ''
                    t.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                        title: "Send Request",
                        text: "Request sent, you will be notifying in the coming days.",
                        show: true,
                        duration: 20000
                    });
                    if(response.data.s){
                        t.status = response.data.s.status;
                    }
                }
                t.edit=true;
                t.isLoading = false;
            })
            .catch((error) => {
                console.log(error)
                t.isLoading = false;
                t.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                    title: "Error",
                    text: "Failed to connect to the server, try again later.",
                    show: true,
                    duration: 10000
                });
            })
        }
    },
    computed: {
        ...mapGetters("core", { 
            accitonPusher: "get_accitonPusher",
        }),
        ...mapGetters("core/Org", { 
            orgdata: "orginf", 
        }),
        isDisabled: function () {
            if (this.f.phoneNoti === null || this.f.phoneNoti === '' || this.edit==true) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        "accitonPusher": function() {    
            if (this.accitonPusher != undefined) {
                let p = this.accitonPusher;
                if (p.action == "serviceSMS" && p.org_id == window.localorgdata) {
                    this.status = this.accitonPusher.accountSMS;
                }
            }
        }
    }
};
</script>
<style lang="css" scoped>
.passsecurity {
    position: relative;
}
.Password {
    max-width: 100% !important;
}
.Password__field {
    background-color: #fff !important;
    border: 1px solid #d8d8d8 !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
    font-size: 14px !important;
    padding: 9px !important;
    width: 100% !important;
}
.Password__strength-meter {
    margin: 0px auto 0px !important;
}
.icontri {
    position: absolute;
    top: -21px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #a1e0ae;
    transform: translateX(-50%);
}
.prof_title {
    display: block;
    font-size: 14px;
}
.prof_title h4 {
    font-weight: 100;
    font-size: 24px;
    text-align: center;
}
.segurity {
    margin-top: 60px;
}
.segurity label {
    display: block;
    margin-bottom: 0.1rem;
}
</style>
