let delTsgs = function() {
    let id = this.tagsModalId;
    this.$swal({
        title: "Delete Tags?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
        if (result.value) {
            let url = this.apis.delTsgs()
            window.master
                .delete(url, {
                        user_id: this.user_id,
                        org_id: this.org_id,
                        id: id,
                    }
                )
                .then((response) => {
                    let r = response.data.status;
                    if (r == false) {
                        this.$swal.fire({
                            icon: "error",
                            title: "cannot be deleted because it is in use",
                            showConfirmButton: false,
                            timer: 4000,
                        });
                    } else {
                        this.update_menuTags();
                        //this.getJobsTags();
                    }
                })
                .catch((error) => console.log(error));
        }
    });
};
export default delTsgs;
