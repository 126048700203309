let del = function(id, idx) {
    this.$swal({
        title: "Delete Jobs Order?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
        if (result.value) {
            let url = this.apis.del(id)
            window.master
                .post(url, {
                        user_id: this.user_id,
                        org_id: this.org_id,
                        id: id,
                    }
                )
                .then((response) => {
                    let r = response.data.status;
                    this.listTaks.splice(idx, 1);
                    this.$notify({
                        group: "noti",
                        type: "success",
                        title: "Success",
                        text: "successfully removed!",
                    });
                    this.reEscribirLista();
                    this.starGrid();
                    this.update_JOPending(this.tagsModalId);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    });
};
export default del;
