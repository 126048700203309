<template>
    <div class="card ">
        <leadsCharts v-if="chartData.length>0"></leadsCharts>
        <div class="card-header" style="padding: 0.25rem 1.25rem; ">
            <div class="row">
                <div class="col-md-8">
                    <!-- <h3 style="margin-bottom: 0px; display: inline-block;">
                        <strong class="" style="font-size: 32px;"> Leads</strong>
                    </h3> -->
                    <button type="button" class="btn btnadd" @click="openLateral('NewLead')" style="position:relative; top:-5px;">+ Add </button>
                </div>
                <div class="col-md-4 filtros">
                    <form class="form-inline" @submit.prevent="getLeads()">
                        <div class="input-group" >
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                            </div>
                            <select v-model="year" class="form-control" @change="getLeads()">
                                <option v-for="(i, idx) in filterYear" :key="idx" :value="i">
                                    {{ i }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group" style="width: 300px;">
                            <div class="input-group-prepend">
                                <div class="btnShowStatus" >
                                    <span @click="showStatus()" style="cursor: pointer" >
                                        <i class="fas fa-cog"></i>
                                    </span>
                                    <span class="status_list" v-if="showActive">
                                        <ul class="reset" style="padding-left: 0">
                                            <li >
                                                Show spam
                                                <div class="onoffswitch">
                                                    <input
                                                    type="checkbox"
                                                    id="spam"
                                                    v-model="spam"
                                                    :checked="spam"
                                                    class="onoffswitch-checkbox"
                                                    @input="handleOnInput(spam, 'leadsSpam')"
                                                    />
                                                    <label class="onoffswitch-label" for="spam">
                                                        <span class="onoffswitch-inner"></span>
                                                        <span class="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li >
                                                Show trash
                                                <div class="onoffswitch">
                                                    <input
                                                    type="checkbox"
                                                    id="trash"
                                                    v-model="trash"
                                                    :checked="trash"
                                                    class="onoffswitch-checkbox"
                                                    @input="handleOnInput(trash, 'leadsTrash')"
                                                    />
                                                    <label class="onoffswitch-label" for="trash">
                                                        <span class="onoffswitch-inner"></span>
                                                        <span class="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            <input type="text" class="form-control search" v-model="search" placeholder="Search lead">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="container-fluid card-body" style=" background-color: #f2f4f5;padding-top: 10px;">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 130px;">ID/Date</th>
                                <th style="width: 200px;">IP/Location</th>
                                <th  style="width: 135px;text-align: center;">Source</th>
                                <th  style="width: 135px;text-align: center;">CTA</th>
                                <th >Customer</th>
                                <th style="width: 300px;">Message</th>
                                <th style="width: 100px;text-align: center;">Status</th>
                                <th style="width: 60px;text-align: center;">Send</th>
                            </tr>
                        </thead>
                        <tbody v-if="isLoading == false">
                            <tr 
                                v-for="(i, index) in leadsList" 
                                :key="i.id" 
                                :class="
                                    (i.status == 5) ? ((trash) ? 'viewTrash' : 'noneTrash') : 
                                    ((i.status == 4) ? ((spam) ? 'viewSpam': 'noneSpam') : '')
                                "
                            >
                                <td class="datetime">
                                    <span class="badge badge-primary" style="font-size: 14px;">#{{i.id}}</span>
                                    <span v-if="i.form_name=='Added manually'"  class="badge badge-light" style="margin-left:10px;border:1px solid #ccc; cursor:pointer;" @click="editLead(i)"><i class="fas fa-pen"></i></span>
                                    <br />
                                    {{ i.date_create }} <br />
                                    {{ i.hora }}
                                </td>
                                <td class="ipLocation">
                                    {{i.ip}}<br>
                                    {{i.locationIP}}
                                </td>
                                <td class="source">
                                    <div class="action_order_btn" >
                                        <span class="nameCRM" @click="activeSource(index)" style="cursor:pointer;">
                                            <span class="badge" v-if="i.source_id!=null">  
                                                <span v-if="i.source_id==0">
                                                    Uncategorized
                                                </span>
                                                <span v-else>
                                                    <span  
                                                    v-for="(s, indexs) in mark_source" 
                                                    :key="indexs"
                                                    v-if="s.id==i.source_id">
                                                        <span  v-if="s.show_img==0">
                                                            {{i.sourceName}}
                                                        </span>
                                                        <img v-else :src="s.image" alt="icon" class="icoMark">
                                                    </span>
                                                </span>  
                                            </span>
                                            <span v-else  class="badge select0">
                                                Select
                                            </span>
                                        </span>
                                        <span class="status_list ulCRM" v-if="sourceActive == index">
                                            <ul class="reset" style="padding-left: 0">
                                                <li 
                                                    v-if="i.source_id!=0" 
                                                    @click="changeCRM(0, 0, i.id)" 
                                                    >
                                                        Uncategorized
                                                </li>
                                                <li 
                                                    v-for="(s, indexs) in mark_source"
                                                    v-if="s.id!=i.source_id" 
                                                    :key="indexs"
                                                    @click="changeCRM(0, s.id, i.id)" 
                                                    >
                                                        <span v-if="s.show_img==0 || s.img==null">{{s.name}}</span>
                                                        <img v-else :src="s.image" alt="icon" class="icoMark">
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </td>
                                <td class="cta">
                                    <span v-if="i.type==3" style="display: block; margin-bottom: 10px;text-align: center;">
                                        <span class="badge badge-light" style="border:1px solid #ccc;">Smart Card: {{i.form_name}}</span>
                                    </span>
                                    <div v-if="i.cta_id>1000" class="text-center">
                                        <span
                                            v-for="(s, indexs) in ctachannel"
                                            :key="indexs"
                                            v-if="s.id==i.cta_id"
                                        >
                                            <span>{{s.name}}</span>
                                        </span>
                                    </div>
                                    <div class="action_order_btn" v-else>
                                        <span class="nameCRM" @click="activeCTA(index)" style="cursor:pointer;">
                                            <span class="badge" v-if="i.cta_id!=null">
                                                <span v-if="i.cta_id==0">
                                                    Uncategorized
                                                </span>
                                                <span v-else>
                                                    <span  
                                                    v-for="(s, indexs) in ctachannel" 
                                                    :key="indexs"
                                                    v-if="s.id==i.cta_id">
                                                        <span  v-if="s.show_img==0">
                                                            {{s.name}} 
                                                        </span>
                                                        <img v-else :src="s.image" alt="icon" class="icoMark">
                                                    </span>
                                                </span>
                                            </span>
                                            <span v-else  class="badge select0">
                                                Select 
                                            </span>
                                        </span>
                                        <span class="status_list ulCRM" v-if="ctaActive == index">
                                            <ul class="reset " style="padding-left: 0">
                                                <li 
                                                    v-if="i.cta_id!=0" 
                                                    @click="changeCRM(2, 0, i.id)" 
                                                    >
                                                        Uncategorized
                                                </li>
                                                <li 
                                                    v-for="(s, indexs) in ctachannel"
                                                    v-if="s.id!=i.cta_id" 
                                                    :key="indexs"
                                                    @click="changeCRM(2, s.id, i.id)" 
                                                    >
                                                        <span v-if="s.show_img==0 || s.img==null">{{s.name}}</span>
                                                        <img v-else :src="s.image" alt="icon" class="icoMark"> 
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </td>
                                <td class="customer">
                                    <span v-if="i.firstCustomer.length>0">
                                        {{i.firstCustomer}} 
                                    </span>
                                    <span v-if="i.lastCustomer.length>0">
                                        {{i.lastCustomer}}<br>
                                    </span>
                                    <div v-if="i.phoneCustomer.length>0">
                                        {{i.phoneCustomer}}
                                    </div>
                                    <span v-if="i.emailCustomer.length>0">
                                        {{i.emailCustomer}}
                                    </span>
                                </td>
                                <td class="message" @click="MessagePreview(i)" style="cursor: pointer;">
                                    {{i.msg.substring(0, 122)}}<span v-if="i.msg.length>122">...</span>
                                    <div>See detalis.</div>
                                </td>
                                <td class="status text-center">   
                                    <span
                                        v-if="i.status == 6"
                                        style="padding: 0.5em 0.4em;"
                                        class="badge badge-success"
                                    >
                                        Sent to SALES
                                    </span>
                                    <div
                                        v-else
                                        class="action_order_btn"
                                        style="width: 100px"
                                    >
                                        <span class="nameStatus" 
                                            @click="activeStatus(index)"
                                            style="cursor: pointer"
                                        >
                                            <span
                                                v-if="i.status == 0"
                                                class="badge badge-warning"
                                                >Pending
                                                <i class="fas fa-angle-down"></i
                                            ></span>
                                            <span
                                                v-else-if="i.status == 4"
                                                class="badge badge-danger spam"
                                                >Spam
                                                <i class="fas fa-angle-down"></i
                                            ></span>
                                            <span
                                                v-else-if="i.status == 5"
                                                class="badge badge-default trash"
                                                >Trash
                                                <i class="fas fa-angle-down"></i
                                            ></span>
                                            <span
                                                v-else-if="i.status == 6"
                                                class="badge badge-success"
                                                >Sent to SALES
                                                <i class="fas fa-angle-down"></i
                                            ></span>
                                            <span
                                                v-else
                                                class="badge badge-secondary"
                                                >None
                                                <i class="fas fa-angle-down"></i
                                            ></span>
                                        </span>
                                        <span
                                            class="status_list"
                                            v-if="statusActive == index"
                                        >
                                            <ul class="reset" style="padding-left: 0">
                                                <li @click="statusChange(0, i.id)" v-if="i.status!=0">
                                                    <span
                                                        class="badge badge-warning"
                                                        >Pending</span
                                                    >
                                                </li>
                                                <li @click="statusChange(4, i.id)"  v-if="i.status!=4">
                                                    <span
                                                        class="badge badge-danger spam"
                                                        >Spam</span
                                                    >
                                                </li>
                                                <li @click="statusChange(5, i.id)"  v-if="i.status!=5">
                                                    <span
                                                        class="badge badge-default trash"
                                                        >Trash</span
                                                    >
                                                </li>
                                                <li @click="statusChange(6, i.id)"  v-if="i.status!=6">
                                                    <span
                                                        class="badge badge-success send"
                                                        >Sent to SALES</span
                                                    >
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </td>
                                <td class="send" style="text-align: center;">
                                    <div v-if="i.status != 4 && i.status != 5">
                                        <span class="cdropdown salesSendStatus" v-if="i.status != 6">
                                            <button class="btn btn-success btn-sm salesSend">
                                                <i class="fas fa-chart-line"></i>
                                            </button>
                                            <label>
                                            <input type="checkbox" :id="'salescheck-'+index"/>
                                            <ul class="text-left">
                                                <li @click="statusChangeSales(6, i.id, 'retail')">Sale Retail</li>
                                                <li @click="statusChangeSales(6, i.id, 'insurance')">Sale Insurance</li>
                                            </ul>
                                            </label>
                                        </span>
                                        <span v-if="i.status==6">
                                            <span class="badge badge-primary" v-if="i.sales_type=='insurance'">Insurance</span>
                                            <span class="badge badge-info" v-else>Retail</span>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <button class="btn btn-success btn-sm salesSend disabled">
                                            <i class="fas fa-chart-line"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="isLoading">
                            <tr>
                                <th colspan="8"><Loader></Loader></th>
                            </tr>
                        </tbody>
                    </table>
                    <ul
                        v-if="
                            pagination.total > pagination.per_page && content == false
                        "
                        class="pagination justify-content-center"
                        style="borde: 1px solid blue"
                    >
                        <li v-if="pagination.current_page !== 1" class="page-car">
                            <button
                                class="page-link"
                                aria-label="Previous"
                                v-on:click.prevent="
                                    changePage(pagination.current_page - 1)
                                "
                            >
                                <span aria-hidden="true">«</span>
                            </button>
                        </li>
                        <li
                            v-for="(page, index) in pagesNumber"
                            :key="index"
                            class="page-car"
                            :class="{ active: page == pagination.current_page }"
                        >
                            <button
                                class="page-link"
                                v-on:click.prevent="changePage(page)"
                            >
                                {{ page }}
                            </button>
                        </li>
                        <li
                            v-if="pagination.current_page !== pagination.last_page"
                            class="page-car"
                        >
                            <button
                                class="page-link"
                                aria-label="Next"
                                v-on:click.prevent="
                                    changePage(pagination.current_page + 1)
                                "
                            >
                                <span aria-hidden="true">»</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <modal
            name="modal-message"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">Message</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="CloseModal"
                ></i>
            </div>
            <div class="card-body">
                <div v-if="type==1 || type==3" > 
                    <div v-for="i in message" :key="i.id" style="display: flex">
                        <div v-if="i.campo!='ip_info'">
                            <b style="width: 120px">{{ i.campo }}:</b>
                            <span v-if="i.type == 'check'" style="width: 80%">
                                <span v-for="o in i.value" :key="o.id"> {{ o }} ,</span>
                            </span>
                            <span v-else style="width: 80%"> {{ i.value }}</span>
                        </div>
                    </div>
                </div>
                <div v-else >
                    <div style="display: flex">
                        <b style="width: 120px">Date/Times:</b>
                        <span style="width: 80%"> {{ message.date }}</span>
                    </div>
                    <div style="display: flex">
                        <b style="width: 120px">Url Origin:</b>
                        <span style="width: 80%"> {{ message.url }}</span>
                    </div>
                </div>
                <div style="display: flex">
                    <b style="width: 80px">Location IP:</b>
                    <span style="width: 80%"> {{ locationIP }}</span>
                </div>
            </div>
        </modal>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
import NewLead from '@/apps/Marketea/components/NewLead';
import leadsCharts from '@/apps/Marketea/components/leads/leadsCharts';
export default {
    name: "LeadsMark",
    components: {
        Loader,
        modalLateral,
        NewLead,
        leadsCharts
    },
    data() {
        return {
            isLoading: false,
            statusActive: null,
            sourceActive: null,
            ctaActive: null,
            qualityActive: null,
            leadsListCompleta: [],
            leadsList: [],
            message: [],
            search: "",
            source_id: [],
            cta_id: [],
            quality_id: [],
            selectFilter: "0",
            selectFilterList: [],
            offset: 4,
            page: 1,
            pagination: {
                current_page: 1,
            },
            query: "",
            result: "",
            content: false,
            ip: '',
            locationIP: '',
            type: 1,
            apis: {},
            spam: true,
            trash: false,
            conversion: false,
            showActive: false,
            SettingBox: "",
            m: {
                show: false,
                component: '',
                data: []
            }, 
            ctachannel: [],
            mark_source: [],
            quality: [],
            form_app: 0,
            phone_link: 0,
            manual_added: 0,
            sales:0,
            pendding:0,
            counter_spam:0,
            trahs:0,
            PlanPremium: false,
            planesPro: [6, 7, 10, 11, 12],
            planActual: 1,
            appIDactual: localStorage.getItem('appIDactual') || 0, 
            listCRM: [],
            year: new Date().getFullYear(),
            filterYear: [new Date().getFullYear()],
            typeChart: "ColumnChart",
            chartData: [],
            chartOptions: {
                // tooltip: {isHtml: true},
                title:'Leads: Jan - Dec '+ new Date().getFullYear(),
                colors: ["#0065df", "#5ac146", "#ffbc34", "#fa5838", "#ccc", "#ffbc34", "#b54934", "#e6e6e6"],
                height: "260",
                //bar: {groupWidth: "50%"},
                chartArea: { left: "5%", top: "15%", width: "100%", height: "200" },
                legend: { position:'none', textStyle: { color: "#333", fontSize: 14 } },
                vAxis: { textStyle: { color: "#333", fontSize: 14 } }, // linea vertical
                hAxis: { direction: 1, textStyle: { color: "#333", fontSize: 14 } }, // linea horizontal
            },
            mounths: {
                1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun", 7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
			this.planesPro = this.$store.getters['ModApps/Marketea/get_planesPro'];
            this.PlanPremium = (this.planesPro.indexOf(this.$store.getters['core/apps/getPlanIdApp'])>-1);
            // this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6);
            console.log('PlanPremium', this.PlanPremium);
            this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
            if(this.planActual==7){
                this.PlanPremium = true;
                console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
            }
            this.appIDactual = this.$store.getters['core/apps/getAppIDactual'];
            let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
            this.apis = urlApis.leads;
            this.getLeads();
        },
        changePlanApp(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 15000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        editLead(i){
            let t = this;
            t.m.show= true;
            t.m.data= [i];
            t.m.component= 'NewLead';
            this.$store.commit('core/setOpenModal', t.m);
        },
        openLateral(name) {
            let t = this;
            if(!t.PlanPremium){
                t.changePlanApp();
            }else{
                t.m.show= true;
                t.m.component= name;
                t.m.data=[];
                this.$store.commit('core/setOpenModal', t.m);
            }
        },
        showStatus() {
            this.showActive = !this.showActive;
        },
        handleOnInput(val, type){
            val = !val;
            window.master.post(this.apis.setStatusLeads(), {
                org_id: window.localorgdata,
                user_id: window.master_client,
                val: val,
                type: type
            })
            .then((response) => {
                console.log('setStatusLeads');
                this.filtrarStatus();
            })
            .catch((error) => {console.log(error)});
        },
        MessagePreview(i) {
            var items = i.message;
            this.message = JSON.parse(items);
            this.ip = i.ip;
            this.type = i.type;
            this.locationIP = i.locationIP;
            this.$modal.show("modal-message");
        },
        CloseModal() {
            this.$modal.hide("modal-message");
        },
        activeStatus(index) {
            this.sourceActive = null;
            this.qualityActive = null;
            this.ctaActive = null;
            if (this.statusActive == index) {
                this.statusActive = null;
            } else {
                this.statusActive = index;
            }
        },
        activeSource(index) {
            this.statusActive = null;
            this.qualityActive = null;
            this.ctaActive = null;
            if (this.sourceActive == index) {
                this.sourceActive = null;
            } else {
                this.sourceActive = index;
            }
        },
        activeCTA(index) {
            this.sourceActive = null;
            this.qualityActive = null;
            this.statusActive = null;
            if (this.ctaActive == index) {
                this.ctaActive = null;
            } else {
                this.ctaActive = index;
            }
        },
        activeQuality(index) {
            this.sourceActive = null;
            this.statusActive = null;
            this.ctaActive = null;
            if (this.qualityActive == index) {
                this.qualityActive = null;
            } else {
                this.qualityActive = index;
            }
        },
        changeCRM(table, crm_id, lead_id){
            let t = this;
            if(table==0){t.sourceActive = null;}
            if(table==1){t.qualityActive = null;}
            if(table==2){t.ctaActive = null;}
            window.master.post("leads/changeCRM", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                t: table,
                crm_id: crm_id,
                lead_id: lead_id,
                year: t.year,
            })
            .then((response) => {
                console.log('changeCRM', response.data)
                if(response.data.status){
                    for (let e in t.leadsList) {
                        if(t.leadsList[e].id == lead_id){
                            if(table==0){
                                t.leadsList[e].sourceName = "Uncategorized";
                                t.leadsList[e].source_id= crm_id;
                                for (let j in t.mark_source) {
                                    if(t.mark_source[j].id == crm_id){
                                        t.leadsList[e].sourceName= t.mark_source[j].name;
                                    }
                                }
                            }
                            if(table==1){
                                t.leadsList[e].qualityName = "Uncategorized";
                                t.leadsList[e].quality_id= crm_id;
                                for (let j in t.quality) {
                                    if(t.quality[j].id == crm_id){
                                        t.leadsList[e].qualityName= t.quality[j].name;
                                    }
                                }
                            }
                            if(table==2){
                                t.leadsList[e].ctaName = "Uncategorized";
                                t.leadsList[e].cta_id= crm_id;
                                for (let j in t.ctachannel) {
                                    if(t.ctachannel[j].id == crm_id){
                                        t.leadsList[e].ctaName= t.ctachannel[j].name;
                                    }
                                }
                            }
                        }
                    }
                    if(response.data.dataChart){
                        let dataChart = {
                            leadsAllYear: response.data.dataChart.leadsA,
                            sales_leads: response.data.dataChart.sales,
                        };
                        t.rowCharts(dataChart);
                    }
                }
            })
            .catch((error) => {console.log(error)});
        },
        statusChange(status, id) {
            let t =this;
            if(status==5){ // delete
                t.$swal({
                    title: "Delete Item?",
                    text: "Are you sure to send to trash?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Delete it!",
                }).then((result) => {
                    if (result.value) {
                        t.setStatus(status, id);
                    }else{this.statusActive = null;}
                });
            }else{
                if(!t.PlanPremium){
                    t.changePlanApp();
                }else{
                    t.setStatus(status, id);
                }
            }
        },
        statusChangeSales(status, id, sales_type){
            let t = this;
            if(!t.PlanPremium) {
                t.changePlanApp();
            }else{
                window.master.put(this.apis.status(id), {
                    status: status,
                    sales_type: sales_type,
                })
                .then((response) => {
                    console.log('statusChange', response.data)
                    if(status!=5){
                        this.$notify({
                            group: "noti",
                            type: "success",
                            title: "Successful change",
                            text: "Status update",
                        });
                    }else{
                        this.$notify({
                            group: "noti",
                            type: "success",
                            title: "Successful change",
                            text: "Record trashed",
                        });
                    }
                    t.setSales(id, sales_type);
                })
                .catch((error) => {console.log(error)});
            }
        },
        setStatus(status, id){
            let t = this;
            this.statusActive = null;
            window.master.put(this.apis.status(id), {
                status: status,
            })
            .then((response) => {
                console.log('statusChange', response.data)
                if(status!=5){
                    this.$notify({
                        group: "noti",
                        type: "success",
                        title: "Successful change",
                        text: "Status update",
                    });
                }else{
                    this.$notify({
                        group: "noti",
                        type: "success",
                        title: "Successful change",
                        text: "Record trashed",
                    });
                }
                if(status==6){

                    if(!t.PlanPremium){
                        t.changePlanApp();
                    }else{
                        t.setSales(id);
                    }
                }
            })
            .catch((error) => {console.log(error)});
        },
        setSales(id, type){
            let t= this;
            let sales_type = (type == undefined) ? 'retail' : type;
            window.master.post('setSales', {
                org_id: window.localorgdata,
                user_id: window.master_client,
                lead_id: id,
                sales_type: sales_type,
            })
            .then((response) => {
                console.log('setSales', response.data)
                //t.getLeads(1);
            })
            .catch((error) => {console.log(error)});
        },
        getLeads(load) {
            let t= this;
            if(load==undefined){t.isLoading = true;}
            t.statusActive = null;
            //.post("leads/form" + t.query, {
            window.master.post(t.apis.gets(t.query), {
                org_id: window.localorgdata,
                user_id: window.master_client,
                search: t.search,
                year: t.year,
                source_id: t.source_id,
                cta_id: t.cta_id,
                quality_id: t.quality_id,
                socket_id: pusher.connection.socket_id
            })
            .then((response) => {
                console.log('data leads', response.data);
                t.selectFilterList = response.data.nameFilter;
                let e = response.data.lista.data;
                t.listCRM = response.data.listCRM;
                let crm = t.listCRM;
                t.mark_source = crm.crm_mark_source;
                let years = response.data.years;
                if(years!=null){
                    let oldYear = (years.StartYear) ? years.StartYear.slice(0, 4) : new Date().getFullYear();
                    let currentYear = (years.EndYear) ? years.EndYear.slice(0, 4) : new Date().getFullYear();
                    t.filterYear = [];
                    for (let index = oldYear; index <= currentYear; index++) {
                        t.filterYear.push(index);
                    }
                }
                for (let i in t.mark_source) {
                    if(t.mark_source[i]){
                        let item = t.mark_source[i];
                        if(item.img != null) {
                            item.image = window.urlmaster+item.img.slice(1, item.img.length);
                        }
                    }
                }
                t.ctachannel = crm.crm_ctachannel;
                for (let i in t.ctachannel) {
                    if(t.ctachannel[i]){
                        let item = t.ctachannel[i];
                        if(item.img != null) {
                            item.image = window.urlmaster+item.img.slice(1, item.img.length);
                        }
                    }
                }
                t.ctachannel.push({
                    org_id: window.localorgdata,
                    user_id: window.master_client,
                    id: 1001,
                    img: null,
                    name: "Form (by Marketea)",
                    orderItem: t.ctachannel.length+1,
                    show_img: 0,
                    status: 1,
                });
                t.ctachannel.push({
                    org_id: window.localorgdata,
                    user_id: window.master_client,
                    id: 1002,
                    img: null,
                    name: "Phone (link)",
                    orderItem: t.ctachannel.length+1,
                    show_img: 0,
                    status: 1,
                });
                t.quality = crm.crm_quality;
                let p = [];
                for (const i in e) {
                    let locationIP='';
                    if(e[i].ip_info!=null){
                        if(Object.keys(e[i].ip_info).length >0) {
                            locationIP = e[i].ip_info.country +', '; 
                            locationIP += e[i].ip_info.region +', '; 
                            locationIP += e[i].ip_info.city +', '; 
                            locationIP += e[i].ip_info.postal;
                        }
                    }
                    let sourceName = "Uncategorized";
                    for (let j in t.mark_source) {
                        if(t.mark_source[j].id == e[i].source_id){
                            sourceName= t.mark_source[j].name;
                        }
                    }
                    let ctaName = "Uncategorized";
                    for (let j in t.ctachannel) {
                        if(t.ctachannel[j].id == e[i].cta_id){
                            ctaName= t.ctachannel[j].name;
                        }
                    }
                    let qualityName = "Uncategorized";
                    for (let j in t.quality) {
                        if(t.quality[j].id == e[i].quality_id){
                            qualityName= t.quality[j].name;
                        }
                    }
                    let firstCustomer = "Does not apply";
                    let lastCustomer = "";
                    let phoneCustomer = "";
                    let emailCustomer = "";
                    let msg = "See details";
                    let details = JSON.parse(e[i].message);
                    for (let j in details) {
                        if(details[j].campo){
                            if(details[j].campo == "First Name" || details[j].campo == "First name" || 
                            details[j].campo == "first name" || details[j].campo == "Name" || 
                            details[j].campo == "Name " || details[j].campo == "name "|| 
                            details[j].campo == " Name" || details[j].campo == " name"|| 
                            details[j].campo == " Name " || details[j].campo == " name "|| 
                            details[j].campo == "name" || details[j].campo == "Name :" || 
                            details[j].campo == "name :" || details[j].campo == "Name:" || 
                            details[j].campo == "name:" || details[j].campo == "Full Name" || 
                            details[j].campo == "Full name" || details[j].campo == "full name")
                            {
                                firstCustomer=(details[j].value!=null) ? details[j].value : '';
                            }
                            if(details[j].campo == "Last Name" || details[j].campo == "Last name" || details[j].campo == "last name"){
                                lastCustomer=(details[j].value!=null) ? details[j].value : '';
                            }
                            if(details[j].campo == "PHONE" || details[j].campo == "Phone" || details[j].campo == "phone" || details[j].campo == "Telephone" || details[j].campo == "telephone"){
                                phoneCustomer=(details[j].value!=null) ? details[j].value : '';
                            }
                            if(details[j].campo == "EMAIL" || details[j].campo == "Email" || details[j].campo == "email"){
                                emailCustomer=(details[j].value!=null) ? details[j].value : '';
                            }
                            if(details[j].campo.toLowerCase() == "message" || details[j].campo.toLowerCase() == "message " || 
                                details[j].campo.toLowerCase() == "message:" || details[j].campo.toLowerCase() == "message :" || 
                                details[j].campo.toLowerCase() == "message: " || details[j].campo.toLowerCase() == "message : " || 
                                details[j].campo.toLowerCase() == "comments" || details[j].campo.toLowerCase() == "comments " || 
                                details[j].campo.toLowerCase() == "comments:" || details[j].campo.toLowerCase() == "comments :" || 
                                details[j].campo.toLowerCase() == "comments: " || details[j].campo.toLowerCase() == "comments : "
                            )
                            {
                                msg=(details[j].value!=null) ? details[j].value : '';
                            }
                        }
                    }
                    let cta_id = e[i].cta_id;
                    if((cta_id==0 || cta_id==null) && e[i].form_id > 0 && e[i].type==1){
                        cta_id= 1001;
                    }
                    if((cta_id==0 || cta_id==null) && e[i].form_id > 0 && e[i].type==2){
                        cta_id= 1002;
                    }
                    p.push({
                        date_create: e[i].date_create,
                        hora: e[i].hora,
                        form_name: e[i].form_name,
                        customer_name: e[i].customer_name,
                        firstCustomer : firstCustomer,
                        lastCustomer : lastCustomer,
                        phoneCustomer : phoneCustomer,
                        emailCustomer : emailCustomer,
                        status: e[i].status,
                        sales_type: e[i].sales_type,
                        message: e[i].message,
                        msg: msg,
                        details: details,
                        customer_id: e[i].customer_id,
                        form_id: e[i].form_id,
                        user_id: e[i].user_id,
                        org_id: e[i].org_id,
                        id: e[i].id,
                        type: e[i].type,
                        created_lead: e[i].created_lead,
                        date_lead: e[i].date_lead,
                        hours_lead: e[i].hours_lead,
                        min_lead: e[i].min_lead,
                        seg_lead: e[i].seg_lead,
                        created_at: e[i].created_at,
                        updated_at: e[i].updated_at,
                        ip: e[i].ip,
                        source_id: e[i].source_id,
                        sourceName: sourceName,
                        cta_id: cta_id,
                        ctaName: ctaName,
                        quality_id: e[i].quality_id,
                        qualityName: qualityName,
                        locationIP: locationIP,
                    });
                }
                t.leadsListCompleta = p;
                //console.log('leadsListCompleta', t.leadsListCompleta);
                t.pagination = response.data.lista;
                let setting=response.data.setting;
                if(setting!=null && setting.length>0){
                    setting.filter(s =>{
                        if(s.meta_key=='leadsSpam'){
                            t.spam =(s.meta_value>0);
                        }
                        if(s.meta_key=='leadsTrash'){
                            t.trash =(s.meta_value>0);
                        }
                    });
                }
                t.filtrarStatus();
                t.rowCharts(response.data);
                t.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false;
            });
        },
        rowCharts(d){
            let t = this;
            t.chartData=[];
            let reportes=[];
            let sourceIds = t.listCRM.crm_mark_source.filter(source =>{
                source.leads=0;
                source.sales=0;
                source.sold=0;
                return source;
            });
            let sourceReports =[];
            t.chartOptions.title = 'Leads: Jan - Dec '+ t.year;
            // reportes.push(['Source', 'Leads', {type: 'string', role: 'tooltip','p': {'html': true}}, { role: 'style' }]);
            // reportes.push(['Source', 'Leads', 'Sent to sales', 'SOLD']);
            reportes.push(['Source', 'Leads', 'SOLD']);
            // sources uncategorized
            let leadsTotal = 0;
            let leadsTotalSales = 0;
            let leadsTotalSOLD = 0;
            let leads = 0;
            let leadsSales = 0;
            let leadsSOLD = 0;
            d.leadsAllYear.filter(all =>{
                leadsTotal++;
                if(all.source_id==null || all.source_id=='null' || all.source_id==0){
                    leads++;
                    // console.log('lead_id', all.id, 'leads', leads)
                    if(all.status==6){
                        leadsSales++;
                        leadsTotalSales++;
                        // console.log('leadsSales', leadsSales)
                        d.sales_leads.filter(sales =>{
                            if(sales.lead_id == all.id && sales.sales_status==6){
                                leadsSOLD++;
                                leadsTotalSOLD++;
                                // console.log('SOLD lead_', all.id, leadsSOLD)
                            }
                        });
                    }
                }
                else
                {
                    for (let i in sourceIds) {
                        if(sourceIds[i].id == all.source_id){
                            sourceIds[i].leads++;
                            // console.log('lead_id', all.id, 'sourceId', sourceIds[i].id, 'sourceIds.leads', sourceIds[i].leads)
                            if(all.status==6){
                                sourceIds[i].sales++;
                                leadsTotalSales++;
                                d.sales_leads.filter(sales =>{
                                    if(sales.lead_id == all.id && sales.sales_status==6){
                                        sourceIds[i].sold++;
                                        leadsTotalSOLD++;
                                        // console.log('SOLD lead_id', all.id, 'source.id', sourceIds[i].id, 'source.sold', sourceIds[i].sold)
                                    }
                                });
                            }
                        }
                    }
                }

            })
            // console.log('Uncategorized', d.reportAnualDefaults, d.anualDefaultSold.count);
            let colors= ["#0065df", "#5ac146", "#fa5838", "#ccc", "#ffbc34", "#b54934", "#e6e6e6"];
            let cont = 0;
            reportes.push(['Total', leadsTotal, leadsTotalSOLD]);
            // reportes.push(['Total', leadsTotal, leadsTotalSales, leadsTotalSOLD]);
            // reportes.push(['Total', leadsTotal,t.tootlipChart(leadsTotal, leadsTotalSales, leadsTotalSOLD), colors[cont]]);
            reportes.push(['Uncategorized', leads, leadsSOLD]);
            // reportes.push(['Uncategorized', leads, leadsSales, leadsSOLD]);
            //reportes.push(['Uncategorized', leads,t.tootlipChart(leads, leadsSales, leadsSOLD), "#e51500"]);
            cont++;
            sourceIds.filter(source =>{
                if(source.leads>0){
                    // console.log('sourceIds', sourceIds);
                    reportes.push([source.name, source.leads, source.sold]);
                    // reportes.push([source.name, source.leads, source.sales, source.sold]);
                    //reportes.push([source.name, source.leads, source.sold, t.tootlipChart(source.leads, source.sales, source.sold),colors[cont]]);
                    cont++;
                    if(cont > colors.length){
                        cont=0;
                    }    
                }else{
                    // console.log('source sin lead ', source.id)
                }
            });
            t.chartData = reportes.sort((a, b) => b[1] - a[1]);
            //console.log('chartData', t.chartData);
        },
        tootlipChart(leads, sales, sold){
             return '<div style="padding:5px 5px 5px 5px;">' +
             '<ul style="list-style: none;padding-left: 0;width:80px;">'+
             '<li>Leads: '+leads+'</li>'+
             '<li>In sales: '+sales+'</li>'+
             '<li>SOLD: '+sold+'</li>'+
             '</ul>'+
             '</div>';
        },
        filtrarStatus(){
            this.leadsList = [];
            let l = this.leadsListCompleta;
            for (let i in l) {
                if(l[i]){
                    let add = false;
                    if(l[i].status == 4 && !this.spam){ add = false;}
                    else if(l[i].status == 5 && !this.trash){ add = false;}
                    //else if(l[i].status == 6 && !this.conversion){ add = false;}
                    else { add = true;}
                    if(add){
                        this.leadsList.push(l[i]);
                    }
                }
            }
            //console.log('leadsList', this.leadsList);
        },
        changePage(page) {
            this.page = page;
            this.pagination.current_page = page;
            this.query = "?page=" + page;
            this.getLeads();
        },
        reescribirList(item){
            let t = this;
            let newList = [];
            let oldList = [];
            for (let i in t.leadsList) {if(t.leadsList[i]){oldList.push(t.leadsList[i]);}}
            for (let e in oldList) {
                newList.push(oldList[e]);
                if(oldList[e].id == item.itemID){
                    console.log('cambiando status de '+ oldList[e].status+ ' a '+ item.statusNew);
                    newList[e].status = item.statusNew;
                }
            }
            t.leadsList = newList;
        },
        actList(){
            window.master.post(this.apis.gets(this.query), {
                org_id: window.localorgdata,
                user_id: window.master_client,
            })
            .then((response) => {
                console.log('data leads', response.data);
                this.selectFilterList = response.data.nameFilter;
                let e = response.data.lista.data;
                let p = [];
                for (const i in e) {
                    let locationIP='';
                    if(Object.keys(e[i].ip_info).length >0) {
                        locationIP = e[i].ip_info.country +', '; 
                        locationIP += e[i].ip_info.region +', '; 
                        locationIP += e[i].ip_info.city +', '; 
                        locationIP += e[i].ip_info.postal;
                    }
                    p.push({
                        date_create: e[i].date_create,
                        hora: e[i].hora,
                        form_name: e[i].form_name,
                        customer_name: e[i].customer_name,
                        status: e[i].status,
                        message: e[i].message,
                        customer_id: e[i].customer_id,
                        form_id: e[i].form_id,
                        user_id: e[i].user_id,
                        org_id: e[i].org_id,
                        id: e[i].id,
                        type: e[i].type,
                        orgcreated_at_id: e[i].created_at,
                        updated_at: e[i].updated_at,
                        ip: e[i].ip,
                        locationIP: locationIP,
                    });
                }
                this.leadsList = p;
                //this.result = response.data.result;
                this.pagination = response.data.lista;
                this.spam =response.data.leadsSpam;
                this.trash =response.data.leadsTrash;
                this.isLoading = false;
            })
            .catch((error) => {console.log(error)});
        },
    },
    watch: {
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "leadsStatus" && t.getPusher.org_id == window.localorgdata){
                    console.log('t.getPusher', t.getPusher);
                    // t.reescribirList(t.getPusher);
                    t.getLeads();
                }
                else if(t.getPusher.action == "actListLeads" && t.getPusher.org_id == window.localorgdata){
                    // t.actList();
                    t.getLeads();
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
        pagesNumber() {
            if (!this.pagination.to) {
                return [];
            }
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            let to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }
            return pagesArray;
        },
    },
};
</script>
<style lang="scss" scoped>
.action_order_btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.status_list {
    padding: 8px 7px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    width: 120px;
    font-size: 14px;
    z-index: 9;
    ul {
        li {
            display: block;
            position: relative;
            margin: 1px 0;
            padding: 5px;
            text-align: center;
            span {
                cursor: pointer;
            }
            &:hover {
            }
        }
    }
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.search {
    border-color: #555;
}
.badge-danger.spam {
    background-color: #b54934;
}
.badge-default.trash {
    background-color: #e6e6e6;
    color: #555;
}
tr.viewTrash> td {
    text-decoration: line-through;
    color: #ccc;
}
tr.viewSpam > td {
    text-decoration: line-through;
    color: #b54934;
}
tr.viewTrash,
tr.viewConversion,
tr.viewSpam{ display: table-row;}
tr.noneTrash,
tr.noneSpam,
tr.noneConversion{ display: none;}
/*bnt swicht*/
.btn-switch {
    display: block;
    margin-top: 3%;
    margin-right: 30%;
    margin-left: 30%;
}
.onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #585858;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 30px;
    padding-left: 10px;
    background-color: #5ac146;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Off";
    padding-right: 10px;
    background-color: #d65353;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #fff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 33px;
    border: 1px solid #585858;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
/*Final de swicht*/
.search{
    border: 1px solid rgb(0 0 0 / 25%);
}
.btnShowStatus {
    border: 1px solid rgb(0 0 0 / 25%);
    border-right: 0px;
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.btnShowStatus span.status_list {
    left: -84px;
    border: 1px solid #ddd;
    top: 42px;
}

.btnShowStatus span.status_list ul.reset {
    margin-bottom: 0px;
    text-align: center;
}
.action_order_btn .status_list {
    border: 1px solid #ddd;
}
@media(max-width: 768px){
    .page-wrapper > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
    }

    .page-breadcrumb {
    padding-left: 10px;
    }

    .card-body {
    padding: 1.25rem 0.50rem;
    overflow-x: auto;
    max-width: 600px;
    }
}
@media(max-width: 415px){
    .card-body {max-width: 389px;}
}
.form-inline{
    margin-left: 20px;
}
.btn.btnadd {
    margin-left: 20px;
    background-color: #031099;
    border-color: #031099;
    color: #fff;
    border-radius: 24px;
    padding: 10px 20px;
    font-weight: bold;
}
span.nameStatus .badge {
    font-size: 14px;
}
span.nameCRM .badge {
    border: 0px solid #b9b9b9;
    color: #137eff;
    font-size: 14px;
}
span.status_list.ulCRM {
    background-color: #ededed;
    font-size: 12px;
    width: 150px;
}

span.status_list.ulCRM ul li:hover {
    cursor: pointer;
    font-weight: bold;
}
.table th, .table td {
    padding: 0.4rem;
    vertical-align: middle;
    border: 1px solid #dee2e6 !important;
}
.table tbody tr:nth-of-type(odd) { 
    background-color: #f2f2f2;
}
.table tbody tr:nth-of-type(even) { 
    background-color: #fff; 
}
.table tbody tr:hover, .table tbody tr:hover{
    background-color: #f6fafe;
}
span.badge.select0 {
    color: #737e8c !important;
}
.icoMark{
    height: 20px;
}
td.customer, td.message {
    max-width: 300px;
}
.filtros{
    display:flex;
    select.form-control{height: 43px; margin-right:10px;}
}
.cdropdown > button.salesSend {
    color: #fff;
    background-color: #5ac146;
    border-color: #5ac146;
    padding: 5px 10px 4px 10px;
    text-shadow: 1px 1px rgb(0 0 0 / 40%);
    font-size: 18px;
}

.cdropdown > button.salesSend:before {
    content: "";
    border: 0px solid !important;
}
.cdropdown.salesSendStatus ul li {font-size: 16px;}
</style>
