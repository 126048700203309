let autoHeigth = function(elem){
    let calcHeight = elem;
    if(calcHeight){
        let numberOfLineBreaks = (calcHeight.value.match(/\n/g) || [1]).length;
        let logitud = Math.round(calcHeight.clientWidth) / 14 + 10; //elemWidth + font-size + line-height
        let caracteres = (calcHeight.value.length>logitud) ? (calcHeight.value.length/logitud) : 1;
        // numberOfLineBreaks = (numberOfLineBreaks==1) ? caracteres : numberOfLineBreaks;
        // console.log('item', calcHeight, 'numberOfLineBreaks', caracteres)
        // min-height + lines x line-height + padding + border
        let newHeight = (caracteres>30) ? Math.round(16 + caracteres * 16 + 16 + 14) : Math.round(14 + caracteres * 16 + 4 + 4);
        calcHeight.style.height = newHeight + "px";
        // if(numberOfLineBreaks>=3){
        //     calcHeight.style.height = calcHeight.scrollHeight + "px";
        // }
    }
};
export default autoHeigth;
