let SquarePay = function(id, numdoc, total, customer_id, cname, clname, cbname, balance) {
	this.closeSubmenu(id);
	let t = this;
	t.m = {};
	if(t.PlanPremium){
		t.m = {
			show: true,
			bill: true,
			action: 'SquarePay',
			component: 'formSquarepay',
			data: {
				user_id: window.master_client,
				org_id: window.localorgdata,
				invoice_id: id,
				numdoc: numdoc,
				total: total,
				customer_id: customer_id,
				cname: cname,
				clname: clname,
				cbname: cbname,
				balance: balance,
				balanceText: balance,
			}
		};
		t.$store.commit('core/setOpenModal', t.m);
	}else{
		t.llamarPopPremium();
	}
};
export default SquarePay;
