let activeEdit = function(e) {
    let elem = e.target
    let her = elem.previousElementSibling;
    her.classList.remove('hide')
    elem.classList.add('hide')
    setTimeout(function () {
        her.focus();
    }, 200);
};
export default activeEdit;
