<template>
	<div class="card">
		<div class="card-header">
			<h3 class="card-title" v-if="!updateLead">Add Lead</h3>
			<h3 class="card-title" v-else>Update Lead</h3>
		</div>
		<div class="card-body">
			<form @submit.prevent="addNewLead()" class="form-lead">
			  <div class="row form-group">
			    <div class="col">
			      <input type="text" v-model="f.name" class="form-control" placeholder="First name *" required>
			    </div>
			    <div class="col">
			      <input type="text" v-model="f.last" class="form-control" placeholder="Last name *" required>
			    </div>
			  </div>
			  <div class="row form-group">
			    <div class="col">
			      <input type="text" v-model="f.phone" class="form-control" placeholder="Phone *" required>
			    </div>
			    <div class="col">
			      <input type="text" v-model="f.email" class="form-control" placeholder="Email" >
			    </div>
			  </div>
			  <div class="row form-group">
			    <div class="col-8">
			      <input type="text" v-model="f.address" class="form-control" placeholder="Address">
			    </div>
			    <div class="col-4">
			      <input type="text" v-model="f.state" class="form-control" placeholder="State">
			    </div>
			  </div>
			  <div class="row form-group">
			    <div class="col">
			      <input type="text" v-model="f.city" class="form-control" placeholder="City">
			    </div>
			    <div class="col">
			      <input type="text" v-model="f.zip" class="form-control" placeholder="Zip code">
			    </div>
			    <div class="col">
					<date-picker
						v-model="f.date_lead"
						format="YYYY-MM-DD HH:mm a"
						placeholder="YYYY-MM-DD HH:mm"
						type="datetime"
						:input-attr="{'data-value': f.date_lead}"
						:show-time-panel="showTimePanel"
						:minute-step="5"
						input-class="date-input-lead"
						@open="dateOpen()"
						@close="closeDatePicker()"
						@input="changeDate()"
						:clearable="false"
						:show-second="false"
						:open.sync="openCal"
						:use12h="true"
						:use-utc="true"
					>
						<template v-slot:header="{ emit }" class="header-date-picker">
							<button class="mx-btn mx-btn-text btn-today" @click="emit(new Date())">Today</button>
						</template>
						<template v-slot:footer>
							<button type="button" class="mx-btn mx-btn-text" @click="toggleTimePanel" v-if="!showTimePanel">
								Hours
							</button>
							<button type="button" class="mx-btn mx-btn-text" @click="toggleTimePanel" v-else>
								Date
							</button>
							<button type="button" class="mx-btn mx-btn-text" @click="closeDatePicker()" >
								Close
							</button>
						</template>
					</date-picker>
			    </div>
			  </div>
			  <div class="row form-group">
			    <div class="col">
			      <textarea v-model="f.message" class="form-control" placeholder="Message" style="height: 100px;"></textarea>
			    </div>
			  </div>
			  <div class="row form-group">
			    <div class="col">
			      <button type="submit" class="btn btn-success btn-lg" style="margin-right: 20px;">Save</button>
			      <button type="button" class="btn btn-secondary btn-lg" @click="cancel()">Cancel</button>
			    </div>
			  </div>
			</form>
		</div>
	</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '@/apps/Marketea/components/leads/calendar.scss';
export default {
	name: 'NewLead',
    components: {
        DatePicker,
	},
	data () {
		return {
			isLoading: false,
			f: {
				user_id: window.master_client,
				org_id: window.localorgdata,
				name: '',
				last: '',
				phone: '',
				email: '',
				address: '',
				state: '',
				city: '',
				zip: '',
				message: '',
				date: new Date(),
				date_lead: new Date(),
			},
			updateLead: false,
			showTimePanel: false,
			openCal: false,
			dateSelected: new Date(),
		}
	},
	created(){
		let t = this;
		let d = t.$store.getters['core/getOpenModal'].data;
		if(d.length>0){
			t.updateLead =true;
			t.LoaderLead(d[0]);
		}
		console.log('updateLead', t.updateLead);
	},
	methods: {
		LoaderLead(i){
			let t = this;
			console.log('LoaderLead', i.date_lead);
			t.f.user_id= window.master_client;
			t.f.org_id= window.localorgdata;
			t.f.id= i.id;
			t.f.date_lead= new Date(new Date(i.created_at).setHours(i.hours_lead, i.min_lead, i.seg_lead, 0));
			t.dateSelected= t.f.date;
            let inputs = JSON.parse(i.message);
			t.f.name=inputs[0].value;
			t.f.last=inputs[1].value;
			t.f.phone=inputs[2].value;
			t.f.email=inputs[3].value;
			t.f.state=inputs[4].value;
			t.f.city=inputs[5].value;
			t.f.zip=inputs[6].value;
			t.f.address=inputs[7].value;
			t.f.message=inputs[8].value;
			console.log('LoaderLead', t.f);
		},
		addNewLead(){
			let t= this;
			let url= "leads/add";
			let d = new Date(t.f.date_lead).toISOString().slice(0,10);
			d+='T'+new Date(t.f.date_lead).getHours()+':'+new Date(t.f.date_lead).getMinutes()+':';
			d+=new Date(t.f.date_lead).getSeconds()>9 ? new Date(t.f.date_lead).getSeconds() : '0'+new Date(t.f.date_lead).getSeconds();
			t.f.date=d;
			if(t.updateLead){url= "leads/update";}
			t.$store.commit("core/setLoadingModal", true);
			window.master.post(url, t.f)
			.then((response) => {
				console.log('r', response.data);
				t.$store.commit("core/setLoadingModal", false);
				t.$parent.$parent.$parent.getLeads();
				t.cancel();
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
				t.isLoading = false;
			});
		},
		cancel(){
			this.f= {
				user_id: window.master_client,
				org_id: window.localorgdata,
				name: '',
				last: '',
				phone: '',
				email: '',
				address: '',
				state: '',
				city: '',
				zip: '',
				message: '',
				date: new Date().toISOString().slice(0, 10),
			};
			this.$store.commit("core/setCloseModal", true);
			this.$parent.close();
		},
        dateOpen(){
			this.openCal=true;
            this.dateSelected=this.f.date;
        },
        toggleTimePanel() {
            this.showTimePanel = !this.showTimePanel;
        },
        changeDate(date) {
            console.log('changeDate', date)
            this.showTimePanel = true;
        },
        handleOpenChange() {
            this.showTimePanel = false;
        },
        closeDatePicker() {
            this.showTimePanel = false;
			this.openCal=false;
        },
	}
}
</script>
<style lang="css" scoped>
</style>
