let selectedTr = function() {
	let list = document.querySelectorAll('.table tbody tr');	
	for (let j in list) {
		if (list.hasOwnProperty(j)) {
			let tr = list[j];
			let dataID = parseInt(tr.getAttribute('data-id'));
			if(document.querySelector('#checkbox-' + dataID))
			{
				if(!tr.classList.contains('active') && this.selected.indexOf(dataID) > -1) {
					tr.classList.add('active');
				} else if(tr.classList.contains('active') && this.selected.indexOf(dataID) == -1) {
					tr.classList.remove('active');
				}
			}
		}
	}
	if(this.selected.length != this.InvoicesList.length) {
		this.selectAll = false;
	} else { this.selectAll = true;}
};
export default selectedTr;
