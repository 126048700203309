<template>
	<div class="row" >
		<div class="col-12">
			<div class="btn-group btn-group-toggle tabs" data-toggle="buttons">
				<label
					v-for="(i, index) in $parent.tabsList"
					:key="index"
					v-bind:class="['btn btn-lg', (i.value[0]==$parent.tabs) ? 'bgActive'+i.value[0] : '', insurance ? ((i.value[0]==$parent.tabs && i.value[0]=='7') ? 'accRej' : '') : '']"
					:for="'tabs'+index"
				>
					<input type="radio" :id="'tabs'+index" v-model="$parent.tabs" :value="i.value[0]" :checked="(i.value[0]==$parent.tabs)" 
					@click="$parent.navTabs(i.value[0], index)">
					<span v-if="insurance && i.value[0]=='7'">
						<span class="accRej_label">
							<span class="acc">
								Accepted
								<span v-bind:class="['badge', (i.value[0]==$parent.tabs) ? 'badge-light' : 'badge-check']">
									{{i.count[1]}}
								</span>
							</span>
							<span v-if="(i.value[0]!=$parent.tabs)">/</span>
							<span class="rej">
								Rejected
								<span v-bind:class="['badge', (i.value[0]==$parent.tabs) ? 'badge-light' : 'badge-check']">
									{{i.count[0]}}
								</span>
							</span>
						</span>
					</span>
					<span v-else>
						<span class="name_label">{{i.name}}</span>
						<span v-bind:class="['badge', (i.value[0]==$parent.tabs) ? 'badge-light' : 'badge-check']">
							{{i.count}}
						</span>
					</span>
				</label>
			</div>
		</div>
	</div>
</template>
<script>
export default {
name: 'filtrosContadores',
	props: {
		stype: {
			default: 'retail'
		}
	},
	data () {
		return {
			insurance: this.stype =='insurance' ? true : false,
		}
	}
}
</script>
<style lang="scss" scoped>	
	$colorDark: #555;
	$colorWhite: #fff;
	$colorBorBase: #f2f4f5;
	$colores: (
		'1': #6c757d,
		'2': #fc8505,
		'3': #031099,
		'4': #0065df,
		'5': #ffc107,
		'6': #7460ee,
		'7': #fa5838,
		'8': #6c757d,
		'9': #031099,
		'10': #0065df,
	);
	@function getColor($m, $v) {
	    @return map-get($m, $v);
	}
	.btn-group.btn-group-toggle.tabs {
		background-color: $colorBorBase;
		label.btn.btn-lg {
		    padding: 10px 20px;
		    max-width: 220px;
		    height: 64px;
		    white-space: break-spaces;
		    font-size: 14px;
		    margin-right: 10px;
		    background-color: $colorWhite;
		    color: #555;
		    justify-content: center;
		    align-content: center;
		    align-items: center;
		    display: flex;
		    border-radius: 0px;
			-webkit-transition: width 1s ease;
			-moz-transition: width 1s ease;
			-ms-transition: width 1s ease;
			-o-transition: width 1s ease;
			transition: width 1s ease;
			&:before {
	    		content: "";
	    		position: absolute;
			    top: -1px;
			    left: -5px;
			    width: 0; 
			     height: 0; 
			     border-left: 20px solid $colorBorBase;
			     border-top: 32px solid $colorWhite;
			     border-bottom: 32px solid $colorWhite;
			}
			&:after {
	    		content: "";
	    		position: absolute;
			    top: -1px;
			    right: -2px;
			    width: 0;
			    height: 0;
			    border-left: 20px solid $colorWhite;
			    border-top: 32px solid $colorBorBase;
			    border-bottom: 32px solid $colorBorBase;
			}
			.name_label{margin-right: 5px;}
		}
		@for $i from 1 through 10{
			label.btn.btn-lg.bgActive#{$i}{
    			background-color: getColor($colores, #{$i}) !important;
    			border-color: getColor($colores, #{$i}) !important;
				color: if($i==5,$colorDark, $colorWhite) !important;
			}
			label.btn.btn-lg.bgActive#{$i}:before{
				border-top: 32px solid getColor($colores, #{$i});
				border-bottom: 32px solid getColor($colores, #{$i})
			}
			label.btn.btn-lg.bgActive#{$i}:after{
				border-left: 20px solid getColor($colores, #{$i});
			}
			label.btn.btn-lg.bgActive#{$i}.accRej{
    			background: linear-gradient(112deg, #04b653 50%, #fa5838 50%)!important;
    			border-color: transparent !important;
				color: if($i==5,$colorDark, $colorWhite) !important;
				.accRej_label{
					margin-right: 5px;
					.acc{padding-right:10px;}
					.rej{padding-left: 5px;}
				}
			}
			label.btn.btn-lg.bgActive#{$i}.accRej:before{
				border-top: 32px solid #04b653;
				border-bottom: 32px solid #04b653;
			}
		}
	}
</style>
