<template>
	<div class="card">
		<div class="card-header">
			<h3 class="page-title">
				Send Text Message - {{typeDocument[getData.typeDocumentID]}} 
				<span class="badge badge-info">#{{getData.numdoc}}</span>
			</h3>
		</div>
		<div class="card-body">
			<div class="col-md-12" v-if="viewCustomerData">
				<p>
					<b>Customer Name: </b>
					{{customer.firstname}} {{customer.lastname}}<br>
					<!--<b>Business Customer: </b> {{customer.bussinesname}} -->
				</p>
			</div>
        	<div v-if="alertEmpty" class="alert alert-warning" style="padding: 5px; margin-bottom: 20px;">
            	<span>Cell phone field is empty, you must add a phone contact information before continuing.</span>
        	</div>
        	<form class="row" v-if="viewForm" @submit.prevent="setCellPhone()">
        		<div class="col-12">
        			<div class="alert alert-danger " v-if="errorForm">
        			  <strong>Error</strong> could not save, try again, if the error persists try again later.
        			  <button type="button" class="close" @click="errorForm=false">
        			    <span aria-hidden="true">&times;</span>
        			  </button>
        			</div>
        		</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="">Customer Cell Phone:</label>
						<input
							type="text"
							class="form-control"
							v-model="mobilephone"
							required="true"
						/>
					</div>
				</div>
				<div class="col-md-12 d-flex justify-content-start">
                    <button
                        type="submit"
                        class="btn btn-success d-flex"
                        :disabled="isDisabled2"
                        style="margin-right: 10px;"
                    >
                        SAVE
                    </button>
                    <button
                        type="button"
                        class="btn btn-light d-flex"
                        @click="closeForm()"
                    >
                        CANCEL
                    </button>
                </div>
        	</form>
        	<div v-if="isLoading" >
        		<form @submit.prevent="sendSMS()" >
        			<div class="custom-control custom-radio">
        				<input 
        			  	type="radio" 
        			  	v-model="cellPhonecheck" 
        			  	:value="1"
        			  	id="radio1" 
        			  	class="custom-control-input" 
        			  	/>
        			  	<label class="custom-control-label" for="radio1">
        			  		Will be sent to the Client: <b> {{ customer.mobilephone }}</b>
        			  	</label>
        			  	<small style="padding-left: 10px;">
        			  		(
        			  		<a href="#" @click.prevent="editPhone()" title="Edit" v-if="customer.mobilephone.length>=10">Edit</a>
        			  		<a href="#" @click.prevent="editPhone()" title="Add" v-else>Add</a>
        			  		)
        			  	</small>
        			</div>
        			<div class="form-inline" style="padding-top: 15px; padding-bottom: 15px">
        				<div class="form-group mb-2 col-sm-5" style="padding-left: 0px;">
        					<div class="custom-control custom-radio">
		        				<input 
		        			  	type="radio" 
		        			  	v-model="cellPhonecheck" 
		        			  	:value="2"
		        			  	id="radio2" 
		        			  	class="custom-control-input" 
		        			  	/>
		        			  	<label class="custom-control-label" for="radio2">
		        			  		Other: (Add a Cell Phone)
		        			  	</label>
		        			</div>
        				</div>
        				<div class="form-group col-sm-6 mb-2" style="padding-left: 0px;">
        					<label for="otherCellPhone" class="sr-only">Cell:</label>
        					<input
        					type="text"
        					class="form-control"
        					style="display: block;width: 100%;"
        					placeholder="Other cell phone"
        					v-model="otherCellPhone"
        					:disabled="(cellPhonecheck!=2)"
        					/>
        				</div>
        			</div>
        			<div class="col-md-12 d-flex justify-content-start">
        				<button
        				type="submit"
        				class="btn btn-success d-flex"
        				:disabled="isDisabled"
        				>
        					<i class="fas fa-sms fa-lg"
        					style="margin-right: 5px; margin-top: 3px"
        					></i>
        					SEND
        				</button>
        			</div>
        		</form>
        	</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'sendSMSCustomer',
	data () {
		return {
			typeDocument: ["Estimate", "Invoice", "Payment"],
			customer: {},
			customer_id: 0,
			getData: {
				id: 0, 
				numdoc: "0000",
				customer_id: 0,
				typeDocumentID: null, 
            },
            mobilephone: '',
            alertEmpty: false,
            viewForm: false,
            errorForm: false,
            isLoading: false,
            cellPhonecheck: 0,
            otherCellPhone: '',
            isDisabled: true,
            isDisabled2: true,
            viewCustomerData: false,
            idSale: 0,
		}
	},
	created(){
		this.init();
	},
	methods: {
		init(){
			let t = this;
			let getModal = t.$store.getters['core/getOpenModal'];
            t.idSale=getModal.data.idSale;
            t.getEstimateSimple(getModal.data);
		},
		getEstimateSimple(d){
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
			window.billingSales.post('estimateSimple', {
				org_id: window.localorgdata,
				user_id: window.master_client,
				id: d.estimate,
			})
			.then((response) => {
				console.log('estimateSimple', response.data);
				if(response.data.status){
                    t.getData = response.data.estimate;
			        t.getData.typeDocumentID = d.typeDocumentID;
                    t.customer = response.data.customer;
                    t.viewCustomerData = true;
                    if(t.customer.mobilephone==null || 
                        t.customer.mobilephone=='null'
                    ){
                        t.customer.mobilephone='';
                        t.alertEmpty=true;
                        t.viewForm=true;
                    }else{
                        t.isLoading = true;
                        t.alertEmpty=false;
                    }
				}
                t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		setCellPhone(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.billingSales.post("editCustomerCellPhone", {
				customer_id: t.getData.customer_id,
				mobilephone: t.mobilephone
			})
			.then(response => {
				console.log('response', response.data);
				if(response.data.status){
					t.customer.mobilephone = t.mobilephone;
					t.closeForm();
				}
				t.$store.commit("core/setLoadingModal", false);
			})
			.catch(error => {
				console.log('error', error);
				t.viewForm=true;
				t.errorForm=true;
				t.alertEmpty = true;
				t.$store.commit("core/setLoadingModal", false);
			})
		},
		editPhone(){
			let t = this;
			t.isLoading = false;
			t.errorForm=false;
			t.viewForm=true;
			t.mobilephone = t.customer.mobilephone;
		},
		closeForm(){
			let t = this;
			t.isLoading = true;
			t.errorForm=false;
			t.viewForm=false;
			t.mobilephone = '';
			if(t.customer.mobilephone.length<=10){
				t.alertEmpty=true;
			}else{
				t.alertEmpty=false;
			}
		},
		sendSMS(){
			let t = this;
			console.log('sendSMS')
			t.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                title: "Sending sms",
                text: "Loading...",
                show: true,
                duration: 500
            });
			let obj = {
                id: t.getData.id,
                idNumdoc: t.getData.numdoc,
                icCustomer: t.getData.customer_id,
                customerName: t.customer.firstname+' '+t.customer.lastname,
                need_contract: t.getData.need_contract,
                signe: t.getData.signe
            };
            let type = (t.typeDocument[t.getData.typeDocumentID]).toLowerCase();
            let cellPhone = t.customer.mobilephone;
            if(t.cellPhonecheck==2 && t.otherCellPhone.length>=10){
                cellPhone = t.otherCellPhone
            }
            let sendData = {
                user_id: window.localuserdata,
                org_id: window.localorgdata,
                oauth_m: window.localStorage.getItem("oauth_m"),
                AccountID: window.localAccountID,
                app_name: window.localStorage.getItem('nameApp'),
                app_id: window.localStorage.getItem('appIDactual'),
                id: t.getData.id,
                idcustomer: t.getData.customer_id,
                type: type,
                parameters:JSON.stringify(obj),
                para: cellPhone
            };
            t.$store.commit("core/setLoadingModal", true);
            window.billingSales
            .post("getShellpdf", sendData)
            .then((response) => {
                let r = response.data.status; 
                if(r == false){
                    t.$notify({
                        group: "noti",
                        title: "Error",
                        type: "error",
                        text: "You must add mobile phone",
                    });
                	t.$store.commit("core/setLoadingModal", false);
                } else {
                    t.$notify({
                        group: "noti",
                        title: "Send PDF by SMS",
                        type: "success",
                        text: "Send PDF by SMS Successful",
                    });
                	t.$store.commit("core/setLoadingModal", false);
                    t.$store.commit("core/setCloseModal", {action: 'sendSMSEst', 'idSale': t.idSale});
                }
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
	},
	watch: {
		cellPhonecheck: function(){			
			let t = this;
			if(t.cellPhonecheck==1 && t.customer.mobilephone.length>=10){t.isDisabled=false;}
			else{
				console.log('btn send false')
				console.log('cellPhonecheck', t.cellPhonecheck);
				t.isDisabled=true;
			}
		},
		otherCellPhone: function(){			
			let t = this;
			if(t.cellPhonecheck==2 && t.otherCellPhone.length>=10){t.isDisabled=false;}
			else{
				console.log('btn send false')
				console.log('otherCellPhone', t.otherCellPhone);
				t.isDisabled=true;
			}
		},
		viewForm: function(){	
			let t = this;
			if(t.viewForm && t.mobilephone.length>=10){t.isDisabled2=false;}
			else{
				console.log('btn salve false')
				console.log('mobilephone', t.mobilephone);
				t.isDisabled2=true;
			}
		},
		mobilephone: function(){	
			let t = this;
			if(t.viewForm && t.mobilephone.length>=10){t.isDisabled2=false;}
			else{
				console.log('btn salve false')
				console.log('mobilephone', t.mobilephone);
				t.isDisabled2=true;
			}
		},
		'customer.mobilephone': function(){
			let t = this;
			if(t.customer.mobilephone.length>=10){
				t.cellPhonecheck=1;
			}
		},
	}
}
</script>
<style lang="css" scoped>
</style>
