<template>
	<div>
		<div class="card">
			<div class="card-header">
				{{ modal.title }}
			</div>
			<div
				class="card-body"
				style="padding-bottom: 100px; height: 400px; overflow-y: auto"
			>
				<div class="alert alert-danger" v-if="modalError">
					{{ modalErrorMjs }}
				</div>
				<div class="row">
					<div class="col-6">
						<input
							type="text"
							class="form-control"
							style="border: 1px solid #6f7f90; margin-bottom: 10px"
							v-model="searchExpList"
						/>
					</div>
					<div class="col-6 text-right">
						<button
							type="button"
							class="btn btn-success btn-xs"
							@click="SelectTransacID"
						>
							<i class="fas fa-check"></i> SELECT EXPENSE
						</button>
						<button
							type="button"
							class="btn btn-secundary btn-xs"
							style="margin-left: 10px"
							@click="resetTransacID"
						>
							<i class="fas fa-times"></i> CANCEL
						</button>
						<button
							type="button"
							class="btn btn-danger btn-xs"
							style="margin-left: 10px"
							@click="unlinkTransacID"
							v-if="unlink"
						>
							<i class="fas fa-unlink"></i> UNLINK
						</button>
					</div>
				</div>
				<div class="table-Transaction">
					<table class="table table-striped table-bordered table-hover">
						<thead>
							<tr>
								<th style="text-align: center"></th>
								<th>Exp #</th>
								<th style="width: 100px;">Date</th>
								<th>Vendor - Subcon.</th>
								<th>Bank</th>
								<th><i class="fas fa-link fa-lg"></i></th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="listExpensesLoading">
								<td colspan="7" style="text-align: center">
									<Loader></Loader>
								</td>
							</tr>							
							<tr
								v-for="(i, index) in filterExpList" 
								:key="index"
								v-else-if="filterExpList.length > 0"
							>
								<td style="text-align: center">
									<input
										type="radio"
										v-model="transac_id"
										:value="i.id"
									/>
								</td>
								<td>{{ i.numdoc }}</td>
								<td>{{ i.fecha }}</td>
								<td>{{ i.vendor }} {{ i.subcon }}</td>
								<td>{{ i.formapago }}</td>
								<td>
									<span v-if="i.linkTransac > 0"
										><i class="fas fa-university fa-lg"></i
									></span>
									<span v-else-if="i.linkCheck > 0"
										><i class="fas fa-money-check-alt fa-lg"></i
									></span>
									<span v-else-if="i.PmtType == 3"
										><i class="fas fa-money-bill-wave fa-lg"></i
									></span>
								</td>
								<td>${{ i.amount | formatMoney }}</td>
							</tr>
							<tr v-else>
								<td colspan="7" style="text-align: center">
									No record
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="row">
					<div class="col-12 text-right" style="padding-top: 20px">
						<button
							type="button"
							class="btn btn-success btn-xs"
							@click="SelectTransacID"
						>
							<i class="fas fa-check"></i> SELECT EXPENSE
						</button>
						<button
							type="button"
							class="btn btn-secundary btn-xs"
							style="margin-left: 10px"
							@click="resetTransacID"
						>
							<i class="fas fa-times"></i> CANCEL
						</button>
						<button
							type="button"
							class="btn btn-danger btn-xs"
							style="margin-left: 10px"
							@click="unlinkTransacID"
							v-if="unlink"
						>
							<i class="fas fa-unlink"></i> UNLINK
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
	components: {
		Loader
	},
	name: "assignCheckExp",
	data () {
		return {
            modal: {
                title: "",
            },
           	Exp_id: 0,
            checkITEM: 0,
			searchExpList: "",
            transac_id: 0,
            listExpensesLoading: false,
            modalError: false,
            modalErrorMjs: "",
			unlink: false,							
		}
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
			console.log(r);
            this.modal = r.modal
			this.searchExpList = r.searchExpList			
            this.modalError = r.modalError
            this.modalErrorMjs = r.modalErrorMjs
			this.transac_id = r.transac_id
			this.Exp_id = r.Exp_id			
			this.unlink = r.unlink
            this.checkITEM = r.checkITEM             
			this.listExpensesLoading = r.listExpensesLoading
			this.listExp()			     
        },
		listExp() {
			this.listExpensesLoading = true;
			this.listExpenses = [];
			window.billing
			.get("expenses/" + window.localuserdata)
			.then((response) => {
				let exp = response.data.result;
				for (let i in exp) {
					// agregar de primero registro seleccionado
					if (exp[i].id === this.Exp_id) {
						this.transac_id = exp[i].id;
						this.unlink = true;
						this.listExpenses.push(exp[i]);
					}
				}
				for (let j in exp) {
					// agregar los demas registros
					if (exp[j].id !== this.Exp_id) {
						this.listExpenses.push(exp[j]);
					}
				}
				this.listExpensesLoading = false;
			})
			.catch((error) => {
				this.listExpensesLoading = false;
				console.log(error);
			});
		},		
        SelectTransacID() {
            if (this.transac_id === 0 || this.checkITEM === 0) {
                this.modalError = true;
                this.modalErrorMjs = "Error, you must select an expense.";
            } else {
                console.log("item = " + this.checkITEM);
                console.log("transac_id = " + this.transac_id);
                this.listExpensesLoading = true;
                window.billing
                    .post("vinculartransac", {
                        id: this.checkITEM,
                        expense_id: this.transac_id,
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status) {
                            this.$notify({
                                group: "noti",
                                title: "Check",
                                type: "success",
                                text: "Complete Linkage.",
                            });
                        }
                        this.listExpensesLoading = false;
                        this.$store.commit("core/setCloseModal", {refresh:1});
                        this.$store.commit("core/setActionRefresh", {refresh:1});
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.listExpensesLoading = false;
                        console.log(error);
                    });
            }
        },
        resetTransacID() {
            this.transac_id = 0;
            this.checkITEM = 0;
        },
        unlinkTransacID() {
            if (this.transac_id === 0 || this.checkITEM === 0) {
                this.modalError = true;
                this.modalErrorMjs = "Error, you must select a Transaction.";
            } else {
                console.log("item = " + this.checkITEM);
                console.log("transac_id = " + this.transac_id);
                this.listExpensesLoading = true;
                window.billing
                    .post("desvinculartransac", {
                        id: this.checkITEM,
                        expense_id: this.transac_id,
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status) {
                            this.$notify({
                                group: "noti",
                                title: "Success",
                                type: "success",
                            });
                        }
                        this.listExpensesLoading = false;
                        this.$store.commit("core/setCloseModal", {refresh:1});
                        this.$store.commit("core/setActionRefresh", {refresh:1});
                    })
                    .catch((error) => {
                        this.listExpensesLoading = false;
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
    },
    computed: {
        filterExpList: function () {
            let self = this;
            return self.listExpenses.filter(function (i) {
                return (
                    (i.fecha &&
                        i.fecha.toLowerCase().indexOf(self.searchExpList.toLowerCase()) >=
                            0) ||
                    (i.amount &&
                        i.amount
                            .toLowerCase()
                            .indexOf(self.searchExpList.toLowerCase()) >= 0) ||
                    (i.formapago &&
                        i.formapago
                            .toLowerCase()
                            .indexOf(self.searchExpList.toLowerCase()) >= 0) ||
                    (i.numdoc &&
                        i.numdoc
                            .toLowerCase()
                            .indexOf(self.searchExpList.toLowerCase()) >= 0) ||
                    (i.subcon &&
                        i.subcon
                            .toLowerCase()
                            .indexOf(self.searchExpList.toLowerCase()) >= 0) ||
                    (i.vendor &&
                        i.vendor
                            .toLowerCase()
                            .indexOf(self.searchExpList.toLowerCase()) >= 0)
                );
            });
        },
    },	
}
</script>
