//display
import DisplayLayout from '@/apps/display/Layouts/DisplayLayout.vue'
import DisplayDash from '@/apps/display/views/DisplayDash.vue'
import DisplayForm from '@/apps/display/views/DisplayForm.vue'
import DisplaySetting from '@/apps/display/views/DisplaySetting.vue'
export default [
	{
		path: '/display',
		folder: DisplayLayout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'Display',
				folder: DisplayDash
			},      
			{
				path: 'form',
				name:'DisplayForm',
				folder: DisplayForm
			},  
			{
				path: 'editDisplay/:id/:edit/:dash',
				name:'DisplayEditdash',
				folder: DisplayForm
			},           
			{
				path: 'setting',
				name:'DisplaySetting',
				folder: DisplaySetting
			},
		]
	}
];    
