let updateColor = function() {
    for (var k in this.listTaks) {
        if (this.listTaks.hasOwnProperty(k)) {
            if (this.listTaks[k].id_i == this.accitonPusher.item.job) {
                this.listTaks[k].id_color = this.accitonPusher.item.id_color;
            }
        }
    }
    this.reEscribirLista();
};
export default updateColor;
