<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4>Cover Picture:</h4>
		</div>
        <div class="window-body">
            <div class="row">
                <div class="col-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item" v-for="(i, idx) in tab" :key="'item'+idx">
                            <a href="#" @click.prevent="chosseTab(i)"
                                :class="['nav-link', (tabSelected==i.val) ? 'active' : '', (i.val==2 && !premiumProfile) ? 'disabled' : '']"
                            >
                                {{ i.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" v-if="tabSelected==1">
                <div class="col-12 text-center marginTop">
                    <div class="banner" :style="isBackgroundCover()">
                        <div class="cover_picture_before" v-if="pictures.cover_active"
                            :style="'background-color: rgba(0 0 0 / '+pictures.cover_overlay+'%);'"
                        ></div>
                        <i class="fas fa-image" v-if="isBackgroundCover()=='' && coverLoad==false || !pictures.cover_active"></i>
                        <Loader v-else-if="coverLoad" :getProgress="ploadProgress"></Loader>
                        <div class="edit edit-cover" @click="$refs.coverImg.click()">
                            <i class="fas fa-upload"></i> Upload Image
                        </div>
                    </div>
                    <input type="file" style="display:none;"
                        ref="coverImg"
                        accept="image/*"
                        @change="loadPicCover($event)"
                    />
                </div>
                <div class="col-12 text-center" style="margin-top: 5px;">
                    <button type="button" @click="rotateImage()"
                        class="btn btn-info"
                        style="border-radius:10px; padding: 8px 12px; background-color: #0075ff;"
                    >
                        Rotate Image
                        <i class="fas fa-redo"></i>
                    </button>
                </div>
                <div class="col-12 marginTop">
                    <div class="controls row">
                        <div class="col-sm-4">
                            <label>
                                PositionY : {{ pictures.cover_positionY }}%
                            </label>
                            <input type="range"
                                v-model="pictures.cover_positionY"
                                min="0" max="100" step="1"
                                :disabled="!pictures.cover_active"
                                style="width: 100%;"
                                @change="setCover()"
                            />
                        </div>
                        <div class="col-sm-4">
                            <label>
                                PositionX : {{ pictures.cover_positionX }}%
                            </label>
                            <input type="range"
                                v-model="pictures.cover_positionX"
                                min="0" max="100" step="1"
                                :disabled="!pictures.cover_active"
                                style="width: 100%;"
                                @change="setCover()"
                            />
                        </div>
                        <div class="col-sm-4">
                            <label>
                                Zoom : {{ pictures.cover_zoom }}%
                            </label>
                            <input type="range"
                                v-model="pictures.cover_zoom"
                                min="100" max="200" step="1"
                                :disabled="!pictures.cover_active"
                                style="width: 100%;"
                                @change="setCover()"
                            />
                        </div>
                    </div>
                    <div class="controls row">
                        <div class="col-4 col-sm-4 col-md-3 marginTop">
                            <label>Image:</label>
                            <div class="onoffswitch" style="margin-left: 0px;">
                                <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    id="cove_active"
                                    v-model="pictures.cover_active"
                                    :value="1"
                                    @change="setCover()"
                                />
                                <label class="onoffswitch-label" for="cove_active" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 marginTop">
                            <label>
                                Overlay: {{ pictures.cover_overlay }}%
                            </label>
                            <input type="range" class="slider-range"
                                v-model="pictures.cover_overlay"
                                min="0" max="100" step="5"
                                :disabled="!pictures.cover_active"
                                style="width: 100%;"
                                @change="setCover()"
                            />
                        </div>
                        <div class="col-12 marginTop">
                            <div class="form-group">
                                <label>Background Color</label>
                                <div class="input-group">
                                    <input type="text"
                                        class="form-control color1"
                                        v-model="pictures.cover_color"
                                        disabled
                                        @change="setCover()"
                                    />
                                    <span class="input-group-text" style="padding:0px;">
                                        <button type="button" class="btn btn-sm btn-light"
                                        v-if="!showPicker" @click="openPicker"
                                        :disabled="pictures.cover_active"
                                        :style="'background-color:'+pictures.cover_color+';padding:10px 16px;color:#fff;'"
                                        ><i class="fas fa-pen"></i></button>
                                        <button type="button" class="btn btn-sm btn-success"
                                        v-if="showPicker" @click="okPicker"
                                        >Ok</button>
                                        <button type="button" class="btn btn-sm btn-danger"
                                        v-if="showPicker" @click="cancelPicker"
                                        >X</button>
                                    </span>
                                </div>
                                <chrome-picker v-if="showPicker"
                                    v-model="getcolor"
                                    class="getcolor"
                                ></chrome-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row controls"  v-if="tabSelected==2" id="content-general-video">
                <Loader v-if="loadingGeneralVideo"  :getProgress="ploadProgress" class="loadingGeneralVideo"></Loader>
                <div class="col-4 col-sm-4 col-md-3 marginTop">
                    <label for="cover_v_active">Video:</label>
                    <div class="onoffswitch" style="margin-left: 5px;display: inline-block;top: 5px;">
                        <input
                            type="checkbox"
                            class="onoffswitch-checkbox"
                            id="cover_v_active"
                            v-model="pictures.cover_v_active"
                            :value="1"
                            @change="setCover()"
                        />
                        <label class="onoffswitch-label" for="cover_v_active" >
                            <span class="onoffswitch-inner"></span>
                            <span class="onoffswitch-switch"></span>
                        </label>
                    </div>
                </div>
                <div class="col-8 col-sm-8 col-md-9 marginTop">
                    <label>
                        Overlay: {{ pictures.cover_overlay }}%
                    </label>
                    <input type="range" class="slider-range"
                        v-model="pictures.cover_overlay"
                        min="0" max="100" step="5"
                        :disabled="!pictures.cover_active"
                        style="width: 100%;"
                        @change="setCover()"
                    />
                </div>
                <div class="col-sm-6">
                    <label>
                        PositionY : {{ pictures.cover_video_y }}%
                    </label>
                    <input type="range"
                        v-model="pictures.cover_video_y"
                        min="0" max="100" step="1"
                        :disabled="!pictures.cover_v_active"
                        style="width: 100%;"
                        @change="setCover()"
                    />
                </div>
                <div class="col-sm-6">
                    <label>
                        Zoom : {{ pictures.cover_video_z }}%
                    </label>
                    <input type="range"
                        v-model="pictures.cover_video_z"
                        min="100" max="200" step="1"
                        :disabled="!pictures.cover_v_active"
                        style="width: 100%;"
                        @change="setCover()"
                    />
                </div>
                <div class="col-12 marginTop">
                    <div id="cover_video" :class="(srcCoverVideo()!=null) ? 'siVideo' : 'noVideo'">
                        <video-player
                            v-if="srcCoverVideo()!=null && pictures.cover_v_active"
                            :srcVideo="srcCoverVideo()"
                            :controlsBtns="controlsVideo"
                            :controlsDefault="true"
                            classVideo="cover_video_preview"
                        ></video-player>
                        <!-- <video v-if="srcCoverVideo()!=null" loop muted="muted" controls style="width: 90%;">
                            <source :src="srcCoverVideo()" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                        </video> -->
                        <i class="fas fa-video-slash" v-else-if="srcCoverVideo()==null && !coverVideoLoad || !pictures.cover_v_active"></i>
                        <i class="fas fa-video" v-else-if="srcCoverVideo()==null && !coverVideoLoad && pictures.cover_v_active"></i>
                        <Loader class="loading_video" v-if="coverVideoLoad" :getProgress="ploadProgress"></Loader>
                        <div class="edit edit-video active" @click="$refs.coverVideo.click()" v-if="pictures.cover_v_active">
                            <i class="fas fa-upload"></i> Upload Video
                        </div>
                        <div class="edit edit-video inactive" v-else  @click="activeVideo()">
                            <i class="fas fa-upload"></i> Upload Video
                        </div>
                    </div>
                    <input type="file" style="display:none;"
                        ref="coverVideo"
                        accept="video/mp4"
                        @change="loadCoverVideo($event)"
                        @cancel="inactiveVideo($event)"
                    />
                </div>
                <div class="col-12 marginTop">
                    <div class="alert alert-info text-center" role="alert">
                        <h2 class="alert-heading">Create video:</h2>
                        <button class="bnt btn-primary btn-lg" @click.prevent="$refs.createVideo.click()">Choose your images</button>
                        <p class="mb-2"></p>
                        <h4 class="alert-heading">Create your own video with your preferred images.<br><small> ( MAX 5, MIN 2 IMG) </small></h4>
                        <input type="file" style="display:none;"
                            ref="createVideo"
                            accept="image/jpg,image/jpeg"
                            multiple
                            @change="uploadImages($event)"
                            @cancel="inactiveCreateVideo($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
// @ts-ignore
import { Chrome } from "vue-color";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import videoplayer from "@/core/plugins/video_player/";
export default {
    name: "editCover",
    components: {
        "chrome-picker": Chrome,
        "video-player": videoplayer,
        Loader,
    },
    data () {
        return {
            urlweb : window.urlbSite,
            getcolor: {
                rgba:'rgb(50 50 50)',
                r: 79,
                b: 164,
                g: 255,
                a: 1,
            },
            showPicker: false,
            colorPreview: 'rgb(50 50 50)',
            logoPreview: null,
            logoLoad: false,
            coverPreview: null,
            coverLoad: false,
            coverVideoLoad: false,
            urlPic: null,
            coverDefault: {
                cover: null,
                cover_active: false,
                cover_overlay: 50,
                cover_positionY: 50,
                cover_positionX: 50,
                cover_zoom: 100,
                cover_color: 'rgb(50 50 50)',
                cover_v_active: false,
                cover_video: null,
                cover_video_y: 50,
                cover_video_z: 120,
            },
            cover_old: null,
            show: true,
			paramsImg: {
			},
			imgDataUrl: '', // the datebase64 url of created image
            video_old: null,
            videoPreview: null,
            controlsVideo: {
                btnPlay: true,
                btnStop: true,
                btnVol: false,
                btnUp: false,
                btnDown: false,
                btnFullScreen: true,
            },
            inputFileStatus: 0,
            uploadType: 'img',
            scanPer: 0,
            tab:[{
                name:'Image Background',
                val:'1',
            },
            {
                name:'Video Background',
                val:'2',
            }],
            tabSelected: '1',
            premiumProfile: false,
            planActual: 1,
            loadingGeneralVideo: false,
        }
    },
    created(){
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        this.pictures = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        if(this.pictures!=null && this.pictures.cover_v_active){
            this.tabSelected='2';
            console.log('tabSelected', this.tabSelected)
        }
    },
    methods: {
        showPremium(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 15000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        chosseTab(i){
            let t = this;
            // if(i.val==2 && t.premiumProfile){
            //     t.tabSelected=i.val;
            // }
            // else if(i.val==2 && !t.premiumProfile){
            //     t.showPremium();
            // }
            // else{
            //     t.tabSelected=i.val;
            // }
            t.tabSelected=i.val;
        },
        isBackgroundCover(){
            let t= this;
            let bg = '';
            let p = t.pictures;
            if(p !=null){
                if(p.cover!=null && p.cover_active){
                    bg='background-image: url('+t.getUrlPic+'large/'+p.cover+');';
                    bg+='background-color:'+p.cover_color+';';
                    bg+='background-position-y: '+p.cover_positionY+'%;';
                    bg+='background-position-x: '+p.cover_positionX+'%;';
                    bg+='background-size: '+p.cover_zoom+'%;';
                    t.imgDataUrl = t.getUrlPic+'large/'+p.cover;
                }else{
                    bg='background-color:'+p.cover_color+';';
                }
            }
            return bg;
        },
        srcCoverVideo(){
            let t = this;
            let vd =null;
            let p = t.pictures;
            if(t.videoPreview!=null){
                vd = t.videoPreview;
            }
            else if(p !=null){
                if(p.cover_video!=null && p.cover_v_active){
                    if(p.cover_video!=null && p.cover_video!='' && p.cover_video!='null'){
                        vd =t.getUrlPic+'video/'+p.cover_video;
                    }
                }
            }
            return vd;
        },
        openPicker(){
            this.showPicker = true;
            this.colorPreview =this.pictures.cover_color;
            localStorage.setItem('cover_color', this.pictures.cover_color);
        },
        okPicker(){
            this.showPicker =false;
            this.pictures.cover_color = this.colorPreview;
            localStorage.removeItem('cover_color');
        },
        cancelPicker(){
            this.showPicker =false;
            this.pictures.cover_color = localStorage.getItem('cover_color');
            this.colorPreview = localStorage.getItem('cover_color');
            localStorage.removeItem('cover_color');
        },
        activeVideo(){
            this.$refs.coverVideo.click();
        },
        inactiveVideo(event){
            let t = this;
            console.log("Suspect Cancel was hit, no files selected.", event);
            if(t.videoPreview==null){
                t.pictures.cover_v_active=0;
                t.coverVideoLoad=false;
            }
        },
        loadPicCover(event){
            let t = this;
            let file = event.target.files[0];
            console.log('loadPicCover', file);
            if (file) {
                t.coverLoad=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                t.coverPreview = e.target.result;
                t.pictures.cover = file;
                t.pictures.cover_active=true;
                t.$refs.coverImg.value = null;
                t.uploadType='img';
                t.setCover();
                };
            } else {
                t.$refs.coverImg.value = null;
            }
        },
        loadCoverVideo(event){
            let t = this;
            let target = event.target;
            console.log('change', event);
            t.inputFileStatus=1;
            let video = target.files[0];
            if(video){
                // 50 mg = 52428800 | 10mg = 10485760
                if(video.size<=52428800){
                    t.coverVideoLoad=true;
                    t.inputFileStatus=3;
                    console.log('loadCoverVideo', video);
                    t.pictures.cover_video = video;
                    t.pictures.cover_v_active=1;
                    // t.videoPreview = URL.createObjectURL(video);
                    t.$refs.coverVideo.value = null;
                    t.uploadType='video';
                    t.setCover();
                }else{
                    t.coverVideoLoad=false;
                    t.inputFileStatus=2;
                    t.$refs.coverVideo.value = null;
                    t.$notify({
                        group: "noti",
                        type: 'Error',
                        title: "Error",
                        text: "Video weighs more than 50 mb",
                    });
                }
            }
        },
        setCover(imagesForVideo){
            let t = this;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.getCardItem.id);
            formData.append("cover_old", localStorage.getItem('cover_old'));
            formData.append("cover", t.pictures.cover);
            let cover_overlay = (typeof t.pictures.cover_overlay == 'boolean') ? Number(t.pictures.cover_overlay) : t.pictures.cover_overlay;
            formData.append("cover_overlay", cover_overlay);
            let cover_active = (typeof t.pictures.cover_active == 'boolean') ? Number(t.pictures.cover_active) : t.pictures.cover_active;
            formData.append("cover_active", cover_active);
            formData.append("cover_color", t.pictures.cover_color);
            formData.append("cover_positionY", t.pictures.cover_positionY);
            formData.append("cover_positionX", t.pictures.cover_positionX);
            formData.append("cover_zoom", t.pictures.cover_zoom);
            let cover_v_active = (typeof t.pictures.cover_v_active == 'boolean') ? Number(t.pictures.cover_v_active) : t.pictures.cover_v_active;
            formData.append("cover_v_active", cover_v_active);
            formData.append("cover_video", t.pictures.cover_video);
            formData.append("cover_video_y", t.pictures.cover_video_y);
            formData.append("cover_video_z", t.pictures.cover_video_z);
            formData.append("pusher_id", pusher.connection.socket_id);
            if(imagesForVideo!=undefined){
                t.uploadType='video';
                formData.append("imagesForVideo", imagesForVideo.length);
                for (const key in imagesForVideo) {
                    if (Object.hasOwnProperty.call(imagesForVideo, key)) {
                        let img = imagesForVideo[key];
                        formData.append("img-"+key+"", img);
                    }
                }
            }
            t.scanPer=0;
            t.$store.dispatch('ModApps/Marketea/editCover', formData)
            .then(r => {
                console.log('setCover', r)
                if(r.status){
                    if(t.uploadType=='img'){
                        if(r.upload){
                            t.pictures.cover=r.filename;
                            t.$parent.close();
                        }
                    }
                    if(t.uploadType=='video'){
                        if(r.upload){
                            t.videoPreview = null;
                            t.pictures.cover_video=r.filename;
                            if(imagesForVideo==undefined){
                                t.$notify({
                                    group: "noti",
                                    type: 'success',
                                    title: "Success",
                                    text: "Setting background with video",
                                });
                            }
                            else{
                                if(r.video.videoImage.status){
                                    t.$notify({
                                        group: "noti",
                                        type: 'success',
                                        title: "Success",
                                        text: "Setting background with video",
                                    });
                                }else{
                                    t.$refs.createVideo.value=null;
                                    t.$notify({
                                        group: "noti",
                                        type: 'Error',
                                        title: "Fail",
                                        text: "Could not create video, try later.",
                                        duration: 2000
                                    });
                                }
                            }
                            t.$parent.close();
                        }
                        else{
                            t.$refs.createVideo.value=null;
                            t.$refs.coverVideo.value = null;
                            t.loadingGeneralVideo=false;
                        }
                        t.coverLoad=false;
                        t.coverVideoLoad=false;
                        t.uploadType=null;
                    }
                }else{
                    t.$notify({
                        group: "noti",
                        type: 'Error',
                        title: "Fail",
                        text: "Error uploading file.",
                        duration: 2000
                    });
                }
            })
            .catch(error => {
                console.log(error)
                t.pictures.cover_video=localStorage.getItem('cover_video');
                t.coverLoad=false;
                if(t.uploadType=='video'){
                    t.coverVideoLoad=false;
                    t.uploadType=null;
                    t.loadingGeneralVideo=false;
                }
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        rotateImage(){
            let t = this;
            t.coverLoad=true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.getCardItem.id);
            formData.append("cover", t.pictures.cover);
            formData.append("field", "cover");
            formData.append("angle", 90);
            formData.append("pusher_id", pusher.connection.socket_id);
            t.$store.dispatch('ModApps/Marketea/rotateImage', formData)
            .then(r => {
                console.log('rotateImage cover', r)
                if(r.upload){
                    t.pictures.cover=r.filename;
                }
                t.coverLoad=false;
            })
            .catch(error => {
                console.log(error);
                t.coverLoad=false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        inactiveCreateVideo(event){
            let t = this;
            // console.log("Suspect Cancel was hit, no files selected.", event);
            t.$refs.createVideo.value=null;
        },
        uploadImages(event){
            let t = this;
            let imgs = event.target.files;
            console.log('uploadImages', imgs);
            if (imgs.length > 5) {
                t.$notify({
                    group: "noti",
                    type: 'error',
                    title: "Limit Max",
                    text: "You cannot select more than 5 images",
                });
                t.$refs.createVideo.value = null;
            }else if(imgs.length < 2){
                t.$notify({
                    group: "noti",
                    type: 'error',
                    title: "Limit Min",
                    text: "you need to select at least 2 images",
                });
                t.$refs.createVideo.value = null;
            }
            else {
                t.loadingGeneralVideo=true;
                t.setCover(imgs);
            }
        },
    },
    watch: {
        getCardItem: function(){
            if(this.getCardItem!=null){
                this.pictures=this.getCardItem;
            }
        },
        getCardUrlPic: function(){
            this.getUrlPic=this.getCardUrlPic;
        },
        ploadProgress: function(){
            console.log('ploadProgress', this.ploadProgress);
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getCardItem: "get_bsite_cardItem",
            getCardUrlPic: "get_bsite_cardUrlPic",
            ploadProgress: "get_ploadProgress",
        }),
        getUrlPic: {
            get: function () {
                let t = this;
                return t.getCardUrlPic;
            },
            set: function (v) {
                return v;
            }
        },
        pictures: {
            get: function () {
                let t = this;
                if(t.getCardItem!=null){
                    let p= t.getCardItem.pictures;
                    localStorage.setItem('cover_old', (p.length>0) ? p[0].cover : null);
                    localStorage.setItem('video_old', (p.length>0) ? p[0].cover_video : null);
                    return (p.length>0) ? p[0] : t.coverDefault;
                }
                return t.coverDefault;
            },
            set: function (v) {
                return v;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .banner{
        border: 1px solid #ddd;
        background-color: #f2f4f5;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 180px;
        margin: auto;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i{font-size: 100px;}
        .cover_picture_before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 4px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
        .edit {
            position: absolute;
            color: #fff;
            right: 15px;
            border: 1px dashed #fff;
            padding: 2px 5px;
            font-size: 18px;
            border-radius: 4px;
            z-index: 4;
            i{font-size: 12px;}
            &.edit-cover{
                bottom:10px;
                &:hover{
                    cursor: pointer;
                    border-color: #fcd602;
                    color: #fcd602;
                }
            }
        }
    }
    .marginTop{margin-top: 20px;}
    .getcolor {
        position: absolute;
        top: -150px;
        z-index: 1;
    }
    .window-header{cursor: move;}
    .window-body{
        height: calc(100vh - 45px);
        height: 600px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-bottom: 3em;
        width: 100%;
        &::-webkit-scrollbar:vertical {
            width:3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #797979;
            border-radius: 20px;
            border: 0px solid #f1f2f3;
        }
        .row{
            width: 100%;
        }
        #cover_video {
            position: relative;
            width: 100%;
            background: #414141;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &.siVideo{min-height: 300px;}
            &.noVideo{min-height: 120px;}
            i.fa-video, i.fa-video-slash{
                font-size: 100px;
                color:#ddd;
            }
            .edit {
                position: absolute;
                right: 15px;
                padding: 2px 5px;
                font-size: 18px;
                border-radius: 4px;
                z-index: 4;
                i{font-size: 20px;}
                &.edit-video{
                    bottom:10px;
                    &.active{
                        border: 1px dashed #fcd602;
                        color: #fcd602;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &.inactive{
                        color: #929090;
                        border: 1px dashed #929090;
                    }
                }
            }
            .loading_video{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: rgba(0 0 0 / 25%);
                .container-progress{
                    top: 0px;
                    width: 80%;
                    .progress{
                        height: 10px;
                    }
                }
            }
        }
    }
    .slider-range {
        margin: auto;
        width: 90%;
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        -webkit-appearance: none;
        &:hover {opacity: 1;}
        &:disabled {opacity: 0.2;}
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #000;
            cursor: pointer;
            background: #000 url('./../../../../../core/assets/img/overlay.png') center no-repeat;
        }
        &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #4CAF50;
            cursor: pointer;
            background: #000 url('./../../../../../core/assets/img/overlay.png') center no-repeat;
        }
    }
    .onoffswitch{
        .onoffswitch-label{
            .onoffswitch-inner{
                &:before{
                    content: "On";
                    padding-left: 10px;
                    background-color: #5ac146;
                    color: #ffffff;
                }
            }
        }
    }
    .card-header h4{font-size:24px;}
    label, .form-control, .input-group-text{
        font-size:18px;
    }
    @media (max-width:767px){
        .window-body{
            height: 100%;
            max-height: 750px;
            padding-bottom: 20em;
        }
    }
    #content-general-video{position: relative;}
    .loadingGeneralVideo{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(0 0 0 / 15%);
        z-index: 1;
        padding-top: 50px;
    }
</style>
