export default { 
    SetAppIDactual(state){ 
      state.appIDactual = localStorage.getItem('appIDactual');
    },
    set_app_modulos(state, modulos){ state.modulos = modulos; },
    SET_APPLIST (state, data){
      let listApps = data.listApps
      let apps = [];
      let privilegios = data.privilegies;
      
      if(data.user_type > 0)
      {
        for (let a in listApps) {listApps[a].privilegies = false; } // inicializando todas como denegadas 
        if(state.appRolUser.length > 0) {
          for (let r in state.appRolUser) {
            for (let a in listApps) {
              if(listApps[a].id == state.appRolUser[r].app_id && state.appRolUser[r].rol == 1){ 
                  listApps[a].icon='';
                  listApps[a].privilegies = true; // acceso a la app
                  //console.log(listApps[a].name, 'permiso ', listApps[a].privilegies);
                }
            }
          }
        }
        // privilegios de modulos para billing o jobs
        for (let i in privilegios.modulos)
        {
          if(privilegios.modulos[i].length > 0)
          {
            for (let a in listApps)
            {
              //console.log( 'comparar  ', listApps[a].id, ' con ', i);
              if(listApps[a].name == i && !listApps[a].privilegies){ 
                listApps[a].icon='';
                listApps[a].privilegies = true;
                //console.log(listApps[a].name, 'permiso ', listApps[a].privilegies);
              }
            }
          }
        }
      }
      else{
        for (let a in listApps) {listApps[a].privilegies = true; } // para admin
      }
      apps = listApps;
      //console.log('lista de app disponibles',apps);
      let orderApp= [
        {name: "Smart Office", id:"1"},
        {name: "Marketea", id:"8"},
        {name: "Jobs", id:"4"},
        {name: "Sites", id:"2"},
        {name: "RoofCal", id:"5"},
        {name: "Slabs", id:"7"},
        {name: "Display", id:"10"},
        {name: "Projects", id:"3"},
      ];
      let newApp = [];
      for (let e in orderApp) {
        if(orderApp[e]){
          for (let i in apps) {
            if(orderApp[e].id == apps[i].id){
              newApp.push(apps[i]);
              //console.log('agg app', apps[i]);
            }
          }
        }
      }
      if(newApp.length < apps.length){
        for (let i in apps) {
          if(!orderApp.indexOf(apps[i])== -1){
            newApp.push(apps[i]);
            //console.log('agg app fuera del order', apps[i]);
          }
        }
      }
      state.appAll = newApp;
    },    
    SET_LISTAVAILABLEAPPS(state, val){
      let r = val;
      let result = r.filter(
        (item) => {
          let ok = true;
          for (let i = 0; i < state.appAll.length && ok; i++) {
            let c = state.appAll[i];
            if (c.id == item.id)
            {
              ok = false;
            }
          }
          return ok;
      });
      state.listAvailableApps = result;
    },
    SET_appRolUser(state, val){ state.appRolUser = val; },
    SET_adminApp(state, val){ state.adminApp = val; },
    setPlanIdApp(state, val){ 
      state.planIdApp = val;
      localStorage.setItem('planIdApp', val);
      console.log('actualizando planIdApp', val);
    },
    setModulesMenu(state, payload){
      // console.log('setModulesMenu', payload)
      localStorage.setItem('modulesMenu', JSON.stringify(payload));
      state.modulesMenu=payload;
    },
}
