<template>
	<div style="display:flex;">
		<dropdown-menu
			v-if="appsorg.length > 0"
            v-model="show"
            :right="right"
            :hover="hover" class="dropAppsArea"
            style="margin-left: 10px;"
        >
			<button class="btnDrop btnListApps">
				<span class="Apps-icon-Left">
					<img class="menuapps" alt="menuapps" src="@/core/assets/img/menuapps.svg" />
				</span>
			</button>
			<div slot="dropdown" class="appslist">
				<div class="AreaDropItems">
					<div class="title_app_header">My Apps</div> 
					<a 
						@click="linkApps(i.id,index)"
						v-for="(i,index) in appsorg"
						:key="index" 
						v-bind:class="['dropdown-item iconapps', (i.statusApp==3) ? 'novisible ' : '']"
						v-if="i.assingStatus!=0 && i.statusApp!=3"
					>
						<span v-if="i.statusApp<3">
							<img alt="billing" v-if="i.id==1" src="@/core/assets/img/icon_billing.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="project"  v-else-if="i.id==3" src="@/core/assets/img/icon_project.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="sites"  v-else-if="i.id==2" src="@/core/assets/img/icon_sites.svg"  style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="jobs"  v-else-if="i.id==4" src="@/core/assets/img/icon_jobs.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="calculator" v-else-if="i.id==5" src="@/core/assets/img/icon_calculator.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="slabs"  v-else-if="i.id==7" src="@/core/assets/img/icon_slabs.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="marketea"  v-else-if="i.id==8" src="@/core/assets/img/icon_bsite.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="display"  v-else-if="i.id==10" src="@/core/assets/img/icon_display.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="dimenu"  v-else-if="i.id==11" src="@/core/assets/img/icon_dimenu.svg" style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<img alt="otro"  v-else  src="@/core/assets/img/icon_auxiliar.svg"  style="height:50px;" v-bind:class="[(i.statusApp==2 || i.assingStatus==0) ? 'disabled ' : '']">
							<!--<img alt="otro" v-bind:class="{disabled: OrgPlan}" v-else  src="@/core/assets/img/icon_auxiliar.svg"  style="height:50px;">-->
						</span>
						<!-- <div v-if="i.id==6">Cars</div> -->
						<div v-if="i.statusApp<3">
							<span v-if="i.id==8">bSite</span><span v-else>{{ i.name }}</span>
						</div>
					</a>
				</div>
			</div>
		</dropdown-menu>
		<router-link :to="{name:'Home'}" >
			<img class="logo" alt="Marketea" src="@/core/assets/img/isotipo-M-white.svg" />
		</router-link>
	</div>
</template>

<script>
	import DropdownMenu from '@innologica/vue-dropdown-menu'
	import { mapGetters } from 'vuex'
	export default {
		name: 'menuApps',
		components: {
			DropdownMenu
		},
		data () {
			return {
				isLoading: false,
				show: false,
				right: true,
				hover: false,
				appsorg: [],
				newListApps: [],
				OrgPlan : (window.OrgPlan <= 1),
				statusPlanBilling: 1,
			}
		},
		created(){
			this.appsorg = this.$store.getters['core/apps/applistorgs'];
			this.statusPlanBilling = localStorage.getItem('statusBilling');
			// console.log('created menuApps', this.appsorg);
		},
		methods: {
			homeAccount() {
				console.log('volver a dash');
				localStorage.setItem('appIDactual', 0);
				this.$store.commit('core/apps/SetAppIDactual');
				this.$router.push({name: 'Home' });
			},
			linkApps(app_id, index) {
				//if(window.OrgPlan > 1 || this.appsorg[index].id == 1){
					if(!this.appsorg[index].privilegies) {
						let msj = this.$store.getters["core/apps/getMsgAccessDeneid"];
						this.$store.commit("core/setAlertSimple", {
							icon: 'error',
							title: msj.title,
							text: msj.msg,
							showConfirmButton: false,
							timer: 4000,
							show: true
						});
					}
					else
					{
						let continuar = true;
						this.$store.dispatch("core/apps/getAppStatus", this.appsorg[index].id).then(r => {
							console.log('getAppStatus', r);
							if(r.appStatus < 1){
								continuar = false; // cambiar a false para bloquear acceso
								let name = this.appsorg[index].name;
								let msj = this.$store.getters["core/apps/getMsgAccessSuspended"];
								let msg = msj.msg.replace('[nameApp]', name);
								this.$store.commit('core/setNotiPush', {
									title: msj.title,
									text: msg,
									show: true,
									group: "notiPush",
									type: '5', // 1=success|2=info|3=error|4=loading|5=warning
									duration: 15000
								});
							}
							if(continuar){
								console.log('linkApps', this.appsorg[index]);
								this.$store.commit('set_isLoading', true);
								let app = {app_id, index};
								this.$store.dispatch('core/apps/linkApp', app).then(r => {
									console.log(r);
									if (r.status) {
										console.log('eliminar appStart');
										localStorage.removeItem('appStart');
										localStorage.setItem('nameApp', r.nomApp);
										this.$router.push({name: r.nomApp });
									}
								})
								.catch(error => {
									console.log(error);
								});
							}
						});
					}
				//}else{
				//	this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
				//}
			},
		},
		watch: {
			'getAppsorg': function(){
				this.appsorg = this.getAppsorg;
				// console.log('watch menuApps', this.appsorg);
			},
			appsorg: function(){
				this.newListApps = this.appsorg;
			},
	        accitonPusher: function () {
	            let t = this;
	            if (t.accitonPusher != undefined) 
	            {
	            	if(t.accitonPusher.action === "statusBilling")
	            	{
                    	let data = t.accitonPusher.data;
                    	if(window.localorgdata == data.org_id && window.localAccountID == data.user_id){
							t.statusPlanBilling = data.statusBilling
							localStorage.setItem('statusBilling', data.statusBilling);
							console.log('accitonPusher statusBilling', t.statusPlanBilling);
                    	}
                	}
            	}
            },
		},
		computed: {
			...mapGetters("core", {accitonPusher: "get_accitonPusher"}),
			...mapGetters('core/apps', {getAppsorg : 'applistorgs'}),
			...mapGetters('core/apps', {getAppID : 'getAppIDactual'}),
		}
	};
</script>
<style lang="css" scoped>
	a.dropdown-item.iconapps img.disabled {
    	opacity: 0.4;
	}
	a.dropdown-item.iconapps.novisible{
		display: none;
	}
</style>
