let actionPDFforEnvelope = function(id) {
	this.closeSubmenu(id);
	window.open(
		window.urlbase +
		"api/v2/invoiceenvelopes/pdf/" +
		id +
		"/" +
		window.master_client +
		"/" +
		localStorage.getItem("oauth_m"),
		"_blank"
	);
};
export default actionPDFforEnvelope;
