import axios from 'axios';
export default {
    SetActive(context, val){
        context.commit('Active', val);
    },
    getStatusAppCRM(context){
        return new Promise((resolve, reject) => {
          window.master.post('getStatusAppCRM',{
            user_id: window.master_client,
            org_id: window.localorgdata,
            pusher_id: pusher.connection.socket_id
        })
          .then(response => {
            console.log('getStatusAppCRM', response.data)
            resolve(response.data);
        })
          .catch(error => {
            console.log(error);
            reject(false);
        });
      });
    },
    getbSite_cardID(context, id){
      return context.getters.get_bsite_cardID(id);
    },
    setCard(context){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(1); // solo editar
        let formData = context.getters.getFielsd;
        window.master.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    editCover(context, payload){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = window.urlmaster + 'api/v3/'+apis.bSite.set(3); // setcover
        context.commit('set_ploadProgress', 0);
        axios.post(url, payload, {
          headers: {
            common: {
              Authorization: 'Bearer ' + window.localtoken_M
            },
            post: { 'Content-Type': 'multipart/form-data' }
          },
          onUploadProgress: (progressEvent) => {
              // console.log('onUploadProgress', progressEvent)
              let scanPer = Math.floor(
                  (progressEvent.loaded*100 / progressEvent.total)
              );
              // console.log('scanPer', scanPer);
              context.commit('set_ploadProgress', scanPer);
          }
        })
        .then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
        // window.master.post(url, payload, {
        //   headers: { "Content-Type": "multipart/form-data" }
        // }).then((response) => {
        //   console.log(url, response.data);
        //   resolve(response.data);
        // }).catch((error) => {
        //   console.log(error);
        //   reject(error);
        // });
      });
    },
    editPicProfile(context, payload){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(4); // setPicProfile
        window.master.post(url, payload, {
          headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    setContact(context){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(7); // editar set_find_me_at
        let formData = context.getters.getFielsd;
        window.master.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    createProfile(context, payload){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(0); // crear card
        window.master.post(url, payload).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    changeStatusCard(context, payload){
      return new Promise((resolve, reject) => {
        let status = (typeof payload.status == 'boolean') ? Number(payload.status) : payload.status
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(6); // changeStatusCard
        window.master.post(url, {
          user_id: window.master_client,
          org_id: window.localorgdata,
          id: payload.id,
          status: status,
          pusher_id: pusher.connection.socket_id
        }).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    setBtnActions(context, payload){
      return new Promise((resolve, reject) => {
        let apis =context.getters.urlApis;
        let url = apis.bSite.set(8); // editar set_btns_action
        let formData = context.getters.getFielsd;
        window.master.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
          console.log(url, response.data);
          resolve(response.data);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    deleteCard(context, payload){
        return new Promise((resolve, reject) => {
          let apis =context.getters.urlApis;
          let url = apis.bSite.set(9); // editar set_btns_action
          window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            id: payload.id,
            about_id: payload.id,
            pusher_id: pusher.connection.socket_id
        })
          .then(response => {
            console.log('getStatusAppCRM', response.data)
            resolve(response.data);
        })
          .catch(error => {
            console.log(error);
            reject(false);
        });
      });
    },
    rotateImage(context, payload){
      return new Promise((resolve, reject) => {
          let apis =context.getters.urlApis;
          let url = apis.bSite.set(10); // rotateImg
          window.master.post(url, payload)
          .then(response => {
            console.log('rotateImg', response.data)
            resolve(response.data);
        })
          .catch(error => {
            console.log(error);
            reject(false);
        });
      });
    },
    setTempOpts(context, payload){
      return new Promise((resolve, reject) => {
          let apis =context.getters.urlApis;
          let url = apis.bSite.set(11); // setOptionsTemplate
          window.master.post(url, payload)
          .then(response => {
            resolve(response.data);
        })
          .catch(error => {
            console.log(error);
            reject(false);
        });
      });
    },
    getPlugins(context){
      return new Promise((resolve, reject) => {
          let apis =context.getters.urlApis;
          let url = apis.bSite.pull(9); // getPlugins
          window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            pusher_id: pusher.connection.socket_id
          })
          .then(response => {
            // console.log('getPlugins', response.data.plugins);
            context.commit('set_pluginList', response.data.plugins);
            // console.log('get_pluginList', context.getters.get_pluginList);
            resolve(response.data);
        })
          .catch(error => {
            console.log(error);
            reject(false);
        });
      });
    },
    search_plugins(context, payload){
      return new Promise((resolve, reject) => {
        console.log(context.getters.get_pluginList);
          let pluginList =context.getters.get_pluginList;
          let result = null;
          if(pluginList && pluginList.length>0){
            result = pluginList.find(p => { return p.plugin_id == payload.id || p.slug == payload.slug});
          }
          resolve(result);
      });
    },
}
