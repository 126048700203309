<template>
	<div class="filtros">
		<div class="row mobile_display">
			<div class="uno">
				<form @submit.prevent="$parent.searchFilter">
					<div class="input-group">
						<dropdown-menu
				            v-model="$parent.fieldsdropd"
				            :right="$parent.right"
				            :hover="$parent.hover"
				        >
							<button type="button" class="btn btn-light btn-sm" style="padding: 0.48rem 0.75rem;border: 0px;">
								<i class="fas fa-search-plus fa-lg"></i>
							</button>
							<div slot="dropdown">
								<div
									class="dropdown-item"
									v-for="(item, index) in $parent.fieldsList"
									:key="index"
									>
									<div class="custom-control custom-checkbox">
										<input
										type="checkbox"
										class="custom-control-input"
										:id="index + '-' + item.name"
										v-bind:value="item.value"
										v-model="$parent.fieldsFilter"
										/>
										<label
										class="custom-control-label"
										:for="index + '-' + item.name"
										>
										{{ item.name }}
										</label>
									</div>
								</div>
							</div>
						</dropdown-menu>
						<input
							type="text"
							class="form-control searchbox-input"
							placeholder="Search"
							v-model="$parent.inputFilter"
						/>
						<div class="input-group-prepend btn-filter-search">
							<span
								class="btn btn-info"
								@click="$parent.searchFilter"
								style="border: 0px"
							>Search</span>
						</div>
						<div
							class="input-group-prepend btn-filter-search"
							@click="$parent.searchAll"
						>
							<span class="input-group-text"><i class="fas fa-backspace"></i></span>
						</div>
					</div>
				</form>
			</div>
			<div class="dos">				
				<dropdown-menu
		            v-model="$parent.statusdropd"
		            :right="$parent.right"
		            :hover="$parent.hover"
		        >
		            <button class="btn btn-light btn-sm">
		                Status
						<i class="fas fa-caret-down"></i>
		            </button>
		            <div slot="dropdown">
						<div class="dropdown-item">
							<div class="custom-control custom-checkbox">
								<input
									type="checkbox"
									class="custom-control-input"
									id="check12"
									@click="$parent.checkAll()"
									v-model="$parent.isCheckAll"
								/>
								<label class="custom-control-label" for="check12">
									<b>All</b>
								</label>
							</div>
						</div>
		                <div
		                    class="dropdown-item"
		                    v-for="(item, index) in $parent.statusdata"
							:key="index"
						>
							<div class="custom-control custom-checkbox">
								<input
									type="checkbox"
									class="custom-control-input"
									:id="index + '-' + item.name"
									v-bind:value="item.value"
									v-model="$parent.statuslist"
									@change="$parent.searchFilter"
								/>
								<label
									class="custom-control-label"
									:for="index + '-' + item.name"
								>
									{{ item.name }}
								</label>
							</div>
		                </div>
		            </div>
		        </dropdown-menu>
			</div>
			<div class="tres">
				<button
					type="button"
					class="btn btn-light btn-sm"
					@click="$parent.filterFechas"
					>
					<i class="fas fa-calendar-alt"></i>
				</button>
			</div>
			<div class="cuatro">
				<dropdown-menu
		            v-model="$parent.show"
		            :right="$parent.right"
		            :hover="$parent.hover"
		        >
		            <button class="btn btn-light btn-sm">
		                <i class="fas fa-cogs"></i>
		            </button>
		            <div slot="dropdown">
		                <div
		                    class="dropdown-item"
		                    v-for="(i, idx) in $parent.opAddi"
		                    :key="idx"
		                >
		                    <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="idx + '-' + i.name"
                                    v-bind:value="i.value"
                                    v-model="$parent.valOpAddi"
									@change="$parent.changeOpAddi"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="idx + '-' + i.name"
                                >
                                    {{ i.name }}
                                </label>
                            </div>
		                </div>
		            </div>
		        </dropdown-menu>
			</div>
		</div>
	</div>
</template>
<script>
import { ClickOutside } from 'vue-click-outside'
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
	name: 'filtros',
	components: {
		DropdownMenu
	},
	data () {
		return {
		}
	},
	created(){
		console.log('created itemSelected', this.$parent.itemSelected)
	},
	directives: {
		ClickOutside,
	},
	watch: {
		"$parent.itemSelected": function() {
			console.log('watch itemSelected', this.$parent.itemSelected)	
		}
	}
}
</script>
<style lang="scss" scoped>
@import './css/filtros.scss';
</style>
