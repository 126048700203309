<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full" data-boxed-layout="full">
        <div class="page-wrapper homepage" bg="@/core/assets/img/isologo-w.svg">
            <slot>
                <div class="bgloading" v-if="isLoading"><Loader></Loader></div>
                <div class="container ">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <infoApp></infoApp>
                        </div>
                        <div class="col-xs-12 col-sm-12 text-center">
                            <span class="appLogin bsiteme" @click="link_bSite()">
                                <img class="iconapp" alt="bsiteme-8" src="@/core/assets/img/icon_bsite_white.svg">
                            </span>
                            <span class="appLogin billing" @click="link_billing()">
                                <img class="iconapp" alt="billing-1" src="@/core/assets/img/icon_billing.svg">
                            </span>
                        </div>
                        <div class="col-xs-12 col-sm-12 formloginhome">
                            <formLogin></formLogin>
                        </div>
                    </div>
                </div>
            </slot> 
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/Loader'
    import formLogin from '@/core/components/Login/formLogin'
    import infoApp from '@/core/components/Login/infoApp'
    import { mapGetters } from 'vuex'
    export default {
        name: 'LoginLayout',
        components: {
            Loader,
            formLogin,
            infoApp
        },
        data () {
            return {
                isLoading: false,
                urlPage: window.urlweb
            }
    },
    created () {
        this.$store.commit('set_isLoading', false);
        localStorage.removeItem('disconnected');
        this.validSesion();
    },
    methods: {
        validSesion(){
            this.isLoading= true;
            this.$store.dispatch('core/session/validToken')
            .then(response => {
                //console.log(response.data);
                let r = response.data;
                console.log('validToken login ', r);
                if(r.tokenValid)
                {
                    this.$store.dispatch('core/session/SET_EMAIL_VERIFY', r.emailVerify);
                    this.loginExterno();
                }
                else
                {
                    console.log('no hay entrada al sistema ', r.tokenValid);
                    if(this.getVarUrlByName('s')){
                        console.log('validando support', this.getVarUrlByName('s'))
                        this.$store.dispatch('core/session/validTokenSupportM', this.getVarUrlByName('s'))
                        .then(response => {
                            if(response.data.status){
                                // window.location.reload(true);
                                window.location.href=window.location.origin
                                this.isLoading= false;
                            }else{
                                window.location.href=window.location.origin
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            window.location.href=window.location.origin
                        });
                    }else{
                        this.isLoading= false;
                        // debugger;
                        if(localStorage.getItem('oauth_r')!=null){
                            this.$router.push({ name: 'Reseller' });
                        }
                        else {
                            this.$store.commit('core/session/destroyToken');
                            if(!localStorage.getItem('access_web')){localStorage.setItem('access_web', 0)}
                            if(r.sms){
                                localStorage.setItem('loginSMS', 1);
                            }else{localStorage.setItem('loginSMS', 0);}
                            let validLogin = false;
                            if(localStorage.getItem('ui')==null || localStorage.getItem('ui')=='Reseller'){
                                localStorage.setItem('ui', 'Login');
                                validLogin = true;
                            }
                            else if(localStorage.getItem('ui')=='Login'){
                                validLogin = true;
                            }
                            if(!validLogin){
                                console.log('ui', localStorage.getItem('ui'));
                            }
                        }
                    }
                }
            });
        },
        loginExterno()
        {
            let client_Org = localStorage.getItem('mark_client_data');
            let client_M = localStorage.getItem('mark_client_data_m');
            let token_M = localStorage.getItem('access_token_m');
            let oauth_M = localStorage.getItem('oauth_m');
            this.isLoading = true;
            if(oauth_M != null && client_Org !=null && client_M != null && token_M != null)
            {
                let t = this;
                this.$store.dispatch('core/session/retrieveToken', {validM: true})
                .then(response => {
                    console.log('sesion master ', response);
                    //llamar privilegios de la cuenta
                    t.$store.commit('set_isLoading', true);
                    t.getPrivilegios();
                })
                .catch((error) => {
                    //console.log(error);
                    console.log('error al iniciar sesion', error);
                    t.logout();
                });
            }
            else
            {
                console.log('no hay variables', oauth_M, client_Org, client_M, token_M);
                this.logout();
            }
        },
        getPrivilegios(){
            let t = this;
            // t.$store.dispatch('core/session/privilegies_session').then(response => {
                // console.log('privilegies_session', response);
                if(t.$store.getters['core/session/loggedIn']){
                    t.$router.push({ name: 'Home' });
                }else{
                    console.log('sesion corructa loggedIn', t.$store.getters['core/session/loggedIn']);
                    t.logout();
                }
            // })
            // .catch((error) => {
            //     //console.log(error);
            //     console.log('error privilegies_session', error);
            //     t.logout();
            // });
        },
        logout() {
            this.$store.dispatch('core/session/destroyToken')
            .then(response => {
                console.log('destroyToken', response);
                this.isLoading= false;
            })
            .catch(error => {
                console.log('destroyToken', error);
                this.isLoading= false;
            });
        },
        link_bSite() {
            window.open(window.urlbSite, "_blank");
        },
        link_billing() {
            window.open(window.urlweb+'smart-office', "_blank");
        },
        getVarUrlByName(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
            let results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },
    },
    watch: {
        'get_isLoading': function() {
            this.isLoading = this.get_isLoading;
            if(this.get_isLoading) {
                this.$notify({
                    group: 'noti',
                    type: 'success',
                    title: 'Accessing',
                    text: 'redirecting...',
                });
            }
        }
    },
    computed: {
        ...mapGetters({get_isLoading: 'get_isLoading'}),
  }
  };
</script>
<style lang="scss" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .page-wrapper{margin-top: 0px;}
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .bgloading {
        padding-top: 200px;
        align-items: initial !important;
    }
    .container {
        max-width: 1000px;
        padding-top: 10px;
        padding-bottom: 50px;
        /*background: linear-gradient(to right, rgb(255 255 255 / 68%) 0%, rgb(82 94 255) 50%, rgb(72 88 253) 100%);
        border-radius: 10px;    
        border: 1px solid rgb(38 55 242 / 12%);
        box-shadow: 3px 3px 3px rgb(44 62 248 / 34%);*/
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        position: relative;
        /*background: linear-gradient(to right,#031099 0%,#0212eb 50%,#3243f8 100%);*/
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 0px;
    }
    .page-wrapper.homepage:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-image: url('../assets/img/isologo-blue.svg');
        background-position: center;
        background-repeat: repeat-x;
        background-size: 200px;
        opacity: 0.1;*/
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 0px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 100%;
        }   
    }
    @media (max-width: 767px) {
        .container {
            padding-top: 20px;
            padding-bottom: 0px;
            height: 100vh;
            border-radius: 0px;
        }
        .page-wrapper.homepage{padding-top: 00px;}
        .page-wrapper.homepage:before{
            /*background-image: url('../assets/img/isologo-blue.svg');
            background-repeat: repeat-y;
            background-size: 200px;
            opacity: 0.1;*/
        }
    }
    .appLogin{
        position: relative;
        display: inline-flex;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin: 10px 25px;
        overflow: hidden;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 10px #ddd;
        &:hover{cursor: pointer;}
        .iconapp{
            width: 100%;
            margin: auto;
        }
        &.bsiteme{
            .iconapp{
                position: relative;
                scale: 1.6;
            }
        }
        &.billing{
            .iconapp{
                scale: 1.1;
                position: relative;
                left: 2px;
                top: 2px;
            }
        }

    }
</style>
