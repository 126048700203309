<template>
    <div>
        <header-principal></header-principal>
        <div v-if="accessDisplay">
            <div v-if="getHeader" class="page-wrapper" @click="ShowHiddenAside">
                <!--  v-bind:class="{ show: showAside }"> -->
                <router-view></router-view>
            </div>
        </div>
        <footer-principal></footer-principal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import FooterPrincipal from "@/core/views/Footer";
export default {
    name: "DisplayLayout",
    components: {
        HeaderPrincipal,
        FooterPrincipal,
    },
    data() {
        return {};
    },
    created() {
        if (this.loggedIn && this.getHeader) {
            this.validAccess();
        }
    },
    mounted() {},
    beforeCreate() {},
    methods: {
        validAccess() {
            this.$store.dispatch("core/apps/validAccess").then((response) => {
                let AppDisplay = response;
                if (AppDisplay) {
                    //si tiene Display asignado entonces permitir acceso
                    this.$store
                        .dispatch("core/session/privilegies_session")
                        .then((response) => {
                            console.log(response);
                            console.log("Accesso permitido");
                            this.$store.dispatch(
                                "ModApps/display/SetActiveDisplay",
                                AppDisplay
                            );
                        });
                } else {
                    this.$store.dispatch("ModApps/display/SetActiveDisplay", AppDisplay);
                    console.log("Accesso Negado");
                    this.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
        ShowHiddenAside() {
            this.$store.commit("ModApps/display/ActiveLateral", true);
        },
    },
    watch: {
        getHeader: function () {
            //console.log('getHeader ', this.getHeader);
            this.validAccess();
        },
    },
    computed: {
        ...mapGetters("ModApps/display", {
            accessDisplay: "access_display",
            showAside: "lateral",
        }),
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" }),
    },
};
</script>
<style scoped lang="scss">
.header {
    top: 0;
}
.page-wrapper {
    margin-left: 0px;
    padding-top: 0px;
}
</style>
