<template>
    <div class="template_container">
        <!-- <div class="add_contact_share_qr text-center">
            <a href="#Show_Qr" @click.prevent="$parent.linkVoid()" class="btn show_Qr hvr-float" aria-label="show or Hide code Qr">
                <i class="fas fa-qrcode"></i>
            </a>
            <a href="#Share_link" @click.prevent="$parent.linkVoid()" class="btn share_link hvr-float" aria-label="Share" >
                <img :src="$parent.urlweb+'img/share.svg'" alt="icon_share" width="25" height="32"/>
            </a>
        </div> -->
        <div class="cover_container">
            <div class="cover_picture" :style="$parent.isBackgroundCover($parent.item)">
                <div class="cover_video" v-if="$parent.item.pictures[0].cover_v_active">
                    <video id="cover_video_player" loop muted="muted" playsinline="true" autoplay :key="'video-'+$parent.item.id"
                    :style="$parent.positionVideo($parent.item)">
                        <source :src="$parent.srcCoverVideo($parent.item)" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                <div class="cover_picture_before"
                    v-if="$parent.item.pictures[0].cover_active"
                    :style="'background-color: rgba(0 0 0 / '+$parent.item.pictures[0].cover_overlay+'%);'"
                ></div>
                <div class="edit edit-cover t4" @click="$parent.$emit('setModal', 'editCover')"><i class="fas fa-pen"></i> Cover</div>
                <div :class="['contact-iconos', 'text-center', 'edit-contact']" @click.prevent="$parent.editContact()">
                    <div class="box-icon" v-for="(c, cIdx) in $parent.item.find_me_at" :key="c.id" v-show="c.status==1">
                        <div :class="['button-icon hvr-sweep-to-bottom','bg'+(parseInt(cIdx)+1)]">
                            <a href="#" @click.prevent="$parent.linkVoid()" :class="'btn btn-sbg '" >
                                <i :class="c.icon"></i>
                                <p>{{$parent.helperLabel(c)}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="map_location">
            <div class="logo_org">
                <div class="profile">
                    <div class="profile_picture" :style="$parent.isProfile($parent.item)">
                        <i class="fas fa-image fa-lg" v-if="$parent.isProfile($parent.item)==''"></i>
                        <Loader v-if="$parent.profileLoad" class="profileLoad"></Loader>
                        <div class="edit edit-profile" @click="$parent.editPicProfile()"><i class="fas fa-pen"></i></div>
                    </div>
                </div>
            </div>
            <div class="location">
                <div class="edit edit-info" @click="$parent.$emit('setModal', 'editMapIframe')"><i class="fas fa-pen"></i></div>
                <iframe :src="$parent.mapLocation"
                    width="100%"
                    height="100%"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
        <div class="btnsActions">
            <div class="edit edit-btnsActions" @click="$parent.edit_btnsActions()"><i class="fas fa-pen"></i></div>
            <div class="list-btnsActions" v-if="$parent.premiumProfile && $parent.item.btns_action!=null && $parent.item.btns_action.length>0">
                <a href="#" v-if="$parent.btns_action($parent.item.btns_action).length>=1"
                    @click.prevent="$parent.edit_btnsActions()"
                    class="btn-action hvr-shrink btnFirst"
                    :aria-label="$parent.btns_action($parent.item.btns_action)[0].label"
                    :style="customBgBtn($parent.item.btns_action[0].style)"
                >
                    {{$parent.btns_action($parent.item.btns_action)[0].label}}
                </a>
                <a href="#" v-if="$parent.btns_action($parent.item.btns_action).length>=2"
                    @click.prevent="$parent.edit_btnsActions()"
                    class="btn-action hvr-shrink btnSecond"
                    :aria-label="$parent.btns_action($parent.item.btns_action)[1].label"
                    :style="customBgBtn($parent.item.btns_action[1].style)"
                >
                <div class="label-text">
                    {{ $parent.btns_action($parent.item.btns_action)[1].label }}
                </div>
                </a>
                <a href="#" v-if="$parent.btns_action($parent.item.btns_action).length==3"
                    @click.prevent="$parent.edit_btnsActions()"
                    class="btn-action hvr-shrink btnThird"
                    :aria-label="$parent.btns_action($parent.item.btns_action)[2].label"
                    :style="customBgBtn($parent.item.btns_action[2].style)"
                >
                    <span class="label-link">{{$parent.btns_action($parent.item.btns_action)[2].label}}</span>
                </a>
            </div>
            <a v-else href="#" @click.prevent="$parent.edit_btnsActions()"
                class="btn-action hvr-shrink btn-action-width-full" aria-label="ADD TO CONTACT">
                ADD TO CONTACT
            </a>
        </div>
        <div class="infoContainer">
            <!-- <div class="add_contact_share_qr text-center">
                <a href="#Show_Qr" @click.prevent="$parent.linkVoid()" class="btn show_Qr hvr-float" aria-label="show or Hide code Qr">
                    <i class="fas fa-qrcode"></i>
                </a>
                
                <a href="#Share_link" @click.prevent="$parent.linkVoid()" class="btn share_link hvr-float" aria-label="Share" >
                    <img :src="$parent.urlweb+'img/share.svg'" alt="icon_share" width="25" height="32"/>
                </a>
            </div> -->
            
        </div>
    </div>
</template>
<script>
export default {
    name:"htmlTemplate6",
    methods: {
        customBgBtn(style){
            let t = this;
            let d = t.$parent.style_default;
            let s = '';
            let css = (style==null || style=='null' || style=='' || style==='[object Object]') ? d : ((typeof style === 'object') ? style : JSON.parse(style));
            s+='background: '+css.bgColor+';';
            s+='border-color: '+css.bgColor+';';
            s+='color: '+css.txtColor+';'
            // console.log('style', s);
            return s;
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('../scss/efectsThemes.scss');
@import url('../scss/baseTemplate.scss');
@import url('../scss/events.scss');
</style>
