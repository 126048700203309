<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-lg-5 col-xl-6">
                <tablegutters></tablegutters>
            </div>
            <div class="col-md-8 col-lg-7 col-xl-6">
                <formestgutter></formestgutter>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import "./scss/stylesGlobal.scss"
import formestgutter from './components/formEstGutter.vue';
import tablegutters from './components/tableGutters.vue';
export default {
    components: {
        formestgutter,
        tablegutters
    }
}
</script>

<style lang="scss" scoped>

</style>
