var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notifications',{staticClass:"vue-notification-notiPush",attrs:{"group":"noti","position":"top center","animation-name":"v-fade-left"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('div',{class:[
					'notiPush', item.type,
					''
				]},[_c('div',{staticClass:"notiPush-icon"},[_c('i',{class:[
							'fas', 
							(item.type=='success') ? 'fa-check-circle' :
							(item.type=='error') ? 'fa-times-circle' : '',
							' fa-fw '
						]})]),_c('div',{staticClass:"notiPush-content"},[_c('div',{staticClass:"notiPush-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"notiPush-message",domProps:{"innerHTML":_vm._s(item.text)}})]),_c('div',{staticClass:"notiPush-close"},[_c('button',{staticClass:"close",on:{"click":close}},[_c('i',{staticClass:"fa fa-times"})])])])]}}])}),_c('notifications',{staticClass:"vue-notification-notiPush",attrs:{"group":"notiPush","position":"top center","animation-name":"v-fade-left"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
						var item = ref.item;
						var close = ref.close;
return [_c('div',{class:[
					'notiPush',
						(item.type==1) ? 'success' :
						(item.type==2) ? 'info' : 
						(item.type==3) ? 'error' :
						(item.type==4) ? 'refresh' :
						(item.type==5) ? 'warning' : '',
					''
				]},[_c('div',{staticClass:"notiPush-icon"},[_c('i',{class:[
							'fas', 
							(item.type==1) ? 'fa-check-circle' :
							(item.type==2) ? 'fa-info-circle' : 
							(item.type==3) ? 'fa-times-circle' :
							(item.type==4) ? 'fa-hourglass-half' :
							(item.type==5) ? 'fa-exclamation-triangle' : '',
							' fa-fw '
						]})]),_c('div',{staticClass:"notiPush-content"},[_c('div',{class:[
							'notiPush-title',
								(item.type==1) ? 'success' :
								(item.type==2) ? 'info' : 
								(item.type==3) ? 'error' :
								(item.type==4) ? 'refresh' :
								(item.type==5) ? 'warning' : '',
							''
						]},[_vm._v(_vm._s(item.title))]),_c('div',{class:[
							'notiPush-message',
								(item.type==1) ? 'success' :
								(item.type==2) ? 'info' : 
								(item.type==3) ? 'error' :
								(item.type==4) ? 'refresh' :
								(item.type==5) ? 'warning' : '',
							''
						],domProps:{"innerHTML":_vm._s(item.text)}})]),_c('div',{class:[
							'notiPush-close',
								(item.type==1) ? 'success' :
								(item.type==2) ? 'info' : 
								(item.type==3) ? 'error' :
								(item.type==4) ? 'refresh' :
								(item.type==5) ? 'warning' : '',
							''
						]},[_c('button',{staticClass:"close",on:{"click":close}},[_c('i',{staticClass:"fa fa-times"})])])])]}}])}),_c('notifications',{attrs:{"group":"update-item","width":160,"animation-name":"v-fade-left","position":"top center"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"custom-template"},[_c('div',{staticClass:"custom-template-content"},[_c('div',{staticClass:"custom-template-title"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-hourglass-half"})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.noti.text))])])])])])],2),_c('notifications',{staticClass:"vue-notification-notiPushPremium",attrs:{"group":"notiPushPremium","position":"top center","animation-name":"v-fade-left"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
						var item = ref.item;
						var close = ref.close;
return [_c('div',{staticClass:"notiPush Premium light"},[_c('div',{staticClass:"notiPush-icon"},[_c('i',{staticClass:"fas fa-info-circle fa-fw"})]),_c('div',{staticClass:"notiPush-content Premium"},[_c('div',{staticClass:"notiPush-title"},[_vm._v("This feature is available for PREMIUM USERS.")]),_c('div',{staticClass:"notiPush-message"},[_c('h4',[_vm._v("Upgrade your App to Premium and advanced features. Premium Plan cost $50/month.")])]),_c('button',{staticClass:"btn btn-success",staticStyle:{"display":"inline-flex","justify-content":"center","width":"160px","margin-right":"10px"},attrs:{"type":"button"},on:{"click":_vm.setPremium}},[_vm._v(" UNLOCK PREMIUM ")]),_c('button',{staticClass:"btn btn-secondary",staticStyle:{"display":"inline-flex","justify-content":"center","width":"100px"},attrs:{"type":"button"},on:{"click":function($event){return close()}}},[_vm._v(" CLOSE ")])]),_c('div',{staticClass:"notiPush-close Premium"},[_c('button',{staticClass:"close",on:{"click":function($event){return close()}}},[_c('i',{staticClass:"fa fa-times"})])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }