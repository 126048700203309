<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title">
                        <i class="fas fa-chart-line"></i> Report Estimates
                    </h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>                           
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-3">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    @click="FilterEstimate()"
                                                    style="width: inherit;min-width: 80px;"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button>
                                            </div>                               
                                            <div class="col-md-9">
                                                <select
                                                        class="form-control"
                                                        v-model="SelectOne"
                                                        @change="ChangeSelectPrimary"
                                                    >
                                                    <option value="1">This Year</option>
                                                    <option value="2">This Month</option>
                                                    <option value="3">Calendar</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divS" class="col-md-4" v-if="SelectOne == 3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>From:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateFrom"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>            
                                                    </div>
                                                </div>                               
                                            </div>
                                            <div class="col-md-6">   
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>To:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateTo"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>           
                                                    </div>                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divB" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <div class="col-md-4">
                                                        <label for="staticEmail2" class="">
                                                            <span style="">Status:</span>    
                                                        </label>                                                      
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="basic_dropdown">
                                                            <button
                                                                class="btn-status"
                                                                @click="btnstatus()"
                                                            >
                                                                Status <i class="fas fa-caret-down"></i>
                                                            </button>
                                                            <ul
                                                                class="reset dropdStatus"
                                                                v-if="statusdropd"
                                                            >
                                                                <li>
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            id="check12"
                                                                            @click="checkAll()"
                                                                            v-model="isCheckAll"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            for="check12"
                                                                            ><b>All</b></label
                                                                        >
                                                                    </div>
                                                                </li>
                                                                <li
                                                                    v-for="(item, index) in statusdata"
                                                                    :key="index"
                                                                >
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            :id="index"
                                                                            v-bind:value="item.value"
                                                                            v-model="statuslist"
                                                                            @change="updateCheckall()"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            :for="index"
                                                                            >{{ item.name }}</label
                                                                        >
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divC" v-if="SelectOne != 3" class="col-md-6">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFile('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>   
                                    <div id="divC" v-else class="col-md-2">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFile('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->                        
                            <!-- tabla -->
                            <div class="col-md-12">
                                <div class="contenedor-table">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        id="invList"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Est #</th>
                                                <th scope="col">Date</th>
                                                <th scope="col" style="width: 200px">
                                                    Customer
                                                </th>
                                                <th scope="col">Name</th>
                                                <th
                                                    scope="col"
                                                    style="
                                                        text-align: center;
                                                        width: 100px;
                                                    "
                                                >
                                                    Status
                                                </th>
                                                <th scope="col" style="text-align: right">
                                                    Total
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: right"></td>
                                                <td style="text-align: right"></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>

                                            <tr
                                                v-for="(item, index) in EstimatesList"
                                                v-bind:key="index"
                                            >
                                                <td scope="row">{{ item.nro }}</td>
                                                <td>{{ item.fecha }}</td>
                                                <td>
                                                    {{ item.customername }}
                                                    {{ item.customelastname }}<br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.customecompany }}</span
                                                    >
                                                </td>
                                                <td>{{ item.name }}</td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.status == 1"
                                                        class="badge badge-secondary"
                                                        style="background-color: #f89406"
                                                        >Pending</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 3"
                                                        class="badge badge-primary"
                                                        >Rejected</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 2"
                                                        class="badge badge-success"
                                                        >Accepted</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 4"
                                                        class="badge badge-danger"
                                                        style="background-color: #b94a48"
                                                        >Void</span
                                                    >
                                                </td>
                                                <td style="text-align: right">
                                                    ${{ item.total | formatMoney }}
                                                </td>
                                            </tr>
                                            <tr v-if="noresult">
                                                <td colspan="6">
                                                    <div
                                                        style="
                                                            text-align: center;
                                                            color: red;
                                                        "
                                                    >
                                                        No result
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: right"></td>
                                                <td style="text-align: right"></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "Report",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            noresult: false,
            statusdropd: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Accepted" },
                { value: "3", name: "Rejected" },
                { value: "4", name: "Void" },
            ],
            statuslist: [1, 2, 3, 4],
            showModal: false,
            EstimatesList: [],
            SelectOne: 1,
            SelectStatus: 0,
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            thisYear: null,
            thisMonth: null,
        };
    },
    created() {
        this.FilterEstimate();
    },
    mounted() {
        this.setTimeoutResponsive()
    },
    methods: {
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            let divS = document.getElementById("divS");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            } 
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }  
            }
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                              	
            } 
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
        },
        ChangeSelectPrimary() {
            this.setTimeoutResponsive()
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        },          
        formatearFecha() {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        /* generarFiles(type){
        this.$swal({
            title: "Export",
            text: "Export Report as "+type+" (Microsoft Excel)",
            icon: "info",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: 'Cancel',
            confirmButtonText: "Export",
            showLoaderOnConfirm: true,
            preConfirm: () => {
            window.billing.post('report/generatefile',{
                          user_id: window.localuserdata,
                          selecttype: this.SelectOne,
                          status: this.statuslist,
                          dateFrom: this.dateFrom,
                          dateTo: this.dateTo,
                          filetype : type,
                          })
                .then(response => {
                    console.log(response.data);
                    this.statusdropd = false
                    this.isLoading = false
                    window.open(window.urlbase+'files/'+response.data.result);
                    
                })
                .catch(error => {
                  this.$swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
          .then((result) => {
          if (result.value) {
             this.$swal.fire({
              allowOutsideClick: false,
              text: `Successfully downloaded`,
              confirmButtonText: "Close",
              showLoaderOnConfirm: true,
                preConfirm: () => {
                 this.DeleteFile();
                },
            })
          }
        }) 
        }, */
        DeleteFile() {
            let name = "estimates";
            window.billing
                .get("report/deletefile/" + window.localuserdata + "/" + name)
                .then((response) => {
                    console.log(response.data.result);
                })
                .catch((error) => console.log(error));
        },
        generarFile(type) {
            let user_id = window.localuserdata;
            let selecttype = this.SelectOne;
            let status = this.statuslist;
            let dateFrom = this.dateFrom;
            let dateTo = this.dateTo;
            let uri =
                "report/export/estimate/pdf/" +
                user_id +
                "/" +
                selecttype +
                "/" +
                status +
                "/" +
                dateFrom +
                "/" +
                dateTo +
                "/" +
                type;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        ChangeSelectStatus() {},
        FilterEstimate() {
            this.isLoading = true;

            window.billing
                .post("report/estimates", {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                    status: this.statuslist,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                })
                .then((response) => {
                    console.log(response.data.result);
                    this.statusdropd = false;
                    this.isLoading = false;
                    this.EstimatesList = response.data.result;
                    if (this.EstimatesList.length == 0) {
                        this.noresult = true;
                    } else {
                        this.noresult = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
    },
    watch: {
        dateFrom: function () {
            this.formatearFecha();
        },
    },
    computed: {
        total: function () {
            if (!this.EstimatesList) {
                return 0;
            }
            return this.EstimatesList.reduce(function (total, value) {
                return total + Number(value.total);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style-type: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}

#invList thead tr th:nth-child(2),
#invList tbody tr td:nth-child(2) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(3),
#invList tbody tr td:nth-child(3) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(4),
#invList tbody tr td:nth-child(4) {
    min-width: 200px;
}
#invList thead tr th:nth-child(5),
#invList tbody tr td:nth-child(5) {
    min-width: 180px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
#invList thead tr th:nth-child(11),
#invList tbody tr td:nth-child(11) {
    min-width: 85px;
    width: 85px;
}
@media (max-width: 1100px) {
    #invList thead tr th:nth-child(1),
    #invList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    #invList thead tr th:nth-child(2),
    #invList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(3),
    #invList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(4),
    #invList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    #invList thead tr th:nth-child(5),
    #invList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    #invList thead tr th:nth-child(6),
    #invList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(7),
    #invList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    #invList thead tr th:nth-child(8),
    #invList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(9),
    #invList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(10),
    #invList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    #invList thead tr th:nth-child(11),
    #invList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
table tbody tr td .badge {
    width: 70px;
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
