<template>
    <div>
        <div class="card-header">Show PDF Options INVOICE</div>
        <div class="card-body">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <form @submit.prevent="saveShowPDFOptionsInvo">
                <div class="form-row">
                    <div class="col-12">
                        <label class="radio-inline col-6">
                            <span id="email" class="form-text"> Show Email</span>
                            <input
                                type="radio"
                                value="1"
                                v-model="showEmail"
                            />&nbsp;&nbsp;
                        </label>
                        <label class="radio-inline col-6">
                            <span id="email" class="form-text"> Hide Email</span>
                            <input type="radio" value="0" v-model="showEmail" />
                        </label>
                    </div>
                    <div class="col-12">
                        <label class="radio-inline col-6">
                            <span id="tlf" class="form-text"> Show Telephone</span>
                            <input type="radio" value="1" v-model="showTlf" />&nbsp;&nbsp;
                        </label>
                        <label class="radio-inline col-6">
                            <span id="tlf" class="form-text"> Hide Telephone</span>
                            <input type="radio" value="0" v-model="showTlf" />
                        </label>
                    </div>
                    <div class="col-12">
                        <br />
                        <button type="submit" class="btn btn-success">UPDATE</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader';
import { mapGetters } from 'vuex';
export default {
    name: 'ShowPDFOptionsInvo',
    components: {
        Loader,
    },
    data () {
        return {
            isLoading: false,
            showEmail: 0,
            showTlf: 0,
        };
    },
    created () {
        this.getStart();
    },
    methods: {
        getStart () {
            this.isLoading = true;
            window.billing
                .get('showopcpdfinvoice/' + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.showEmail = response.data.show_email;
                    this.showTlf = response.data.show_tlf;
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        saveShowPDFOptionsInvo () {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('updateshowopcpdfinvoice', {
                    user_id: window.localuserdata,
                    show_email: this.showEmail,
                    show_tlf: this.showTlf,
                })
                .then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Updated',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'}); 
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error, (this.isLoading = false))
                });
        },
    },
    computed: { },
};
</script>
