<template>
    <div class="container-fluid estproForm">
        <div v-show="isLoading" class="loading"><Loader></Loader></div>
        <div class="lockStartNumber"
            v-show="start_estimate"
            v-bind:class="{ show: start_estimate }"
        >
            <startNumber
                v-show="start_estimate"
                @setChildEmitStartNumber="getParentEmitStartNumber($event)"
                :updateNum="updateStartNumeber"
            ></startNumber>
        </div>
        <div class="card formEst">
            <div class="card-body body-form">
                <div class="row">
                    <div class="col-4 text-left">
                        <h4 class="card-title">
                            {{ form.titlePage }} #
                            <span v-if="form.numdoc!=null">{{ form.numdoc }}</span>
                            <span v-else>0000</span>
                        </h4>
                    </div>
                    <div class="col-8">
                        <div class="botonera text-right">
                                <button type="button"
                                    class="btn btn-success btn-sm"
                                    @click="saveEstimates"
                                    :disabled="saveDisabled"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary btn-sm"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: 'estpro' })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="deleteEstimate(form.idEst)"
                                    v-if="form.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-6">
                        <div class="form-group">
                            <label for="customerInput" style="position: relative;width: 100%;display: block;">
                                Customer
                                <span style="position: absolute;right: 0;top: 3px;">
                                    <button
                                        type="button"
                                        @click="NewCustomer"
                                        class="btnlink"
                                    >
                                        <i class="fas fa-plus"></i>
                                        New Customer
                                    </button>
                                </span>
                            </label>
                            <model-select
                                id="customerInput"
                                class="customerInput"
                                ref="customerInput"
                                :options="CustomersList"
                                v-model="selectedCustomer"
                                placeholder="Choose a Customer"
                                tabindex="1"
                                required="true"
                            >
                            </model-select>
                        </div>
                        <div class="form-group">
                            <div class="form-group bill_to">
                                <div v-if="selectedCustomer.value!=null && selectedCustomer.value!=0">
                                    <div>
                                        <span>
                                            {{ selectedCustomer.customer_name }}
                                        </span>
                                        <span>
                                            {{ selectedCustomer.customer_lastname}}
                                        </span>
                                    </div>
                                    <div v-if="selectedCustomer.bussinesname!=null && selectedCustomer.bussinesname!='' && selectedCustomer.bussinesname!='null'">
                                        {{ selectedCustomer.bussinesname }}
                                    </div>
                                    <div v-if="selectedCustomer.billing_address!='' && selectedCustomer.billing_address!=null">
                                        {{selectedCustomer.billing_address}}
                                    </div>
                                    <div
                                        v-if="selectedCustomer.city!=null ||
                                        selectedCustomer.state!=null ||
                                        selectedCustomer.code!=null 
                                        "
                                    >
                                        <span v-if="selectedCustomer.city!=null" style="margin-right:5px;">{{selectedCustomer.city}}</span>
                                        <span v-if="selectedCustomer.state!=null" style="margin-right:5px;">{{ selectedCustomer.state }}</span>
                                        <span v-if="selectedCustomer.code!=null" style="margin-right:5px;">{{ selectedCustomer.code }}</span>
                                    </div>
                                    <div>
                                        <span v-if="selectedCustomer.customer_phone!=null">
                                            {{ selectedCustomer.customer_phone }}
                                        </span>
                                        <span v-if="selectedCustomer.customer_officephone!=null">
                                            <span v-if="selectedCustomer.customer_phone!=null"> / </span>
                                            {{ selectedCustomer.customer_officephone }}
                                        </span>
                                    </div>
                                    <div v-if="selectedCustomer.email!=null">
                                        {{ selectedCustomer.email }}
                                    </div>
                                </div>
                                <label v-else for="customerInput" style="position: relative;width: 100%;display: block;color:#dee2e6;">
                                    Name, Last name<br>
                                    Company name<br>
                                    Billing Address<br>
                                    City, Estate, Zip Code<br>
                                    Cell Phone / Office Phone<br>
                                    Email Address
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6">
                        <div class="form-group">
                            <label for="nameEstInput">Estimate Name <small>(Optional)</small></label>
                            <input
                                type="text"
                                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                :disabled="disabledSteps.customer"
                                id="nameEstInput"
                                maxlength="60"
                                v-model="form.estimates_optional"
                                placeholder="Document name"
                                />
                        </div>
                        <div class="form-group showtooltip">
                            <!-- <label for="job_information" v-if="jobinfomationcheck">{{ jobinfomationtitle }} <small>(Optional)</small></label> -->
                            <textarea v-model="form.job_information"
                                id="job_information"
                                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                maxlength="200"
                                @keypress="noEnter"
                                :disabled="!jobinfomationcheck || disabledSteps.customer"
                                :placeholder="jobinfomationtitle+': '+((jobinfomationcheck) ? 'Let your client know what this Estimate is for' : 'Field disabled, set in settings')"
                            ></textarea>
                            <span class="maxSize">
                                {{ (form.job_information!=null) ? form.job_information.length : 0 }}/200
                            </span>
                            <!-- <span class="tooltip">prueba de tootlip</span> -->
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 date-status" >
                        <div class="row">
                            <div class="form-group col-lg-12 col-md-6">
                                <!-- <label for="datedocInput">Document Date</label> -->
                                <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                                    {{ form.date_created | moment("MMM DD, YYYY")}}
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.date_created"
                                    required="true"
                                    disabled
                                    style="display:none;"
                                />
                            </div>
                            <div class="form-group col-lg-12 col-md-6">
                                <!-- <label for="statusInput">Status</label> -->
                                <div class="fillStatus">
                                    <span class="cdropdown" v-if="disabledSteps.customer">
                                        <button type="button" class="btn-status disabled">
                                            <span style="">Disabled</span>
                                        </button>
                                    </span>
                                    <span class="cdropdown" v-else>
                                        <button type="button" 
                                            :class="['btn-status',
                                            (form.status==1) ? 'pending' :
                                                (form.status==2) ? 'accepted' :
                                                    (form.status==3) ? 'rejected' :
                                                        (form.status==4) ? 'void' :
                                                            (form.status==5) ? 'signed' : 'inactivo'
                                            ]"
                                        >
                                            <span v-for="(i, idx) in statusdata" :key="idx" :style="(form.status!=i.value) ? 'display:none;' : ''">
                                                {{ i.name }}
                                            </span>
                                        </button>
                                        <label>
                                            <input type="checkbox" id="statusInput"/>
                                            <ul class="actions-status">
                                                <li v-for="(i, idx) in statusdata"
                                                    :key="idx"
                                                    :style="(form.status!=i.value && i.value!=5 && i.value!=4) ? '' : 'display:none;'"
                                                    @click="form.status=i.value"
                                                >
                                                    {{ i.name }}
                                                </li>
                                            </ul>
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row">
                    <div class="col-12  text-right" style="padding-right: 100px;">
                        <div class="form-group row" style="width:300px;display: inline-flex;align-content: center;align-items: center;">
                            <label class="col-4" for="Group">Group</label>
                            <select class="form-control col-4" v-model="groupShow" id="Group">
                                <option :value="0">None</option>
                                <option :value="1">Show</option>
                            </select>
                        </div>
                        <div class="form-check form-check-inline ">
                            <label class="form-check-label" for="taxShow">
                                <input class="form-check-input"
                                    type="checkbox"
                                    v-model="taxShow"
                                    id="taxShow"
                                    :value="1"
                                    :disabled="disabledSteps.customer"
                                /> Show Taxes
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row">
                    <div class="table-productos">
                        <table class="table table-striped">
                            <thead style="background-color: #6b6b6b">
                                <tr>
                                    <th style="color: #fff;">Product / Service</th>
                                    <!-- <th style="color: #fff;">Descripction</th> -->
                                    <th style="color: #fff;" class="text-right">Quantity</th>
                                    <th style="color: #fff;" class="text-right">Unit Cost</th>
                                    <th v-if="taxShow==1" style="color: #fff;" class="text-center">Tax</th>
                                    <th style="color: #fff;" class="text-right">Price</th>
                                </tr>
                            </thead>
                            <draggable
                                v-model="itemProducts"
                                tag="tbody"
                                class="tbody"
                                v-bind="dragOptions"
                                @start="isDragging(true)"
                                @end="isDragging(false)"
                                handle=".handle"
                            >
                                <template v-for="(item, index) in itemProducts">
                                    <tr v-if="item.model.value!=idseparador && item.model.value!=idcontinue && item.model.value!=idGroup"
                                        :class="['tr-products item-porduct-' + index, 'tr-product-'+item.model.value]"
                                        :key="index"
                                    >
                                        <td class="itemAndDescrip" style="">
                                            <div class="btnmove handle" style="line-height: 50px;">
                                                <!-- <i class="fas fa-ellipsis-v"></i> -->
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                                            </div>
                                            <button type="button"
                                                @click="NewProducts(item, index)"
                                                :class="['btnAddItem', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                title="New Product / Service"
                                            >+</button>
                                            <model-select :options="item.options"
                                                v-model="itemProducts[index].model"
                                                placeholder="Choose a Products" 
                                                :class="['productos', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                            ></model-select>
                                            <div class="descripcion">
                                                <textarea
                                                    :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                                    :disabled="disabledSteps.customer"
                                                    placeholder="Product description"
                                                    @input="continueDes(index, 300)"
                                                    v-model="itemProducts[index].model.descripcion"
                                                ></textarea>
                                                <span class="maxSize">
                                                    {{ (item.model.descripcion!=null) ? item.model.descripcion.length : 0 }}/300
                                                </span>
                                            </div>
                                        </td>
                                        <td :class="['quantity', 'quantity-' + index, 'showtooltip']">
                                            <input type="number"
                                                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                placeholder="E.g. 1.00"
                                                v-model="item.model.qty"
                                                step="0.01"
                                                min="0"
                                                max="100000"
                                                @keypress="quantityIsInteger"
                                                @change="isInterger"
                                                @keyup="lengMax($event, 8)"
                                            />
                                            <span class="tooltip">Error, wrong format, maximum 2 decimals.</span>
                                        </td>
                                        <td :class="['cost', 'cost-' + index, 'showtooltip']">
                                            <input type="number"
                                                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                placeholder="E.g. 1.00"
                                                v-model="item.model.unitcost"
                                                step="0.01"
                                                min="0"
                                                max="999999999.99"
                                                @keypress="quantityIsInteger"
                                                @change="isInterger"
                                                @keyup="lengMax($event, 12)"
                                            />
                                            <span class="tooltip">Error, wrong format, maximum 2 decimals.</span>
                                        </td>
                                        <td class="taxselect" v-if="taxShow==1">
                                            <select class="form-control" v-model="item.model.taxOpc">
                                                <template v-for="(t, taxIdx) in item.taxlistOption">
                                                    <option :key="taxIdx+'index'" :value="t" v-if="t!=0">
                                                        {{ t.initialstate }} {{ t.name }}
                                                    </option>
                                                    <option :key="taxIdx" :value="t" v-else>
                                                        {{ t.initialstate }} {{ t.name }}
                                                    </option>
                                                </template>
                                            </select>
                                        </td>
                                        <td class="importe" >
                                            <strong>{{ item.model.prices | formatMoney }}</strong>
                                            <span class="btndelete" v-if="validRemoveItem" @click="removeItem(index)">x</span>
                                        </td>
                                    </tr>
                                    <tr v-else-if="item.model.value==idGroup"
                                        :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-'+item.model.value, (groupShow==0) ? 'tr-none' : '']"
                                        :key="index"
                                    >
                                        <td :colspan="(taxShow==1) ? '5' : '4'" class="item-separador-header group" >
                                            <button type="button"
                                                @click="addLineFromGroup(index)"
                                                :class="['btnAddItem', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                title="Add item"
                                            ><i class="fas fa-level-up-alt"></i></button>
                                            <input type="text"
                                                placeholder="Text Line"
                                                maxlength="126"
                                                required
                                                :class="['form-control name-group', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                v-model="itemProducts[index].model.descripcion"
                                            />
                                            <span class="maxSize">
                                                {{ (item.model.descripcion!=null) ? item.model.descripcion.length : 0 }}/126
                                            </span>
                                            <div class="totalGroup">{{ totalGroup(index) }}</div>
                                            <span class="btndelete" v-if="validRemoveGroup && index>0" @click="removeItem(index)">x</span>
                                        </td>
                                    </tr>
                                    <tr v-else-if="itemProducts[index].model.value==idcontinue"
                                        :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-'+item.model.value]"
                                        :key="index"
                                    >
                                        <td class="itemAndDescrip">
                                            <div class="btnmove handle" style="line-height: 50px;">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                                            </div>
                                            <div class="descripcion">
                                                <textarea
                                                    :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                                    :disabled="disabledSteps.customer"
                                                    placeholder="Product description"
                                                    @input="continueDes(index, 300)"
                                                    v-model="itemProducts[index].model.descripcion"
                                                ></textarea>
                                                <span class="maxSize">
                                                    {{ (item.model.descripcion!=null) ? item.model.descripcion.length : 0 }}/300
                                                </span>
                                            </div>
                                        </td>
                                        <td class="quantity"></td>
                                        <td class="cost"></td>
                                        <td class="taxselect" v-if="taxShow==1"></td>
                                        <td class="importe">
                                            <span class="btndelete" v-if="itemProducts.length>1" @click="removeItem(index)">x</span>
                                        </td>
                                    </tr>
                                    <tr v-else
                                        :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-'+item.model.value]"
                                        :key="index"
                                    >
                                        <td :colspan="(taxShow==1) ? '5' : '4'" class="item-separador-header" >
                                            <div class="btnmove handle" style="line-height: 50px;">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                                            </div>
                                            <input type="text"
                                                placeholder="Text Line"
                                                maxlength="130"
                                                required
                                                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                                :disabled="disabledSteps.customer"
                                                v-model="itemProducts[index].model.descripcion"
                                            />
                                            <span class="maxSize">
                                                {{ (item.model.descripcion!=null) ? item.model.descripcion.length : 0 }}/130
                                            </span>
                                            <span class="btndelete" v-if="itemProducts.length>1" @click="removeItem(index)">x</span>
                                        </td>
                                    </tr>
                                </template>
                            </draggable>
                            <tfoot>
                                <tr>
                                    <th :colspan="(taxShow==1) ? '5' : '4'" class="text-left">
                                        <button
                                            type="button"
                                            :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            style="margin-right: 25px;"
                                            @click="addNewLine"
                                        >
                                            Add line
                                        </button>
                                        <button
                                            type="button"
                                            :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            style="margin-right: 25px;"
                                            @click="lineSeparator"
                                        >
                                            Add Text Line
                                        </button>
                                        <!-- <button
                                            type="button"
                                            :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            @click="continueDes"
                                        >
                                            continueDes
                                        </button> -->
                                        <button
                                            v-if="groupShow==1"
                                            type="button"
                                            :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            @click="addNewLineWithGroup"
                                        >
                                            Add Group
                                        </button>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row">
                    <div class=" col-5 form-group col-customer_message" style="display: flex;flex-direction: column;justify-content: flex-end;margin-bottom: 0;">
                        <label for="customer_message">Customer Message <small>(Optional)</small></label>
                        <textarea
                            id="customer_message"
                            :class="['form-control customer_message', (disabledSteps.customer) ? 'disabled' : '']"
                            :disabled="disabledSteps.customer"
                            v-model="form.customer_message"
                            placeholder="We hope to continue doing business with you."
                            maxlength="140"
                        ></textarea>
                        <span class="maxSize">
                            {{ (form.customer_message!=null) ? form.customer_message.length : 0 }}/150
                        </span>
                    </div>
                    <div class="col-7">
                        <div class="totales-resumen">
                            <table style="width:100%;">
                                <tr>
                                    <td style=" width: 70px;">Subtotal:</td>
                                    <td ></td>
                                    <td style="text-align: right;">
                                        {{ subTotal | formatMoney }}
                                        <input type="hidden" class="form-control" v-model="form.sub_Total" />
                                    </td>
                                </tr>
                                <tr v-if="taxShow==1 && taxesResumen.length>0">
                                    <td style=" width: 70px; vertical-align: top;">Taxes:</td>
                                    <td colspan="2" style="text-align: right;">
                                        <table style="width:100%;">
                                            <tr v-for="(iva, index) in taxesResumen" :key="index">
                                                <td colspan="2">
                                                    <table width="100%">
                                                        <tr v-if="iva.total > 0">
                                                            <td align="left">
                                                                <div style="padding-right: 10px;">
                                                                    <b>
                                                                        <span>{{ iva.name }}</span>
                                                                        ({{ iva.porcentaje }}%)
                                                                    </b>
                                                                </div>
                                                            </td>
                                                            <td align="right">
                                                                {{ Number(iva.total) | formatMoney }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom:10px;">Discount:</td>
                                    <td style="padding-bottom:10px;width: 200px;">
                                        <input type="text"
                                            :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            style="text-align: right; width:80px;display:inline-block; margin-right: 10px;"
                                            placeholder="E.g. 1.00"
                                            v-model="form.discount_mount"
                                            step="0.01"
                                            min="0"
                                            max="9999999.99"
                                            maxlength="11"
                                            @keypress="quantityIsInteger"
                                            @change="vDiscount"
                                        />
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input"
                                                    type="radio" v-model="form.discount_type"
                                                    :disabled="disabledSteps.customer"
                                                    value="2"
                                                /> $
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input"
                                                    type="radio" v-model="form.discount_type"
                                                    :disabled="disabledSteps.customer"
                                                    value="1"
                                                /> %
                                            </label>
                                        </div>
                                    </td>
                                    <td class="discountTotal" :rowspan="(discount > 0) ? 2 : 1">
                                        {{ (discount * -1) | formatMoney }}
                                    </td>
                                </tr>
                                <tr v-if="discount > 0">
                                    <td colspan="2" class="nameDiscount">
                                        <textarea placeholder="Discount name or description (optional)"
                                            class="form-control" 
                                            style="height: 70px; display: inline-block;width: 280px;"
                                            v-model="form.discount_name"
                                            maxlength="101"
                                            @keyup="lengMax($event, 100)"
                                            @keypress="noEnter"
                                        ></textarea>
                                        <span class="maxSize">
                                            {{ (form.discount_name!=null) ? form.discount_name.length : 0 }}/100
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="border-top: 1px solid #dedede; width: 70px;font-weight: bold;font-size: 18px;padding-top:10px;">Total ( $ )</td>
                                    <td id="total" style="">
                                        {{ total | formatMoney }}
                                        <input type="hidden" class="form-control" v-model="form.total" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row">
                    <div class="pageSignature col-12">
                        <div class="form-inline row" >
                            <div class="col-12 text-left" style="border-bottom: 1px solid #ccc; margin-bottom:20px;">
                                <span style="display:inline-block; margin-right:10px;">Need a Contract?</span>
                                <div class="custom-control custom-checkbox" style="display:inline-block;">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        id="customControlInline"
                                        v-model="form.checked"
                                        :disabled="disabledSteps.customer"
                                    />
                                    <label class="custom-control-label"
                                        for="customControlInline"
                                    >Yes</label>
                                </div>
                            </div>
                            <div v-if="form.checked"
                                class="form-group"
                                style="margin-right: 5px;margin-left: 15px;"
                            >
                                <input type="text"
                                    class="form-control"
                                    style="margin-bottom:0px; "
                                    v-model="selectCTemplate.title"
                                    placeholder="Terms & Conditions"
                                />
                            </div>
                            <div v-if="form.checked" class="form-group">
                                <select v-model="selectCTemplate"
                                    class="form-control my-1 mr-2"
                                    id="exampleFormControlSelect1"
                                    style="width: 300px;margin-top: 10px;"
                                >
                                    <option :value="{title:'Contract Title', description:''}" selected>
                                        Select template
                                    </option>
                                    <option v-for="i in ContractTemLists" :key="i.id" :value="i">
                                        {{ i.title }}
                                        <span v-if="i.alias !== null">- {{ i.alias }}</span>
                                    </option>
                                </select>
                            </div>
                            <div class="my-1 mr-2" v-if="form.checked && PlanPremium">
                                Request Digital Signature?
                            </div>
                            <div v-if="form.checked && PlanPremium" class="custom-control custom-checkbox my-1 mr-sm-2">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="signe"
                                    v-model="form.signe"
                                />
                                <label
                                    class="custom-control-label"
                                    for="signe"
                                    >Yes</label>
                            </div>
                        </div>
                        <vue-editor v-model="selectCTemplate.description" v-if="form.checked"/>
                        <h5 style="padding-top: 20px" v-if="form.checked">
                            Signature:
                        </h5>
                        <div class="row" v-if="form.checked">
                            <div class="col">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Customer</label>
                                    <div class="col-sm-10">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="selectedCustomer.text"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Date</label>
                                    <div class="col-sm-10">
                                        <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                                            {{ FormSignature.dateCustomer | moment("MMM DD, YYYY")}}
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormSignature.dateCustomer"
                                            required="true"
                                            disabled
                                            style="display:none;"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Company</label>
                                    <div class="col-sm-10">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormSignature.nameCompany"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        for=""
                                        class="col-sm-2 col-form-label"
                                        >Date</label
                                    >
                                    <div class="col-sm-10">
                                        <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                                            {{ FormSignature.dateCompany | moment("MMM DD, YYYY")}}
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="FormSignature.dateCompany"
                                            required="true"
                                            disabled
                                            style="display:none;"
                                        />
                                        <!-- <datetime
                                            type="date"
                                            v-model="FormSignature.dateCompany"
                                            value-zone="America/New_York"
                                            input-class="form-control"
                                        ></datetime> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row" style="margin-top: 20px;">
                    <div class="col-4 text-left">
                    </div>
                    <div class="col-8">
                        <div class="botonera text-right">
                                <button type="button"
                                    class="btn btn-success btn-sm"
                                    @click="saveEstimates"
                                    :disabled="saveDisabled"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary btn-sm"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: 'estpro' })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="deleteEstimate(form.idEst)"
                                    v-if="form.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer formEst text-muted"></div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import Loader from '@/core/components/shared/Loader';
import startNumber from '@/apps/billing/components/estpro/startNumber';
import formCustomer from '@/apps/billing/components/formCustomer';
import { ModelSelect } from 'vue-search-select';
import draggable from 'vuedraggable';
import formProducts from '@/apps/billing/components/formProducts'
import { VueEditor } from 'vue2-editor'
import './form.scss';
export default {
    name: "estproForm",
    components: {
        Loader,
        modalLateral,
        startNumber,
        formCustomer,
        ModelSelect,
        draggable,
        formProducts,
        VueEditor,
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },
            disabledSteps:{
                startNumer: true,
                customer: true,
                nameEst: true,
                jobsInfo: true,
                products: true,
            },
            isLoading: true,
            PlanPremium: false,
            freePlan: false,
            start_estimate: false,
            updateStartNumeber: null,
            form: {
                idEst: null,
                titlePage: "Add Estimate",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                numdoc: null,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                estimates_optional: null,
                status: 1,
                accepted_by: null,
                accepted_date: null,
                job_information: null,
                customer_message: null,
                date_created: new Date(),
                subtotal: 0,
                discount_type: 2,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                totaltax: null,
                qty: null,
                checked: false,
                signe: false,
                dataItems: [],
            },
            itemProducts: [],
            CustomersList: [],
            selectedCustomer: {
                value: 0,
                text: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_lastname: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
                city: null,
                state: null,
                code: null,
            },
            jobinfomationcheck: true,
            jobinfomationtitle: "Job information",
            ProdServiceLists: [],
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: 0,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 0,
                description: null,
                applytax: 0,
                status: 0,
            },
            TypeofAccount: [],
            TaxesLists: [],
            taxesResumen: [],
            taxShow: 0,
            groupShow: 0,
            groupItem:[],
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Accepted" },
                { value: "3", name: "Rejected" },
                { value: "4", name: "Void" },
                { value: "5", name: "Signed" },
            ],
            idseparador: 999999999,
            idcontinue: 999999998,
            idGroup: 999999997,
            productDefault: {
                value: 0,
                text: "Uncategorized",
                description: "",
                unitcost: "1.00",
                qty: "1.00",
                taxOpc: {
                    id: 0,
                    name: 'None',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                },
                applytax: 0,
                prices: 0
            },
            taxDefault: {
                id: 0,
                name: 'None',
                porcentaje: '',
                initialstate: '',
                statename: '',
                count: 0,
                total: 0,
            },
            saveDisabled: false,
            ContractTemLists: [],
            selectCTemplate: {
                title: "Contract Title",
                description: "",
            },
            FormSignature: {
                //nameCustomer: null,
                dateCustomer: new Date(),
                dateCompany: new Date(),
                nameCompany: localStorage.getItem("nomCompany"),
            },
        };
    },
    directives: {
        ClickOutside,
    },
    created () {
        let t = this;
        t.PlanPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
        t.freePlan = (t.$store.getters['core/Org/getPlanId'] == 4);
        if(!t.PlanPremium){
            t.$router.push({ name: "Estimates" });
        }else{
            localStorage.setItem('groupItem', JSON.stringify(t.groupItem));
            localStorage.setItem('productDefault', JSON.stringify(t.productDefault));
            localStorage.setItem('taxDefault', JSON.stringify(t.taxDefault));
            t.metstartEstimate().then((start) => {
                t.getEstimateSetting();
                t.getAccountTypes();
                t.getCTemplates();
                t.getCustomer().then((resulcustomers) => {
                    console.log('continue');
                    t.getTaxes().then((resulTaxes) => {
                        t.getProdservices().then((resulProd) => {
                            let params = Object.keys(t.$route.params);
                            if (t.$route.name=='estproFormEdit' && t.$route.params.idest && params.length==1) {
                                t.form.titlePage = "Edit Estimate";
                                t.form.actionNname = "update";
                                t.EstimateById(1);
                                t.saveDisabled=true;
                            }
                            else if (t.$route.name=='estproFormDuplicate' && t.$route.params.idest && params[1]=='duplicate') {
                                t.form.titlePage = "Duplicate Estimate";
                                t.form.actionNname = "duplicate";
                                t.EstimateById(2);
                                t.saveDisabled=true;
                                t.isLoading = false;
                            }
                            else if (t.$route.name=='estproFormcustomerID' && t.$route.params.idc) {
                                // t.selectBillTo(t.$route.params.idc)
                                localStorage.removeItem('CustomersList');
                                localStorage.removeItem('ProdServiceLists');
                                t.getCustomer(t.$route.params.idc).then((idc) => {
                                    // t.getProdservices();
                                })
                                t.saveDisabled=true;
                                t.isLoading = false;
                            }
                            else if (t.form.actionNname == "create") {
                                t.$refs.customerInput.openOptions();
                                t.saveDisabled=true;
                                t.isLoading = false;
                            }
                        });
                    });
                });
            });
        }
    },
    methods: {
        metstartEstimate() {
            let t = this;
            return new Promise((resolve, reject) => {
                window.billing.get("startestimate/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.start_estimate == false) {
                        // no ha especificado el numero inicial de estimate
                        // abrir modal para config num start
                        t.start_estimate = true;
                    }else{
                        // ya ha sido configurado
                        t.disabledSteps.startNumer=false;
                    }
                    if (response.data.result!=null) {
                        // obtener valor actual
                        //t.updateStartNumeber = response.data.result.meta_value;
                        //t.start_estimate = true;
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
            });
        },
        getParentEmitStartNumber(value){
            this.start_estimate=value;
            this.disabledSteps.startNumer=false;
        },
        getEstimateSetting() {
            window.billing
            .get("estimates/setting/" + window.localuserdata)
            .then((response) => {
                // console.log(response.data.result);
                if (response.data.result.length) {
                    let r = response.data.result[0];
                    this.jobinfomationcheck = r.jobinfomationcheck;
                    this.jobinfomationtitle = r.jobinfomationtitle;
                    this.form.job_information = r.jobinfomationtext;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getAccountTypes() {
            window.billing
            .get("chart-account-list/" + window.localuserdata)
            .then((response) => {
                this.TypeofAccount = response.data.result;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCTemplates() {
            window.billing
            .get("contracttemplate/" + window.localuserdata)
            .then((response) => {
                console.log("template estimates list");
                console.log(response.data);
                this.ContractTemLists = response.data.result;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        validDateUpdateStorage(varDate, varStorage){
            if(localStorage.getItem(varDate)!=null){
                let dateStorage = new Date(localStorage.getItem(varDate, varStorage));
                let dateActual = new Date();
                var fecha1 = moment(dateActual);
                var fecha2 = moment(dateStorage);
                let minutes = fecha1.diff(fecha2, 'minutes');
                if(minutes>5){
                    localStorage.removeItem(varStorage);
                }
            }
        },
        setDateStorage(varDate){
            let fecha = new Date().getFullYear()+'/'+(new Date().getMonth()+1)+'/'+new Date().getDate();
            let hora = new Date().getHours()+':'+new Date().getMinutes()+':0';
            let dateStorage = fecha+' '+hora;
            localStorage.setItem(varDate, dateStorage);
        },
        getCustomer(idc = 0) {
            let t = this;
            return new Promise((resolve, reject) => {
                t.validDateUpdateStorage('CustomersDate', 'CustomersList');
                if(localStorage.getItem('CustomersList')==null){
                    window.billing
                    .get("customers/" + window.localuserdata)
                    .then((response) => {
                        const Customers = [];
                        // console.log(response.data.result);
                        let result = response.data.result;
                        if (result) {
                            for (let item in result) {
                                var id = result[item].id;
                                var text = result[item].firstname;
                                var billing_address =
                                    result[item].billingaddress;
                                var customer_address = result[item].address;
                                var bussinesname = result[item].bussinesname;
                                var email = result[item].email;
                                var lastname = result[item].lastname;
                                var customer_phone = result[item].mobilephone;
                                var customer_officephone = result[item].officephone;
                                var city = result[item].bcity;
                                var state = result[item].bstate;
                                var code = result[item].bpostcode;
                                city = city != null ? city + "," : "";
                                state = state != null ? state : "";
                                code = code != null ? code : "";
                                var guion = " - ";
                                this.form.customer_email = email;
                                if (bussinesname == null) {bussinesname = "";guion = " ";}
                                if (lastname == null) {lastname = "";}
                                let newCustomer ={
                                    text: text + " " + lastname + guion + bussinesname,
                                    value: id,
                                    billing_address: billing_address,
                                    bussinesname: bussinesname,
                                    customer_address: customer_address,
                                    customer_name: text,
                                    customer_lastname: lastname,
                                    customer_phone: customer_phone,
                                    customer_officephone: customer_officephone,
                                    email: email,
                                    city: city,
                                    state: state,
                                    code: code,
                                };
                                Customers.push(newCustomer);
                                if(idc>0 && idc==id) {t.selectedCustomer=newCustomer;}
                            }
                        }
                        t.CustomersList = Customers;
                        localStorage.setItem('CustomersList', JSON.stringify(t.CustomersList));
                        t.setDateStorage('CustomersDate');
                        if (idc > 0) {
                            t.isLoading = false;
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
                }else{
                    t.CustomersList = JSON.parse(localStorage.getItem('CustomersList'));
                if(localStorage.getItem('CustomersDate')==null){
                    t.setDateStorage('CustomersDate');
                }
                    resolve(true);
                }
            });
        },
        NewCustomer() {
            let t = this;
            this.m = {
                show: true,
                component: 'formCustomer',
                data: {}
            }
            this.$store.commit('core/setOpenModal', this.m);
        },
        selectBillTo(idc) {
            for (let item in this.CustomersList) {
                let id = this.CustomersList[item].value;
                if (idc > 0 && idc == id) {
                    this.selectedCustomer = {
                        text: this.CustomersList[item].text,
                        value: this.CustomersList[item].value,
                        billing_address: this.CustomersList[item].billing_address,
                        bussinesname: this.CustomersList[item].bussinesname,
                        customer_address: this.CustomersList[item].customer_address,
                        customer_name: this.CustomersList[item].customer_name,
                        customer_lastname: this.CustomersList[item].customer_lastname,
                        customer_phone: this.CustomersList[item].customer_phone,
                        customer_officephone: this.CustomersList[item].customer_officephone,
                        email: this.CustomersList[item].email,
                        city: this.CustomersList[item].city,
                        state: this.CustomersList[item].state,
                        code: this.CustomersList[item].code,
                    };
                }
            }
            // console.log("customer seleccionado", this.selectedCustomer);
        },
        NewProducts(item, index) {
            let t = this;
            t.FormAddPS.index_modal = index;
            t.m = {
                show: true,
                component: 'formProducts',
                data: {
                    FormAddPS: t.FormAddPS,
                    TypeofAccount: t.TypeofAccount,
                    TaxesLists: t.TaxesLists,
                    formList: t.itemProducts,
                }
            }
            t.$store.commit('core/setOpenModal', t.m);
        },
        getTaxes() {
            let t = this;
            return new Promise((resolve, reject) => {
                if (localStorage.getItem('TaxesLists') == null) {
                    window.billing.get("taxes/" + window.localuserdata)
                        .then((response) => {
                            console.log("taxes request all");
                            //console.log(response.data)
                            //this.TaxesLists = response.data;
                            const ObjTaxes = [JSON.parse(localStorage.getItem('taxDefault'))];
                            if (response.data) {
                                for (let item in response.data) {
                                    var id = response.data[item].id;
                                    var initialstate = response.data[item].initialstate;
                                    var name = response.data[item].name;
                                    var state_id = response.data[item].state_id;
                                    var statename = response.data[item].statename;
                                    var status = response.data[item].status;
                                    var user_id = response.data[item].user_id;
                                    ObjTaxes.push({
                                        id: id,
                                        initialstate: initialstate,
                                        name: name,
                                        state_id: state_id,
                                        statename: statename,
                                        status: status,
                                        user_id: user_id,
                                        default: "none",
                                    });
                                    t.taxesResumen.push({
                                        id: id,
                                        name: initialstate,
                                        porcentaje: name,
                                        initialstate: initialstate,
                                        statename: statename,
                                        count: 0,
                                        total: 0,
                                    });
                                    console.log('taxesResumen', t.taxesResumen)
                                }
                            }
                            t.TaxesLists = ObjTaxes;
                            localStorage.setItem('TaxesLists', JSON.stringify(this.TaxesLists));
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    t.TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
                    if (t.TaxesLists) {
                        for (let item in t.TaxesLists) {
                            var id = t.TaxesLists[item].id;
                            var initialstate = t.TaxesLists[item].initialstate;
                            var name = t.TaxesLists[item].name;
                            var state_id = t.TaxesLists[item].state_id;
                            var statename = t.TaxesLists[item].statename;
                            var status = t.TaxesLists[item].status;
                            var user_id = t.TaxesLists[item].user_id;
                            t.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                            console.log('taxesResumen', t.taxesResumen)
                        }
                    }
                    console.log('TaxesLists localStorage');
                    resolve(true);
                }
            });
        },
        getProdservices(load) {
            let t = this;
            return new Promise((resolve, reject) => {
                t.validDateUpdateStorage('ProdServiceListsDate', 'ProdServiceLists');
                if(localStorage.getItem('ProdServiceLists')==null){
                    window.billing
                    .get("product-services/" + window.localuserdata)
                    .then((response) => {
                        console.log("product sevice list table");
                        const ProdList = [JSON.parse(localStorage.getItem('productDefault'))];
                        if (response.data.result) {
                            for (let item in response.data.result) {
                                var id = response.data.result[item].id;
                                var prodname = response.data.result[item].productname;
                                var quantity = response.data.result[item].quantity;
                                var unitcost = response.data.result[item].unitcost;
                                var descripcion = response.data.result[item].description;
                                var applytax = response.data.result[item].applytax;
                                let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'))
                                let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
                                for (let i in TaxesLists) {
                                    if (
                                        TaxesLists[i].id ==
                                        response.data.result[item].applytax
                                    ) {
                                        taxOpcID = TaxesLists[i];
                                    }
                                }
                                ProdList.push({
                                    value: id,
                                    text: prodname,
                                    unitcost: unitcost,
                                    descripcion: descripcion,
                                    qty: quantity,
                                    taxOpc: taxOpcID,
                                    applytax: applytax,
                                });
                            }
                        }
                        t.ProdServiceLists = ProdList;
                        localStorage.setItem('ProdServiceLists', JSON.stringify(this.ProdServiceLists));
                        t.setDateStorage('ProdServiceListsDate');
                        console.log("ProdList ", this.ProdServiceLists);
                        if(load==undefined){
                            if (t.$route.name == "estproForm" || t.$route.name =="estproFormcustomerID") {
                                t.addNewLinePrimary();
                                t.isLoading = false;
                            }
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
                }else{
                    t.ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
                    if(localStorage.getItem('ProdServiceListsDate')==null){
                        t.setDateStorage('ProdServiceListsDate');
                    }
                    console.log('ProdServiceLists localStorage');
                    if(load==undefined){
                        if (this.$route.name == "estproForm" || t.$route.name =="estproFormcustomerID") {
                            t.addNewLinePrimary();
                            t.isLoading = false;
                        }
                    }
                    resolve(true);
                }
            });
        },
        getModelProductServ(){
            let t = this;
            let ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            const ProdList = [];
            for (let item in ProdServiceLists) {
                var id = ProdServiceLists[item].value;
                var prodname = ProdServiceLists[item].text;
                var quantity = ProdServiceLists[item].qty;
                var unitcost = ProdServiceLists[item].unitcost;
                var descripcion = ProdServiceLists[item].descripcion;
                var applytax = ProdServiceLists[item].applytax;
                let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
                for (let i in TaxesLists) {
                    if (TaxesLists[i].id == ProdServiceLists[item].applytax) {
                        taxOpcID = TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            return ProdList;
        },
        addNewLinePrimary() {
            let t = this;
            if (t.form.actionNname == "create") {
                t.addGroup();
                let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
                let ProdList = t.getModelProductServ();
                t.itemProducts.push({
                    options: ProdList,
                    taxlistOption: TaxesLists,
                    model: JSON.parse(localStorage.getItem('productDefault')),
                });
                localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
            }
        },
        addGroup() {
            let t = this;
            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts')); // traemos
            let itemGroup = {
                value: t.idGroup,
                text: "",
                descripcion: "Uncategorized Group",
                unitcost: 0,
                qty: 0,
                taxOpc: taxOpcID,
                applytax: 0,
                prices: 0
            };
            itemProducts.push({
                options: [itemGroup],
                taxlistOption: [],
                model: itemGroup,
            });
            t.itemProducts = itemProducts;
            groupItem[t.itemProducts.length-1]=itemGroup;
            localStorage.setItem('groupItem', JSON.stringify(groupItem)); // guardamos nueva linea
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts)); // guardamos nueva linea
        },
        addNewLineWithGroup() {
            let t = this;
            t.addGroup();
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            t.itemProducts.push({
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            });
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        addLineFromGroup(index){
            let t = this;
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
            let newitem = {
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            };
            this.itemProducts.splice(index+1, 0, newitem);
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        lineSeparator() {
            let t = this;
            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts')); // traemos
            itemProducts.push({
                options: [{
                    value: t.idseparador,
                    text: '',
                    unitcost: 0,
                    descripcion: 'Simple text',
                    qty: 0,
                    taxOpc: taxOpcID,
                    prices: 0
                }],
                taxlistOption: [],
                model: {
                    value: t.idseparador,
                    text: "",
                    descripcion: "Simple text",
                    unitcost: 0,
                    qty: 0,
                    taxOpc: taxOpcID,
                    applytax: 0,
                    prices: 0
                },
            });
            t.itemProducts = itemProducts;
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts)); // guardamos nueva linea
        },
        continueDes(index, limit) {
            let t = this;
            let desc = t.itemProducts[index].model.descripcion;
            if(desc!=null && desc!=''){
                let div = desc.length / limit;
                if(!Number.isInteger(div)){
                    div= Number(div.toFixed(0)) + 1;
                }
                let newDesc = desc.slice(0, limit);
                let desCarry = desc.replace(newDesc, '');
                t.itemProducts[index].model.descripcion = newDesc;
                if(div>1){
                    // console.log('desc div', div)
                    for (let i = 0; i < div; i++) {
                        let aggDesc = desCarry.slice(0, limit);
                        if(aggDesc.length>0){
                            // console.log('desc...', aggDesc)
                            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
                            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
                            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
                            itemProducts.push({
                                options: [{
                                    value: t.idcontinue,
                                    text: '',
                                    unitcost: 0,
                                    descripcion: aggDesc,
                                    qty: 0,
                                    taxOpc: taxOpcID,
                                    prices: 0
                                }],
                                taxlistOption: [],
                                model: {
                                    value: t.idcontinue,
                                    text: "",
                                    descripcion: aggDesc,
                                    unitcost: 0,
                                    qty: 0,
                                    taxOpc: taxOpcID,
                                    applytax: 0,
                                    prices: 0
                                },
                            });
                            this.itemProducts = itemProducts;
                            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
                            desCarry = desCarry.replace(aggDesc, '');
                        }
                    }
                }
            }
        },
        addNewLine() {
            let t = this;
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
            itemProducts.push({
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            });
            this.itemProducts = itemProducts;
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        removeItem(index) {
            this.itemProducts.splice(index, 1);
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        noEnter(e){
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                return e.preventDefault();
            }
        },
        delLineBreaks(e){
            let t = this;
            let val =e.target.value;
            if(val!=null && val!=''){
                let breakLines = (val.match(/\n/g)||[]).length;
                console.log('saltos de linea', breakLines);
                if(breakLines>0) {
                    console.log('remplazar BreaksLine por espacios');
                    let val = val.split('\n');
                    let newVal='';
                    for (let i in val) {
                        if(val[i]){
                            newVal+=val[i];
                            if((i+1) < val.length){newVal+=' ';}
                            console.log('linea add', newVal)
                        }
                    }
                    e.target.value = newVal;
                }
            }
        },
        isDragging(t){
            console.log('move')
            return t;
        },
        formatUSD(amount) {
            let vatIdRegex = new RegExp(/^[0-9.,-]+$/);
            let value = '';
            let val = 0.0;
            if (vatIdRegex.test(amount)) {
                let ops = {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                };
                let numberFormat2 = new Intl.NumberFormat('en-US', ops);
                value = numberFormat2.format(amount)
                // console.log('numberFormat2', numberFormat2.format(amount)); // amount
                if (value.indexOf(".") > -1) {
                    val = value;
                } else {
                    val = value + ".00";
                }
            }
            return val
        },
        isEmptyNaN(val){
            if (isNaN(val)){ return true;}
            else if (val==null){ return true;}
            else if (val==''){ return true;}
            else{ return false;}
        },
        quantityIsInteger(e) {
            var code = (e.which) ? e.which : e.keyCode;
            if((code>=48 && code<=57) || code==46) { // is a number.
                return true;
            } else{ // other keys.
                console.log('other keys', code);
                return e.preventDefault();
            }
        },
        isInterger(e){
            let t=this;
            console.log(e.target.value)
            if (t.isEmptyNaN(e.target.value)) {e.target.value = '0.00';}
            setTimeout(() => {
                t.calcPrices(t.itemProducts);
            }, 500);
            e.target.value = parseFloat(e.target.value.replace(/,/g, "."))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
        },
        lengMax(e, limit) {
            if (e.target.value.length > limit) {
                e.target.value = e.target.value.slice(0, limit);
            }
        },
        vDiscount(e){
            let t=this;
            if (t.isEmptyNaN(e.target.value)) {e.target.value = '0.00';}
            //.replace(/,/g, ".")
            e.target.value= parseFloat(e.target.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
            let val = e.target.value;
            let valResult = val;
            let msj = '';
            let validInt = new RegExp(/^[0-9.]+$/); 
            // si es diferente de numero , retornar cero
            console.log('vDiscount', val)
            if(!validInt.test(val)){
                if(val != null && val!=''){
                    msj="Discount, only numbers allowed.";
                }
                valResult = 0;
            }
            // si es NaN
            else if(t.isEmptyNaN(val)){
                msj="Incorrect amount, you may be adding disallowed symbology.";
                valResult = 0;
            }
            // si es menor de cero, retorna cero
            else if(val < 0){
                msj="Discount cannot be less than zero";
                valResult = 0;
            }
            //validaciones donde descuento es porcentaje y es mayor de 99.99, retornar cero
            else if(this.form.discount_type==1 && val > 0 && val >99.99){
                msj="Discount cannot be greater than 99.99%";
                valResult = 0;
            }
            // validaciones siendo un monto y si es mayor a subtotal, retornar cero
            else if(this.form.discount_type==2 && val > 0 && parseInt(val) >= parseInt(this.form.subtotal)){
                msj="Discount cannot be greater than or equal to the subtotal.";
                valResult = 0;
            }
            if(msj.length > 0){this.msjAlert(msj);}
            // e.target.value = Number(valResult).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return e.target.value;
        },
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },
        ValItemTax(itemProducts, valDiscount, subtotal){
            let t=this;
            //reiniciar totales
            if (t.taxesResumen.length > 0) {
                for (let e in t.taxesResumen) {
                    t.taxesResumen[e].total = 0;
                }
            }
            // calcular totales 
            let obj = itemProducts;
            for (let i in obj) {
                if (obj[i].model.taxOpc != 0) {
                    if (t.taxesResumen.length > 0) {
                        for (let e in t.taxesResumen) {
                            if (t.taxesResumen[e].id == obj[i].model.taxOpc.id) {
                                let unitcost = obj[i].model.unitcost;
                                let qty = obj[i].model.qty;
                                let valTax = obj[i].model.taxOpc.name;
                                if(valDiscount > 0){
                                    //calculamos el valor en % equivalente al subtotal
                                    let defragDiscount= (unitcost / subtotal) * 100;
                                    let defragDiscVal = valDiscount * defragDiscount / 100;
                                    // console.log('descuento en producto', defragDiscVal)
                                    unitcost = unitcost - defragDiscVal
                                    // console.log('new unitcost', unitcost)
                                }
                                let precioTotal = (unitcost * qty);
                                let importeTax = (precioTotal * valTax) / 100;
                                if (!t.isEmptyNaN(importeTax)) {
                                    let TaxTotal = parseFloat(t.taxesResumen[e].total) + parseFloat(importeTax);
                                    t.taxesResumen[e].total = parseFloat(TaxTotal)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, "");
                                    console.log('taxesResumen', t.taxesResumen[e].total)
                                }
                            }
                        }
                    }
                }
            }
        },
        calcPrices(m){
            let t = this;
            let groupIdx = 0;
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            // este metodo se llama desde el watch y el metodo isInterger
            for (let i in m) {
                let qty = m[i].model.qty;
                let unitcost = m[i].model.unitcost;
                if(t.isEmptyNaN(qty)){qty='0.00';}
                if(t.isEmptyNaN(unitcost)){unitcost='0.00';}
                // 'tr-product-'+m[i].model.value
                // item-porduct-' + i
                // if(qty>0 || unitcost>0){
                //     t.itemProducts[i].model.prices = (qty * unitcost);
                // }else{
                //     t.itemProducts[i].model.prices=0;
                // }
                if(t.itemProducts[i].model.value == t.idGroup){
                    groupIdx = i;
                    if(groupItem[groupIdx]){groupItem[groupIdx].prices=0;}
                }else{
                    let precioProduct = 0;
                    if(qty>0 || unitcost>0){
                        precioProduct = (qty * unitcost);
                    }
                    t.itemProducts[i].model.prices=precioProduct;
                    if(groupItem[groupIdx]){
                        groupItem[groupIdx].prices+=precioProduct;
                    }
                }
            }
            localStorage.setItem('groupItem', JSON.stringify(groupItem));
        },
        saveEstimates() {
            this.isLoading = true;
            this.saveDisabled = true;
            this.errors = [];
            if (!this.selectedCustomer.value) {
                //this.errors.push('Must select "Customer"');
                this.msjAlert('Must select "Customer')
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            if (this.form.total == 0) {
                // this.errors.push("Total must not be greater than zero");
                this.msjAlert("Total must not be greater than zero")
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            // if (!this.itemProducts[0].model.value && this.itemProducts[0].model.descripcion.length==0) {
            //     //this.errors.push("Must add at least 1 Product or service");
            //     this.msjAlert("Must add at least 1 Product or service")
            //     this.isLoading = false;
            // }
            for (let i in this.itemProducts) {
                if (
                    this.itemProducts[i].model.qty == "" ||
                    this.itemProducts[i].model.qty == null
                ) {
                    this.itemProducts[i].model.qty = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.isLoading = false
                }
                if (
                    this.itemProducts[i].model.unitcost == "" ||
                    this.itemProducts[i].model.unitcost == null
                ) {
                    this.itemProducts[i].model.unitcost = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.isLoading = false
                }                
            }
            if (this.selectedCustomer.value && this.errors.length == 0) {
                if (
                    this.form.actionNname == "create" ||
                    this.form.actionNname == "duplicate"
                ) {
                    window.billing
                    .post("estimates/add", {
                        user_idd: window.master_client,
                        user_id: window.localorgdata,
                        org_id: window.localorgdata,
                        oauth_m: window.oauth_m,
                        socket_id: pusher.connection.socket_id,
                        customer_id: this.selectedCustomer.value,
                        estimate_optional: this.form.estimates_optional,
                        status: this.form.status,
                        accepted_by: this.form.accepted_by,
                        accepted_date: this.form.accepted_date,
                        job_information: this.form.job_information,
                        customer_message: this.form.customer_message,
                        need_contract: this.form.checked,
                        signe: this.form.signe,
                        terms_conditions_title: this.selectCTemplate.title,
                        terms_conditions_desc: this.selectCTemplate.description,
                        signaturecustomer: this.selectedCustomer.text,
                        signaturecustomerdate: this.FormSignature.dateCustomer,
                        signaturecompany: this.FormSignature.nameCompany,
                        signaturecompanydate: this.FormSignature.dateCompany,
                        date_create: this.form.date_created,
                        sub_total: this.form.subtotal,
                        discount_type: this.form.discount_type,
                        discount_mount: this.form.discount_mount,
                        discount_total: this.form.discount_total,
                        discount_name: this.form.discount_name,
                        total: this.form.total,
                        dataItems: this.itemProducts,
                        taxShow: this.taxShow,
                        type_estimate: 'pro',
                        groupShow: this.groupShow,
                    })
                    .then((response) => {
                        this.isLoading = false;
                        this.saveDisabled = false;
                        this.$notify({
                            group: "noti",
                            title: "Created!",
                            type: "success",
                            text: "Successful registration",
                        });
                        this.$router.push({ name: "estpro" });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                        this.saveDisabled = false;
                    });
                }
                if (this.form.actionNname == "update") {
                    window.billing
                    .put("estimate/edit/" + this.form.idEst, {
                        user_idd: window.master_client,
                        user_id: window.localorgdata,
                        org_id: window.localorgdata,
                        oauth_m: window.oauth_m,
                        socket_id: pusher.connection.socket_id,
                        numdoc: this.form.numdoc,
                        customer_id: this.selectedCustomer.value,
                        estimate_optional: this.form.estimates_optional,
                        status: this.form.status,
                        accepted_by: this.form.accepted_by,
                        accepted_date: this.form.accepted_date,
                        job_information: this.form.job_information,
                        customer_message: this.form.customer_message,
                        need_contract: this.form.checked,
                        signe: this.form.signe,
                        terms_conditions_title: this.selectCTemplate.title,
                        terms_conditions_desc: this.selectCTemplate.description,
                        signaturecustomer: this.selectedCustomer.text,
                        signaturecustomerdate: this.FormSignature.dateCustomer,
                        signaturecompany: this.FormSignature.nameCompany,
                        signaturecompanydate: this.FormSignature.dateCompany,
                        date_create: this.form.date_created,
                        sub_total: this.form.subtotal,
                        discount_type: this.form.discount_type,
                        discount_mount: this.form.discount_mount,
                        discount_total: this.form.discount_total,
                        discount_name: this.form.discount_name,
                        total: this.form.total,
                        dataItems: this.itemProducts,
                        taxShow: this.taxShow,
                        type_estimate: 'pro',
                        groupShow: this.groupShow,
                    })
                    .then((response) => {
                        this.isLoading = false;
                        this.saveDisabled = false;
                        console.log(response.data);
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Successful Updated",
                        });
                        this.$router.push({ name: "estpro" });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                        this.saveDisabled = false;
                    });
                }
            }
        },
        EstimateById(state) {
            let t = this;
            t.isLoading = true;
            window.billing
            .get("estimate/" + t.$route.params.idest)
            .then((response) => {
                let dataR = response.data;
                let estItem = dataR.result;
                t.form.idEst = estItem.id;
                t.form.numdoc = estItem.numdoc;
                t.form.job_information =
                    estItem.job_information;
                t.form.estimates_optional =
                    estItem.estimate_optional;
                t.form.customer_message =
                    estItem.customer_message;
                t.form.accepted_by = estItem.accepted_by;
                t.form.accepted_date =new Date(estItem.accepted_date);
                t.form.status = estItem.status;
                t.form.date_created = (t.form.actionNname == 'duplicate') ? new Date() : estItem.date_create
                t.inputFecha = (t.form.actionNname == 'duplicate') ? new Date().toISOString().slice(0, 10) : estItem.date_create
                t.form.actionNname = "update"
                t.form.checked = estItem.need_contract == 1;
                t.form.signe = estItem.signe == 1;
                t.form.subtotal = estItem.sub_total;
                t.form.discount_type = estItem.discount_type;
                t.form.discount_mount = estItem.discount_mount;
                t.form.discount_total = estItem.discount_total;
                t.form.discount_name = estItem.discount_name;
                t.form.total = estItem.total;
                t.taxShow = (dataR.taxShow!=null && dataR.taxShow.meta_value==1) ? 1 : 0;
                t.type_estimate = (dataR.type_estimate!=null && dataR.type_estimate.meta_value=='pro') ? 'pro' : 'normal';
                t.groupShow = (dataR.groupShow!=null && dataR.groupShow.meta_value==1) ? 1 : 0;

                t.selectCTemplate.description =
                    estItem.terms_conditions_desc;
                t.selectCTemplate.title =
                    estItem.terms_conditions_title;
                t.FormSignature.dateCustomer = new Date(estItem.signaturecustomerdate);
                t.FormSignature.dateCompany = new Date(estItem.signaturecompanydate);
                t.selectBillTo(response.data.customer.id);
                let groupItem = JSON.parse(localStorage.getItem('groupItem'));
                for (let item in response.data.itemsprod) {
                    let taxfil = t.TaxesLists.filter(
                        (taxfil) => taxfil.id == response.data.itemsprod[item].tax_id
                    );
                    let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                    let taxOpcDefault =JSON.parse(localStorage.getItem('taxDefault'));
                    let isEmptyObj = !Object.keys(newObj).length;
                    let unitcost = response.data.itemsprod[item].unitcost;
                    let qty = response.data.itemsprod[item].qty;
                    let itemModel = {
                        value: response.data.itemsprod[item].product_id,
                        unitcost: unitcost,
                        text: response.data.itemsprod[item].product_name,
                        descripcion: response.data.itemsprod[item].description,
                        qty: qty,
                        taxOpc: isEmptyObj ? taxOpcDefault : newObj,
                    };
                    t.itemProducts.push({
                        options: t.ProdServiceLists,
                        taxlistOption: t.TaxesLists,
                        model: itemModel,
                    });
                    if(response.data.itemsprod[item].product_id==t.idGroup){
                        groupItem[t.itemProducts.length-1]=itemModel;
                        localStorage.setItem('groupItem', JSON.stringify(groupItem));
                    }
                }
                if (state == 2) {
                    t.form.actionNname = "duplicate"
                    t.form.accepted_by = "";
                    t.form.accepted_date = "";
                    t.form.status = 1;
                    t.FormSignature.dateCustomer = new Date();
                    t.FormSignature.dateCompany = new Date();
                    t.FormSignature.nameCompany = localStorage.getItem("nomCompany");
                }
                t.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        deleteEstimate(idest) {
            let t = this;
            t.$swal({
                title: "Delete t Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.isLoading = true;
                    window.billing
                    .put("estimate/delete/" + idest, {
                        user_id: window.localuserdata,
                    })
                    .then((response) => {
                        t.isLoading = false;
                        if (response.data.status) {
                            t.$notify({
                                group: "noti",
                                title: "Deleted!",
                                type: "success",
                                text: "Deleted Estimate Successful",
                            });
                            t.$router.push({ name: "estpro" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading = false;
                    });
                }
            });
        },
        calcHeigthDescrip(){
            let t = this;
            let descrip = document.querySelectorAll('div.descripcion textarea');
            if(descrip!= null && descrip.length>0){
                descrip.forEach(e => {
                    //console.log('descrip', e.value)
                    if(e.value!=null && e.value!=''){
                        t.autoHeigthDescrip(e);
                    }
                })
            }
        },
        autoHeigthDescrip(elem){
            let calcHeight = elem;
            if(calcHeight){
                let numberOfLineBreaks = (calcHeight.value.match(/\n/g) || [1]).length;
                let logitud = Math.round(calcHeight.clientWidth) / 14 + 10; //elemWidth + font-size + line-height
                let caracteres =calcHeight.value.length*14/calcHeight.clientWidth;
                numberOfLineBreaks = (numberOfLineBreaks==1) ? caracteres : numberOfLineBreaks;
                //console.log('logitud', logitud)
                // min-height + lines x line-height + padding + border
                let newHeight = 14 + numberOfLineBreaks * 10 + 0 + 0;
                if(newHeight<40){newHeight=40;}
                calcHeight.style.height = newHeight + "px";
                calcHeight.style.maxHeight = newHeight + "px";
                if(numberOfLineBreaks>2 && calcHeight.scrollHeight>newHeight){
                    calcHeight.style.height = calcHeight.scrollHeight + "px";
                    calcHeight.style.maxHeight = calcHeight.scrollHeight + "px";
                    // console.log(calcHeight, calcHeight.style.height)
                }
            }
        },
        totalGroup(index){
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            if(groupItem.length>0 && groupItem[index]){
                return parseFloat(groupItem[index].prices)
                .toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
            }else{
                return '0.00';
            }
        }
    },
    watch: {
        getPlanId() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
        'itemProducts': {
            handler: function (after, before) {
                let t = this;
                let m = after;
                t.calcPrices(m);
                if(t.taxShow==1){
                    console.log('ValItemTax')
                    t.ValItemTax(m, t.form.discount_total, t.form.subtotal);
                }
                setTimeout(() => {
                    t.calcHeigthDescrip();
                }, 500);
            },
            deep: true
        },
        'taxShow': function(){
            let t = this;
            t.ValItemTax(t.itemProducts, t.form.discount_total, t.form.subtotal);
        },
        'selectedCustomer.value': function(){
            let t =this;
            if(t.selectedCustomer.value>0){
                t.saveDisabled=false
                t.disabledSteps.customer=false;
            }
            else{
                t.saveDisabled=true;
                t.disabledSteps.customer=true;
            }
        },
        'form.job_information': function(after, before){
        },
        'getActionRefresh': function() {
            let t =this;
            console.log('getActionRefresh', t.getActionRefresh)
            if (Object.keys(t.getActionRefresh.data).length > 0) {
                let data = t.getActionRefresh.data; 
                let methods = t.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    t.selectedCustomer = data;
                    localStorage.removeItem('CustomersList');
                    t.getCustomer();
                }
                if (methods === 'products') {
                    t.ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
                    t.ProdServiceLists.push(data.model);
                    localStorage.setItem('ProdServiceLists', JSON.stringify(t.ProdServiceLists));
                    t.itemProducts[t.FormAddPS.index_modal].options = t.ProdServiceLists;
                    t.itemProducts[t.FormAddPS.index_modal].model = data.model;
                    localStorage.removeItem('ProdServiceLists');
                    t.getProdservices(1);
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                };
            }
        },
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        subTotal() {
            let t = this;
            return t.itemProducts.reduce((carry, item) => {
                let unitcost = item.model.unitcost;
                let qty = item.model.qty;
                let amount = parseFloat(carry) + parseFloat(qty) * parseFloat(unitcost)
                let stt = item.model.prices;
                if (t.isEmptyNaN(amount)) {
                    amount = '0.00'
                }else{
                    amount = parseFloat(amount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, "");
                }
                t.form.subtotal = amount
                // console.log('subtotal', t.form.subtotal)
                return amount
            }, 0);
        },
        discount() {
            let t = this;
            // inicializo variables a utilizar
            let subtotal = t.subTotal;
            let discount_value = '0.00';
            let mount = t.form.discount_mount;
            // obtener el valor del descuento para ello
            // primero valido si el descuento es en % o $
            if(mount > 0){
                // si descuento es mayor a cero, calculo
                if(t.form.discount_type==1){ // porcentaje
                   discount_value = (subtotal * mount) / 100;
                }
                else if(t.form.discount_type==2){ 
                    // monto
                    discount_value = mount;
                }
            }
            // si el calculo de descuento es igual al subtotal retorno cero o error
            if(discount_value == subtotal){discount_value = '0.00';}
            if (t.isEmptyNaN(discount_value)) {discount_value = '0.00';}
            discount_value = parseFloat(discount_value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
            if(discount_value>0){
                // sino calculo descuneto para cada item
                t.ValItemTax(t.itemProducts, discount_value, subtotal);
            }
            t.form.discount_total = discount_value;
            return discount_value;
        },
        TotalTax() {
            let t = this;
            let totaltaxes = '0.00';
            if (t.taxesResumen.length > 0) {
                for (let e in t.taxesResumen) {
                    totaltaxes+=t.taxesResumen[e].total;
                }
            }
            if (t.isEmptyNaN(totaltaxes)) {totaltaxes = '0.00';}
            return parseFloat(totaltaxes)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
        },
        total() {
            let t = this;
            // this.valueOptionstax(t.itemProducts);
            let stt=(parseFloat(t.subTotal) + parseFloat(t.TotalTax)) - parseFloat(t.form.discount_total)
            if (t.isEmptyNaN(stt)) {stt = '0.00';}
            t.form.total = parseFloat(stt)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
            return stt;
        },
        validRemoveGroup(){
            let t = this;
            let list = t.itemProducts;
            if(list.length>1){
                let valid = 0;
                for (let i in list) {
                    if(list[i].model.value==t.idGroup){
                        valid++;
                    }
                }
                if(valid>1){
                    return true;
                }
            }
            return false;
        },
        validRemoveItem(){
            let t = this;
            let list = t.itemProducts;
            if(list.length>1){
                let valid = 0;
                for (let i in list) {
                    if(list[i].model.value==t.idGroup){
                        valid=1;
                    }
                    else if(list[i].model.value!=t.idGroup && list[i].model.value!=t.idseparador && list[i].model.value!=t.idcontinue){
                        valid++;
                    }
                }
                if(valid>2){
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
