<template>
    <div class="popGallery">
        <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
        >
            <div class="pop-modal" v-if="index >= 0 && imagenes.length>0">
                <button
                    class="btn btn-outline-danger btn-sm pop-modal-close"
                    @click="close"
                >
                    <i class="fas far fa-times fa-2x"></i>
                </button>
                <button class="btn btn-outline-primary pop-modal-prev" @click="prev">
                    <i class="fas far fa-angle-left fa-3x"></i>
                </button>
                <button class="btn btn-outline-primary pop-modal-next" @click="next">
                    <i class="fas far fa-angle-right fa-3x"></i>
                </button>
                <div class="btn-group op-avanzadas">
                    <!-- <a
                        :href="src"
                        target="_blank"
                        download
                        class="btn btn-link pop-modal-download"
                    >
                        <i class="fas fa-download"></i>
                    </a> -->
                    <button
                        type="button"
                        class="btn btn-link pop-modal-zoom-menos"
                        @click="zoomManual(-1)"
                    >
                        <i class="fas fa-search-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link pop-modal-zoom-mas"
                        @click="zoomManual(1)"
                    >
                        <i class="fas fa-search-plus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link pop-modal-rotate-left"
                        @click="rotate(1)"
                    >
                        <i class="fas fa-undo"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link pop-modal-rotate-right"
                        @click="rotate(2)"
                    >
                        <i class="fas fa-undo rotate_custom"></i>
                    </button>
                    <!-- <button
                        type="button"
                        class="btn btn-link pop-modal-delete"
                        @click="delIMG"
                        v-if="typez == 0"
                    >
                        <i class="fas fa-trash"></i>
                    </button> -->
                </div>

                <div class="pop-modal-img">
                    <img :src="src" :style="girarImg+opacityImage" id="ImgGallery" />
                    <div class="lb-loader" v-show="loading">
                        <img src="@/core/assets/img/loading.gif" style="width: 32px;" id="ImgLoading" />
                        <!-- <div class="loading"></div> -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        itemIndex: {
            type: Number,
            default: -1,
            require: false,
        },
        dataPop: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            src: null,
            idsrc: 0,
            index: -1,
            loading: false,
            events: [],
            imagenes: [],
            rotar: 0,
            girarImg: "",
            opacityImage: "",
            idx: 0,
            tam: 600,
            tamMin: 600,
            tamMax: 1000,
            typez: window.user_type,
            url: window.urlmaster+'api/v2/projects/previewImgProject/'+window.master_client+'/'+ window.localorgdata+'/',
        };
    },
    created() {
        this.init();
    },
    mounted() {
        if (window.addEventListener) {
            window.addEventListener("DOMMouseScroll", this.wheel, false);
            window.onmousewheel = document.onmousewheel = this.wheel;
        }
    },
    watch: {
        items: function () {
            this.init();
        },
    },
    methods: {
        init(){
            this.imagenes = this.items;
            this.index = this.itemIndex;
            this.rotar = 0;
            this.girarImg = "";
            this.opacityImage = "";
            this.loading = false;
            this.src = null;
            this.idsrc = 0;
            console.log("imagenes", this.imagenes);
            console.log("index", this.index);
            if(this.index >= 0 && this.imagenes.length>0){
                this.show(this.index);
            }
        },
        validTam() {
            this.tamMax =window.screen.width;
            this.tamMin = (window.screen.width > 400) ? 400 : 200;
            this.tam = (window.screen.width > 1000) ? 1000 : ((window.screen.width > 400) ? 400 : 200);
            // console.log('tamMax', this.tamMax, 'tamMin', this.tamMin, 'tam', this.tam)
        },
        zoomManual(zoom) {
            this.validTam();
            console.log('zoom', zoom)
            let img = document.getElementById("ImgGallery");
            if (img) {
                let wz = Math.round(parseInt(img.style.maxWidth));
                if (zoom < 0) {
                    // console.log('zoom --')
                    if(wz>=this.tamMin){
                        wz -= 100;
                        // console.log('zoom -100');
                    }
                    else{
                        wz = this.tamMin;
                        // console.log('zoom min');
                    }
                }else if (zoom >= 0) {
                    console.log('zoom ++');
                    if(wz<=this.tamMax){
                        wz += 100;
                        // console.log('zoom +100');
                    }
                    else{
                        wz = this.tamMax;
                        // console.log('zoom max')
                    }
                }else{
                    wz = this.tam;
                    // console.log('zoom original');
                }
                // console.log('new width', wz);
                img.style.maxWidth = wz + "px";
            }
        },
        moveMouseWheel(delta) {
            this.zoomManual(delta);
        },
        wheel(event) {
            var delta = 0;
            if (!event) event = window.event;
            if (event.wheelDelta) {
                delta = event.wheelDelta / 120;
                if (window.opera) delta = -delta;
            } else if (event.detail) {
                delta = -event.detail / 3;
            }
            if (delta) {
                this.moveMouseWheel(delta);
            }
        },
        downloadIMG() {},
        rotate(r) {
            if (r == 1) {
                r = -90;
            }
            if (r == 2) {
                r = 90;
            }
            this.rotar += r;
            this.girarImg =
                `-moz-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-o-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-webkit-transform: rotate(` +
                this.rotar +
                `deg);` +
                `transform: rotate(` +
                this.rotar +
                `deg);`;
            //console.log('rotate', this.rotar, 'girarImg='+this.girarImg);
        },
        delIMG() {},
        bind() {
            if (this.events.length > 0) return;
            this.events.push([
                "keydown",
                (e) => {
                    if (this.index < 0) return;
                    if (e.keyCode === 37) {
                        this.prev();
                    } else if (e.keyCode === 39) {
                        this.next();
                    } else if (e.keyCode === 27) {
                        this.close();
                    }
                },
            ]);
            this.events.forEach((e) => {
                window.addEventListener(e[0], e[1]);
            });
        },
        show(i) {
            let t = this;
            document.body.style.overflowY = "hidden";
            if (i >= t.imagenes.length) {
                i = 0;
            }
            if (i < 0) {
                i = t.imagenes.length - 1;
            }
            t.loading = true;
            t.bind();
            t.index = i;
            //console.log('index',i);
            t.idx = i;
            t.src = '';
            if(t.imagenes.length>0){
                let resize = 1500;
                console.log('show', t.imagenes[i].filename)
                var src = t.url + t.imagenes[i].filename+'/'+resize+'/100';
                var idsrc = t.dataPop.id;
                var img = new Image();
                img.src = src;
                t.opacityImage = "opacity:0;";
                img.onload = () => {
                    console.log('onload de new image');
                    t.rotar = 0;
                    t.src = src;
                    t.idsrc = idsrc;
                    let imgItem = document.getElementById("ImgGallery");
                    if (imgItem) {
                        t.opacityImage = "opacity:1;";
                        t.loading = false;
                        t.zoomManual();
                    }
                };
            }
        },
        loadedImg(e){
            console.log('loadedImg', e);
        },
        next() {
            this.show(this.index + 1);
        },
        prev() {
            this.show(this.index - 1);
        },
        close() {
            this.index = -1;
            this.imagenes = [];
            this.events.forEach((e) => {
                window.removeEventListener(e[0], e[1]);
            });
            this.events = [];
            document.body.style.overflowY = "initial";
            this.$emit('closePop', 'close pop gallery');
        },
    },
};
</script>
<style lang="scss" scoped>
.popGallery{
    .pop-modal {
        transition: opacity .2s ease;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        min-height: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .8);
        display: block;
        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .pop-modal-close {
        position: absolute;
        right: 20px;
        top: 10px;
        color: #fff;
        z-index: 999;
        text-shadow: 2px 2px 2px #000;
    }

    .pop-modal-next, .pop-modal-prev {
        position: absolute;
        top: 0;
        width: 30%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        color: #fff;
        background-color: transparent;
        border: none;
        text-shadow: 2px 2px 2px #000;
        &:hover, &:focus, &:active{
            outline: none;
            box-shadow: none;
            border: 0px;
            background: transparent;
        }
    }
    .pop-modal-prev {
        left: 0;
        text-align: left;
        padding-left: 5%;
    }
    .pop-modal-next {
        right: 0;
        text-align: right;
        padding-right: 5%;
    }

    .pop-modal-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: calc(100% - 20px);
        margin: 10px auto;
    }

    .pop-modal-img:before {
        content: "";
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }

    .pop-modal-img img {
        display: block;
        width: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .rotate_custom {
        transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
    }
    .op-avanzadas{
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 999;
        background-color: transparent;
        border: none;
        box-shadow: none;
        text-align: center;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .op-avanzadas:focus{
        outline: none;
    }
    .op-avanzadas .btn-link{
        text-shadow: 2px 2px 2px #000;
        color: #fff;
        font-size: 20px;
        border-color: #fff;
        border-radius: 4px;
        margin: 0px 2px;
        box-shadow: 2px 2px 2px rgb(0 0 0 / 40%), inset 2px 2px 20px 8px rgb(0 0 0 / 10%);
        &:hover {
            box-shadow: 2px 2px 2px rgb(0 0 0 / 40%), inset 2px 2px 20px 8px rgb(0 0 0 / 40%);
        }
    }
    .pop-modal-download {
    }
    .pop-modal-rotate-left {
    }
    .pop-modal-rotate-right {
    }
    .pop-modal-delete {
    }
    .pop-modal-zoom-menos{}
    .pop-modal-zoom-mas{}
    .lb-loader{
        transition: all 1.5s;
        width: 150px;
        display: flex;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 50px;
        z-index: 2;
        .loading{
            margin-top: 50px;
            border-radius: 50%;
            width:1em;
            height: 1em;
            position: relative;
            background-color:#fff;
            animation: efectShadown 1.5s infinite ease-in-out;
            animation-delay: -0.16s;
            &:before, &:after{
                border-radius: 50%;
                width:1em;
                height: 1em;
                position: absolute;
                top:0px;
                content:"";
                background-color:#fff;
                animation: efectShadown 1.5s infinite ease-in-out;
            }
            &:before{left: -1.5em;animation-delay: -0.32s;}
            &:after{right: -1.5em;}
        }
    }
}
@media(max-width: 767px){

}
@keyframes efectShadown {
    0%,
    80%,
    100%{
        box-shadow: 0 0.1em 0 -0.1em #fff;
        background-color: #fff;
    }
    40%{
        background-color: transparent;
        box-shadow: 0 0.1em 0 0 #fff;
    }
}
</style>
