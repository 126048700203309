<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4 style="margin-bottom: 0px;">
                About me:
                <button type="button"
                    class="btn btn-success btn-sm"
                    :disabled="isExistSlug"
                    style="margin-left: 10px;"
                    @click="saveCard()"
                >
                        Save
                </button>
            </h4>
		</div>
        <div class="row">
            <div class="col-md-4 ">
                <label class="col-form-label">Name</label>
                <input type="text" class="form-control" v-model="form.name">
            </div>
            <div class="col-md-4 ">
                <label class="col-form-label">Last Name</label>
                <input type="text" class="form-control" v-model="form.lastname">
            </div>
            <div class="col-md-4 ">
                <label class="col-form-label">Position:</label>
                <input type="text" class="form-control" v-model="form.rol">
            </div>
            <div class="col-md-12">
                <label class="col-form-label">
                    Url:
                    <small>
                        <a href="#" @click.prevent="external_link">
                            <span v-if="card_primary==0">{{urlweb}}{{ form.slug_url }}</span>
                            <span v-else>{{urlweb}}{{ form.slug_url }}</span>
                        </a>
                    </small>
                </label>
                <div class="input-group">
                    <span class="input-group-text"
                    style="padding-right: 0px;border-right: 0px;"
                    v-if="card_primary==0">{{suf_url}}/</span>
                    <span class="input-group-text"
                    style="padding-right: 10px;border-right: 1px;cursor:pointer;"
                    v-if="card_primary==1" @click="saveCard('editUrl')">Edit</span>
                    <input type="text" class="form-control"
                        v-if="card_primary==1"
                        v-model="form.slug_url"
                        @change="validSlug"
                        :disabled="(card_primary==1)"
                    />
                    <input type="text" class="form-control"
                        v-else
                        v-model="slug_name"
                        @change="validSlug"
                        style="padding-left: 0;border-left: 0;"
                    />
                </div>
                <div class="invalid-feedback" :style="'display:'+ (isExistSlug ? 'block' : 'none')+';'">
                    Wrong url or already exist.
                </div>
            </div>
        </div>
        <div class="row" style="width: 100%;margin-top: 40px;">
            <div class="col-12 text-center" style="">
                <button type="button"
                    class="btn btn-success btn-lg"
                    @click="saveCard()"
                    :disabled="isExistSlug"
                    style="width: 100px;border-radius: 20px;"
                >
                Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"editInfo",
    data(){
        return {
            card_id: 0,
            form : {
                name : '',
                lastname : '',
                rol: 'Employee',
                slug_url : '',
                slug_name: '',
                status : '1',
                template_id : '1',
            },
            urlweb : window.urlbSite,
            apis: null,
            validError: false,
            isExistSlug: false,
            errorMsg: null,
            notOwner: false,
            suf_url: null,
            card_primary: 0,
            loadWrite: 0,
            slug_name:'',
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        t.suf_url = this.$store.getters['ModApps/Marketea/get_bsite_suf_url'];
        let data = this.$store.getters['ModApps/Marketea/get_bsite_cardItem']
        t.fillForm(data)
    },
    methods: {
        crearLink(noValid){
            let t =this;
            if(t.card_primary==0){
                if(t.form.slug_url == null || t.form.slug_url.length<=2 || t.slug_name.length<=2 || t.slug_name.length!=t.form.name.length){
                    console.log('loadWrite', t.loadWrite);
                    let slug = '';
                    slug+=(t.form.name.length>0) ? t.slugUrl(t.form.name) : '';
                    slug+=(t.form.lastname.length>0) ? ((t.form.name.length>0) ? '-'+t.slugUrl(t.form.lastname) : t.slugUrl(t.form.lastname)) : '';
                    t.form.slug_name = slug;
                    t.slug_name = slug;
                    t.form.slug_url = t.suf_url+'-'+slug;
                    if(noValid==undefined){
                        t.validSlug();
                    }
                }
            }
        },
        external_link() {
            if(this.card_primary==1){
                window.open(this.urlweb + this.suf_url, "_blank");
                }else{
                if (this.form.slug_url.length > 0) {
                    window.open(this.urlweb + this.form.slug_url, "_blank");
                }
            }
        },
        slugUrl(slug) {
            let str = slug;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();
            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");

            return str;
        },
        validSlug(){
            let t = this;
            let url = t.apis.pull(5);
            t.slug_name=t.slugUrl(t.slug_name);
            t.form.slug_url = t.suf_url+'/'+t.slug_name
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                suf_url: t.suf_url,
                slug_url: t.form.slug_url,
                socket_id: pusher.connection.socket_id
            })
            .then((response) => {
            if(response.data.valid > 1){
                t.isExistSlug=true;
                t.validError=true;
                console.log('isExistSlug', t.isExistSlug)
            }
            else if(t.action=='edit' && response.data.valid == 1){
                if(t.card_id == response.data.get.id){
                t.isExistSlug=false;
                t.validError=false;
                }
                else{
                t.isExistSlug=true;
                t.validError=true;
                console.log('isExistSlug', t.isExistSlug)
                }
            }
            else if(response.data.valid == 1){
                if(t.card_id == response.data.get.id){
                t.isExistSlug=false;
                t.validError=false;
                }
                else{
                t.isExistSlug=true;
                t.validError=true;
                console.log('isExistSlug', t.isExistSlug)
                }
            }
            else{
                t.isExistSlug=false;
                t.validError=false;
            }
            if(t.slug_name==null || t.slug_name.length==0){
                t.isExistSlug=true;
                t.validError=true;
                console.log('isExistSlug', t.isExistSlug)
            }
            }).catch((error) => {
                console.log(error);
                t.isExistSlug=false;
                t.validError=false;
            });
        },
        fillForm(data){
            let t = this;
            let r = data;
            t.loadWrite=1;
            // t.org.company_name = r.company_name;
            t.form.name = r.name;
            t.form.lastname = r.lastname;
            t.form.rol = r.rol;
            t.form.status = r.status;
            t.card_primary = r.card_primary;
            t.form.template_id = r.template_id;
            t.form.slug_url = r.slug_url;
            t.form.slug_name = r.slug_url.replace(t.suf_url+'/', '');
            t.slug_name = r.slug_url.replace(t.suf_url+'/', '');
        },
        setModalOrgUrl(){
            let t = this;
            let valEdit = (localStorage.getItem('editOrgUrl')!=null) ? (parseInt(localStorage.getItem('editOrgUrl'))+1) : 1;
            let payload = {
                key: 'editOrgUrl',
                val: valEdit,
            };
            t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload);
        },
        saveCard(editUrl){
            let t = this;
            t.$store.commit('ModApps/Marketea/setAbout', t.form);
            t.$store.dispatch('ModApps/Marketea/setCard')
            .then(r => {
                console.log('saveCard', r);
                setTimeout(() => {
                    // t.$parent.close();
                    if(editUrl != undefined){t.setModalOrgUrl();}
                }, 400);
            })
            .catch(error => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
    },
    watch: {
        'form.name': function(){
            if(this.loadWrite==0){this.crearLink();}
        },
        'form.lastname': function(){
            if(this.loadWrite==0){this.crearLink();}
        },
        'slug_name': function(){
            let t =this;
            t.isExistSlug=false;
            if(this.loadWrite==0){
                if(t.slug_name!=null && t.slug_name.length>0){
                    t.form.slug_url = t.suf_url+'-'+t.slugUrl(t.slug_name);
                    t.form.slug_name =t.slugUrl(t.slug_name);
                    t.validSlug();
                }else{
                // t.crearLink();
                    t.isExistSlug=true;
                    console.log('isExistSlug', t.isExistSlug)
                }
            }
        },
        'form.slug_url': function(){
            let t =this;
            if(this.loadWrite==0){
                if(t.form.slug_url==null || t.form.slug_url.length==0){
                    t.crearLink();
                }
            }
        },
        'suf_url': function(){
            if(this.loadWrite==0){this.crearLink();}
        },
    },
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.card-header h4{font-size:24px;}
.col-form-label, .form-control, .input-group-text{
    font-size:18px;
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
</style>
