<template>
	<div>
		<dropdown-menu
			v-model="show"
			:right="right"
			:hover="hover"
		>
			<button class="btnDrop">
				<div class="imgUserAvatar" :data-avatar="dataUser.avatar">
					<img 
						v-if="dataUser.avatar!=null && dataUser.avatar!='' "
						v-bind:src="dataUser.url+dataUser.avatar" 
						id="UserAvatar"
					/>
					<span v-else class="UserAvatarDefault">
						{{ avatarDefault }}
					</span>
				</div>
			</button>
			<div slot="dropdown" class="menu-account">
				<li 
					class="dropdown-item"  
					style="width:158px;"
					@click="modaloptions('Profiler')"  
				>
					<a>Profiler</a>
				</li>
				<li 
					class="dropdown-item"  
					style="width:158px;" 
					@click="modaloptions('Security')" 
				>
					<a>Security</a>
				</li>
				<li class="dropdown-item" style="width:158px;cursor:pointer;padding-top:8px;margin-top:11px;border-top:1px solid #ececec;" @click="$parent.logout">
					<a ><span class="logout">Sign Out</span></a>
				</li>
			</div>
		</dropdown-menu>
        <!-- modal -->
        <modalLateral v-show="activeModalProfiler">
            <component v-bind:is="m.component"></component>
        </modalLateral> 		
	</div>
</template>
<script>
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import Profiler from '@/core/components/Reseller/Account/profilerReseller'
import Security from '@/core/components/Reseller/Account/securityReseller'
import { mapGetters } from 'vuex'
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
	name: 'menuAccount',
	components: {
		DropdownMenu,
		modalLateral,
		Profiler,
		Security,
	},
	data () {
		return {
			show : false,
			right: true,
			hover: false,
			avatarDefault:'',
			timezonesList: [],
			m: {
				show: false,
				component: '',
				data: []
			},
			dataUser: {},	
			activeModalProfiler: false,	
		}
	},
	created(){
		this.getResellerbyId();
	},
	methods:{
        modaloptions(component) {
            this.m = {
                show: true,
                component: component,
                data: {}
            }  
            this.activeModalProfiler = true;
            console.log('abrir modal de menu account')
            this.$store.commit('core/setOpenModal', this.m);          
        },		
		getResellerbyId(){
			let t = this;
			window.reselleR2.post('getResellerbyId', {
				user_id: window.resellerId,
			})
			.then(response => {
				console.log('getsession', response.data)
				if(response.data.status){
					t.avatarDefault = '';
					t.dataUser = response.data.r;
					if ((t.dataUser.name != null && t.dataUser.name.length > 0)) {
						t.avatarDefault = t.dataUser.name.charAt().toUpperCase();
					}
					if (t.dataUser.lastname != null && t.dataUser.lastname.length > 0) {
						t.avatarDefault += t.dataUser.lastname.charAt().toUpperCase();
					}
				}
			})
			.catch(error => { 
				console.log(error);
			});				
		},
	},
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
        'getOpenModal': function() {
			if(!this.getOpenModal.show){
				this.activeModalProfiler= false;
			}
        },          
    },
};
</script>
<style lang="css" scoped>
</style>
