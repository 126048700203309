let getOpenModal = function() {	
	if (!this.getOpenModal.show) {
		if (Object.keys(this.getOpenModal.data).length > 0) {
			let t = this.getOpenModal.data;
			if (t.methods === 'invoFilter') {
				this.isFilter = t.isFilter
				this.InvoicesListResult = t.InvoicesListResult
				this.statuslist = t.statuslist
				this.inputFilter = t.inputFilter
				this.InvoicesList = t.InvoicesList
			}
			if (t.methods === 'invoNote') {
				let id = t.id
				let value = t.value
				let g = this.InvoicesList
				let a = []
				for (let i in g) {
					if (id === g[i].id) {
						a.push({
							actions: g[i].actions,
							activorestore: g[i].activorestore,
							balance: g[i].balance,
							bussinesname: g[i].bussinesname,
							created_at: g[i].created_at,
							customer_id: g[i].customer_id,
							customer_message: g[i].customer_message,
							email: g[i].email,
							fecdelete: g[i].fecdelete,
							fecdoc: g[i].fecdoc,
							fecdue: g[i].fecdue,
							firstname: g[i].firstname,
							id: g[i].id,
							idxstatus:g[i].idxstatus,
							invoicename: g[i].invoicename,
							jobinformation: g[i].jobinformation,
							jobinformation_text: g[i].jobinformation_text,
							job: g[i].job,
							lastname: g[i].lastname,
							notes: value,
							numdoc: g[i].numdoc,
							numemaienv: g[i].numemaienv,
							ordenDate: g[i].ordenDate,
							ordenDate2: g[i].ordenDate2,
							overdue: g[i].overdue,
							rec: g[i].rec,
							subtotal: g[i].subtotal,
							tax: g[i].tax,
							terms_id: g[i].terms_id,
							total: g[i].total,
							updated_at: g[i].updated_at,
							user_id: g[i].user_id
						})
					} else {
						a.push({
							actions: g[i].actions,
							activorestore: g[i].activorestore,
							balance: g[i].balance,
							bussinesname: g[i].bussinesname,
							created_at: g[i].created_at,
							customer_id: g[i].customer_id,
							customer_message: g[i].customer_message,
							email: g[i].email,
							fecdelete: g[i].fecdelete,
							fecdoc: g[i].fecdoc,
							fecdue: g[i].fecdue,
							firstname: g[i].firstname,
							id: g[i].id,
							idxstatus:g[i].idxstatus,
							invoicename: g[i].invoicename,
							jobinformation: g[i].jobinformation,
							jobinformation_text: g[i].jobinformation_text,
							job: g[i].job,
							lastname: g[i].lastname,
							notes: g[i].notes,
							numdoc: g[i].numdoc,
							numemaienv: g[i].numemaienv,
							ordenDate: g[i].ordenDate,
							ordenDate2: g[i].ordenDate2,
							overdue: g[i].overdue,
							rec: g[i].rec,
							subtotal: g[i].subtotal,
							tax: g[i].tax,
							terms_id: g[i].terms_id,
							total: g[i].total,
							updated_at: g[i].updated_at,
							user_id: g[i].user_id
						})                                
					}
				}
				this.InvoicesList = a
			}                    
		}
		this.m = {
			show: false,
			bill: false,
			component: '',
			data: {}
		}
	}
};
export default getOpenModal;
