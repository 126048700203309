<template>
	<div>
		<div class="card">
			<div class="card-header">
				Add Task                    
			</div>
			<form @submit.prevent="saveTimesheettask">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<label>From:</label>
							<input
								type="text"
								class="form-control"
								v-model="f.name"
								style="width: 100%"
								required
							/>
						</div>
						<div class="col-md-12">
							<label for="">DescripTion:</label>
							<textarea
								v-model="f.description"
								class="form-control"
								autocomplete="off"
								style="height: 100px"
								size="40"
								rows="24"
								cols="80"
							></textarea>
						</div>
					</div>
					<div style="width: 100%; text-align: right; margin-top: 20px">
						<button
							type="submit"
							class="btn btn-success"
							:disabled="isDisabled"
						>
							<i class="fas fa-save"></i> SAVE
						</button>
					</div>
				</div>
				<input type="hidden" v-model="f.id" />
			</form>
		</div>	
	</div>
</template>
<script>
export default {
	components: {
	},
	name: "formTask",
	data () {
		return {
			isDisabled: false,
            f: {
                id: 0,
                user_id: window.localorgdata,
                name: "",
                description: "",
            },          
		};
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.f = r.f;       
        },		
        saveTimesheettask() {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post("timesheettask/form", this.f)
                .then((response) => {
                    console.log(response.data);
					this.$store.commit("core/setLoadingModal", false);
					this.$store.commit("core/setCloseModal", {action: 'saved'});
                    this.closeModal();
                    this.refresTimesheettask();
                })
                .catch((error) => {
                    console.log(error);
					this.$store.commit("core/setLoadingModal", false);
                });
        },
        closeModal() {
            this.f.id = 0;
            this.f.name = "";
            this.f.description = "";
        },
        refresTimesheettask() {
            this.f.id = 0;
            this.f.name = "";
            this.f.description = "";
            this.task = [];
            this.StotalHoras = 0;
            this.StotalPagos = 0;
        },	                       
    }
}
</script>
<style lang="css" scoped></style>
