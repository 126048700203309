<template>
    <div class="mini_site">
        <div class="row">
            <div class="col-12" v-if="isLoading"><Loader></Loader></div>
            <div class="col-12 text-center" v-else>
                <span class="cdropdown">
                    <button>
                        <i class="fas fa-globe"></i> Choose Mini Site
                    </button>
                    <label>
                        <input type="checkbox" @click="checkSubmenu($event)" />
                        <ul>
                            <li @click="chooseSiteID(0)">
                                <i class="far fa-check-square" v-if="miniSiteId==0" style="margin-right: 5px;"></i>
                                <i class="far fa-square" v-else style="margin-right: 5px;"></i>
                                None
                            </li>
                            <li v-for="(itemSite, idxSite) in listMiniSite" :key="idxSite" @click="chooseSiteID(itemSite.id)">
                                <i class="far fa-check-square" v-if="miniSiteId==itemSite.id" style="margin-right: 5px;"></i>
                                <i class="far fa-square" v-else style="margin-right: 5px;"></i>
                                {{ itemSite.name }}
                            </li>
                        </ul>
                    </label>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "minisitePreview",
    comments: {
        Loader,
    },
    props: {
        itemPreview: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            validMiniSite: false,
            isLoading: false,
            listMiniSite: [],
            apis: null,
            miniSiteId: 0,
        };
    },
    computed: {
        ...mapGetters("core", {
            getPusher: "get_accitonPusher",
        }),
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined && t.getPusher != null) {
                if(t.getPusher.modulo!=null && t.getPusher.modulo == "bsite"){
                    let p = t.getPusher.data;
                    if(p.org_id == window.localorgdata){
                        // leer acciones
                        t.pusherActions(p);
                    }
                }
            }
        },
        itemSelected: {
            get: function () {
                let t = this;
                return (t.itemPreview!=null) ? t.itemPreview : {};
            },
            set: function (v) {
                return v;
            }
        },
    },
    watch: {
        itemPreview: function(){
            let t = this;
            if(t.itemPreview!=null){
                setTimeout(() => {
                    t.itemSelected = t.itemPreview;
                    t.getAllMiniSite();
                }, 800);
            }else{
                t.itemSelected = {};
            }
        },
    },
    created(){
        let t = this;
        // valid premium planID
        t.PlanPremium = (t.$store.getters['core/apps/getPlanIdApp']==6);
        t.planActual = t.$store.getters['core/apps/getPlanIdApp'];
        t.premiumProfile = (t.$store.getters['core/apps/getPlanIdApp']!=8);
        if(t.planActual==7){
            t.PlanPremium = true;
            console.log('Plan sponsored, dando permiso de premium', t.PlanPremium);
        }
        console.log('PlanPremium', t.PlanPremium);
        let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
        t.apis = urlApis.bSite;
        if(t.premiumProfile){
            t.getAllMiniSite();
        }
    },
    methods: {
        getMiniSiteItemSelected(){
            let t = this;
            let item = t.itemSelected;
            if(item.temp_opts && item.temp_opts.length>0){
                    for (const key in item.temp_opts) {
                        if (Object.hasOwnProperty.call(item.temp_opts, key)) {
                            if(item.template_id==item.temp_opts[key].template_id){
                                if(item.temp_opts[key].meta_key=='mini_site' && item.temp_opts[key].meta_value!=null && item.temp_opts[key].meta_value.length>0){
                                    t.miniSiteId = item.temp_opts[key].meta_value;
                                }
                            }
                        }
                    }
                }
        },
        getAllMiniSite(){
            let t = this;
            let url = t.apis.pull(7); // getAllMiniSite
            let payload = new FormData();
            payload.append("user_id", window.master_client);
            payload.append("org_id", window.localorgdata);
            payload.append("pusher_id", pusher.connection.socket_id);
            t.listMiniSite=[{"id":0, "name":'None'}];
            window.master.post(url, payload)
            .then((response) => {
                console.log(url, response.data);
                t.listMiniSite = response.data;
                t.getMiniSiteItemSelected();
            }).catch((error) => {
                console.log(error);
            });
        },
        checkSubmenu(e){
            let submenus = document.querySelectorAll('.' + e.target.classList[0]);
            submenus.forEach(function(i){
                if(i != e.target){
                    i.checked = false;
                }else{
                    console.log('checkSubmenu', i.classList[1], i.checked)
                }
            });
        },
        chooseSiteID(id){
            let t = this;
            t.isDisabled = true;
            let payload = new FormData();
            payload.append("user_id", window.master_client);
            payload.append("org_id", window.localorgdata);
            payload.append("id", t.itemSelected.id);
            payload.append("template_id", t.itemSelected.template_id);
            payload.append("meta_key", 'mini_site');
            payload.append("meta_value", id);
            payload.append("pusher_id", pusher.connection.socket_id);
            t.$store.dispatch('ModApps/Marketea/setTempOpts', payload)
            .then(r => {
                console.log('setOptionsTemplate mini_site', r);
            })
            .catch(error => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
                t.miniSiteId=0;
            });
        },
        pusherActions(p){
            console.log('pusherActions', p)
            if(p.action=='refreshMiniSite'){
                this.getAllMiniSite();
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.mini_site{
    margin-top: 20px;
    span.cdropdown {
        width: 100%;
        max-width: 450px;
        button{
            width: 100%;
            height: 40px;
        }
        ul{
            width: 100%;
            top: 40px;
            li {
                width: 100%;
                text-align: left;
                padding: 10px;
                &:hover{ cursor: pointer;}
            }
        }
    }
}
</style>
