export default {  
    "label_option_name" : "Nombre de la opcion",
    "label_name_preview" : "Nombre para previsualizar ",
    "label_category_name" : "Nombre de la categoría",
    "label_records" : "Records",
    "label_assigned" : "Asignado",    
    "label_amount_extras" : "Cantidad de adicionales",
    "label_allocated_amount" : "Cantidad asignada",
    "label_categorys" : "Categorias",
    "label_additionals" : "Adicionales",    
    "label_extra_name" : "Nombre del extra",
    "label_addition_name" : "Nombre de la adición",    
    "label_variants" : "Opciones",
    "label_extras" : "Extras",
    "label_crop_img" : "Cortar imagen",
    "label_complete": "Completar",
    "label_expire": "Expirar",    
    "label_temail": "Eliminar este Correo",
    "label_website_menu_link": "Enlace del Menú del Sitio Web",
    "label_add_row": "Añadir Fila",
    "label_notifications": "Notificaciones",
    "label_reservation": "Reservación",
    "label_pending": "Pendiente",
    "label_reserved": "Reservado",
    "label_canceled": "Cancelado",    
    "label_detail": "Detalles",
    "label_edit_reserve": "Editar Reservacion",    
    "label_del_reserve": "Eliminar esta reservacion",
    "label_r_m_wget": "Widget Para Reservar una Mesa",
    "label_r_m": "Reservar Mesa",
    "label_del_img": "Desea eliminar esta imagen",
    "label_max_caracter": "Max: 155 caracteres",
    "label_maxr_caracter": "Max: 90 caracteres, recomendable 30",
    "label_fimage": "Ingrese una imagen con alguno de los siguientes formatos: .jpeg/ .jpg/ .png",
    "label_tm": "Tamaño Maximo es de 1200 de Ancho y 628 de Alto",
    "label_mt" : "Metas Etiquetas",    
    "label_gmc_webd" : "Código para Mostrar Menú del Día",  
    "label_gmc_web" : "Código para Mostrar Menú",    
    "label_sw_info_spcl" : "Mostrar Menú del Día", 
    "label_sw_info_res" : "Mostrar Info Restaurante",    
    "label_dl_cat_asg" : "El grupo no se pudo eliminar, puede tener categorías asignadas.",
    "label_itis_empty" : "Esta Vacio",  
    "label_name_empty" : "El nombre está vacío",
    "label_success_send" : "Envío Exitoso",
    "label_success" : "Exito",
    "label_d_g" : "Eliminar Grupo",
    "label_g" : "Grupo",
    "label_ungrouped" : "Sin Grupo",
    "label_n_g" : "Nombre de Grupos",
    "label_nn_g" : "Nuevo Grupo de Menú",
    "label_m_g" : "Grupo de Menú",
    "label_date" : "Fecha",
    "label_time" : "Hora",
    "label_foods" : "Alimentos",
    "label_nc" : "Nombre/Categoría",
    "label_default" : "Predeterminado",
    "label_nrecord" : "SIN REGISTRO",
    "label_restore" : "Restaurar",
    "label_yrestore" : "Sí, restaurar",
    "label_rcat" : "Restaurar Categoría",
    "label_rt_cat" : "Restaurar esta categoría",
    "msj_del_ctgory_specific" : "¿Eliminar esta Categoría? si elimina ese elemento, perderá los alimentos relacionados con el.",
    "label_edit" : "Editar",
    "label_add" : "Agregar",
    "label_save" : "Guardar",
    "label_saved" : "Guardado",
    "label_del" : "Eliminar",
    "label_deld" : "Eliminado",
    "label_back" : "Regresar",
    "label_up" : "Actualizar",
    "label_upd" : "Actualizado",
    "label_new" : "Nuevo",
    "msj_info_updd_sful" : "Información actualizada Exitosamente",
    "msj_upd_sful" : "Actualización exitosa",
    "msj_img_err" : "Error de imagen, máximo 30 MB",
    "msj_img_err_size_exceeded" : "Error. el tamaño excedió los 30Mb",
    "msj_upting" : "Actualizando",
    "label_show" : "mostrar",
    "label_hidden" : "ocultar",
    "label_cancel" : "Cancelar",
    "label_title" : "Titulo",
    "label_keywords" : "Palabras Clave",
    "label_poster" : "Póster",    
    "label_action" : "Acción",
    "label_numberG" : "Invitados",
    "label_name" : "Nombre",
    "label_fname" : "Nombres",
    "label_lname" : "Apellidos",
    "label_telephone" : "Teléfono",
    "label_notes" : "Notas",
    "label_adress" : "Dirección",
    "label_city" : "Distrito o Ciudad",
    "label_state" : "Departamento o Estado",
    "label_zip" : "Cód. Postal",
    "label_gmap_c" : "Código de mapa de Google",
    "label_cover" : "Portada",
    "label_hours" : "Horario",
    "label_closed" : "Cerrado",  
    "label_close" : "Cerrar",    
    "label_open" : "Abierto",   
    "msj_info_wget" : "¿Mostrar información en el widget?", 
    "label_mn_wget" : "Widget de menú",
    "label_mn_wtget" : "Widget 2 de menú",
    "label_cc_mn" : "Copiar código del Menú",
    "label_cc_spcls" : "Copiar código de los Especiales",
    "label_cc" : "Copiar código",
    "label_mn_spcl_wget" : "Widget especial de menú",
    "label_gnrl_setting" : "Ajustes Generales",
    "label_rtrant_info" : "Información del restaurante",
    "msj_ccd" : "Código copiado",    
    "label_act_multisize" : "Activar Multiples Precios",
    "label_add_ctgory" : "Agregar Categoría",
    "msj_del_ctgory" : "¿Eliminar esta categoría?",
    "msj_you_sure" : "Estas seguro",
    "msj_del_it" : "¡Sí, elimínelo!",
    "label_ingedientes" : "ingedientes",
    "label_add_food" : "Agregar comida",
    "label_ctgory" : "Categoría",
    "label_orders" : "Pedidos",
    "label_mn" : "Menú",    
    "label_spcl" : "Especial",
    "label_spcls" : "Menú del día",   
    "label_report" : "Informes", 
    "label_marketing" : "Márketing",
    "label_setup" : "Ajustes",
    "label_dashboar" : "Tablero",
    "label_lst" : "Lista",
    "label_list_group" : "Lista de Grupos",
    "label_my_apps" : "Mis aplicaciones",
    "label_primary" : "Primario",
    "label_my_orgs" : "Mis Organizaciones",
    "label_add_new" : "Agregar nueva",
    "label_my_acct" : "Mi cuenta",    
    "label_users" : "Usuarios",
    "label_user" : "Usuario",
    "label_security" : "Seguridad",
    "label_sign_out" : "Desconectar",
    "msj_slted_org_not_app" : "La organización seleccionada no tiene esta aplicación disponible, pruebe con otra.",
    "label_price" : "Precio",
    "label_description" : "Descripción",
    "msj_del_cover" : "¿Eliminar esta portada?",
    "label_sign_in" : "Iniciar Sesión",
    "label_login" : "Acceder",    
    "label_fgot_pass" : "¿Olvidaste tu contraseña?",   
    "label_all_rgts_rsrvd_by" : "Todos los derechos reservados por", 
    "msj_err_server" : "error de servidor",
    "label_send" : "Enviar",
    "label_go_login" : "Ir a Iniciar sesión",
    "msj_loading" : "Cargando",
    "msj_inct_usern_pass" : "nombre de usuario / contraseña incorrectos",
    "msj_click_cont" : "Haga clic para continuar como",    
    "msj_click_enter" : "Click para entrar",    
    "msj_cold_gnrat_tken" : "no pudo generar un token de recuperación, su cuenta puede estar mal escrita o no estar afiliada.",   
    "msj_rcovry_tken" : "Se ha enviado un token de recuperación a su cuenta.", 
    "msj_sometg_wrong" : "algo salió mal, inténtalo de nuevo más tarde.",
    "label_website" : "Sitio web",
    "msj_valid_email" : "Ingrese un formato de correo electrónico válido",
    "label_app" : "Aplicación",
    "label_intl" : "Instalar",
    "msj_add_org_sful" : "Organización agregada exitosamente",
    "label_learn_more" : "Aprende más",
    "label_app_availatable" : "Aplicaciones disponibles",
    "msj_org_nt_app_yet" : "Esta organización aún no tiene ninguna aplicación, puede instalarla una aplicación de la lista de abajo.",
    "msj_no_org_asgnd_acct" : "No hay organizaciones asignadas a esta cuenta, comuníquese con el administrador del sistema.",
    "msj_time_intl" : "¡Es hora de INSTALAR su aplicación preferida! Simplemente haga clic en instalar a continuación.",
    "msj_verify_email" : "Verifique su correo electrónico!.",
    "msj_email_vrifiction" : "Reenviar verificación de correo electrónico",
    "msj_agree_intl" : "ACEPTAR E INSTALAR",
    "msj_vrifiction_email" : "El mensaje de verificación ha sido enviado.",
    "msj_err_sndng_email" : "Error al enviar el correo electrónico, inténtelo de nuevo más tarde.",
    "msj_internal_err" : "Error interno, inténtalo de nuevo más tarde.",
    "label_setting_orgs" : "Configuración de las Organizaciones",
    "label_add_org" : "Agregar organización",
    "label_org" : "Organización",
    "label_name_org" : "Nombre de la Organización",
    "label_name_app" : "Nombre de la Aplicación",
    "label_primary_acct" : "Establecer Cuenta Principal",
    "label_change" : "Cambiar",
    "label_org_asgnd_sful" : "Organización asignada exitosamente",
    "label_org_asgnd" : "Organizaciones asignadas a",
    "label_asgn_new_org" : "Asignar Organización",
    "label_new_org" : "Nueva Organización",
    "label_asgn_priv" : "Asignar Privilegios",
    "label_asgn_org" : "Asignar Organizaciones",
    "label_edit_priv" : "Editar Privilegios",
    "msj_org_apps_intled" : "Esta organización no tiene aplicaciones instaladas",
    "msj_crte_user_sful" : "Usuario creado exitosamente",  
    "label_priv" : "Privilegios",
    "label_priv_for" : "Privilegios para",
    "label_slte_all" : "Seleccionar todo",
    "label_money_in" : "Dinero En",
    "label_money_out" : "Dinero Fuera",
    "label_customer" : "Clientes",
    "label_setting" : "Ajuste",
    "label_proyect" : "Proyecto",
    "label_Timesheet" : "Hoja de horas",
    "label_labor_orders" : "Órdenes laborales",
    "label_warranty_list" : "Lista de garantía",
    "label_bling_inf" : "Datos de facturación",     
    "label_manage_bling_dtls" : "Administre sus detalles de facturación, información de contacto, idioma, país y configuración de zona horaria.",
    "msj_up_prof_sful" : "Actualización de perfil exitosa",
    "msj_err_upting_data" : "Error al actualizar los datos",
    "label_pass" : "Contraseña",
    "label_change_pass" : "Cambiar Contraseña",
    "label_up_pass" : "Actualice la contraseña de su cuenta. Establezca siempre una contraseña segura, lo que ayuda a evitar el acceso no autorizado a su cuenta.",
    "label_current_pass" : "Contraseña Actual",
    "label_new_pass" : "Nueva Contraseña",
    "label_reenter_pass" : "Escriba la contraseña otra vez",
    "msj_up_pass_sful" : "Actualización de contraseña exitosa",
    "label_crte_accts" : "Crear cuentas de usuario",
    "label_crte_accts_managt" : "Cree cuentas de usuario para la gestión de sus organizaciones.",
    "msj_avatar" : "Avatar",
    "msj_email" : "Correo",
    "msj_status" : "Estatus",
    "msj_active" : "Activo",
    "msj_inactive" : "Inactivo",
    "msj_users_lst" : "Lista de usuarios",
    "label_edit_prof" : "Editar perfil",
    "label_asgn" : "Asignar",
    "label_lst_asgnd_org" : "Lista de organizaciones asignadas",
    "msj_email_exists" : "Ya existe una cuenta con ese correo electrónico",
    "label_mn_lst" : "Lista de Menú",
    "label_ctgory_lst" : "Lista de Categorías",
    "label_optiones_lst" : "Lista de Opciones",
    "label_imptd" : "Importado",
    "label_verify_imptd" : "Importación exitosa para verificar ingresar a los clientes",
    "label_english" : "Ingles",
    "label_spain" : "Español",
    "label_IE" : "1",
    "label_additional" : "Agregar Extras o Adicional",
    "label_valcover" : "El alto y ancho debe ser mayor 400px",
    "label_new_extra" : "Nuevo Extra",
    "label_cop_extra" : "Copiar un Extra",
    "label_add_opcions" : "Agregar opciones",
    "label_del_extra" : "Desea eliminar este Ítem",
    "label_currency" : "Moneda",
    "label_save_currency" : "Guardar Moneda",
    "label_duplicate" : "Duplicar",
    "label_change_group" : "Cambiar de grupo",
    "label_selected" : "Seleccionado",
    "del_image" : "¿Eliminar esta imagen?",
};
