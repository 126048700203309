<template>
    <div>
        <div class="card">
            <div class="card-header">
                {{ modal.title }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" v-if="modalError">
                    {{ modalErrorMjs }}
                </div>
                <form @submit.prevent="saveAccount" v-if="modal.account">
                    <div class="form-row">
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Account Type:</span
                            >
                            <select
                                class="form-control"
                                v-model="addAccount.account_type"
                                required
                            >
                                <option value="0">--Select--</option>
                                <option value="2">Expenses</option>
                                <option value="4">Other Expense</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Account Name</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="addAccount.name_account"
                                required
                            />
                        </div>
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Description:</span
                            >
                            <textarea
                                name=""
                                id=""
                                cols="10"
                                rows="2"
                                class="form-control form-text w100"
                                style="width: 100%"
                                v-model="addAccount.description"
                            ></textarea>
                        </div>
                        <div class="col-12 text-right" style="margin-top: 10px">
                            <button type="submit" class="btn btn-success">
                                <i class="fas fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "formExpensesAccount",
    data () {
        return {     
            modalError: false,
            modalErrorMjs: "",
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            addAccount: {
                account_type: 0,
                name_account: null,
                description: null,
            },                          
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;   
            this.modal.title = r.modal.title
            this.modal.vendor = r.modal.vendor
            this.modal.account = r.modal.account 
            this.modal.banking = r.modal.banking
            this.modal.bankingTransac = r.modal.bankingTransac
            this.modal.writeCheck = r.modal.writeCheck          
        },
        saveAccount() {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post("chart-account/add", {
                    user_id: window.localuserdata,
                    account_type_id: this.addAccount.account_type,
                    name_account: this.addAccount.name_account,
                    description: this.addAccount.description,
                })
                .then((response) => {
                    this.modal.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Chart Created successfully",
                    });
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'});
                    this.$store.commit("core/setActionRefresh", {
                        action: 'saved',
                        data: {
                            methods: 'account',
                            value: response.data.id,
                            text: t.addAccount.name_account
                        }
                    });
                    this.addAccount.account_type = 0;
                    this.addAccount.name_account = null;
                    this.addAccount.description = null;
                })
                .catch((error) => {
                    console.log(error)
                    this.$store.commit("core/setLoadingModal", false);
                });
        },                            
    },
    watch: {
    },
    computed: {},
};
</script>
