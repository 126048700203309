/*rutas de Estimates*/
import Estimates from '@/apps/billing/views/Estimates';
import EstimatesNew from '@/apps/billing/views/EstimatesNew';
import estpro from '@/apps/billing/views/estpro/';
import estproForm from '@/apps/billing/views/estpro/estproForm';
export default [
  {
  	path: 'estimates',
  	name: 'Estimates',
    folder: Estimates,
  },
	{
	  path: 'estimates/new',
	  name: 'EstimatesNew',
	  folder: EstimatesNew,
	},
	{
	  path: 'estimate/:idest',
	  name: 'EstimatesDet',
	  folder: EstimatesNew,
	},
	{
	  path: 'estimate/:idc/:transac',
	  name: 'EstimatesNewTransac',
	  folder: EstimatesNew,
	},
	{
	  path: 'estimate/:idest/:duplicate',
	  name: 'EstimatesDuplicate',
	  folder: EstimatesNew,
	},
  {
  	path: 'estimates-pro',
  	name: 'estpro',
    folder: estpro,
  },
	{
	  path: 'estimates-pro/new',
	  name: 'estproForm',
	  folder: estproForm,
	},
	{
	  path: 'estimate-pro/:idest',
	  name: 'estproFormEdit',
	  folder: estproForm,
	},
	{
	  path: 'estimate-pro/:idest/:duplicate',
	  name: 'estproFormDuplicate',
	  folder: estproForm,
	},
	{
	  path: 'estimate-pro-for-customer/:idc',
	  name: 'estproFormcustomerID',
	  folder: estproForm,
	},
];
