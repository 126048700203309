<template>
    <div>
        <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: activarLateral }"
        >
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li
                            class="sidebar-item"
                            v-for="i in menuSlabs"
                            :key="i.m_id"
                            v-bind:class="{ sub: i.type === 2 }"
                        >
                            <router-link
                                :to="i.ruta"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark type1"
                                v-if="i.type == 1"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }}</span>
                            </router-link>
                            <div
                                v-else-if="i.type == 2"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeMenuMoneyIn"
                                v-bind:class="{ active: i.mActive }"
                                aria-expanded="false"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>
                            <div
                                v-else-if="i.type == 3"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeMoneyOut"
                                v-bind:class="{ active: i.mActive }"
                                aria-expanded="false"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>

                            <ul
                                aria-expanded="false"
                                class="collapse first-level"
                                v-if="i.mActive > 0"
                                v-bind:class="{ in: i.mActive }"
                            >
                                <li
                                    class="sidebar-item"
                                    v-for="(item, index) in i.items"
                                    :key="index"
                                >
                                    <router-link
                                        :to="item.ruta"
                                        exact-active-class="active"
                                        class="sidebar-link"
                                    >
                                        <span class="hide-menu">{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div class="btnAsideAction" @click="ShowHiddenAside">
                    <i
                        class="fas fa-chevron-left"
                        v-bind:class="{ min: activarLateral }"
                    ></i>
                </div>
            </div>
        </aside>
    </div>
</template>
<script>
export default {
    name: "AsideSlabs",
    data() {
        return {
            isMoneyIn: true,
            isMoneyOut: false,
            activarLateral: false,
            winnerWidth: window.innerWidth,
            menuSlabs: {},
            submenu1: 0,
            submenu2: 0,
        };
    },
    created() {
        this.estructuraMenu();
        this.validarMenu();
    },
    methods: {
        ShowHiddenAside() {
            this.activarLateral = !this.activarLateral;
            this.$emit("activarLateral", this.activarLateral);
        },
        estructuraMenu() {
            this.menuSlabs = {
                1: {
                    name: "Dashboard",
                    ruta: { name: "slabs" },
                    icon: "fas fa-home",
                    type: 1,
                    mActive: 0,
                    items: [],
                    m_id: 0,
                },
                2: {
                    name: "Leads",
                    ruta: { name: "SlabsLeads" },
                    icon: "fas fa-user",
                    type: 1,
                    mActive: 0,
                    items: [],
                    m_id: 1,
                },
                3: {
                    name: "Slabs",
                    ruta: { name: "SlabsSlabs" },
                    icon: "fas fa-square-full",
                    type: 1,
                    mActive: 0,
                    items: [],
                    m_id: 2,
                },
                4: {
                    name: "Remnants",
                    ruta: { name: "SlabsRemanents" },
                    icon: "fas fa-shapes",
                    type: 1,
                    mActive: 0,
                    items: [],
                    m_id: 3,
                },
                5: {
                    name: "Setting",
                    ruta: { name: "SlabsSetting" },
                    icon: "fas fa-cog",
                    type: 1,
                    mActive: 0,
                    items: [],
                    m_id: 4,
                },
            };
        },
        validarMenu() {
            let menu = [];
            if (window.user_type == 0) {
                menu = this.menuSlabs;
                this.submenu1 = 0;
                this.submenu2 = 0;
            } else {
                menu.push(this.menuSlabs[1]);
                /*let privilegios = this.$store.getters.privilegiesSesion;
                let modulos = privilegios.modulos.Slabs;
                console.log('modulos a seleccionar =', modulos);
                if(modulos != null && modulos.length > 0){
                    for (let i in modulos) {
                        for (let m in this.menuSlabs){
                            if((this.submenu1 == 3  && m == 3) || (this.submenu2 == 4 && m==4))
                            {
                                let SubMoney={};
                                let subMenus = [];
                                for (let item in this.menuSlabs[m].items) {  
                                    if(this.menuSlabs[m].items[item].m_id == modulos[i] )
                                    {
                                        subMenus.push(this.menuSlabs[m].items[item]);
                                    }   
                                }
                                if(subMenus.length > 0)
                                {
                                    let group =0;
                                    if(m==3){ group =this.submenu1 ;}
                                    if(m==4){ group =this.submenu2;}                                
                                    if (group == 0) {
                                        SubMoney['name']= this.menuSlabs[m].name;
                                        SubMoney['icon']= this.menuSlabs[m].icon;
                                        SubMoney['type']= this.menuSlabs[m].type;
                                        SubMoney['mActive']= this.menuSlabs[m].mActive;
                                        SubMoney['items']=subMenus ;
                                        menu[m] = SubMoney;
                                        console.log('agregado'+m+' = ',menu[m])
                                        if(m==3 && this.submenu1 == 0){ this.submenu1=m;}
                                        if(m==4 && this.submenu2==0){ this.submenu2=m;}
                                    }
                                    else{menu[group].items.push(subMenus[0]);}
                                    subMenus = [];
                                }
                            }
                            else
                            {
                              if(this.menuSlabs[m].m_id == modulos[i] )
                                {
                                    menu[m]=this.menuSlabs[m];
                                    console.log('agregado'+m+' = ',menu[m])
                                }  
                            }
                        }
                    }
                    this.menuSlabs = [];            
                    for (let i in menu) {
                        this.menuSlabs.push(menu[i])
                       //if (menu[i].type==2) {
                       //    this.submenu1 = this.menuSlabs.length - 1;
                       //}
                       //if (menu[i].type==3) {
                       //    this.submenu2 = this.menuSlabs.length - 1;
                       //}
                    } 
                }
                console.log('nuevo menu =',this.menuSlabs);*/
            }
        },
    },
    mounted: function () {
        if (this.winnerWidth < 1050) {
            this.ShowHiddenAside();
        }
    },
};
</script>
<style lang="scss" scoped>
.left-sidebar {
    position: fixed;
    width: 100px;
    z-index: 100px;
}

.sidebar-nav ul .sidebar-item {
    width: 100px;
}
.btnAsideAction {
    position: absolute;
    bottom: -20px;
    top: initial;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 60px;
}
aside.left-sidebar.min .scroll-sidebar {
    height: 100%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction {
    bottom: 0px;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction i:before {
    content: "\f054";
}
aside.left-sidebar.min .scroll-sidebar nav.sidebar-nav ul#sidebarnav li.sidebar-item,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level
    li.sidebar-item {
    width: 60px;
    transition: all 0.5s ease-out;
    position: relative;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in {
    position: absolute;
    top: 0px;
    left: 40px;
    width: 170px;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link {
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    div.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level.in {
    display: none;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link
    span.hide-menu {
    display: block;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link {
    border-left: 2px solid transparent;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    a.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark.active {
    border-left: 2px solid #00b04b;
    opacity: 1;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level {
    background: #191919;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    transition: all 0.5s ease-out;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    -webkit-transform: rotate(135deg) translate(0, -50%) !important;
    transform: rotate(135deg) translate(0, -50%) !important;
}
a.sidebar-link.waves-effect.waves-dark {
    display: flex;
    flex-direction: column;
}
.sidebar-nav ul .sidebar-item .sidebar-link i {
    line-height: 10px;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .active.router-link-active {
    border-left: 2px solid #00b04b;
    color: #fff;
    opacity: 1;
}
#main-wrapper[data-layout="vertical"]
    .left-sidebar[data-sidebarbg="skin5"]
    .sidebar-nav
    > #sidebarnav
    > .sidebar-item
    > .sidebar-link:hover,
#main-wrapper[data-layout="horizontal"]
    .left-sidebar[data-sidebarbg="skin5"]
    .sidebar-nav
    > #sidebarnav
    > .sidebar-item
    > .sidebar-link:hover {
    border-left: 2px solid #00b04b;
}
span.hide-menu {
    margin-top: 5px;
}
@media (max-width: 1024px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 8px 8px;
    }
}
</style>
