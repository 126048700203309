<template>
    <BillingRestore>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Restore Expenses</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <div class="row">
                        <div id="ResposivetableExpenses" class="col-md-12">
                            <table
                                class="table table-striped table-bordered table-hover"
                                id="tableExpenses"
                            >
                                <thead>
                                    <tr>
                                        <th>Exp #</th>
                                        <th>Date</th>
                                        <th>Vendor</th>
                                        <th>Subcontractor</th>
                                        <th>Pmt Method</th>
                                        <th>Amount</th>
                                        <th>Att</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="isLoadingExpenses">
                                    <tr>
                                        <td colspan="9"><Loader></Loader></td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="ExpensesList.length > 0">
                                    <tr v-for="i in ExpensesList" v-bind:key="i">
                                        <td>{{ i.numdoc }}</td>
                                        <td>{{ i.fecha }}</td>
                                        <td>{{ i.vendor }}</td>
                                        <td>{{ i.subcon }}</td>
                                        <td>{{ i.formapago }}</td>
                                        <td>${{ i.amount | formatMoney }}</td>
                                        <td>
                                            <i
                                                class="fas fa-paperclip fa-lg"
                                                v-if="i.atta == 1"
                                            ></i>
                                        </td>
                                        <td>
                                            <span
                                                class="badge badge-success"
                                                v-if="i.status == 1"
                                                >PAID</span
                                            >
                                            <span
                                                class="badge badge-danger"
                                                v-else-if="i.status == 0"
                                                >VOID</span
                                            >
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                class="btn btn-warning"
                                                @click="setExpensesRestore(i.id)"
                                            >
                                                <i class="fas fa-share"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="9" class="text-center">no record</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </BillingRestore>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import BillingRestore from "@/apps/billing/Layouts/BillingRestore";
export default {
    components: {
        BillingRestore,
        Loader,
    },
    data() {
        return {
            isLoading: false,
            ExpensesList: [],
            isLoadingExpenses: true,
        };
    },
    created() {
        this.getExpensesRestore();
    },
    methods: {
        getExpensesRestore() {
            var self = this;
            this.isLoadingExpenses = true;
            window.billing
                .get("expensesrestore/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data.result);
                    self.ExpensesList = response.data.result;
                    self.isLoadingExpenses = false;
                })
                .catch((error) => console.log(error));
        },
        setExpensesRestore(id) {
            console.log("restaurar expense" + id);
            this.$swal({
                title: "Restore this Expense?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, restore!",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingExpenses = true;
                    window.billing
                        .post("expense/setrestore", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            //console.log(response.data);
                            this.getExpensesRestore();
                            this.$notify({
                                group: "noti",
                                title: "Success",
                                type: "success",
                                text: "Restore successfully",
                            });
                            this.isLoadingExpenses = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoadingExpenses = false;
                        });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#listExpenses {
    margin-bottom: 50px;
}
#tableExpenses thead tr th:nth-child(1),
#tableExpenses tbody tr td:nth-child(1) {
    min-width: 50px;
}
#tableExpenses thead tr th:nth-child(2),
#tableExpenses tbody tr td:nth-child(2) {
    min-width: 100px;
}
#tableExpenses thead tr th:nth-child(3),
#tableExpenses tbody tr td:nth-child(3),
#tableExpenses thead tr th:nth-child(4),
#tableExpenses tbody tr td:nth-child(4) {
    min-width: 180px;
}
#tableExpenses thead tr th:nth-child(5),
#tableExpenses tbody tr td:nth-child(5) {
    min-width: 140px;
}
#tableExpenses thead tr th:nth-child(6),
#tableExpenses tbody tr td:nth-child(6) {
    min-width: 100px;
    text-align: center;
}
#tableExpenses tbody tr td:nth-child(6) {
    text-align: right;
}
#tableExpenses thead tr th:nth-child(7),
#tableExpenses tbody tr td:nth-child(7),
#tableExpenses thead tr th:nth-child(8),
#tableExpenses tbody tr td:nth-child(8),
#tableExpenses thead tr th:nth-child(9),
#tableExpenses tbody tr td:nth-child(9) {
    text-align: center;
}
#ResposivetableExpenses {
    min-height: 250px;
}
@media (max-width: 1024px) {
    #ResposivetableExpenses {
        max-width: 1004px;
        overflow-x: scroll;
    }
    .textActionExpenses {
        display: none;
    }
    .ActionExpenses {
        padding: 10px 12px 10px 10px;
    }
}
</style>
