// @ts-nocheck
let actionPdf = function(id) {
	this.closeSubmenu(id);
	window.open(
		window.urlbase +
		"api/v2/invoice/pdf/" +
		id +
		"/" +
		window.localAccountID +
		"/" +
		localStorage.getItem("oauth_m"),
		"_blank"
	);
};
export default actionPdf;
