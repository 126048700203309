let dataJobDash = function(e, isAT, listTags) {
    let total = "";
    let list = [];
    for (const i in e) {
        //debugger;
        let nun = parseInt(e[i].id);
        if (nun <= 9) {
            total = "0000" + nun;
        } else if (nun >= 10 && nun <= 99) {
            total = "000" + nun;
        } else if (nun >= 100 && nun <= 999) {
            total = "00" + nun;
        } else if (nun >= 1000) {
            total = "0" + nun;
        } else {
            total = nun;
        }
        let customer_name = "None";
        let customer_businessname = "None";
        let invoice_name = "";
        let showInvo = 0;
        if (
            e[i].customer_name !== undefined &&
            e[i].customer_name != "" &&
            e[i].customer_name != null
        ) {
            customer_name = e[i].customer_name;
        }
        if (
            e[i].customer_businessname !== undefined &&
            e[i].customer_businessname != "" &&
            e[i].customer_businessname != null
        ) {
            customer_businessname = e[i].customer_businessname;
        } else {
            customer_businessname = customer_name;
        }
        if (e[i].invoice_name !== undefined) {
            invoice_name = e[i].invoice_name;
            showInvo = 1;
        }
        let taks = e[i].taks;
        for (let j in taks) {
            taks[j].isLoading = false;
            //taks[j].alto = 36;
            //console.log('alto', taks[j].alto);
        }
        let edit = false;
        if (this.typez == 0) {
            edit = true;
        } else if (isAT) {
            let t = e[i].tags;
            for (let k in t) {
                for (let l in listTags) {
                    if (t[k].id_tags == listTags[l].tags_id) {
                        edit = true;
                    }
                }
            }
        }
        let newLine = {
            date: e[i].date,
            counter: e[i].counter,
            id: total,
            id_i: e[i].id,
            ids: e[i].id,
            id_assign: 1,
            tags: e[i].tags,
            taks: taks,
            id_color: e[i].id_color,
            id_customer: e[i].id_customer,
            id_invoice: e[i].id_invoice,
            id_status: e[i].id_status,
            id_subcontractor: e[i].id_subcontractor,
            jsstatus: e[i].jsstatus,
            name: e[i].name,
            notes: e[i].notes,
            org_id: e[i].org_id,
            user_id: e[i].user_id,
            customer_name: customer_name,
            businessname: customer_businessname,
            invoice_name: invoice_name,
            showInvo: showInvo,
            selected: false,
            colorList: this.colorList,
            edicion: edit,
            cus: e[i].cus,
            gallery: e[i].gallery,
            galleryList: e[i].galleryList,
            link_drive: e[i].link_drive,
            url: e[i].url,
            user_id_assign: e[i].user_id_assign,
            pusher: 0,
        };
        list.push(newLine);
    }
    return list;
};
export default dataJobDash;
