<template>
    <div class="container-fluid myCards">
        <navTopbar></navTopbar>
        <div class="body_card">
            <div class="row" v-if="isLoading">
                <div class="col-12 text-center">
                    <Loader></Loader>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 ">
                    <firma1 v-if="listcard.length>0" :listData="listcard" :orgData="org" @editPerfil="openEdit($event)" @copyFormat="copySign($event)"></firma1>
                </div>
            </div><!-- modal popup-->
            <modal name="modal-editProfile" class="modal-editProfile" draggable=".window-header" width="400px" height="auto" @before-close="beforeClosedModal">
                <div class="card">
                    <button @click="ClosedModal" class="closeIcon"><span></span></button>
                    <component :is="nameComp" :account_id="editAccount_id" @setUpdate="updateList($event)"></component>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import navTopbar from "@/apps/Marketea/components/bSite/navTopbar.vue";
// @ts-ignore
import firma1 from "@/apps/Marketea/components/bSite/emailSingature/template1.vue";
// @ts-ignore
import editProfile from "@/core/components/Account/Users/editBasicProfile.vue";
import './index.scss';
export default {
    name: "mini_site",
    components: {
        Loader,
        navTopbar,
        firma1,
        editProfile,
    },
    data () {
        return {
            isLoading: false,
            listcard: [],
            org: {
                company_name: null,
                address: null,
                city: null,
                state: null,
                zip: null,
                Showlogo: null,
                logo: null,
                telephone: null,
                website: null,
            },
            userlist:[],
            editAccount_id: 0,
            roles:[],
            PlanPremium: false,
            planActual: 1,
            premiumProfile: false,
            planesPro: [6, 7, 10, 11, 12],
            nameComp: null,
            apis: null,
        }
    },
    created(){
        let t =this;
            let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
            t.apis = urlApis.bSite;
        // valid premium planID
        t.PlanPremium = (t.planesPro.indexOf(t.$store.getters['core/apps/getPlanIdApp'])>-1);
        console.log('PlanPremium', t.PlanPremium);
        t.planActual = t.$store.getters['core/apps/getPlanIdApp'];
        t.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        if(t.planActual==7){
            t.PlanPremium = true;
            console.log('Plan sponsored, dando permiso de premium', t.PlanPremium);
        }
        t.getAllRecords();
        // t.getUsersList();
    },
    watch: {
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined && t.getPusher != null) {
                if(t.getPusher.modulo!=null && t.getPusher.modulo == "bsite"){
                    let p = t.getPusher.data;
                    if(p.org_id == window.localorgdata){
                        // leer acciones
                        t.pusherActions(p);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            getPusher: "get_accitonPusher",
        }),
        ...mapGetters("ModApps/Marketea", {
            getListcard: "get_bsite_listcard",
        }),
    },
    methods: {
        getAllRecords(load){
            let t = this;
            if(load==undefined){
                t.isLoading=true;
            }
            window.master.post(t.apis.pull(0), {
                user_id: window.master_client,
                org_id: window.localorgdata,
                search: '',
                socket_id: pusher.connection.socket_id
            })
            .then((response) => {
                console.log(t.apis.pull(0), response.data);
                t.listcard=response.data.r
                t.getOrg();
            }).catch((error) => {
                console.log(error);
                t.isLoading = false;
            });
        },
        pusherActions(p){
            console.log('pusherActions', p)
        },
        getUsersList() {
            let t = this;
            t.isLoading=true;
            let url = "/users/" + window.master_client;
            window.master.post('usersListSec', {
                user_id: window.master_client,
                org_id: window.localorgdata,
            })
            .then((response) => {
                let r = response.data;
                t.roles= response.data.roles;
                t.userlist=[{
                    id: r.org.id,
                    account_id: r.org.account_id,
                    owner: true,
                    rol: 0,
                    rolName: 'Owner',
                    firstname: r.org.bill_firstname,
                    lastname: r.org.bill_lastname,
                    email: r.org.bill_email,
                    telephone: r.org.bill_telephone,
                    pref_name: r.org.pref_name+' '+r.org.pref_lastname,
                    pref_email: r.org.pref_email,
                    imgAvatar: false,
                }];
                for (let i in r.users) {
                    if(r.users[i]){
                        t.userlist.push({
                            id: r.users[i].id,
                            account_id: r.users[i].account_id,
                            owner: false,
                            firstname: r.users[i].firstname,
                            lastname: r.users[i].lastname,
                            email: r.users[i].email,
                            telephone: r.users[i].telephone,
                            pref_name: r.users[i].pref_name+' '+r.users[i].pref_lastname,
                            pref_email: r.users[i].pref_email,
                            rol: r.users[i].rol,
                            rolName: (r.users[i].rol>0) ? t.roles.filter(e => {if(e.id==r.users[i].rol){ return e}})[0].name : 'Uncategorized',
                            avatar: r.users[i].ruta + r.users[i].avatar,
                            imgAvatar: r.users[i].imgAvatar,
                        });
                    }
                }
                console.log('usersListSec', t.userlist);
                t.getOrg()
            })
            .catch((error) => {
                console.log(error);
                t.isLoading=false;
            });
        },
        getOrg() {
            let t = this;
            let getOrg = this.$store.getters['core/Org/orginf'];
            if (getOrg != null) {
                console.log('getters FormInfo', getOrg);
                t.org.company_name = getOrg.com_company_name;
                t.org.telephone = getOrg.com_telephone;
                t.org.website = getOrg.com_website;
                t.org.address = getOrg.com_address;
                t.org.city = getOrg.com_city;
                t.org.state = getOrg.com_state;
                t.org.zip = getOrg.com_zip_code;
                t.org.Showlogo = false;
                t.org.logo = null;
                window.master.get("bg/logo/" + window.localorgdata + "/" + +window.master_client)
                .then((response) => {
                    console.log('bg/logo', response.data);
                    t.org.Showlogo = response.data.Showlogo;
                    t.org.logo = response.data.logo;
                    t.isLoading=false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading=false;
                });
            }
        },
        ClosedModal() {
            this.$modal.hide("modal-editProfile");
        },
        beforeClosedModal(){
            this.nameComp = null;
            localStorage.removeItem('edirUrl');
        },
        ShowdModal(n) {
            this.nameComp = n;
            this.$modal.show("modal-editProfile");
        },
        openEdit(id) {
            this.editAccount_id = id;
            this.ShowdModal('editProfile');
        },
        updateList($event){
            console.log('updateList', $event)
            this.getUsersList();
        },
        copySign(id){
            let t = this;
            console.log('copySign', id);
            if(document.getElementById('singature-'+id)){
                let singature = document.getElementById('singature-'+id);
                singature.style.backgroundColor='#ddd';
                let aux = document.createElement("div");
                aux.setAttribute("contentEditable", true);
                aux.innerHTML = singature.innerHTML;
                aux.id= "copyContent";
                aux.style.background= "#fff";
                aux.style.position= "absolute";
                aux.style.top= "-9999px";
                aux.onfocus= function(){
                    console.log('focus selected content')
                    document.execCommand('selectAll', false, null);
                };
                document.body.appendChild(aux);
                setTimeout(() => {
                    singature.style.backgroundColor='#ccc';
                    if(document.getElementById('copyContent')){
                        let c = document.getElementById('copyContent');
                        c.focus();
                        document.execCommand("copy");
                        setTimeout(() => {
                            document.body.removeChild(c);
                            singature.style.backgroundColor='#fff';
                            t.$notify({
                                group: "noti",
                                type: "success",
                                title: "Copy",
                                text: 'Signature copied successfully',
                            });
                        }, 500);
                    }else{singature.style.backgroundColor='#fff';}
                }, 200);
            }
        },
    }
}
</script>
