import apisLeads from './apisLeads';
import apisForm from './apisForm';
import apisGoogle from './apisGoogle';
import apisBtncallLink from './apisBtncallLink';
import apisAds from './apisAds';
import apisLandingPage from './apisLandingPage';
import apisbSite from './apisbSite';
import apisProjects from './apisProjects';
import apisbmenu from './apisbmenu';
export default {
	leads : apisLeads,
	Form : apisForm,
	google : apisGoogle,
	btnCall : apisBtncallLink,
	apisAds : apisAds,
	landingPage : apisLandingPage,
	bSite : apisbSite,
	Projects : apisProjects,
	bMenu : apisbmenu,
};
