<template>
    <div class="card">
        <div class="card-header window-header">
            <h4 style="margin-bottom: 0px;"> Code Embed</h4>
            <button @click="$parent.close()" class="closeIcon"><span></span></button>
        </div>
        <div class="card-body">
            <h3>Paste the Code HTML</h3>
            <form class="" @submit.prevent="insertIframeWithHTML()" style="margin-bottom: 10px;">
                <div class="input-group" style="padding:0px;">
                    <input type="text" class="form-control" v-model="codeHTML"
                    required placeholder="Code HTML"
                    style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;font-size: 10px;height: 35px;"/>
                    <span class="input-group-text" style="padding:0px;background: transparent;border:0px;line-height: 1;">
                        <button type="submit"
                        class="btn btn-success"
                        style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                        >Save</button>
                    </span>
                </div>
            </form>
            <h3>Or Url</h3>
            <form class="" @submit.prevent="insertIframeWithURL()">
                <div class="input-group" style="padding:0px;">
                    <input type="text" class="form-control" v-model="url"
                    required placeholder="https://domain.com/embed"
                    style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;font-size: 10px;height: 35px;"/>
                    <span class="input-group-text" style="padding:0px;background: transparent;border:0px;line-height: 1;">
                        <button type="submit"
                        class="btn btn-success"
                        style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                        >Save</button>
                    </span>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: [Number, String, Object, Array],
            default: null,
            require: false,
        },
    },
    data(){
        return {
            codeHTML: null,
            url: null,
        };
    },
    created(){
        let t = this;
        if(t.data!=null){
            t.codeHTML=t.data;
            t.url=t.data;
        }
    },
    methods: {
        insertIframeWithHTML(){
            let t = this;
            let urlIframe = null;
            console.log('event input', t.codeHTML)
            let iframe = t.codeHTML.split(' ');
            for (const i in iframe) {
                if (iframe[i].indexOf('src="')>-1){
                    urlIframe= iframe[i].slice(5, iframe[i].length-1);
                }
            }
            t.$emit('actionModal', {
                action: 'iframe',
                url: urlIframe,
            });
        },
        insertIframeWithURL(){
            let t = this;
            t.$emit('actionModal', {
                action: 'iframe',
                url: t.url,
            });
        }
    }
}
</script>
<style lang="scss" scoped>
small.form-text.text-muted{
    display: block;
    font-size: 10px;
}
</style>
