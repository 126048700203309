<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="page-title"><i class="fas fa-cog"></i> Setup</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Leads Setting
                            </h4>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="editOptions(0)" 
                            >
                                Marketing Source
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="editOptions(1)" 
                            >
                                Quality
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="editOptions(2)"
                            >
                                CTA (Call to Action)
                            </div> 
                            <!--<router-link
                                :to="{ name: 'BuilderForms' }"
                                class="list-group-item list-group-item-action"
                                >Edit Form</router-link
                            >-->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Btn Call Link
                            </h4>
                            <router-link
                                :to="{ name: 'btnCallLink' }"
                                class="list-group-item list-group-item-action"
                                >Create Call Link
                            </router-link>
                            <h4
                                style="
                                    color: #9c9c9c;
                                    margin-top: 30px;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Smard Card
                            </h4>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('orgUrl')"
                            >
                                Organization Information
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Forms Setting
                            </h4>
                            <router-link
                                :to="{ name: 'listForms' }"
                                class="list-group-item list-group-item-action"
                                >List of Forms
                            </router-link>
                            <router-link
                                :to="{ name: 'AlertForm' }"
                                class="list-group-item list-group-item-action"
                                >Alert & Response
                            </router-link> 
                            <router-link
                                :to="{ name: 'notiForms' }"
                                class="list-group-item list-group-item-action"
                                >Notificacions
                            </router-link>  
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('fnoti')" 
                            >
                                SMS Form Notification
                            </div>                                                       
                        </div>
                    </div>                                        
                </div>
            </div>
        </div>
        <!-- modal popup-->
        <modal name="modal-setting" height="auto" @before-close="ClosedModal">
            <div class="card">
                <button @click="closeModal" class="closeIcon"><span></span></button>
                <component :is="SettingBox"></component>
            </div>
        </modal>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import fnoti from "@/apps/Marketea/components/notiSmsForm.vue";
import copycode from "@/apps/Marketea/components/FormsCopyCode.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import SettingLeads from '@/apps/Marketea/components/SettingLeads'
import orgUrl from '@/apps/Marketea/components/bSite/orgUrl'
import { mapGetters } from "vuex";
export default {
    name: "Setup",
    components: {
        Loader,
        fnoti,
        copycode,
        modalLateral,
        SettingLeads,
        orgUrl,
    },
    data() {
        return {
            SettingBox: "",
            m: {
                show: false,
                component: '',
                data: []
            },  
        };
    },
    methods: {
        editOptions(num) {
            let t = this;
            t.m.show= true;
            t.m.component= 'SettingLeads';
            if(num == 0){t.m.data= { opts: 0 };}
            if(num == 1){t.m.data= { opts: 1 };}
            if(num == 2){t.m.data= { opts: 2 };}
            if(num == 3){t.m.data= { opts: 3 };}
            this.$store.commit('core/setOpenModal', t.m);
        },
        ModalBox(i) {
            this.SettingBox = i;
            this.$modal.show("modal-setting");
        },
        closeModal() {
            this.$modal.hide("modal-setting");
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        resfresh(res) {
            console.log(res.open);
            if (res.open == 1) {
                this.ClosedModal();
                this.closeModal();
            }
        },
    },
    watch: {
        get_settupStorage: function () {
            if (this.get_settupStorage) {
                let g = this.$store.getters["ModApps/Marketea/get_settupStorage"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "Noti") {
                    this.resfresh(r);
                }
            }
        },
        SettingBox: function () {
            if (!this.SettingBox == "") {
                this.$modal.show("modal-setting");
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_settupStorage"]),
    },
};
</script>
