/*rutas app de Setting*/
import Setting from '@/apps/billing/views/Setting';
import Taxes from '@/apps/billing/views/setting/Taxes';
import Terms from '@/apps/billing/views/setting/Terms';
import SettingCompanyInfo from '@/apps/billing/views/setting/CompanyInfo';
import Settingeprint from '@/apps/billing/views/setting/EprintSetting';
import ContractTemplates from '@/apps/billing/views/setting/ContractTemplates';
import EmailMessageEstimate from '@/apps/billing/views/setting/EmailMessageInfo';
import EmailMessageInvoice from '@/apps/billing/views/setting/EmailMessageInv';
import EmailMessageInvVoid from '@/apps/billing/views/setting/EmailMessageInvVoid';
import Backgrounds from '@/apps/billing/views/setting/Backgrounds';
import ChartAccounts from '@/apps/billing/views/setting/ChartAccounts';
import ProductServices from '@/apps/billing/views/setting/ProductServices';
import EmailMessageStatement from '@/apps/billing/views/setting/EmailMessageStatement';
import squareAccount from '@/apps/billing/views/setting/squareAccount';
export default [
	{
		path: 'setting',
		name: 'Setting',
    folder: Setting,
  },  
  {
    path: 'setting/taxes',
    name: 'Taxes',
    folder: Taxes,
  },
  {
    path: 'setting/terms',
    name: 'Terms',
    folder: Terms,
  },
  {
    path: 'setting/companyinfo',
    name: 'SettingCompanyInfo',
    folder: SettingCompanyInfo,
  },
  {
    path: 'setting/eprint-setup',
    name: 'Settingeprint',
    folder: Settingeprint,
  },
  {
    path: 'setting/contract-templates',
    name: 'ContractTemplates',
    folder: ContractTemplates,
  },
  {
    path: 'setting/emailestimate',
    name: 'EmailMessageEstimate',
    folder: EmailMessageEstimate,
  },
  {
    path: 'setting/emailinvoice',
    name: 'EmailMessageInvoice',
    folder: EmailMessageInvoice,
  },
  {
    path: 'setting/email/invoice/void',
    name: 'EmailMessageInvVoid',
    folder: EmailMessageInvVoid,
  },
  {
    path: 'setting/emailstatement',
    name: 'EmailMessageStatement',
    folder: EmailMessageStatement,
  },  
  {
    path: 'setting/chart-accounts',
    name: 'ChartAccounts',
    folder: ChartAccounts,
  },
  {
    path: 'setting/product-services',
    name: 'ProductServices',
    folder: ProductServices,
  },
  {
    path: 'setting/backgrounds',
    name: 'Backgrounds',
    folder: Backgrounds,
  },
  {
    path: 'setting/square-account',
    name: 'squareAccount',
    folder: squareAccount,
  },
];
