<template>
    <div class="card" v-if="isLoading"><Loader></Loader></div>
    <div class="card" v-else>
        <div class="card-header">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="card-title">
                        {{ customerDetail.firstname }}
                        {{ customerDetail.lastname }}
                        <span 
                            class="badge badge-danger inactivo" 
                            v-if="customerDetail.active==0"
                        >
                            Inactive
                        </span>
                        <span 
                            class="badge badge-success inactivo" 
                            v-if="customerDetail.active==1"
                        >
                            Active
                        </span>
                    </h3>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12 col-md-3 text-center">
                            <button @click="MetEditCustomers" class="btn btn-info btn-sm eboton">
                                <i class="fas fa-pen"></i> Edit
                            </button>
                        </div>
                        <div class="col-12 col-md-5">
                            <select class="form-control" @change="getTransactions()"
                                v-model="transactionsID">
                                <option value="0" label="All">
                                    All
                                </option>
                                <option value="1" label="Estimates">
                                    Estimates
                                </option>
                                <option value="2" label="Invoices">
                                    Invoices
                                </option>
                                <option value="3" label="Recurring Invoices">
                                    Recurring Invoices
                                </option>
                                <option value="4" label="Payments">
                                    Payments
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="cdropdown">
                                <button class="btn btn-transaction">
                                    NEW
                                </button>
                                <label>
                                    <input type="checkbox" />
                                    <ul class="text-left">
                                        <li @click="estimateNewtransac(customerDetail.id)">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Estimate
                                            </button>
                                        </li>
                                        <li @click="invoiceNewtransac(customerDetail.id)">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Invoice
                                            </button>
                                        </li>
                                        <li @click="recurringNewtransac(customerDetail.id)">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Recurring Invoice
                                            </button>
                                        </li>
                                        <li @click="paymentsNewtransac(customerDetail.id)">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Payment
                                            </button>
                                        </li>
                                        <!-- <li @click="chartAccounts()">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Chart of Accounts
                                            </button>
                                        </li>
                                        <li @click="productServices()">
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Products &amp; Service
                                            </button>
                                        </li>
                                        <li>
                                            <button>
                                                <i class="fas fa-file"></i>
                                                Projects
                                            </button>
                                        </li> -->
                                    </ul>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 resumen-data">
                    <h6 class="card-subtitle mb-2 text-muted">
                        {{ customerDetail.bussinesname }}
                    </h6>
                    <h6 v-if="customerDetail.billingaddress !== null">
                        <span>{{customerDetail.billingaddress}}</span>&nbsp;
                        <span v-if="customerDetail.bcity !== null">{{ customerDetail.bcity }}</span>,&nbsp;
                        <span v-if="customerDetail.bstate !== null">{{ customerDetail.bstate }}</span>&nbsp;
                        <span v-if="customerDetail.bpostcode !== null">{{ customerDetail.bpostcode }}</span>
                    </h6>
                   <h6 class="mb-2">
                        <span style="color: #9e9e9e">Cell: </span>
                        <span v-if="customerDetail.mobilephone !== null">{{ customerDetail.mobilephone }}</span> | 
                        <span style="color: #9e9e9e">Office: </span>
                        <span v-if="customerDetail.officephone !== null">{{ customerDetail.officephone }}</span> 
                    </h6>
                    <h6>
                        <span v-if="customerDetail.email !== null">{{ customerDetail.email }}</span>
                    </h6>
                    <pre v-if="customerDetail.notes !== null"><b><span style="color: #9e9e9e;">NOTES: </span> </b> <span class="notas-customer">{{customerDetail.notes}}</span></pre>
                </div>
                <div class="col-md-6 resumen-saldo">
                    <div class="card">
                        <div class="card-body contenedor-total">
                            <div class="outFilterDate">
                                <b>Date:</b> {{outFilterDate}}
                            </div>
                            <div class="alert alert-success alert_totales_customer" role="alert">
                                TOTAL SALES:
                                <h1>
                                    <span class="badge badge-success">${{totalSales | formatMoney}}</span>
                                </h1>
                            </div>
                            <div class="alert alert-danger alert_totales_customer" role="alert">
                                OVERDUE:
                                <h1>
                                    <span class="badge badge-danger">${{overdue | formatMoney}}</span>
                                </h1>
                            </div>

                            <span class="cdropdown btnFtotales">
                                <button>
                                    <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <label>
                                    <input type="checkbox" />
                                    <ul>
                                        <li @click="filterTotales(0)">{{ botonAction[0] }}</li>
                                        <li @click="filterTotales(1)">{{ botonAction[1] }}</li>
                                        <li @click="filterTotales(2)">{{ botonAction[2] }}</li>
                                        <li @click="filterTotales(3)">{{ botonAction[3] }}</li>
                                        <li @click="filterTotales(4)">{{ botonAction[4] }}</li>
                                    </ul>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <listCustomer></listCustomer>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader.vue'
import listCustomer from '@/apps/billing/components/customer/listCustomer'
export default {
    name: "dataCustomer",
    components: {
        Loader,
        listCustomer
    },
    data () {
        return {
            isLoading:false,
            customerDetail:{
                acity: null,
                active: 0,
                activorestore: 0,
                address: null,
                apostcode: null,
                astate: null,
                balance: 0,
                bcity: "",
                billingaddress: "",
                bpostcode: "",
                bstate: "",
                bussinesname: null,
                created_at: "",
                email: "",
                fecdelete: null,
                firstname: "",
                id: 0,
                lastname: "",
                mobilephone: null,
                notes: "",
                officephone: "",
                referal: null,
                totalSales: 0,
                updated_at: "",
                user_id: 0,
                website: null               
            },
            transactionsID: 0,
            paginador: 100,
            customerID: 0,
            transactionsList: [],
            overdue:0,
            totalSales:0,
            botonAction: [
                "Default",
                "This Year",
                "Previous Year",
                "This Month",
                "Previous Month",
            ],
            filterdate: 0,
            outFilterDate: '',
        }
    },
    created () {
        this.customerID = this.$store.getters['ModApps/billing/getCustomerID'];
        if (this.customerID > 0) {
            this.detailCustomer(this.customerID)
        }
    },
    methods: {
        filterTotales(i){
            this.filterdate = i;
            this.detailCustomer(this.customerID)
        },
        detailCustomer(idc) {
            this.isLoading = true
            this.customerID = idc;
            window.billing
                .post("customer/" + idc, {
                    user_id: window.localuserdata,
                    trans: 0,
                    filterdate: this.filterdate,
                    pag: this.paginador,
                })
                .then((response) => {
                    let r = response.data.result;
                    this.customerDetail = r[0]
                    this.outFilterDate=response.data.outFilterDate;
                    console.log('actualizando customerDetail', r[0]);
                    this.$store.commit('ModApps/billing/setCustomerByID', r[0]);
                    this.overdue = response.data.result[0].balance;
                    this.totalSales = response.data.result[0].totalSales;
                    let transac = response.data.trasaction;
                    this.transactionsList = transac
                    this.$store.commit('ModApps/billing/setCustomerData', transac);
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.isLoading = false
                });
        },        
        MetEditCustomers() {
            this.$store.commit('ModApps/billing/setFormCustomer', true);
        }, 
        getTransactions() {
            this.tablaValid = false;
            window.billing
                .post("customer/" + this.customerID, {
                    user_id: window.localuserdata,
                    trans: this.transactionsID,
                    pag: this.paginador,
                })
                .then((response) => {
                    let transac = response.data.trasaction;
                    this.transactionsList = transac;
                    if (this.transactionsList.length > 0) {
                        this.tablaValid = true;
                        this.transacValid = true;
                        for (let i in transac) {
                            if (transac[i].type == "Invoice") {
                                let f = new Date(transac[i].fv);
                                let fa = new Date();
                                if (
                                    fa > f &&
                                    transac[i].status != 3 &&
                                    transac[i].status != 5 &&
                                    transac[i].status != 2
                                ) {
                                    transac[i].status = 4;
                                }
                            }
                        }
                        this.$store.commit('ModApps/billing/setCustomerData', transac);
                    } else {
                        this.tablaValid = false;
                        this.transacValid = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        estimateNewtransac(idc) {
            let t =this;
            let planPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
            if(!planPremium){
                t.$router.push({
                    name: "EstimatesNewTransac",
                    params: { idc: idc, transac: "transac" },
                });
            }else{
                t.$router.push({
                    name: "EstimatesNewTransac",
                    params: { idc: idc, transac: "transac" },
                });
                // t.$router.push({
                //     name: "estproFormcustomerID",
                //     params: { idc: idc},
                // });
            }
        },
        invoiceNewtransac(idc) {
            this.$router.push({
                name: "InvoiceNewTransac",
                params: { idc: idc, transac: "transac" },
            });
        },
        paymentsNewtransac(idc) {
            this.$router.push({
                name: "PaymentsReceivedNewTransac",
                params: { idc: idc, transac: "transac" },
            });
        },
        chartAccounts() {
            this.$router.push({ name: "ChartAccounts" });
        },
        productServices() {
            this.$router.push({ name: "ProductServices" });
        }, 
        recurringNewtransac(idc) {
            this.$router.push({
                name: "InvoiceRecTransac",
                params: { idc: idc, transac: "transac" },
            });
        },                            
    },
	computed: {
		...mapGetters("ModApps/billing", { 
			getCustomerID: "getCustomerID",
		}),        
	},
	watch: {
		getCustomerID: function () {  
            if (this.getCustomerID > 0) {
                this.filterdate = 0;
                this.outFilterDate='';
                this.detailCustomer(this.getCustomerID)
			}
		},
    },
};
</script>
<style lang="scss" scoped>
    .inactivo { 
        border-radius: 10px;
        font-size: 12px;
        padding: 3px 8px;
    }
    .resumen-data {height: 150px;}
    .resumen-saldo {height: 100px;}
    pre {
        font-family: "ProximaNova", sans-serif;
       white-space: break-spaces;
    }
    .resumen-data {
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 5px;
    }
    .notas-customer {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6A7A8C;
        font-family: "ProximaNova", sans-serif;
        height: 40px;
    }
    .eboton i {
        font-size: 12px;
    }
    .eboton {
        padding: 5px 10px;
        font-size: 14px;
        margin-left: 10px;
        color: #fff;
        background-color: #137eff !important;
        border-color: #137eff !important;
    }  
    .btn-transaction {
        color: #fff !important;
        background-color: #137eff !important;
        border-color: #137eff !important;
        border-radius: 2px !important;
    }      
    @media (max-width: 767px) {
        span.cdropdown {
            width: 100%;
        }
        span.cdropdown .btn.btn-transaction {
            width: 100%;
        }
        .btn.btn-info.btn-sm.eboton {
            width: 100%;
            margin: 0px;
            margin-bottom: 10px;
        }        
    } 
    .contenedor-total {
        padding: 0rem;
    }
    .alert_totales_customer {
        display: inline-block;
        padding: 0.2rem 0.5rem;
        width: 50%;
        text-align: center;
    }

    .alert_totales_customer h1 span {
        font-size: 20px;
    }
    @media (max-width: 1190px) {
        .alert_totales_customer {
            display: inline-block;
            width: 50%;
            text-align: right;
        }

        .alert_totales_customer h1 span {
            font-size: 14px;
        }
    }
    @media (max-width: 991px) {
        .alert_totales_customer {
            display: inline-block;
            width: 100%;
            text-align: right;
        }

        .alert_totales_customer h1 span {
            font-size: 18px;
        }
    }  
    span.cdropdown.btnFtotales {
        position: absolute;
        z-index: 1;
        right: -20;
    }
    span.cdropdown.btnFtotales button {
        border: 0px solid #ccc;
        border-radius: 10px;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #000;
        font-size: 16px;
    }
    span.cdropdown.btnFtotales button:before {
        border-top: 5px solid transparent;
        content: initial;
    }  
    .outFilterDate{
        position: relative;
        z-index: 1;
        right: 0;
        text-align: right;
    }
</style>
