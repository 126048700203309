let jobsinvo = function() {
	let t = this;
	let jobs = 0;
	if(t.accessJobsApp){jobs=1;}
	let listinvo = [];
	for (let i in t.InvoicesList) {
		if(t.InvoicesList[i].id){
			listinvo.push(t.InvoicesList[i].id);
		}
	}
	if(listinvo.length>0){
		window.masterGetBilling
		.get("getJobsForBilling/"+window.localuserdata+'/'+listinvo.toString())
		.then((response) => {
			let r = response.data.r;
			if(r.length>0){
				console.log('getJobsForBilling', response.data);
				for (let i in r) {
					let item = r[i];
					for (let e in t.InvoicesList) {
						let v = t.InvoicesList[e];
						if(item.id_invoice==v.id && item.id_customer==v.customer_id){
							t.InvoicesList[e].job=item;
						}
					}		
				}		
				t.InvoicesListResult = t.InvoicesList;		
			}
		})
		.catch((error) => {
			t.isLoading = false;
			console.log(error);
		});
	}else{
		console.log('listinvo empty', listinvo)
	}
};
export default jobsinvo;
