<template>
    <div class="card">
        <div class="card-body" style="padding-top:0px;">
            <div class="card-header">
                <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-info" @click="printPdf()" v-if="f.id>0">
                                <i class="fas fa-print"></i> Print
                            </button>
                            <button type="button" class="btn btn-success" @click="saveForm" style="margin-left: 10px;">
                                <i class="fas fa-save"></i> Save
                            </button>
                            <button type="button" class="btn btn-secundary" 
                                style="margin-left: 10px;background-color:#fff;border-color:#ccc;" 
                                @click="closeModal()"
                            >
                                <i class="fas fa-times"></i> Cancel
                            </button>
                        </div>
                    </div>
                    <!-- <button @click="closeModal()" class="closeIcon"><span></span></button> -->
            </div>
            <div v-if="isLoading"><Loader></Loader></div>
            <form v-else @submit.prevent="saveForm" >
                <div class="form-group col-sm-12">
                    <textarea v-model="f.task_name" class="form-control title_know" maxlength="60" ref="task_name" @input="auto_height($refs.task_name)" placeholder="Title here"></textarea>
                </div>
                <!-- <div class="col-sm-12">
                    <h4>What to do</h4>
                </div> -->
                <draggable
                    tag="div"
                    class="col-sm-12"
                    :list="f.actions"
                    v-bind="dragActions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    handle=".moveItem"
                    group="sections"
                    @change="moveSection"
                    @add="addItem"
                >
                    <div v-for="(a, idx) in f.actions" :key="idx" :class="['row', (f.actions.length>0) ? 'row_hr' : '']">
                        <div class="form-group col-md-12" style="margin-bottom: 5px;">
                            <input type="text" class="form-control action_name" v-model="a.accion_name" placeholder="Action name:" required>
                        </div>
                        <div :class="'form-group col-md-12 descrip_action_know editor-'+idx">
                            <vue-editor
                            v-model="a.accion_descrip"
                            :editor-toolbar="customToolbar"
                            :customModules="customModulesForEditor"
                            :editorOptions="editorSettings"
                            useCustomImageHandler
                            @image-added="handleImageAdded"
                            @image-removed="handleImageDeleted"
                            class="descripcion"
                            placeholder="Action Details:"
                            />
                            <button type="button" class="btn btn-primary moveItem" v-if="f.actions.length>1">
                                <i class="fas fa-arrows-alt-v"></i>
                                <span class="tooltip">Move</span>
                            </button>
                            <button type="button" class="btn btn-danger delAction" @click="deleteAction(a, idx)" v-if="f.actions.length>1">
                                <i class="fas fa-trash"></i>
                                <span class="tooltip">Delete</span>
                            </button>
                            <button type="button" class="btn btn-danger delAction" disabled v-else>
                                <i class="fas fa-trash"></i>
                                <span class="tooltip">Delete</span>
                            </button>
                            <div class="editor-resizer" @mousedown="initResize($event)" @touchstart="initResize($event)"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <button type="button" class="btn btn-info" @click="newAction">
                                <i class="fas fa-plus"></i> Add Action
                            </button>
                        </div>
                    </div>
                </draggable>
                <!-- <div class="row">
                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success">
                            <i class="fas fa-save"></i> Save
                        </button>
                        <button type="button" class="btn btn-secundary" 
                            style="margin-left: 10px;background-color:#fff;border-color:#ccc;" 
                            @click="closeModal()"
                        >
                            <i class="fas fa-times"></i> Cancel
                        </button>
                    </div>
                </div> -->
            </form>
        </div>
        <!-- <div class="modal-resizer" @mousedown="initResize"></div> -->
    </div>
</template>
<script>
import '@/apps/billing/components/smartOffice/form_task.scss';
import Loader from '@/core/components/shared/Loader';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from "quill-image-resize-module";
import draggable from 'vuedraggable'
export default {
	name: 'form_knowledge',
    components: {
        Loader,
        VueEditor,
        draggable,
    },
    props:{
        itemDisplay: {
            type: Object,
            require: false,
            default: () => {}
        }
    },
	data () {
		return {
            isLoading: false,
            f: {
                id: 0,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
				oauth_m: window.oauth_m,
                task_name: '',
                actions: [{
                    id: 0,
                    accion_name: '',
                    accion_descrip: '',
                }],
                socket_id: pusher.connection.socket_id,
            },
            customModulesForEditor: [
                { alias: "imageDrop", module: ImageDrop },
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                theme: 'bubble',
                modules: {
                    imageDrop: true,
                    imageResize: ImageResize
                }
            },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "image"],
                ["clean"] // remove formatting button
            ],
            itemThis: null,
            heightEditor: 160,
		}
	},
	created(){
        this.init();
        console.log('form', this.f)
	},
    mounted(){
        let t = this;
        var qlEditor = document.querySelectorAll('#app .descrip_action_know .ql-bubble .ql-editor ');
        if(qlEditor){
            qlEditor.forEach(element => {
                element.addEventListener('click', function(e){
                    // console.log('click in', this)
                    // ql-tooltip
                    let qlTooltip = this.nextSibling.nextSibling.classList.contains('ql-hidden');
                    console.log('qlTooltip', qlTooltip);
                    if(qlTooltip){
                        this.nextSibling.nextSibling.classList.toggle('ql-hidden');
                    }
                });
            });
        }
        setTimeout(() => {
            t.auto_height(t.$refs.task_name);
        }, 1000);
        // vmodal.style.left = (window.innerWidth-vmodal.offsetWidth)+'px)';
    },
	methods: {
        init(){
            let t = this;
            if(Object.keys(t.itemDisplay).length>0){
                t.f.id = t.itemDisplay.id;
                t.f.task_name = t.itemDisplay.task_name;
                t.f.actions = t.itemDisplay.actions;
            }
        },
        initResize(e) {
            let t = this;
            //console.log(e.currentTarget.parentElement)
            t.itemThis = e.currentTarget.parentElement;
            window.addEventListener('mousemove', t.vResize, false);
            window.addEventListener('touchmove', t.vResize, false);
            window.addEventListener('mouseup', t.stopResize, false);
            window.addEventListener('touchend', t.stopResize, false);
        },
        vResize(e) {
            let t = this;
            if(t.itemThis){
                let alto = 160;
                if(e.clientY != undefined){ alto = e.clientY;}
                else if(e.changedTouches != undefined){ alto = e.changedTouches[0].clientY;}
                //console.log(t.itemThis, alto)
                let elemt = t.itemThis.classList.toString().replaceAll(' ', '.')
                let editor = document.querySelector('#app .'+elemt+' .ql-editor');
                if(editor){
                    editor.style.height = (alto - editor.offsetTop) + 'px';
                }
            }
        },
        stopResize(e) {
            let t = this;
            window.removeEventListener('mousemove', t.vResize, false);
            window.removeEventListener('touchmove', t.vResize, false);
            window.removeEventListener('mouseup', t.stopResize, false);
            window.removeEventListener('touchend', t.stopResize, false);
        },
		saveForm(event){
            let t = this;
            t.isLoading=true;
            let url = 'addTaskKno';
            if(t.f.id>0){
                url='updateFromTaskKno';
            }
			window.billing
            .post(url, t.f)
            .then((response) => {
                console.log(url, response.data);
                if(t.f.id==0){
                    t.$emit('setEmitForm', {action: 'add', item: response.data.r});
                }else{ t.$emit('setEmitForm', {action: 'update', item: response.data.r});}
				t.isLoading=false;
                t.closeModal();
            })
            .catch((error) => {
                console.log(error);
				t.isLoading=false;
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            let t = this;
            var setImage = new FormData();
            setImage.append("user_id", t.f.user_idd);
            setImage.append("org_id", t.f.org_id);
            setImage.append("folder", 'so_task');
            setImage.append("img1", file, file.name);
            setImage.append("nombreIMG", "img");
            setImage.append("task_id", t.f.task_id);
            setImage.append("contIMG", 1);
            setImage.append("delIMG", "imgOld");
            setImage.append("contDel", 0);
            window.masterGetBilling
            .post("subirIMG", setImage)
            .then(result => {
                let url = window.urlmaster+'images/no-image.png'; // imagen x defaul
                let name_image = '';
                if(result.data.imgs["1"]){
                    url = window.urlmaster + result.data.rooPath.slice(1) + result.data.imgs["1"]; 
                    name_image = result.data.imgs["1"];
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                }else{
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: "Image upload failed, please try again.",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
        },
        handleImageDeleted: function (file, Editor, cursorLocation, resetUploader) {
            console.log('handleImageDeleted', file, Editor, cursorLocation, resetUploader)
            let t = this;
            file = file.split('so_task/');
            var setImage = new FormData();
            setImage.append("user_id", t.f.user_idd);
            setImage.append("org_id", t.f.org_id);
            setImage.append("folder", 'so_task');
            setImage.append("image", file[1]);
            window.masterGetBilling
            .post("delOnlyIMG", setImage)
            .then(result => {
                let url = window.urlmaster+'images/no-image.png'; // imagen x defaul
                let name_image = '';
                if(!result.data.status){
                    console.log('delOnlyIMG', result.data)
                }else{
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: "Image upload failed, please try again.",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
        },
        closeModal(){
            this.$modal.hide('modalKnowledge');
        },
        newAction(){
            let t= this;
            let newItem = {id: 0, accion_name: '',accion_descrip: ''};
            t.f.actions.push(newItem);
        },
        deleteAction(action, idx){
            let t= this;
            t.f.actions.splice(idx, 1);
            let itemAction = action;
            itemAction.socket_id= pusher.connection.socket_id;
            window.billing
            .post('deleteActionknow', itemAction)
            .then((response) => {
                console.log('deleteActionknow', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        auto_height(element) {
            element.style.height = "34px";
            element.style.height = (element.scrollHeight) + "px";
        },
        isDragging(t) {
            console.log('move', t) // t = true or false
            return t;
        },
        log: function (evt) {
            console.log('log', evt)
        },
        addItem: function (evt) {
            console.log('addItem', evt);
        },
        moveSection(){
            let t = this;
            let list = [];
            t.f.actions.forEach((element, idx) => {
                list.push(element);
                list[idx].item_order = idx;
            });
            console.log('moveSection', list)
            t.f.actions = list;
            let url = 'orderActionsTaskKnow';
			window.billing
            .put(url, t.f)
            .then((response) => {
                console.log(url, response.data);
            })
            .catch((error) => {
                console.log(error);
				t.isLoading=false;
            });
        },
        printPdf() {
            let t = this;
            window.open(window.urlbase + "api/v2/pdfTaskKnow/" + t.f.id+"/"+ t.f.user_id, "_blank");
        },
	},
    computed: {
        dragActions() {
            return {
                animation: 200,
                group: "sections",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.card {
    background: #f1f1f1;
}
.card-header {
    padding: 4px 10px;
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    z-index: 2;
    background: #fff;
}
.card-body {
    margin: auto;
    width: 100%;
    max-width: 900px;
    background: #fff;
	height: calc(100vh - 42px);
	overflow: hidden;
	overflow-y: auto;
    position: relative;
    z-index: 1;
    box-shadow: 2px 8px 3px 3px rgb(0 0 0 / 19%);
}
.row_hr{
    border-top: 1px dashed #fff;
    border-left: 1px dashed #fff;
    border-right: 1px dashed #fff;
    border-bottom: 1px dashed #ddd;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
    &:hover{
        border: 1px dashed #555;
        .delAction{opacity: 1;}
        .moveItem{opacity: 1;}
    }
    .moveItem {
        opacity: 0;
        position: absolute;
        top: -37px;
        left: -10px;
        padding: 7px 3px;
        &:hover{
            cursor: move;
        }
    }
    .delAction{
        position: absolute;
        right: 0px;
        top: -30px;
        opacity: 0;
        &.btn-danger{
            padding: 4px 8px;
            color: #fa5838;
            background-color: #fff;
            border-color: #fa5838;
            &:hover {
                opacity: 1;
                .tooltip {
                    opacity: 1;
                    visibility: visible;
                }
                &:disabled{opacity: 0.65;}
            }
            &:disabled{opacity: 0.65;}
        }
    }
}
.form-control.title_know{
    font-weight: 400;
    line-height: 1.3;
    font-size: 30px;
    padding: 0px;
    color: #000;
    width: 100%;
    max-width: 600px;
    margin-top: 15px;
    resize: none;
    height: 34px;
    background: transparent;
    border: 1px dashed #ffff;
    overflow: hidden;
    &::placeholder { color: #000;}
    &:hover{
        border: 1px dashed #555;
    }
}
.form-control.action_name{
    font-weight: 400;
    line-height: 1.2;
    font-size: 20px;
    padding: 0px;
    color: #000;
    width: 100%;
    background: transparent;
    border: 1px dashed #ffff;
    &::placeholder { color: #000;}
    &:hover{
        border: 1px dashed #ffff;
    }
}
@import url("form_know.scss");
</style>
