<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting Message Invoice</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <form @submit.prevent="MetSavesettingInvoice">
                            <div class="card">
                                <div class="card-header">Email Message</div>
                                <div class="card-body">
                                    <div class="bgloading" v-if="FormInvoiceSetting.isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ctrlbtnShow">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                </div>
                                                <div class="col-md-6">
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ctrlbtnHiden">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label for="staticEmail" class="col-sm-2 col-form-label">
                                                        Greeting
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div style="margin-left:30px;">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="customControlAutosizing"
                                                            v-model="FormInvoiceSetting.greeting"
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customControlAutosizing"
                                                            >Insert a greeting before the message
                                                            text</label
                                                        > 
                                                    </div>                                            
                                                </div>  
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div style="margin-right: 10px">
                                                <label
                                                    class="form-check-label"
                                                    for="inlineFormCheck"
                                                    ><b>Greeting format:</b></label
                                                >
                                            </div>
                                        </div>    
                                        <div class="col-md-4">
                                            <select class="form-control" style="margin-right: 10px;margin-bottom: 10px"
                                                v-model="FormInvoiceSetting.greetingFormat1"
                                                :disabled="FormInvoiceSetting.greeting ? disabled : ''"
                                            >
                                                <option value="1">Dear:</option>
                                                <option value="2">To:</option>
                                            </select>
                                        </div>    
                                        <div class="col-md-4">
                                            <select class="form-control"
                                                v-model="FormInvoiceSetting.greetingFormat2"
                                                :disabled="FormInvoiceSetting.greeting ? disabled : ''"
                                            >
                                                <option value="1">Full Name</option>
                                                <option value="2">
                                                    Business Name
                                                </option>
                                            </select>
                                        </div>                                                                                                                            
                                    </div>                                        

                                    <hr />
                                    <div class="form-group row">
                                        <label for="" class="col-sm-2 col-form-label"
                                            >Message</label
                                        >
                                        <div class="col-sm-10">
                                            <div class="">
                                                <span class="control-label"
                                                    ><b>INVOICE</b>
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary btn-sm"
                                                        @click="MetDefaultMessage"
                                                        style="
                                                            margin-left: 10px;
                                                            border-radius: 5px;
                                                        "
                                                    >
                                                        Use Standard Message
                                                    </button>
                                                </span>
                                            </div>
                                            <div style="margin-top: 15px">
                                                <div class="form-group row">
                                                    <label
                                                        for=""
                                                        class="col-sm-2 col-form-label text-right"
                                                        >Subject:</label
                                                    >
                                                    <div class="col-sm-10">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                FormInvoiceSetting.messagesubjet
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        for=""
                                                        class="col-sm-2 col-form-label text-right"
                                                        >Message:</label
                                                    >
                                                    <div class="col-sm-10">
                                                        <textarea
                                                            class="form-control"
                                                            id=""
                                                            rows="10"
                                                            v-model="
                                                                FormInvoiceSetting.messagetext
                                                            "
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card">
                                <div class="card-header">Options</div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <label
                                            for="inputPassword"
                                            class="col-sm-1 col-form-label"
                                        ></label>
                                        <div class="col-sm-11">
                                            <div style="margin-top: 15px">
                                                <div class="form-group row">
                                                    <label
                                                        for="jobi"
                                                        class="col-sm-3 col-form-label text-right"
                                                        >Default Invoice Terms:</label
                                                    >
                                                    <div class="col-sm-9">
                                                        <select
                                                            class="form-control"
                                                            id=""
                                                            v-model="
                                                                FormInvoiceSetting.term_id
                                                            "
                                                        >
                                                            <option :value="0">
                                                                Due on Receipt
                                                            </option>
                                                            <option
                                                                :value="termconf.id"
                                                                v-for="termconf in configtermsList"
                                                                :key="termconf.id"
                                                            >
                                                                {{ termconf.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-sm-9">
                                                        <div
                                                            class="custom-checkbox text-right"
                                                            style="
                                                                display: flex;
                                                                align-items: center;
                                                            "
                                                        >
                                                            <label
                                                                for="jobi"
                                                                class="col-sm-4 col-form-label text-right"
                                                                >Job information:</label
                                                            >
                                                            <input
                                                                type="checkbox"
                                                                class=""
                                                                id="jobi"
                                                                v-model="
                                                                    FormInvoiceSetting.jobinfomationcheck
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        for=""
                                                        class="col-sm-3 col-form-label text-right"
                                                        >Job information:</label
                                                    >
                                                    <div class="col-sm-9">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                FormInvoiceSetting.jobinfomationtitle
                                                            "
                                                            :disabled="
                                                                FormInvoiceSetting.jobinfomationcheck
                                                                    ? disabled
                                                                    : ''
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        for=""
                                                        class="col-sm-3 col-form-label text-right"
                                                        >Default job information:</label
                                                    >
                                                    <div class="col-sm-9">
                                                        <textarea
                                                            class="form-control"
                                                            id=""
                                                            rows="10"
                                                            v-model="
                                                                FormInvoiceSetting.jobinfomationtext
                                                            "
                                                            :disabled="
                                                                FormInvoiceSetting.jobinfomationcheck
                                                                    ? disabled
                                                                    : ''
                                                            "
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ctrlbtnShow">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                </div>
                                                <div class="col-md-6">
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ctrlbtnHiden">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div> -->
                        </form>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    data() {
        return {
            configtermsList: [],
            disabled: false,
            FormInvoiceSetting: {
                isLoading: false,
                user_id: null,
                action: "Add",
                greeting: false,
                greetingFormat1: 1,
                greetingFormat2: 1,
                messagesubjet: null,
                messagetext: null,
                jobinfomationcheck: true,
                jobinfomationtitle: "Job information",
                jobinfomationtext: null,
                term_id: 0,
            },
            DefaultMessage: {
                Subject: null,
                Message: null,
            },
        };
    },
    created() {
        this.getCompanyInfo();
    },  
    methods: {  
        getTermsList() {
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    this.configtermsList = response.data;
                    if (this.configtermsList.length == 0) {
                        this.NoRecordTerms = true;
                    }
                })
                .catch((error) => console.log(error));
        },
        getCompanyInfo() {
            this.isLoading = true;
            window.billing
                .get("companyinfo/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.DefaultMessage.Subject = "Invoice from %COMPANY_NAME%";
                    this.DefaultMessage.Message =
                        "Your invoice is attached. \n\nThank you for your business - we appreciate it very much.\n\n\nSincerely,\n%COMPANY_NAME%";
                    this.getInvoiceSetting();
                    this.getTermsList();
                })
                .catch((error) => console.log(error));
        },
        MetDefaultMessage() {
            console.log("creando msj por defecto");
            this.FormInvoiceSetting.messagesubjet = this.DefaultMessage.Subject;
            this.FormInvoiceSetting.messagetext = this.DefaultMessage.Message;
        },
        MetSavesettingInvoice() {
            this.FormInvoiceSetting.isLoading = true;
            if (this.FormInvoiceSetting.action == "Add") {
                window.billing
                    .post("invoice/setting", {
                        user_id: window.localuserdata,
                        greeting: this.FormInvoiceSetting.greeting,
                        greetingFormat1: this.FormInvoiceSetting.greetingFormat1,
                        greetingFormat2: this.FormInvoiceSetting.greetingFormat2,
                        messagesubjet: this.FormInvoiceSetting.messagesubjet,
                        messagetext: this.FormInvoiceSetting.messagetext,
                        term_id: this.FormInvoiceSetting.term_id,
                        jobinfomationcheck: this.FormInvoiceSetting.jobinfomationcheck,
                        jobinfomationtitle: this.FormInvoiceSetting.jobinfomationtitle,
                        jobinfomationtext: this.FormInvoiceSetting.jobinfomationtext,
                    })
                    .then((response) => {
                        this.FormInvoiceSetting.action = "Update";
                        this.FormInvoiceSetting.isLoading = false;
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created Message Successful",
                        });
                    })
                    .catch((error) =>
                        console.log(error, (this.FormInvoiceSetting.isLoading = false))
                    );
            }
            if (this.FormInvoiceSetting.action == "Update") {
                this.FormInvoiceSetting.isLoading = true;

                window.billing
                    .put("invoice/setting/" + window.localuserdata, {
                        user_id: window.localuserdata,
                        greeting: this.FormInvoiceSetting.greeting,
                        greetingFormat1: this.FormInvoiceSetting.greetingFormat1,
                        greetingFormat2: this.FormInvoiceSetting.greetingFormat2,
                        messagesubjet: this.FormInvoiceSetting.messagesubjet,
                        messagetext: this.FormInvoiceSetting.messagetext,
                        term_id: this.FormInvoiceSetting.term_id,
                        jobinfomationcheck: this.FormInvoiceSetting.jobinfomationcheck,
                        jobinfomationtitle: this.FormInvoiceSetting.jobinfomationtitle,
                        jobinfomationtext: this.FormInvoiceSetting.jobinfomationtext,
                    })
                    .then((response) => {
                        this.FormInvoiceSetting.action = "Update";
                        this.FormInvoiceSetting.isLoading = false;
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated Setting Successful",
                        });
                    })
                    .catch((error) =>
                        console.log(error, (this.FormInvoiceSetting.isLoading = false))
                    );
            }
        },
        getInvoiceSetting() {
            //this.FormestimateSetting.isLoading = true
            window.billing
                .get("invoice/setting/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data.result);
                    if (response.data.result.length) {
                        this.FormInvoiceSetting.user_id = response.data.result[0].user_id;
                        this.FormInvoiceSetting.greeting =
                            response.data.result[0].greeting;
                        this.FormInvoiceSetting.greetingFormat1 =
                            response.data.result[0].greetingFormat1;
                        this.FormInvoiceSetting.greetingFormat2 =
                            response.data.result[0].greetingFormat2;
                        this.FormInvoiceSetting.messagesubjet =
                            response.data.result[0].messagesubjet;
                        this.FormInvoiceSetting.messagetext =
                            response.data.result[0].messagetext;
                        this.FormInvoiceSetting.jobinfomationcheck =
                            response.data.result[0].jobinfomationcheck;
                        this.FormInvoiceSetting.jobinfomationtitle =
                            response.data.result[0].jobinfomationtitle;
                        this.FormInvoiceSetting.jobinfomationtext =
                            response.data.result[0].jobinfomationtext;
                        this.FormInvoiceSetting.term_id = response.data.result[0].term_id;
                        if (this.FormInvoiceSetting.user_id !== null) {
                            this.FormInvoiceSetting.action = "Update";
                        }
                        this.FormInvoiceSetting.isLoading = false;
                    } else {
                        this.MetDefaultMessage();
                    }

                    console.log(response.data);
                })
                .catch((error) => console.log(error));
        },
    },
};
</script>
<style lang="css" scoped>
#editor {
    height: 300px;
}
@media (min-width: 1279px) {
    .ctrlbtn {
        width:100px !important;
    }
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}
</style>
