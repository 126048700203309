<template>
    <div class="food_item_content_media" :data-indicador="indicador" :id="'food_item_content_media_'+indicador">
        <div class="food_gallery_slider" :data-indicador="indicador" :id="'food_gallery_slider'+indicador">
            <ul :data-indicador="indicador" v-if="gallery && gallery.length>0" :id="'food_gallery_slider_list_'+indicador">
                <li v-for="(g, gIdx) in gallery" :key="gIdx" :data-indicador="indicador" :id="'gellery_'+indicador+'_item_'+g.id" :class="['gellery_'+indicador+'_items', (itemId==g.id) ? 'active' : '']" :data-itemG="g.id">
                    <img v-if="g.type_file=='img'" class="food_item_img" :src="urlMedia+'/img/'+g.name_file" alt="" :data-indicador="indicador">
                    <video v-else :src="urlMedia+'/video/'+g.name_file" controls muted loop preload="auto" :poster="urlMedia+'/video/'+g.name_file+'.jpg'" class="food_item_video" :data-indicador="indicador"></video>
                </li>
            </ul>
            <ul v-else>
                <li>
                    <img class="food_item_img" src="@/core/assets/img/add-img-icon.png" alt="add image" >
                </li>
            </ul>
        </div>
        <div class="food_item_price" :data-indicador="indicador"> {{currency}} {{ foodItem.price | formatMoney}}</div>
        <div class="food_item_nav" v-if="gallery && gallery.length>0">
            <ul :data-indicador="indicador" v-if="gallery && gallery.length>0">
                <li v-for="(g, gIdx) in gallery" :key="gIdx" >
                    <a href="#" :class="['nav-item', (itemId==g.id) ? 'active' : '']" @click.prevent="showItem(g.id, (gIdx+1))" :id="'nav_item_'+g.id" :data-itemG="g.id"></a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['foodItem', 'indicador', 'gallery', 'urlMedia', 'currency'],
    data(){
        return {
            itemId: null,
        };
    },
    watch: {
        gallery(){
            let t = this;
            t.itemId = (t.gallery && t.gallery.length>0) ? t.gallery[0].id : 0;
        }
    },
    mounted(){
        let t = this;
        t.itemId = (t.gallery && t.gallery.length>0) ? t.gallery[0].id : 0;
        let slider = document.getElementById('food_gallery_slider'+t.indicador);
        if(slider){
            let isMouseDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isMouseDown = true;
                // console.log('mousedown', isMouseDown)
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
                slider.style.cursor = 'grabbing'; /* Cambia el cursor durante el arrastre */
            });
            slider.addEventListener('mouseup', () => {
                // console.log('mouseup')
                isMouseDown = false;
                slider.style.cursor = 'grab';
            });
            slider.addEventListener('mouseleave', () => {
                // console.log('mouseleave')
                isMouseDown = false;
                slider.style.cursor = 'grab';
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isMouseDown) return;
                // console.log('mousemove', isMouseDown)
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 2; // Ajusta la velocidad de desplazamiento
                // console.log('scrollLeft', scrollLeft - walk)
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    },
    methods: {
        showItem(id, multi){
            let t = this;
            let slider = document.getElementById('food_gallery_slider'+t.indicador);
            let item = document.getElementById('gellery_'+t.indicador+'_item_'+id);
            if(item){
                let rect = item.getBoundingClientRect();
                let navItems = document.querySelectorAll('a.nav-item');
                let scrollLeft =0;
                let idx=0;
                navItems.forEach(i => {
                    i.classList.remove('active');
                    let idNav = i.getAttribute('data-itemG');
                    if(id==idNav){
                        if(idx==0){scrollLeft = 0;}
                        else{scrollLeft = (rect.width*idx);}
                    }
                    idx++;
                });
                console.log('scrollLeft', scrollLeft)
                slider.scroll({
                    left: scrollLeft,
                    top: 0,
                    behavior: 'smooth'
                });
                // t.isActive(scrollLeft);
            }
        },
        isActive(scrollLeft){
            let t = this;
            console.log('scrollLeft', scrollLeft)
            let slider = document.getElementById('food_gallery_slider'+t.indicador);
            let navItems = document.querySelectorAll('a.nav-item');
            navItems.forEach(i => {i.classList.remove('active');});
            let gItems = document.querySelectorAll('li.gellery_'+t.indicador+'_items');
            gItems.forEach(li => {
                let rect = li.getBoundingClientRect();
                console.log(rect, slider.scrollLeft)
                if(rect.left == slider.scrollLeft){
                    let id = li.getAttribute('data-itemG');
                    t.itemId = id;
                    let nav_active = document.getElementById('nav_item_'+id);
                    nav_active.classList.add('active');
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
    .food_item_nav {
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .food_item_nav ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
    }

    .food_item_nav ul li {
        flex: none;
        margin: 0px 5px;
    }

    .food_item_nav ul li a {
        display: block;
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        opacity: 0.5;
    }
    .food_item_nav ul li a:hover {
        cursor: pointer;
        opacity: 1;
    }
    /*.food_item_nav ul li a.active {
        opacity: 1;
        padding: 5px;
    }*/
</style>
