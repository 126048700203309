<template>
    <div>
        <div class="page-breadcrumb" style="margin-bottom: 10px">
            <div class="row">
                <div class="col-5 align-self-center">
                    <h4 class="page-title"><i class="fas fa-tasks"></i> Setting</h4>
                </div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <div class="list-group list-group-flush">
                                        <h4>GENERAL SETTING</h4>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="SettingBox = 'ShowEmailNotification'"
                                        >
                                            Notification
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="list-group list-group-flush">
                                        <h4>WIDGET WEBSITES</h4>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="copyCode(0)"
                                        >
                                            Materials all
                                        </div>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="copyCode(1)"
                                        >
                                            Slabs
                                        </div>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="copyCode(2)"
                                        >
                                            Remanents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="modal-setting" height="auto" @before-close="ClosedModal">
            <div class="card">
                <button @click="closeModal" class="closeIcon"><span></span></button>
                <ShowEmailNotification
                    v-if="SettingBox == 'ShowEmailNotification'"
                ></ShowEmailNotification>
                <widgetCode :type="type"></widgetCode>
            </div>
        </modal>
    </div>
</template>
<script>
import ShowEmailNotification from "@/apps/slabs/components/settingEmail.vue";
import widgetCode from "@/apps/slabs/components/widgetCode.vue";
import { mapGetters } from "vuex";
export default {
    name: "SlabsSetting",
    components: {
        ShowEmailNotification,
        widgetCode,
    },
    data() {
        return {
            isLoading: false,
            SettingBox: "",
            type: "",
        };
    },
    created() {},
    methods: {
        resfresh(res) {
            if (res.open == 1) {
                this.ClosedModal();
                this.closeModal();
            }
        },
        copyCode(i) {
            this.type = i;
            this.SettingBox = "widgetCode";
        },
        closeModal() {
            this.$modal.hide("modal-setting");
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/slabs/set_slabsStorage", k);
        },
    },
    watch: {
        get_slabsStorage: function () {
            if (this.get_slabsStorage) {
                let g = this.$store.getters["ModApps/slabs/get_slabsStorage"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "UpdateEmailSetting") {
                    this.resfresh(r);
                }
            }
        },
        SettingBox: function () {
            if (!this.SettingBox == "") {
                this.$modal.show("modal-setting");
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/slabs", ["get_slabsStorage"]),
    },
};
</script>
<style lang="scss" scoped>
.list-group-item-action:hover {
    cursor: pointer;
}
</style>
