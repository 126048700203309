const variables = {
	m: {
		show: false,
		component: '',
		data: []
	},            
	cam:{
		id_tags: [],
		tagsModalName:"",
		taksID:0,
		idJobs:[],
		user_id_assign: 0,
		taksSelected: 0,
		f: {
			id_jobs: 0,
			id: 0,
			id_status: 0,
			user_id: window.master_client,
			org_id: window.localorgdata,
			name: "",
			taks: [],
		},
		id:0
	},
	resizable: true,
	adaptive: true,
	isLoading: false,
	listTaks: [],
	listTaksResult: [],
	dash: [],
	id_tags: [],
	user_id: 0,
	org_id: 0,
	typez: 0,
	user_id_assign: 0,
	active: 0,
	f: {
		id_jobs: 0,
		id: 0,
		id_status: 0,
		user_id: 0,
		org_id: 0,
		name: "",
		taks: [],
	},
	count_CJ: 0,
	count_taks: 0,
	colorList: [],
	titleModalTaks: "",
	idJobs: 0,
	taksID: 0,
	userID: 0,
	closeModals: 0,
	content: true,
	query: "",
	offset: 4,
	search: "",
	jobsNoteNum: 0,
	jobsNoteID: 0,
	jobsNote: "",
	jobsNoteBtn: false,
	jobsEdicion: false,
	jobsLinkDrive: "",
	jobsLinkDriveEdit: false,
	JobsIFGallery: 0,
	JobsGalleryList: [],
	JobsGalleryURL: "",
	JobsGallerySHOW: false,
	itemNameCustomers: [],
	tagsModalName: "",
	tagsModalId: 0,
	btnSave: false,
	show_customer_name: 1,
	show_customer_notes: 0,
	show_invoice: 1,
	privilegies_dash: 0,
	editorTag: false,
	editarTags: [],
	btnAddJobs: false,
	tagsList: [],
	tags: [],
	dataBilling: [],
	sub: [],
	customer: [],
	invoice: [],
	sub_id: 0,
	customer_id: 0,
	invoice_id: 0,
	editCusInv: false,
	editCusInvLoading: false,
	listCustomer: [],
	isLoadingImgNotes: false,
	next_page_url: "",
	nextPage: false,
	heightPage: 0,
	topMax: 0,
	btnToDoList: false,
	statusList: [],
	templateValue: 0,
	listTemplate: [],
	btnSavetoDo: false,
	addTaks: {
		id: 0,
		org_id: "",
		user_id: "",
		taks: [],
	},
	searchActivate: false,
	footer:{
		privilegies_dash:0,
		colorList:[],
		typez:0,
		taksID: 0,    
		listTaks:[],
		id_tags:[],
		tagsModalId: 0,
	},
	sortBy: 0,
	tagsDefault: {
		user_id: 0, // usuario segun sesion
		org_id: 0,
		meta_value: '',
		meta_key: "tagsDefault",
		tagsID: [],
	},
	tagsDefaulttagsID: [],
	apis: {},
};
export default variables;
