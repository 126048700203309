<template>
        <div class="container-fluid">
            <div class="row">
                <div class="card" id="card-invoice">
                    <div class="card-header" style="padding-left: 0">
                        <div class="row">
                            <div class="botonera-filtros">
                                <div class="btn-add-invo">
                                    <router-link
                                        v-if="!freePlan"
                                        :to="{ name: 'InvoicesNew' }"
                                        class="btn btn-success"
                                    >
                                        <i class="fas fa-plus"></i>
                                        Add Invoice
                                    </router-link>
                                    <button type="button" 
                                        v-else
                                        @click="llamarPopPremium()"
                                        class="btn btn-success disabled"
                                    >
                                        <i class="fas fa-plus"></i>
                                        Add Invoice
                                    </button>
                                </div>
                                <!--<div class="btn-sync">
                                    <button
                                        type="button"
                                        class="btn btn-light btn-sm"
                                        @click="reloadTable"
                                        style="
                                            border: 1px solid #dcdcdc;
                                            background-color: #fff;
                                            padding: 7px 10px;
                                            color: #5d5b5b;
                                        "
                                    >
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </div>-->

                                <dropdown-menu
                                    v-model="colsDrop"
                                    :right="right"
                                    :hover="hover"
                                >
                                    <button class="btn btn-light btn-sm" style="border: 1px solid rgb(220, 220, 220);background-color: rgb(255, 255, 255);padding: 7px 10px; color: rgb(93, 91, 91);">
                                        <i class="fas fa-columns"></i>
                                    </button>
                                    <div slot="dropdown">
                                        <div
                                            class="dropdown-item"
                                            v-for="(i, idx) in initCols"
                                            :key="idx"
                                        >
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    :id="idx + '-' + i.name"
                                                    v-bind:value="i.value"
                                                    v-model="cols"
                                                    :disabled="(i.value==1)"
                                                    checked="(i.value==1)"
                                                    @change="editCols"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    :for="idx + '-' + i.name"
                                                >
                                                    {{ i.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </dropdown-menu>
                                <filtros></filtros>
                            </div>
                            <div
                                class="col-12 scroll-right"
                                v-if="InvoicesList.length > 0"
                            >
                                <h4>
                                    Scroll right <i class="fas fa-arrow-right"></i>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="row card-body" style="padding: 0px 5px;">
                        <tablaInvo></tablaInvo>
                    </div>
                </div>
            </div>
			<!-- modal -->
			<modalLateral v-show="modalBill.indexOf(m.component)> -1">
			<component v-bind:is="m.component" :items="DataMail"></component>
			</modalLateral>
        </div>
</template>
<script>
import {variables, components, methods, watch, computed} from './js';
import { ClickOutside } from 'vue-click-outside'
export default {
	name: 'invoice',
	data(){
		return variables;
	},
	components,
	methods,
	watch,
	computed,
	created () {
        this.getkeyVal('JobsInt');
        this.validSquare()
        this.invoOpAditional()
        this.accountSMS = (!isNaN(localStorage.getItem('accountSMS'))) ? parseInt(localStorage.getItem('accountSMS')) : false;
        console.log('created accountSMS', this.accountSMS);
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        console.log('created PlanPremium', this.PlanPremium);
        this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
        console.log('created freePlan', this.freePlan);
		this.searchFilter();
		this.getMethods();
        this.showSMS = this.$store.getters['ModApps/billing/getShowSMS'];
	},
	directives: {
		ClickOutside,
	},
	mounted() {
		var t = this;
        let searchbox = document.querySelector("input.searchbox-input")
        let timeout;
        searchbox.addEventListener('keydown', () => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                // console.log('has dejado de escribir')
                // t.searchFilter();
                // clearTimeout(timeout)
            }, 400)
        })
	},
}
</script>
<style lang="scss" scoped>
@import './css/styles.scss';
</style>
