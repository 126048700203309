<template>
    <div class="pop-gallery">
        <swiper
            class="swiper"
            :options="swiperOption"
        >
            <swiper-slide v-for="(i, idx) in imgs" :key="idx">
                <img
                    :src="i"
                    v-bind:class="['img-responsive horizontal']"
                />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
    },
    data(){
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            imgs: [
                "https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0058.jpg",
                "https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0495.jpg",
                "https://mainatorestoration.com/imgcms/galeria/696249477613/large/dji0343.jpg",
                "https://mainatorestoration.com/imgcms/editor/images/Depositphotos_23579053_original.jpg",
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
img.img-responsive.horizontal {
    height: 330px;
    width: auto;
    max-width: 100%;
}

</style>
