<template>
    <div>
        <div class="card-header">
            <h3>Notificacions SMS</h3>
        </div>
        <div v-if="isLoading"><Loader></Loader></div>
        <form @submit.prevent="registerNumberPhone" id="form1" v-else>
            <div class="card-body" >
                <div class="alert alert-dark" role="alert">
                    <b>Info: </b>Write the phone number, do not use characters, symbols or spaces,<br /> Ex: 7745551234 or 17745551234
                </div>
                <div class="form-group">
                    <label for="phoneNumber">Phone Number:</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="phone" id="phoneNumber" style="font-size: 20px" :disabled="approved==1">
                        <button type="submit" class="input-group-text bg-info btn-sm" style="color:#fff;cursor: pointer;" v-if="approved!=1">
                                <i class="fas fa-paper-plane" style="margin-right: 5px;"></i>
                                <span>Send Verification Code</span>
                        </button>
                    </div>
                </div>
                <div class="alert alert-warning" role="alert" v-if="approved==0">
                    <b>Waiting for approval</b>, enter the <b>4-digit code</b> that was sent.
                </div>
                <div style="display: flex;align-items: start;flex-wrap: wrap;position: relative;width: 300px;" v-if="approved==0">
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control inputcode"
                            max="4"
                            v-model="code"
                            placeholder="xxxx"
                            style="font-size: 20px;letter-spacing: 5px;"
                        />
                        <button
                            type="button"
                            class="input-group-text btn btn-success btn-sm"
                            style="margin-right: 5px;"
                            @click.prevent="verifyNumberPhone"
                        >
                            Verify Now
                        </button>
                    </div>
                </div>
                <div class="alert alert-info" role="alert" v-if="approved==1">
                    <b>Approved service.</b><br />
                    This record cannot be modified, if you wish to make a change please contact your administrator at support@marketea.com or Call us at 774-287-3353.
                </div>
                <div class="alert alert-danger" role="alert" v-if="errorCode!=null">
                    <b>Wrong code or it has already been used.</b>
                </div>
            </div>
            <!-- <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm" :disabled="disabled">Request Authorization</button>
            </div> -->
        </form>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
export default {
    name: "notiSmsForm",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            phone: null,
            code: null,
            phone_old: null,
            disabled: false,
            approved: null,
            errorCode: null,
        };
    },
    created() {
        this.getSetting();
    },
    mounted() {
        //this.email1 = this.org_email;
    },
    methods: {
        getSetting() {
            let t = this;
            t.isLoading = true;
            window.master
                .post("form/getNotiSmsForm", {
                    org_id: window.localorgdata,
                    user_id: window.master_client,
                })
                .then((response) => {
                    console.log(response.data);
                    if(response.data.r){
                        let val= response.data.r.meta_value.split('|');
                        t.phone = val[0];
                        t.phone_old = val[0];
                        t.disabled = (val[1]>0);
                        t.approved = val[1];
                    }
                    t.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
        },
        registerNumberPhone() {
            let t = this;
            if(!t.phone_old != t.phone){
                window.master.post("form/setNotiSmsForm", {
                        org_id: window.localorgdata,
                        user_id: window.master_client,
                        phone: t.phone,
                })
                .then((response) => {
                    console.log(response.data)
                    t.code=null
                    t.getSetting();
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
            }
        },
        verifyNumberPhone() {
            let t = this;
            if(!t.phone_old != t.phone && t.code.length>0){
                t.errorCode=null;
                window.master.post("form/verifyCodeSmsForNumberPhone", {
                        org_id: window.localorgdata,
                        user_id: window.master_client,
                        phone: t.phone,
                        code: t.code,
                })
                .then((response) => {
                    if(response.data.status){
                        t.$swal.fire({
                            icon: "success",
                            title: "Approved service",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        t.getSetting()
                    }else{
                        t.errorCode=1;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
            }
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/Marketea/set_settupStorage", k);
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_settupStorage"]),
    },
};
</script>

<style lang="scss" scoped></style>
