<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-5 align-self-center"></div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'PaymentsReceived' }"
                                        class="btn btn-info btn-sm mr-2"
                                        ><i class="fas fa-arrow-left"></i>
                                        Back</router-link
                                    >
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xl-8 col-lg-7">
                    <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                    <form
                        @submit.prevent="savePayment"
                        enctype="multipart/form-data"
                        id="form1"
                    >
                        <div style="width: 100%; text-align: right; margin-bottom: 10px">
                            <button
                                type="button"
                                class="btn btn-secundary btn-lg"
                                style="margin-right: 10px"
                                @click="$router.push({ name: 'PaymentsReceived' })"
                            >
                                <i class="fas fa-times"></i> CANCEL
                            </button>
                            <button
                                type="submit"
                                class="btn btn-success btn-lg"
                                :disabled="isDisabled"
                            >
                                <i class="fas fa-save"></i> SAVE
                            </button>
                            <button
                                type="submit"
                                class="btn btn-success btn-lg"
                                style="margin-left: 10px"
                                :disabled="isDisabled"
                                @click="saveNewPaymen"
                            >
                                <i class="fas fa-save"></i> SAVE & NEW
                            </button>
                        </div>
                        <div class="card">
                            <div
                                class="card-header alert alert-info"
                                style="margin-bottom: 0rem"
                            >
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h4>
                                            <b>{{ title }}</b> Payment
                                            <strong v-if="editar == true"
                                                ># {{ form.idx }}
                                            </strong>
                                        </h4>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="row">
                                            <div class="col-md-5"></div>
                                            <div class="col-md-7">
                                                <div
                                                    class="form-check form-check-inline"
                                                    style="display: flex"
                                                >
                                                    <label
                                                        class="form-check-label"
                                                        style="padding-right: 10px"
                                                        >Date</label
                                                    >
                                                    <datetime
                                                        type="date"
                                                        v-model="inputFecha"
                                                        format="MMM dd, yyyy"
                                                        value-zone="America/New_York"
                                                        input-class="form-control"
                                                        required
                                                    ></datetime>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="card-body"
                                        style="border: 1px solid #d6d8d9"
                                    >
                                        <div class="form-row alert alert-dark">
                                            <div class="form-group col-md-5">
                                                <label for="">Step 1</label>
                                                <model-select
                                                    required
                                                    :options="CustomersList"
                                                    v-model="Customer"
                                                    v-if="editar == false"
                                                    placeholder="Choose a Customer"
                                                >
                                                </model-select>
                                                <div v-else>
                                                    <strong
                                                        >{{ form.firstname }}
                                                        <span
                                                            v-if="form.lastname != null"
                                                            >{{ form.lastname }}</span
                                                        ></strong
                                                    ><br />
                                                    <small>{{ form.bussinesname }}</small>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-7">
                                                <label for="">Step 2 </label>
                                                <div v-if="editar == false">
                                                    <model-select
                                                        required
                                                        :options="invoicesList"
                                                        v-model="invoiceSelect"
                                                        v-if="invoicesValid"
                                                        placeholder="Choose a Invoice"
                                                    >
                                                    </model-select>
                                                    <select
                                                        name=""
                                                        id=""
                                                        class="form-control"
                                                        v-else
                                                    >
                                                        <option>Select Invoice</option>
                                                    </select>
                                                </div>
                                                <div v-else>Invoice #{{ numdoc }}</div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label>Pmt Method</label>
                                                <model-select
                                                    required
                                                    :options="PaymentMethodsList"
                                                    v-model="form.method_id"
                                                    class="metodos"
                                                    placeholder="Choose a Method"
                                                >
                                                </model-select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label for="inputCity">Ref #</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.ref"
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label for="inputZip">Amount</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"
                                                            >$</span
                                                        >
                                                    </div>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="0.00"
                                                        required
                                                        v-model="form.price"
                                                        @keyup="validP"
                                                        v-if="editar == false"
                                                        :disabled="isDisabledPrice"
                                                    />
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        disabled
                                                        v-model="form.price"
                                                        v-else-if="editar"
                                                    />
                                                    <span
                                                        class="error"
                                                        style="color: red"
                                                        v-if="form.vatError"
                                                        >{{ form.vatErrorMsg }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Admin Notes</label>
                                            <textarea
                                                class="form-control"
                                                rows="2"
                                                v-model="form.memo"
                                            ></textarea>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="gridCheck"
                                                    v-model="form.send_invoice"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="gridCheck"
                                                >
                                                    Send Invoice/Payment notification mail
                                                    to the client
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="gridCheck2"
                                                    v-model="form.send_img"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="gridCheck2"
                                                >
                                                    Send Image
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="img1"
                                                accept="image/*"
                                                @change="fileImg1"
                                            />
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                @click="$refs.img1.click()"
                                                v-if="PlanPremium"
                                            >
                                                Attachment 1
                                                <span class="premium pre">
                                                    <img src="@/core/assets/img/premium.png" alt="premium">
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                @click="llamarPopPremium"
                                                v-else
                                            >
                                                Attachment 1
                                                <span class="premium pre">
                                                    <img src="@/core/assets/img/premium.png" alt="premium">
                                                </span>
                                            </button>
                                            <div
                                                v-if="Showimg1"
                                                style="
                                                    margin-top: 10px;
                                                    width: 250px;
                                                    position: relative;
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    style="
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        background-color: red;
                                                        color: #fff;
                                                        border: 0;
                                                    "
                                                    @click="delimg1"
                                                >
                                                    X
                                                </button>
                                                <img
                                                    v-bind:src="preview1"
                                                    id="img1"
                                                    @click="$refs.img1.click()"
                                                    style="width: 100%; max-width: 600px"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="img2"
                                                accept="image/*"
                                                @change="fileImg2"
                                            />
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                @click="$refs.img2.click()"
                                                v-if="PlanPremium"
                                            >
                                                Attachment 2
                                                <span class="premium pre">
                                                    <img src="@/core/assets/img/premium.png" alt="premium">
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                @click="llamarPopPremium"
                                                v-else
                                            >
                                                Attachment 2
                                                <span class="premium pre">
                                                    <img src="@/core/assets/img/premium.png" alt="premium">
                                                </span>
                                            </button>
                                            <div
                                                v-if="Showimg2"
                                                style="
                                                    margin-top: 10px;
                                                    width: 250px;
                                                    position: relative;
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    style="
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        background-color: red;
                                                        color: #fff;
                                                        border: 0;
                                                    "
                                                    @click="delimg2"
                                                >
                                                    X
                                                </button>
                                                <img
                                                    v-bind:src="preview2"
                                                    id="img2"
                                                    @click="$refs.img2.click()"
                                                    style="width: 100%; max-width: 600px"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-4 col-xl-4 col-lg-4 d-md-none d-lg-block">
                    <div class="card">
                        <div class="card-header">Recent Payments</div>
                        <div class="card-body">
                            <ul class="list-group list-group-flush">
                                <li
                                    class="list-group-item d-flex justify-content-between align-items-left"
                                    v-for="(i, index) in InvoiceHistoryList"
                                    v-bind:key="index"
                                >
                                    #{{ i.numdoc.toString().padStart(4, "000") }}
                                    <span
                                        >{{ i.firstname }}
                                        <span v-if="i.lastname != null">{{
                                            i.lastname
                                        }}</span></span
                                    >
                                    <span
                                        ><b>$ {{ i.price }}</b></span
                                    >
                                </li>
                            </ul>
                            <router-link
                                :to="{ name: 'PaymentsReceived' }"
                                class="btn btn-primary float-right"
                                >All payments</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from "@/core/components/shared/Loader";
import { ModelSelect } from "vue-search-select";
import ClickOutside from "vue-click-outside";
export default {
    name: "payments",
    components: {
        Loader,
        ModelSelect,
    },
    data() {
        return {
            title: "",
            editar: false,
            isLoading: false,
            CustomersList: [],
            PaymentMethodsList: [],
            InvoiceHistoryList: [],
            saveNewPay: false,
            form: {
                id: null,
                user_id: window.localuserdata,
                customer_id: null,
                invoice_id: "",
                fecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                method_id: 12,
                ref: "",
                price: null,
                memo: null,
                send_invoice: false,
                send_img: false,
                img1: null,
                img2: null,
                imgOld1: null,
                imgOld2: null,
                status: 1,
                ope: "create",
                vatError: false,
                vatErrorMsg: null,
            },
            Customer: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                email: null,
            },
            invoiceSelect: "",
            invoicesList: [
                {
                    id: null,
                    numdoc: null,
                    total: null,
                    balance: null,
                    text: null,
                },
            ],
            invoicesValid: false,
            validimg1: false,
            validimg2: false,
            validimgOld1: false,
            validimgOld2: false,
            Showimg1: false,
            Showimg2: false,
            preview1: "",
            preview2: "",
            folder_user: "",
            botton: true,
            isDisabledPrice: true,
            isDisabled: true,
            numdoc: "",
            inputFecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            PlanPremium : (window.OrgPlan > 1),
        };
    },
    created() {
        this.iniCreated();
    },
    directives: {
        ClickOutside,
    },
    methods: {
        iniCreated() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            this.getCustomer();
            this.getMethods();
            this.getHistory();
            if (this.$route.params.idpay) {
                this.title = "Edit";
                this.form.id = this.$route.params.idpay;
                this.getpaymentsID();
                this.form.ope = "update";
                this.editar = true;
            } else {
                this.title = "Add";
            }
            if (this.$route.params.idc && this.$route.params.transac) {
                this.getCustomer(this.$route.params.idc);
            }
        },
        llamarPopPremium(){
            this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
        },
        delimg1() {
            this.preview1 = "";
            this.Showimg1 = false;
            this.validimg1 = false;
            this.validimgOld1 = true;
            this.form.img1 = null;
            this.$refs.img1.value =null;
        },
        delimg2() {
            this.preview2 = "";
            this.Showimg2 = false;
            this.validimg2 = false;
            this.validimgOld2 = true;
            this.form.img2 = null;
            this.$refs.img2.value =null;
        },
        saveNewPaymen() {
            this.saveNewPay = true;
        },
        formatearFecha() {
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        getCustomer(idc = 0) {
            var self = this;
            this.isLoading = true;
            window.billing
                .get("customers/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data.result);
                    //self.CustomersList = response.data.result;
                    const Customers = [];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var text = response.data.result[item].firstname;
                            var billing_address =
                                response.data.result[item].billingaddress;
                            var customer_address = response.data.result[item].address;
                            var bussinesname = response.data.result[item].bussinesname;
                            var email = response.data.result[item].email;
                            var lastname = response.data.result[item].lastname;
                            var customer_phone = response.data.result[item].officephone;
                            var guion = " - ";
                            if (bussinesname == null) {
                                bussinesname = "";
                                guion = " ";
                            }
                            if (lastname == null) {
                                lastname = "";
                            }
                            Customers.push({
                                value: id,
                                text: text + " " + lastname + guion + bussinesname,
                                customer_name: text,
                                customer_address: customer_address,
                                customer_phone: customer_phone,
                                customer_lastname: lastname,
                                billing_address: billing_address,
                                bussinesname: bussinesname,
                                email: email,
                            });
                            if (idc > 0 && idc == id) {
                                this.Customer = {
                                    text: text + " " + lastname + guion + bussinesname,
                                    value: id,
                                    billing_address: billing_address,
                                    bussinesname: bussinesname,
                                    customer_address: customer_address,
                                    customer_name: text,
                                    customer_phone: customer_phone,
                                    email: email,
                                };
                            }
                        }
                    }
                    this.CustomersList = Customers;
                    self.isLoading = false;
                })
                .catch((error) => {
                    self.isLoading = false;
                    console.log(error);
                });
        },
        getMethods() {
            var self = this;
            window.billing
                .get("paymentsmetodos")
                .then((response) => {
                    let Metodos = [];
                    for (let item in response.data.result) {
                        Metodos.push({
                            value: response.data.result[item].id,
                            text: response.data.result[item].name,
                        });
                    }
                    console.log("methods", Metodos);
                    self.PaymentMethodsList = Metodos;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getHistory() {
            var self = this;
            window.billing
                .get("payments/history/" + window.localuserdata)
                .then((response) => {
                    console.log("history", response.data.result);
                    self.InvoiceHistoryList = response.data.result;
                    self.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getpaymentsID() {
            this.isLoading = true;
            if (this.form.id != null) {
                window.billing
                    .post("paymentsbyid", {
                        id: this.form.id,
                        user_id: window.localuserdata,
                        folder_user: localStorage.getItem("folder_user"),
                    })
                    .then((response) => {
                        console.log(response.data);
                        var result = response.data.result;
                        this.folder_user = response.data.folder_user;
                        for (let prop in result) {
                            if (this.form[prop] != "id") {
                                if (prop == "idx") {
                                    this.form[prop] = result[prop]
                                        .toString()
                                        .padStart(4, "000");
                                } else if (prop == "fecha") {
                                    this.inputFecha = result[prop];
                                } else if (prop == "memo" && result[prop] == "null") {
                                    this.form[prop] = "";
                                } else if (prop == "ref" && result[prop] == "null") {
                                    this.form[prop] = "";
                                } else {
                                    this.form[prop] = result[prop];
                                }
                            }
                        }
                        if (this.form.img1 !== null && this.form.img1 !== "") {
                            this.Showimg1 = true;
                            this.preview1 = this.folder_user + this.form.img1;
                            this.form.imgOld1 = this.form.img1;
                        }
                        if (this.form.img2 !== null && this.form.img2 !== "") {
                            this.Showimg2 = true;
                            this.preview2 = this.folder_user + this.form.img2;
                            this.form.imgOld2 = this.form.img2;
                        }
                        this.botton = false;
                        this.numdoc = result.numdoc;
                        this.cargarInvoices(this.form.invoice_id);
                        console.log(this.form);
                        this.isLoading = false;
                        this.isDisabled = false;
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            }
        },
        fileImg1(event) {
            this.form.img1 = event.target.files[0];
            this.validimg1 = true;
            this.Showimg1 = true;
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#img1").src = e.target.result;
                };
            }
        },
        fileImg2(event) {
            this.form.img2 = event.target.files[0];
            this.validimg2 = true;
            this.Showimg2 = true;
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#img2").src = e.target.result;
                };
            }
        },
        cargarInvoices(idc) {
            window.billing
                .post("payments/invoices", {
                    user_id: window.localuserdata,
                    idc: idc,
                })
                .then((response) => {
                    this.invoicesValid = true;
                    const invoicesList = [];
                    let o = response.data.result;
                    for (let i in o) {
                        invoicesList.push({
                            value: i,
                            id: o[i].id,
                            numdoc: o[i].numdoc,
                            total: o[i].total,
                            balance: parseFloat(o[i].balance).toFixed(2),
                            text:
                                "No: " +
                                o[i].numdoc +
                                " / Total $" +
                                parseFloat(o[i].total).toFixed(2) +
                                " / Balance $" +
                                parseFloat(o[i].balance).toFixed(2) +
                                " (Standard)",
                        });
                    }
                    this.invoicesList = invoicesList;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        validP() {
            let vatIdRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
            //let vatIdRegex =  new RegExp(/^\d*(\.\d{1})?\d{0,1}$/)
            //console.log('invoice',this.invoiceSelect)
            let balance = 0;
            let i = this.invoiceSelect;
            if (
                this.invoicesList[i] != undefined &&
                this.invoicesList[i].value == this.invoiceSelect
            ) {
                balance = parseFloat(parseFloat(this.invoicesList[i].balance).toFixed(2));
            }

            if (
                !vatIdRegex.test(parseFloat(this.form.price).toFixed(2)) &&
                event.key != "."
            ) {
                this.form.vatError = true;
                this.form.vatErrorMsg =
                    "Enter the valid number, with a maximum of two decimal places.";
                this.isDisabled = true;
            } else if (parseFloat(parseFloat(this.form.price).toFixed(2)) == 0) {
                this.form.vatError = true;
                this.form.vatErrorMsg = "Must be greater than zero.";
                this.isDisabled = true;
            } else if (
                this.invoicesList[i] != undefined &&
                parseFloat(parseFloat(this.form.price).toFixed(2)) > balance
            ) {
                this.form.vatError = true;
                this.form.vatErrorMsg = "Amount cannot be greater than the balance. ";
                this.isDisabled = true;
            } else {
                this.form.vatError = false;
                this.form.vatErrorMsg = "";
                this.isDisabled = false;
            }
        },
        savePayment() {
            this.subirIMGs();
        },
        subirIMGs() {
            let t = this;
            t.isLoading = true;
            let f = new FormData();
            let contIMG = 0;
            let contDel = 0;
            for (let i in t.form) {
                if (i == "img1" && t.form[i] !== null && t.validimg1 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.form[i], t.form[i].name);
                }
                if (i == "img2" && t.form[i] !== null && t.validimg2 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.form[i], t.form[i].name);
                }
                if (i == "imgOld1" && t.form[i] !== null && t.validimgOld1 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.form[i]);
                }
                if (i == "imgOld2" && t.form[i] !== null && t.validimgOld2 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.form[i]);
                }
            }
            f.append("nombreIMG", "img");
            f.append("contIMG", contIMG);
            f.append("delIMG", "imgOld");
            f.append("contDel", contDel);
            f.append("folder", "payments");
            f.append("org_id", window.localorgdata);
            window.master
                .post("upload", f)
                .then((response) => {
                    let imgs = response.data.imgs;
                    this.saveData(imgs);
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        saveData(imgs) {
            var self = this;
            self.isLoading = true;
            var f = new FormData();
            for (let prop in self.form) {
                //console.log(prop, self.form[prop])
                if (
                    prop == "img1" &&
                    self.form[prop] !== null &&
                    self.validimg1 == true
                ) {
                    f.append(prop, imgs[1]);
                } else if (
                    prop == "img2" &&
                    self.form[prop] !== null &&
                    self.validimg2 == true &&
                    self.validimg1 == true
                ) {
                    f.append(prop, imgs[2]);
                } else if (
                    prop == "img2" &&
                    self.form[prop] !== null &&
                    self.validimg2 == true &&
                    self.validimg1 == false
                ) {
                    f.append(prop, imgs[1]);
                } else if (prop == "send_invoice") {
                    if (self.form[prop]) {
                        self.form[prop] = 1;
                    } else {
                        self.form[prop] = 0;
                    }
                    f.append("send_invoice", self.form[prop]);
                } else if (prop == "send_img") {
                    if (self.form[prop]) {
                        self.form[prop] = 1;
                    } else {
                        self.form[prop] = 0;
                    }
                    f.append("send_img", self.form[prop]);
                } else {
                    f.append(prop, self.form[prop]);
                }
            }
            if (self.validimg1 == true) {
                f.append("validimg1", 1);
            } else {
                f.append("validimg1", 0);
            }
            if (self.validimg2 == true) {
                f.append("validimg2", 1);
            } else {
                f.append("validimg2", 0);
            }
            if (self.validimgOld1 == true) {
                f.append("validimgOld1", 1);
            } else {
                f.append("validimgOld1", 0);
            }
            if (self.validimgOld2 == true) {
                f.append("validimgOld2", 1);
            } else {
                f.append("validimgOld2", 0);
            }
            f.append("folder_user", localStorage.getItem("folder_user"));
            f.append("org_id", localStorage.getItem("mark_client_data"));
            f.append("user_idd", localStorage.getItem("mark_client_data_m"));
            f.append("typeUser", localStorage.getItem("user_type"));
            f.append("oauth", localStorage.getItem("oauth_m"));
            if (self.form.ope == "create") {
                window.billing
                    .post("payments/create", f)
                    .then((response) => {
                        let e = response.data;
                        self.isLoading = false;

                        if (e.status == false) {
                            self.$notify({
                                group: "noti",
                                title: "Error!",
                                type: "error",
                                text: "Amount cannot be greater than the balance.",
                            });
                        } else {
                            self.$notify({
                                group: "noti",
                                title: "Created!",
                                type: "success",
                                text: "Successful registration",
                            });
                            if (self.saveNewPay == true) {
                                self.form.memo = null;
                                self.Customer.text = null;
                                self.Customer.value = null;
                                self.invoiceSelect = "";
                                self.title = "New";
                                self.editar = false;
                                self.form.price = null;
                                self.form.ref = "";
                                self.form.customer_id = null;
                                self.invoicesValid = false;
                                self.iniCreated(); // resiniando
                                //self.$router.push({ name: 'PaymentsReceivedNew' })
                                self.saveNewPay = false;
                                self.delimg1();
                                self.delimg2();
                            } else {
                                self.saveNewPay = false;
                                self.$router.push({ name: "PaymentsReceived" });
                            }
                        }
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            } else {
                window.billing
                    .post("payments/update", f)
                    .then((response) => {
                        this.$notify({
                            group: "noti",
                            title: "Update!",
                            type: "success",
                            text: "Successful Update",
                        });
                        this.$router.push({ name: "PaymentsReceived" });
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            }
        },
    },
    mounted() {},
    watch: {
        Customer: function (i) {
            this.cargarInvoices(i.value);
            this.form.customer_id = i.value;
        },
        invoiceSelect: function (i) {
            if (this.invoicesValid) {
                this.form.invoice_id = this.invoicesList[i].id;
            }

            this.isDisabledPrice = false;
            this.form.price = "";
        },
        inputFecha: function () {
            this.formatearFecha();
            this.form.fecha = this.inputFecha;
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
    },
};
</script>
<style lang="sass" scoped>

</style>
