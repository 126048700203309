import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import core from '@/core/storeIndex'
import ModApps from '@/apps/storeIndex'
Vue.use(Vuex)
// console.log('ModApps', ModApps);
export default new Vuex.Store({
	state: {
		isLoading: false
	},
	getters: {
		get_isLoading(state){ return state.isLoading;}
	},
	mutations: {
		set_isLoading(state, val){ 
			state.isLoading = val;
		}

	},
	actions: {

	},
	modules: {
		core,
		ModApps
	}
})
