<template>
    <div class="row">
        <div class="col-9 text-left">
            <div class="row">
                <div class="col-sm-2 text-left d-flex align-items-start">
                    <button class="btn btn-success" style="margin-right: 10px;" @click="modalTransac">
                        <i class="fas fa-plus"></i> Add
                    </button>
                    <span class="cdropdown import-transaction">
                        <button class="btn btn-transaction" >
                            Import
                        </button>
                        <label>
                            <input type="checkbox" />
                            <ul class="text-left">
                                <li @click="$refs.template.click()">
                                    <button>
                                        Import
                                        Transactions
                                    </button>
                                </li>
                                <li @click="downloadTemplete('csv')">
                                    <button>
                                        Download
                                        Templete  CSV
                                    </button>
                                </li>
                                <li @click="downloadTemplete('excel')">
                                    <button>
                                        Download
                                        Templete Excel
                                    </button>
                                </li>
                            </ul>
                        </label>
                    </span>
                    <input type="file" ref="template" @change="importFile" style="display: none" accept=".csv,.xlsx" />
                </div>
                <div class="col-sm-10" >
                    <div class="form-inline" style="flex-wrap: nowrap;">
                        <div class="form-group mb-2">
                        	<input type="text" class="form-control" id="search" placeholder="Search" v-model="TextfilterBanksList" style="border: 1px solid #afafaf;" />
                        </div>
                        <div class="form-group mb-2" style="padding-left: 8px;">
                            <div class="input-group">
                                <div class="input-group-prepend" v-if="SelectOne < 4">
                                    <span class="input-group-text">
                                        <span class="cdropdown">
                                            <div class="d-flex align-items-center setting_btn">
                                                <i class="fas fa-cog"></i>
                                            </div>
                                            <label>
                                                <input type="checkbox" />
                                                <ul class="text-left">
                                                    <li @click="SetSettingFilter('ly')">
                                                        <button>
                                                            <b v-if="SelectOne == 2">Last year</b>
                                                            <span v-else>Last year</span>
                                                        </button>
                                                    </li>
                                                    <li @click="SetSettingFilter('ty')">
                                                        <button>
                                                            <b v-if="SelectOne == 1">This year</b>
                                                            <span v-else>This year</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </label>
                                        </span>
                                    </span>
                                </div>
                                <select class="form-control" v-model="SelectOne">
                                    <option value="1">
                                        This
                                        Year
                                    </option>
                                    <option value="2">
                                        Last
                                        Year
                                    </option>
                                    <option value="3">
                                        This
                                        Month
                                    </option>
                                    <option value="4">
                                        Calendar
                                    </option>
                                    <!-- <option value="5">All</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="form-group mb-2" v-if="SelectOne == 4">
                            <div style="display: flex;align-items: center;">
                                <span style="padding-right: 4px;padding-left: 4px;"><!-- <b>From:</b> --></span>
                                <span class="inputDateFilter">
                                    <datetime 
                                    	type="date" 
                                    	v-model="dateFrom" 
                                    	format="MMM dd, yyyy" 
                                    	input-class="form-control" 
                                    	placeholder="From:"
                                    	value-zone="America/New_York"></datetime>
                                </span>
                                <span style="padding-right: 4px;padding-left: 8px;"><b>To:</b></span>

                                <span class="inputDateFilter">
                                    <datetime type="date" v-model="dateTo" format="MMM dd, yyyy" input-class="form-control"
                                        value-zone="America/New_York"></datetime>
                                </span>
                            </div>
                        </div>
                        <div class="form-group mb-2" style="padding-left: 8px;padding-right: 4px;">
                            <div class="basic_dropdown">
                                <button class="btn-status" @click="btnstatus()" style="padding: 4px 7px;">
                                    Status
                                    <i class="fas fa-caret-down"></i>
                                </button>
                                <ul class="reset dropdStatus" v-if="statusdropd">
                                    <li>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="check12" @click="checkAll()" v-model="isCheckAll" />
                                            <label class="custom-control-label" for="check12"><b>All</b></label>
                                        </div>
                                    </li>
                                    <li v-for="(item, index) in statusdata" :key="index">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" :id="index" v-bind:value="item.value" v-model="statuslist" />
                                            <label class="custom-control-label" :for="index">{{item.name}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="button" class="btn btn-success mb-2" @click="filtros()" style="margin-left: 10px;">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col text-right">
            <button class="btn btn-danger" 
                :disabled="(selected.length<2)"
                style="background-color: #ff7500; padding: 0.375rem 0.25rem;border-color: #ff7500;display:inline-block;margin-right:10px;" 
                @click="AsignUnaccepted">
                Assign Multiple
            </button>
            <button class="btn btn-danger" @click="delUnaccepted" style="display:inline-block;">
                <i class="fas fa-trash"></i> selected
            </button>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formBaking from '@/apps/billing/components/banking/formBaking'
import assignExpense from '@/apps/billing/components/banking/assignExpense'
export default {
	name: 'filtrosTransac',
    components: {
        modalLateral,
        formBaking,
        assignExpense,
    },      
	data () {
		return {
            m: {
                show: false,
                component: '',
                data: []
            }, 
            selectBank: {
                account_type: 1,
                bank_name: "",
                account_number: "1234",
            },
            unacceptedList: [],
            loadingUnaccepted: false,
            AccountList: [],
            selected: [],
            file: null,
            template: null,
            Transac: {
                isLoading: false,
                banking_id: 0,
                date: new Date().toISOString().slice(0, 10),
                type: 1,
                amount: 0.01,
                desc: "",
            }, 
            filterSelected: 1,
            TextfilterBanksList: "",
            statusdropd: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Income" },
                { value: "2", name: "Expense" },
                { value: "3", name: "Unaccepted" },
            ],
            statuslist: [1, 2, 3],
            SelectOne: 1,
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),                   
		}
	},
	created() {
		let getBank = this.$store.getters['ModApps/billing/getSelectBank'];
        this.selectBank = getBank;
		this.SettingBank();
	},
    methods: {  
        SettingBank() {
        this.selectBank.id = this.$route.params.idb
        if(this.$store.getters['core/getOpenModal'].show){
            this.$store.commit("core/setLoadingModal", false);
            this.$store.commit("core/setCloseModal", {});
        }
            window.billing
                .get("banking/setting/" + window.localorgdata)
                .then((response) => {
                    if (response.data.status) {
                        let setting = JSON.parse(response.data.result.meta_value);
                        // this.selectBank.id = setting.bank_id;
                        if (setting.yearoption === "ly") {
                            this.SelectOne = 2;
                        } else if (setting.yearoption === "ty") {
                            this.SelectOne = 1;
                        }
                        console.log("setting get=>", setting);
                    }else{
                        console.log("setting default SelectOne=>1");
                    }
                    this.filtros();
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                    this.filtros();
                });
        }, 
        SetSettingFilter(action) {
            if (action === "ly") {
                this.SelectOne = 2;
            } else if (action === "ty") {
                this.SelectOne = 1;
            }
            let setting_items = { bank_id: this.selectBank.id, yearoption: action };
            window.billing
                .post("banking/setting", {
                    setting_items: JSON.stringify(setting_items),
                    org_id: window.localuserdata,
                })
                .then((response) => {
                    this.filtros();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        filtros() {
            this.statusdropd = false;
            let datos = {
                id: this.$route.params.idb,
                filter: this.SelectOne,
                status: this.statuslist,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };
            this.$store.commit('ModApps/billing/SetFilterBank', datos);
            if (this.SelectOne === 1) {
                // this.filtrosResumen(1);
            }
            if (this.SelectOne === 2) {
                // this.filtrosResumen(2);
            }
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
            }
        },       
        modalTransac() {
            this.Transac.banking_id = this.$route.params.idb;
            let t = this;
            this.m = {
                show: true,
                component: 'formBaking',
                data: {
                    Transac:t.Transac,
                }
            }  
            this.$store.commit('core/setOpenModal', this.m);
        }, 
        downloadTemplete(prop) {
            window.open(this.template[prop], "_blank");
        },
        importFile(event) {
            if (event !== undefined) {
                this.loadingUnaccepted = true;
                this.file = event.target.files[0];
                debugger;
                let ext = this.file.name.split(".");
                if(ext[1] === "cvs" || ext[1] === 'xlsx') {
                    let f = new FormData();
                    f.append("import", this.file, this.file.name);
                    f.append("user_id", window.localuserdata);
                    f.append("banking_id", this.$route.params.idb);
                    f.append("type", this.selectBank.account_type);
                    f.append("typeFile", ext[1]);
                    window.billing
                        .post("banking/import", f)
                        .then((response) => {
                            console.log("import  file test");
                            console.log(response.data);
                            this.$notify({
                                group: "noti",
                                title: "Processing",
                                type: "success",
                                text: "File processed successfully",
                            });
                            this.filtros();
                            this.loadingUnaccepted = false;
                            this.$refs.template.value = "";
                        })
                        .catch((error) => {
                            this.loadingUnaccepted = false;
                            this.$refs.template.value = "";
                            console.log(error);
                        });                
                } else {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Error extension",
                        text: "Extension error, it must be csv or xlsx",
                        showConfirmButton: false,
                        timer: 2000,
                    });                    
                }
            }
        }, 
        delUnaccepted() {
            if (this.selected.length > 0) {
                this.$swal({
                    title: "Delete",
                    text: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Remove!",
                }).then((result) => {
                    if (!result.value) { 
                        this.$store.commit('ModApps/billing/setDelItemSelected', 0);
                    }
                    else {
                        this.loadingUnaccepted = true;
                        this.$store.commit("core/setLoadingModal", true);
                        window.billing
                            .post("banking/unacceptdel", { selected: this.selected })
                            .then((response) => {
                                this.$notify({
                                    group: "noti",
                                    title: "Remove",
                                    type: "success",
                                    text: "Successfully eliminated",
                                });
                                this.selected = [];
                                this.filtros();
                                this.loadingUnaccepted = false;
                                this.$store.commit("core/setLoadingModal", false);
                                this.$store.commit("core/setCloseModal", {action: 'saved'});
                                this.$store.commit('ModApps/billing/setDelItemSelected', 0);
                            })
                            .catch((error) => {
                                console.log(error)
                                this.$store.commit("core/setLoadingModal", false);
                                this.$store.commit('ModApps/billing/setDelItemSelected', 0);
                            });
                    }
                });
            }
        }, 
        AsignUnaccepted() {
            let t = this;
            if (t.selected.length > 1) {
                let assignExp = {
                    f:{},
                    title: null,
                    AccountList: [],
                    assing: 0,
                    vendor: 0,
                    isLoading: 0,
                };
                assignExp.title = "Assign Multiple Expense";
                assignExp.AccountList = t.$store.getters['ModApps/billing/getAccountListBanking'];
                assignExp.assing = true;
                assignExp.vendor = false;
                assignExp.isLoading = true;
                assignExp.f.id = t.selected;
                assignExp.f.masivo = 1;
                assignExp.f.Vendor = 0;
                assignExp.f.subcontractor_id = 0;
                assignExp.f.Account = 39;
                assignExp.f.typeOption = 2;
                assignExp.f.description = '';
                assignExp.f.amount = 0;
                assignExp.f.date = '';
                t.m = {
                    show: true,
                    component: 'assignExpense',
                    data: assignExp
                }  
                t.$store.commit('core/setOpenModal', t.m);
            }
        },                    
    },
    watch: {
    	TextfilterBanksList: function () {
    		this.$store.commit('ModApps/billing/setTextfilter', this.TextfilterBanksList);
    	},
    	getSelected: function () {
            if(typeof this.getSelected === "object") {
                // si es un array y viene desde table transac
                this.selected = this.getSelected;
            }
            else if(Number(this.getSelected) && this.getSelected > 0) {
                // si es number es xq viene desde modal de assignar expense
                this.selected = [this.getSelected];
                this.delUnaccepted();
            }
    	},
    	getTemplateBanking: function () {
            console.log('getTemplateBanking', this.getTemplateBanking)
    		this.template = this.getTemplateBanking;
    	},
        getAssignExpense: function () {
            if(Object.keys(this.getAssignExpense).length > 0) {
                // console.log('getAssignExpense', this.getAssignExpense);
                this.m = {
                    show: true,
                    component: 'assignExpense',
                    data: this.getAssignExpense
                }  
                this.$store.commit('core/setOpenModal', this.m);
            }
        },
        getOpenModal: function() {
            if (!this.getOpenModal.show) {               
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },  
    },
	computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
		...mapGetters("ModApps/billing", { 
			getSelected: "getDelItemSelected",
			getTemplateBanking: "getTemplateBanking",
            getAssignExpense: "getAssignExpense",
		}),
	}
};
</script>
<style lang="css" scoped>
@import '../../assets/filtrosTransac.css';
.inputDateFilter {
    width: 110px !important;
    border: 0px solid #afafaf;
    overflow: hidden;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #afafaf;
    padding: 10px;
    list-style: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status,
.btn-status:hover,
.btn-status:focus,
.btn-status:active {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #afafaf;
    background-color: #fff;
    padding: 7px;
    outline: none;
}
.input-group-prepend {
    border: 1px solid #afafaf;
    border-right: 0px;
}
.input-group select.form-control {
	border-left: 0px;
	border: 1px solid #afafaf;
}
</style>
