<template>
	<div class="card">
		<div class="card-header">Renewal Date</div>
		<div class="card-body" style="padding-top:0px;">
			<form @submit.prevent="form">
				<div class="row">	
                </div>
                <div class="row">
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Interval</label>
						<select class="form-control" v-model="f.interval">
                            <option value="0">-- Select Interval --</option>
							<option value="1">monthly</option>
                            <option value="2">Year</option>
						</select>
					</div>						
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Day</label>
						<select class="form-control" v-model="f.day">
							<option :value="i" v-for="(i,index) in day" v-bind:key="index">{{i}}</option>
						</select>
					</div>

					<div class="col-md-4 marginTop">
						<label class="col-form-label">Month</label>
						<select class="form-control" v-model="f.month" :disabled="(f.interval==1)">
							<option :value="i" v-for="(i,index) in month" v-bind:key="index">{{i}}</option>
						</select>
					</div>										
					<div class="col-md-12">
						<div style="margin-top:15px;display: flex;justify-content: flex-end;">
							<button type="submit" class="btn btn-success " style="margin-right:10px;">SAVE</button>
						</div>
					</div>						
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	name: 'renewal',
	data () {
		return {
			f : {
				user_id:0,
                org_id:0,
                month:0,
                day:0,
                interval:0,
			},
			month:[],
			day:[],	         					
		}
	},
	created() {
		let data = this.$store.getters['core/getOpenModal'].data;
        // console.log(data);
		this.f.user_id = data.user_id
        this.f.org_id = data.id
        this.f.month = data.renewal_mes
        this.f.day = data.renewal_dia
        this.f.interval = data.renewal_interval  
        console.log('datos', this.f);
        this.init();
	},
	methods: {	
        init(){      
            for (let i = 1; i <= 12; i++) {
                this.month.push(i);
            }
            for (let i = 1; i <= 28; i++) {
                this.day.push(i);
            }                      
        },
		form(){	                        		
			let t = this;
            t.$store.commit('core/setLoadingModal', true);
            let url ='saveRenewal';
            console.log(this.f);
            window.reselleR2.post(url, t.f)
            .then((response) => {
                this.resetIntervat()
                t.$store.commit('core/setLoadingModal', false);
                t.$store.commit("core/setCloseModal", {action: 'saved', method: 'refresh-editOrg'});                         
            }).catch((error) => {
                console.log(error);
                t.$store.commit('core/setLoadingModal', false);
            });
		},
		resetIntervat(){
			this.f.user_id = ''
			this.f.org_id = ''
        	this.f.month = 0
        	this.f.day = 0
        	this.f.interval = 0
		},        
	}  
};
</script>
<style lang="css" scoped>
	.form-control {
		border: 1px solid #aaa;
	}
</style>
