import langjobs from '@/apps/jobs/lang/';
import urlApis from '@/apps/jobs/apis/';
export default {
	langjobs: langjobs,
	urlApis: urlApis,
	jobs: false,
	lateralShow: true, // oculto= true, mostar = false
	modalAddTo: false, // oculto= false, mostar = true
	alertTosaved: false, // oculto= false, mostar = true
	jobsOrderPending: 0, 
	menu_tags:[],
	// jobsListAssignUser:{user_id_assign: 0, name:null, email:null},
	jobsListAssignUser:{user_id_assign: 0},
	changeAssignUser: false,
	searchJobs: '',
	typeVisor: 1,
	act_list: 0,
	itemJob: {idx:0, item:{}},
	editTask: {},
	delTags:[],
	editTags: {idx:0, idJob:0, active:false, option:[], values:[] },
	editCusInv: {},
	showCus: {active:false, idx:0, item:{}},
	ActiveMA: false,
	refresh_grid: false,
	todolist:[],
	refresh_list: false,
	jobsDash:[],
	datafilterTagsUrl: {},
	sortJobsBy: 0,
	seeTagsDefault: false,
}
