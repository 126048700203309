<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title">
                        <i class="fas fa-chart-line"></i> Report Expenses by Accounts
                    </h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-right justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link> 
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body" style="padding-bottom: 130px;">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    style="width: inherit;min-width: 80px;"
                                                    @click="FilterExpensesbyAccounts"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button>
                                            </div>                               
                                            <div class="col-md-8">
                                                <select class="form-control" v-model="SelectOne" @change="ChangeSelectPrimary">
                                                    <option value="1">This Year</option>
                                                    <option value="4">Last Year</option>
                                                    <option value="2">This Month</option>
                                                    <option value="3">Calendar</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divS" class="col-md-4" v-if="SelectOne == 3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>From:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateFrom"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>            
                                                    </div>
                                                </div>                               
                                            </div>
                                            <div class="col-md-6">   
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>To:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateTo"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>           
                                                    </div>                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divB" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <model-select
                                                        class="form-control"
                                                        :options="Accounts"
                                                        v-model="account_id"
                                                        placeholder="Choose a Account"
                                                    >
                                                    </model-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divC" v-if="SelectOne != 3" class="col-md-6">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>                                                        
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                            <!--    <button type="button" class="btn btn-primary" style="margin-right: 7px;"><i class="fas fa-file-excel"></i> Download Spreadsheet</button>
                                                    <button type="button" class="btn btn-secondary"><i class="fas fa-file-pdf"></i> Open PDF</button> -->
                                        </div>
                                    </div>   
                                    <div id="divC" v-else class="col-md-2">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>                                                        
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->                        
                            
                            <div class="col-md-12">
                                <Loader class="bgloading" v-if="isLoading"></Loader>
                                <div
                                    class="contenedor-table"
                                    id="expensesByAccounts"
                                    v-else
                                >
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Account Name</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style="font-weight: bold">
                                                    ${{ total | formatMoney }}
                                                </td>
                                            </tr>
                                            <tr v-if="noresult">
                                                <td colspan="3">
                                                    <div
                                                        style="
                                                            text-align: center;
                                                            color: red;
                                                        "
                                                    >
                                                        No result
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="(
                                                    i, index
                                                ) in ExpensesByAccountsList"
                                                v-bind:key="index"
                                                v-else
                                            >
                                                <td>Expenses</td>
                                                <td>{{ i.name }}</td>
                                                <td>${{ i.totalExp | formatMoney }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th>${{ total | formatMoney }}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import { ModelSelect } from "vue-search-select";
export default {
    components: {
        Loader,
        ModelSelect,
    },
    name: "ExpensesbyAccount",
    data() {
        return {
            isLoading: false,
            dateFrom: new Date().toISOString(),
            dateTo: new Date().toISOString(),
            thisYear: null,
            thisMonth: null,
            SelectOne: 1,
            account_id: "",
            Accounts: [],
            ExpensesByAccountsList: [],
            total: 0,
            startdate: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T06:00:00.000Z"
            ).toISOString(),
            enddate: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T06:00:00.000Z"
            ).toISOString(),
            noresult: false,
        };
    },
    watch: {
        startdate: function () {
            this.formatearFecha();
            this.dateFrom = this.startdate;
        },
        enddate: function () {
            this.formatearFecha();
            this.dateTo = this.enddate;
        },
    },
    created() {
        this.getchartaccount();
        this.FilterExpensesbyAccounts();
    },
    mounted() {
        this.setTimeoutResponsive()
    },    
    methods: {
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            let divS = document.getElementById("divS");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }  
            } 
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                              	
            }
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
        },
        ChangeSelectPrimary() {
            this.setTimeoutResponsive()
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        },         
        formatearFecha() {
            this.startdate = new Date(
                (new Date(this.startdate).toISOString() + "").slice(0, 10) +
                    "T06:00:00.000Z"
            ).toISOString();
            if (this.enddate != "") {
                this.enddate = new Date(
                    (new Date(this.enddate).toISOString() + "").slice(0, 10) +
                        "T06:00:00.000Z"
                ).toISOString();
            }
        },
        getchartaccount() {
            this.isLoading = true;
            window.billing
                .get("expenses/account/" + window.localuserdata)
                .then((response) => {
                    if (response.data.result.length > 0) {
                        let AccountList = [{ value: "", text: "Alls Accounts" }];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].name_account;
                            AccountList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.Accounts = AccountList;
                        this.isLoading = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        /* generarFiles(type){
	    	this.$swal({
            	title: "Export",
            	text: "Export Report as "+type+" (Microsoft Excel)",
            	icon: "info",
            	allowOutsideClick: false,
            	showCancelButton: true,
            	confirmButtonColor: "#3085d6",
            	cancelButtonText: 'Cancel',
            	confirmButtonText: "Export",
            	showLoaderOnConfirm: true,
            	preConfirm: () => {
	            	window.billing.post('report/export/expensesaccounts',{
	                    user_id: window.localuserdata,
	                    selecttype: this.SelectOne,
	                    account_id: this.account_id,
	                    dateFrom: this.dateFrom,
	                    dateTo: this.dateTo,
	                    filetype : type,
	                })
	                .then(response => {
	                    console.log(response.data);
	                    this.isLoading = false;
	                    window.open(window.urlbase+'files/'+response.data.result);                    
	                })
	                .catch(error => {
	                  this.$swal.showValidationMessage(
	                    `Request failed: ${error}`
	                  )
	                })
            	},
            	allowOutsideClick: () => !this.$swal.isLoading()
            })
            .then((result) => {
            	if (result.value) {
            		this.$swal.fire({
            			allowOutsideClick: false,
            			text: `Successfully downloaded`,
            			confirmButtonText: "Close",
            			showLoaderOnConfirm: true,
            			preConfirm: () => {
            				this.DeleteFile();
            			},
            		});
            	}
            });
        }, */
        DeleteFile() {
            let name = "expenses_by_accounts";
            window.billing
                .get(
                    "report/expensesaccounts/deletefiles/" +
                        window.localuserdata +
                        "/" +
                        name
                )
                .then((response) => {
                    console.log(response.data.result);
                })
                .catch((error) => console.log(error));
        },
        generarFile(type) {
            let user_id = window.localuserdata;
            let selecttype = this.SelectOne;
            let account_id = this.account_id == "" ? 0 : this.account_id;
            let dateFrom = this.dateFrom;
            let dateTo = this.dateTo;
            let uri =
                "report/expensesaccounts/pdf/" +
                user_id +
                "/" +
                selecttype +
                "/" +
                account_id +
                "/" +
                dateFrom +
                "/" +
                dateTo +
                "/" +
                type;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
        FilterExpensesbyAccounts() {
            this.isLoading = true;
            window.billing
                .post("report/expensesaccounts", {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                    account_id: this.account_id,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                })
                .then((response) => {
                    console.log(response.data.result);
                    this.isLoading = false;
                    let e = response.data.result;
                    this.ExpensesByAccountsList = e;
                    this.total = 0;
                    if (Object.keys(e).length > 0) {
                        this.noresult = false;
                        for (let i in e) {
                            this.total += parseFloat(e[i].totalExp);
                        }
                    } else {
                        this.noresult = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style lang="css" scoped>
#expensesByAccounts table th {
    font-weight: bold;
}
#expensesByAccounts table thead tr th,
#expensesByAccounts table tbody tr td,
#expensesByAccounts table tfoot tr th {
    text-align: left;
}
#expensesByAccounts table thead tr th:nth-child(3),
#expensesByAccounts table tbody tr td:nth-child(3),
#expensesByAccounts table tfoot tr th:nth-child(3) {
    text-align: right;
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
