<template>
	<div class="container">
		<div class="row">
			<div class="card-body">
                <listDesign></listDesign>
				<table v-if="showlist" class="table table-bordered table-inverse table-hover">
					<thead>
						<tr>
							<th class="text-center" style="width: 50px;">ID</th>
							<th>Name interno</th>
							<th>Titulo</th>
							<th class="text-center">Public</th>
							<th style="width:100px;"></th>
						</tr>
					</thead>
					<tbody>
                        <tr v-if="isLoading"><td colspan="5"><Loader></Loader></td> </tr>
                        <tr v-else-if="list.length == 0">
                            <td colspan="5" class="text-center">No records</td> 
                        </tr>
                        <tr v-else v-for="(i, index) in list" :key="index">
							<td class="text-center" style="width: 50px;">{{i.id}}</td>
							<td>{{i.name}}</td>
							<td>{{i.title}}</td>
							<td class="text-center">
								<span class="badge badge-pill badge-success" >Active</span>
								<!-- <span class="badge badge-pill badge-danger" v-else>Inactive</span> -->
							</td>
							<td style="width:100px;">
								<span class="cdropdown">
									<button class="btn btn-sm mr-2">
									Actions
									</button>
									<label>
									<input type="checkbox" />
									<ul>
										<li @click="editLanding(i.id)"> edit</li>
										<li @click="duplicate(i.id)"> Duplicate</li>
										<li @click="del(i.id)"> delete</li>
									</ul>
									</label>
								</span>								
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>  
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import listDesign from "@/apps/Marketea/components/LandingPage/listDesign";
export default {
	name: 'listInfo',
    components: {
        Loader,
        listDesign
    },    
	data () {
		return {
			f:{
                id: 0,
                user_id: window.localAccountID,
                org_id: window.localorgdata,							
			},
			showlist:true,
            isLoading:false,
            list: [],
			apis:{},
			landingInfo:[],
			landingMeta:[],
			landingTypeDesin:[],
		}
	},
    created() {
        this.init();
    },
    methods: {
        init() {
            let apisForm = this.$store.getters["ModApps/Marketea/urlApis"];
            this.apis = apisForm.landingPage;
            this.get();
        },
        editLanding(id) {
            this.$router.push({ name: 'editMaquetador', params: {id: id} });
        },
        form() {
            this.isLoading = true;
            let url = this.apis.add();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        get() {
            this.isLoading = true;
            let url = this.apis.get();
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.rs;
                    this.list = r;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        del(id){
            this.f.id = id
            this.isLoading = true;
            let url = this.apis.del();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful Deleting",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        duplicate(id){
            this.f.id = id
            this.isLoading = true;
            let url = this.apis.duplicate();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful Duplicate",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        formMeta() {
            this.isLoading = true;
            let url = this.apis.addMeta();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        getMeta() {
            this.isLoading = true;
            let url = this.apis.getMeta();
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.rs;
                    this.landingMeta = r
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
		
        formTypeDesin() {
            this.isLoading = true;
            let url = this.apis.addTypeDesing();
            window.master
                .post(url, this.f)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        getTypeDesin() {
            this.isLoading = true;
            let url = this.apis.getTypeDesin();
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.rs;
                    this.landingTypeDesin = r
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },		
    },  
}
</script>
<style lang="css" scoped>
</style>
