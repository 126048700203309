var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",staticStyle:{"padding-top":"10px"}},[_c('h3',{staticClass:"card-title"},[_vm._v("Edit Tags "),(_vm.item!=null)?_c('span',{staticClass:"badge badge-success"},[_vm._v("#"+_vm._s(_vm.item.idx))]):_vm._e()])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"selecttags"},[_c('multiselect',{ref:'vselect'+_vm.item.id,class:['form-control', 'vselect'+_vm.item.id],attrs:{"tag-placeholder":"Add new","placeholder":"Add","label":"category_name","track-by":"id","options":_vm.CategoryList,"multiple":true,"taggable":true,"clear-on-select":false,"select-label":"Choose","deselect-label":"Remove"},on:{"tag":_vm.addTag,"input":_vm.changeTags},scopedSlots:_vm._u([{key:"tag",fn:function(props){return [_c('span',{class:[
                        'multiselect__tag', 
                        (_vm.item.category.length<=4) ? 'wAuto' : 
                            (_vm.item.category.length<6) ? 'w60' : 
                                (_vm.item.category.length<7) ? 'w50' : 
                                    (_vm.item.category.length<8) ? 'w40' : 
                                        (_vm.item.category.length<13) ? 'w30' : 
                                            (_vm.item.category.length<16) ? 'w20' : ''
                        ],attrs:{"title":_vm.getOptionDisplay(props.option)}},[_c('span',{domProps:{"textContent":_vm._s(_vm.getOptionDisplay(props.option))}}),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"tabindex":"1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.removeElement(props.option, _vm.item)},"mousedown":function($event){$event.preventDefault();return _vm.removeElement(props.option, _vm.item)}}})])]}}]),model:{value:(_vm.item.category),callback:function ($$v) {_vm.$set(_vm.item, "category", $$v)},expression:"item.category"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }