<template>
    <div class="gallery" v-if="imagenes.length > 0">
        <div
            v-for="(src, i) in imagenes"
            :key="i"
            class="img-thumbnail"
        >
            <div class="custom-control custom-radio">
            <input type="radio" v-model="coverItem" :value="src.id" :id="'cover'+i" class="custom-control-input" @change="coverSeleted(src)">
            <label class="custom-control-label" :for="'cover'+i">Cover</label>
            </div>
            <div class="show" @click.prevent="show(i)" :style="bg(url + src.name)">
                <i class="fas fa-search-plus"></i>
            </div>
        </div>
        <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
        >
            <div class="lb-modal" v-if="index >= 0">
                <button
                    class="btn btn-outline-danger btn-sm lb-modal-close"
                    @click="close"
                >
                    <i class="fas far fa-times fa-2x"></i>
                </button>
                <button class="btn btn-outline-primary lb-modal-prev" @click="prev">
                    <i class="fas far fa-angle-left fa-3x"></i>
                </button>
                <button class="btn btn-outline-primary lb-modal-next" @click="next">
                    <i class="fas far fa-angle-right fa-3x"></i>
                </button>
                <div class="btn-group op-avanzadas">
                    <a
                        :href="src"
                        target="_blank"
                        download
                        class="btn btn-link lb-modal-download"
                    >
                        <i class="fas fa-download"></i>
                    </a>
                    <!--<button
                        type="button"
                        class="btn btn-link lb-modal-zoom-menos"
                        @click="zoomManual(0)"
                    >
                        <i class="fas fa-search-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link lb-modal-zoom-mas"
                        @click="zoomManual(1)"
                    >
                        <i class="fas fa-search-plus"></i>
                    </button>-->
                    <button
                        type="button"
                        class="btn btn-link lb-modal-rotate-left"
                        @click="rotate(1)"
                    >
                        <i class="fas fa-undo"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link lb-modal-rotate-right"
                        @click="rotate(2)"
                    >
                        <i class="fas fa-undo rotate_custom"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-link lb-modal-delete"
                        @click="delIMG"
                        v-if="typez == 0"
                    >
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
                <div class="lb-modal-img" @click="close">
                    <img :src="src" :style="girarImg" id="ImgGallery" />
                    <div class="spinner spinner-dots-wave" v-if="loading">
                        <span class="badge badge-primary rounded-circle w-10 h-10">
                            <i class="sr-only">&nbsp;</i>
                        </span>
                        <span class="badge badge-primary rounded-circle w-10 h-10">
                            <i class="sr-only">&nbsp;</i>
                        </span>
                        <span class="badge badge-primary rounded-circle w-10 h-10">
                            <i class="sr-only">&nbsp;</i>
                        </span>
                        <span class="badge badge-primary rounded-circle w-10 h-10">
                            <i class="sr-only">&nbsp;</i>
                        </span>
                        <span class="badge badge-primary rounded-circle w-10 h-10">
                            <i class="sr-only">&nbsp;</i>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        url: {
            type: String,
            default: () => "/",
        },
        css: {
            type: String,
            default: () => "h-250",
        },
        cover: {
            type: Number,
            default: () => 0,
        },
        cells: {
            type: Number,
            default: () => 5,
        },
        typez: {
            type: Number,
            default: () => window.user_type,
        },
        salesID: {
            type: Number,
            default: () => 0,
        },
    },
    data() {
        return {
            src: "",
            idsrc: 0,
            index: -1,
            loading: false,
            events: [],
            imagenes: [],
            rotar: 0,
            girarImg: "",
            idx: 0,
            tam: 1000,
            tamMin: 1000,
            tamMax: 1000,
            coverItem: 0,
        };
    },
    created() {
        this.imagenes = this.items;
        this.imagenes.filter(r => {
            if(r.cover>0){
                this.coverItem = r.id;
            }
        });
        console.log("imagenes", this.imagenes);
    },
    mounted() {
        if (window.addEventListener) {
            window.addEventListener("DOMMouseScroll", this.wheel, false);
            window.onmousewheel = document.onmousewheel = this.wheel;
        }
    },
    watch: {
        items: function () {
            this.imagenes = this.items;
            console.log("imagenes", this.imagenes);
        },
    },
    methods: {
        validTam() {
            if (window.screen.width > 991) {
                this.tamMax = 1000;
            }
            if (window.screen.width < 991 && window.screen.width > 767) {
                this.tamMax = 700;
            }
            if (window.screen.width < 767 && window.screen.width > 600) {
                this.tamMax = 600;
                this.tam = 500;
                this.tamMin = 500;
            }
            if (window.screen.width < 599 && window.screen.width > 400) {
                this.tamMax = 400;
                this.tam = 300;
                this.tamMin = 300;
            }
            if (window.screen.width < 399 && window.screen.width > 360) {
                this.tamMax = 360;
                this.tam = 300;
                this.tamMin = 300;
            }
            if (window.screen.width < 359 && window.screen.width > 320) {
                this.tamMax = 320;
                this.tam = 290;
                this.tamMin = 290;
            }
        },
        zoomManual(zoom) {
            this.validTam();
            if (zoom == 0) {
                this.tam = this.tamMin;
            } else {
                this.tam = this.tamMax;
            }
            let img = document.getElementById("ImgGallery");
            if (img) {
                img.style.width = this.tam + "px";
            }
        },
        moveMouseWheel(delta) {
            this.validTam();
            if (delta < 0 && this.tam >= this.tamMin) {
                this.tam -= 10;
            } else if (delta > 0 && this.tam <= this.tamMax) {
                this.tam += 10;
            }
            let img = document.getElementById("ImgGallery");
            if (img) {
                img.style.width = this.tam + "px";
            }
        },
        wheel(event) {
            var delta = 0;
            if (!event) event = window.event;
            if (event.wheelDelta) {
                delta = event.wheelDelta / 120;
                if (window.opera) delta = -delta;
            } else if (event.detail) {
                delta = -event.detail / 3;
            }
            if (delta) {
                this.moveMouseWheel(delta);
            }
        },
        downloadIMG() {},
        rotate(r) {
            if (r == 1) {
                r = -90;
            }
            if (r == 2) {
                r = 90;
            }
            this.rotar += r;
            this.girarImg =
                `-moz-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-o-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-webkit-transform: rotate(` +
                this.rotar +
                `deg);` +
                `transform: rotate(` +
                this.rotar +
                `deg);`;
            //console.log('rotate', this.rotar, 'girarImg='+this.girarImg);
        },
        delIMG() {
            let t = this;
            t.$swal({
                title: "Delete Image?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.loading = true;
                    window.master
                        .post("/jobs/delImgGallery", {
                            user_id: window.master_client,
                            org_id: window.localorgdata,
                            id: t.idsrc,
                            delete: 'item',
                        })
                        .then((response) => {
                            t.imagenes.splice(t.idx, 1);
                            t.show(t.index - 1);
                            t.loading = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            t.loading = false;
                        });
                }
            });
        },
        coverSeleted(img) {
            let t = this;
            t.loading = true;
            window.master
            .post("/jobs/coverSeleted", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                job_id: img.job_id,
                id: img.id,
            })
            .then((response) => {
                t.loading = false;
            })
            .catch((error) => {
                console.log(error);
                t.loading = false;
            });                
        },
        bind() {
            if (this.events.length > 0) return;
            this.events.push([
                "keydown",
                (e) => {
                    if (this.index < 0) return;
                    if (e.keyCode === 37) {
                        this.prev();
                    } else if (e.keyCode === 39) {
                        this.next();
                    } else if (e.keyCode === 27) {
                        this.close();
                    }
                },
            ]);
            this.events.forEach((e) => {
                window.addEventListener(e[0], e[1]);
            });
        },
        show(i) {
            document.body.style.overflowY = "hidden";
            if (i >= this.imagenes.length) {
                i = 0;
            }
            if (i < 0) {
                i = this.imagenes.length - 1;
            }
            this.loading = true;
            this.bind();
            this.index = i;
            //console.log('index',i);
            this.idx = i;
            this.tam = this.tamMin; // reiniciar tamaño
            if(this.imagenes.length>0){
                var src = this.url + this.imagenes[i].name;
                var idsrc = this.imagenes[i].id;
                let t = this;
                var img = new Image();
                img.src = src;
                img.onload = () => {
                    t.rotar = 0;
                    t.girarImg = "";
                    t.loading = false;
                    t.src = src;
                    t.idsrc = idsrc;
                    let imgItem = document.getElementById("ImgGallery");
                    if (imgItem) {
                        imgItem.style.width = t.tam + "px";
                    }
                };
            }
        },
        next() {
            this.show(this.index + 1);
        },
        prev() {
            this.show(this.index - 1);
        },
        close() {
            this.index = -1;
            this.events.forEach((e) => {
                window.removeEventListener(e[0], e[1]);
            });
            this.events = [];
            document.body.style.overflowY = "initial";
        },
        bg(i) {
            return i && i.length > 0 ? `background-image: url('${i}');` : "";
        },
    },
};
</script>
<style lang="scss" scoped>
    .lb-grid {
    position: relative;
    display: block;
}
.lb-item {
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
}
.lb-item:hover, .lb-item:focus {
    opacity: 0.6;
    cursor: pointer;
}
.lb-grid-1 .lb-item {
    width: 100%;
    height: 100%;
}
.lb-grid-2 .lb-item, .lb-grid-3 .lb-item, .lb-grid-4 .lb-item, .lb-grid-5 .lb-item {
    width: 50%;
}
.lb-grid-2 .lb-item, .lb-grid-3 .lb-item:nth-child(1), .lb-grid-4 .lb-item:nth-child(1) {
    height: 100%;
}
.lb-grid-3 .lb-item:nth-child(2), .lb-grid-3 .lb-item:nth-child(3), .lb-grid-5 .lb-item:nth-child(1), .lb-grid-5 .lb-item:nth-child(2) {
    height: 50%;
}
.lb-item:last-child, .lb-grid-2 .lb-item:nth-child(2), .lb-grid-3 .lb-item:nth-child(2), .lb-grid-3 .lb-item:nth-child(3), .lb-grid-4 .lb-item:nth-child(2), .lb-grid-4 .lb-item:nth-child(3), .lb-grid-4 .lb-item:nth-child(4), .lb-grid-5 .lb-item:nth-child(3), .lb-grid-5 .lb-item:nth-child(4), .lb-grid-5 .lb-item:nth-child(5) {
    left: auto;
    right: 0;
    border-right: 0;
}
.lb-grid-3 .lb-item:nth-child(3), .lb-grid-4 .lb-item:nth-child(4), .lb-grid-5 .lb-item:nth-child(2), .lb-grid-5 .lb-item:nth-child(5) {
    bottom: 0;
    top: auto;
}
.lb-grid-4 .lb-item:nth-child(3), .lb-grid-5 .lb-item:nth-child(4) {
    top: 33.333333333333336%;
}
.lb-grid-4 .lb-item:nth-child(2), .lb-grid-4 .lb-item:nth-child(3), .lb-grid-4 .lb-item:nth-child(4), .lb-grid-5 .lb-item:nth-child(3), .lb-grid-5 .lb-item:nth-child(4), .lb-grid-5 .lb-item:nth-child(5) {
    height: 33.333333333333336%;
}
.lb-more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    font-size: 3rem;
    background-color: rgba(0, 0, 0, .4);
}
.lb-more:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}
/* 
.lb-more:after{
    position: absolute;
    top: 0;
    left: 0;
    left: 0;
    right: 0;
    z-index: -1;   
    content: "";
    background: #222;
    opacity: 0.9;
} */
.lb-modal {
    transition: opacity .2s ease;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    display: block;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.lb-modal-close {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #fff;
    z-index: 999;
    text-shadow: 2px 2px 2px #000;
    padding: 0.3rem 0.5rem;
}
.lb-modal-next, .lb-modal-prev {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -25px;
    /* width: 50px;
    height: 50px; */
    z-index: 999;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    border: none;
    text-shadow: 2px 2px 2px #000;
}
.lb-modal-next {
    left: auto;
    right: 20px;
}
.lb-modal-img {
    position: absolute;
    top: 10px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    text-align: center;
}
.lb-modal-img:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}
.lb-modal-img img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    object-fit: contain;
}
.lb-modal-img .spinner {
    width: 150px;
    position: absolute;
    margin-left: -75px;
    top: 50%;
    left: 50%;
    z-index: 1;
}
.h-3 {
    height: .189rem !important
}
.h-5 {
    height: .315rem !important
}
.h-10 {
    height: .63rem !important
}
.h-15 {
    height: .945rem !important
}
.h-20 {
    height: 1.26rem !important
}
.h-25 {
    height: 1.575rem !important
}
.h-30 {
    height: 1.89rem !important
}
.h-35 {
    height: 2.205rem !important
}
.h-40 {
    height: 2.52rem !important
}
.h-50 {
    height: 3.15rem !important
}
.h-60 {
    height: 3.78rem !important
}
.h-70 {
    height: 4.41rem !important
}
.h-80 {
    height: 5.04rem !important
}
.h-90 {
    height: 5.67rem !important
}
.h-100 {
    height: 6.3rem !important
}
.h-150 {
    height: 9.45rem !important
}
.h-200 {
    height: 12.6rem !important
}
.h-250 {
    height: 15.75rem !important
}
.h-300 {
    height: 18.9rem !important
}
.h-350 {
    height: 22.05rem !important
}
.h-400 {
    height: 25.2rem !important
}
.h-450 {
    height: 28.35rem !important
}
.h-500 {
    height: 31.5rem !important
}
.h-550 {
    height: 34.65rem !important
}
.h-600 {
    height: 37.8rem !important
}
.h-650 {
    height: 40.95rem !important
}
.rotate_custom {
     transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}
.op-avanzadas{    
    position: absolute;
    top: 10px;
    right: 58px;
    left: initial;
    margin-top: 0px;
    z-index: 999;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.op-avanzadas:focus{ 
    outline: none;
}
.op-avanzadas .btn-link{ 
    text-shadow: 2px 2px 2px #000;
    color: #fff;
    font-size: 20px;
    border-color: #fff;
    border-radius: 4px;
    padding: 0.25rem 0.5rem
}
.lb-modal-download { 
}
.lb-modal-rotate-left {  
}
.lb-modal-rotate-right {
}
.lb-modal-delete {
}
.lb-modal-zoom-menos{}
.lb-modal-zoom-mas{}
.lb {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 2px;
}
.swal2-container {
    z-index: 9999 !important;
}
@media(max-width: 767px){ 
    .op-avanzadas{
        left: 25%;
        top: 92px;
    }
    .lb-modal-close{
        top: 92px;   
    }
    .lb-modal-img {
        top: -24px;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }
}
@media(max-width: 420px){ 
    .op-avanzadas{
        left: 0%;
    }
}
.img-thumbnail {
    height: 184px;
    margin: 0.66667%;
    width: 184px;
    flex: 0 0 16%;
    display: inline-block;
}
.img-thumbnail:hover {cursor: pointer;}
.show {
    position: relative;
    top: 0%;
    height: 70%;
    width: 100%;
    text-align: center;
    background-size: cover;
}
.show i{
    opacity: 0.2;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 30px;
    margin-top: 10px;
    transition: all;
}
.show:hover i{
    opacity: 0.9;
}
.custom-control.custom-radio:hover{
    label.custom-control-label{
        font-weight: bold;
        cursor: pointer;
    }
}
</style>
