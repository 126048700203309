/*rutas de WriteaCheck*/
import WriteaCheck from '@/apps/billing/views/WriteaCheck';
import WriteaCheckNew from '@/apps/billing/views/WriteaCheckNew';
export default [
	{
        path: 'write-check',
        name: 'WriteaCheck',
        folder: WriteaCheck,
	},
    {
        path: 'write-check/new',
        name: 'WriteaCheckNew',
        folder: WriteaCheckNew,
   }

];
