let updateJobsAssign = function() {
    let i = this.accitonPusher.item.user_id_assign;
    let o = this.accitonPusher.item.user_id_assign_old;
    if (i.length > 0 && i.indexOf(parseInt(window.localAccountID)) > -1) {
        this.newAddRefresh(this.accitonPusher.item.job, 1);
    } else {
        if (o.length > 0 && o.indexOf(parseInt(window.localAccountID)) > -1) {
            for (var k in this.listTaks) {
                if (this.listTaks.hasOwnProperty(k)) {
                    if (this.listTaks[k].id_i == this.accitonPusher.item.job) {
                        this.listTaks.splice(k, 1);
                    }
                }
            }
            this.reEscribirLista();
            let tag_id = this.id_tags.length > 0 ? this.id_tags[0] : 0;
            this.update_JOPending(tag_id);
            this.starGrid();
        }
    }
};
export default updateJobsAssign;
