let landing = "landing/";
export default {  
	add(){
		return landing+'formLandingInfo';
	},
	get(){
		return landing+'getLandingInfo';
	}, 
	del(){
		return landing+'delLandingInfo';
	}, 
	duplicate(){
		return landing+'duplicateLandingInfo';
	}, 		  
	addMeta(){
		return landing+'formLandingMeta';
	},
	getMeta(){
		return landing+'getLandingMeta';
	},   
	addTypeDesing(){
		return landing+'formLandingTypeDesing';
	},
	getdesing(){
		return landing+'getdesing';
	},            
}
