<template>
	<div class="card ">
		<div class="card-header">
			<h3>{{titleSelected}}</h3>
		</div>
		<div class="card-body">
			<div class="table-responsive">				
				<table class="table table-striped mb-0" v-if="id_campaign>0">
					<thead>
						<tr>
							<td style="width:120px;">Campaign Name:</td>
							<td>
								<ModelSelect 
									v-model="id_campaign"
									:options="listCampaign" 
									placeholder="Campign Name"
									class="form-control"
									@input="groupList"
									style="border:1px solid #ccc !important;min-height: 30px !important;"
								></ModelSelect>
							</td>
						</tr>
					</thead>
				</table>
				<table class="table table-striped mb-0">
					<thead>
						<tr>
							<td style="width:120px;">Add new item:</td>
							<td>
								<form @submit.prevent="addItem">
									<div class="input-group">
										<input type="text" class="form-control" placeholder="Name" v-model="nomItem">
										<div class="input-group-prepend">
												<button class="btn btn-success btn-sm">
													<i class="fas fa-plus"></i>
												</button>
										</div>
									</div>
								</form>
							</td>
						</tr>
					</thead>
				</table>
				<table class="table table-striped">
					<thead>
						<tr>
							<th valign="middle">Name</th>
							<th valign="middle">Status</th>
							<th valign="middle" class="text-right">
							</th>
						</tr>
					</thead>
					<tbody v-if="NoRecord">
						<tr><td colspan="3" class="text-center">No record</td></tr>
					</tbody>
					<draggable
						v-else
						v-model="listMain"
						tag="tbody"
						class="tbody"
						v-bind="dragOptions"
						@change="checkMove"
						@start="isDragging = true"
						@end="isDragging = false"
						handle=".handle"
					>
						<tr
							v-for="(i, index) in listMain"
							:key="index"
						>
							<td scope="row" style="position: relative">
								<div class="btnmove handle">
									<i class="fas fa-ellipsis-v"></i>
								</div>
								<span style="margin-left: 15px">
									<input type="text" class="item-edit" placeholder="Name" v-model="i.name" >
								</span>
							</td>
							<td>
								<div class="onoffswitch">
									<input
										type="checkbox"
										class="onoffswitch-checkbox"
										:id="i.id"
										:checked="i.checked"
										@change="changeStatus(i)"
									/>
									<label class="onoffswitch-label" :for="i.id">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div>
							</td>
							<td class="text-right">
								<button class="btn btn-secondary btn-sm mr-2" @click="editItem(i)">
									<i class="fas fa-edit"></i>
								</button>
								<button class="btn btn-danger btn-sm" @click="deleteRow(i)">
									<i class="fas fa-times"></i>
								</button>
							</td>
						</tr>
					</draggable>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { ModelSelect } from 'vue-search-select'
export default {
	name: 'optiones',   
	components: {
		draggable,
		ModelSelect
	},
	data () {
		return {
			listMain: [],
			NoRecord: false,
			title: [
				'Marketing Source',
				'CTA Channel',
				'Campaign Name',
				'Group Name'
			],
			titleSelected: '',
			nomItem: '',
			id_campaign: 0,
			idOLDcampaign: 0,
			listCampaign: [],
			apis: {},
		}
	},
	created(){
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.apisAds;
		this.init();
	},
	methods: {
		init(){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			t.titleSelected = t.title[d.opts];
			t.$store.commit("core/setLoadingModal", true);
			window.master.post(t.apis.getItemComun(), {
				org_id: window.localorgdata,
				t: d.opts,
			})
			.then((response) => {
				// console.log(response.data);
				let e = response.data.r;
				let list = [];
				if(d.opts <=2) {
					list = response.data.r;
				} else {
					let listCampaign = [];
					for (let i in e) {
						if(e[i]) {
							listCampaign.push({
								value: e[i].id,
								text: e[i].name,
								GroupName: e[i].GroupName,
							});
						}
					}
					t.listCampaign = listCampaign;
					if(t.idOLDcampaign>0) {
						for (let i in e) {
							if(e[i] && e[i].id==t.idOLDcampaign){
								t.id_campaign = t.listCampaign[i].value;
								list = t.listCampaign[i].GroupName;
							}
						}
					}
					else {
						t.id_campaign = t.listCampaign[0].value;
						list = t.listCampaign[0].GroupName;
					}					
				}
				t.moldearLista(list);
				t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
			});
		},
		groupList() {
			let t = this;
			let e = t.listCampaign;
			if(t.id_campaign > 0) {
				for (let i in e) {
					if(e[i] && e[i].value == t.id_campaign) {
						let list = e[i].GroupName;
						t.moldearLista(list);
					}
				}
			}
			t.NoRecord = (!t.listMain.length>0);
			console.log('groupList', t.listMain)
		},
		moldearLista(list) {
			let t = this;
			for (let j in list) {
				if(list[j]) {
					list[j].checked = (list[j].status>0);
					// console.log(j, 'status', list[j].status)
					// console.log(j, 'checked', list[j].checked)
				}
			}
			t.listMain = list;
		},
		addItem() {
			let t= this;
			let d = t.$store.getters['core/getOpenModal'].data;
			if(t.nomItem.length > 0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post(t.apis.setItemComun(), {
					user_id: window.master_client,
					org_id: window.localorgdata,
					t: d.opts,
					name: t.nomItem,
					id_campaign: t.id_campaign,
				})
				.then((response) => {
					if(response.data.status) {
						t.refreshSaved();
						if(t.id_campaign>0) {
							t.idOLDcampaign = t.id_campaign;
						} else {
							t.idOLDcampaign = 0;
						}
						t.init();
						t.nomItem = '';
					}
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});	
			}
		},
		refreshSaved() {
			let t = this;
			let m = t.$store.getters['core/getOpenModal'];
			let setM = {
				show: m.show,
				component: m.component,
				data: {
					opts: m.data.opts,
					list: m.data.list,
					saved: true
				},
			};
			t.$store.commit('core/setOpenModal', setM);
			console.log('activity', t.$store.getters['core/getModalActivity'])
		},		
		deleteRow(i) {
			let t =this;
			t.$swal({
				title: "Delete Item?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					t.deleteItem(i);
				}
			});
		},	
		deleteItem(i){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			if(i.name.length>0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post(t.apis.delItemComun(), {
					org_id: window.localorgdata,
					id: i.id,
					t: d.opts,
				})
				.then((response) => {
					this.init();
					this.refreshSaved();
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});
			}
		},
		editItem(i){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			if(i.name.length>0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.put(t.apis.editItemComun(), {
					org_id: window.localorgdata,
					data: [i],
					t: d.opts,
				})
				.then((response) => {
					console.log(response.data);
					for (let j in t.listMain) {
						if(t.listMain[j].id === i.id) {
							t.listMain[j] = i;
						}
					}
					this.refreshSaved();
					t.$store.commit("core/setLoadingModal", false);
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});
			}
		},
		changeStatus(i){
			let t = this;
			if (i.checked) {
				i.status = 0;
				i.checked = false;
			} else {
				i.status = 1;
				i.checked = true;
			}
			let d = t.$store.getters['core/getOpenModal'].data;
			window.master.put(t.apis.editItemComun(), {
				org_id: window.localorgdata,
				t: d.opts,
				data: [i],
			})
			.then((response) => {
				console.log(response.data);
				for (let j in t.listMain) {
					if(t.listMain[j].id === i.id) {
						t.listMain[j] = i;
					}
				}
				this.refreshSaved();
			})
			.catch((error) => {
				console.log(error);
			});
		},
		checkMove() {
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			t.listMain.map((item, index) => {
				item.orderItem = index + 1;
			});
			window.master.put(t.apis.editItemComun(), {
				org_id: window.localorgdata,
				t: d.opts,
				data: t.listMain,
			})
			.then((response) => {
				console.log(response.data);
				this.refreshSaved();
			})
			.catch((error) => {
				console.log(error);
			});
		},
	}, 
	computed: {
		...mapGetters("core", { getOpenModal: "getOpenModal" }),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},
}
</script>
<style lang="scss" scoped>
.btnmove {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #057121;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 10px;
    padding-left: 5px;
    background-color: #04b653;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Off";
    padding-right: 10px;
    background-color: #eeeeee;
    color: #222;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #ffffff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 33px;
    border: 1px solid #057121;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
.item-edit {
	width: calc(100% - 15px);
	border:0px solid transparent;
	box-shadow: none;
	background-color: transparent;
}
</style>
