<template>
	<div :class="['card printTask', ($parent.showPrintTask)? 'show' : 'none']">
		<div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h3 class="card-title">Task <span class="badge badge-success" v-if="item!=null">#{{item.idx}}</span></h3>
                </div>
                <div class="col-5 text-right">
                    <button v-if="!editTask" type="button" class="btn btn-ligth" @click="$parent.printTaskPdf(item)">
                        PDF
                    </button>
                    <button v-if="!editTask" type="button" class="btn btn-ligth" @click="editTask=true">
                        Edit
                    </button>
                    <button v-if="editTask" type="button" class="btn btn-ligth" @click="editTask=false">
                        Save Content
                    </button>
                </div>
                <button @click="$parent.modalPop" class="closeIcon"><span></span></button>
            </div>
        </div>
		<div :class="['card-body filesAttach', editTask ? 'edit' : 'print']" v-if="item!=null">
            <table  v-if="editTask" class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-gray">Task name:</th>
                    </tr>
                    <tr>
                        <th>
                            <textarea
                                class="name_input"
                                v-model="item.task_name"
                                @change="changeInput('task_name', item)"
                                @keydown="prevenirSaltosDeLinea"
                                maxlength="200"
                                :placeholder="(item.task_name!=null && item.task_name.length>0) ? 'Unnamed task' :'Task name'"
                            ></textarea>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="bg-gray" >Actions List:</td>
                    </tr>
                </tbody>
                <draggable
                    v-model="item.actions"
                    tag="tbody"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMove(item, item.actions)"
                    handle=".handle"
                >
                    <template  v-for="(i, index) in item.actions">
                    <tr :key="index" :style="(i.action_status_id==3) ? 'display:none;' : ''">
                        <td>
                            <button
                                type="button"
                                class="moveAction handle"
                            >
                                <i class="bar-vertical"></i>	
                            </button>
                            <div class="row">
                                <div class="form-group  col-md-9">
                                    <textarea
                                        class="name_input"
                                        v-model="i.accion_name"
                                        @change="inputActions(item, i)"
                                        @keydown="prevenirSaltosDeLinea"
                                        maxlength="200"
                                        :placeholder="(i.accion_name!=null && i.accion_name.length>0) ? 'Unnamed action' :'Action name'"
                                    ></textarea>
                                </div>
                                <div class="form-group  col-md-3">
                                    <select
                                        v-model="i.action_status_id"
                                        :class="['form-control', 'setStatus'+i.action_status_id]"
                                        @change="inputActions(item, i)"
                                    >
                                        <option :value="0" :selected="(i.action_status_id==0) ? 'true' : 'false'">Pending</option>
                                        <option :value="2" :selected="(i.action_status_id==2) ? 'true' : 'false'">Done</option>
                                        <option :value="3" :selected="(i.action_status_id==3) ? 'true' : 'false'">Delete</option>
                                    </select>
                                </div>
                                <div class="form-group  col-md-12 ">
                                    <vue-editor
                                    v-model="i.accion_descrip"
                                    :editor-toolbar="customToolbar"
                                    @blur="inputActions(item, i)"
                                    useCustomImageHandler
                                    @image-added="handleImageAdded"
                                    class="descripcion"
                                    placeholder="Describe the procedure to follow to enforce this action."
                                    ></vue-editor>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </template>
                </draggable>
                <tfoot>
                    <tr>
                        <td :class="showForm ? 'bg-gray' : ''">
                            <form @submit.prevent="saveNewAction">
                                <div class="row" v-if="showForm">
                                    <div class="form-group  col-md-9">
                                        <textarea
                                            class="name_input"
                                            v-model="f.accion_name"
                                            @keydown="prevenirSaltosDeLinea"
                                            maxlength="200"
                                            placeholder="New Action"
                                        ></textarea>
                                    </div>
                                    <div class="form-group  col-md-3">
                                        <select
                                            v-model="f.action_status_id"
                                            class="form-control"
                                        >
                                            <option :value="0">Pending</option>
                                            <option :value="2">Done</option>
                                        </select>
                                    </div>
                                    <div class="form-group  col-md-12 ">
                                        <vue-editor
                                        v-model="f.accion_descrip"
                                        :editor-toolbar="customToolbar"
                                        useCustomImageHandler
                                        @image-added="handleImageAdded"
                                        class="descripcion"
                                        placeholder="Describe the procedure to follow to enforce this action."
                                        ></vue-editor>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-info" v-if="!showForm" @click="addAction">New Action [+]</button>
                                <div class="row" v-if="showForm">
                                    <div class="col-md-12" style="margin-top: 20px;">
                                        <button type="submit" class="btn btn-success">Save</button>
                                        <button 
                                            type="button"
                                            class="btn btn-light" 
                                            @click="cancel()"
                                            style="border:1 px solid #ddd; margin-left: 10px; background-color: #fff;"
                                        >Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div  v-else class="row contentPreview" :style="bgDocumtent">
                <div class="col-12">
                    <img :src="logo" alt="logo" v-if="Showlogo" class="logoOrg" />
                </div>
                <div class="col-12 text-center">
                    <h1 class="name_task">{{ item.task_name }} </h1>
                </div>
                <div class="col-12">
                    <ol>
                        <template  v-for="(i, index) in item.actions">
                            <li :key="index" :style="(i.action_status_id==3) ? 'display:none;' : ''">
                                <h4><b>{{ i.accion_name }}</b></h4>
                                <div class="previewHTML" v-html="i.accion_descrip"></div>
                            </li>
                        </template>
                    </ol>
                </div>
            </div>
		</div>
	</div>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import draggable from 'vuedraggable';
import './filesAttach.scss';
export default {
    name: "printTask",
    components: {
        VueEditor,
        draggable,
    },
    data(){
        return {
            item: null,
            isLoading: false,
            f : {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: 0,
                id:0,
                item_order:0,
                actions: [],
            },
            showForm: false,
            editTask: false,
            optionsTime: {hour12: true, hour: "2-digit", minute: "2-digit"},
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "image"],
                ["clean"] // remove formatting button
            ], 
            bgDocumtent: 'background-image: url('+window.urlbilling+'images/backgrounds/fondo1.jpg);',
            bg: null,
            bg_custom: null,
            fileBg: null,
            Showlogo: false,
            logo: "",
            validBg: false,
            ShowBg: false,
            validBgOld: false,
            bgOld: false,
            preview: "",
            color1: "rgba(189,223,239,0.20)",
            oldColor1: "",
            getcolor1: "rgba(189,223,239,0.20)",
            color2: "rgba(252,19,19,0.07)",
            oldColor2: "rgba(252,19,19,0.07)",
            getcolor2: "rgba(252,19,19,0.07)",
            bgAgua: "none",
            opacity: "0.05",
            position: "60",
            angulo: 45,
            watermark: 0,
            cssDegraded: "",
            cssMagua: "",
            tipo_fondo: 1,
        }
    },
    created(){ 
    },
    methods: {
        init(){
            let t = this;
            t.editTask= false;
            t.item = t.$parent.itemSelected;
            console.log('printTask', t.item);
            t.getActionsByTaskId();
            t.getLogo();
        },
        getActionsByTaskId(){
            let t = this;
            t.isLoading=true;
            window.billing
            .post("getActionsTask", {
                task_id: t.item.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
            })
            .then((response) => {
                t.item.actions = response.data.r;
                t.isLoading=false;
                console.log('item', this.item);
            })
            .catch((error) => {
                console.log(error);
                t.isLoading=false;
            });
        },
        addAction(){
            let t = this;
            t.f.task_id = this.item.id;
            t.f.item_order = this.item.actions.length;
            t.showForm=true;
        },
        inputActions(item, i){
            let t = this;
            t.changeItemAction(item, i);
        },
        saveNewAction(){
            let t = this;
            var url = 'setActionsTask';
            if(t.f.id==0 && t.f.accion_name!=null && t.f.accion_name.length>0){
                console.log(url);
                window.billing
                .post(url, t.f)
                .then((response) => {
                    console.log(response.data);
                    t.cancel();
                    t.item.actions.push(response.data.r)
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        cancel(){
            this.showForm=false;
            this.f={
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: 0,
                id:0,
                item_order:0,
                action_status_id:0,
                accion_name: null,
                accion_descrip:null,
                date_action:null,
            };
        },    
        changeInput(campo, i){
            let t = this;
            if(campo=='task_name'){
                t.saveChangeInput(campo, i);
            }
            else{
                //console.log('change', campo, i[campo])
                t.saveChangeInput(campo, i);    
            }
        },
        saveChangeInput(campo, i){
            let t = this;
            console.log('change', campo, i[campo])
            window.billing
            .post("updateTask", {
                id: i.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: campo,
                valor: i[campo],
            })
            .then((response) => {
                console.log('updateTask');
                localStorage.removeItem('task_name');
            })
            .catch((error) => {
                console.log(error)
            });
        },
        changeItemAction(itemTask, itemAction){
            let t = this;  
            window.billing
            .put("updateActionTask", {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: itemTask.id,
                id: itemAction.id,
                accion_name: itemAction.accion_name,
                accion_descrip: itemAction.accion_descrip,
                date_action: itemAction.date_action,
                action_status_id: itemAction.action_status_id
            })
            .then((response) => {
                console.log('updateActionTask', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        prevenirSaltosDeLinea(evento) {
            if (evento.keyCode === 13) {
                evento.preventDefault();
            }
        },
        isDragging(t){ console.log('move'); return t; },
        checkMove: function(item, actions){
            actions = actions.filter((r, idx) => {
                r.item_order = idx;
                return r;
            });
            console.log('checkMove', actions);
            window.billing
            .put('orderActionsTask', {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: item.id,
                itemActions: actions,                		
            })
            .then((response) => {
                console.log('orderActionsTask');
            })
            .catch((error) => {
                console.log(error);
            });
        },        
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            let t = this;
            var setImage = new FormData();
            setImage.append("user_id", t.f.user_idd);
            setImage.append("org_id", t.f.org_id);
            setImage.append("folder", 'so_task');
            setImage.append("img1", file, file.name);
            setImage.append("nombreIMG", "img");
            setImage.append("task_id", t.f.task_id);
            setImage.append("contIMG", 1);
            setImage.append("delIMG", "imgOld");
            setImage.append("contDel", 0);
            window.masterGetBilling
            .post("subirIMG", setImage)
            .then(result => {
                let url = window.urlmaster+'images/no-image.png'; // imagen x defaul
                let name_image = '';
                if(result.data.imgs["1"]){
                   url = window.urlmaster + result.data.rooPath.slice(1) + result.data.imgs["1"]; 
                   name_image = result.data.imgs["1"];
                   Editor.insertEmbed(cursorLocation, "image", url);
                   resetUploader();
                }else{
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: "Image upload failed, please try again.",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
        },        
        getLogo() {
            let t= this;
            window.master
            .get("bg/logo/" + window.localuserdata + "/" + +window.master_client)
            .then((response) => {
                console.log(response.data);
                t.Showlogo = response.data.Showlogo;
                t.logo = response.data.logo;
                t.bgAgua = "url";
                t.bgAgua += "(";
                t.bgAgua += response.data.logo;
                t.bgAgua += ")";
                console.log("bg/logo/", t.bgAgua);
                //t.getBgs();
            })
            .catch((error) => {
                console.log(error);
            });
        },        
        getBgs() {
            let t= this;
            window.billing
            .get("bg/gebgs/" +window.localuserdata +"/" +localStorage.getItem("folder_user"))
            .then((response) => {
                let r = response.data;
                if (r.status) {
                    for (let i in r.result) {
                        if (t.formIvo.modulo == r.result[i].modulo) {
                            t.color1 = r.result[i].color1;
                            t.color2 = r.result[i].color2;
                            t.opacity = r.result[i].opacity;
                            t.position = r.result[i].position;
                            t.angulo = r.result[i].angle;
                            t.watermark = r.result[i].watermark;
                            t.tipo_fondo = r.result[i].type;
                            console.log("valor de BG = ", r.result[i].bg);
                            if (r.result[i].bg != null) {
                                if (t.watermark == 1) {
                                    t.bg = r.result[i].bg + "-w";
                                } else {
                                    t.bg = r.result[i].bg + "-b";
                                }
                            }
                            if (r.result[i].bg_custom != null) {
                                t.ShowBg = true;
                            }
                        }
                    }
                }
                t.rutaLocal = r.rutaM;
            })
            .catch((error) => {
                t.isLoading = false;
                console.log(error);
            });
        },
    },
    watch: {
        "$parent.showPrintTask": function(){
			let t = this;
			if(t.$parent.showPrintTask){
                t.init();
            }else{
                t.item=null;
            }
        }
    },
    computed: { 
        dragOptions() {
            return {
                animation: 200,
                group: "actions",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.printTask{
        position: fixed;
        top: 40px;
        max-width: 1000px;
        width: 100%;
        min-height: 100px;
        height: calc(100% - 45px);
        display: block;
        overflow: hidden;
        margin: auto;
        background: #fff;
        color: #000;
        filter: drop-shadow(0 3px 5px #ccc);
        border-radius: 4px;
        z-index: 999;
        right: 0px;
        transition: all 0.5s;
        &.show{
            transform: translateX(0px);
        }
        &.none{
            transform: translateX(1000px);
        }
        .card-body {
        height: calc(100vh - 110px);
        overflow-x:hidden;
        overflow-y:auto;
        }
        .closeIcon {
            position: absolute;
            right: 5px;
            top: 8px;
            border: 0;
            background-color: transparent;
            height: 30px;
            width: 30px;
            cursor: pointer;
            z-index: 99;
        }
    }
    .name_input{
        border: 0px;
        background-color: #fff;
        width: 100%;
        line-height: 1.1;
        height: 50px;
        color:#555;
        resize: none;
        overflow: hidden;
        border: 1px solid #ccc;border-radius: 4px;
        &:focus{
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
            color:#555;
            font-weight: normal;
        }
    }
    .bg-gray{
        background-color: #ddd;
        font-weight: bold;
        font-size: 18px;
    }
    .form-group{
        margin-bottom: 0px;
    }
    table tbody tr td {
        position: relative;
        
		.moveAction{
			padding: 1px 10px 1px 5px;
			font-size: 14px;
			border-radius: 2px;
    		border-top-left-radius: 2px;
    		border-bottom-left-radius: 2px;
			position: absolute;
			left: -18px;
			top: 2px;
			height: 95%;
			z-index: 1;
			margin-right: 15px;
			box-shadow: none;
			opacity: 0.2;
			color: #555;
			border:0px;
			box-shadow: none;
			background-color: transparent;
            &:hover {
				opacity: 0.8;
				cursor: move;
				background-color: #b4d6ff;
				border-color: #137eff;
			}
			i.bar-vertical{
				display: block;
				position: relative;
				border: 2px solid #555;
				border-top: 0px solid #555;
				border-bottom: 0px solid #555;
				width: 100%;
				height: 100%;
				padding: 0px 1.5px;
				&:after{	
					content: "";
					position: absolute;
					z-index: 1;
					top:0px;
					right: -6px;
					width: 100%;
					height: 100%;
					display: block;
					border-left: 2px solid #555;
					border-right: 0px;
					border-top: 0px;
					border-bottom: 0px;
				}
			}
		}
    }
    .task_timer{
        display: flex;
        width: 44%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .daystimer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .days-left{
            display: inline-flex;
            margin-right: 10px;
            font-weight: bold;
            font-size: 12px;
        }
        .days-before{
            display: inline-flex;
            font-weight: bold;
            font-size: 12px;
        }
        .on_time{color:#555;}
        .time_running_out{color: #d38019;}
        .today, .overdue{color:#e2445c;}
        .completed{color:#1010d8;}
        .label-time{
            color: #d38019;
            margin-left: 5px;
            &.expired{color: #e2445c;}
            &.closed{color: #1010d8;}
        }
        .date{
            font-size: 12px;
            color: #555;
            border-radius: 4px;
            width: 87px;
            padding: 2px 5px;
            margin-top: 5px;
            span{font-weight: bold;}
        }
    }    
    .btn-ligth{margin-right: 10px;background-color:#fff;border-color:#ccc;}    
    legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}
.name_task{color: #1010d8;}
.contentPreview{
    width: 100%;
    max-width: 900px;
    margin: auto;
    padding: 30px 15px 10px 15px;
    box-shadow: -1px 0px 4px #aaa;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    ol li h4{
        color: #005fbc;
    }
    p{margin-bottom: 0px;}
    img.logoOrg {
        width: 200px;
        margin-bottom: 40px;
    }
}
</style>
