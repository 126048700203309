export default {
    OrgAccountAll (context) {
        return new Promise((resolve, reject) => {
            let url = 'organizationall'
            // llamando datos del state de core/session
            const getUserType = context.rootState.core.session.user_type
            if (getUserType === 2) { url = 'orgallsecundary' }
            window.master.get(url + '/' + window.localAccountID)
                .then(response => {
                    context.commit('SET_ORG_All', response.data.result)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    ActiveOrg (context, id) { context.commit('SELECT_ORG_ACTIVE', id) },
    ActiveOrgdataByUser (context, orgId) {
        return new Promise((resolve, reject) => {
            if (orgId > 0) {
                window.master.post('/selectOrgByUser', {
                    org_id: orgId, 
                    oauth: localStorage.getItem('oauth_m'),
                })
                .then(response => {
                        if(response.data.status){
                            localStorage.setItem('mark_client_data', response.data.org.id);
                            context.commit('SET_ORGINFO', response.data.org);
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    })
                    .catch(error => {
                        resolve(false);
                        console.log(error)
                    })
            }else{resolve(false);}
        })
    },
    ActiveOrgdata (context, idorg) {
        if (idorg > 0) {
            window.master.get('orginfoactive/' + idorg)
                .then(response => {
                    context.commit('SET_ORGINFO', response.data.result)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    changeOrgPrimary (context, orgId) {
        return new Promise((resolve, reject) => {
            if (orgId > 0) {
                window.master.put('changeprimaryorg/' + window.master_client, {
                    user_id: window.master_client,
                    org_id: orgId,
                    org_id_old: context.state.varChangeOrg.org_id_old
                }).then((response) => {
                    if (response.data.status) {
                        resolve(true)
                        location.reload(true)
                    } else {
                        resolve(false)
                    }
                }).catch((error) => {
                    console.log(error)
                    resolve(false)
                })
            } else {
                resolve(false)
            }
        })
    },
    PlanPremium (context,) {
        return new Promise((resolve, reject) => {
            window.master.post('setPlan', {
                user_id: window.master_client,
                org_id: window.localorgdata,
                planes: 2,
                client: true,
            }).then((response) => {
                if (response.data.status) {
                    if(localStorage.getItem('getDataSession'))
                    {
                        let r = JSON.parse(localStorage.getItem('getDataSession'));
                        r.plan=response.data.r.plan_id;
                        context.commit('setPlanId', r.plan);
                        window.OrgPlan = response.data.r.plan_id;
                        localStorage.setItem('getDataSession', JSON.stringify(r));
                        console.log('actualizando sesion');
                        resolve(true);
                    }else{
                        console.log('error al actualizar session');
                        resolve(false);
                    }
                } else {
                    console.log('fallo setPlan status');
                    resolve(false);
                }
            }).catch((error) => {
                console.log('setPlan', error)
                resolve(false);
            });
        })
    }
}
