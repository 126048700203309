<template>
    <div>
        <div class="card">
            <div class="card-header">
                {{ modal.title }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" v-if="modalError">
                    {{ modalErrorMjs }}
                </div>
                <form @submit.prevent="saveVendor" v-if="modal.vendor">
                    <div class="form-group">
                        <label for="inputCity">Company Name</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="addVendor.company_name"
                            required
                        />
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="submit" class="btn btn-success">
                                <i class="fas fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "formExpensesVendor",
    data () {
        return {    
            modalError: false,
            modalErrorMjs: "",
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            addVendor: { company_name: null },         
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;   
            this.addVendor.company_name = r.addVendor
            this.modal.title = r.modal.title
            this.modal.vendor = r.modal.vendor
            this.modal.account = r.modal.account 
            this.modal.banking = r.modal.banking
            this.modal.bankingTransac = r.modal.bankingTransac
            this.modal.writeCheck = r.modal.writeCheck         
        },
        saveVendor() {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post("expenses/vendor/add", {
                    user_id: window.localuserdata,
                    company_name: this.addVendor.company_name,
                })
                .then((response) => {
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Vendor Created successfully",
                    });

                    this.formExp.Vendor = response.data.id;
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'});
                    this.$store.commit("core/setActionRefresh", {
                        action: 'saved', 
                        data: {
                            methods: 'vendor',
                            value: t.formExp.Vendor,
                            text: t.addVendor.company_name
                        }
                    });
                    this.addVendor.company_name = "";
                    this.modal.isLoading = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.$store.commit("core/setLoadingModal", false);
                });
        },                            
    },
    watch: {
    },
    computed: {},
};
</script>
