<template>
    <div class="container_food_list">
        <section class="food_list food_seccion" v-for="(i, idx) in listMain" :key="idx" :id="'section-'+i.id" :data-row="i.id">
            <!-- <h2 class="food_item_Category" :data-indicador="i.id" >{{ i.name_cat }}</h2> -->
            <article class="food_item_container" v-for="(f, index) in i.foods" :key="index" :data-indicador="i.id">
                <div class="food_item" :data-indicador="i.id">
                    <header class="food_item_header" :data-indicador="i.id">
                        <h4 class="food_item_name" :data-indicador="i.id">{{f.name_food}}</h4>
                        <slider-media :indicador="i.id" :gallery="f.gallery" :urlMedia="urlMedia" :foodItem="f" :currency="getCurrency"></slider-media>
                    </header>
                    <div class="food_item_details" v-html="f.desc_food" :data-indicador="i.id"></div>
                </div>
            </article>
        </section>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import foodSlider from "./foodSlider.vue"
export default {
    props: ['urlMedia', 'listCat', 'menuCatActivo', 'getCurrency'],
    components: {
        'slider-media': foodSlider,
    },
    data(){
        return {
            apis: null,
            coordenadas: null,
            currentCoord: null,
            scrollingValue: 0,
            coordIndex: 0,
            listMain: [],
            playVideo: false,
            loadSave: false,
            details: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia provident eum iusto! Eveniet, reprehenderit quod. Eaque unde fuga dicta necessitatibus! Corrupti odio tempore totam ratione nulla eius velit provident porro',
        };
    },
    created() {
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
        t.listMain = t.listCat;
    },
    watch: {
        listCat: function(newVal, oldVal){
            let t = this;
            t.updateList(newVal);
        },
    },
    mounted(){
        let t = this;
        if(document.querySelector('.container_food_list')){
            if(document.querySelectorAll('.food_seccion').length>0){
                t.startEvents();
            }
        }
    },
    methods: {
        callbackScrolling(scrollTop){
            let t = this;
            let changeSection = false;
            let direction = 0;
            if(t.coordenadas.length>2){
                if(scrollTop==0){
                    console.log('scrollTop initial', scrollTop)
                    console.log('currentCoord', t.coordenadas[t.coordIndex])
                    let categoryActive = t.listMain.find(i => { return (i.id == t.currentCoord.id)});
                    t.$emit('selectCat', categoryActive);
                    setTimeout(() => { t.$emit('followItem');}, 100);
                    t.$emit('sectionActive', t.currentCoord.elemtId)
                }else{
                    if(t.scrollingValue < scrollTop){
                        changeSection=(scrollTop> t.currentCoord.height); // -(t.currentCoord.top+300)
                        direction=1;
                    }else{
                        // changeSection=t.coordenadas.find(i => { return (scrollTop <= i.height && i.height <= t.currentCoord.height)})
                        changeSection=(scrollTop < t.currentCoord.height);
                        direction=-1;
                    }
                    t.scrollingValue=scrollTop;
                    if(changeSection){
                        if(direction>0){
                            t.coordIndex++;
                            // console.log('scrollTop ++', scrollTop, t.currentCoord.bottom); // bajando
                        }else if(direction<0){
                            t.coordIndex--;
                            // console.log('scrollTop --', scrollTop, t.currentCoord.top);// subiendo
                        }else{t.coordIndex=0;}
                        if(t.coordIndex<0){t.coordIndex=0;}
                        if(t.coordenadas[t.coordIndex].height !=t.currentCoord.height){
                            // console.log('section activa', t.coordenadas[t.coordIndex].elemtId)
                            // console.log('currentCoord', t.coordenadas[t.coordIndex])
                            t.currentCoord = t.coordenadas[t.coordIndex];
                            changeSection=false;
                            let categoryActive = t.listMain.find(i => { return (i.id == t.currentCoord.id)});
                            t.$emit('selectCat', categoryActive);
                            setTimeout(() => {
                                t.$emit('followItem')
                            }, 100);
                            t.$emit('sectionActive', t.currentCoord.elemtId)
                        }
                    }
                }
            }
        },
        sectActive(idx){
            let t = this;
            if(t.menuCatActivo.id!=idx){
                let categoryActive = t.listMain.find(i => { return (i.id == idx)});
                if(categoryActive){
                    // console.log('change section', idx);
                    t.$emit('sectionActive', 'section-'+idx)
                    t.$emit('selectCat', categoryActive);
                    setTimeout(() => {
                        t.$emit('followItem')
                    }, 100);
                }
            }
        },
        async updateList(list){
            let t = this;
            t.listMain = list;
            await this.sleep(800);
            t.startEvents();
        },
        async startEvents(){
            let t= this;
            t.resetEvent();
            await this.sleep(1000);
            console.log('startEvents')
            const food_list = document.querySelector('.container_food_list');
            const secciones = document.querySelectorAll('.food_seccion');
            t.coordenadas = [];
            let cont = 0;
            let contBottom = 0;
            // console.log('secciones', secciones);
            secciones.forEach(section => {
                let rect= section.getBoundingClientRect();
                let setItem = {height: (cont + section.offsetHeight), elemtId: section.id, top: rect.top, bottom: (rect.bottom + rect.top), id: section.getAttribute('data-row')}
                t.coordenadas.push(setItem);
                cont += section.offsetHeight
                contBottom+= rect.bottom;
                let sSelectores =document.querySelectorAll('[data-indicador="'+section.getAttribute('data-row')+'"]');
                if(sSelectores.length>0){
                    sSelectores.forEach(s => {
                        s.addEventListener('mouseover', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                            t.sectActive(idx)
                        });
                        s.addEventListener('touchstart', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                            t.sectActive(idx)
                        });
                        s.addEventListener('click', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                            t.sectActive(idx)
                        });
                    })
                }
            });
            t.currentCoord=t.coordenadas.find(r => {return r.id==t.menuCatActivo.id})
            // t.handleVideoPlayback();
            const itemVideos = document.querySelectorAll('.food_item_video');
            itemVideos.forEach(iVideo => {
                iVideo.addEventListener('click', (e) => {
                    console.log('click gallery', e.target.scrollLeft)
                    t.playPauseVideo(e.target)
                });
                iVideo.addEventListener('touchstart', (e) => {
                    console.log('touchstart gallery', e.target.scrollLeft)
                    t.playPauseVideo(e.target)
                });
            });
        },
        resetEvent(){
            console.log('resetEvent')
            const food_list = document.querySelector('.container_food_list');
            food_list.removeEventListener('scroll', (e) => {
                console.log('scrollTop target', e.target)
            }, true);
            const secciones = document.querySelectorAll('.food_seccion');
            secciones.forEach(section => {
                let sSelectores =document.querySelectorAll('[data-indicador="'+section.getAttribute('data-row')+'"]');
                if(sSelectores.length>0){
                    sSelectores.forEach(s => {
                        s.removeEventListener('mouseover', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                        }, true);
                        s.removeEventListener('touchstart', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                        }, true);
                        s.removeEventListener('click', (e) => {
                            let idx = e.target.getAttribute('data-indicador');
                        }, true);
                    })
                }
            });
            const itemVideos = document.querySelectorAll('.food_item_video');
            itemVideos.forEach(iVideo => {
                iVideo.removeEventListener('click', (e) => {
                    // console.log('click gallery', e.target)
                }, true);
                iVideo.removeEventListener('touchstart', (e) => {
                    // console.log('touchstart gallery', e.target)
                }, true);
            });
        },
        isElementInViewport(el) {
            let t = this;
            return new Promise(resolve => {
                const rect = el.getBoundingClientRect();
                let result= t.sleep(2000).then(r => {
                    console.log(`Waiting 2 seconds...`);
                    return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                });
                resolve(result);
            });
        },
        // Función para reproducir o detener el video según su visibilidad
        handleVideoPlayback() {
            let t = this;
            const videos = document.querySelectorAll('.food_item_video');
            videos.forEach((video) => {
                // console.log('video', video);
                t.playPauseVideo(video)
            });
        },
        playPauseVideo(video){
            let t = this;
            // Verifica si el video está en el viewport
            t.playVideo = !t.playVideo;
            t.isElementInViewport(video).then(r => {
                if (r) {
                    // console.log('video.paused', video.paused)
                    // console.log('video.playing', video.playing)
                    // console.log('video.playsInline', video.playsInline)
                    if (video.paused && t.playVideo) {
                        console.log('play');
                        video.play(); // solo funciona si el video esta en muted
                    }
                } else {
                    if (!video.paused) {
                        console.log('pause');
                        video.pause();
                    }
                }
            });
        },
        sleep(ms) {
            console.log('sleep', ms);
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
