export default {
    apps_modulos(context, app_id){
      return new Promise((resolve, reject) => {
        if(app_id == 0){ reject(false); }
        else
        {
          // @ts-ignore
          window.master.get('appmodulos/'+app_id)
          .then(response => {
            //console.log('modulos de app_id = '+app_id)
            //console.log('modulos = ', response.data.result)
            context.commit('set_app_modulos', response.data.result);
            resolve(true);
          })
          .catch(error => {
            console.log(error);
            reject(false);
          });
        }
      });
    },
    ListAppsbyOrg(context, idorg){
      return new Promise((resolve, reject) => {
        if(idorg > 0)
        {
          let url ='getapp/'+idorg;
          // @ts-ignore
          if (window.user_type > 0) { url ='listappsusersec/'+window.localAccountID+'/'+idorg; }
          console.log('ruta de app = '+url)
          // @ts-ignore
          window.master.get(url)
          .then(response => {
            console.log(url, response.data)
            resolve(response.data.r); 
            context.commit('SET_APPLIST', response.data.r);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
        }else{reject(false);}
      });
    },
    ListAppsForOrgOfSecondary(context, idorg){
      return new Promise((resolve, reject) => {
        if(idorg > 0)
        {
          // @ts-ignore
          window.master.get('getappsecondary/'+idorg)
          .then(response => {
            //console.log('ListAppsbyOrg',response.data)
            resolve(response.data.r);
            //context.commit('SET_APPLIST', response.data.r );
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
        }else{reject(false);}
      });
    },
    SET_APP_ID(context, app_id){
      return new Promise((resolve, reject) => {
        if(app_id == 0){ reject(false); }
        else
        {
          // @ts-ignore
          window.master.post('setActivityLogApp', {
              app_id: app_id,
              user_id: localStorage.getItem('mark_client_data_m'),
              org_id: localStorage.getItem('mark_client_data'),
              typeUser: localStorage.getItem('user_type'),
              oauth: localStorage.getItem('oauth_m'),
          })
          .then(response => {
            //console.log('SET_APP_ID');
            localStorage.setItem('appIDactual', app_id);
            context.commit('SetAppIDactual');
            if(app_id == 4){ 
              // si es jobs reiniciamos valor inicial de tags default
              context.rootState.ModApps.jobs.seeTagsDefault= false;
            }
            resolve(true); 
          })
          .catch(error => {
            console.log(error);
            resolve(false);
          });
        }
      });
    },
    accessApp(context, App){
      return new Promise((resolve, reject) => {
        if(App.id == 0) { reject(false); }
        else
        {
          let a = localStorage.getItem('access_token_m');
          let o = localStorage.getItem('oauth_m');
          let u = localStorage.getItem('mark_client_data_m');
          let org = localStorage.getItem('mark_client_data');
          //console.log('accediendo a la app '+App.id,App.i);
          let validApp = false;
          let nomApp = '';
          let appsorg = context.getters.applistorgs;
          if(appsorg.length > 0)
          {
            for (let j in appsorg) {
              if(appsorg[j].id==App.id && appsorg[j].privilegies){
                validApp=true;
                nomApp = appsorg[j].name;
              }
            }
          }
          if(validApp)
          {
            localStorage.setItem('menu_top_active', App.id);
            localStorage.setItem('applive', App.id);
            //console.log('app= '+nomApp, 'iD= '+App.id);
            if(App.id==1){ nomApp= 'Billing';}
            if(App.id==6){ nomApp= 'CarsDashboard';}
            context.dispatch('apps_modulos', App.id).then(response => {
              context.dispatch('SET_APP_ID', App.id).then(response => {
                resolve(nomApp); 
              });
            });
          }
          else
          {
            // @ts-ignore
            window.master.get('getappid/'+org+'/'+App.id)
            .then(response => {
              let r = response.data.r;
              localStorage.setItem('menu_top_active', App.id);
              localStorage.setItem('applive', App.id);
              nomApp = r.name;
              //console.log('app= '+nomApp, 'iD= '+App.id);
              if(App.id==1){ nomApp= 'Billing';}
              if(App.id==6){ nomApp= 'CarsDashboard';}
              context.dispatch('apps_modulos', App.id).then(response => {
                context.dispatch('SET_APP_ID', App.id).then(response => {
                  resolve(nomApp); 
                });
              });
            })
            .catch(error => {
              console.log(error);
            });
          }
        }
      });
    },
    validAccess(context) {
      return new Promise((resolve, reject) => {
        let validApp = false;
        let appsorg = context.getters.applistorgs;
        let getAppIDactual = context.getters.getAppIDactual;
        if(appsorg.length > 0)
        {
          for (let j in appsorg) {
            if(appsorg[j].id==getAppIDactual && appsorg[j].privilegies){
              validApp=true;
            }
          }
        }
        resolve(validApp);
      });
    },
    exit_Apps(context) {
      return new Promise((resolve, reject) => {
        const access = {
          login: false,
          token: false,
        };
        console.log('mandar a cerrar sesiones de apps');
        /*context.commit('retrieveTokenBilling', access);
        context.commit('retrieveTokenProject', access);
        context.commit('retrieveTokenCalculator', access);
        let a = localStorage;
        for(var i in a){
        if(a.hasOwnProperty(i))
          console.log(i, a[i]);
          localStorage.removeItem(i, a[i]);
        }*/
        resolve(true);
      });
    },
	linkApp(context, app) {
    let index = app.index;
    let app_id = app.app_id;
    // console.log('app', app);
    // console.log('appAll', context.rootState.core.apps.appAll[index].privilegies);
    let AppIdActual = localStorage.getItem('appIDactual');
    let result = { status: false };
    return new Promise((resolve, reject) => {
      if (AppIdActual !== app_id)
      {
        let a ={ i: index, id: app_id};
        context.dispatch('accessApp', a).then(response => {
          if(response) {
            console.log('redireccion = '+ response);
            result.status = true;
            result.nomApp = response;
            localStorage.setItem('appIDactual', app_id);
            context.commit('SetAppIDactual');
          }
          else {
            console.log('redireccion fallida '+ response);
          }
          resolve(result);
        });
      }
      else {
        console.log('llamando misma ruta  ', AppIdActual, app_id);
        resolve(result);
      }
    });
	},
  setPlanApp (context, app_id) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
        window.master.post('setPlanApp', {
          // @ts-ignore
          user_id: window.master_client,
          // @ts-ignore
          org_id: window.localorgdata,
          app_id: app_id,
          planes: 2,
          client: true,
        }).then((response) => {
            if (response.data.status) {
              console.log('actualizando planIdApp', response.data);
                let r = response.data;
                context.commit('setPlanIdApp', r.plan_id);
                resolve(true);
            } else {
                console.log('fallo setPlanApp');
                resolve(false);
            }
        }).catch((error) => {
            console.log('setPlanApp', error)
            resolve(false);
        });
    });
  },
  getPlanApp (context, app_id) {
    return new Promise((resolve, reject) => {
      app_id = (app_id!=undefined) ? app_id : localStorage.getItem('appIDactual');
      // @ts-ignore
        window.master.post('getPlanApp', {
          // @ts-ignore
          user_id: window.master_client,
          // @ts-ignore
          org_id: window.localorgdata,
          app_id: app_id,
        }).then((response) => {
          // console.log('actualizando planIdApp', response.data);
          let r = response.data;
          context.commit('setPlanIdApp', r.plan_id);
          context.commit('setModulesMenu', r.modulesMenu);
          resolve(true);
        }).catch((error) => {
            console.log('setPlanApp', error)
            resolve(false);
        });
    });
  },
  getAppStatus(context, appId){
    return new Promise((resolve, reject) => {
      let status= {
        paymentStatus: null, // statusPay=0 es unpaid, statusPay=1 es  paid, statusPay=2 es cancelled (ya no se usa), statusPay=3 es  declined
        appStatus: null, //assingStatus=0 app inactive, statusApp=3 app cancelled, statusApp=2 app suspended, statusApp=1 app active
      };
      context.getters.applistorgs.filter(a => {
        if(a.id == appId){
          status.paymentStatus = a.statusPay
          status.appStatus = (a.assingStatus==0 || a.statusApp==3) ? -1 : ((a.statusApp==2) ? 0 : 1);
        }
      });
      // status.appStatus== -1 es app inactiva
      // status.appStatus== 0 es app bloqueo
      // status.appStatus== 1 es app activa
      // status.paymentStatus== statusPay
      // console.log('getAppStatus', status)
      resolve(status)
    });
  },
}
