<template>
	<div class="card">
		<div class="card-header">
			<h5 class="card-title">Assign Organizations </h5>
		</div>
		<div class="card-body">
			<div v-if="isLoading"><Loader></Loader></div>
			<form v-else @submit.prevent="addAssignOrg">
				<div class="row" v-if="orgsDisponibles.length > 0">
					<div class="col-9">
						<label for="inputEmail4">Organization Name:</label>
							<select id="inputOrgs" class="form-control" v-model="modal.org_id">
								<option :value="i.id" v-for="i in orgsDisponibles" :key="i.id">{{i.bill_company_name}}</option>
							</select>
					</div>
					<div class="col-3">
						<label for="inputEmail4" style="display: block;"><span style="color:#5198d4">.</span></label>
						<input class="btn btn-success" type="submit" value="SAVE">
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-12 text-center">
						<div class="alert alert-danger">
							<strong>No record.</strong><br>
							There are no organizations available to assign, I may have already reached the maximum number of organizations that I could install or simply already have them all in this account
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import { mapGetters } from 'vuex'
export default {
	name: 'AssingOrgs',
		components: {
		Loader,
	},
	// props:{
	// 	accountID:{type: Number, required:true},
	// 	orgID:{type: Number, required:true}
	// },
	data () {
		return {
			isLoading: false,
			user_id: window.master_client,
			org_id: window.localorgdata,  
			orgsDisponibles:[],  
			OrgAssignList:[],   
			modal :{
				user_id: window.master_client,
				org_id: window.localorgdata,  				
				user_id_secondary : 0,
			},	
			accountID: 0,
			orgID: 0,
		}
	},
	created(){
		this.init();
	},
	methods: {
		init() {
			let g = this.$store.getters['core/getOpenModal'];
			this.accountID = g.data.account_id;
			this.orgID = g.data.orgid;
			this.modal.user_id_secondary = this.accountID;
			this.getUsersById();
		},
		addAssignOrg(){
			this.$store.commit('core/setLoadingModal', true);
			window.master.post('user/assignorg', {
				user_id: parseInt(this.modal.user_id),
				org_id: parseInt(this.modal.org_id),
				user_id_secondary : parseInt(this.modal.user_id_secondary)
			})
			.then(response => {
				this.$store.commit('core/setLoadingModal', false);
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '1', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Update',
					text: 'Successfully assigned organization.',
					duration: 50000
				});
				this.$store.commit("core/setCloseModal", true);
				this.$store.commit('core/session/SET_RETURN_USER', true);
			})
			.catch(error => {
				this.$store.commit('core/setLoadingModal', false);
				console.log(error)
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Error',
					text: 'the organization could not be assigned.',
					duration: 10000
				});
				this.$store.commit("core/setCloseModal", true);
			});			
		},		
		getUsersById(){
			this.isLoading= true;
			window.master.get('user/'+this.user_id+'/'+this.accountID)
			.then(response => {
				let listOrgs = response.data.orgList;
				//console.log(' listOrgs =',listOrgs);
				this.OrgAssignList = listOrgs;
		    	this.validarOrgDisponibles();
		    })
		    .catch(error => {
		    	console.log(error);
		    	this.isLoading= false;
		    });
		},	
		validarOrgDisponibles(){
			this.orgsDisponibles= [];
			let allOrgs = this.$store.getters['core/Org/orgAll']; // llamar todas las org desde la session
			//console.log('all ', allOrgs);
			let listOrgs = this.OrgAssignList;// org ya asignadas 
			if(listOrgs.length > 0){// si el usuario tiene org asignadas las elimino de la lista principal
				for (let i in allOrgs) {
					// valida q tenga la misma cantidad de registros o mas 
					let id = allOrgs[i].id;
					if(listOrgs.length >= i) 
					{
						for (let e in listOrgs) 
						{
							//console.log('e ', e, listOrgs[e]);
							//console.log('comparando ', listOrgs[e].org_id, ' con  ', allOrgs[i].id);
							if (listOrgs[e].org_id == allOrgs[i].id) { 
								// comparo si existe coincidencias, si existe lo borro de la lista 
								//console.log('excluir', allOrgs[i].id)
								id =0;
							}
						}
					}
					if(id > 0){
						//console.log('agregando ',allOrgs[i].id);
						this.orgsDisponibles.push(allOrgs[i]);
					}
				}
			}
			else{
				this.orgsDisponibles = allOrgs;
			}
			//console.log('orgsDisponibles', this.orgsDisponibles)
			if(this.orgsDisponibles.length > 0){this.modal.org_id=this.orgsDisponibles[0].id;}
			this.isLoading= false;
		},		
	},
	watch:{},
	computed: {}
};
</script>
<style lang="scss" scoped>
	#inputOrgs{border:1px solid #555;}
</style>
