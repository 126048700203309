let delTask = function(id, i) {
        let t = this;
        t.jobBox = i
        // this.$swal({
        //     title: "Delete Task?",
        //     text: "Are you sure?",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     confirmButtonText: "Yes, Delete it!",
        // }).then((result) => {
        //     if (result.value) {
                let url = this.apis.delTask()
                window.master
                    .post(url, {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        id_jobs: this.jobBox.id_i,
                        id: id,
                    })
                    .then((response) => {
                        let r = response.data;
                        if (r.status) {
                            for (let i in t.jobBox.taks) {
                                if (t.jobBox.taks[i]) {
                                    if (t.jobBox.taks[i].id === id) {
                                        t.jobBox.taks.splice(i, 1);
                                    }
                                }
                            }
                            this.$store.commit("ModApps/jobs/set_refresh_grid", true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        //     }
        // });
};
export default delTask;
