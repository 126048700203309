<template>
    <div class="template_container">
        <div class="cover_container">
            <div class="cover_picture" :style="$parent.isBackgroundCover($parent.item)">
                <div class="cover_video" v-if="$parent.item.pictures[0].cover_v_active">
                    <video id="cover_video_player" loop muted="muted" playsinline="true" autoplay :key="'video-'+$parent.item.id"
                    :style="$parent.positionVideo($parent.item)">
                        <source :src="$parent.srcCoverVideo($parent.item)" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                <div class="cover_picture_before"
                    v-if="$parent.item.pictures[0].cover_active"
                    :style="'background-color: rgba(0 0 0 / '+$parent.item.pictures[0].cover_overlay+'%);'"
                ></div>
                <div class="edit edit-cover t4" @click="$parent.$emit('setModal', 'editCover')"><i class="fas fa-pen"></i> Cover</div>
                <div class="schedule hours24Open" v-if="$parent.hours24Open">
                    <div class="edit" @click="$parent.$emit('setModal', 'editSchedule')"><i class="fas fa-pen"></i></div>
                    <span class="schedule_oppening open">Open 24 hours</span>
                </div>
                <div class="schedule" v-else-if="$parent.ifExistSchedule!=null">
                    <div class="edit" @click="$parent.$emit('setModal', 'editSchedule')"><i class="fas fa-pen"></i></div>
                    <span class="schedule_oppening open" v-if="$parent.ifExistSchedule.open_status==1">Open</span>
                    <span class="schedule_oppening closed" v-else>Closed</span>
                    <span class="schedule_closing_time" v-if="$parent.ifExistSchedule.closed==0">
                        <span v-if="$parent.ifExistSchedule.open_status==0">Opens</span>
                        <span v-else>Closes</span>
                        <span v-if="$parent.ifExistSchedule.open_status==0">
                            {{ $parent.ifExistSchedule.opens_at }}
                            <span v-if="$parent.ifExistSchedule.opens_me==1">am</span>
                            <span v-else>pm</span>
                        </span>
                        <span v-else>
                            {{ $parent.ifExistSchedule.closes_at }}
                            <span v-if="$parent.ifExistSchedule.closes_me==1">am</span>
                            <span v-else>pm</span>
                        </span>
                    </span>
                </div>
                <div class="author-info">
                    <div class="edit edit-info" @click="$parent.$emit('editOrgInfo', 'orgUrl')"><i class="fas fa-pen"></i></div>
                    <h2 class="company">{{$parent.orginf.com_company_name}}</h2>
                    <p class="company_address">{{ $parent.orginf.bill_address }} {{ $parent.orginf.bill_city }}, {{ $parent.orginf.bill_state }} {{ $parent.orginf.bill_zip_code}}</p>
                </div>
                <div :class="['contact-iconos', 'edit-contact']" @click.prevent="$parent.editContact()">
                    <div class="box-icon" v-for="(c, cIdx) in $parent.item.find_me_at" :key="c.id" v-show="c.status==1">
                        <div :class="['button-icon hvr-sweep-to-bottom','bg'+(parseInt(cIdx)+1)]">
                            <a href="#" @click.prevent="$parent.linkVoid()" :class="'btn btn-sbg '" >
                                <i :class="c.icon"></i>
                                <p>{{$parent.helperLabel(c)}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bMenuWidget">
            <!-- <div class="menuTab">
                <ul>
                    <li v-for="(i, idx) in navTab" :key="idx">
                        <a href="#" :class="['', (navTabActive==i.id) ? 'active' : '']" @click.prevent="changeNavTab(i)">{{ i.name }}</a>
                    </li>
                </ul>
            </div> -->
            <div class="content-menu">
                <widget-bMenu modeDark="0" :class="['bmenu', (navTabActive==1) ? 'visible' : 'novisible']"></widget-bMenu>
                <div :class="['moreContact', (navTabActive==2) ? 'visible' : 'novisible']">
                    <div class="map_location">
                        <div class="logo_org">
                            <div class="profile">
                                <div class="profile_picture" :style="$parent.isProfile($parent.item)" >
                                    <i class="far fa-image" v-if="$parent.isProfile($parent.item)==''"></i>
                                    <!-- <img v-else :src="$parent.isLogoProfile($parent.item)[0]" :style="$parent.isLogoProfile($parent.item)[1]" class="org_logo_profile" alt="logo"/> -->
                                    <Loader v-if="$parent.profileLoad" class="profileLoad"></Loader>
                                    <div class="edit edit-profile" @click="$parent.editPicProfile()"><i class="fas fa-pen"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="location">
                            <div class="edit edit-info" @click="$parent.$emit('setModal', 'editMapIframe')"><i class="fas fa-pen"></i></div>
                            <iframe :src="$parent.mapLocation"
                                width="100%"
                                height="100%"
                                style="border:0;"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import widget_bMenu from "@/apps/Marketea/components/minisite/site_desing/plugins/bMenu/widget_bMenu.vue";
export default {
    name: "htmlTemplate5",
    components: {
        'widget-bMenu': widget_bMenu,
    },
    data(){
        return {
            navTabActive: null,
            navTab: [
                {id: 1, name: 'Menu', comp: 'bMenuWidget'},
                {id: 2, name: 'More Contact', comp: 'moreContact'},
            ],
            nameSection: null,
        };
    },
    created() {
        this.changeNavTab(this.navTab[0]);
    },
    methods: {
        changeNavTab(i){
            let t = this;
            t.navTabActive = i.id;
            t.nameSection = i.comp;
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('../scss/efectsThemes.scss');
@import url('../scss/baseTemplate.scss');
@import url('../scss/bmenu.scss');
.visible{display: block !important;}
.novisible{display: none !important;}
</style>
