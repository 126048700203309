import { render, staticRenderFns } from "./formExpensesBankingTransac.vue?vue&type=template&id=268b6728&scoped=true&"
import script from "./formExpensesBankingTransac.vue?vue&type=script&lang=js&"
export * from "./formExpensesBankingTransac.vue?vue&type=script&lang=js&"
import style0 from "./formExpensesBankingTransac.vue?vue&type=style&index=0&id=268b6728&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268b6728",
  null
  
)

export default component.exports