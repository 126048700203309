<template>
    <div class="container">
        <div class="card-header">Change Password</div>
        <div class="card-body">
            <div class="card">
                <div class="card-body">
					<form @submit.prevent="changePass">
						<div class="row">
							<div class="col-12">
								<label for="">Password</label>
								<div class="passsecurity">
									<password v-model="f.newPassword" placeholder="********"/>
								</div>
							</div>
							<div class="col-12">
								<label for="">Confirm</label>
								<input type="password" class="form-control"  v-model="f.rePassword" placeholder="********">
							</div>                          
							<div class="col-12">
								<label for="inputEmail4" style="display: block;margin-top: 23px;"><span style="color:#5198d4;"></span></label>
								<button class="btn btn-success" style="margin-right: 10px;" type="submit">SAVE</button> 
							</div>              
						</div>
					</form>
                </div>
            </div>
        </div>		
	</div>
</template>
<script>
import password from 'vue-password-strength-meter'
export default {
	name: 'passAccount',
	components: {
		password,
	},	
	data () {
		return {
			f: {
				newPassword:'',
				rePassword:'',
			}, 
			user_id:0,			
		}
	},
	created(){
        let data = this.$store.getters['core/getOpenModal'].data;
        console.log('Change Password', data);
		this.user_id = data.userID;
	},
    methods: {
		changePass(){
			if(this.f.newPassword !== this.f.rePassword) {
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'Password and Confirm, they must be the same.',
					duration: 10000
				});
			} else if (this.f.newPassword.length < 6) {
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'the password must have more than 6 characters.',
					duration: 10000
				});
			} else {
				this.$store.commit('core/setLoadingModal', true);
				window.reselleR2.post('/changePassAccount', {
					id : this.user_id,
					new_password: this.f.newPassword,
					new_confirm_password: this.f.rePassword,
				})
				.then((response) => {
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '1', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Update',
						text: 'Password Successful.',
						duration: 3000
					});
					this.$store.commit("core/setCloseModal", true);
					this.f.newPassword = '';
					this.f.rePassword = ''; 
				})
				.catch((error) => {
					console.log(error);
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit("core/setCloseModal", true);
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '3', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Error',
						text: 'updating data.',
						duration: 10000
					});
				}); 
			}
		},		
    }
}
</script>
<style lang="css" scoped>
</style>
