// sites
import SitesLayout from '@/apps/Sites/Layouts/SitesLayout.vue'
import SitesHome from '@/apps/Sites/views/SitesHome.vue'
import SitesBiulder from '@/apps/Sites/views/SitesBiulder.vue'
import SitesThemes from '@/apps/Sites/views/SitesThemes.vue'
export default [
	{
		path: '/Sites',
		folder: SitesLayout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'Sites',
				folder: SitesHome
			},
			{
				path: 'biulder',
				name:'biulder',
				folder: SitesBiulder
			},
			{
				path: 'biulder/edit/:webid',
				name:'editor',
				folder: SitesBiulder
			},
			{
				path: 'theme-select/:webid',
				name:'themeSelect',
				folder: SitesThemes
			},
		]
	}
];
