<template>
	<div style="display:flex;">
		<router-link :to="{name:'HomeReseller'}" >
			<img class="logo" alt="Marketea" src="@/core/assets/img/isotipo-M-white.svg">
		</router-link>
		<!-- <button
                class="navbar-toggler"
                type="button"
                id="activeMenuLateral"
                @click="ShowHiddenAside"
            >
                <i class="fas fa-bars"></i>
        </button> -->
        <ul class="menuTop">
        	<li
                class="sidebar-item"
                v-for="(i, index) in menuDefaul"
                :key="index"
                v-bind:class="{ sub: i.type === 2 }"
            >
                <router-link
                    :to="i.ruta"
                    exact-active-class="active"
                    class="sidebar-link waves-effect waves-dark"
                    v-if="i.type == 1"
                >
                    <i
                        :class="i.icon"
                        style="font-size: 15px"
                    ></i>
                    <span class="hide-menu">{{ i.name }}</span>
                </router-link>
            </li>
        </ul>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		name: 'menuAppsR',
		components: {
		},
		data () {
			return {
				isLoading: false,
				show: false,
				right: true,
				hover: false,
				activar: false, // abrir lateral				
	            menuDefaul: [
	                {
	                    name: "Dash.",
	                    ruta: { name: "HomeReseller" },
	                    icon: "fas fa-lg fa-home",
	                    m_id: 0,
	                    type: 1,
	                },
	                {
	                    name: "Org.",
	                    ruta: { name: "OrgbyReseller" },
	                    icon: "fas fa-lg fa-building",
	                    m_id: 1,
	                    type: 1,
	                },
	                {
	                    name: "Accounts",
	                    ruta: { name: "UsersbyReseller" },
	                    icon: "fas fa-lg fa-users",
	                    m_id: 2,
	                    type: 1,
	                },
	                {
	                    name: "Smart Office",
	                    ruta: { name: "HomeReseller" },
	                    icon: "fas fa-lg fa-file-invoice-dollar",
	                    m_id: 2,
	                    type: 1,
	                },
	                {
	                    name: "CRM",
	                    ruta: { name: "HomeReseller" },
	                    icon: "fas fa-lg fa-chart-line",
	                    m_id: 2,
	                    type: 1,
	                },
	            ],
			}
		},
		created(){
			this.ShowHiddenAside();
		},
		methods: {
	        ShowHiddenAside() {
	            this.activar = !this.activar;
	            this.$store.commit("core/reseller/ActiveLateral", this.activar);
	        },
		},
		watch: {
	        lateral: function () {
	            this.activar = this.lateral;
	        },
		},
		computed: {
        	...mapGetters("core/reseller", ["lateral"]),
		}
	};
</script>
<style lang="css" scoped>
	#activeMenuLateral {
	    display: block;
	    margin: 5px;
	    margin-left: 10px;
	    border: 1px solid #fff;
	    padding: 8px 10px;
	    color: #fff;
	}
	ul.menuTop li {
    	padding: 15px;
	}
	ul.menuTop li a {
	    color: #fff;
	    font-size: 14px;
	}

	ul.menuTop li a i {
	    margin-right: 10px;
	}
</style>
