const variables = {
	freePlan: false,
	showSMS: 1,
	JobsInt: 1,
	callbackUrl: window.urlmaster,
	m: {
		show: false,
		bill: false,
		component: '',
		data: []
	},            
	isLoading: false,
	showModal: false,
	SettingBox: "",
	InvoicesList: [],
	DataMail: {
		isLoading: false,
		idinv: null, // numdoc
		IDitem: null, 
		email: null,
		EmailCheck: false,
		view: "invoice",
	},
	emails: [],
	form: {
		id: null,
		numdoc: null,
		user_id: window.localuserdata,
		customer_id: null,
		invoice_id: "",
		fecha: new Date().toISOString().slice(0, 10),
		method_id: "",
		ref: null,
		price: null,
		memo: null,
		send_invoice: false,
		send_img: false,
		img1: null,
		img2: null,
		imgOld1: null,
		imgOld2: null,
		status: 1,
		ope: "create",
		vatError: false,
		vatErrorMsg: null,
	},
	editar: false,
	validimg1: false,
	validimg2: false,
	validimgOld1: false,
	validimgOld2: false,
	Showimg1: false,
	Showimg2: false,
	preview1: "",
	preview2: "",
	isDisabledPrice: true,
	isDisabled: true,
	PaymentMethodsList: [],
	totalinvoice: "",
	previewtotal: null,
	previewbalance: null,
	previewcustomername: null,
	previewcustomerlastname: null,
	previewcustomerbusinessname: null,
	inputFilter: "",
	fieldsFilter: ["numdoc", "customer", "bussinesname", "invoicename", "total"],
	fieldsList: [
		{ value: "numdoc", name: "Inv#" },
		{ value: "customer", name: "Customer Name" },
		{ value: "bussinesname", name: "Customer Bussines" },
		{ value: "invoicename", name: "Invoice name" },
		{ value: "total", name: "Total" },
	],
	fieldsdropd: false,
	OldInvoicesList: [],
	invStatus: 0,
	isFilter: false,
	isCheckAll: true,
	statusdata: [
	{ value: "1", name: "Pending" },
	{ value: "2", name: "Partially Paid" },
	{ value: "3", name: "Paid" },
	{ value: "4", name: "Overdue" },
	{ value: "5", name: "Void" },
	],
	statuslist: [1, 2, 3, 4, 5],
	statusdropd: false,
	Actionsdropd: [],
	ActionsSelected: 0,
	paginador: 0,
	offset: 4,
	page: 1,
	pagination: {
		current_page: 1,
	},
	content: false,
	InvoicesListResult: [],
	query: "",
	dateFrom: new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString(),
	dateTo: new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString(),
	duedateFrom: new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString(),
	duedateTo: new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString(),
	searchDate: false,
	invoIdPusher: 0,
	initCols: [
		{value: '3', name:'Due Date'},
		{value: '4', name:'Customer'},
		{value: '5', name:'Name'},
		{value: '7', name:'Tax'},
		{value: '8', name:'Balance'},
		{value: '10', name:'Rec'},
		{value: '11', name:'Email'},
		{value: '12', name:'Job'},
	],
	colsAll: [
		{id: '1', name: 'Inv #', class: 'numID', },
		{id: '2', name: 'Date', class: 'date', },
		{id: '3', name: 'Due Date', class: 'date-due', },
		{id: '4', name: 'Customer', class: 'customer', },
		{id: '5', name: 'Name', class: 'name', },
		{id: '6', name: 'Total', class: 'total', },
		{id: '7', name: 'Tax', class: 'tax', },
		{id: '8', name: 'Balance', class: 'balance', },
		{id: '9', name: 'Status', class: 'status', },
		{id: '10', name: 'Rec', class: 'rec', },
		{id: '11', name: 'Email', class: 'email', },
		{id: '12', name: 'Job', class: 'jobs', },
		{id: '13', name: '', class: 'actions', },
	],
	cols: ['1', '2', '4', '5', '6', '8', '9', '11'], // '3', '7', '10', '12'
	colsDrop: false,	
	selectAll: false,
	selected: [],
	itemSelected: {id: 0},
	opAddi: [
		{value: '1', name:'Open PDF for Envelope'},
		{value: '2', name:'Statement'},
		{value: '3', name:'Duplicate'},
		{value: '4', name:'Convert to Recurring'},
	],
	valOpAddi: ['2', '3'],
    show: false,
    right: true,
    hover: false,    
	rowNum: 50,
	rowList: [5, 10, 20, 50, 100, 200],
	numPag: [4],
	validAccountSquare: false,
	validEprint: false,
	accessJobsApp: false,
	colFoot: 11,
	lastFnActionJobs: 0,
	accountSMS: 0,
	PlanPremium : (window.OrgPlan > 1),
	modalBill: [
		'NotesAdmin',
		'modalEmail',
		'formPayment',
		'formFilterFechas',
		'formSquarepay',
	],
	tablaInvoKey: 0,
};
export default variables;
