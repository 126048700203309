<template>
    <div class="container-fluid myCards">
        <navTopbar></navTopbar>
        <div class="body_card">
            <div class="row" v-if="isLoading">
                <div class="col-12 text-center">
                    <Loader></Loader>
                </div>
            </div>
            <div class="row" :style="(isLoading) ? 'display:none;' : ''">
                <div class="col-12 ">
                    <list-mini-site
                    ></list-mini-site>
                    <!-- <preview-card
                        v-model="refreshPusher"
                        @hidePreview="clearItemSelected($event)"
                        @setModal="openModal($event)"
                    ></preview-card> -->
                </div>
            </div><!-- modal popup-->
            <!-- <modal name="modal-editProfile" class="modal-editProfile" draggable=".window-header" height="auto" @before-close="beforeClosedModal">
                <div class="card">
                    <button @click="ClosedModal" class="closeIcon"><span></span></button>
                    <component :is="nameComp"></component>
                </div>
            </modal> -->
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import navTopbar from "@/apps/Marketea/components/bSite/navTopbar.vue";
// @ts-ignore
import listminisite from "@/apps/Marketea/components/minisite/listminisite.vue";
import './index.scss';
export default {
    name: "mini_site",
    components: {
        Loader,
        navTopbar,
        'list-mini-site': listminisite,
    },
    data () {
        return {
            isLoading: false,
        }
    },
    created(){
    },
    watch: {
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined && t.getPusher != null) {
                if(t.getPusher.modulo!=null && t.getPusher.modulo == "bsite"){
                    let p = t.getPusher.data;
                    if(p.org_id == window.localorgdata){
                        // leer acciones
                        t.pusherActions(p);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            getPusher: "get_accitonPusher",
        }),
    },
    methods: {
        pusherActions(p){
            console.log('pusherActions', p)
        },
    }
}
</script>
