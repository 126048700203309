<template>
    <div>
        <div class="card-header">{{ card_title }}</div>
        <form @submit.prevent="sendMail" v-if="showCompanyinfo == false">
            <div class="card-body">
                <div
                    class="alert alert-warning"
                    role="alert"
                    v-if="formsend.bill_email_verify == 0"
                    style="padding: 5px"
                >
                    Your Organization’s EMAIL wasn’t verifed. Go to Setting to Verify it
                    now.
                    <b
                        ><router-link :to="{ name: 'SettingCompanyInfo' }">
                            Click Here</router-link
                        ></b
                    >
                </div>
                <h4 class="card-title">
                    Send Record
                    <b
                        ><span class="badge badge-info">{{ items.IDitem }} </span></b
                    >
                </h4>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        v-model="formsend.emailcustomercheck"
                        :disabled="items.email == null ? true : false"
                    />
                    <label class="custom-control-label" for="customCheck1"
                        >Will be sent to the Client:
                        <b>
                            <span v-if="items.email">{{ items.email }}</span
                            ><span style="color: red" v-else
                                >This client DOESN'T have email</span
                            >
                        </b></label
                    >
                </div>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        v-model="formsend.emailcompanycheck"
                    />
                    <label class="custom-control-label" for="customCheck2"
                        >Send me a copy: <b> {{ formsend.emailcompany }}</b></label
                    >
                </div>
                <div class="form-inline" style="padding-top: 15px; padding-bottom: 15px">
                    <div class="form-group mb-2 col-sm-2" style="padding-left: 0px;">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="othermail"
                                v-model="formsend.othercheck"
                            />
                            <label for="othermail" class="custom-control-label"
                                >Other :</label
                            >
                        </div>
                    </div>
                    <div class="form-group col-sm-3 mb-2" style="padding-left: 0px;">
                        <label for="inputPassword2" class="sr-only">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            style="display: block;width: 100%;"
                            placeholder="Name"
                            v-model="formsend.nameother"
                        />
                    </div>
                    <div class="form-group col-sm-6 mb-2" style="padding-left: 0px;">
                        <label for="inputPassword2" class="sr-only">Email</label>
                        <input
                            type="text"
                            class="form-control"
                            style="display: block;width: 100%;"
                            placeholder="Email"
                            v-model="formsend.emailother"
                        />
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div
                    class="d-flex justify-content-end"
                    v-if="formsend.bill_email_verify == 1"
                >
                    <button
                        type="submit"
                        class="btn btn-success d-flex"
                        :disabled="isDisabled"
                    >
                        <div class="ldsnew" v-if="miniloader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <i
                            class="fas fa-paper-plane"
                            style="margin-right: 5px; margin-top: 3px"
                        ></i>
                        SEND
                    </button>
                </div>
                <div class="d-flex justify-content-end" v-else>
                    <button type="button" class="btn btn-success d-flex" disabled>
                        <i
                            class="fas fa-paper-plane"
                            style="margin-right: 5px; margin-top: 3px"
                        ></i>
                        SEND
                    </button>
                </div>
            </div>
            <div v-if="companyinfo" class="avisoCompany">
                <div class="alert alert-warning">
                    <span class="badge badge-warning">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    Company Info is required to send messages. Please fill out your
                    business name. <br />
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    components: {
    },
    name: "sendEmailEst",
    data() {
        return {
            isLoading: false,
            miniloader: false,
            formsend: {
                DataItem: null,
                emailcompany: null,
                emailcompanycheck: false,
                email1eprintcheck: false,
                email2eprintcheck: false,
                nameother: null,
                emailother: null,
                emailcustomercheck: false,
                othercheck: false,
                email1eprint: null,
                email2eprint: null,
                bill_email_verify: 1,
            },
            emails: [],
            companyinfo: false,
            showCompanyinfo: false,
            company_name: "",
            card_title: "SEND EMAIL",
            isDisabled: false,
            idSale: 0,
            items: {
                idinv: 0,
                IDitem: 0,
                email: null,
                validEprint: false,
                view: 'estimate',
                isLoading: false,
            },
            getModal: null
        };
    },
    created() {
		this.init();
    },
    methods: {
		init(){
			let t = this;
            t.$store.commit("core/setLoadingModal", true);
			t.getModal = t.$store.getters['core/getOpenModal'];
            t.idSale=t.getModal.data.idSale;
            t.getCompanyInfo();
		},
        getCompanyInfo() {
			let t = this;
            t.isLoading = true;
            window.billingSales
                .get("companyinfo/" + window.localuserdata)
                .then((response) => {
                    console.log("info company info");
                    console.log(response.data[0]);
                    let e = response.data[0];
                    t.formsend.emailcompany = e.bill_email;
                    t.formsend.bill_email_verify = e.bill_email_verify;
                    if (e.com_company_name == null || e.com_company_name == "") {
                        t.companyinfo = true;
                    } else {
                        t.companyinfo = false;
                    }
                    t.getEstimateSimple();
                })
                .catch((error) => {
                    console.log(error);
                    t.$store.commit("core/setLoadingModal", false);
                });
        },
		getEstimateSimple(){
            let t = this;
			window.billingSales.post('estimateSimple', {
				org_id: window.localorgdata,
				user_id: window.master_client,
				id: t.getModal.data.estimate,
			})
			.then((response) => {
				console.log('estimateSimple', response.data);
				if(response.data.status){
                    t.items.idinv = response.data.estimate.id;
                    t.items.IDitem = response.data.estimate.numdoc;
                    t.items.email = response.data.customer.email;
                    t.items.validEprint = false;
                    t.items.view = "estimate";
                    t.items.isLoading=false;
                    t.formsend.DataItem= t.items;
				}
                t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
        sendMail() {
            let t = this;
            let fEventBus = "estimate-email";
            let url = "estimatemailsend";
            t.$store.commit("core/setLoadingModal", true);
            console.log(t.formsend);
            console.log(t.formsend.DataItem.idinv);
            let valido = true;
            if(t.formsend.othercheck && t.formsend.emailother != null && t.formsend.emailother.length > 0)
            {
                let emailother = t.formsend.emailother;
                let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                //Se muestra un texto a modo de ejemplo, luego va a ser un icono
                if (!emailRegex.test(emailother)) 
                {
                    t.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                        title: "Email Other: ",
                        text: "Incorrect email",
                        show: true,
                        duration: 10000
                    });
                    valido = false;
                    t.isDisabled = false;
                    t.miniloader = false;
                }
            }
            if(valido)
            {
                t.isLoading = true;
                t.isDisabled = true;
                window.billingSales
                .post(url + "/" + t.formsend.DataItem.idinv + "/" + window.oauth_m, {
                    user_id: window.localuserdata,
                    emailcustomercheck: t.formsend.emailcustomercheck,
                    emailcompany: t.formsend.emailcompany,
                    nameother: t.formsend.nameother,
                    emailother: t.formsend.emailother,
                    email1eprint: t.formsend.email1eprint,
                    email2eprint: t.formsend.email2eprint,
                    emailcompanycheck: t.formsend.emailcompanycheck,
                    othercheck: t.formsend.othercheck,
                    email1eprintcheck: t.formsend.email1eprintcheck,
                    email2eprintcheck: t.formsend.email2eprintcheck,
                })
                .then((response) => {
                    t.isLoading = false;
                    t.miniloader = false;
                    t.formsend.DataItem.send = 1;
                    t.formsend.fEventBus = fEventBus;
                	t.$store.commit("core/setLoadingModal", false);
                    t.$store.commit("core/setCloseModal", {action: 'sendEmailEst', 'idSale': t.idSale});
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                    t.isDisabled = false;
                    t.miniloader = false;
                    this.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Send Mail!",
                    });
                	t.$store.commit("core/setLoadingModal", false);
                });
            }
        },
    },
    watch: {
        "formsend.emailcompanycheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.emailcustomercheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.othercheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.email1eprintcheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.email2eprintcheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        companyinfo: function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
    },
    computed: {},
};
</script>

<style lang="css" scoped>
.avisoCompany {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 80px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
