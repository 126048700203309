<template>
	<div class="card">
		<div class="card-header">
			<h3 class="card-header">Project</h3>
		</div>
		<div class="card-body" style="padding-top:2px">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<a :class="['nav-link', (tabActive==1) ? 'active' : '']" @click.prevent="tabActive=1" href="#">HomeOwner Details</a>
				</li>
				<li class="nav-item">
					<a :class="['nav-link', (tabActive==2) ? 'active' : '']" @click.prevent="tabActive=2" href="#">Insurance Info</a>
				</li>
			</ul>
			<form @submit.prevent="saveSales" v-show="tabActive==1">
				<table class="table table-bordered" style="border:0px">
					<thead>
						<tr>
							<td colspan="4" class="text-right">
								<button type="submit" class="btn btn-success">Save</button>
								<button type="button" class="btn btn-light" @click="close()" style="margin-left: 10px; border:1px solid #ccc;">Cancel</button>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>First Name:</td>
							<td><input type="text" class="form-control" required v-model="f.name"></td>
							<td>Last Name:</td>
							<td><input type="text" class="form-control" v-model="f.lastname"></td>
						</tr>
						<tr>
							<td>Phone:</td>
							<td><input type="text" class="form-control" required v-model="f.phone"></td>
							<td>Email:</td>
							<td><input type="text" class="form-control" v-model="f.email"></td>
						</tr>
						<tr>
							<td>Address:</td>
							<td><input type="text" class="form-control" v-model="f.address"></td>
							<td>City:</td>
							<td><input type="text" class="form-control" v-model="f.city"></td>
						</tr>
						<tr>
							<td>State:</td>
							<td><input type="text" class="form-control" v-model="f.state"></td>
							<td>Zip Code:</td>
							<td><input type="text" class="form-control" v-model="f.zip"></td>
						</tr>
						<tr>
							<td>Title:</td>
							<td colspan="3">
								<input type="text" class="form-control" required v-model="f.title">
							</td>
						</tr>
						<tr>
							<td>Description:</td>
							<td colspan="3">
								<textarea v-model="f.descip" class="form-control lineas-notes" style="height: 50vh;"></textarea>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
			<form @submit.prevent="saveContact" v-show="tabActive==2">
				<table class="table table-bordered" style="border:0px">
					<thead>
						<tr>
							<td colspan="4" class="text-right">
								<button type="submit" class="btn btn-success">Save</button>
								<button type="button" class="btn btn-light" @click="close()" style="margin-left: 10px; border:1px solid #ccc;">Cancel</button>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colspan="3">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Insurance Company *</div>
									<input type="text" class="form-control" v-model="c.insurance_co" required placeholder="" maxlength="20">
								</div>
							</td>
							<td width="200px">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Phone #</div>
									<input type="text" class="form-control" v-model="c.phone" placeholder="" maxlength="20">
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Claim Number:</div>
									<input type="text" class="form-control" v-model="c.claim" placeholder="" maxlength="20">
								</div>
							</td>
							<td width="200px">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Policy #</div>
									<input type="text" class="form-control" v-model="c.policy" placeholder="" maxlength="20">
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="4"><br></td>
						</tr>
						<tr>
							<td colspan="4" style="border-bottom: 1px dashed #eee;"></td>
						</tr>
						<tr>
							<td colspan="4"><br></td>
						</tr>
						<tr>
							<td colspan="4">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Contact:</div>
									<input type="text" class="form-control" v-model="c.contact" placeholder="" maxlength="20">
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="4">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Email:</div>
									<input type="text" class="form-control" v-model="c.email" placeholder="" maxlength="20">
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Phone:</div>
									<input type="text" class="form-control" v-model="c.phonecontact" placeholder="" maxlength="20">
								</div>
							</td>
							<td colspan="2">
								<div class="input-group d-flex align-items-center label_input_inline" >
									<div class="input-group-prepend" style="padding-left: 5px;">Extension #</div>
									<input type="text" class="form-control" v-model="c.extension" placeholder="" maxlength="20">
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="4"><br></td>
						</tr>
						<tr>
							<!-- {{ c.additional.length }}/310 -->
							<td colspan="4" style="font-size: 12px;">Additional Notes: </td>
						</tr>
						<tr>
							<td colspan="4">
								<textarea v-model="c.additional" class="form-control lineas-notes" style="height: 120px;" maxlength="310"></textarea>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	</div>
</template>
<script>
import './modalLateralCustom.scss'
export default {
name: 'profile',
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				name: '',
				lastname: '',
				phone: '',
				email: '',
				address: '',
				city: '',
				state: '',
				zip: '',
				title: '',
				descip: '',
			},
			c: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				id: 0,
				contract_id: 0,
				insurance_co: '',
				policy: '',
				claim: '',
				phone: '',
				additional: '',
				contact: '',
				email: '',
				phonecontact: '',
				extension: '',
			},
			tabActive: 1,
			validContactInfo: false,
		}
	},
	created(){
		this.init();
	},
	methods:{
		init(){
			this.$store.commit("core/setLoadingModal", false);
			this.validContactInfo = this.$store.getters['core/getOpenModal'].data.valid;
			let d = this.$store.getters['core/getOpenModal'].data.item;
			if(d.id){
				this.f= {
					user_id: window.master_client,
					org_id: window.localorgdata,
					sales_id: d.id,
					name: d.perfil.name,
					lastname: d.perfil.lastname,
					phone: d.perfil.phone,
					email: d.perfil.email,
					address: d.perfil.address,
					city: d.perfil.city,
					state: d.perfil.state,
					zip: d.perfil.zip,
					title: d.title,
					descip: d.descip,
				};
				this.c.sales_id= d.id;
				if(this.validContactInfo){this.tabActive=2;}
				if(d.contract>0){
					this.c.id=d.contract_id;
					this.c.contract_id=d.contract_id;
					this.salesGetContract()
				}
			}
		},
		salesGetContract(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('salesGetContract', t.c)
            .then((response) => {
                console.log('salesGetContract', response.data)
                if(response.data.status && response.data.r){
                    t.c=response.data.r;
					t.c.contract_id=response.data.r.id;
                }
				t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveSales(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('saveSales', t.f)
            .then((response) => {
                console.log('saveSales', response.data)
                t.$store.commit("core/setLoadingModal", false);
                t.close();
            })
            .catch((error) => {
                console.log(error);
                t.$refs.addGalley.value=null;
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveContact(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			let url ='salesSetInsurance';
			window.master.post(url, t.c)
            .then((response) => {
                console.log(url, response.data)
                t.$store.commit("core/setLoadingModal", false);
                t.close();
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		close(){
			this.f= {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				name: '',
				lastname: '',
				phone: '',
				email: '',
				address: '',
				city: '',
				state: '',
				zip: '',
				title: '',
				descip: '',
			};
			this.c= {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				id: 0,
				contract_id: 0,
				insurance_co: '',
				policy: '',
				claim: '',
				phone: '',
				additional: '',
				contact: '',
				email: '',
				phonecontact: '',
				extension: '',
			};
			this.$store.commit("core/setCloseModal", true);
			this.$parent.close();
		}
	}
}
</script>
<style lang="css" scoped>
	h3.card-header {
    	background: transparent;
    	margin-bottom: 0px;
    	padding: 0px;
	}
	.table-bordered th, .table-bordered td {
	    border: 0px solid #dee2e6;
	}
	.lineas-notes {background-image: linear-gradient(to bottom, transparent 28px, #e9ecef 28px); background-size: 100% 30px;line-height: 28px;}
	.lineas-notes:focus {background-image: linear-gradient(to bottom, transparent 29px, rgba(0, 0, 0, 0.25) 29px);	}
	.money-align{ text-align: right;}
	.table td {padding: 0.15rem 0.4rem;}
	input.placeholder::placeholder {color: #aaa; font-size: 11px;}
	label {font-size: 12px;}
	.label_input_inline{background-color: transparent; border:0px solid #e9ecef;}
	.label_input_inline .input-group-prepend{font-size: 12px;}
	.label_input_inline .form-control{border: 0px; border-bottom: 1px solid #ccc; border-radius: 0; background-color: transparent;font-size: 12px;}
</style>
