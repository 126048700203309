let closeAll = function(noUl){
	let list = document.querySelectorAll('.menuActions ul.dropdown-menu');	
	for (let j in list) {
		if (list.hasOwnProperty(j)) {
			let ul = list[j];
			if(noUl != j) {
				if(ul && ul.classList.contains('open')) {
					ul.classList.remove('open');
					// console.log('remove open');
					if(ul.classList.contains('isTop')) {
						ul.classList.remove('isTop');
					}
				}						
			}
		}
	}
};
export default closeAll;
