let addLine = function() {
    let d = this.get_todolist[0].data;
    let r = d[0].lineNew;
    let id = this.f.taks.length - 1;
    let lineNew = {
        id: id,
        name: null,
        status: 1,
        statusList: r.statusList,
    };
    this.f.taks.push(lineNew);
    let idx = this.f.taks.length - 1;
    setTimeout(function () {
        document.getElementById("taks" + idx).focus();
    }, 200);
};
export default addLine;
