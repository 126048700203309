import { render, staticRenderFns } from "./designForm.vue?vue&type=template&id=8876e62e&scoped=true&"
import script from "./designForm.vue?vue&type=script&lang=js&"
export * from "./designForm.vue?vue&type=script&lang=js&"
import style0 from "./designForm.vue?vue&type=style&index=0&id=8876e62e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8876e62e",
  null
  
)

export default component.exports