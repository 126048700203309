<template>
    <div>
        <div class="card">
            <div class="card-header">
                {{ modal.title }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" v-if="modalError">
                    {{ modalErrorMjs }}
                </div>
                <form @submit.prevent="saveBanking" v-if="modal.banking">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="control-label required col-md-4"
                                    >Select Account Type</label
                                >
                                <div class="col-md-8">
                                    <label class="radio-inline">
                                        <input
                                            name="bankorcard"
                                            type="radio"
                                            value="true"
                                            v-model="addBanking.account_type"
                                        />Bank &nbsp;&nbsp;
                                    </label>
                                    <label class="radio-inline">
                                        <input
                                            name="bankorcard"
                                            type="radio"
                                            value="false"
                                            v-model="addBanking.account_type"
                                        />Credit Card
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label required col-md-4">
                                    <span class="text-dashed-underline"
                                        >Account Name
                                    </span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        class="form-control"
                                        type="text"
                                        required
                                        v-model="addBanking.account_name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-4">
                                    <span class="text-dashed-underline">
                                        Account Code
                                    </span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        class="form-control"
                                        type="text"
                                        required
                                        v-model="addBanking.account_code"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-4"
                                    >Account Number</label
                                >
                                <div class="col-md-8">
                                    <input
                                        class="form-control"
                                        type="text"
                                        required
                                        v-model="addBanking.account_number"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-4">
                                    <span class="text-dashed-underline">
                                        Bank Name
                                    </span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        class="form-control"
                                        type="text"
                                        required
                                        v-model="addBanking.bank_name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-4">
                                    <span class="text-dashed-underline">
                                        Routing Number
                                    </span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        class="form-control"
                                        type="text"
                                        required
                                        v-model="addBanking.routing_number"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-4"
                                    >Description</label
                                >
                                <div class="col-md-8">
                                    <textarea
                                        rows="3"
                                        placeholder="Max 500 characters"
                                        class="form-control"
                                        v-model="addBanking.description"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-success" type="submit">
                                <i class="fas fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "formExpensesBanking",
    data () {
        return {     
            modalError: false,
            modalErrorMjs: "",
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            addBanking: {
                user_id: window.localuserdata,
                account_type: true,
                account_name: "",
                account_code: "",
                account_number: "",
                bank_name: "",
                routing_number: "",
                description: "",
                activo: 1,
                primary: false,
                account_type_transac:1,
            },                
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;   
            this.modal.title = r.modal.title
            this.modal.vendor = r.modal.vendor
            this.modal.account = r.modal.account 
            this.modal.banking = r.modal.banking
            this.modal.bankingTransac = r.modal.bankingTransac
            this.modal.writeCheck = r.modal.writeCheck         
        },       
        saveBanking() {
            this.$store.commit("core/setLoadingModal", true);
            this.modal.banking = false;
            window.billing
                .post("banking/add", this.addBanking)
                .then((response) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Banking Created successfully",
                    });

                    this.formExp.Banking = response.data.id;
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'});
                    this.$store.commit("core/setActionRefresh", {
                        action: 'saved', 
                        data: {
                            methods: 'baking',
                            value: t.formExp.Banking,
                            text: t.addBanking.account_name
                        }
                    });
                    this.addBanking.account_type = true;
                    this.addBanking.account_name = "";
                    this.addBanking.account_code = "";
                    this.addBanking.account_number = "";
                    this.addBanking.bank_name = "";
                    this.addBanking.routing_number = "";
                    this.addBanking.description = "";
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },                             
    },
    watch: {
    },
    computed: {},
};
</script>
