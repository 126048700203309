// import HomeAccount from '../views/HomeAccount.vue'
//Marketea
import Layout from '@/apps/Marketea/Layouts/Layout.vue'
import Dashboard from '@/apps/Marketea/views/Dashboard.vue'
import Leads from '@/apps/Marketea/views/LeadsMark.vue'
import AlertForm from '@/apps/Marketea/views/forms/AlertForm.vue'
import BuilderForms from '@/apps/Marketea/views/forms/BuilderForms.vue'
import notiForms from '@/apps/Marketea/views/forms/notiForms.vue'
import listForms from '@/apps/Marketea/views/forms/listForms.vue'
import Setup from '@/apps/Marketea/views/setup/Setup.vue'
import BuilderFormsOLD from '@/apps/Marketea/views/setup/BuilderForms_old.vue'
import OauthClient from '@/apps/Marketea/views/google/OauthClient.vue'
import Profile from '@/apps/Marketea/views/google/Profile.vue'
import Review from '@/apps/Marketea/views/google/Review.vue'
import AdsConversion from '@/apps/Marketea/views/seoTools/AdsConversion.vue'
// import Sales from '@/apps/Marketea/views/seoTools/Sales.vue'
import Sales from '@/apps/Marketea/views/sales/Retail.vue'
import sales_insurance from '@/apps/Marketea/views/sales/Insurance.vue'
import btnCallLink from '@/apps/Marketea/views/seoTools/btnCallLink.vue'
import listPages from '@/apps/Marketea/views/landingPage/listPages.vue'
import Maquetador from '@/apps/Marketea/views/landingPage/maquetador.vue'
import smartCard from '@/apps/Marketea/views/smartCard/index.vue'
import fromCard from '@/apps/Marketea/views/smartCard/formCard.vue'
import minisite from '@/apps/Marketea/views/smartCard/mini_site.vue'
import emailSignature from '@/apps/Marketea/views/smartCard/emailSignature.vue'
import bSiteSetting from '@/apps/Marketea/views/smartCard/setting.vue'
import iframePreview from '@/apps/Marketea/components/minisite/site_desing/iframePreview.vue'
import indexProjects from '@/apps/Marketea/views/plugins/projects/indexProjects.vue'
import bsite_gallery from '@/apps/Marketea/views/plugins/bsite_gallery/indexGallery.vue'
import bMenu from '@/apps/Marketea/views/plugins/bMenu/bMenu_index.vue'
import salesPreview from '@/apps/Marketea/public/views/salesPreview.vue'
import createEstGutter from '@/apps/Marketea/views/plugins/gutters/createEstGutter.vue'
import settingGutter from '@/apps/Marketea/views/plugins/gutters/settingGutter.vue'
export default [
	{
		path: '/bSite',
		folder: Layout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'bSite',
				folder: smartCard
			},
			{
				path: 'leads',
				name:'LeadsMark',
				folder: Leads
			},
			{
				path: 'Setup',
				name:'MSetup',
				folder: Setup
			},
			{
				path: 'forms/list',
				name:'listForms',
				folder: listForms
			},
			{
				path: 'forms/edit2/:id',
				name:'BuilderForms',
				folder: BuilderForms
			},
			{
				path: 'forms/noti',
				name:'notiForms',
				folder: notiForms
			},
			{
				path: 'forms/edit-alert',
				name:'AlertForm',
				folder: AlertForm
			}, 
			{
				path: 'forms/edit/:id',
				name:'BuilderFormsOLD',
				folder: BuilderFormsOLD
			},  
			{
				path: 'google/form',
				name:'OauthClient',
				folder: OauthClient
			}, 
			{
				path: 'google/profile',
				name:'googleProfile',
				folder: Profile
			}, 	
			{
				path: 'google/review',
				name:'Review',
				folder: Review
			}, 
			{
				path: 'sales',
				name:'Sales',
				folder: Sales
			}, 
			{
				path: 'sales-insurance',
				name:'sales_insurance',
				folder: sales_insurance
			},
			{
				path: 'btn-call-link',
				name:'btnCallLink',
				folder: btnCallLink
			},
			{
				path: 'landing-pages',
				name:'listPages',
				folder: listPages
			},
			{
				path: 'template-builder',
				name:'maquetador',
				folder: Maquetador
			},
			{
				path: 'template-builder/:id',
				name:'editMaquetador',
				folder: Maquetador
			},
			{
				path: 'smartCard',
				name:'smartCard',
				folder: smartCard
			},
			{
				path: 'fromCardPrimary',
				name:'fromCardPrimary',
				folder: fromCard
			},
			{
				path: 'fromCard',
				name:'fromCard',
				folder: fromCard
			},
			{
				path: 'editCard/:editId',
				name:'EdifromCard',
				folder: fromCard
			},
			{
				path: 'mini-site',
				name:'minisite',
				folder: minisite
			},
			{
				path: 'email-signature',
				name:'emailSignature',
				folder: emailSignature
			},
			{
				path: 'setting',
				name:'bSiteSetting',
				folder: bSiteSetting
			},
			{
				path: 'plugins/projects',
				name:'ProjectsAll',
				folder: indexProjects
			},
			{
				path: 'plugins/gallery',
				name:'bsite_gallery',
				folder: bsite_gallery
			},
			{
				path: 'plugins/bMenu',
				name:'bsite_bmenu',
				folder: bMenu
			},
			{
				path: 'gutters/create-estimate',
				name:'cal_gutter_est',
				folder: createEstGutter
			},
			{
				path: 'gutters/setting',
				name:'cal_gutter_setting',
				folder: settingGutter
			},
		]
	},
	{
		path: '/bSite/mini-site/preview/:miniId',
		name:'iframePreview',
		folder: iframePreview
	},
	{
		path: '/bSite/s/:salesId/:appttId',
		name:'salesPreview',
		folder: salesPreview,
		meta: { requiresVisitor: true },
	},
]
