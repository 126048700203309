export default {	
    orgAll : [],
    infoorg : null,
    formInfo : {
		user_id : window.master_client,
		id : 0,
		title : 'ADD',
		action : 'add',
		companyname : '',
		firstname : '',
		lastname : '',
		address : '',
		city : '',
		state : '',
		zipcode : '',
		telephone : '',
		email : '',
		website : '',
		timezone_id : 1,
	},
	varChangeOrg: {
		org_id: 0,
		org_id_old: 0
	},
	plan_id: 0,
}
