<template>
	<div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full" data-boxed-layout="full">
		<div class="header">
            <div class="block block1" style="height: 40px">
                <menuApps></menuApps>
            </div>
        </div>
        <div class="container ">
            <div class="row" style="margin-top:80px;">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 ">
                	<div class="alert alert-danger" role="alert">
                		<h1 class="alert-heading">Error 404!</h1>
                	  <p>The path you have selected does not exist or has recently changed, please return to the start at try again.</p>
                	  <hr>
                	  <p class="mb-0">
                	  	<button type="button" class="btn btn-info btn-sm" @click="backToHome()">
                	  		Back to Main Menu <i class="fas fa-arrow-right" style="margin-left:10px;"></i>
                	  	</button>
                	  </p>
                	</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import menuApps from "@/core/components/Header/menuApps";
export default {
  name: 'error404',
  components: {
  	menuApps
  },
  data () {
    return {
    }
  },
  created(){
  	let ruta_path = this.$router.currentRoute.path;
  	let ruta_arr = ruta_path.split('/');
  	if(ruta_arr.includes('Marketea')) {
  		this.$router.push({name : "CRM"});
  	}
  },
  methods:{
  	backToHome(){
  		this.$router.push({name : "Home"});	
  	}
  }
}
</script>
<style lang="css" scoped>
</style>
