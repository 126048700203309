<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <sheader :name="titulo"></sheader>
            </div>
            <div class="card-body">
                <div class="row" style="padding-bottom: 20px">
                    <div class="col-md-2"></div>
                    <div class="col-md-2">
                        <button class="btn btn-success" @click="ModalBox('addto', 0)">
                            <i class="fa fa-plus"></i> Add new
                        </button>
                    </div>
                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control search"
                            placeholder="Search"
                            v-model="search"
                        />
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th><b>Name</b></th>
                                    <th class="text-center" width="60px"><b>Edit</b></th>
                                    <th class="text-center" width="60px">
                                        <b>Delete</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="isLoading">
                                <tr>
                                    <td colspan="10"><Loader></Loader></td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="filterItems.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No Record</td>
                                </tr>
                            </tbody>
                            <draggable
                                v-else
                                class="tbody"
                                v-bind="dragOptions"
                                tag="tbody"
                                @start="isDragging = true"
                                @end="isDragging = false"
                                handle=".handle"
                                v-model="listTemplate"
                                @change="checkMove"
                            >
                                <tr v-for="(i, index) in filterItems" :key="index">
                                    <td>
                                        <div class="handle">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </div>
                                        {{ i.name }}
                                    </td>
                                    <td class="text-center">
                                        <button
                                            class="btn btn-default"
                                            @click="ModalBox('addto', i.id)"
                                        >
                                            <i class="fa fa-pencil-alt"></i>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            class="btn btn-danger"
                                            @click="delTemplate(i.id)"
                                        >
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                    </div>
                </div>
            </div>
        </div>
		<!-- modal -->
		<modalLateral>
			<component v-bind:is="m.component"></component>
		</modalLateral>         
    </div>
</template>
<script>
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import draggable from "vuedraggable";
import Loader from "@/core/components/shared/Loader.vue";
import sheader from "@/apps/jobs/components/setup/setupHeader.vue";
import addto from "@/apps/jobs/components/setup/add_to_do_list.vue";
import { mapGetters } from "vuex";
export default {
    name: "To_do_lists",
    components: {
        Loader,
        sheader,
        addto,
        draggable,
        modalLateral,
    },
    data() {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },            
            titulo: "To do lists",
            isLoading: false,
            modalTo: "",
            user_id: window.master_client,
            org_id: window.localorgdata,
            listTemplate: [],
            search: "",
        };
    },
    created() {
        this.init()
    },
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.setup;               
            this.getTemplateList()            
        },        
        checkMove() {
            this.listTemplate.map((item, index) => {
                item.order = index + 1;
            });
            let url = this.apis.ordertolist()
            window.master
                .put(url, {
                    listTemplate: this.listTemplate,
                })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        delTemplate(id) {
            this.$swal({
                title: "Delete this item?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let url = this.apis.delTemplate(id)
                    window.master
                        .delete(url)
                        .then((response) => {
                            let r = response.data.status;
                            if (r == false) {
                                this.$notify({
                                    group: "noti",
                                    type: "error",
                                    title: "Error",
                                    text: "could not be deleted.",
                                });
                            } else {
                                this.$notify({
                                    group: "noti",
                                    type: "success",
                                    title: "Delete",
                                    text: "successfully removed",
                                });
                                this.getTemplateList();
                            }
                        })
                        .catch((error) => console.log(error));
                }
            });
        },
        getTemplateList() {
            this.isLoading = true;
            let url = this.apis.getTemplate()
            window.master
                .get(url)
                .then((response) => {
                    let r = response.data.result;
                    this.listTemplate = r;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        ModalBox(i, id) {
            this.m = {
                show: true,
                component: 'addto',
                data: id
            }
            this.$store.commit('core/setOpenModal', this.m);
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "listTemplate",
                disabled: false,
                ghostClass: "ghost",
            };
        },        
        filterItems: function () {
            var self = this;
            return this.listTemplate.filter(function (item) {
                return (
                    item.name &&
                    item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                );
            });
        },
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
        'getOpenModal': function() {
            if (this.getOpenModal.data.metodo === "return") {               
                this.$notify({
                    group: "noti",
                    title: "Saved",
                    type: "success",
                    text: "Successful registration",
                }); 
                this.getTemplateList();                               
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }                
            }
        },         
    },
};
</script>
<style lang="scss" scoped>
.card-header {
    padding: 0px;
}
.search {
    border-color: #555;
}
.handle {
    position: absolute;
    border: 0px solid #000;
    left: 0px;
    padding: 15px 2px 3px 4px;
    height: 42px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(17, 177, 245, 0.38);
    cursor: move;
    margin-top: -9px;
}
.tbody t {
    position: relative;
}
.tbody tr td {
    position: relative;
    padding-left: 16px;
}
</style>
