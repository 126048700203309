<template>
    <div class="card food-gallery">
        <div class="card-header">
            <h3>
                Food Gallery:
                <span v-if="foodItem">{{ foodItem.name_food }}</span>
            </h3>
        </div>
        <div class="card-body">
            <header class="btn-upload">
                <ul>
                    <li>
                        <button class="btn btn-primary" @click.prevent="$refs.image.click()">Upload Image: JPEG & PNG</button>
                        <input type="file" ref="image" accept="image/jpeg,image/jpg,image/png," style="display:none;" @change="uploadFile($event, 'img')">
                    </li>
                    <li>
                        <button class="btn btn-info" @click.prevent="$refs.video.click()">Upload Video: MP4</button>
                        <input type="file" ref="video" accept="video/mp4" style="display:none" @change="uploadFile($event, 'video')">
                    </li>
                </ul>
            </header>
            <article class="gallery-list">
                <ul v-if="!foodItem.gallery || foodItem.gallery.length==0">
                    <li class="gallery_item">
                        <div class="gallery_media" @click.prevent="$refs.image.click()">
                            <img class="item_media" src="@/core/assets/img/add-img-icon.png" alt="add image" >
                        </div>
                    </li>
                </ul>
                <draggable v-else v-model="foodItem.gallery" tag="ul" v-bind="dragOptions" @change="checkMove" @start="isDragging = true" @end="isDragging = false" handle=".handle">
                    <li class="gallery_item" v-for="(g, gIdx) in foodItem.gallery" :key="gIdx">
                        <button class="btn-move handle"><i class="fas fa-arrows-alt"></i></button>
                        <div class="gallery_media">
                            <img v-if="g.type_file=='img'" class="item_media" :src="urlMedia+'/'+g.type_file+'/'+g.name_file" :alt="g.name_file" >
                            <video v-else :src="urlMedia+'/'+g.type_file+'/'+g.name_file" muted loop preload="auto" :poster="urlMedia+'/video/'+g.name_file+'.jpg'" class="item_media"></video>
                            <div class="gallery_media_icon">
                                <i class="fas fa-image" v-if="g.type_file=='img'"></i>
                                <i class="fas fa-video" v-else></i>
                            </div>
                        </div>
                        <button class="btn-del" @click.prevent="deleteConfirm(g, gIdx)"><i class="fas fa-trash-alt"></i></button>
                    </li>
                </draggable>
            </article>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    data() {
        return {
            isLoading: false,
            foodItem: null,
            apis: null,
            urlMedia: null,
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "gallery",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
        t.foodItem = t.$store.getters['core/getOpenModal'].data.foodItem;
        t.urlMedia = t.$store.getters['core/getOpenModal'].data.urlMedia;
    },
    methods: {
        uploadFile(e, type){
            let t = this;
            if (e.target.files && e.target.files.length==1) {
                let file = e.target.files[0];
                t.isLoading = true;
                let urlService = t.apis.set(5);//setFoodGalleries
                var f = new FormData();
                // @ts-ignore
                f.append("user_id", window.master_client);
                // @ts-ignore
                f.append("org_id", window.localuserdata);
                f.append("pusher_id", pusher.connection.socket_id);
                f.append("type_file", type);
                f.append('file', file, file.name);
                f.append('group_id', t.foodItem.group_id);
                f.append('cat_id', t.foodItem.cat_id);
                f.append('food_id', t.foodItem.id);
                t.$store.commit("core/setLoadingModal", true);
                // @ts-ignore
                window.master.post(urlService, f, {
                    headers: {
                        common: {
                        // @ts-ignore
                        Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    t.$store.commit("core/setLoadingModal", false);
                    console.log(urlService, response.data);
                    t.isLoading = false;
                    t.$refs.image.value=null;
                    t.$refs.video.value=null;
                    if(!response.data.status){
                        t.$notify({
                            group: "noti",
                            type: "error",
                            title: "Error",
                            text: "File could not be uploaded!",
                        });
                    }else{
                        // exito, agregar nuevo item,
                        t.$store.commit('ModApps/Marketea/set_bMenuAddGalleryItem', response.data.result);
                    }
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.$store.commit("core/setLoadingModal", false);
                    t.isLoading = false;
                    t.$refs.image.value=null;
                    t.$refs.video.value=null;
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be saved!",
                    });
                });
            }
        },
        checkMove() {
            let t = this;
            let d = t.$store.getters['core/getOpenModal'].data;
            t.foodItem.gallery.map((item, index) => {
                item.orden = index;
            });
            // t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key: 'bmenu_listCat', val: t.listMain});
            let urlService = t.apis.set(6)// setGalleryOrden
            // @ts-ignore
            window.master.post(urlService, {
                ordenGalleries: t.foodItem.gallery,
            })
            .then((response) => {
                console.log(urlService, response.data);
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Could not be saved!",
                });
            });
        },
        deleteConfirm(i, indexVal) {
            let t = this;
            if(!i.name_file || i.name_file.length==0){
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "To remove it must be without item",
                });
            }else{
                t.$swal({
                    title: "Delete Item?",
                    text: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Delete it!",
                }).then((result) => {
                    if (result.value) {
                        console.log('delete item', i.name_file)
                        t.$store.commit('ModApps/Marketea/set_bMenuDelGalleryItem', i);
                        t.deleteItem(i, indexVal);
                    }
                });
            }
        },
        deleteItem(i, indexVal) {
            let t = this;
            if (i.id > 0) {
                let urlService = t.apis.set(7)// deleteGallery
                // @ts-ignore
                window.master.post(urlService, i)
                .then((response) => {
                    console.log(urlService, i, response.data);
                })
                .catch((error) => {
                    console.log(error);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be delete!",
                    });
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .card.food-gallery{
        .card-header{
            padding: 10px 15px;
            h3{margin: 0px;}
        }
    }
    .btn-upload{
        display: flex;
        ul{
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            li{
                margin-right: 10px;
            }
        }
    }
    .gallery-list{
        margin-top:20px;
        display: flex;
        ul{
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            li{
                position: relative;
                margin-right: 10px;
                margin-bottom: 20px;
                flex: none;
                button{
                    position: absolute;
                    z-index: 1;
                    border:0px;
                    box-shadow: none;
                    background: #fff;
                    color:#000;
                    border-radius: 4px;
                    cursor: pointer;
                    &.btn-move{
                        top:-10px;
                        left: -10px;
                        z-index: 2;
                        background-color: rgb(145 193 239 / 75%);
                        color: #113cfc;
                        cursor: move;
                    }
                    &.btn-cover{
                        right: 0;
                        top: -10px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        i{
                            font-size: 1.2rem;
                            margin-left: 5px;
                        }
                    }
                    &.btn-del{
                        right: 0;
                        bottom: 0;
                        color: #f5f1f1;
                        background-color: #fa5838;
                        border-color: #fa5838;
                    }
                }
                &.gallery_item {
                    width:100%;
                    max-height: 200px;
                    min-height: 200px;
                    max-width: 250px;
                    justify-content: center;
                    display: flex;
                    transition: .3s ease-out all;
                    border-radius: 4px;
                    border:1px solid #eee;
                    .gallery_media{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .item_media{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        .gallery_media_icon{
                            position: absolute;
                            height: 50px;
                            width: 50px;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 1;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            text-shadow: 0 0 10px rgba(0, 0, 0, 0.30);
                            background-color: rgba(0, 0, 0, 0.30);
                            font-size: 1.5rem;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }
    }
</style>
