<template>
    <div class="card">
        <div class="card-header">List Footage Estimate Gutter</div>
        <div class="card-body">
           table
        </div>
    </div>
</template>

<script>
export default {
    
};
</script>
<style lang="scss" scoped>

</style>
