<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title">
                        <i class="fas fa-chart-line"></i> Report Invoices
                    </h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>  
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-3">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    style="width: inherit;min-width: 80px;"
                                                    @click="FilterInvoice()"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button> 
                                            </div>                               
                                            <div class="col-md-9">
                                                <select
                                                    class="form-control"
                                                    v-model="SelectOne"
                                                    @change="ChangeSelectPrimary"
                                                >
                                                    <option value="1">This Year</option>
                                                    <option value="4">Last Year</option>
                                                    <option value="2">This Month</option>
                                                    <option value="3">Calendar</option>
                                                    <option value="5">All</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divS" class="col-md-4" v-if="SelectOne == 3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>From:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateFrom"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>            
                                                    </div>
                                                </div>                               
                                            </div>
                                            <div class="col-md-6">   
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>To:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateTo"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>           
                                                    </div>                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divB" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <div class="col-md-4">
                                                        <label for="staticEmail2" class="">
                                                            <span style="">Status:</span>    
                                                        </label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="basic_dropdown">
                                                            <button
                                                                class="btn-status"
                                                                @click="btnstatus()"
                                                            >
                                                                Status <i class="fas fa-caret-down"></i>
                                                            </button>
                                                            <ul
                                                                class="reset dropdStatus"
                                                                v-if="statusdropd"
                                                            >
                                                                <li>
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            id="check12"
                                                                            @click="checkAll()"
                                                                            v-model="isCheckAll"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            for="check12"
                                                                            ><b>All</b></label
                                                                        >
                                                                    </div>
                                                                </li>
                                                                <li
                                                                    v-for="(item, index) in statusdata"
                                                                    :key="index"
                                                                >
                                                                    <div
                                                                        class="custom-control custom-checkbox"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            class="custom-control-input"
                                                                            :id="index"
                                                                            v-bind:value="item.value"
                                                                            v-model="statuslist"
                                                                            @change="updateCheckall()"
                                                                        />
                                                                        <label
                                                                            class="custom-control-label"
                                                                            :for="index"
                                                                            >{{ item.name }}</label
                                                                        >
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="customCheck1"
                                                        v-model="taxeCheck"
                                                    />
                                                    <label
                                                        class="custom-control-label"
                                                        for="customCheck1"
                                                        >Show Taxes?</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divC" v-if="SelectOne != 3" class="col-md-6">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFile('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                            <!--    <button type="button" class="btn btn-primary" style="margin-right: 7px;"><i class="fas fa-file-excel"></i> Download Spreadsheet</button>
                                                    <button type="button" class="btn btn-secondary"><i class="fas fa-file-pdf"></i> Open PDF</button> -->
                                        </div>
                                    </div>   
                                    <div id="divC" v-else class="col-md-2">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFile('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                            <!--    <button type="button" class="btn btn-primary" style="margin-right: 7px;"><i class="fas fa-file-excel"></i> Download Spreadsheet</button>
                                                    <button type="button" class="btn btn-secondary"><i class="fas fa-file-pdf"></i> Open PDF</button> -->
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->
                            <!-- tabla -->
                            <div class="col-md-12">
                                <div class="contenedor-table">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        id="invList"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Inv #</th>
                                                <th scope="col">Date</th>
                                                <th scope="col" style="width: 200px">
                                                    Customer
                                                </th>
                                                <th scope="col">Name/Project</th>
                                                <th scope="col" style="text-align: right">
                                                    Taxes
                                                </th>
                                                <th scope="col" style="text-align: right">
                                                    Total
                                                </th>
                                                <th scope="col" style="text-align: right">
                                                    Balance
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    REC
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalbalance | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: center"></td>
                                            </tr>
                                            <tr
                                                v-for="item in InvoicesListR"
                                                :key="item.id"
                                            >
                                                <td scope="row">{{ item.nro }}</td>
                                                <td>{{ item.fecha }}</td>
                                                <td>
                                                    {{ item.customername }}
                                                    {{ item.customelastname }}<br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.customecompany }}</span
                                                    >
                                                </td>
                                                <td>{{ item.name }}</td>
                                                <td style="text-align: right">
                                                    <span v-if="item.taxe != ''"
                                                        >${{
                                                            item.taxe | formatMoney
                                                        }}</span
                                                    >
                                                </td>
                                                <td style="text-align: right">
                                                    ${{ item.total | formatMoney }}
                                                </td>
                                                <td style="text-align: right">
                                                    ${{ item.balance | formatMoney }}
                                                </td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.rec == 1"
                                                        class="badge badge-success"
                                                        >Rec</span
                                                    >
                                                </td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.status == 1"
                                                        class="badge badge-secondary"
                                                        style="background-color: #f89406"
                                                        >Pending</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 2"
                                                        class="badge badge-primary"
                                                        >Partially Paid</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 3"
                                                        class="badge badge-success"
                                                        >Paid</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 4"
                                                        class="badge badge-primary"
                                                        style="background-color: #e90404"
                                                        >Overdue</span
                                                    >
                                                    <span
                                                        v-else-if="item.status == 5"
                                                        class="badge badge-danger"
                                                        style="background-color: #b94a48"
                                                        >Void</span
                                                    >
                                                </td>
                                            </tr>
                                            <tr v-if="noresult">
                                                <td colspan="9">
                                                    <div
                                                        style="
                                                            text-align: center;
                                                            color: red;
                                                        "
                                                    >
                                                        No result
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        totalbalance | formatMoney
                                                    }}</b>
                                                </td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: center"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "Report",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            noresult: false,
            statusdropd: false,
            taxeCheck: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Partially Paid" },
                { value: "3", name: "Paid" },
                { value: "4", name: "Overdue" },
                { value: "5", name: "Void" },
            ],
            statuslist: [1, 2, 3, 4, 5],
            showModal: false,
            InvoicesListR: [],
            SelectOne: 1,
            SelectStatus: 0,
            //dateFrom : new Date().toISOString(),
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            thisYear: null,
            thisMonth: null,
            type: null,
        };
    },
    created() {
        this.init();
    },
    mounted() {
        this.setTimeoutResponsive()
    },
    methods: {
        init() {
            this.FilterInvoice();
        },
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            let divS = document.getElementById("divS");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }  
            } 
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                              	
            }
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-3");	
                    divB.classList.add("col-md-6");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-3");	
                    divC.classList.add("col-md-6"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-12"); 
                }                                               	
            }
        },
        ChangeSelectPrimary() {
            this.setTimeoutResponsive()
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        },        
        formatearFecha() {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        /* generarFiles(type){
          this.$swal({
            title: "Export",
            text: "Export Report as "+type+" (Microsoft Excel)",
            icon: "info",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: 'Cancel',
            confirmButtonText: "Export",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              window.billing.put('report/invoices/'+ window.localuserdata,{
                          user_id: window.localuserdata,
                          selecttype: this.SelectOne,
                          status: this.statuslist,
                          dateFrom: this.dateFrom,
                          dateTo: this.dateTo,
                          taxeCheck : this.taxeCheck,
                          filetype : type,
                          })
                .then(response => {
                    console.log(response.data);
                    this.statusdropd = false
                    this.isLoading = false
                    window.open(window.urlbase+'files/'+ window.localuserdata + '-'+ response.data.file +'.'+type);
                    
                })
                .catch(error => {
                  this.$swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
          .then((result) => {
          if (result.value) {
             this.$swal.fire({
              allowOutsideClick: false,
              text: `Successfully downloaded`,
              confirmButtonText: "Close",
              showLoaderOnConfirm: true,
                preConfirm: () => {
                 this.DeleteFile();
                },
            })
          }
        }) 
         
        }, */
        DeleteFile() {
            let name = "invoices";
            window.billing
                .get("report/deletefile/" + window.localuserdata + "/" + name)
                .then((response) => {})
                .catch((error) => console.log(error));
        },
        generarFile(type) {
            let user_id = window.localuserdata;
            let selecttype = this.SelectOne;
            let status = this.statuslist;
            let taxeCheck = this.taxeCheck;
            let dateFrom = this.dateFrom;
            let dateTo = this.dateTo;
            let uri =
                "report/export/invoice/pdf/" +
                user_id +
                "/" +
                selecttype +
                "/" +
                status +
                "/" +
                taxeCheck +
                "/" +
                dateFrom +
                "/" +
                dateTo +
                "/" +
                type;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        ChangeSelectStatus() {},
        FilterInvoice() {
            /*1 = Pending
             2 = Partially Paid
             3 = Paid
             4 = Overdue
             5 = Void */
            this.isLoading = true;
            console.log("Filtrar");
            window.billing
                .put("report/invoices/" + window.localuserdata, {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                    status: this.statuslist,
                    taxeCheck: this.taxeCheck,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    filetype: this.type,
                })
                .then((response) => {
                    console.log(response.data);
                    this.statusdropd = false;
                    this.isLoading = false;
                    let list = response.data.result;
                    if (list == 0) {
                        this.noresult = true;
                        this.InvoicesListR = [];
                    } else {
                        this.noresult = false;
                        let e = list;
                        for (var i = 0; i < e.length; i++) {
                            let fecdue = new Date(e[i].fecdue).toDateString();
                            let fn = fecdue.split(" ");
                            list[i].fecdue = fn[1] + " " + fn[2] + ", " + fn[3];
                            let f_a = new Date();
                            fecdue = new Date(e[i].fecdue);
                            if (f_a > fecdue && e[i].overdue) {
                                list[i].status = 4;
                            }
                        }
                        console.log("inv list");
                        console.log(list);
                        let a = list;
                        let nuevaList = [];
                        let statuslist = this.statuslist;
                        console.log("status length " + statuslist.length);
                        if (statuslist.length < 5) {
                            //console.log('agregando solo los disponibles en la lista')
                            for (let i in a) {
                                //debugger;
                                if (statuslist.indexOf(a[i].status.toString()) > -1) {
                                    nuevaList.push(a[i]);
                                }
                            }
                            console.log("nuevalist ", nuevaList);
                            if (nuevaList.length == 0) {
                                this.noresult = true;
                            }
                            this.InvoicesListR = nuevaList;
                        } else {
                            this.InvoicesListR = list;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
    },
    watch: {
        dateFrom: function () {
            this.formatearFecha();
        },
    },
    computed: {
        total: function () {
            if (!this.InvoicesListR) {
                return 0;
            }
            console.log(this.InvoicesListR);
            return this.InvoicesListR.reduce(function (total, value) {
                return total + Number(value.total);
            }, 0);
        },
        totalbalance: function () {
            if (!this.InvoicesListR) {
                return 0;
            }
            return this.InvoicesListR.reduce(function (total, value) {
                return total + Number(value.balance);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style-type: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
#invList thead tr th:nth-child(2),
#invList tbody tr td:nth-child(2) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(3),
#invList tbody tr td:nth-child(3) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(4),
#invList tbody tr td:nth-child(4) {
    min-width: 200px;
}
#invList thead tr th:nth-child(5),
#invList tbody tr td:nth-child(5) {
    min-width: 180px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
#invList thead tr th:nth-child(11),
#invList tbody tr td:nth-child(11) {
    min-width: 85px;
    width: 85px;
}
@media (max-width: 1100px) {
    #invList thead tr th:nth-child(1),
    #invList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    #invList thead tr th:nth-child(2),
    #invList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(3),
    #invList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(4),
    #invList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    #invList thead tr th:nth-child(5),
    #invList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    #invList thead tr th:nth-child(6),
    #invList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(7),
    #invList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    #invList thead tr th:nth-child(8),
    #invList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(9),
    #invList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(10),
    #invList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    #invList thead tr th:nth-child(11),
    #invList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
table tbody tr td .badge {
    width: 90%;
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
