<template>
    <div>
        <div class="card">
            <div class="card-header">
                <i class="fas fa-edit"></i> EDIT
            </div>
            <div class="card-body">
                <form @submit.prevent="UpdateEmilePrint">
                    <div class="form-row">
                        <div class="col-6">
                            <span id="emailHelp" class="form-text text-muted">
                                HP ePrint1 email</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormEPrint.email1"
                            />
                        </div>
                        <div class="col-6">
                            <span id="emailHelp" class="form-text text-muted">
                                HP ePrint2 email</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormEPrint.email2"
                            />
                        </div>

                        <div class="col-12">
                            <small id="emailHelp" class="form-text text-muted"
                                >.</small
                            >
                            <button type="submit" class="btn btn-success float-right">
                                SAVE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { 
    },
    name: "EprintSettings",
    data () {
        return {
            FormEPrint: {
                action: "update",
                user_id: null,
                email1: "",
                email2: "",
            },
            m: {
                show: false,
                component: '',
                data: []
            }                        
        }
    },
    created () {
        this.getEprintemail();
    },
    methods: {
        getEprintemail() {
            window.billing
                .get("setting/" + window.localuserdata)
                .then((response) => {
                    this.FormEPrint.email1 = response.data.email1.meta_value;
                    this.FormEPrint.email2 = response.data.email2.meta_value;
                })
                .catch((error) => console.log(error));
        },        
        UpdateEmilePrint() {
            if (this.FormEPrint.action == "update") {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                    .put("setting/" + window.localuserdata, {
                        user_idd: window.master_client,
                        org_id: window.localorgdata,
                        email1: this.FormEPrint.email1,
                        email2: this.FormEPrint.email2,
                    })
                    .then((response) => {
                        this.$modal.hide("setting-eprint");
                        if (response.data.success) {
                            this.$notify({
                                group: "noti",
                                title: "UPDATED",
                                type: "success",
                                text: "Update Successful",
                            });
                            this.$store.commit("core/setLoadingModal", false);
                            this.$store.commit("core/setCloseModal", {action: 'saved'});   
                            this.$store.commit("core/setActionRefresh", {
                                action: 'getEprint',
                                data: this.FormEPrint,                 
                            });  
                        }
                    })
                    .catch((error) => {
                        this.$store.commit("core/setLoadingModal", false);
                        console.log(error, (this.isLoading = false))
                    });
            }
        },  
    },
};
</script>

<style lang="css" scoped></style>
