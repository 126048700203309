var dateSumarDias= function(d, fecha) {
	var a = (fecha + "").split(" "); 
	var meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	var nro_mes = parseInt(meses.indexOf(a[1])) + 1;
	nro_mes = nro_mes < 10 ? "0" + nro_mes : nro_mes;
	var f = a[3] + "-" + nro_mes + "-" + a[2];
	f+= "T"+ new Date().toTimeString().slice(0, 8)+'.000-05:00'; 
	console.log("fecha  string = " + f);
	var fn = new Date(f);
	fn.setDate(fn.getDate() + parseInt(d));
	var anno = fn.getFullYear();
	var mes = fn.getMonth() + 1;
	var dia = fn.getDate();
	mes = mes < 10 ? "0" + mes : mes;
	dia = dia < 10 ? "0" + dia : dia;
	var fechaOut = anno + "-" + mes + "-" + dia;
	fechaOut+= "T" + new Date().toTimeString().slice(0, 8)+'.000-05:00'; // horas y GTM
	return fechaOut;
};
export default dateSumarDias;
