/*rutas de Employees*/
import Employees from '@/apps/billing/views/Employees';
import EmployeesNew from '@/apps/billing/views/EmployeesNew';
export default [
  {
    path: 'employees',
    name: 'Employees',
    folder: Employees,
  },
  {
    path: 'employees-new',
    name: 'EmployeesNew',
    folder: EmployeesNew,
  },
  {
    path: 'employees-new/:subcontractor_id',
    name: 'EmployeesNewEdit',
    folder: EmployeesNew,
  }
];
