<template>
	<div style="margin-bottom: 50px;">
		<form @submit.prevent="saveEstimates()">
	        <div class="row">
                <div class="alert alert-danger" role="alert" v-if="errors.length">
                    <ul style="margin-bottom: 0">
                        <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </div>
	        </div>
	        <div class="row">
	            <div class="col-6 align-self-center">
	                <h3>Add Estimate</h3>
	            </div>
	            <div class="col-6 text-right">
	                <button type="submit" class="btn btn-success"  style="margin-bottom: 0.5rem;">
	                    <i class="fas fa-save"></i> SAVE
	                </button>
	            </div>
	        </div>
	        <div class="row" style="background-color: #f1f1f1;padding: 8px;margin-bottom: 12px;">
                <div class="col-2">
                    <label for="inputEmail4"
                        >Status</label
                    >
                    <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="formEstimates.status"
                    >
                        <option :value="1">
                            Pending
                        </option>
                        <option :value="2">
                            Accepted
                        </option>
                        <option :value="3">
                            Rejected
                        </option>
                        <!-- <option :value="4">Void</option> -->
                    </select>
                </div>
                <div class="col-6">
                    <label for="inputEmail4"
                        >Accepted By</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="
                            formEstimates.accepted_by
                        "
                    />
                </div>
                <div class="col-4">
                    <label for="inputEmail4"
                        >Accepted Date</label
                    >
                    <datetime
                        v-model="
                            formEstimates.accepted_date
                        "
                        input-class="form-control"
                        value-zone="America/New_York"
                    ></datetime>
                </div>
	        </div>
	        <div class="row">
                <div class="col-6">
                    <label
                        for="inputEmail4"
                        style="
                            position: relative;
                            width: 100%;
                            display: block;
                        "
                        >Customer
                    </label>
                    <div
                        class="form-group"
                        id="CustomersList"
                    >
                        <model-select
                            :options="CustomersList"
                            v-model="ValuesCustomers"
                            placeholder="Choose a Customer"
                        >
                        </model-select>
                    </div>
                </div>
                <div class="col-6">
                    <label for="inputEmail4"
                        >Estimate Name (Optional)
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        maxlength="50"
                        v-model="
                            formEstimates.estimates_optional
                        "
                    />
                </div>
	        </div>
	        <div class="row">
                <div class="col-5">
                    <label for="inputEmail4">Bill To</label>
                    <div class="form-group bill_to">
                        <div>
                            <span>
                                {{ ValuesCustomers.customer_name }}
                            </span>
                            <span>
                                {{ ValuesCustomers.customer_lastname
                        }}
                            </span>
                        </div>
                        <div v-if="ValuesCustomers.bussinesname!=null && ValuesCustomers.bussinesname!='' && ValuesCustomers.bussinesname!='null'">
                            {{ ValuesCustomers.bussinesname }}
                        </div>
                        <div 
                            v-if="ValuesCustomers.customer_address!=''"
                        >
                            {{
                                ValuesCustomers.billing_address
                            }}
                        </div>
                        <div
                            v-if="ValuesCustomers.city!=null ||
                            ValuesCustomers.state!=null ||
                            ValuesCustomers.code!=null 
                            "
                        >
                            <span v-if="ValuesCustomers.city!=null">{{ ValuesCustomers.city }}</span>
                            <span v-if="ValuesCustomers.state!=null">{{ ValuesCustomers.state }}</span>
                            <span v-if="ValuesCustomers.code!=null">, </span>
                            <span v-if="ValuesCustomers.code!=null">{{ ValuesCustomers.code }}</span>
                        </div>
                        <div>
                            <span v-if="ValuesCustomers.customer_phone!=null">
                                {{ ValuesCustomers.customer_phone }}
                            </span>
                            <span v-if="ValuesCustomers.customer_officephone!=null">
                                <span v-if="ValuesCustomers.customer_phone!=null"> / </span>
                                {{ ValuesCustomers.customer_officephone }}
                            </span>                  
                        </div>
                        <div v-if="ValuesCustomers.email!=null">
                            {{ ValuesCustomers.email }}
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <label for="job_information" >Job information</label
                    >
                    <textarea
                        name=""
                        id="job_information"
                        cols="30"
                        rows="2"
                        class="form-control"
                        v-model="
                            formEstimates.job_information
                        "
                    ></textarea>
                </div>
                <div class="col-2">
                    <label for="datedoc">Date</label>
                    <datetime
                    	id="datedoc"
                        type="date"
                        v-model="inputFecha"
                        input-class="form-control"
                        value-zone="America/New_York"
                    ></datetime>
                </div>
	        </div>
	        <div class="row" style="margin:0px; padding-left: 10px; padding-right: 10px;">
	        	<tableProductos :prodnew="true"></tableProductos>
	        </div>
            <div
                class="blockMont"
                style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                "
            >
                <div style="width: 50%">
                    <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="4"
                    class="form-control"
                    v-model="
                    formEstimates.customer_message
                    "
                    placeholder="Customer message"
                    ></textarea>
                </div>
                <div style="width: 50%">
                    <tableResumenTotales :form="formEstimates"></tableResumenTotales>
                </div>
            </div>
            <div class="row">
                
                                            <div class="pageSignature">
                                                <div
                                                    class="form-inline"
                                                    style="border-bottom: 1px solid #ccc"
                                                >
                                                    <div class="my-1 mr-2">
                                                        Need a Contract?
                                                    </div>
                                                    <div
                                                        class="custom-control custom-checkbox my-1 mr-sm-2"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="customControlInline"
                                                            v-model="
                                                                formEstimates.checked
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customControlInline"
                                                            >Yes</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        v-if="formEstimates.checked"
                                                        style="
                                                            margin-right: 5px;
                                                            margin-left: 15px;
                                                        "
                                                    >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                selectCTemplate.title
                                                            "
                                                            placeholder="Terms & Conditions"
                                                        />
                                                    </div>
                                                    <!-- <div
                                                        class="form-group"
                                                        v-if="formEstimates.checked"
                                                    >
                                                        <select
                                                            class="form-control my-1 mr-2"
                                                            id="exampleFormControlSelect1"
                                                            style="
                                                                width: 300px;
                                                                margin-top: 10px;
                                                            "
                                                            v-model="selectCTemplate"
                                                        >
                                                            <option :value="{title:'', description:''}" selected>
                                                                Select template
                                                            </option>
                                                            <option
                                                                v-for="i in ContractTemLists"
                                                                :key="i.id"
                                                                :value="i"
                                                            >
                                                                {{ i.title }}
                                                                <span
                                                                    v-if="
                                                                        i.alias !== null
                                                                    "
                                                                    >- {{ i.alias }}</span
                                                                >
                                                            </option>
                                                        </select>
                                                    </div> -->
                                                    <div class="my-1 mr-2" v-if="formEstimates.checked && PlanPremium">
                                                        Request Digital Signature?
                                                    </div>
                                                    <div
                                                        class="custom-control custom-checkbox my-1 mr-sm-2"
                                                        v-if="formEstimates.checked && PlanPremium"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="signe"
                                                            v-model="
                                                                formEstimates.signe
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="signe"
                                                            >Yes</label
                                                        >
                                                    </div>
                                                </div>
                                                <vue-editor
                                                    v-model="selectCTemplate.description"
                                                    v-if="formEstimates.checked"
                                                />
                                                <h5
                                                    style="padding-top: 20px"
                                                    v-if="formEstimates.checked"
                                                >
                                                    Signature:
                                                </h5>
                                                <div
                                                    class="row"
                                                    v-if="formEstimates.checked"
                                                >
                                                    <div class="col">
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Name</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    id=""
                                                                    v-model="
                                                                        ValuesCustomers.text
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Date</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <datetime
                                                                    type="date"
                                                                    v-model="
                                                                        FormSignature.dateCustomer
                                                                    "
                                                                    value-zone="America/New_York"
                                                                    input-class="form-control"
                                                                ></datetime>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Name</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="
                                                                        FormSignature.nameCompany
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Date</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <datetime
                                                                    type="date"
                                                                    v-model="
                                                                        FormSignature.dateCompany
                                                                    "
                                                                    value-zone="America/New_York"
                                                                    input-class="form-control"
                                                                ></datetime>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            </div>
            <div class="row" style="margin-top: 50px;">
                <div class="col-6 align-self-center">
                </div>
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-success"  style="margin-bottom: 0.5rem;">
                        <i class="fas fa-save"></i> SAVE
                    </button>
                </div>
            </div>
		</form>
        <!-- modal -->
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ModelSelect } from 'vue-search-select'
import { VueEditor } from 'vue2-editor'
import tableProductos from '@/apps/billing/components/tableProductos'
import tableResumenTotales from '@/apps/billing/components/tableResumenTotales'
export default {
	name: 'crearEst',
	components: {
		ModelSelect,
        VueEditor,
		tableProductos,
        tableResumenTotales,
	},
	data () {
		return {
            saveDisabled: true,
            formEstimates: {
                idEst: null,
                titlePage: "Add Estimate",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                numdoc: null,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                estimates_optional: null,
                status: 1,
                accepted_by: null,
                accepted_date: new Date((new Date().toISOString() + "").slice(0, 10) + "T13:31:04.000Z").toISOString(),
                job_information: null,
                customer_message: null,
                date_created: new Date((new Date().toISOString() + "").slice(0, 10) + "T13:31:04.000Z").toISOString(),
                subtotal: 0,
                discount_type: 1,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                totaltax: null,
                qty: null,
                checked: false,
                signe: false,
                dataItems: [],
            },
            CustomersList: [],
			ValuesCustomers: {
                text: null,
                value: 0,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
            },
            inputFecha: new Date((new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z").toISOString(),
            ContractTemLists: [],
            selectCTemplate: {
                title: "",
                description: "",
            },
            FormSignature: {
                //nameCustomer: null,
                dateCustomer: new Date(
                    (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                dateCompany: new Date(
                    (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                nameCompany: localStorage.getItem("nomCompany"),
            },
            taxesResumen: [],
            jobinfomationcheck: true,
            jobinfomationtitle: "Job information",
            jobinfomationtext: null,
            dateCustomer: new Date().toISOString().slice(0, 10),
            dateCompany: new Date().toISOString().slice(0, 10),
            accepted_date: new Date().toISOString().slice(0, 10),
            ProdServiceLists: [],
            TaxesLists: [],
            formList: {
                items: [],
            },
            errors: [],
            TypeofAccount: [],
            PlanPremium : (window.OrgPlan > 1),
            m: {
                show: false,
                component: '',
                data: []
            },
            modalBill: [
                'NotesAdmin',
                'formCustomer',
                'formProducts',
                'StartEstimate',
            ],
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 0,
                description: null,
                applytax: 0,
                status: 0,
            },
		}
	},
	created(){
        console.log('crearEst');
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        if(this.$parent.step==2){
            this.getCustomerID();
            this.getTaxes();
            this.getCTemplates();
            this.getAccountTypes();
        }
	},
	methods : {
        getCustomerID() {
            let t = this;
            t.$parent.isLoading = true;
            window.billingSales
            .post("customer/" + t.$parent.customerId)
            .then((response) => {
                console.log('getCustomerID', response.data.result);
                if (response.data.result.length>0) {
                    console.log('getCustomerID', response.data.result[0]);
                    var id = response.data.result[0].id;
                    var name = response.data.result[0].firstname;
                    var billing_address =
                        response.data.result[0].billingaddress;
                    var customer_address = response.data.result[0].address;
                    var bussinesname = response.data.result[0].bussinesname;
                    var email = response.data.result[0].email;
                    var lastname = response.data.result[0].lastname;
                    var customer_phone = response.data.result[0].mobilephone;
                    var customer_officephone = response.data.result[0].officephone;
                    var city = response.data.result[0].bcity;
                    var state = response.data.result[0].bstate;
                    var code = response.data.result[0].bpostcode;
                    lastname = lastname != null ? lastname : "";
                    email = email != null ? email : "";
                    bussinesname = bussinesname != null ? bussinesname : "";
                    city = city != null ? city + "," : "";
                    state = state != null ? state : "";
                    code = code != null ? code : "";
                    customer_address = customer_address != null ? customer_address : "";
                    customer_officephone = customer_officephone != null ? customer_officephone : "";
                    let guion = '-';
                    let customer= {
                        text: name + " " + lastname + ((bussinesname!='') ? " "+guion +" "+bussinesname : ''),
                        value: id,
                        billing_address: billing_address,
                        bussinesname: bussinesname,
                        customer_address: customer_address,
                        customer_name: name,
                        customer_lastname: lastname,
                        customer_phone: customer_phone,
                        customer_officephone: customer_officephone,
                        email: email,
                        city: city,
                        state: state,
                        code: code,
                    };
                    t.ValuesCustomers =customer;
                    t.CustomersList.push(customer);
                }
                if(this.$parent.dataSales.est_id && this.$parent.dataSales.est_id==0){
                    t.$parent.isLoading = false;
                }
            })
            .catch((error) => {
                console.log(error);
                t.$parent.isLoading = false;
            });
        },
        getAccountTypes() {
            let t = this;
            window.billingSales
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    t.TypeofAccount = response.data.result;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        NewProducts(item, index) {
            let t = this;
            t.FormAddPS.index_modal = index;
            t.$parent.$parent.m = {
                show: true,
                component: 'formProducts',
                data: {
                    FormAddPS:t.FormAddPS,
                    TypeofAccount:t.TypeofAccount,
                    TaxesLists:t.TaxesLists,
                    formList:t.formList
                }
            }
            t.$store.commit('core/setOpenModal', t.$parent.$parent.m);
        },
        saveEstimates() {
            let t = this;
            this.$parent.isLoading = true;
            this.saveDisabled = true;
            this.errors = [];
            if (!this.ValuesCustomers.value) {
                //this.errors.push('Must select "Customer"');
                this.msjAlert('Must select "Customer')
                this.$parent.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            if (this.formEstimates.total == 0) {
                // this.errors.push("Total must not be greater than zero");
                this.msjAlert("Total must not be greater than zero")
                this.$parent.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            if (!this.formList.items[0].model.value) {
                //this.errors.push("Must add at least 1 Product or service");
                this.msjAlert("Must add at least 1 Product or service")
                this.$parent.isLoading = false;
            }
            for (let i in this.formList.items) {
                if (
                    this.formList.items[i].model.qty == "" ||
                    this.formList.items[i].model.qty == null
                ) {
                    this.formList.items[i].model.qty = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.$parent.isLoading = false
                }
                if (
                    this.formList.items[i].model.unitcost == "" ||
                    this.formList.items[i].model.unitcost == null
                ) {
                    this.formList.items[i].model.unitcost = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.$parent.isLoading = false
                }                
            }
            if (this.ValuesCustomers.value && this.errors.length == 0) {
                if (
                    this.formEstimates.actionNname == "create" 
                ) {
                    window.billingSales
                    .post("estimates/add", {
                        user_id: window.localuserdata,
                        customer_id: this.ValuesCustomers.value,
                        estimate_optional: this.formEstimates.estimates_optional,
                        status: this.formEstimates.status,
                        accepted_by: this.formEstimates.accepted_by,
                        accepted_date: this.formEstimates.accepted_date,
                        job_information: this.formEstimates.job_information,
                        customer_message: this.formEstimates.customer_message,
                        need_contract: this.formEstimates.checked,
                        signe: this.formEstimates.signe,
                        terms_conditions_title: this.selectCTemplate.title,
                        terms_conditions_desc: this.selectCTemplate.description,
                        signaturecustomer: this.ValuesCustomers.text,
                        signaturecustomerdate: this.FormSignature.dateCustomer,
                        signaturecompany: this.FormSignature.nameCompany,
                        signaturecompanydate: this.FormSignature.dateCompany,
                        date_create: this.formEstimates.date_created,
                        sub_total: this.formEstimates.subtotal,
                        discount_type: this.formEstimates.discount_type,
                        discount_mount: this.formEstimates.discount_mount,
                        discount_total: this.formEstimates.discount_total,
                        discount_name: this.formEstimates.discount_name,
                        total: this.formEstimates.total,
                        dataItems: this.formList.items,
                    })
                    .then((response) => {
                        this.saveDisabled = false;
                        console.log('add est', response.data);
                        t.salesSetEstID(response.data.Estimates, 'add');
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$parent.isLoading = false;
                        this.saveDisabled = false;
                        this.$notify({
                            group: "noti",
                            title: "Error!",
                            type: "error",
                            text: "Add Estimate",
                        });
                    });
                }
                if (this.formEstimates.actionNname == "update") {
                    window.billingSales
                        .post("estimate/edit/" + this.formEstimates.idEst, {
                            user_id: window.localuserdata,
                            numdoc: this.formEstimates.numdoc,
                            customer_id: this.ValuesCustomers.value,
                            estimate_optional: this.formEstimates.estimates_optional,
                            status: this.formEstimates.status,
                            accepted_by: this.formEstimates.accepted_by,
                            accepted_date: this.formEstimates.accepted_date,
                            job_information: this.formEstimates.job_information,
                            customer_message: this.formEstimates.customer_message,
                            need_contract: this.formEstimates.checked,
                            signe: this.formEstimates.signe,
                            terms_conditions_title: this.selectCTemplate.title,
                            terms_conditions_desc: this.selectCTemplate.description,
                            signaturecustomer: this.ValuesCustomers.text,
                            signaturecustomerdate: this.FormSignature.dateCustomer,
                            signaturecompany: this.FormSignature.nameCompany,
                            signaturecompanydate: this.FormSignature.dateCompany,
                            date_create: this.formEstimates.date_created,
                            sub_total: this.formEstimates.subtotal,
                            discount_type: this.formEstimates.discount_type,
                            discount_mount: this.formEstimates.discount_mount,
                            discount_total: this.formEstimates.discount_total,
                            discount_name: this.formEstimates.discount_name,
                            total: this.formEstimates.total,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.saveDisabled = false;
                            console.log(response.data);
                            this.$notify({
                                group: "noti",
                                title: "Updated",
                                type: "success",
                                text: "Successful Updated",
                            });
                            console.log("estimate/edit", response.data);
                            t.salesSetEstID(response.data.Estimates, 'update');
                        })
                        .catch((error) => {
                            console.log(error);
                            this.saveDisabled = false;
                            this.$parent.isLoading = false;
                            this.$parent.$parent.CloseModalPopFull()
                        });
                }
            }
        },
        getProdservices() {
            window.billingSales
                .get("product-services/" + window.localuserdata)
                .then((response) => {
                    console.log("product sevice list table");
                    //this.ProdServiceLists = response.data.result
                    const ProdList = [{
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    }];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var prodname = response.data.result[item].productname;
                            var quantity = this.formatUSD(response.data.result[item].quantity);
                            var unitcost = this.formatUSD(response.data.result[item].unitcost);
                            var descripcion = response.data.result[item].description;
                            var applytax = response.data.result[item].applytax;
                            let taxOpcID = {
                                id: 0,
                                name: 'None',
                                porcentaje: '',
                                initialstate: '',
                                statename: '',
                                count: 0,
                                total: 0,
                            };
                            for (let i in this.TaxesLists) {
                                if (
                                    this.TaxesLists[i].id ==
                                    response.data.result[item].applytax
                                ) {
                                    taxOpcID = this.TaxesLists[i];
                                }
                            }
                            ProdList.push({
                                value: id,
                                text: prodname,
                                unitcost: unitcost,
                                descripcion: descripcion,
                                qty: quantity,
                                taxOpc: taxOpcID,
                                applytax: applytax,
                            });
                        }
                    }
                    this.ProdServiceLists = ProdList;
                    console.log("ProdList ", this.ProdServiceLists);
                    if(this.$parent.dataSales.est_id && this.$parent.dataSales.est_id>0){
                        console.log('EstimateById', this.$parent.dataSales.est_id);
                        this.EstimateById();
                    }else{
                        this.addNewLinePrimary();
                        this.$parent.isLoading = false;
                    }
                })
                .catch((error) => {
                    this.$parent.isLoading = false;
                    console.log(error);
                });
        }, 
        salesSetEstID(est, action) {
            let t = this;
            window.master
                .post("salesSetEstID", {
                org_id: window.localorgdata,
                user_id: window.master_client,
                idSale: t.$parent.dataSales.id,
                est_id: est.id,
                numdoc: est.numdoc,
                statusEstimate: est.status,
                })
                .then((response) => {
                    if(action=='add')
                    {
                        this.$notify({
                            group: "noti",
                            title: "Created!",
                            type: "success",
                            text: "Successful registration",
                        });
                    }
                    this.$parent.isLoading = false;
                    console.log("salesSetEstID", response.data);
                    this.$parent.$parent.CloseModalPopFull()
                })
                .catch((error) => {
                    console.log(error);
                    this.$parent.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Error!",
                        type: "error",
                        text: "Sync Estimate",
                    });
                });
        },
        getCTemplates() {
            window.billingSales
                .get("contracttemplate/" + window.localuserdata)
                .then((response) => {
                    console.log("template estimates list");
                    console.log(response.data);
                    this.ContractTemLists = response.data.result;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getTaxes() {
            window.billingSales
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log("taxes request all");
                    //console.log(response.data)
                    //this.TaxesLists = response.data;
                    const ObjTaxes = [{
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        }];
                    if (response.data) {
                        for (let item in response.data) {
                            var id = response.data[item].id;
                            var initialstate = response.data[item].initialstate;
                            var name = response.data[item].name;
                            var state_id = response.data[item].state_id;
                            var statename = response.data[item].statename;
                            var status = response.data[item].status;
                            var user_id = response.data[item].user_id;
                            ObjTaxes.push({
                                id: id,
                                initialstate: initialstate,
                                name: name,
                                state_id: state_id,
                                statename: statename,
                                status: status,
                                user_id: user_id,
                                default: "none",
                            });

                            this.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                        }
                    }else{
                    }
                    this.TaxesLists = ObjTaxes;
                    this.getProdservices();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        }, 
        formatUSD(amount) {    
            let vatIdRegex = new RegExp(/^[0-9.,-]+$/); 
            let value = ''     
            let val = 0.0;
            if (vatIdRegex.test(amount)) {
                let ops = {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                };
                let numberFormat2 = new Intl.NumberFormat('en-US', ops);  
                value = numberFormat2.format(amount)                
                // console.log('numberFormat2', numberFormat2.format(amount)); // amount
                if (value.indexOf(".") > -1) {
                    val = value;
                } else {
                    val = value + ".00";
                }
            }              
            return val
        }, 
        addNewLinePrimary() {
            const ProdList = [];
            for (let item in this.ProdServiceLists) {
                var id = this.ProdServiceLists[item].value;
                var prodname = this.ProdServiceLists[item].text;
                var quantity = this.ProdServiceLists[item].qty;
                var unitcost = this.ProdServiceLists[item].unitcost;
                var descripcion = this.ProdServiceLists[item].descripcion;
                var applytax = this.ProdServiceLists[item].applytax;
                let taxOpcID = {
                    id: 0,
                    name: '--None--',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                for (let i in this.TaxesLists) {
                    if (this.TaxesLists[i].id == this.ProdServiceLists[item].applytax) {
                        taxOpcID = this.TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            if (this.formEstimates.actionNname == "create") {
                this.formList.items.push({
                    options: ProdList,
                    taxlistOption: this.TaxesLists,
                    model: {
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    },
                });
            }
        },
        formatearFechaContrato2() {
            this.FormSignature.dateCustomer = new Date(
                (new Date(this.FormSignature.dateCustomer).toISOString() + "").slice(
                    0,
                    10
                ) + "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFechaContrato() {
            this.FormSignature.dateCompany = new Date(
                (new Date(this.FormSignature.dateCompany).toISOString() + "").slice(
                    0,
                    10
                ) + "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFecha() {
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFecha2() {
            this.formEstimates.accepted_date =
                this.formEstimates.accepted_date == null ||
                this.formEstimates.accepted_date == ""
                    ? null
                    : new Date(
                          (
                              new Date(this.formEstimates.accepted_date).toISOString() +
                              ""
                          ).slice(0, 10) + "T23:31:04.000Z"
                      ).toISOString();
        },
        EstimateById() {
            window.billingSales
                .get("estimate/" + this.$parent.dataSales.est_id)
                .then((response) => {
                    this.formEstimates.idEst = response.data.result.id;
                    this.formEstimates.numdoc = response.data.result.numdoc;
                    this.formEstimates.job_information =
                        response.data.result.job_information;
                    this.formEstimates.estimates_optional =
                        response.data.result.estimate_optional;
                    this.formEstimates.customer_message =
                        response.data.result.customer_message;
                    this.formEstimates.accepted_by = response.data.result.accepted_by;
                    this.formEstimates.accepted_date =
                        response.data.result.accepted_date == null ||
                        response.data.result.accepted_date == ""
                            ? null
                            : new Date(
                                  (
                                      new Date(
                                          response.data.result.accepted_date
                                      ).toISOString() + ""
                                  ).slice(0, 10) + "T23:31:04.000Z"
                              ).toISOString();
                    this.formEstimates.status = response.data.result.status;
                    this.formEstimates.actionNname = "update";
                    this.inputFecha = this.formEstimates.accepted_date;
                    this.formEstimates.checked =
                        response.data.result.need_contract == 1;
                    this.formEstimates.signe =
                        response.data.result.signe == 1;
                    this.formEstimates.subtotal = response.data.result.sub_total;
                    this.formEstimates.discount_type = response.data.result.discount_type;
                    this.formEstimates.discount_mount = response.data.result.discount_mount;
                    this.formEstimates.discount_total = response.data.result.discount_total;
                    this.formEstimates.discount_name = response.data.result.discount_name;
                    this.formEstimates.total = response.data.result.total;

                    this.selectCTemplate.description =
                        response.data.result.terms_conditions_desc;
                    this.selectCTemplate.title =
                        response.data.result.terms_conditions_title;
                    this.FormSignature.dateCustomer = new Date(
                        (
                            new Date(
                                response.data.result.signaturecustomerdate
                            ).toISOString() + ""
                        ).slice(0, 10) + "T23:31:04.000Z"
                    ).toISOString();
                    this.FormSignature.dateCompany = new Date(
                        (
                            new Date(
                                response.data.result.signaturecompanydate
                            ).toISOString() + ""
                        ).slice(0, 10) + "T23:31:04.000Z"
                    ).toISOString();
                    this.selectBillTo(response.data.customer.id);
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id == response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let isEmptyObj = !Object.keys(newObj).length;
                        let unitcost = this.formatUSD(response.data.itemsprod[item].unitcost);  
                        let qty = this.formatUSD(response.data.itemsprod[item].qty);
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: unitcost,
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: qty,
                                taxOpc: isEmptyObj ? 0 : newObj,
                            },
                        });
                    }
                    this.$parent.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$parent.isLoading = false;
                });
        },
        selectBillTo(idc) {
            for (let item in this.CustomersList) {
                let id = this.CustomersList[item].value;
                if (idc > 0 && idc == id) {
                    this.ValuesCustomers = {
                        text: this.CustomersList[item].text,
                        value: this.CustomersList[item].value,
                        billing_address: this.CustomersList[item].billing_address,
                        bussinesname: this.CustomersList[item].bussinesname,
                        customer_address: this.CustomersList[item].customer_address,
                        customer_name: this.CustomersList[item].customer_name,
                        customer_lastname: this.CustomersList[item].customer_lastname,
                        customer_phone: this.CustomersList[item].customer_phone,
                        customer_officephone: this.CustomersList[item].customer_officephone,
                        email: this.CustomersList[item].email,
                        city: this.CustomersList[item].city,
                        state: this.CustomersList[item].state,
                        code: this.CustomersList[item].code,
                    };
                }
            }
            // console.log("customer seleccionado", this.ValuesCustomers);
        },
	},
	watch: {
        inputFecha: function () {
            this.formatearFecha();
            this.formEstimates.date_created = this.inputFecha;
        },
        "FormSignature.dateCustomer": function () {
            this.formatearFechaContrato2();
            this.dateCustomer = this.FormSignature.dateCustome;
        },
        "FormSignature.dateCompany": function () {
            this.formatearFechaContrato();
            this.dateCompany = this.FormSignature.dateCompany;
        },
        "formEstimates.accepted_date": function () {
            if (
                this.formEstimates.accepted_date !== null ||
                this.formEstimates.accepted_date !== ""
            ) {
                this.formatearFecha2();
                this.accepted_date =
                    this.formEstimates.accepted_date == null ||
                    this.formEstimates.accepted_date == ""
                        ? null
                        : this.formEstimates.accepted_date;
            }
        },
        "$parent.step": function(){
            console.log('watch step ', this.$parent.step)
            if(this.$parent.step==2){
                this.getCustomerID();
                this.getTaxes();
                this.getCTemplates();
            }
        },
        'getActionRefresh': function() {
            console.log('getActionRefresh', this.getActionRefresh)
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let data = this.getActionRefresh.data; 
                let methods = this.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    this.ValuesCustomers = data;
                    // this.getCustomer();
                }
                if (methods === 'products') {
                    this.ProdServiceLists.push(data.model);
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].options = this.ProdServiceLists;
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].model = data.model;
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
	},
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
    }
}
</script>
<style lang="scss" scoped>
.iva_block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    .itemsb {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.deletebtn {
    position: absolute;
    right: -24px;
    top: 22px;
    width: 24px;
    height: 20px;
    background-color: #f12e2e;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    line-height: 0px;
    &:hover {
        background-color: #c12727;
    }
}
.pageSignature {
    display: block;
    margin-top: 20px;
    width: 100%;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #a0a0a0;
}
.ui.fluid.search.selection.dropdown.productos {
    width: 90%;
    float: left;
    display: inline-block;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.btninv {
    position: relative;
    left: initial;
    top: 0;
    width: 20px;
    height: 38px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    color: #333;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    font-weight: 400;
    border: 1px solid #dedede;
    float: right;
    display: inline-block;
    display: none !important;
    margin-left: -6px;
    &:hover {
        background-color: #e0e0e0;
    }
}
.btnmove {
    position: absolute;
    left: -13px;
    top: -3px;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.table-productos {
    width: 100%;
}
.bill_to {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dedede;
    border-radius: 2px;
    height: 130px;
}
#job_information {
    height: 130px;
}
#CustomersList .ui.selection.dropdown .menu {
    border-top: 1px solid #d4d0c8 !important;
}
@media (max-width: 1100px) {
    .table-productos {
        position: relative;
        display: block;
        padding-left: 15px;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-productos table {
        width: 860px;
    }
}
@media (max-width: 800px) {
    .table-productos {
        max-width: 800px;
    }
}
.error {
    border-color: red !important;
}
</style>
