<template>
    <div class="container-nav-gallery">
        <button class="btn-backScrollleft" @click.prevent="backScrollleft()" v-if="activeScrollLeft"><i class="fas fa-angle-double-left"></i></button>
        <nav class="nav-tab-gallery" @scroll="handleScroll">
            <button class="btn-add"><i class="fa fa-plus"></i></button>
            <ul>
                <li><a href="#" @click.prevent="noClick()">Roofing Asphalt</a></li>
                <li><a href="#" @click.prevent="noClick()">Roofing Repairs</a></li>
                <li><a href="#" @click.prevent="noClick()">Vinyl Siding</a></li>
                <li><a href="#" @click.prevent="noClick()">Basement Remodeling</a></li>
                <li><a href="#" @click.prevent="noClick()">Leaks & Flodding</a></li>
                <li><a href="#" @click.prevent="noClick()">Sump Pump</a></li>
                <li><a href="#" @click.prevent="noClick()">Window & Door</a></li>
                <li><a href="#" @click.prevent="noClick()">Seamless Gutters</a></li>
                <li><a href="#" @click.prevent="noClick()">Deck & Porch</a></li>
                <li><a href="#" @click.prevent="noClick()">Insurance Claims</a></li>
                <li><a href="#" @click.prevent="noClick()">Warranty</a></li>
            </ul>
        </nav>
        <button class="btn-arrow-right" @click.prevent="scrollLeft()" v-if="showBtnRight"><i class="fas fa-chevron-right"></i></button>
    </div>
</template>
<script>
export default {
    data(){
        return {
            activeScrollLeft: false,
            valueScrollLeft: 0,
            totalScroll: 0,
            showBtnRight: true,
            sizeWindowOld: 0,
            sizeWindow: 0,
        }
    },
    mounted(){
        let t = this;
        t.calcTotalScroll();
        t.validBtnsDirectional();
        t.sizeWindow =window.innerWidth
        t.sizeWindowOld =window.innerWidth
        addEventListener('resize', (evt) => { console.log('event resize');t.sizeWindow = window.innerWidth });
    },
    methods: {
        noClick(){console.log('click')},
        handleScroll(evt){
            console.log('handleScroll', evt.target.scrollLeft)
            this.valueScrollLeft=evt.target.scrollLeft
        },
        scrollLeft(){
            document.querySelector(".nav-tab-gallery").scrollLeft += 100;
            this.valueScrollLeft=document.querySelector(".nav-tab-gallery").scrollLeft
            console.log(this.valueScrollLeft)
        },
        backScrollleft(){
            document.querySelector(".nav-tab-gallery").scrollLeft = 0;
            this.valueScrollLeft = 0;
        },
        calcTotalScroll(){
            let t = this;
            let list = document.querySelectorAll(".nav-tab-gallery li");
            t.totalScroll =0;
            t.p =0;
            list.forEach((e) => {
                t.totalScroll+= e.clientWidth;
                t.p+=4;
            });
            t.totalScroll = (t.totalScroll - document.querySelector(".nav-tab-gallery").clientWidth) + t.p;
            console.log('totalScroll', t.totalScroll);
        },
        validBtnsDirectional(){
            if(this.valueScrollLeft==0){this.activeScrollLeft=false}else{this.activeScrollLeft=true}
            if(this.valueScrollLeft>=this.totalScroll){this.showBtnRight=false}else{this.showBtnRight=true}
            console.log('activeScrollLeft', this.activeScrollLeft)
            console.log('showBtnRight', this.showBtnRight)
        },
    },
    watch: {
        valueScrollLeft: function(){
            this.validBtnsDirectional()
        },
        sizeWindow: function(){
            let t = this;
            if((t.sizeWindow - t.sizeWindowOld)>100){
                console.log('resize valid', t.sizeWindowOld, t.sizeWindow)
                t.calcTotalScroll();
                t.validBtnsDirectional();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
