<template>
	<div class="mainPage">
		<div v-if="pageCurrent != null" class="pageCurrent">
      <draggable
        tag="div"
        v-if="pageCurrent.content.length>0" class="content"
        :list="pageCurrent.content"
        v-bind="dragSections"
        @start="isDragging(true)"
        @end="isDragging(false)"
        handle=".moveItem"
        group="contents"
        @change="log"
        @add="addItem"
      >
				<section v-for="(page, pageIdx) in pageCurrent.content" :key="pageIdx" :class="[page.divClass, (pageCurrent.content.length==(pageIdx+1)) ? 'lastSection' : '']" :id="'section-main'+pageIdx">
          <div class="btnsOpcSect before row">
              <div class="col-md-4 text-left" style="padding:0px;">
                  <div class="optEdicion left">
                      <ul>
                          <li class="moveItem" v-if="pageCurrent.content.length>1"><i class="fas fa-ellipsis-v"></i></li>
                          <li><i class="fas fa-edit"></i></li>
                          <li  @click="delSection(page, pageIdx)" ><i class="fas fa-trash-alt"></i></li>
                          <li class="nameItem">Section #{{pageIdx+1}}</li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-4 text-center" style="padding:0px;display: flex;justify-content: center;">
                  <div class="optEdicion" v-if="pageCurrent.content.length>1">
                      <ul>
                          <li @click="addSectionBeforeAfter(page, pageIdx, 'before')"><i class="fas fa-plus-circle"></i>Add Before Section #{{pageIdx+1}}</li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-4 text-right" style="padding:0px;">
                  <div class="optEdicion right">
                    <ul>
                      <li class="up-down" v-if="pageCurrent.content.length>1" >
                        <i class="fas fa-chevron-up" v-if="pageIdx>0" @click="moveSection(page, pageIdx, 'up')"></i>
                        <i class="fas fa-chevron-down" v-if="(pageIdx+1) < pageCurrent.content.length" @click="moveSection(page, pageIdx, 'down')"></i>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
					<div :class="page.container">
            <!-- <div class="row section-content" v-if="page.columns.length>0"> -->
            <draggable
              tag="div"
              v-if="page.columns.length>0" 
              class="row section-content"
              v-model="page.columns"
              v-bind="dragGrid"
              @start="isDragging(true)"
              @end="isDragging(false)"
              handle=".moveColumn"
            >
                <div v-for="(c, columnIdx) in page.columns" :key="columnIdx" :class="['columnItem',c.grids, c.moreClass]">
                    <div class="btnsOpcColumn row">   
                      <div class="col-md-12 text-left" style="padding:0px;">
                        <div class="optEdicion left">
                            <ul>
                              <li class="moveColumn" v-if="page.columns.length>1"><i class="fas fa-ellipsis-v"></i></li>
                              <li><i class="fas fa-edit"></i></li>
                              <li @click="delColumn(pageIdx, columnIdx)"><i class="fas fa-trash-alt"></i></li>
                              <li><i class="fas fa-plus-circle"></i></li>
                              <li class="nameItem">
                                Grid {{c.grids}}
                              </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                    <draggable
                      tag="div"
                      v-if="c.widgets.length>0" 
                      :class="['section-content-column', c.moreClass]"
                      v-model="c.widgets"
                      v-bind="dragWidgets"
                      @start="isDragging(true)"
                      @end="isDragging(false)"
                      @change="logWidget($event, pageIdx, columnIdx)"
                      handle=".moveWidget"
                      group="widgets"
                    >
                      <div v-for="(w, widgetIdx) in c.widgets" :key="widgetIdx" :class="['widgetItem']">
                        <div v-html="w.htmlCode" :class="['htmlCode', w.moreClass]" v-if="w.htmlCode!=null"></div>
                        <div class="add-widget" v-else @click="addWidget(pageIdx, columnIdx)"><i class="fas fa-plus-circle"></i><span>Add widget</span></div>
                        <div class="btnsOpcWidget row" v-if="w.htmlCode!=null">   
                          <div class="col-md-12 text-left" style="padding:0px;">
                            <div class="optEdicion right">
                                <ul>
                                  <li class="nameItem">
                                    <span v-if="w.type=='text'">Text</span>
                                    <span v-if="w.type=='img'">Img</span>
                                    <span v-if="w.type=='btn'">Button</span>
                                    <span v-if="w.type=='code'">Code</span>
                                  </li>
                                  <li><i class="fas fa-edit"></i></li>
                                  <li><i class="fas fa-clone"></i></li>
                                  <li @click="delWidget(pageIdx, columnIdx, widgetIdx)"><i class="fas fa-trash-alt"></i></li>
                                  <li class="moveWidget" v-if="page.columns.length>1"><i class="fas fa-ellipsis-v"></i></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row btn-add-widget" v-if="c.widgets[0].htmlCode!=null">
                        <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: center;">
                            <div class="optEdicion">
                                <ul>
                                    <li @click="addWidget(pageIdx, columnIdx)"><i class="fas fa-plus-circle"></i>Add widget</li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </draggable>      
                    <!-- <div class="add-widget" v-else ><i class="fas fa-plus-circle"></i><span>Add widget</span></div> -->
                </div>
            </draggable>
            <!-- </div> -->
            <div v-else class="row section-content">
            	<div class="col-12 columnItem">
            		<div class="add-widget widget-default" @click="addSectionBeforeAfter(page, pageIdx, 'after')"><i class="fas fa-plus-circle"></i><span> Add Section</span></div>
            	</div>
            </div>
					</div>
          <div class="btnsOpcSect after row" >
              <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: center;">
                  <div class="optEdicion">
                      <ul>
                          <li @click="addSectionBeforeAfter(page, pageIdx, 'after')"><i class="fas fa-plus-circle"></i>Add After Section #{{pageIdx+1}}</li>
                      </ul>
                  </div>
              </div>
          </div>
				</section>
      </draggable>
			<div class="newSection row" v-if="pageCurrent.content.length==0">
          <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: center;">
              <div class="optEdicion">
                  <ul>
                      <li @click="addSections()"><i class="fas fa-plus-circle"></i>Add Section</li>
                  </ul>
              </div>
          </div>
      </div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable'
export default {
	components: {
    draggable
	},
  props: {
    title: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'Lorem ipsum dolor sit amet',
    },
    parrafo: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    img1: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: require('@/core/assets/img/no-image.png'),
    },
    btn: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'Read more',
    },
    
  },
	name: 'viewHeader',
	data() {
		return {
			pageCurrent: null,
			pageCurrentIdx: 0,
      contentEmpty: [
        {
          container: 'container',
          divClass: '',
          columns: [
            {
              widgets: [{
                type: 'text', // texto, img
                htmlCode: null,
                moreClass: '',
              }],
              grids: 'col-12',
              moreClass: '',
            }
          ]
        }
      ],
      widgetEmpty: [{
        type: 'text', // texto, img
        htmlCode: null,
        moreClass: '',
      }],
		};
	},
	created(){
		this.pageCurrent = this.$parent.pageCurrent;
		this.pageCurrentIdx = this.$parent.pageCurrentIdx;
	},
	methods:{
		newSection(id){
			return {
    		divClass: 'section-custom section-custom'+id,
    		container: 'container',
    		columns: [
          {
              widgets: [{
                type: 'text', // texto, img
                htmlCode: '<h2>'+this.title+'</h2>',
                moreClass: 'text-center',
              },
              {
                type: 'text', // texto, img
                htmlCode: '<p>'+this.parrafo+'</p>',
                moreClass: 'text-center',
              }
              ],
              grids: 'col-12',
              moreClass: '',
          },
          {
              widgets: [{
                type: 'img', // texto, img
                htmlCode: '<img src="'+this.img1+'" alt="img" class="img-responsive"/>',
                moreClass: 'img-thumbnail',
              },
              {
                type: 'text', // texto, img
                htmlCode: '<h3>'+this.title+'</h3><p>'+this.parrafo+' '+this.parrafo+'</p>',
                moreClass: 'textContent',
              },
              {
                type: 'btn', // texto, img
                htmlCode: '<a href="#" class="btn-builder">'+this.btn+'</a>',
                moreClass: '',
              }],
              grids: 'col-3',
              moreClass: '',
          },
          {
              widgets: [{
                type: 'img', // texto, img
                htmlCode: '<img src="'+this.img1+'" alt="img" class="img-responsive"/>',
                moreClass: 'img-thumbnail',
              },
              {
                type: 'text', // texto, img
                htmlCode: '<h3>'+this.title+'</h3><p>'+this.parrafo+' '+this.parrafo+'</p>',
                moreClass: 'textContent',
              },
              {
                type: 'btn', // texto, img
                htmlCode: '<a href="#" class="btn-builder">'+this.btn+'</a>',
                moreClass: '',
              }],
              grids: 'col-3',
              moreClass: '',
          },
          {
              widgets: [{
                type: 'img', // texto, img
                htmlCode: '<img src="'+this.img1+'" alt="img" class="img-responsive"/>',
                moreClass: 'img-thumbnail',
              },
              {
                type: 'text', // texto, img
                htmlCode: '<h3>'+this.title+'</h3><p>'+this.parrafo+' '+this.parrafo+'</p>',
                moreClass: 'textContent',
              },
              {
                type: 'btn', // texto, img
                htmlCode: '<a href="#" class="btn-builder">'+this.btn+'</a>',
                moreClass: '',
              }],
              grids: 'col-3',
              moreClass: '',
          },
          {
              widgets: [{
                type: 'img', // texto, img
                htmlCode: '<img src="'+this.img1+'" alt="img" class="img-responsive"/>',
                moreClass: 'img-thumbnail',
              },
              {
                type: 'text', // texto, img
                htmlCode: '<h3>'+this.title+'</h3><p>'+this.parrafo+' '+this.parrafo+'</p>',
                moreClass: 'textContent',
              },
              {
                type: 'btn', // texto, img
                htmlCode: '<a href="#" class="btn-builder">'+this.btn+'</a>',
                moreClass: '',
              }],
              grids: 'col-3',
              moreClass: '',
          },
    		]
    	};
		},
		addSections(i, idx){
    	let id = this.$parent.theme.main.pages[this.pageCurrentIdx].content.length;
    	this.$parent.theme.main.pages[this.pageCurrentIdx].content.push(this.newSection(id));
		},
		addSectionBeforeAfter(i, idx, action){
			let id = 0;
			let contents = this.$parent.theme.main.pages[this.pageCurrentIdx].content;
			let newContents = [];
			if(action=='before'){
				newContents.push(this.newSection(id));
			}
			for (let e in contents) {
				if(contents[e]){
					newContents.push(contents[e]);
				}
			}
			if(action=='after'){
				id = newContents.length;
        if(newContents[idx].columns.length==0){
          newContents[idx] = this.newSection(idx);
          console.log('reemplazar idx=0')
        }
        else{
          newContents.push(this.newSection(id));
        }
			}
    	this.$parent.theme.main.pages[this.pageCurrentIdx].content=newContents;
		},
    delSection(i, idx){
      let t = this;
      let elem = document.querySelector("#section-main"+idx);
      let heightResta = elem.offsetHeight/10;
      elem.style.overflow = 'hidden';
      let bg1 = '#d7d7d7';
      let bg2 = '#f2f4f5';
      let bgCurrent = '#d7d7d7';
      elem.style.height = elem.offsetHeight - heightResta + 'px';
      let efectoKeyframes = [
        { height: elem.style.height },
        { background: bgCurrent },
        { height: '272px' },
      ];
      let optionsTiming = {
        duration: 400,
        iterations: 1,
      };
      elem.animate(efectoKeyframes, optionsTiming);
      setTimeout(function(){
        elem.removeAttribute('style');
        t.$parent.theme.main.pages[t.pageCurrentIdx].content.splice(idx, 1);
        if(t.$parent.theme.main.pages[t.pageCurrentIdx].content.length==0){
          t.$parent.theme.main.pages[t.pageCurrentIdx].content = t.contentEmpty;
        }
      }, 500);
    },
    moveSection(page, pageIdx, move){
      let items = [];
      let items2 = [];
      for (let i in this.pageCurrent.content) {
        if(this.pageCurrent.content[i]){
          items.push(this.pageCurrent.content[i]);
        }
      }
      console.log('nueva lista', items);
      console.log('move', move);
      let idxActual = pageIdx;
      let idxMove = pageIdx;
      if(move == 'up'){
        idxMove = pageIdx-1;       
      }else{
        // down
        idxMove = pageIdx+1;
      }
      let itemAcual = items[idxActual];
      //console.log('itemAcual', itemAcual);
      let itemAsustituir = items[idxMove];
      //console.log('itemAsustituir', itemAsustituir);
      for (let i in this.pageCurrent.content) {
        if(this.pageCurrent.content[i]){
          if(i == idxActual){
            items2.push(itemAsustituir);
          }
          else if(i == idxMove){
            items2.push(itemAcual);
          }
          else{
            items2.push(this.pageCurrent.content[i]);
          }
        }
      }
      this.pageCurrent.content = items2;
      this.$parent.theme.main.pages[this.pageCurrentIdx].content = this.pageCurrent.content;
    },
    delColumn(pageIdx, columnIdx){
        this.$parent.theme.main.pages[this.pageCurrentIdx].content[pageIdx].columns.splice(columnIdx, 1);
        //this.$store.commit("ModApps/Sites/setRefreshBoxContent", 1);
    },
    addWidget(pageIdx, columnIdx){
      this.$parent.showAsideEditor();
    },
    delWidget(pageIdx, columnIdx, widgetIdx){
        this.$parent.theme.main.pages[this.pageCurrentIdx].content[pageIdx].columns[columnIdx].widgets.splice(widgetIdx, 1);
        if(this.$parent.theme.main.pages[this.pageCurrentIdx].content[pageIdx].columns[columnIdx].widgets.length==0){
          this.$parent.theme.main.pages[this.pageCurrentIdx].content[pageIdx].columns[columnIdx].widgets=this.widgetEmpty;
        }
        //this.$store.commit("ModApps/Sites/setRefreshBoxContent", 1);
    },
    isDragging(t){
        //console.log('move', t) // t = true or false
        return t;
    },
    log: function(evt) {
      //console.log('item clonado', evt);
      //console.log('lista principal con item clonado', this.pageCurrent.content);
      if(evt.added){
        //this.$store.commit("ModApps/Sites/setBoxContentCloneNewIndex", evt.added.newIndex);
        /*
        console.log('copia lista principal sin clon', items)
        //items.push(evt.added.element); // agg item 
        this.pageCurrent.content = items; // reemplazar arreglo
        // agregar item en el array en una posicion determinada
        // splice(idx_position, 0, item)
        this.pageCurrent.content.splice(evt.added.newIndex, 0, evt.added.element); // add item
        console.log('new list ', this.pageCurrent.content)*/
        let items = [];
        for (let i in this.pageCurrent.content) {
          if(this.pageCurrent.content[i].columns.length>0){
            items.push(this.pageCurrent.content[i]);
          }
        }
        this.pageCurrent.content = items;
        this.$parent.theme.main.pages[this.pageCurrentIdx].content = this.pageCurrent.content; // actualizar parnet        
      }
    },
    addItem:function(evt) {
      //windoc.console.log('view page addItem', evt);
    },
    logWidget: function(evt, pageIdx, columnIdx) {
      if(evt.added){
        console.log('logWidget', evt, pageIdx, columnIdx)
        let items = [];
        let widgets = this.pageCurrent.content[pageIdx].columns[columnIdx].widgets;
        for (let i in widgets) {
          if(widgets[i].htmlCode !=null){
            items.push(widgets[i]);
          }
        }
        this.pageCurrent.content[pageIdx].columns[columnIdx].widgets=items;
        this.$parent.theme.main.pages[this.pageCurrentIdx].content = this.pageCurrent.content; // actualizar parnet 
      }
    }
	},
	watch: {
		"$parent.pageCurrent": function () {
			this.pageCurrent = this.$parent.pageCurrent;
			this.pageCurrentIdx = this.$parent.pageCurrentIdx;
		},
    getBoxContentclone: function(){
      /*if(this.getBoxContentclone!=null){
        let idx = this.$store.getters['ModApps/Sites/getBoxContentCloneNewIndex'];
        let item = this.getBoxContentclone;
        console.log('getBoxContentclone ', idx, item);
        if(idx!=null){
          this.pageCurrent.content.splice(idx, 0, item); // add item
          console.log('new list ', this.pageCurrent.content);
          this.$parent.theme.main.pages[this.pageCurrentIdx].content = this.pageCurrent.content; // actualizar parnet
        }
      }*/
    },
	},
  computed:{
    ...mapGetters("ModApps/Sites", { 
      getBoxContentclone: "getBoxContentclone",
    }),
    dragSections() {
      return {
        animation: 200,
        group: "sections",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragGrid() {
      return {
        animation: 200,
        group: "columns",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragWidgets() {
      return {
        animation: 200,
        group: "widgets",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  }
};
</script>
<style lang="scss" scoped>
.mainPage {
  position: relative;
}
.slider1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.slider1:after{content:"";background-color: rgb(0 0 0 / 20%);width: 100%;height: 100%;position: absolute;}

.opcSect{display: none;}
.section-content{
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
}
.moveSect{
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px;
  color: #aaa;
    &:hover{
      border-top: 1px dashed #aaa;
      border-bottom: 1px dashed #aaa;
      border-right: 1px dashed #aaa;
      cursor: move;
    }

}
.grip {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1170px;
}
.grip-1{
  flex: 0 0 100%;
  max-width: 100%;
  padding: 5px;
  &:hover{
  }
}
.align-center{
  justify-content: center;
  align-items: center;
  align-content: center;
}
.headerType1{
	height: 250px
}
.headerType2{
	height: 450px
}
.headerType3{
	height: 600px
}
.headerType4{
	height: 100vh;
}
h1{
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
  border: 1px dashed transparent;
  &:hover{
      border: 1px dashed #aaa;
  }
}

</style>
