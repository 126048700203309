<template>
    <div class="card">
        <div class="card-header">
            <h3 class="page-title">
                Send Contract - Sale Id
                <span class="badge badge-info">#{{ f.sales_id }}</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="col-md-12" v-if="viewCustomerData">
                <p>
                    <b>Customer Name: </b>
                    {{ f.firstname }} {{ f.lastname }}<br>
                </p>
            </div>
            <div v-if="alertEmpty" class="alert alert-warning" style="padding: 5px; margin-bottom: 20px;">
                <span>The cell phone field is empty or wrong. You must add a phone number to continue.</span>
            </div>
            <form class="row" v-if="viewForm" @submit.prevent="saveProfile()">
                <div class="col-12">
                    <div class="alert alert-danger " v-if="errorForm">
                        <strong>Error</strong> could not save, try again, if the error persists try again later.
                        <button type="button" class="close" @click="errorForm = false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="">Edit Customer Cell Phone:</label>
                        <input type="text" class="form-control" v-model="addPhone" required="true" />
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-start">
                    <button type="submit" class="btn btn-success d-flex" :disabled="btnSaveIsDisabled"
                        style="margin-right: 10px;">
                        UPDATE
                    </button>
                    <button type="button" class="btn btn-light d-flex" @click="closeForm()">
                        CANCEL
                    </button>
                </div>
            </form>
            <div v-if="isLoading">
                <form @submit.prevent="sendSMS()">
                    <div class="custom-control custom-radio">
                        <input type="radio" v-model="cellPhonecheck" :value="1" id="radio1"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="radio1">
                            Will be sent to the Client: <b> {{ f.phone }}</b>
                        </label>
                        <small style="padding-left: 10px;">
                            (
                            <a href="#" @click.prevent="isEditPhone()" title="Edit / Add Phone">
                                <span v-if="f.phone.length >= 10">Edit</span>
                                <span v-else>Add</span>
                            </a>
                            )
                        </small>
                    </div>
                    <div class="form-inline" style="padding-top: 15px; padding-bottom: 15px">
                        <div class="form-group mb-2 col-sm-5" style="padding-left: 0px;">
                            <div class="custom-control custom-radio">
                                <input type="radio" v-model="cellPhonecheck" :value="2" id="radio2"
                                    class="custom-control-input" />
                                <label class="custom-control-label" for="radio2">
                                    Other: <small style="margin-left: 10px;">(Cell Phone Number)</small>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-sm-6 mb-2" style="padding-left: 0px;">
                            <label for="otherCellPhone" class="sr-only">Cell:</label>
                            <input type="text" class="form-control" style="display: block;width: 100%;"
                                placeholder="Other cell phone" v-model="otherCellPhone"
                                :disabled="(cellPhonecheck != 2)" />
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-start">
                        <button type="submit" class="btn btn-success d-flex" :disabled="btnSendIsDisabled">
                            <i class="fas fa-sms fa-lg" style="margin-right: 5px; margin-top: 3px"></i>
                            SEND
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sendSmsContract',
    data() {
        return {
            addPhone: '',
            alertEmpty: false,
            viewForm: false,
            errorForm: false,
            isLoading: false,
            cellPhonecheck: 0,
            otherCellPhone: '',
            viewCustomerData: false,
            f: {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sales_id: 0,
                contract_id: 0,
                profile_id: 0,
                name: '',
                lastname: '',
                phone: '',
                email: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                title: '',
                descip: '',
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let t = this;
            let d = t.$store.getters['core/getOpenModal'].data;
            console.log('sendSmsContract', d)
            if (d.id) {
                t.f = {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    sales_id: d.id,
                    contract_id: d.contract_id,
                    profile_id: d.perfil.id,
                    name: d.perfil.name,
                    lastname: d.perfil.lastname,
                    phone: d.perfil.phone,
                    email: d.perfil.email,
                    address: d.perfil.address,
                    city: d.perfil.city,
                    state: d.perfil.state,
                    zip: d.perfil.zip,
                    title: d.title,
                    descip: d.descip,
                };
                t.viewCustomerData = true;
                if(t.f.phone==null || t.f.phone=='null' || t.f.phone==''){
                    t.f.phone='';
                    t.alertEmpty=true;
                    t.viewForm=true;
                }else{
                    t.isLoading = true;
                    t.alertEmpty=false;
                    t.cellPhonecheck = 1;
                }
            }
        },
        saveProfile() {
            let t = this;
            if(t.addPhone.length>0){
                t.f.phone = t.addPhone;
                t.$store.commit("core/setLoadingModal", true);
                window.master.post("salesProfileCustomerID", t.f)
                .then(response => {
                    console.log('response', response.data);
                    if (response.data.status) {
                        t.cellPhonecheck = 1;
                        t.closeForm();
                    }
                    t.$store.commit("core/setLoadingModal", false);
                })
                .catch(error => {
                    console.log('error', error);
                    t.viewForm = true;
                    t.errorForm = true;
                    t.alertEmpty = true;
                    t.$store.commit("core/setLoadingModal", false);
                });
            }
        },
        isEditPhone() {
            let t = this;
            console.log('isEditPhone')
            t.isLoading = false;
            t.errorForm = false;
            t.viewForm = true;
            t.addPhone = t.f.phone;
        },
        closeForm() {
            let t = this;
            t.isLoading = true;
            t.errorForm = false;
            t.viewForm = false;
            t.addPhone = '';
            if (t.f.phone.length < 10) {
                t.alertEmpty = true;
            } else {
                t.alertEmpty = false;
            }
        },
        sendSMS() {
            let t = this;
            console.log('sendSMS')
            t.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                title: "Sending sms",
                text: "Loading...",
                show: true,
                duration: 500
            });
            t.$store.commit("core/setLoadingModal", true);
            window.master.post("salesSendSmsContract", t.f)
            .then((response) => {
                let r = response.data.status;
                t.$store.commit("core/setLoadingModal", false);
                if (r == false) {
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        // text: "You must add mobile phone",
                    });
                } else {
                    t.$notify({
                        group: "noti",
                        title: "Send Contract",
                        type: "success",
                        text: "SMS Successful",
                    });
                    t.$parent.close();
                }
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
        },
    },
    watch: {
    },
    computed: {
        btnSendIsDisabled(){
            let t=this;
            if(t.alertEmpty){return true;}
            else if (t.cellPhonecheck == 1 && t.f.phone.length < 10) { return true; }
            else if (t.cellPhonecheck == 2 && t.otherCellPhone.length < 10) { return true; }
            else{return false;}
        },
        btnSaveIsDisabled(){
            let t=this;
            if (t.viewForm && t.addPhone.length < 10) { return false}
            else if (t.viewForm && t.addPhone.length < 10) { return true; }
            else{return false;}
        }
    }
}
</script>
<style lang="css" scoped></style>
