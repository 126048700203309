<template>
	<div class="card">
		<div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h3 class="card-title">Edit Task <span class="badge badge-success" v-if="item!=null">#{{item.idx}}</span></h3>
                </div>
            </div>
        </div>
		<div class="card-body">
            <div class="task_timer">
                <div class="daystimer">
                    <div class="days-left">
                        <span v-if="item.status_id!=2" >
                            <i class="far fa-clock"></i>
                            <span v-if="item.days>0 && item.days > item.daysBefore" class="on_time"> {{ item.days }} days left</span>
                            <span v-else-if="item.days>0 && item.days <= item.daysBefore" class="time_running_out"> {{ item.days }} days left</span>
                            <span v-else-if="item.days==0" class="today"> Due Today</span>
                            <span v-else class="overdue"> Overdue</span>
                        </span>
                        <span v-else class="completed">Completed</span>
                    </div>
                    <div class="days-before">
                        <i class="far fa-clock"></i>
                        <span class="label-time closed" v-if="item.status_id==2"> Closed</span>
                        <span class="label-time" v-else-if="item.days>=0"> {{item.daysBefore}} days before </span>
                        <span class="label-time expired" v-else-if="item.status_id<2"> Time has expired</span>
                    </div>
                </div>
                <div :class="['date', 'status'+item.status_id]">
                    <span>
                        {{ item.due_date | moment("YYYY MMM, DD")}}<br />
                        {{ new Date(item.due_date).toLocaleTimeString("en-us", optionsTime)}}
                    </span>
                </div>
                <div :class="[
                    'date',
                    (item.status_id==2) ? 'label-time closed' :
                        (item.days>=0) ? 'label-time' :
                        (item.status_id<2) ? 'label-time expired' : ''
                    ]
                ">
                    <span>
                        {{ item.schedule | moment("YYYY MMM, DD")}}<br />
                        {{ new Date(item.schedule).toLocaleTimeString("en-us", optionsTime)}}
                    </span>
                </div>
            </div>
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-gray">Task name:</th>
                    </tr>
                    <tr>
                        <th>
                            <textarea
                                class="name_input"
                                v-model="item.task_name"
                                @change="changeInput('task_name', item)"
                                @keydown="prevenirSaltosDeLinea"
                                maxlength="200"
                                :placeholder="(item.task_name!=null && item.task_name.length>0) ? 'Unnamed task' :'Task name'"
                            ></textarea>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="bg-gray" >Things to do</td>
                    </tr>
                </tbody>
                <draggable
                    v-model="item.actions"
                    tag="tbody"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMove(item, item.actions)"
                    handle=".handle"
                >
                    <template  v-for="(i, index) in item.actions">
                    <tr :key="index" :style="(i.action_status_id==3) ? 'display:none;' : ''">
                        <td>
                            <button
                                type="button"
                                class="moveAction handle"
                            >
                                <i class="bar-vertical"></i>
                            </button>
                            <div class="row">
                                <div class="form-group  col-md-9">
                                    <textarea
                                        class="name_input"
                                        v-model="i.accion_name"
                                        @change="inputActions(item, i)"
                                        @keydown="prevenirSaltosDeLinea"
                                        maxlength="200"
                                        :placeholder="(i.accion_name!=null && i.accion_name.length>0) ? 'Unnamed action' :'Action name'"
                                    ></textarea>
                                </div>
                                <div class="form-group  col-md-3">
                                    <select
                                        v-model="i.action_status_id"
                                        :class="['form-control', 'setStatus'+i.action_status_id]"
                                        @change="inputActions(item, i)"
                                    >
                                        <option :value="0" :selected="(i.action_status_id==0) ? 'true' : 'false'">Pending</option>
                                        <option :value="2" :selected="(i.action_status_id==2) ? 'true' : 'false'">Done</option>
                                        <option :value="3" :selected="(i.action_status_id==3) ? 'true' : 'false'">Delete</option>
                                    </select>
                                </div>
                                <div class="form-group  col-md-12 editarAccionesModal">
                                    <vue-editor
                                    v-model="i.accion_descrip"
                                    :editor-toolbar="customToolbar"
                                    @blur="inputActions(item, i)"
                                    class="descripcion"
                                    placeholder="Describe the procedure to follow to enforce this action."
                                    ></vue-editor>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </template>
                </draggable>
                <tfoot>
                    <tr>
                        <td :class="showForm ? 'bg-gray' : ''">
                            <form @submit.prevent="saveNewAction">
                                <div class="row" v-if="showForm">
                                    <div class="form-group  col-md-9">
                                        <textarea
                                            class="name_input"
                                            v-model="f.accion_name"
                                            @keydown="prevenirSaltosDeLinea"
                                            maxlength="200"
                                            placeholder="New Action"
                                        ></textarea>
                                    </div>
                                    <div class="form-group  col-md-3">
                                        <select
                                            v-model="f.action_status_id"
                                            class="form-control"
                                        >
                                            <option :value="0">Pending</option>
                                            <option :value="2">Done</option>
                                        </select>
                                    </div>
                                    <div class="form-group  col-md-12 editarAccionesModal">
                                        <vue-editor
                                        v-model="f.accion_descrip"
                                        :editor-toolbar="customToolbar"
                                        class="descripcion"
                                        placeholder="Describe the procedure to follow to enforce this action."
                                        ></vue-editor>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-info" v-if="!showForm" @click="addAction">New Action [+]</button>
                                <div class="row" v-if="showForm">
                                    <div class="col-md-12" style="margin-top: 20px;">
                                        <button type="submit" class="btn btn-success">Save</button>
                                        <button
                                            type="button"
                                            class="btn btn-light"
                                            @click="cancel()"
                                            style="border:1 px solid #ddd; margin-left: 10px; background-color: #fff;"
                                        >Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </td>
                    </tr>
                </tfoot>
            </table>
		</div>
	</div>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import draggable from 'vuedraggable';
    export default {
        name: "openTask",
        components: {
            VueEditor,
            draggable,
        },
        data(){
            return {
                item: null,
                isLoading: false,
                customToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ],
                f : {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    task_id: 0,
                    id:0,
                    item_order:0,
                    action_status_id:0,
                    accion_name: null,
                    accion_descrip:null,
                    date_action:null,
                },
                showForm: false,
                optionsTime: {hour12: true, hour: "2-digit", minute: "2-digit"},
            }
        },
        created(){
            let g = this.$store.getters['core/getOpenModal'];
            this.item = g.data;
            this.getActionsByTaskId();
        },
        methods: {
            getActionsByTaskId(){
                let t = this;
                t.isLoading=true;
                window.billing
                .post("getActionsTask", {
                    task_id: t.item.id,
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                })
                .then((response) => {
                    t.item.actions = response.data.r;
                    t.isLoading=false;
                    console.log('item', this.item);
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading=false;
                });
            },
            addAction(){
                let t = this;
                t.f.task_id = this.item.id;
                t.f.item_order = this.item.actions.length;
                t.showForm=true;
            },
            inputActions(item, i){
                let t = this;
                t.changeItemAction(item, i);
            },
            saveNewAction(){
                let t = this;
                var url = 'setActionsTask';
                if(t.f.id==0 && t.f.accion_name!=null && t.f.accion_name.length>0){
                    console.log(url);
                    window.billing
                    .post(url, t.f)
                    .then((response) => {
                        console.log(response.data);
                        t.cancel();
                        t.item.actions.push(response.data.r)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            },
            cancel(){
                this.showForm=false;
                this.f={
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    task_id: 0,
                    id:0,
                    item_order:0,
                    action_status_id:0,
                    accion_name: null,
                    accion_descrip:null,
                    date_action:null,
                };
            },
            changeInput(campo, i){
                let t = this;
                if(campo=='task_name'){
                    t.saveChangeInput(campo, i);
                }
                else{
                    //console.log('change', campo, i[campo])
                    t.saveChangeInput(campo, i);
                }
            },
            saveChangeInput(campo, i){
                let t = this;
                console.log('change', campo, i[campo])
                window.billing
                .post("updateTask", {
                    id: i.id,
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    campo: campo,
                    valor: i[campo],
                })
                .then((response) => {
                    console.log('updateTask');
                    localStorage.removeItem('task_name');
                })
                .catch((error) => {
                    console.log(error)
                });
            },
            changeItemAction(itemTask, itemAction){
                let t = this;
                window.billing
                .put("updateActionTask", {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    task_id: itemTask.id,
                    id: itemAction.id,
                    accion_name: itemAction.accion_name,
                    accion_descrip: itemAction.accion_descrip,
                    date_action: itemAction.date_action,
                    action_status_id: itemAction.action_status_id
                })
                .then((response) => {
                    console.log('updateActionTask', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            prevenirSaltosDeLinea(evento) {
                if (evento.keyCode === 13) {
                    evento.preventDefault();
                }
            },
            isDragging(t){ console.log('move'); return t; },
            checkMove: function(item, actions){
                actions = actions.filter((r, idx) => {
                    r.item_order = idx;
                    return r;
                });
                console.log('checkMove', actions);
                window.billing
                .put('orderActionsTask', {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    task_id: item.id,
                    itemActions: actions,
                })
                .then((response) => {
                    console.log('orderActionsTask');
                })
                .catch((error) => {
                    console.log(error);
                });
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "actions",
                    disabled: false,
                    ghostClass: "ghost",
                };
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "./editorActionsModal.css";
    .name_input{
        border: 0px;
        background-color: #fff;
        width: 100%;
        line-height: 1.1;
        height: 50px;
        color:#555;
        resize: none;
        overflow: hidden;
        border: 1px solid #ccc;border-radius: 4px;
        &:focus{
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
            color:#555;
            font-weight: normal;
        }
    }
    .bg-gray{
        background-color: #ddd;
    }
    .form-group{
        margin-bottom: 0px;
    }
    table tbody tr td {
        position: relative;
		.moveAction{
			padding: 1px 10px 1px 5px;
			font-size: 14px;
			border-radius: 2px;
    		border-top-left-radius: 2px;
    		border-bottom-left-radius: 2px;
			position: absolute;
			left: -18px;
			top: 2px;
			height: 95%;
			z-index: 1;
			margin-right: 15px;
			box-shadow: none;
			opacity: 0.2;
			color: #555;
			border:0px;
			box-shadow: none;
			background-color: transparent;
            &:hover {
				opacity: 0.8;
				cursor: move;
				background-color: #b4d6ff;
				border-color: #137eff;
			}
			i.bar-vertical{
				display: block;
				position: relative;
				border: 2px solid #555;
				border-top: 0px solid #555;
				border-bottom: 0px solid #555;
				width: 100%;
				height: 100%;
				padding: 0px 1.5px;
				&:after{
					content: "";
					position: absolute;
					z-index: 1;
					top:0px;
					right: -6px;
					width: 100%;
					height: 100%;
					display: block;
					border-left: 2px solid #555;
					border-right: 0px;
					border-top: 0px;
					border-bottom: 0px;
				}
			}
		}
    }
    select{
        text-align: center;
        &.setStatus0{background-color: #fdab3d;color:#ddd;}
        &.setStatus2{background-color: #0073ea;color:#ddd;}
        option{
            padding: 5px;
            color:#555;
            background-color: #fff;
        }
    }
    .task_timer{
        display: flex;
        width: 44%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .daystimer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .days-left{
            display: inline-flex;
            margin-right: 10px;
            font-weight: bold;
            font-size: 12px;
        }
        .days-before{
            display: inline-flex;
            font-weight: bold;
            font-size: 12px;
        }
        .on_time{color:#555;}
        .time_running_out{color: #d38019;}
        .today, .overdue{color:#e2445c;}
        .completed{color:#1010d8;}
        .label-time{
            color: #d38019;
            margin-left: 5px;
            &.expired{color: #e2445c;}
            &.closed{color: #1010d8;}
        }
        .date{
            font-size: 12px;
            color: #555;
            border-radius: 4px;
            width: 87px;
            padding: 2px 5px;
            margin-top: 5px;
            span{font-weight: bold;}
        }
    }
</style>
