<template>
    <div>
        <div class="card-header">Organizations</div>
        <div class="card-body" v-if="isLoading === true">
			<loader></loader>
		</div>
		<div class="card-body" v-else>
            <div class="card">
                <div class="card-body">              
                    <table class="table table-hover" id="tableID">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Company name</th>
                                <th scope="col">Name</th>	
                                <th scope="col">Status</th>
                                <th scope="col">Primary</th>							
                                <!-- <th scope="col">Actions</th> -->
                            </tr>
                        </thead>
                        <tbody v-if="result.length === 0">
                            <tr>
                                <th colspan="10">
                                    <div style="text-align: center">
                                        no record
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(i, index) in result" :key="index">
                                <td>{{i.id}}</td>
                                <td>{{i.bill_company_name}}</td>
                                <td>{{i.bill_firstname}} {{ i.bill_lastname }}</td>
                                <td>
                                    <span v-if="i.active == 1" class="badge badge-pill badge-success"> Active </span>
                                    <span v-else class="badge badge-pill badge-danger"> Inactive </span>								
                                </td>
                                <td>
                                    <span v-if="i.principal == 1" class="badge badge-pill badge-success"> Primary </span>
                                    <!-- {{i.principal}}						 -->
                                </td>
                                <!-- <td class="actions">
                                    <span class="cdropdown">
                                        <button><i class="fas fa-cog"></i></button>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                class="submenu" 
                                                @click="checkSubmenu($event)" 
                                            />
                                                <ul>
                                                    <li  @click="modal('editAccount', i.id_user)">
                                                        <i class="fa fa-lg fa-edit"></i> Edit
                                                    </li>
                                                    <li @click="modal('editAcount', i.id_user)">
                                                        <i class="fa fa-lg fa-check"></i> Asing App
                                                    </li>
                                                </ul>	
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <a href="{{ url('account/list/edit/' . $i->id . '/'.$i->user_id) }}" class="dropdown-item">Edit</a>
                                                    <a href="{{ url('account/apps/' . $i->user_id . '/'.$i->id) }}" class="dropdown-item">Asign Apps</a>
                                                </div>                                                							 
                                        </label>
                                    </span>
                                </td>												 -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'orgAccount',
    components: {
        Loader
    },
    data () {
        return {
            isLoading: false,
            result:[],
            user_id:''
        };
    },
    created () {
        let data = this.$store.getters['core/getOpenModal'].data;
        console.log('data modal', data);        
        this.getList(data);
    },
    methods: {
		checkSubmenu(e) {		
			let submenus = document.querySelectorAll('.' + e.target.classList[0]);
			submenus.forEach(function(i){
				if(i != e.target){
					i.checked = false;
				}
			});
		},		
        modal(component, i) {
            this.m = {
                show: true,
                component: component,
                data: i
            }  
            this.$store.commit('core/setOpenModal', this.m);          
        },	        
        getList(d) {
            this.isLoading = true;
            window.reselleR2
                .get('/account/list/' + d.userID)
                .then((response) => {
                    console.log(response.data);
                    this.result = response.data.l
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        form() {
            if (this.startnumber !== null) {
                this.$store.commit("core/setLoadingModal", true);
                window.reselleR2
                    .post('startestimate/add', {
                        user_id: window.localuserdata,
                        startnumber: this.startnumber,
                    })
                    .then((response) => {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Updated',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});                        
                    })
                    .catch((error) => {
                        console.log(error, (this.isLoading = false))
                        this.$store.commit("core/setLoadingModal", false);
                    });
            }
        },
    },
};
</script>
