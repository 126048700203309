<template>
    <div class="card-body" style="min-height: 200px">
        <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
        <div class="row" v-if="isCode1">
            <div class="form-group">
                <div class="col-sm-12">
                    <h4>WIDGET All</h4>
                </div>
            </div>
            <div class="col-sm-12">
                <textarea
                    id="codeHtml1"
                    v-show="isCode"
                    style="height: 200px; width: 100%; display: block"
                    readonly
                    onClick="this.select()"
                    v-model="codeHtml1"
                ></textarea>
            </div>
            <div class="col-12">
                <button class="btn btn-primary" @click="copyhtml(1)">
                    Copy code all
                </button>
            </div>
        </div>
        <div class="row" v-if="isCode2">
            <div class="form-group">
                <div class="col-sm-12">
                    <h4>WIDGET SLABS</h4>
                </div>
            </div>
            <div class="col-sm-12">
                <textarea
                    id="codeHtml2"
                    v-show="isCode"
                    style="height: 200px; width: 100%; display: block"
                    readonly
                    onClick="this.select()"
                    v-model="codeHtml2"
                ></textarea>
            </div>
            <div class="col-12">
                <button class="btn btn-primary" @click="copyhtml(2)">
                    Copy code slabs
                </button>
            </div>
        </div>
        <div class="row" v-if="isCode3">
            <div class="form-group">
                <div class="col-sm-12">
                    <h4>WIDGET REMANENTS</h4>
                </div>
            </div>
            <div class="col-sm-12">
                <textarea
                    id="codeHtml3"
                    v-show="isCode"
                    style="height: 200px; width: 100%; display: block"
                    readonly
                    onClick="this.select()"
                    v-model="codeHtml3"
                ></textarea>
            </div>
            <div class="col-12">
                <button class="btn btn-primary" @click="copyhtml(3)">
                    Copy code remanents
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "widgetCode",
    props: {
        type: { type: Number, required: true },
    },
    components: {
        Loader,
    },
    data() {
        return {
            urlAll: "",
            urlSlabs: "",
            urlRemnents: "",
            org_id: window.localuserdata,
            isLoading: false,
            isCode: false,
            isCode1: false,
            isCode2: false,
            isCode3: false,
            codeHtml1: "",
            codeHtml2: "",
            codeHtml3: "",
        };
    },
    created() {
        this.getUrlWidget();
    },
    methods: {
        changeTextarea() {
            var i = "";
            i += '<div id="appSlabs"></div>';
            i += '<script id="appwidget">';
            i += "var d=document,appSlabs=" + this.org_id;
            i += ',IdDiv="appSlabs",IdSlabs="widgetSlabs",';
            i += 'urlSlab="' + this.urlAll;
            i += ';s=d.createElement("script");';
            i += 's.type="text/javascript",s.id="widgetSlabsScript",';
            i += "s.defer=!0,s.src=urlSlab+appSlabs,";
            i += "t=d.querySelector('#appwidget'),t.parentNode.insertBefore(s,t);";
            i += "</" + "script>";
            this.codeHtml1 = i;

            let o = "";
            o += '<div id="appSlabs"></div>';
            o += '<script id="appwidget">';
            o += "var d=document,appSlabs=" + this.org_id;
            o += ',IdDiv="appSlabs",IdSlabs="widgetSlabs",';
            o += 'urlSlab="' + this.urlSlabs;
            o += ';s=d.createElement("script");';
            o += 's.type="text/javascript",s.id="widgetSlabsScript",';
            o += "s.defer=!0,s.src=urlSlab+appSlabs,";
            o += "t=d.querySelector('#appwidget'),t.parentNode.insertBefore(s,t);";
            o += "</" + "script>";
            this.codeHtml2 = o;
            //
            let r = "";
            r += '<div id="appSlabs"></div>';
            r += '<script id="appwidget">';
            r += "var d=document,appSlabs=" + this.org_id;
            r += ',IdDiv="appSlabs",IdSlabs="widgetSlabs",';
            r += 'urlSlab="' + this.urlRemnents;
            r += ';s=d.createElement("script");';
            r += 's.type="text/javascript",s.id="widgetSlabsScript",';
            r += "s.defer=!0,s.src=urlSlab+appSlabs,";
            r += "t=d.querySelector('#appwidget'),t.parentNode.insertBefore(s,t);";
            r += "</" + "script>";
            this.codeHtml3 = r;
        },
        getUrlWidget() {
            this.isLoading = true;
            window.SlabsV2.get("urlwiget")
                .then((response) => {
                    this.urlAll = response.data.url + "all/";
                    this.urlSlabs = response.data.url + "slabs/";
                    this.urlRemnents = response.data.url + "remanents/";
                    this.isCode = true;
                    if (this.type == 1) {
                        this.isCode2 = true;
                    } else if (this.type == 2) {
                        this.isCode3 = true;
                    } else {
                        this.isCode1 = true;
                    }
                    this.changeTextarea();
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        copyhtml(i) {
            var element = document.querySelector("#codeHtml" + i).select();
            try {
                document.execCommand("copy");
                this.$swal({
                    icon: "success",
                    title: "Code copied",
                    timer: 1000,
                });
            } catch (err) {
                console.log("unable to copy code");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
