let fillInData = function(data){
	let output =[];
	for (let i in data) {
		if(data[i]) {
			let n = {
				id : data[i].id,
				user_id : data[i].user_id,
				org_id : data[i].org_id,
				name : data[i].name,
				tlf : data[i].tlf,
				link : data[i].link,
				status : data[i].status,
				visits : data[i].visits,
				open : false,
			}
			output.push(n);
		}
	}
	return output;
};
export default fillInData;
