<template>
    <div class="card" id="note">
        <div class="card-header">
            <h3 class="page-title">
                <i class="far fa-sticky-note"></i> {{ modal.title }}
            </h3>
        </div>
        <div class="card-body">
            <form @submit.prevent="saveNote">
                <div class="form-group">
                    <label for="inputCity">Notes Admin</label>
                    <textarea
                        class="form-control"
                        v-model="noteText"
                        cols="30"
                        rows="10"
                    ></textarea>
                </div>
                <div class="row">
                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success">
                            <i class="fas fa-save"></i> SAVE
                        </button>
                        <button type="button" class="btn btn-light" style="margin-left: 10px; border:1px solid #ddd;" @click="$parent.close()">
                            <i class="fas fa-times"></i> CANCEL
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="delNote"
                            v-if="modal.edit"
                            style="margin-left: 10px"
                        >
                            <i class="fas fa-trash-alt"></i> DELETE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
// copia de apps/billing/components/NotesAdmin.vue
export default {
    name: "notesSO",
    data () {
        return {
            isLoadingModal: false,
            modal: {
                title: "Add Notes",
                edit: false,
            },
            task_id:0,
            idNote: 0,
            noteText: '',
            btn: "btn btn-secundary",
        };
    },
    created () {
        if (this.btnNotes === 1) {
            this.btn = "btn btn-warning";
        }
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data; 
            this.noteText = "";
            this.modal.edit = r.edit;  
            this.idNote = r.idNote;
            this.modal.name = "NotesModal" + r.id; 
            this.task_id = r.id;
            if(this.modal.edit){
                this.getNotes();
            }
        },
        hideModal() {
            this.noteText = "";
            this.idNote = null;
            this.modal.edit = false;
            this.modal.title = "Add Notes";
        },
        getNotes() {
            let t = this;
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('noteTask', {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    action: 'first',
                    task_id: t.task_id,
                    idNote: t.idNote,
                })
                .then((response) => {
                    //console.log(response.data.result);
                    if (response.data.result !== null) {
                        t.modal.edit = true;
                        t.modal.title = "Edit Notes";
                        t.noteText = response.data.result.note;
                    }
                    t.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    t.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },
        delNote() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            window.billing.post('noteTask', {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                action: 'setNote',
                task_id: t.task_id,
                idNote: t.idNote,
                note: '',
            }).then((response) => {
                t.isLoadingModal = false;
                t.$parent.close();        
            })
            .catch((error) => {
                t.$store.commit("core/setLoadingModal", false);
                console.log(error);
            });
        },
        saveNote() {
            let t = this
            if(t.modal.edit && t.noteText.length == 0){
                t.delNote();
            }
            else if(t.noteText.length > 0){
                t.$store.commit("core/setLoadingModal", true);
                window.billing.post('noteTask', {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    action: 'setNote',
                    task_id: t.task_id,
                    idNote: t.idNote,
                    note: t.noteText
                }).then((response) => {
                    t.isLoadingModal = false;
                    t.$parent.close();        
                })
                .catch((error) => {
                    t.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
            }
        },
    },
    computed: {},
};
</script>
<style lang="css" scoped>
#note {
    display: inline-block;
}
.textIcon {
    font-size: 10px;
}
div#note label {
    color: #888787;
}
textarea.form-control, 
textarea.form-control:focus {
    border: 1px solid #dddd;
    color: #000;
    font-size: 16px;   
}
</style>
