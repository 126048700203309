<template>
	<div class="card ">
		<div class="card-header" style="padding: 0.25rem 1.25rem;">
			<h3 style="margin-bottom: 0px;">
				<strong class="text-info" style="font-size: 32px;"> 
					Setup / Btn Call Link
				</strong>
			</h3>
			<router-link
				:to="{ name: 'MSetup' }"
				class="btn btn-info btn-sm mr-2 router-link-active"
				style="display: block;position: absolute;margin-top: -37px;right: 10px;border: 1px solid black;"
				> <i class="fas fa-arrow-left"></i>  Back
			</router-link> 
		</div>
		<div class="container-fluid card-body" style=" padding: 0px;">
			<tableBtns></tableBtns>
		</div>
		
	</div>
</template>
<script>
import tableBtns from '@/apps/Marketea/components/btnCallLink/tdynamic';
export default {
	name: 'btnCallLink',
	components: {
		tableBtns
	},
	data () {
		return {
		}
	},
}
</script>
<style lang="css" scoped>
.card.card-body{
	padding-left: 0px;
	padding-right: 0px;
}
</style>
