let validAdminApp = function() {
    let admin = false;
    this.typez = 2;
    if (window.user_type == 0) {
        admin = true;
    } else {
        for (let i in this.appRolUser) {
            if (this.appIDactual == this.appRolUser[i].app_id) {
                if (this.appRolUser[i].rol == 1) {
                    admin = true;
                }
            }
        }
    }
    if (admin) {
        //permisos de admin
        this.typez = 0;
        this.privilegies_dash = 1;
    }
};
export default validAdminApp;
