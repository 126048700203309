// import HomeAccount from '../views/HomeAccount.vue'
// Jobs
import JobsLayout from '@/apps/jobs/Layouts/JobsLayout.vue'
import JobsDashboard from '@/apps/jobs/views/dash/DashJobs.vue'
import JobsSetup from '@/apps/jobs/views/setup/JobsSetup.vue'
import To_do_lists from '@/apps/jobs/views/setup/To_do_lists.vue'
import Tags from '@/apps/jobs/views/setup/Tags.vue'
import JobOrderForm from '@/apps/jobs/views/JobOrderForm.vue'
export default [
	{
		path: '/jobs',
		folder: JobsLayout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'Jobs',
				folder: JobsDashboard
			},      
			{
				path: 'completed',
				name:'JobsComppleted',
				folder: JobsDashboard
			},
			{
				path: '/jobs/:id/:tags',
				name:'JobsTags',
				folder: JobsDashboard
			},
			{
				path: 'setup',
				name:'JobsSetup',
				folder: JobsSetup
			},
			{
				path: 'setup/to-do-lists',
				name:'To_do_lists',
				folder: To_do_lists
			},
			{
				path: 'setup/tags',
				name:'Tags',
				folder: Tags
			},
			{
				path: 'job-order-list/new',
				name:'JobOrderFormNew',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:id/:edit',
				name:'JobOrderFormEdit',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:new/:dash',
				name:'JobsRdash',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:id/:edit/:dash',
				name:'JobsEditdash',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:id/:duplicate',
				name:'JobOrderFormDuplicar',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:id/:duplicate/:dash',
				name:'JobOrderFormDuplicarDash',
				folder: JobOrderForm
			},
			{
				path: 'job-order/:id/:edit/:tag',
				name:'JobOrderFormEditFromTag',
				folder: JobOrderForm
			}
		]
	}
]
