import { render, staticRenderFns } from "./popGallery.vue?vue&type=template&id=12d266f6&scoped=true&"
import script from "./popGallery.vue?vue&type=script&lang=js&"
export * from "./popGallery.vue?vue&type=script&lang=js&"
import style0 from "./popGallery.vue?vue&type=style&index=0&id=12d266f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d266f6",
  null
  
)

export default component.exports