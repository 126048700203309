var crm = "/crm/";
export default {  
	get(){
		return crm+"getAdsList";
	},
	add(){
		return crm+"setAds";
	},
	edit(){
		return crm+"updateAds";
	},
	del(){
		return crm+"delAds";
	},
	groupName(){
		return crm+"getGroupName/";
	},
	getItemComun(){
		return crm+"getItemComun";
	},
	setItemComun(){
		return crm+"setItemComun";
	},
	delItemComun(){
		return crm+"deleteItemComun";
	},
	editItemComun(){
		return crm+"updateItemComun";
	},
};
