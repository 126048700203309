<template>
	<div>
		<table class="table table-striped table-bordered table-hover">
			<thead>
				<tr>
					<th>Date</th>
					<th>Description</th>
					<th>Account</th>
					<th>Transaction Type</th>
					<th>Amount</th>
					<th>Balance</th>
					<th>Type</th>
					<th style="text-align: center;">
						<input type="checkbox" v-model="selectAll" />
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="isLoading">
					<td colspan="8" class="text-center">
						<Loader></Loader>
					</td>
				</tr>
				<tr v-for="(i, index) in filteredBanksList" :key="index" v-else-if="unacceptedList.length > 0">
					<td>{{ i.fecha }}</td>
					<td>
						{{ i.description }}
					</td>
					<td>
						<div v-if="i.account_name" >
							{{ i.account_name }}
						</div>
						<div v-else class="text-center">----------</div>
					</td>
					<td class="text-center">----------</td>
					<td style="text-align: right;">
						<span class="text-danger" v-if="i.textRed == 1">
							${{i.amount | formatMoney}}
						</span>
						<span class="text-success" v-else-if="i.amount > 0 ">
							${{i.amount | formatMoney}}
						</span>
						<span v-else>
							${{ i.amount | formatMoney}}
						</span>
					</td>
					<td style="text-align: right;">
						${{i.balance | formatMoney}}
					</td>
					<td style="text-align: center;">
						<div v-if="i.textRed == 1">
							<span class="badge badge-danger" v-if="i.acctrans == 0" 
								@click="assingModal(i)" 
								style="background-color: #ff7500;cursor: pointer;">
								{{i.account}}
							</span>
							<span class="badge badge-danger" v-else>
								{{i.account}}
							</span>
						</div>
						<div v-else-if="i.amount == 0">
							<span class="badge badge-light">Uncategorized</span>
						</div>
						<div v-else>
							<span class="badge badge-success">
								{{i.account}}
							</span>
						</div>
					</td>
					<td style="text-align: center;">
						<input type="checkbox" v-model="selected" :value="i.id" v-if="i.acctrans == 0" />
						<span class="badge badge-danger" v-else @click="irExpense(i.expense_id)" style="cursor: pointer;">
							<i class="fas fa-times"></i>
						</span>
					</td>
				</tr>
				<tr v-else>
					<td colspan="8" style="text-align: center;">
						No record
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from "@/core/components/shared/Loader";
export default {
	name: 'tablaTransac',
	components: {
		Loader,
	},
	data () {
		return {
			unacceptedList: [],
			isLoading: false,
			selected: [],
			AccountList: [],
			Textfilter: '',
            assignExpense: {
                title: "",
                vendor: false,
                assing: false,
                isLoading: false,
	                f: {
	                id: null,
	                Vendor: null,
	                subcontractor_id: null,
	                Account: null,
	                description: null,
	                amount: 0,
	                date: new Date().toISOString().slice(0, 10),
	                typeOption: 1,
	                AccountList: [],
	            },
            },	
		}
	},
	created() {
		this.getchartaccount(2);
		let datos = this.$store.getters['ModApps/billing/getFilterBank'];
		this.listTransactions(datos);
		if(Object.keys(datos).length > 0) {
		}
	},
	methods: {
        listTransactions(datos) {
            this.isLoading = true;
            window.billing
                .post("banking/transac", datos)
                .then((response) => {
                    //console.log(response.data.result);
                    let e = response.data.result;
                    let list = this.AccountList;
                    for (let i in e) {
                        if (e[i].account_id > 0) {
                            for (let j in list) {
                                if (e[i].account_id === list[j].value) {
                                    e[i].account_name = list[j].text;
                                }
                            }
                        } else {
                            e[i].account_name = '';
                        }
                    }
                    this.unacceptedList = e;
                    console.log('unacceptedList', e)
                    let resumen = response.data.resumen;
                    this.isLoading = false;
                    let template = response.data.template;
                    let templateExcel = response.data.templateExcel;
                    console.log('url template', template, templateExcel)
                    this.$store.commit('ModApps/billing/setTemplateBanking', {csv: template, excel: templateExcel});
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getchartaccount(type) {
            window.billing
                .get("banking/account/" + window.localuserdata + "/" + type)
                .then((response) => {
                    if (response.data.result.length > 0) {
                        let AccountList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].name_account;
                            AccountList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.AccountList = AccountList;
                        this.$store.commit('ModApps/billing/setAccountListBacking', AccountList);
                        // console.log('AccountList', this.AccountList);
                    }
                })
                .catch((error) => console.log(error));
        },
		irExpense(id) {
			this.$router.push({ name: "ExpensesEdit", params: { ide: id } });
		},
        assingModal(i) {
            let t = this;
	        t.assignExpense.title = "Assign Expense";
	        t.assignExpense.assing = true;
            t.assignExpense.vendor = false;
            t.assignExpense.isLoading = true;            
            t.assignExpense.AccountList = this.AccountList;
	        t.assignExpense.f.masivo = 0;
            t.assignExpense.f.Vendor = i.vendor_id;
            t.assignExpense.f.subcontractor_id = i.vendor_id;
            t.assignExpense.f.Account = i.account_id;
            t.assignExpense.f.id = i.id;
            t.assignExpense.f.typeOption = i.type;
            t.assignExpense.f.description = i.description;
            t.assignExpense.f.amount = i.amount * -1;
        	t.assignExpense.f.date = new Date(i.fecha).toISOString().slice(0, 10);
            this.$store.commit('ModApps/billing/setAssignExpense', t.assignExpense);
        },
        assingModalClose() {
            this.$store.commit('ModApps/billing/setAssignExpense', {});
        },
	},
	watch: {
		getFilterBank: function () {
			this.listTransactions(this.getFilterBank);
		},
		getTextfilter: function () {
			this.Textfilter = this.getTextfilter
		},
		selected: function () {
			this.$store.commit('ModApps/billing/setDelItemSelected', this.selected);
		},
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
					let datos = this.$store.getters['ModApps/billing/getFilterBank'];
					if(Object.keys(datos).length > 0) {
						this.listTransactions(datos);
					} 
                }   
				this.assingModalClose();
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        'getActionRefresh': function() {
			if (Object.keys(this.getActionRefresh.data).length > 0) {
				let datos = this.$store.getters['ModApps/billing/getFilterBank'];
				if(Object.keys(datos).length > 0) {
					this.listTransactions(datos);
				} 
				this.assingModalClose();
			}
        },
	},
	computed: {
		...mapGetters("core", {
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh",
        }),
		...mapGetters("ModApps/billing", { 
			getFilterBank: "getFilterBank",
			getTextfilter: "getTextfilter"
		}),
		filteredBanksList: function () {
			let self = this;
			//fecha descripcion o monto
			return this.unacceptedList.filter(function (item) {
				if (item.description && 
					item.description
					.toLowerCase()
					.indexOf(
						self.Textfilter
						.toLowerCase()
					) >= 0
				) { return item;}
				else if (item.fecha &&
						item.fecha
						.toLowerCase()
						.indexOf(
							self.Textfilter
							.toLowerCase()
						) >= 0
					) { return item;}
				else if(item.amount &&
						item.amount
						.indexOf(self.Textfilter) >= 0
					) { return item;}				
			});
		},
		selectAll: {
			get: function () {
				return this.filteredBanksList
					? this.selected.length === this.filteredBanksList.length
					: false;
			},
			set: function (value) {
				var selected = [];
				if (value) {
					this.filteredBanksList.forEach(function (transac) {
						selected.push(transac.id);
					});
				}
				this.selected = selected;
			}
		}
	} 
};
</script>
<style lang="css" scoped>
table tbody tr td:nth-child(7) .badge {
    width: 110px;
}
</style>
