<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card" >
                    <div class="content-default-customer">
                        <div class="card-header">
                                <HeaderTransac></HeaderTransac>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div
                                    class="col-md-12 col-md-12"
                                    style="padding-right: 30px"
                                >
                                	<ResumenTransac></ResumenTransac>
                                </div>
                                <div class="col-12">
                                    <div class="filterOpt d-flex justify-content-end">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div
                                        class="tab-content py-0 px-3 px-sm-0"
                                        id="nav-tabContent"
                                    >
                                        <div
                                            class="tab-pane fade show active"
                                            id="nav-unaccepted"
                                            role="tabpanel"
                                            aria-labelledby="nav-unaccepted-tab"
                                        >
                                            <filtrosTransac></filtrosTransac>
                                            <tablaTransac></tablaTransac>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ResumenTransac from '@/apps/billing/components/banking/ResumenTransac'
import HeaderTransac from '@/apps/billing/components/banking/HeaderTransac'
import tablaTransac from '@/apps/billing/components/banking/tablaTransac'
import filtrosTransac from '@/apps/billing/components/banking/filtrosTransac'
export default {
    name: 'BankingTransac',
    components: {
        ResumenTransac,
        HeaderTransac,
        tablaTransac,
        filtrosTransac,
    },         
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            }, 
            selectBank: {
                account_type: 1,
                bank_name: "",
                account_number: "1234",
            },
            unacceptedList: [],
            loadingUnaccepted: true,
            loadingUnacceptedTable: false,
            modal: {
                title: "",
                vendor: false,
                assing: false,
                isLoading: false,
            },
            VendorList: [],
            SubcontractorList: [],
            addVendor: { company_name: null },
            AccountList: [],
            form: {
                id: null,
                Vendor: null,
                subcontractor_id: null,
                Account: null,
                description: null,
                amount: 0,
                date: new Date().toISOString().slice(0, 10),
                typeOption: 1,
            },
            file: null,
            templete: null,
            Transac: {
                isLoading: false,
                banking_id: 0,
                date: new Date().toISOString().slice(0, 10),
                type: 1,
                amount: 0.01,
                desc: "",
            },
            inputFecha: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            inputFechaEdit: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            totalIncome: 0,
            totalExpenses: 0,
            totalUnaccepted: 0,
            showModal: false,
            SelectOne: 1,
        }
    },
    created() {
        let getSelectBank = this.$store.getters['ModApps/billing/getSelectBank'];
        this.selectBank = getSelectBank;
        if(!this.selectBank.id || this.selectBank.id == 0 || !this.$route.params.idb) {
            this.$router.push({name : 'Banking'});
        } else {
            // this.filtros();
        }
    },
    methods: {
    },
    watch: {
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    if(this.getOpenModal.data.expense === true){
                        let form = this.getOpenModal.data.form
                        this.form.Vendor = form;
                        this.getVendors();
                        this.hideVendor();
                    }                    
                }   
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        'getActionRefresh': function() {
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                if(this.getActionRefresh.data.expense === true){
                    let form = this.getActionRefresh.data.form
                    this.form.Vendor = form;
                    this.getVendors();
                    this.hideVendor();
                }
            }
        },
        inputFecha: function () {
            this.formatearFecha();
            this.Transac.date = this.inputFecha;
        },
        inputFechaEdit: function () {
            this.formatearFechaEdit();
            this.form.date = this.inputFechaEdit;
        },
        dateFrom: function () {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        dateTo: function () {
            this.dateTo = new Date(
                (new Date(this.dateTo).toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
        }
    },
    computed: {
        ...mapGetters("core", {
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh",
        }),
        ...mapGetters("ModApps/billing", { getSelectBank: "getSelectBank" }),
        filteredBanksList: function () {
            var self = this;
            //fecha descripcion o monto
            return this.unacceptedList.filter(function (item) {
                return (
                    (item.description &&
                        item.description
                            .toLowerCase()
                            .indexOf(self.TextfilterBanksList.toLowerCase()) >= 0) ||
                    (item.fecha &&
                        item.fecha
                            .toLowerCase()
                            .indexOf(self.TextfilterBanksList.toLowerCase()) >= 0) ||
                    (item.amount &&
                        item.amount
                            .toLowerCase()
                            .indexOf(self.TextfilterBanksList.toLowerCase()) >= 0)
                );
            });
        },
    } 
};
</script>
<style lang="css" scoped>
.setting_btn {
    button {
        padding: 3px;
        margin-left: 8px;
    }
}

#tbanking {
    margin-bottom: 100px;
}
nav#tablasTransac > .nav.nav-tabs {
    border: none;
    color: #000;
    background: #fff;
    border-radius: 0;
}
nav#tablasTransac > div button.nav-item.nav-link,
nav#tablasTransac > div button.nav-item.nav-link.active {
    border: none;
    padding: 10px 5px;
    color: #000;
    background: #fff;
    border-radius: 0;
    border: 1px solid #ddd;
}

.tab-content {
    background: #fdfdfd;
    line-height: 25px;
    border: 0px solid #ddd;
    padding: 30px 25px;
}

nav#tablasTransac > div button.nav-item.nav-link:hover,
nav#tablasTransac > div button.nav-item.nav-link:focus,
nav#tablasTransac > div button.nav-item.nav-link.active {
    border: none;
    background: #ddd;
    color: #000;
    border-radius: 0;
    transition: background 0.2s linear;
}
.table-unaccepted table {
    min-width: 700px;
}
.table-unaccepted table thead th {
    font-weight: bold;
}
.table-unaccepted table thead th:nth-child(1),
#nav-accepted table tbody td:nth-child(1) {
    width: 100px;
}
.table-unaccepted table thead th:nth-child(3),
#nav-accepted table tbody td:nth-child(3) {
    width: 140px;
    text-align: left;
}
.table-unaccepted table thead th:nth-child(4),
#nav-accepted table tbody td:nth-child(4) {
    width: 140px;
    text-align: left;
}
.table-unaccepted table thead th:nth-child(5),
#nav-accepted table tbody td:nth-child(4) {
    width: 140px;
    text-align: right;
}
.table-unaccepted table thead th:nth-child(6),
#nav-accepted table tbody td:nth-child(5) {
    width: 140px;
    text-align: right;
}
.table-unaccepted table thead th:nth-child(7),
#nav-accepted table tbody td:nth-child(6) {
    width: 120px;
    text-align: center;
}
.table-unaccepted table thead th:nth-child(8),
#nav-accepted table tbody td:nth-child(7) {
    width: 48px;
    text-align: center;
}

.table-unaccepted table tbody tr td {
    font-size: 0.8rem;
}
.table-unaccepted {
    height: 60vh;
    overflow-y: scroll;
}
@media (min-width: 1600px) {
    .table-unaccepted {
        height: 70vh;
    }
}
@media (min-width: 1920px) {
    .table-unaccepted {
        height: 75vh;
    }
}
@media (min-width: 992px) {
    .backingList .ui.fluid.search.selection.dropdown .menu {
        max-height: 8.02857143rem !important;
    }
}
@media (max-width: 1190px) {
    .table-unaccepted {
        height: 80vh;
        overflow-x: scroll;
    }
}
@media (max-width: 992px) {
    #nav-accepted {
        overflow-x: scroll;
    }
    .table-unaccepted {
        height: 75vh;
    }
    .table-unaccepted table {
        min-width: 700px;
    }
}

@media (max-width: 767px) {
    .table-unaccepted {
        height: 55vh;
    }
}
</style>
