<template>
    <div>
        <div class="card">
            <div class="card-header">Add Customer</div>
            <div class="card-body">
                <form @submit.prevent="saveCustomer">
                    <div class="row">
                        <div class="col-md-6">
                             <div class="form-group">
                                <label for="">First Name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.firstname"
                                    required
                                />
                            </div> 
                        </div> 
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Last Name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.lastname"
                                />
                            </div>                                                  
                        </div>                    
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Business  Name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.bussinesname"
                                />
                            </div>                        
                        </div>                   
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.email"
                                />
                            </div>                       
                        </div>                  
                        <div class="col-md-6">                            
                            <div class="form-group">
                                <label for="">Cell Phone</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.cellphone"
                                />
                            </div>                       
                        </div>              
                        <div class="col-md-6">                            
                            <div class="form-group">
                                <label for="">Office Phone</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.phone"
                                />
                            </div>                       
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Address</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.address"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">                            
                            <div class="form-group">
                                <label for="">State</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.state"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">                            
                            <div class="form-group">
                                <label for="">City</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.city"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">  
                            <div class="form-group">
                                <label for="">Zip Code</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="formcustomer.zip"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="btn-group float-right mt-2" role="group">
                        <button type="submit" class="btn btn-success pull-right">
                            <i class="fas fa-save"></i> Save
                        </button>
                    </div>
                </form>
            </div>
        </div>    
    </div>
</template>
<script>
export default {
    components: { },
    name: "formCustomer",
    data () {
        return {
            formcustomer: {
                CustomerId: null,
                firstname: null,
                email: null,
                address: null,
                state: null,
                lastname: null,
                cellphone: null,
                phone: null,
                city: null,
                zip: null,
                bussinesname:null,
                error: 0,
            },
            ValuesCustomers: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                email: null,
            },            
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;            
        }, 
        saveCustomer() {
            let t = this
            t.$store.commit("core/setLoadingModal", true);
            window.billing
                .post("estimate/customer/add", {
                    user_id: window.localuserdata,
                    firstname: t.formcustomer.firstname,
                    lastname: t.formcustomer.lastname,
                    officephone: t.formcustomer.phone,
                    mobilephone: t.formcustomer.cellphone,
                    astate: t.formcustomer.state,
                    acity: t.formcustomer.city,
                    address: t.formcustomer.address,
                    email: t.formcustomer.email,
                    apostcode: t.formcustomer.zip,
                    bussinesname:t.formcustomer.bussinesname,
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status) {
                        t.$store.commit('core/setNotiPush', {
                            show: true,
                            group: "notiPush",
                            type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                            title: 'success',
                            text: 'Successful registration.',
                            duration: 3000
                        });                     
                        let lastname = t.formcustomer.lastname;
                        lastname = lastname !== null ? lastname : "";
                        var city = t.formcustomer.city;
                        city = city !== null ? city + "," : "";
                        t.ValuesCustomers = {
                            text: t.formcustomer.firstname + " " + lastname,
                            value: response.data.customerId,
                            bussinesname: "",
                            billing_address: t.formcustomer.address,
                            customer_address: t.formcustomer.address,
                            customer_name: t.formcustomer.firstname,
                            customer_lastname: lastname,
                            customer_officephone: t.formcustomer.phone,
                            customer_phone: t.formcustomer.cellphone,
                            email: t.formcustomer.email,
                            city: city,
                            state: t.formcustomer.state,
                            code: t.formcustomer.zip,
                        };                        
                        t.$store.commit("core/setLoadingModal", false);
                        t.$store.commit("core/setCloseModal", {
                            action: 'saved',
                            ValuesCustomers:t.ValuesCustomers,
                            methods:'recurring'                       
                        });    
                        t.$store.commit("core/setActionRefresh", {
                            action: 'newCustomer',
                            data: t.ValuesCustomers,                 
                        });                         
                        t.formcustomer = {
                            firstname: null,
                            lastname: null,
                            phone: null,
                            state: null,
                            city: null,
                            address: null,
                            email: null,
                            zip: null,
                            error: 0,
                        };   
                        t.$parent.close();
                    }                    
                })
                .catch((error) => {
                    console.log(error)
                    t.isLoading = false;
                    t.$store.commit("core/setLoadingModal", false);
                });
        }     
    },
    watch: {
    },
    computed: {},
}
</script>
