<template>
    <div>
        <div class="card-header d-flex" style="position: relative; align-items: center">
            <span style="font-size: 18px">Show Customer Notes</span>
        </div>
        <Loader v-if="isLoading"></Loader>
        <div v-else>
            <form @submit.prevent="add" v-if="isLoading == false">
                <div class="form-general">
                    <div
                        class="form-group"
                        style="
                            padding: 0px;
                            padding-right: 20px;
                            margin-top: 10px;
                            margin-left: 20px;
                        "
                    >
                        <label class="c-input c-radio">
                            <input
                                type="radio"
                                v-model="f.meta_value"
                                :value="f.yes"
                                :checked="f.meta_value == 1"
                            />
                            On
                        </label>
                        <label class="c-input c-radio">
                            <input
                                type="radio"
                                v-model="f.meta_value"
                                :value="f.no"
                                :checked="f.meta_value == 1"
                            />
                            Off
                        </label>
                        <br /><br />
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "ShowCustomerNotes",
    components: {
        Loader,
    },
    data() {
        return {
            btnSave: false,
            isLoading: false,
            f: {
                user_id: window.master_client,
                org_id: window.localorgdata,
                yes: 1,
                no: 0,
                meta_value: 0,
                meta_key: "show_customer_notes",
            },
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.setup;         
        this.getJobsSetting();
    },
    methods: {
        getJobsSetting() {
            this.isLoading = true;
            let url = this.apis.getSetting()
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.result;
                    this.f.meta_value = 0;
                    this.f.no = 0;
                    if (r.meta_value == 1) {
                        this.f.meta_value = 1;
                        this.f.yes = 1;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        add() {
            this.$store.commit("core/setLoadingModal", true);
            let url = this.apis.editSetting()
            window.master
                .post(url, this.f)
                .then((response) => {
                    console.log(response.data);
                    this.btnSave = false;
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'});
                    this.$parent.close();
                })
                .catch((error) => {
                    this.btnSave = false;
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },
        closeModal() {
            this.$modal.hide("modal-setting");
        },
    },
};
</script>
<style lang="scss" scoped>
label {
    display: inline-block;
    margin-bottom: 0.1rem;
}
.form-group {
    margin-bottom: 1rem;
}
label.c-input.c-radio {
    padding: 0 10px;
}
</style>
