let NotesModal = function(i) {
    let g = {
        jobsNoteNum: i.id,
        jobsNoteID: i.id_i,
        jobsNote: i.notes,
        jobsEdicion: i.edicion,
        jobsLinkDrive: i.link_drive,
        JobsIFGallery: i.gallery,
        JobsGalleryList: i.galleryList,
        JobsGalleryURL: i.url,
        JobsGallerySHOW: false,
        typez:this.typez,
        methods:"editNoteBox",
        action:"modal",
    }
    this.$store.commit("ModApps/jobs/set_jobsDash", g); 
};
export default NotesModal;
