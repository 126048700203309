<template>
	<div class="card ">
		<div class="card-header">
			<h3>Roles Setting</h3>
		</div>
		<div class="card-body">
			<div class="table-responsive">	
				<form @submit.prevent="addItem">
				<table class="table table-striped mb-0">
					<thead>
						<tr>
							<td style="width:120px;">Add new:</td>
							<td>
									<div class="input-group">
										<input type="text" class="form-control" placeholder="Name role" v-model="nomItem" required>
									</div>
							</td>
							<td>
								<button class="btn btn-success btn-md">
									Save
								</button>
							</td>
						</tr>
					</thead>
				</table>
				</form>
				<table class="table table-striped" >
					<thead>
						<tr>
							<th valign="middle">Name</th>
							<th valign="middle" class="text-left">Description</th>
							<th valign="middle" class="text-right"></th>
						</tr>
					</thead>
					<tbody v-if="NoRecord">
						<tr><td colspan="5" class="text-center">No record</td></tr>
					</tbody>
					<draggable
						v-else
						v-model="listRoles"
						tag="tbody"
						class="tbody"
						v-bind="dragOptions"
						@change="checkMove"
						@start="isDragging = true"
						@end="isDragging = false"
						handle=".handle"
					>
						<tr
							v-for="(i, index) in listRoles"
							:key="index"
						>
							<td scope="row" style="position: relative">
								<div class="btnmove handle">
									<i class="fas fa-ellipsis-v"></i>
								</div>
								<span style="margin-left: 15px">
									<input type="text" class="item-edit" placeholder="Name" v-model="i.name" @change="editItem(i)">
								</span>
							</td>
							<td>
								<textarea type="text" class="item-edit" placeholder="Description or observation" v-model="i.obs" @change="editItem(i)"></textarea>
							</td>
							<td class="text-right">
								<button class="btn btn-danger btn-sm" @click="deleteRow(i)">
									<i class="fas fa-times"></i>
								</button>
							</td>
						</tr>
					</draggable>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
	name: 'RolesSetting',   
	components: {
		draggable,
	},
	data () {
		return {
			listRoles: [],
			NoRecord: false,
			nomItem: '',
		}
	},
	created(){
		this.getRoles();
	},
	methods: {
		getRoles () {
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			t.listRoles=[];
			window.master.post('user/getroles', {
				user_id: window.master_client
			})
			.then((response) => {
				t.listRoles = response.data.roles;
				t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
			})
		},
		addItem() {
			let t= this;
			if(t.nomItem.length > 0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post('user/addroles', {
					user_id: window.master_client,
					name: t.nomItem,
					obs: '',
					orden: 0,
				})
				.then((response) => {
					if(response.data.status) {
						t.getRoles();
					}
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});	
			}
		},
		editItem(i){
			let t = this;
			if(i.name.length>0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post('user/editroles', {
					user_id: window.master_client,
					data: [i],
				})
				.then((response) => {
					console.log(response.data);
					for (let j in t.listRoles) {
						if(t.listRoles[j].id === i.id) {
							t.listRoles[j] = i;
							console.log('item', t.listRoles[j])
						}
					}
					t.$store.commit("core/setLoadingModal", false);
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});
			}
		},	
		deleteRow(i) {
			let t =this;
			t.$swal({
				title: "Delete Item?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					t.deleteItem(i);
				}
			});
		},	
		deleteItem(i){
			let t = this;
			if(i.name.length>0) {
				t.$store.commit("core/setLoadingModal", true);
				window.master.post('user/delroles', {
					user_id: window.master_client,
					data: [i],
				})
				.then((response) => {
					t.getRoles();
				})
				.catch((error) => {
					console.log(error);
					t.$store.commit("core/setLoadingModal", false);
				});
			}
		},
		checkMove(){
			let t = this;
			t.listRoles.map((item, index) => {
				item.orden = index + 1
			});
			let compPadre = t.$parent.$parent.$parent;
			window.master.post('user/editroles', {
				user_id: window.master_client,
				data: t.listRoles,
			})
			.then((response) => {
				console.log(response.data);
				t.$store.commit("core/setLoadingModal", false);
			})
			.catch((error) => {
				console.log(error);
				t.$store.commit("core/setLoadingModal", false);
			});
		},
	}, 
	computed: {
		...mapGetters("core", { getOpenModal: "getOpenModal" }),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},
}
</script>
<style lang="scss" scoped>
.btnmove {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.item-edit {
	width: calc(100% - 15px);
	border:0px solid transparent;
	box-shadow: none;
	background-color: transparent;
}
</style>
