<template>
    <div class="card">
        <div v-if="Rtoken === false">
            <div class="card-header">
                <h4 v-if="formLogin">Reseller Entry</h4>              
            </div>
            <form @submit.prevent="sendform" >
                <div class=" card-body">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="form-row" v-if="formLogin">
                                <div class="form-group col-md-12">
                                    <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                        <b>Error: </b>{{messageError}}
                                    </div>
                                    <!-- <label for="email">E-mail</label> -->
                                    <div class="password-input">
                                        <span class="showpassord " action="hide" >
                                            <i class="fas fa-user"></i>
                                        </span>
                                        <input id="email" type="email" class="form-control" name="email" v-model="userCredenctial.email" placeholder="Email" autocomplete="email" required>
                                    </div>
                                </div>
                                <div class="form-group col-md-12" style="margin-bottom: 0px;">
                                    <!-- <label for="password">Password</label> -->
                                    <div class="password-input">
                                        <span class="showpassord icon-eye" action="hide" @click="shoPass">
                                            <i class="fas fa-eye" v-if="showpassword"></i>
                                            <i class="fas fa-eye-slash" v-else></i>
                                        </span>
                                        <input id="password" :type="typepassword" class="form-control" v-model="userCredenctial.password" name="password" placeholder="Password"  autocomplete="new-password" required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" v-else>
                                <div class="form-group col-md-12">
                                    <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                        <b>Error: </b>{{messageError}}
                                    </div>
                                    <div class="alert alert-success" role="alert" v-if="messageSuccess">
                                        <b>Greath, </b>a recovery token has been sent to your account.
                                    </div>
                                    <!-- <label for="email">E-mail</label> -->
                                    <div class="password-input">
                                        <span class="showpassord " action="hide" >
                                            <i class="fas fa-user"></i>
                                        </span>
                                        <input id="email" type="email" class="form-control" name="email" v-model="userCredenctial.email" placeholder="Email" autocomplete="email" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6 note" v-if="formLogin">
                            <a href="#" @click="forgot">
                                Forgot Password? 
                            </a>
                        </div>  -->                
                    </div> 
                </div>
                <div class="card-footer">
                    <div v-if="!isLoading">
                        <button type="submit" v-if="formLogin" class="btn btn-success btn-login">Sign In</button>
                    </div>
                    <div v-else>
                        <Loader></Loader>
                    </div>
                </div>
            </form>            
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'formLogin',
    components: {
        Loader,
    },
    data () {
        return {
            messageError: null,
            userCredenctial: {
                email: '',
                password: '',
                typeU: 'reseller'
            },
            isLoading: false,
            showpassword: false,
            typepassword: 'password',
            urlPage: window.urlweb,
            sendSMS: false,
            formLogin: true,
            messageSuccess: false,
            Rtoken: false,
            formvalid: false,
            registrarTlf: false,
            codeSMS: '',
            register: {
                phone: '',
                user_idR: 0,
            },
        }
    },
    methods: {
        sendform() {
            this.isLoading = true;
            this.messageError=null;
            this.$store.dispatch('core/reseller/loginReseller', {
              email: this.userCredenctial.email,
              password: this.userCredenctial.password
            })
            .then(response => {
                if(!response){
                    this.isLoading = false;
                    console.log(response);
                    this.messageError= 'incorrect username / password';
                }else if(response==1){
                    console.log('sendCodeSMS');
                    this.sendCodeSMS();
                }else if(response==2){
                    console.log('redirect');
                    this.$store.commit('set_isLoading', true);
                    window.top.location.replace(window.self.location.href);
                }
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.messageError= 'Error de conexion';
            })            
        },
        shoPass() {
            this.showpassword = !this.showpassword;
            // console.log('password show ', this.showpassword);
            if(this.showpassword){ this.typepassword = true;}
            else{ this.typepassword= 'password';}
        },
        sendCodeSMS() {
            this.isLoading = true;
            this.messageError=null;
            this.$store.dispatch('core/session/sendSMSverify', {
              email: this.userCredenctial.email,
              typeU: this.userCredenctial.typeU,
            })
            .then(response => {
                this.isLoading = false;
                this.$parent.pingSMS = true;
                if(!response.status){
                    this.$parent.registrarTlf= true;
                }else{
                    console.log('redirec interno home'); 
                    this.$parent.registrarTlf= false;
                }
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$parent.pingSMS = false;
                this.messageError= 'Error al enviar sms';
            })
        },
    }   
};
</script>

<style lang="css" scoped>
.card {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;
    border-radius: 2px;
    margin-top:40px;
}
.card-header {
    padding: 15px 13px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
.card-header h4 {
    margin-bottom: 0px;
    color: #555;
}
.card-footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
button.btn.btn-success.btn-login {
    padding: 2px 20px;
    border-color: #031099;
    background-color: #031099;
    box-shadow:none;
}
.btn-sing {
    padding: 2px 10px;
    color: #1f1f1f ;
    background-color: #01972000 ;
    border-color: #1f1f1f ;
    border-radius: 2px;
    float: right;
}
.creat-account{
    border-color: #aaa ;
}
form label {
    display: block;
    margin-bottom: 6px;
    line-height: 19px;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    text-align: left;
    white-space: normal;
}
.form-control {
    border: 1px solid #bdbdbd;
    background-color: #fbfbfb !important;
    height: 50px;
}
.form-control:focus {
    background-color: #fbfbfb !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus 
input:-internal-autofill-selected
{
    -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
    background-color: #fbfbfb !important;
}
.showpassord{
    height: 50px;
    border-left: 1px solid;
}
.note {
    margin-top: 6px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}
.note a {
    font-size: 13px;
}
@media (max-width: 1190px){
    .btn.btn-success.btn-login,
    .btn.btn-sing.creat-account {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 40px;
        font-size: 20px;
        float: initial;
    }
    .Ajust {
        width: 74% !important; 
        margin-top: -13px !important;
    }      
}
@media (min-width: 1190px){
    .Ajust {
        width: 90px !important;
        margin-right: 15px !important;
        margin-top: -42px !important;
        position: absolute !important;
        right: 1px !important;
    }      
}

@media (max-width: 767px){
    .card {
        max-width: 330px;
        margin: auto;
    }
}
@media (max-width: 350px){
    .card {
        max-width: 300px;
        margin: auto;
    }
}
</style>
