let dashDelTag = function(d) {
    let t = this;
    // eliminar tags del job box
    if(Object.keys(d).length > 0 && d.job > 0){
        let idx = 0;
        for (let i in this.listTaks) {
            if (t.listTaks[i]) {
                if (t.listTaks[i].id_i == d.job) {
                    let itemTags = t.listTaks[i].tags;
                    for (let j in itemTags) {
                        if(itemTags[j] && itemTags[j].id_tags == d.id_tags){
                            t.listTaks[i].tags.splice(j, 1);
                            idx = i;
                            t.reEscribirLista(idx);
                        }
                    }
                }
                idx++;
            }
        }
    }
    // si estamos en filtro de tags aplicamos lo siguente
    if (t.id_tags.length > 0) {
        let ids = d.ids;
        let p = d.id_tags;
        let h = [];
        for (let k in p) {
            if (t.id_tags == p[k].id_tags) {
                h.push(true);
            }
        }
        if (h.length == 0) {
            let r = [];
            for (let k in t.listTaks) {
                if (ids != t.listTaks[k].ids) {
                    r.push(t.listTaks[k]);
                }
            }
            if (r.length > 0) {
                t.listTaks = r;
            } else {
                t.listTaks = [];
            }
        }
        if (p == "") {
            let r = [];
            for (let k in t.listTaks) {
                if (ids != t.listTaks[k].ids) {
                    r.push(t.listTaks[k]);
                }
            }
            if (r.length > 0) {
                t.listTaks = r;
            } else {
                t.listTaks = [];
            }
        }
    }
    this.reEscribirLista();
    let tag_id = this.id_tags.length > 0 ? this.id_tags[0] : 0;
    this.update_JOPending(tag_id);
    this.starGrid();
    this.$store.commit("ModApps/jobs/set_delTags", []);
};
export default dashDelTag;
