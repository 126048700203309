<template>
  <div class="contenedor_view">
    <div class="header_task">
      <div class="row">
        <div class="col-12"><h3 class="page-title">Smart Office - Task</h3></div>
      </div>
      <filterTable ref="filterTable"></filterTable>
    </div>
    <tableTask ref="tableTask"></tableTask>
    <!-- modal -->
    <modalLateral class="modal-lateral">
        <component v-bind:is="m.component" ></component>
    </modalLateral> 
    <taskActions ref="popAactions" :itemSelected="itemSelected" :setItemAction="itemAction"/>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import filterTable from '@/apps/billing/components/smartOffice/task_filter';
import tableTask from '@/apps/billing/components/smartOffice/task_table';
import formTask from '@/apps/billing/components/smartOffice/form_Task';
import listTags from '@/apps/billing/components/smartOffice/listTags';
import taskActions from '@/apps/billing/components/smartOffice/task_actions';
import NotesSO from '@/apps/billing/components/smartOffice/notesSO'
import openTask from '@/apps/billing/components/smartOffice/openTask';
import filesAttach from '@/apps/billing/components/smartOffice/filesAttach';
export default {
  components: {
    filterTable,
    tableTask,
    modalLateral,
    formTask,
    listTags,
    taskActions,
    NotesSO,
    openTask,
    filesAttach,
  },
  name: "smartO_task",
  data() {
    return {
      isLoading: true,
      btnAdd: false,
      m: {
          show: false,
          component: '',
          data: []
      },
      table_default: [
          {
              label: 'Id / Note',
              class:'id_note',
              id:'id_note',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: false
          },
          {
              label: 'Due Date',
              class:'due_date',
              id:'due_date',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: false
          },
          {
              label: 'Rec',
              class:'rec',
              id:'rec',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: true, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: true
          },
          {
              label: 'Task Name / Vendor',
              class:'name_vendor',
              id:'name_vendor',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: false,
              colspan: 1,
              optional: true
          },
          {
              label: 'Assign',
              class:'assign',
              id:'assign',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: true, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: true
          },
          {
              label: 'Tags',
              class:'category',
              id:'category',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: true, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: true
          },
          {
              label: 'Schedule',
              class:'schedule',
              id:'schedule',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: false
          },
          {
              label: 'Status',
              class:'status',
              id:'status',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: false
          },
          {
              label: 'Open',
              class:'attach',
              id:'attach',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: true
          },
          {
              label: 'Expense',
              class:'expense',
              id:'expense',
              filter: {
                  visible: false,
                  active: false,
                  orderCell: '0',
              },
              customClick: {active: false, show3puntos: false},
              visible: true,
              colspan: 1,
              optional: true
          },
      ],
      desingTableTask: [],
      paginador: 0,
      offset: 4,
      page: 1,
      pagination: {
        current_page: 1,
      },
      rowNum: 100,
      rowList: [5, 10, 20, 50, 100, 200],
      numPag: [4],
      PlanPremium : (window.OrgPlan > 1),
      statuslist: [0, 1],
      inputFilter: "",
      yearFilter: new Date().getFullYear(),
      dataList: [],
      CategoryList: [{id:1, category_name: 'None'}],
      VendorList: [],
      userlist: [],
      assignUserModal: null,
      itemSelected: {},
      itemAction: {},
      assinguser_ids: [],
      showActions: false,
      timerRefresh: null,
      currSeconds: 0,
      filterTimer: 1, //1=all, 2=today, 3=this week, 4= this month
      dateFrom: '',
      dateTo: '',
      countCompleted: 0,
    };
  },
  created(){
    let t = this;
    t.init();
  },
  mounted(){
    // detectar inactividad y consultar refresh
    let t = this;
    window.onload = t.refreshState;
    window.onmousemove = t.refreshState;
    window.onmousedown = t.refreshState;
    window.ontouchstart = t.refreshState;
    window.onclick = t.refreshState;
    window.onkeypress = t.refreshState;
		//setInterval(() => {t.rectifyData();}, 60e3);
  },
  methods: {
    showModalAction(item){
        console.log('showModalAction')
        this.showActions = true;
        item.open = 1;
        this.itemSelected = item;
    },
    resetModal(){
        this.itemSelected = {};
    },
    closeModal(){
        this.showActions = false;
        this.itemSelected = {};
        this.itemAction = {};
        this.$refs.tableTask.showModalPop = false;
    },
    init(){
      let t = this;
      t.getcategory();
      t.getUsersList();
      t.getVendors().then((r) => {
        t.getListTask();
      });
    },
    getListTask(refresh){
      let t = this;
      if(refresh==undefined){t.isLoading = true;}     
      return new Promise((resolve, reject) => { 
        window.billing
        .post("getListTask", {
          user_id: window.localorgdata,
          filter: this.inputFilter,
          status_id: this.statuslist,
          filterTimer: this.filterTimer,
          yearFilter: this.yearFilter,
          pag: this.paginador,
        })
        .then((response) => {
          //console.log('getListTask', response.data);
          let r = response.data;
          if(r.table!=null){
            t.desingTableTask = r.table;
          }else{
            t.desingTableTask = t.table_default;
          }
          t.dataList=[];
          t.countCompleted = r.completed || 0; 
          if(r.result.length>0){
            let e =r.result;
            t.fillData(e);
          }
          t.isLoading = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          t.isLoading = false;
          resolve(false);
        });
      });
    },
    fillData(arr, idItem){
      let t = this;
      let a = arr;
      for (let i in a) {
        if(a[i]){
          let e = a[i];
          let id = ((e.id+'').length==1) ? '000'+e.id : (((e.id+'').length==2) ? '00'+e.id : (((e.id+'').length==3) ? '0'+e.id : e.id));
          let item = e;
          let daysLeft= t.calcDiasDiff(new Date(), e.due_date, '-');
          let daysBefore= t.masOmenosDias(e.due_date, e.date_schedule, '-');
          let category_ids = [];
          let tagsIds = [];
          if(e.category_ids!=null && e.category_ids.length>0){
            category_ids = e.category_ids.split(',');
            tagsIds = t.CategoryList.filter(r => {
              for (let c in category_ids) {
                if(category_ids[c] == r.id){
                  return r;
                }
              }
            });
          }
          let usersAssign_id = [];
          // let usersAssign_id = [{
          //   id: t.userlist[0].id,
          //   owner: t.userlist[0].owner,
          //   firstname: t.userlist[0].firstname,
          //   lastname: t.userlist[0].lastname,
          //   email: t.userlist[0].email,
          //   pref_name: t.userlist[0].pref_name,
          //   pref_email: t.userlist[0].pref_email,
          // }];
          if(item.assing_users!=null){
            let assing_users = item.assing_users.split(',');
            for (let a in assing_users) {
              let addItem = null;
              if(assing_users[a]){
                addItem = t.userlist.find(r => r.id==assing_users[a]);
              }
              if(addItem!=null){
                usersAssign_id.push(addItem);
              }
            }
          }
          item.id= e.id;
          item.idx= id;
          item.isNote= (e.note_id>0);
          item.isRec= (e.rec>0);
          item.isAttach= (e.attach_id>0);
          item.interval= (e.interval==null) ? 0 : e.interval;
          item.days= daysLeft.d;
          item.due_date= e.due_date;
          let day_time = (e.due_date!=null) ? e.due_date.split(' ') : [];
          item.due_date_day= (day_time.length>0) ? day_time[0] : null;
          item.due_date_time= (day_time.length>0) ? day_time[1] : null;
          item.task_name= e.task_name;
          item.editName= false;
          let vendor = t.VendorList.filter((r) => { if(r.value==e.vendor_id){ return r;}})
          item.vendor= (vendor.length>0) ? vendor[0].text : 'Vendor Name';
          item.vendor_id= (vendor.length>0) ? vendor[0].value : 0;
          item.assing=usersAssign_id;
          item.category= tagsIds;
          item.open= (idItem!=undefined && idItem==e.id) ? 1 : 0;
          item.openCal= 0;
          item.menuRow= false;
          item.actions=(e.actions != undefined && (e.actions.length>0)) ? e.actions : [];
          item.isActions= e.isActions;
          item.schedule= daysBefore;
          item.daysBefore= e.date_schedule;
          item.editSchedule= false;
          item.status= e.status_id;
          item.exp_id= e.exp_id;
          item.exp_amount= (!isNaN(e.exp_amount)) ? ((e.exp_amount>0) ? t.formatUSD(t.validDecimal(e.exp_amount)) : null) : e.exp_amount;
          item.expValid= true;
          item.expMsg= '';
          t.dataList.push(item);
        }
      }
      //console.log('dataList', t.orderList())
    }, 
    orderList(){
      let t = this;
      let l = t.dataList;
      let newList = [];
      for (let a in l) {
        if(l[a]){
          for (let b in l) {
            if(l[b]){
              t.validarOrder(l[a], l[b], newList);
            }
          }
        }
      }
      console.log('newList', newList);
    },   
    validarOrder(a, b, output){
      let f = new Date();
      let item = null;
      // validar task incompletas con fecha < today
      if(a.days < 0 && a.status_id < 2 && b.days < 0 && b.status_id < 2){
        if(new Date(a.due_date) < new Date(b.due_date)){
          item=a;
        }else{
          item=b;
        }
      }
      // validar task segun days before
      else if(a.days >0 && b.days >0){
        if(a.days < b.days){
          item=a;
        }else{
          item=b;
        }
      }
      if(!output.includes(item)){
        output.push(item);
      }
    },
    validDecimal(num) {
      let t = this;
      let vatIdRegex = new RegExp(
          /^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:.([0-9]{0,3}))?$/
      );
      num = num.replace('$', '');
      num = t.quitarComas(num);
      var value = 0.0;
      if (vatIdRegex.test(num)) {
        value = num;
      }
      return Number(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
    },    
    formatUSD(mnto) {
      let ops = {
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };                                                  
      let amount = new Intl.NumberFormat('en-US', ops).format(mnto);
      return amount;
    },    
    quitarComas(special){
        let vatIdComas = new RegExp(/,/);       
        if (vatIdComas.test(special)) {
            special = special.replace(",", "");
            special = this.quitarComas(special)
        }
        return special           
    },
    calcDiasDiff(date1, date2, op){
      // console.log('calcDiasDiff: ', date1, date2);
      if(date2==undefined || date2==null){date2= new Date();}
        let dias = 0;
        //opcion con moment
        var fecha1 = moment(date1);
        var fecha2 = moment(date2);
        dias = fecha2.diff(fecha1, 'days');
        let hours = fecha2.diff(fecha1, 'hours');
        let minutes = fecha2.diff(fecha1, 'minutes');
        //if(Math.round(diff/(1000*60*60))>23){dias++;}
        let fecha = new Date(date1);
        let today = new Date(date1);
        let dueDate = new Date(date2);
        if(dias==0 || dias==-1){
          if(today.getDay() < dueDate.getDay()){
            dias = 1;
          }
          else if(hours > 0){
            dias = 0;
          }
          else if(minutes>0){
            dias = 0;
          }
          console.log('dias == 0 ', date2, dias, hours, minutes);
        }
        // else{
        //   console.log('dias diff 0 ', date2, dias, hours, minutes);
        // }
        if(op=='-'){
            fecha.setDate(fecha.getDate() - dias);
        }else{
            fecha.setDate(fecha.getDate() + dias);
        }
        return {d: dias, f: fecha};
    },
    masOmenosDias(fecha, dias, op){
        fecha = new Date(fecha);
        if(op=='-'){
            fecha.setDate(fecha.getDate() - dias);
        }else{
            fecha.setDate(fecha.getDate() + dias);
        }
        return fecha;
    },
    duplicateData(arr1){
      let arr2= [];
      for (let i in arr1) {
        if(arr1[i]){
          arr2.push(arr1[i]);
        }
      }
      localStorage.setItem('duplicateData', JSON.stringify(arr2))
    },
    autoAdd(){
      let t = this;
      t.btnAdd = true;
      let pId = t.dataList.length +1;
      let id = ((pId+'').length==1) ? '000'+pId : (((pId+'').length==2) ? '00'+pId : (((pId+'').length==3) ? '0'+pId : pId));
      let item = {
        id: pId,
        idx: id,
        isNote: false,
        isRec: false,
        interval: '',
        days: '125',
        due_date: new Date(),
        name: 'Add task '+pId,
        vendor: 'name vendor',
        category: [0],
        open: 0,
        openCal: 0,
        actions: [],
        schedule: new Date(),
        status: 0,
        expense: '125'
      };
      /*t.dataList.unshift(item);*/
      window.billing
      .post("autoAdd", {
        user_idd: window.master_client,
        user_id: window.localorgdata,
        org_id: window.localorgdata,
        filter: this.inputFilter,
        status: this.statuslist,
        pag: this.paginador,
      })
      .then((response) => {
        console.log('autoAdd', response.data);        
        t.btnAdd = false;
      })
      .catch((error) => {
        console.log(error);
        t.btnAdd = false;
      });
    },
    addTaksPop(ope, task){
        let t = this;
        let column = {};
        for (let i in t.desingTableTask) {
          if (Object.hasOwnProperty.call(t.desingTableTask, i)) {
            if(t.desingTableTask[i].id=="name_vendor"){
              column = t.desingTableTask[i];
            }            
          }
        }
        t.m = {
            show: true,
            component: 'formTask',
            data: {
                action: ope,
                item: task,
                cellVendor: column,
            }
        }
        t.$store.commit('core/setOpenModal', this.m);  
      },
    getVendors() {
        var t = this;
        return new Promise((resolve, reject) => {
          window.billing.get("vendors/" + window.localuserdata)
          .then((response) => {
            if (response.data.success) {
              let Vendor = [{value: 0, text: 'Vendor name'}];
              for (let item in response.data.result) {
                let t = response.data.result[item].company_name;
                let n = response.data.result[item].firstname;
                if (n !== null && n !== "") {
                  t = t + " - " + n;
                }
                Vendor.push({
                  value: response.data.result[item].id,
                  text: t,
                });
              }
              t.VendorList = Vendor;
              resolve(true);
            }
          })
          .catch((error) => {console.log(error); resolve(false);});
        });
      }, 
    getcategory(){
      let t = this;
      return new Promise((resolve, reject) => { 
        window.billing
        .post("getTagsAll", {
          org_id: window.localorgdata,
        })
        .then((response) => {
          //console.log('getTagsAll', response.data);
          let r = response.data;
            t.CategoryList=[];
          if(r.list.length>0){
            t.CategoryList=r.list;
          }
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          resolve(false);
        });
      });
    },    
    adminTags(){
        let t = this;
        t.m = {
            show: true,
            component: 'listTags',
            data: []
        }
        t.$store.commit('core/setOpenModal', this.m);  
    },
    getUsersList() {
      let t = this;
      let colors = t.$store.getters['core/get_paletaDeColores'];
      let url = "/users/" + window.master_client;
      window.master.post('usersListSec', {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
          let r = response.data;
          t.userlist=[{
            id: r.org.id,
            account_id: r.org.account_id,
            owner: r.org.bill_company_name,
            firstname: r.org.bill_firstname,
            lastname: r.org.bill_lastname,
            email: r.org.bill_email,
            pref_name: r.org.pref_name+' '+r.org.pref_lastname,
            pref_email: r.org.pref_email,
            classColor: (r.org.color) ? ((r.org.color!='') ? (r.org.color) : '#031099') : '#031099'
          }];
          for (let i in r.users) {
            if(r.users[i]){
              t.userlist.push({
                id: r.users[i].id,
                account_id: r.users[i].account_id,
                owner: r.org.bill_email,
                firstname: r.users[i].firstname,
                lastname: r.users[i].lastname,
                email: r.users[i].email,
                pref_name: r.users[i].pref_name+' '+r.users[i].pref_lastname,
                pref_email: r.users[i].pref_email,
                classColor: (r.users[i].color) ? ((r.users[i].color!='') ? (r.users[i].color) : colors[i]) : colors[i]
              });
            }
          }
          //console.log('usersListSec', t.userlist)
      })
      .catch((error) => {
          console.log(error);
      });
    },
    refreshAction(item, task_status){
      let t = this;
      window.billing
      .post("getActionsTask", {
          task_id: item.id,
          user_idd: window.master_client,
          user_id: window.localorgdata,
          org_id: window.localorgdata,
          oauth_m: window.oauth_m,
      })
      .then((response) => {
          console.log('getActionsTask', response.data);
          let newList = t.dataList.filter((r) => { 
              if(r.id==item.id){
                  r.open=item.open;
                  r.actions = response.data.r;
                  r.isActions = response.data.r.length;
                  console.log('item editado', r);
                  if(r.status != task_status){
                    r.status_id = task_status;
                    console.log('change task status', task_status);
                  }
              }
              else{
                  r.open=0;
              } 
              return r; 
          });
          t.dataList=[];
          t.fillData(newList, item.id);
      })
      .catch((error) => {
          console.log(error);
          t.btndisabled=false;
      });
    },
    ejecutarPushRefresh(p){
      let t = this;
      if(p.org_id==window.localorgdata){
        if(p.data.action=='newTask-refresh'){
          t.getListTask(1).then((r) => {
            if(p.oauth == window.oauth_m){
              setTimeout(() => {
                let idx = 0;
                let newItem = {};
                for (let i in t.dataList) {
                  if(p.data.result.id==t.dataList[i].id){
                    idx = i;
                    newItem=t.dataList[i];
                  }
                }
                if(p.data.result.id==newItem.id){
                  console.log('newTask abrir actiones', newItem);
                  t.$refs.tableTask.onActiveTr(idx, newItem);
                }
              }, 400); //end setTimeout
            }
          });
          /*t.duplicateData(t.dataList);
          t.dataList=[];
          let newItem = p.data.result;
          let newList = [];
          let oldArr = JSON.parse(localStorage.getItem('duplicateData'));
          let add = true;
          newList.push(newItem);
          oldArr.filter((r) => {
            newList.push(r);
          });
          t.fillData(newList, newItem.id);
          t.$store.commit('core/set_accitonPusher', null);
          setTimeout(() => {
            t.$refs.tableTask.onActiveTr(0, newItem);
            t.duplicateData(t.dataList);
            // setTimeout(() => {
            //   t.$refs.tableTask.outFocusTr();
            // }, 200)
          }, 400)*/
        }
        if(p.data.action=='taskUpdate-refresh'){
          t.duplicateData(t.dataList);
          t.dataList=[];
          let newItem = p.data.result;
          let newList = [];
          let oldArr = JSON.parse(localStorage.getItem('duplicateData'));
          oldArr.filter((r) => {
            if(r.id == newItem.id){
              r = newItem;
            }
            newList.push(r);
          });
          console.log('taskUpdate', newItem)
          t.fillData(newList, newItem.id);
          if(p.data.campo=='due_date' || (p.data.campo=="status_id" && (p.data.result.status_id==2 || p.data.result.status_id==3))){
            t.getListTask(1).then((r) => {
              if(r){
                let idxItem =null;
                t.dataList.filter((i, idx) => {if(i.id == newItem.id){idxItem =idx;} });
                if(window.oauth_m==p.data.session_id){
                  setTimeout(() => {
                    t.duplicateData(t.dataList);
                    if(p.data.campo==undefined || p.data.campo!='due_date'){
                        t.$refs.tableTask.onActiveTr(idxItem, newItem);
                        setTimeout(() => {
                        t.$refs.tableTask.outFocusTr();
                        }, 1000);
                      }
                  }, 400);
                }
              }
            });
          }
        }
        if(p.data.action=='task-tag-add'){
          let id = 'new999999'; // solo tags recien agregados
          let newTag = p.data.result;
          for (let r in t.CategoryList) {
            if(t.CategoryList[r].id==id){
              t.CategoryList[r].id = newTag.id;
              console.log('pusher tag ID', t.CategoryList[r]);
            }
          }
        }
        if(p.data.action=='task-tag-refresh'){
          let listTag = p.data.result;
          t.CategoryList=listTag;
        }
        if(p.data.action=='actionTask-refresh'){
          let itemID = p.data.task_id;
          let task_status = p.data.task_status;
          let itemUpdate = null;
          if(task_status==2){
            //si ya esta en done refrescar listado
            t.getListTask(1);
          }else{
            // sino solo actualizar item
            t.dataList.filter((r) => {
              if(r.id == itemID){
                itemUpdate = r;
              }
            });
            if(itemUpdate!=null && itemUpdate.open==1){
              task_status=(task_status==undefined || task_status==null) ? itemUpdate.status_id : task_status;
              console.log('actionTask-refresh', itemUpdate.open)
              t.refreshAction(itemUpdate, task_status);
            }
          }
        }
        // limpiar pusher
        t.$store.commit('core/set_accitonPusher', null); 
        localStorage.removeItem('refreshState');
        localStorage.removeItem('pushRefresh');
        t.refreshState();
      }
    },
    refreshState(){
      let t = this;
      // Borrar el intervalo anterior
      clearInterval(t.timerRefresh);
      // Restablecer los segundos del temporizador
      t.currSeconds = 0;
      // Establecer un nuevo intervalo
      t.timerRefresh = setInterval(t.startIdleTimer, 1000);
    },
    startIdleTimer() {
      let t = this;
      // Incrementar segundos sin activida
      t.currSeconds++;
      if((t.currSeconds % 5)==0){
        //console.log('tiempo sin actividad', t.currSeconds);
        t.validPusher();
      }
    },
    validPusher(){
      let t = this;
      //consultar si hay pusher pendiente  
      if(localStorage.getItem('refreshState')=='pending'){
        let p = (localStorage.getItem('pushRefresh')!=null) ? JSON.parse(localStorage.getItem('pushRefresh')) : null;
        if(p != null){
          console.log('ejecutarPushRefresh', p)
          localStorage.setItem('refreshState', 'in progress');
          t.ejecutarPushRefresh(p);
        }else{
          console.log('fail pushRefresh', p)
        }
      }
    },
    actionNotes(task_id, isNote, note_id) {
      let t = this;
      let edit = false
      if (isNote){edit = true}
      this.m = {
        show: true,
        bill: true,
        component: 'NotesSO',
        data: {
          id: task_id, 
          type:"SO",
          edit: edit,
          idNote: note_id
        }
      };
      this.$store.commit('core/setOpenModal', this.m); 
    },
		open_task(i){
			let t = this;
			t.m = {
				show: false,
				component: 'openTask',
				data: i
			}
			t.$store.commit('core/setOpenModal', this.m);  
		},
		open_filesAttach(i){
			let t = this;
			t.m = {
				show: true,
				component: 'filesAttach',
				data: i
			}
			t.$store.commit('core/setOpenModal', this.m);  
		},
		rectifyData(){
			let t = this;
			console.log('rectificar datos')
			t.duplicateData(t.dataList);
			let list = JSON.parse(localStorage.getItem('duplicateData'));
			t.fillData(list);
		},
  },
  watch: {
    "accitonPusher": function(){
      let t = this;
      let p = t.accitonPusher;
      if (p!=undefined && p!=null && p.action=='onlySO'){
        console.log('pusher', p);
        localStorage.setItem('refreshState', 'pending');
        localStorage.setItem('pushRefresh', JSON.stringify(p));
        if(window.oauth_m==p.oauth){
          t.validPusher();
        }
      }
    },
    'getOpenModal': function() {
      if (!this.getOpenModal.show) {
        this.getVendors();
        this.m = {
            show: false,
            component: '',
            data: {}
        }
      }
    },
  },
  computed: {    
    ...mapGetters("core", {
      getOpenModal: "getOpenModal",
      getActionRefresh: "getActionRefresh",
      accitonPusher: "get_accitonPusher",
    }),
  }
};
</script>
<style lang="scss" scoped>  
  .contenedor_view{
    position: relative;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 20px;
    border-top: 0px solid #ccc;
    height: calc(100vh - 55px);
    min-height: calc(100vh - 55px);
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -10px;
    background-color: #fff;
    .page-title{
      padding-left: 15px;
    }
  }
</style>
