var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"50px"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveEstimates()}}},[_c('div',{staticClass:"row"},[(_vm.errors.length)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('ul',{staticStyle:{"margin-bottom":"0"}},_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]):_vm._e()]),_vm._m(0),_c('div',{staticClass:"row",staticStyle:{"background-color":"#f1f1f1","padding":"8px","margin-bottom":"12px"}},[_c('div',{staticClass:"col-2"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formEstimates.status),expression:"formEstimates.status"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formEstimates, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":1}},[_vm._v(" Pending ")]),_c('option',{domProps:{"value":2}},[_vm._v(" Accepted ")]),_c('option',{domProps:{"value":3}},[_vm._v(" Rejected ")])])]),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Accepted By")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.formEstimates.accepted_by
                        ),expression:"\n                            formEstimates.accepted_by\n                        "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                            _vm.formEstimates.accepted_by
                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formEstimates, "accepted_by", $event.target.value)}}})]),_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Accepted Date")]),_c('datetime',{attrs:{"input-class":"form-control","value-zone":"America/New_York"},model:{value:(
                            _vm.formEstimates.accepted_date
                        ),callback:function ($$v) {_vm.$set(_vm.formEstimates, "accepted_date", $$v)},expression:"\n                            formEstimates.accepted_date\n                        "}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{staticStyle:{"position":"relative","width":"100%","display":"block"},attrs:{"for":"inputEmail4"}},[_vm._v("Customer ")]),_c('div',{staticClass:"form-group",attrs:{"id":"CustomersList"}},[_c('model-select',{attrs:{"options":_vm.CustomersList,"placeholder":"Choose a Customer"},model:{value:(_vm.ValuesCustomers),callback:function ($$v) {_vm.ValuesCustomers=$$v},expression:"ValuesCustomers"}})],1)]),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Estimate Name (Optional) ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.formEstimates.estimates_optional
                        ),expression:"\n                            formEstimates.estimates_optional\n                        "}],staticClass:"form-control",attrs:{"type":"text","maxlength":"50"},domProps:{"value":(
                            _vm.formEstimates.estimates_optional
                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formEstimates, "estimates_optional", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Bill To")]),_c('div',{staticClass:"form-group bill_to"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.customer_name)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.customer_lastname)+" ")])]),(_vm.ValuesCustomers.bussinesname!=null && _vm.ValuesCustomers.bussinesname!='' && _vm.ValuesCustomers.bussinesname!='null')?_c('div',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.bussinesname)+" ")]):_vm._e(),(_vm.ValuesCustomers.customer_address!='')?_c('div',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.billing_address)+" ")]):_vm._e(),(_vm.ValuesCustomers.city!=null ||
                            _vm.ValuesCustomers.state!=null ||
                            _vm.ValuesCustomers.code!=null 
                            )?_c('div',[(_vm.ValuesCustomers.city!=null)?_c('span',[_vm._v(_vm._s(_vm.ValuesCustomers.city))]):_vm._e(),(_vm.ValuesCustomers.state!=null)?_c('span',[_vm._v(_vm._s(_vm.ValuesCustomers.state))]):_vm._e(),(_vm.ValuesCustomers.code!=null)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.ValuesCustomers.code!=null)?_c('span',[_vm._v(_vm._s(_vm.ValuesCustomers.code))]):_vm._e()]):_vm._e(),_c('div',[(_vm.ValuesCustomers.customer_phone!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.customer_phone)+" ")]):_vm._e(),(_vm.ValuesCustomers.customer_officephone!=null)?_c('span',[(_vm.ValuesCustomers.customer_phone!=null)?_c('span',[_vm._v(" / ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.ValuesCustomers.customer_officephone)+" ")]):_vm._e()]),(_vm.ValuesCustomers.email!=null)?_c('div',[_vm._v(" "+_vm._s(_vm.ValuesCustomers.email)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-5"},[_c('label',{attrs:{"for":"job_information"}},[_vm._v("Job information")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.formEstimates.job_information
                        ),expression:"\n                            formEstimates.job_information\n                        "}],staticClass:"form-control",attrs:{"name":"","id":"job_information","cols":"30","rows":"2"},domProps:{"value":(
                            _vm.formEstimates.job_information
                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formEstimates, "job_information", $event.target.value)}}})]),_c('div',{staticClass:"col-2"},[_c('label',{attrs:{"for":"datedoc"}},[_vm._v("Date")]),_c('datetime',{attrs:{"id":"datedoc","type":"date","input-class":"form-control","value-zone":"America/New_York"},model:{value:(_vm.inputFecha),callback:function ($$v) {_vm.inputFecha=$$v},expression:"inputFecha"}})],1)]),_c('div',{staticClass:"row",staticStyle:{"margin":"0px","padding-left":"10px","padding-right":"10px"}},[_c('tableProductos',{attrs:{"prodnew":true}})],1),_c('div',{staticClass:"blockMont",staticStyle:{"display":"flex","justify-content":"space-between","width":"100%"}},[_c('div',{staticStyle:{"width":"50%"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.formEstimates.customer_message
                    ),expression:"\n                    formEstimates.customer_message\n                    "}],staticClass:"form-control",attrs:{"name":"","id":"","cols":"30","rows":"4","placeholder":"Customer message"},domProps:{"value":(
                    _vm.formEstimates.customer_message
                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formEstimates, "customer_message", $event.target.value)}}})]),_c('div',{staticStyle:{"width":"50%"}},[_c('tableResumenTotales',{attrs:{"form":_vm.formEstimates}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"pageSignature"},[_c('div',{staticClass:"form-inline",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('div',{staticClass:"my-1 mr-2"},[_vm._v(" Need a Contract? ")]),_c('div',{staticClass:"custom-control custom-checkbox my-1 mr-sm-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                _vm.formEstimates.checked
                                                            ),expression:"\n                                                                formEstimates.checked\n                                                            "}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"},domProps:{"checked":Array.isArray(
                                                                _vm.formEstimates.checked
                                                            )?_vm._i(
                                                                _vm.formEstimates.checked
                                                            ,null)>-1:(
                                                                _vm.formEstimates.checked
                                                            )},on:{"change":function($event){var $$a=
                                                                _vm.formEstimates.checked
                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formEstimates, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formEstimates, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formEstimates, "checked", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v("Yes")])]),(_vm.formEstimates.checked)?_c('div',{staticClass:"form-group",staticStyle:{"margin-right":"5px","margin-left":"15px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                _vm.selectCTemplate.title
                                                            ),expression:"\n                                                                selectCTemplate.title\n                                                            "}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Terms & Conditions"},domProps:{"value":(
                                                                _vm.selectCTemplate.title
                                                            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectCTemplate, "title", $event.target.value)}}})]):_vm._e(),(_vm.formEstimates.checked && _vm.PlanPremium)?_c('div',{staticClass:"my-1 mr-2"},[_vm._v(" Request Digital Signature? ")]):_vm._e(),(_vm.formEstimates.checked && _vm.PlanPremium)?_c('div',{staticClass:"custom-control custom-checkbox my-1 mr-sm-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                _vm.formEstimates.signe
                                                            ),expression:"\n                                                                formEstimates.signe\n                                                            "}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"signe"},domProps:{"checked":Array.isArray(
                                                                _vm.formEstimates.signe
                                                            )?_vm._i(
                                                                _vm.formEstimates.signe
                                                            ,null)>-1:(
                                                                _vm.formEstimates.signe
                                                            )},on:{"change":function($event){var $$a=
                                                                _vm.formEstimates.signe
                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formEstimates, "signe", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formEstimates, "signe", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formEstimates, "signe", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"signe"}},[_vm._v("Yes")])]):_vm._e()]),(_vm.formEstimates.checked)?_c('vue-editor',{model:{value:(_vm.selectCTemplate.description),callback:function ($$v) {_vm.$set(_vm.selectCTemplate, "description", $$v)},expression:"selectCTemplate.description"}}):_vm._e(),(_vm.formEstimates.checked)?_c('h5',{staticStyle:{"padding-top":"20px"}},[_vm._v(" Signature: ")]):_vm._e(),(_vm.formEstimates.checked)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":""}},[_vm._v("Name")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                        _vm.ValuesCustomers.text
                                                                    ),expression:"\n                                                                        ValuesCustomers.text\n                                                                    "}],staticClass:"form-control",attrs:{"type":"text","id":""},domProps:{"value":(
                                                                        _vm.ValuesCustomers.text
                                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ValuesCustomers, "text", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":""}},[_vm._v("Date")]),_c('div',{staticClass:"col-sm-10"},[_c('datetime',{attrs:{"type":"date","value-zone":"America/New_York","input-class":"form-control"},model:{value:(
                                                                        _vm.FormSignature.dateCustomer
                                                                    ),callback:function ($$v) {_vm.$set(_vm.FormSignature, "dateCustomer", $$v)},expression:"\n                                                                        FormSignature.dateCustomer\n                                                                    "}})],1)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":""}},[_vm._v("Name")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                        _vm.FormSignature.nameCompany
                                                                    ),expression:"\n                                                                        FormSignature.nameCompany\n                                                                    "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                                                                        _vm.FormSignature.nameCompany
                                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.FormSignature, "nameCompany", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":""}},[_vm._v("Date")]),_c('div',{staticClass:"col-sm-10"},[_c('datetime',{attrs:{"type":"date","value-zone":"America/New_York","input-class":"form-control"},model:{value:(
                                                                        _vm.FormSignature.dateCompany
                                                                    ),callback:function ($$v) {_vm.$set(_vm.FormSignature, "dateCompany", $$v)},expression:"\n                                                                        FormSignature.dateCompany\n                                                                    "}})],1)])])]):_vm._e()],1)]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 align-self-center"},[_c('h3',[_vm._v("Add Estimate")])]),_c('div',{staticClass:"col-6 text-right"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"margin-bottom":"0.5rem"},attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" SAVE ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",staticStyle:{"margin-top":"50px"}},[_c('div',{staticClass:"col-6 align-self-center"}),_c('div',{staticClass:"col-6 text-right"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"margin-bottom":"0.5rem"},attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" SAVE ")])])])}]

export { render, staticRenderFns }