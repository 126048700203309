let getJobsAllSetting = function() {
    let url = this.apis.getJobsAllSetting()
    window.master
        .post(url, this.f)
        .then((response) => {
            let r = response.data.result;
            for (const i in r) {
                switch (r[i].meta_key) {
                    case "show_customer_name":
                        this.show_customer_name = r[i].meta_value;
                        break;
                    case "show_invoice":
                        this.show_invoice = r[i].meta_value;
                        break;
                    case "privilegio_account":
                        this.typez = r[i].meta_value;
                        if (this.typez > 0) {
                            this.privilegies_dash = this.typez;
                        }
                        break;
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
export default getJobsAllSetting;
