import { ModelSelect } from 'vue-search-select'
import Loader from "@/core/components/shared/Loader.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import optiones from '@/apps/Marketea/components/Ads/optiones'
import tfilters from '@/apps/Marketea/components/Ads/tdynamicFilters'
export default {
	Loader,
	ModelSelect,
	modalLateral,
	optiones,
	tfilters,
};
