<template>
    <div>
        <div class="card-header">START INVOICE</div>
        <div class="card-body">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <form @submit.prevent="MetSAddStartInvoice">
                <div class="form-row">
                    <div class="col-12">
                        <span id="emailHelp" class="form-text" v-if="disabled">
                            Start Number</span
                        >
                        <span id="emailHelp" class="form-text" v-else>
                            Number: Example <b>001</b></span
                        >
                        <input
                            type="text"
                            class="form-control"
                            v-model="startnumber"
                            :disabled="disabled"
                            required
                        />
                    </div>
                    <div class="col-12">
                        <button
                            type="submit"
                            class="btn btn-success"
                            :disabled="disabled"
                        >
                            UPDATE
                        </button>
                    </div>
                </div>
            </form>
            <div></div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader';
import { mapGetters } from 'vuex';
export default {
    components: {
        Loader,
    },
    data () {
        return {
            isLoading: false,
            startnumber: null,
            disabled: false,
        };
    },
    created () {
        this.getStart();
    },
    methods: {
        getStart () {
            this.isLoading = true;
            window.billing
                .get('startinvoice/' + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.result) {
                        this.startnumber = response.data.result.meta_value;
                    }
                    if (response.data.update == false) {
                        this.disabled = true;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },

        MetSAddStartInvoice () {
            if (this.startnumber !== null) {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                    .post('startinvoice/add', {
                        user_id: window.localuserdata,
                        startnumber: this.startnumber,
                    })
                    .then((response) => {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Updated',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});  
                    })
                    .catch((error) => {
                        this.$store.commit("core/setLoadingModal", false);
                        console.log(error, (this.isLoading = false))
                    });
            }
        },
    },
    computed: {},
};
</script>
