let iniMenuAction = function() {
	let t = this;
	var elemento = document.querySelectorAll('.menuActions button');
	if(elemento.length > 0) {
		document.onclick = function (e) {
			e = e || event
			var target = e.target || e.srcElement
			let valid = false;
			for (let i in elemento) {
				if (elemento.hasOwnProperty(i)) {
					if(elemento[i]) {
						if (elemento[i] == target) {
							// click dentro del elemento
							valid = true;
						} // click fuera del elemento
					}
				}
			}
			if(!valid) {					
				// console.log('no click', target);
				this.isCloseMenuAction = null;
				t.closeAll(this.isCloseMenuAction);
			}
		};
	}
};
export default iniMenuAction;
