<template>
    <div class="">
      <form @submit.prevent="setCalLogo" id="form1" >
        <div class="card-header">Header Customization</div>
        <div class="card-body" style="padding-top: 0px;">
            <div class="logoapp">
                <div style="margin-top: 10px">
                    <img
                        v-bind:src="preview"
                        id="logoPreview"
                        @click="$refs.logo.click()"
                        style="width: 100%; max-width: 600px"
                        alt="Logo"
                        v-if="Showlogo"
                    />
                    <img
                      v-else
                        src="@/core/assets/no-image.png"
                        id="logoPreview"
                        @click="$refs.logo.click()"
                        style="width: 100%; max-width: 600px"
                        alt="Logo"
                    />
                </div>
              <div class="btnupload">
                  <input
                      type="file"
                      style="display: none"
                      ref="logo"
                      accept="image/*"
                      @change="filelogo"
                  />
                  <button
                      type="button"
                      class="btn btn-dark"
                      @click="$refs.logo.click()"
                  >
                      <i class="fas fa-upload"></i> Upload Logo
                  </button>
                  <button
                      type="button"
                      class="btn btn-danger"
                      @click="delLogo"
                      style="width: 300px; text-align: center; display: block;"
                      v-if="Showlogo"
                  >
                      <i class="fa fa-trash"></i> Delete
                  </button>
              </div>
            </div>
            <div class="form-group  col-md-12">
              <label for="description">Description: </label>
              <vue-editor
                  id="description"                        
                  :editor-toolbar="customToolbar"
                  v-model="f.desc"
                  style="width: 100%; height: 120px; "
                  required="true"
              ></vue-editor><br><br>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-start" style="margin-top: 80px;">
            <button type="submit" class="btn btn-success btn-lg">SAVE</button>
        </div>
      </form>
    </div>
</template>
<script>
  import { VueEditor } from 'vue2-editor'
export default {
  name: 'RoofCalLogo',
  components: {
    VueEditor
  },
  data() {
    return {
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        meta_key: 'RoofCalLogo',
        logo: null,
        dellogo: false,
        logoOLD: null,
        desc: '',
      },
      apis: {},
      Showlogo: false,
      preview: null,
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4] }],
          ["bold", "italic", "underline", "strike"], 
          ["link"],
          ["clean"] // remove formatting button
      ],
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getCalLogo();
  },
  methods: {
    getCalLogo(){
      let t = this;
      t.$store.commit("core/setLoadingModal", true);
      window.master.post(this.apis.getCalLogo(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
        meta_key: 'RoofCalLogo',
      })
      .then((response) => {
        if(response.data.status){
          t.f.logo = response.data.r.logo;
          t.f.desc = response.data.r.desc;
          if(t.f.logo!=null){
            t.preview = response.data.url + t.f.logo;
            t.f.logoOLD = response.data.r.logo;
            t.Showlogo = true;
          }
        }
        t.$store.commit("core/setLoadingModal", false);
      })
      .catch((error) => {
        console.log(error);
        t.$store.commit("core/setLoadingModal", false);
      });
    },
    setCalLogo(){
      let t = this;
      t.$store.commit("core/setLoadingModal", true);
      let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
      let formData = new FormData();
      if(t.f.logo!=null){
        formData.append("logo", t.f.logo[0]);
      }else{
        formData.append("logo", null);
      }
      formData.append("nombreIMG", "logo");
      formData.append("contIMG", 1);
      formData.append("user_id", window.master_client);
      formData.append("org_id", window.localorgdata);
      formData.append("folder", 'roofcal');
      formData.append("delIMG", "logo");
      formData.append("contDel", 0);
      formData.append("dellogo", t.f.dellogo);
      formData.append("logoOLD", t.f.logoOLD);
      formData.append("desc", t.f.desc);
      window.master
      .post(this.apis.setCalLogo(), formData, fileSendHeader)
      .then((response) => {
          console.log('setCalLogo', response.data)
          t.$store.commit("core/setLoadingModal", false);
          t.$refs.logo.value=null;
          this.$notify({
            group: "noti",
            title: "Save",
            type: "success",
            text: "Save Successful",
          });
          this.$store.commit("core/setCloseModal", true);
      })
      .catch((error) => {
          console.log(error);
          t.$refs.logo.value=null;
          t.$store.commit("core/setLoadingModal", false);
      });
    },
    filelogo(event) {
      this.f.logo = event.target.files;
      this.Showlogo = true;
      this.validlogo = true;
      this.f.dellogo = 0;
      if (event.target.files && event.target.files[0]) {
        if (this.f.logoOLD != null) {
          this.f.dellogo = 1;
        }
        var reader = new FileReader();
        console.log(this.Showlogo, "logo ");
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
            document.querySelector("#logoPreview").src = e.target.result;
            this.preview = e.target.result;
        };
      }
    },    
    delLogo() {
        this.f.dellogo = 1;
        this.f.logo = null;
        this.Showlogo = false;
        this.validlogo = false;
        this.preview = "";
        this.$refs.logo.value = "";
        document.querySelector("#logoPreview").src ="#";
        console.log(this.Showlogo, "logo ");
        if (this.f.logoOLD != null) {
          this.setCalLogo();
        }
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logoapp {
    width: 300px;
    margin: auto;
}
button.btn.btn-dark {
    width: 300px;
}
</style>
