<template>
	<div class="container">
		<div class="row"  v-if="isLoading">
			<div class="col-md-12"><Loader></Loader></div>
		</div>
		<div class="row" v-else>
			<div class="col-md-3"></div>
			<div class="col-md-6"  style="padding-left: 0px; padding-right: 0px;" >
				<div class="card card-body"  v-if="urlIsValid" style="padding-left: 0px; padding-right: 0px;">
					<div class="alert alert-success" v-if="isSaved">
						<strong>Saved!</strong> {{ msj }}. <br>
						<router-link :to="{ name: 'Login' }">
							Back
						</router-link>
					</div>
					<component v-bind:is="m.component"></component>
				</div>
				<div class="alert alert-danger" v-else style="margin-top:20px;" >
					<strong>Error!</strong> {{ msj }}. <br>
					<router-link :to="{ name: 'Login' }">
						Back
					</router-link>
				</div>
			</div>
			<!-- <div class="col-md-6" v-else>
				<div class="alert alert-danger" role="alert">
					<strong>This invoice was void</strong>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import formSquarepay from '@/apps//billing/components/invoices/formSquarepay'
export default {
	name: 'SquarePay',
	components: {
		Loader,
		formSquarepay
	},
	data () {
		return {
			urlIsValid: false,
			isSaved: false,
			isLoading: false,
			msj: null,
			m: {
				show: true,
				component: 'formSquarepay',
				data: {}
			},
			ip: null,
			userAgent: null,
		}
	},
	created() {
		this.validDatos();
	},
	methods: {
		validDatos(){
			if (
				this.$route.params.monto &&
				this.$route.params.oauth &&
				this.$route.params.user_id &&
				this.$route.params.org_id &&
				this.$route.params.typeUser &&
				this.$route.params.customer_id &&
				this.$route.params.invoice_id
				) {
				let t = this;
				t.isLoading = true;
				this.InvoiceById(t);
			}
		},
		InvoiceById(t) {
			window.apiv2.post("invoicebyID", 
			{
				id: this.$route.params.invoice_id,
				customer_id: this.$route.params.customer_id
			}).then((response) => {
				if(response.data.status) {
					if(
						this.$route.params.monto > response.data.balance ||
						this.$route.params.monto > response.data.total
					) {
						t.urlIsValid = false;
						t.msj = 'the amount cannot be greater than the balance';
						console.log('monto mayor q balance');
					} else {
						t.ip = response.data.ip;
						t.userAgent = response.data.userAgent;
						t.m = {
							show: false,
							component: 'formSquarepay',
							data: {
								user_id: this.$route.params.user_id,
								org_id: this.$route.params.org_id,
								invoice_id: response.data.result.id,
								numdoc: response.data.result.numdoc,
								total: response.data.result.total,
								customer_id: response.data.customer.id,
								cname: response.data.customer.firstname,
								clname: response.data.customer.lastname,
								cbname: response.data.customer.bussinesname,
								balance: t.$route.params.monto,
								balanceText: response.data.balance,
							}
						};
						t.urlIsValid = true;
						t.$store.commit('core/setOpenModal', this.m);
					}
				} else {
					t.urlIsValid = false;
					console.log('consulta null');
				}
				if(response.data.result!=null && response.data.result.idxstatus > 2){
					t.msj = 'This invoice was void';
					t.urlIsValid = false;
				}
				t.isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				t.urlIsValid = false;
				t.isLoading = false;
			});
		},
	},
    watch: {
		accitonPusher: function () {
			if (this.accitonPusher != undefined) {
				let p = this.accitonPusher;
				console.log('accitonPusher', p);
				if (p.action && p.action == 'refresh-invo' && p.ip === this.ip && p.userAgent === this.userAgent) {
					this.isSaved = true;
					this.m.savedFormExterno=true;
					this.$store.commit('core/setOpenModal', this.m);
				}
			}
		}
	},
	computed: {
		...mapGetters("core", {
			accitonPusher: "get_accitonPusher",
		}),
	}
};
</script>
<style lang="css" scoped>
</style>
