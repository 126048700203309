let beforeCloseModalEditCusInv = function() {
    this.sub_id = 0;
    this.customer_id = 0;
    this.invoice_id = 0;
    this.sub = [];
    this.customer = [];
    this.invoice = [];
    this.editCusInv = false;
    this.$store.commit("ModApps/jobs/set_editCusInv", {});
};
export default beforeCloseModalEditCusInv;
