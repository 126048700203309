<template>
    <div>
        <div class="card">
            <div class="card-header">
                {{ modal.title }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" v-if="modalError">
                    {{ modalErrorMjs }}
                </div>
                <div v-if="modal.bankingTransac">
                    <div class="row">
                        <div :class="columna">
                            <div class="form-inline" style="flex-wrap: nowrap">
                                <div class="form-group mb-2">
                                    <select class="form-control" v-model="SelectOne">
                                        <option value="1">This Year</option>
                                        <option value="2">Last Year</option>
                                        <option value="3">This Month</option>
                                        <option value="4">Calendar</option>
                                    </select>
                                </div>
                                <div class="form-group mb-2" v-if="SelectOne == 4">
                                    <div style="display: flex; align-items: center">
                                        <span
                                            style="
                                                padding-right: 4px;
                                                padding-left: 8px;
                                            "
                                            ><b>From:</b></span
                                        >
                                        <span class="inputDateFilter">
                                            <datetime
                                                type="date"
                                                v-model="dateFrom"
                                                format="MMM dd, yyyy"
                                                input-class="form-control"
                                            ></datetime>
                                        </span>
                                        <span
                                            style="
                                                padding-right: 4px;
                                                padding-left: 8px;
                                            "
                                            ><b>To:</b></span
                                        >
                                        <span class="inputDateFilter">
                                            <datetime
                                                type="date"
                                                v-model="dateTo"
                                                format="MMM dd, yyyy"
                                                input-class="form-control"
                                            ></datetime>
                                        </span>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-success mb-2"
                                    @click="filtros()"
                                    style="margin-left: 10px"
                                >
                                    <i class="fas fa-search"></i> Filter
                                </button>
                            </div>
                        </div>
                        <div :class="columna2" style="padding-bottom: 20px">
                            <button
                                type="button"
                                class="btn btn-success btn-xs"
                                @click="SelectTransacID"
                            >
                                <i class="fas fa-check"></i> SELECT BANK TRANSACTION
                            </button>
                            <button
                                type="button"
                                class="btn btn-secundary btn-xs"
                                style="margin-left: 10px"
                                @click="resetTransacID"
                            >
                                <i class="fas fa-times"></i> CANCEL
                            </button>
                        </div>
                    </div>
                    <div class="table-Transaction">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th style="width:100px;">Date</th>
                                    <th>Description</th>
                                    <th style="width:100px;">Amount</th>
                                    <th>Type</th>
                                    <th style="text-align: center;width:50px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="listTransactionLoading">
                                    <td colspan="7" style="text-align: center">
                                        <Loader></Loader>
                                    </td>
                                </tr>
                                <tr
                                    v-for="(i, index) in listTransaction"
                                    v-bind:key="index"
                                    v-else-if="listTransaction.length > 0"
                                >
                                    <td>{{ i.fecha }}</td>
                                    <td>{{ i.description }}</td>
                                    <td style="text-align: right">
                                        <span
                                            class="text-danger"
                                            v-if="i.textRed == 1"
                                            >${{ i.amount | formatMoney }}</span
                                        >
                                        <span v-else
                                            >${{ i.amount | formatMoney }}</span
                                        >
                                    </td>
                                    <td style="text-align: center">
                                        <div v-if="i.textRed == 1">
                                            <span
                                                class="badge badge-danger"
                                                v-if="i.acctrans == 0"
                                                style="background-color: #ff7500"
                                            >
                                                Unaccepted
                                            </span>
                                        </div>
                                        <div v-else-if="i.amount == 0">
                                            <span class="badge badge-light"
                                                >Uncategorized</span
                                            >
                                        </div>
                                        <div v-else>
                                            <span class="badge badge-success">{{
                                                i.account
                                            }}</span>
                                        </div>
                                    </td>
                                    <td style="text-align: center;">
                                        <input
                                            type="radio"
                                            v-model="transac_id"
                                            :value="i.id"
                                        />
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="5" style="text-align: center">
                                        No record
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    components: { 
        Loader,
     },
    name: "formExpensesBankingTransac",
    data () {
        return {
            columna: "col-sm-6",
            columna2: "col-6 text-right",            
            CheckList:[],
            check_id: 0,
            transac_id: 0,
            SelectOne: 2,
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),  
            listTransactionLoading:false,
            listTransaction:[],          
            modalError: false,
            modalErrorMjs: "",
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            formExp: {
                id: null,
                titlePage: "New Expenses",
                action: "create",
                fecha: new Date().toISOString().slice(0, 10),
                type: 1,
                PmtType: 1,
                Vendor: 0,
                Employees: 0,
                Account: 0,
                tipformapago: 1,
                Banking: 0,
                Cash: 0,
                ref: null,
                amount: 0.1,
                description: null,
                img1: null,
                img2: null,
                imgOld1: null,
                imgOld2: null,
                numdoc: null,
                check_id: 0,
                transac_id: 0,
            },
            statusdropd: false,                 
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.modal.title = r.modal.title
            this.modal.vendor = r.modal.vendor
            this.modal.account = r.modal.account 
            this.modal.banking = r.modal.banking
            this.modal.bankingTransac = r.modal.bankingTransac
            this.modal.writeCheck = r.modal.writeCheck
            this.formExp.Banking = r.formExp.Banking 
            this.formExp.amount = r.formExp.amount
            this.formExp.description = r.formExp.description
            this.transac_id = r.transac_id          
            if (this.modal.bankingTransac) {                
                this.filtros()
                this.SelectOne = 2
            }           
        },
        filtros() {
            this.statusdropd = false;
            let datos = {
                id: this.formExp.Banking,
                filter: this.SelectOne,
                status: [3],
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };
            this.listTransactions(datos);
        },
        listTransactions(datos) {
            this.listTransactionLoading = true;
            window.billing
                .post("banking/transac", datos)
                .then((response) => {
                    this.listTransaction = [];
                    let transacList = response.data.result;
                    for (let i in transacList) {
                        // solo transac en status unaccepted y no vinculados con expense
                        if (
                            transacList[i].type === 2 &&
                            transacList[i].acctrans === 0 &&
                            transacList[i].expense_id === 0
                        ) {
                            this.listTransaction.push(transacList[i]);
                        }
                    }
                    this.listTransactionLoading = false;
                })
                .catch((error) => {
                    this.listTransactionLoading = false;
                    console.log(error);
                });
        },
        resetTransacID() {
            this.transac_id = 0;
            this.formExp.transac_id = this.transac_id;
            this.formExp.amount = "";
            this.formExp.description = "";
        },
        SelectTransacID() {
            if (this.transac_id === 0) {
                this.modalError = true;
                this.modalErrorMjs = "Error, you must select a Transaction.";
            } else {
                this.formExp.check_id = 0;
                this.check_id = 0;
                for (let i in this.listTransaction) {
                    if (this.transac_id === this.listTransaction[i].id) {
                        this.formExp.transac_id = this.transac_id;
                        this.formExp.amount = this.listTransaction[i].amount * -1;
                        this.formExp.description = this.listTransaction[i].description;
                    }
                }
            }
        },                           
    },
    watch: {
    },
    computed: {},
};
</script>
<style  lang="scss" scoped>
    table.table.table-striped tbody tr td:nth-child(5) {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
    }
</style>
