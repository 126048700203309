<template>
    <div class="taks">
        <div class="row">
            <div class="col8" v-if="displayBox.prefijo == 'imagen'">
                <img
                    v-if="displayBox.urls_imagenTh"
                    :src="displayBox.urls_imagenTh"
                    v-bind:class="[
                        'img-responsive imagen',
                        displayBox.orientacion == 1 ? 'horizontal' : 'vertical',
                    ]"
                    @click="
                        openFile(
                            displayBox.urls_imagenLg,
                            displayBox.orientacion,
                            displayBox.prefijo
                        )
                    "
                />
                <img
                    v-else
                    src="@/core/assets/no-image.png"
                    class="img-responsive horizontal"
                />
            </div>
            <div class="col8" v-else-if="displayBox.prefijo == 'slider'">
                <swiper
                    class="swiper" 
                    :options="swiperOption"
                    v-if="displayBox.urls_imagenTh"
                >
                    <swiper-slide 
                        v-for="(i, index) in displayBox.archivosTH" 
                        :key="index"
                    >
                        <img
                            :src="i"
                            v-bind:class="['img-responsive horizontal']"
                            @click="
                                openFile(
                                    displayBox.archivosLG[index],
                                    1,
                                    'imagen'
                                )
                            "
                        />
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
                <img
                    v-else
                    src="@/core/assets/no-image.png"
                    class="img-responsive horizontal slider"
                />
            </div>
            <div class="col8" v-else>
                <img
                    :src="displayBox.urls_poster"
                    v-bind:class="[
                        'img-responsive video',
                        displayBox.orientacion == 1 ? 'horizontal' : 'vertical',
                    ]"
                    @click="
                        openFile(
                            displayBox.urls_video,
                            displayBox.orientacion,
                            displayBox.prefijo
                        )
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: "sectionTasks",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        itemDisplay: { type: Object, required: true },
    },
    data() {
        return {
            displayBox: [],
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
        };
    },
    created() {
        this.autoHeigth();
    },
    mounted() {},
    methods: {
        openFile(img, orientacion, template) {
            document.styleSheets[1].addRule(
                ".v--modal-overlay .v--modal-box",
                "top: 100px !important;"
            );
            if (orientacion == 1) {
                this.$modal.show("DisplayFileH");
            } else {
                this.$modal.show("DisplayFileV");
            }
            let t = [];
            t.push({ img: img, orientacion: orientacion, template: template });
            this.arrays("DisplayFile", t);
        },
        autoHeigth() {
            if (Object.keys(this.itemDisplay).length > 0) {
                this.displayBox = this.itemDisplay;
                // for (let i in this.displayBox) {
                // 	if (this.displayBox.hasOwnProperty(i)) {
                // 		let n = this.displayBox[i].urls_imagen;
                // 		let h = ( ( (n.length/35) <1 )? 1 :  (n.length/35) )*35 ;
                // 		this.displayBox[i].alto = h;
                // 	}
                // }
            }
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/display/set_displayEdit", k);
        },
    },
    watch: {
        "itemDisplay.pusher": function () {
            this.autoHeigth();
            console.log("detectando cambio de taks");
        },
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
};
</script>

<style lang="scss" scoped>
.taks .row .col8 .bntFile {
    position: absolute;
    display: block;
    z-index: 9;
    color: #555;
}

.taks .row .col8 > img {
    cursor: zoom-in;
    margin: 0 auto;
    display: block;
    margin-top: 0px;
}

.horizontal {
    width: 384px;
    height: 216px;
}
.vertical {
    width: 216px;
    height: 384px;
}
.swiper-container.swiper {
    width: 383.99px;
}
</style>
