/*rutas de payments*/
import PaymentsReceived from '@/apps/billing/views/PaymentsReceived';
import PaymentsReceivedNew from '@/apps/billing/views/PaymentsReceivedNew';
export default [
	{
		path: 'payments-received',
		name: 'PaymentsReceived',
		folder: PaymentsReceived,
	},
	{
		path: 'payments-received/new',
		name: 'PaymentsReceivedNew',
		folder: PaymentsReceivedNew,
	},
	{
		path: 'payments-received/:idc/:transac',
		name: 'PaymentsReceivedNewTransac',
		folder: PaymentsReceivedNew,
	}
];
