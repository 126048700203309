<template>
    <div>
        <div class="form-general">
            <div class="card-header label-header" @click="showform">
                <i class="fa fa-minus" v-if="formConfig"></i>
                <i class="fa fa-plus" v-else></i>
                <span> FORM SETTING</span>
            </div>
            <div v-bind:class="[{ activeLista: formConfig }, 'label-config']">
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Subject:</label>
                    <input type="text" class="form-control" v-model="form.subject" />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Name:</label>
                    <input type="text" class="form-control" v-model="form.name" />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Class:</label>
                    <input type="text" class="form-control" v-model="form.class" />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Google Recaptcha: </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio1"
                            type="radio"
                            value="1"
                            v-model="form.recaptcha"
                        />
                        <span class="c-indicator"></span>
                        Yes
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="0"
                            v-model="form.recaptcha"
                        />
                        <span class="c-indicator"></span>
                        No
                    </label>
                </div>
                <div
                    class="form-group"
                    style="padding: 0px; padding-right: 20px"
                    v-if="form.recaptcha == 1"
                >
                    <label for="">Key website:</label>
                    <input type="text" class="form-control" v-model="form.key_public" />
                </div>
                <div
                    class="form-group"
                    style="padding: 0px; padding-right: 20px"
                    v-if="form.recaptcha == 1"
                >
                    <label for="">Key secret:</label>
                    <input type="text" class="form-control" v-model="form.key_secert" />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Style:</label>
                    <textarea class="form-control" v-model="form.style"></textarea>
                </div>
            </div>
        </div>
        <div v-if="editarCampo && editinput.campo_type != 'submit'" class="label-content">
            <div class="card-header label-header" @click="showLabel">
                <i class="fa fa-minus" v-if="labelConfig"></i>
                <i class="fa fa-plus" v-else></i>
                <span> LABEL</span>
            </div>
            <div
                v-bind:class="[{ activeLista: labelConfig }, 'label-config']"
                v-if="editinput.campo_type != 'submit'"
            >
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Label name:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.label_name"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Show Label: </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio1"
                            type="radio"
                            value="1"
                            v-model="editinput.label_display"
                        />
                        <span class="c-indicator"></span>
                        Yes
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="0"
                            v-model="editinput.label_display"
                        />
                        <span class="c-indicator"></span>
                        No
                    </label>
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Label width: </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio1"
                            type="radio"
                            value="c1-1"
                            v-model="editinput.label_width"
                        />
                        <span class="c-indicator"></span>
                        1/1
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="c1-2"
                            v-model="editinput.label_width"
                        />
                        <span class="c-indicator"></span>
                        1/2
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="c1-3"
                            v-model="editinput.label_width"
                        />
                        <span class="c-indicator"></span>
                        1/3
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="c1-4"
                            v-model="editinput.label_width"
                        />
                        <span class="c-indicator"></span>
                        3/4
                    </label>
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Label ID:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.label_id"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Label class:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.label_class"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Label style:</label>
                    <textarea
                        class="form-control"
                        v-model="editinput.label_style"
                    ></textarea>
                </div>
            </div>
        </div>
        <div v-if="editarCampo" class="campo-content">
            <div class="card-header label-header" @click="showCampo">
                <i class="fa fa-minus" v-if="campoConfig"></i>
                <i class="fa fa-plus" v-else></i>
                <span> INPUT</span>
            </div>
            <div v-bind:class="[{ activeLista: campoConfig }, 'label-config']">
                <div
                    v-if="
                        editinput.campo_type == 'select' ||
                        editinput.campo_type == 'radio' ||
                        editinput.campo_type == 'checkbox'
                    "
                >
                    <div
                        class="form-group"
                        style="padding: 0px; padding-right: 20px"
                        v-if="editinput.options.length > 0"
                    >
                        <label for="">Options:</label>
                        <draggable
                            v-model="editinput.options"
                            tag="div"
                            class="editForm"
                            v-bind="dragOptions"
                            @start="isDragging = true"
                            @end="isDragging = false"
                            handle=".handle"
                            v-if="editinput.options.length"
                        >
                            <div
                                class="input-group"
                                v-for="(o, x) in editinput.options"
                                :key="x"
                            >
                                <span class="input-group-addon handle han-nohover">
                                    <i class="fa fa-ellipsis-v"></i>
                                </span>
                                <input
                                    type="text"
                                    class="form-control opcionsInputs"
                                    v-model="editinput.options[x]"
                                />
                                <span
                                    class="input-group-addon btn btn-danger delOption"
                                    @click="delOption(o)"
                                >
                                    <i class="fa fa-times"></i>
                                </span>
                            </div>
                        </draggable>
                        <button
                            type="button"
                            class="btn btn-info btn-sm"
                            @click="addOptions"
                        >
                            <i class="fa fa-plus"></i> Options
                        </button>
                    </div>
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">ID:</label>
                    <input
                        type="text"
                        :class="'form-control ' + editinput.campo_error"
                        v-model="editinput.campo_id"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">NAME:</label>
                    <input
                        type="text"
                        :class="'form-control ' + editinput.campo_error"
                        v-model="editinput.campo_name"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Class:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.campo_class"
                    />
                </div>
                <div
                    class="form-group"
                    style="padding: 0px; padding-right: 20px"
                    v-if="editinput.campo_type != 'submit'"
                >
                    <label for="">Required: </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio1"
                            type="radio"
                            value="1"
                            v-model="editinput.campo_required"
                        />
                        <span class="c-indicator"></span>
                        Yes
                    </label>
                    <label class="c-input c-radio">
                        <input
                            id="radio2"
                            type="radio"
                            value="0"
                            v-model="editinput.campo_required"
                        />
                        <span class="c-indicator"></span>
                        No
                    </label>
                </div>
                <div
                    class="form-group"
                    style="padding: 0px; padding-right: 20px"
                    v-if="editinput.campo_type != 'submit'"
                >
                    <label for="">Placeholder:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.campo_placeholder"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Value:</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="editinput.campo_value"
                    />
                </div>
                <div class="form-group" style="padding: 0px; padding-right: 20px">
                    <label for="">Style:</label>
                    <textarea
                        class="form-control"
                        v-model="editinput.campo_style"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
export default {
    name: "editorForm",
    components: {
        draggable,
    },
    data() {
        return {
            formConfig: true,
            campoConfig: false,
            labelConfig: false,
            editarCampo: false,
            form: [],
            styleForm: [],
            inputs: [],
            customCSS: "",
            editinput: [],
            inputActive: null,
        };
    },
    created() {
        let u = this.$store.getters["ModApps/Marketea/get_formBuilt"];
        if (Object.keys(u).length == 0) {
            this.getForms();
        } else {
            this.formAction();
        }
    },
    methods: {
        getForms() {
            let e = this.$store.getters["ModApps/Marketea/get_formBuilt"];
            if (Object.keys(e).length > 0 || e != "") {
                this.form = e.result;
                if (e.s.custom_code != null) {
                    this.customCSS = e.s.custom_code;
                } else {
                    this.customCSS = "";
                }
                console.log('form setting', this.form)
                this.styleForm = e.s;
                this.inputs = JSON.parse(this.form.inputs);
            } else {
                console.log('get_formBuilt vacio');
            }
        },
        delOption(o) {
            let list = [];
            let op = this.editinput.options;
            for (let i in op) {
                if (op[i] != o) {
                    list.push(op[i]);
                }
            }
            this.editinput.options = list;
        },
        addOptions() {
            let add = this.editinput.options.length + 1;
            this.editinput.options.push("Item " + add);
        },
        showform() {
            this.formConfig = !this.formConfig;
            this.campoConfig = false;
            this.labelConfig = false;
        },
        showLabel() {
            this.labelConfig = !this.labelConfig;
            this.campoConfig = false;
            this.formConfig = false;
        },
        showCampo() {
            this.campoConfig = !this.campoConfig;
            this.labelConfig = false;
            this.formConfig = false;
        },
        formAction() {
            let g = this.$store.getters["ModApps/Marketea/get_editorStorage"];
            let count = g.length;
            if (count > 0) {
                this.form = g[0].form;
                this.formConfig = g[0].formConfig;
                this.campoConfig = g[0].campoConfig;
                this.labelConfig = g[0].labelConfig;
            }
            let gf = this.$store.getters["ModApps/Marketea/get_asideStorage"];
            let countf = gf.length;
            if (countf > 0) {
                this.editinput = gf[0].inputs;
                this.formConfig = gf[0].formConfig;
                this.campoConfig = gf[0].campoConfig;
                this.labelConfig = gf[0].labelConfig;
                this.inputActive = gf[0].inputActive;
                this.editarCampo = gf[0].editarCampo;
            }
        },
    },
    watch: {
        get_formBuilt: function (val) {
            console.log('watch get_formBuilt', val);
            let u = val;
            this.getForms();
        },
        get_builtForm: function () {
            if (this.get_builtForm) {
                let g = this.$store.getters["ModApps/Marketea/get_builtForm"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "edit") {
                    this.editinput = r.i;
                    this.form = r.form;
                    this.inputActive = r.index;
                    this.labelConfig = false;
                    this.campoConfig = true;
                    this.editarCampo = true;
                    this.formConfig = false;
                }
                if (fun == "RcreateInput") {
                    this.editinput = r.i;
                    this.form = r.form;
                    this.inputActive = r.index;
                    this.labelConfig = false;
                    this.campoConfig = true;
                    this.editarCampo = true;
                    this.formConfig = false;
                }
                if (fun == "cancelInput") {
                    this.editinput = [];
                    this.inputs = JSON.parse(localStorage.getItem("restore"));
                    this.inputActive = null;
                    this.editarCampo = false;
                    this.formConfig = true;
                    this.addInput = [];
                }
            }
        },
        editinput: {
            handler: function (after, before) {
                if (before != "") {
                    let r = [];
                    r.push({
                        inputs: after,
                        formConfig: this.formConfig,
                        campoConfig: this.campoConfig,
                        labelConfig: this.labelConfig,
                        inputActive: this.inputActive,
                        editarCampo: this.editarCampo,
                    });
                    this.$store.commit("ModApps/Marketea/set_asideStorage", r);
                }
            },
            deep: true,
        },
        form: {
            handler: function (after, before) {
                if (before != "") {
                    let r = [];
                    r.push({
                        form: after,
                        formConfig: this.formConfig,
                        campoConfig: this.campoConfig,
                        labelConfig: this.labelConfig,
                    });
                    this.$store.commit("ModApps/Marketea/set_editorStorage", r);
                } else {
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_builtForm", "get_formBuilt"]),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.label-config {
    height: 0px;
    transform: scaleY(0);
    overflow: hidden;
    transition: transform 0.3s ease-out;
}
.activeLista {
    overflow: initial !important;
    height: auto !important;
    transform: scaleY(1);
    transform-origin: top;
}
.opcionsInputs {
    padding-left: 20px;
}
.editForm .form-group.inputActive .handle {
    display: block;
}
.editForm .form-group:hover .handle {
    display: block;
}
.handle {
    position: absolute;
    top: -3px;
    left: 0px;
    width: 15px;
    height: 108%;
    z-index: 1;
    padding: 20px 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(215, 239, 253, 0.7098039215686275);
    color: #8e8383;
    display: none;
    cursor: move;
}
.card-header.label-header {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #000;
    margin: 5px 00px;
    font-weight: bold;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
}
.han-nohover {
    display: block !important;
    padding-top: 6px !important;
    text-align: center;
    height: 85% !important;
    border-radius: 0px !important;
    top: 0px !important;
    cursor: move !important;
}
.delOption {
    padding: 8px 10px;
    line-height: 01.5;
    height: 35px;
    border: 0px;
}
label.c-input.c-radio {
    margin-right: 10px;
}
</style>
