<template>
	<div class="col-md-12">
		<Loader v-if="$parent.isLoading"></Loader>
		<div class="contenedor-invoice" v-else>
			<table :class="[
					'table table-striped table-bordered table-hover invoList', 
					($parent.cols.length>=8) ? 'allCols' : ''
				]" v-if="$parent.InvoicesList.length > 0"
			>
				<thead>
					<tr>
						<!--<th 
							v-for="(i,idx) in $parent.cols" 
							:key="idx" 
							:class="$parent.colsAll[i].class" 
							v-if="($parent.colsAll[i].id!=12 && $parent.cols.includes($parent.colsAll[i].id)) || ($parent.colsAll[i].id==12 && $parent.accessJobsApp) || ($parent.colsAll[i].id=13)"
						>
							{{i.name}}
						</th>-->
						<!-- <th class="colSelected" v-if="$parent.cols.includes('1')"></th> -->
						<th class="numID" v-if="$parent.cols.includes('1')">Inv #</th>
						<th class="rec" v-if="$parent.cols.includes('10')">Rec</th>
						<th class="date" v-if="$parent.cols.includes('2')">Date</th>
						<th class="date-due" v-if="$parent.cols.includes('3')">Due Date</th>
						<th class="customer" v-if="$parent.cols.includes('4')">Customer</th>
						<th class="name" v-if="$parent.cols.includes('5')">Name</th>
						<th class="total" v-if="$parent.cols.includes('6')" >Total</th>
						<th class="tax" v-if="$parent.cols.includes('7')" >Tax</th>
						<th class="balance" v-if="$parent.cols.includes('8')">Balance</th>
						<th class="status" v-if="$parent.cols.includes('9')">Status</th>
						<th class="email" v-if="$parent.cols.includes('11')">Email</th>
						<th class="jobs" v-if="$parent.cols.includes('12') && $parent.accessJobsApp && $parent.JobsInt==1">Job</th>
						<th class="actions"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in $parent.InvoicesList" :key="index" 
						:data-id="item.id" :id="'tr-'+item.id"
						v-bind:class="[(item.id==$parent.selected) ? 'active' : '']"
					>
					<!--td class="colSelected" v-if="$parent.cols.includes('1')">
						<input 
							type="radio" 
							v-model="$parent.selected"
							:value="item.id"
						/>
					</td-->
					<td class="numID" 
						v-if="$parent.cols.includes('1')"
						@click="$parent.activeTR(item.id)"
					>
						{{ item.numdoc }}
						<button  @click="$parent.actionNotes(item.id, item.notes)"
							type="button" 
							title="Edit Notes"
							:class="[(item.notes > 0) ? 'btn btn-warning' : 'btn btn-secundary']"
							style="float: left; padding: 0px 3px; font-size: 10px; height: 16px;margin-right: 5px;"
						>
							<i class="far fa-sticky-note"></i>
							<span class="textIcon" style="display: none;"> Edit Note</span>
						</button>
					</td>
					<td  class="rec" v-if="$parent.cols.includes('10')" 
						@click="$parent.activeTR(item.id)"
					>
						<span v-if="item.rec == 1" class="badge badge-dark rec">Rec</span>
					</td>
					<td class="date" 
						v-if="$parent.cols.includes('2')"
						@click="$parent.activeTR(item.id)"
					>
						{{ item.fecdoc.slice(0, 10) | moment("MMM DD, YYYY")}}
					</td>
					<td 
						class="date-due" 
						v-if="$parent.cols.includes('3')"
						@click="$parent.activeTR(item.id)"
					>
						{{ item.fecdue.slice(0, 10) | moment("MMM DD, YYYY")}}
					</td>
					<td class="customer" 
						v-if="$parent.cols.includes('4')"
						@click="$parent.activeTR(item.id)"
					>
						{{ item.firstname }}
						{{ item.lastname }}<br />
						<span style="color: #a9a9a9">
							{{item.bussinesname}}
						</span>
					</td>
					<td  class="name"  
						v-if="$parent.cols.includes('5')"
						@click="$parent.activeTR(item.id)"
					>
						{{ item.invoicename }}
					</td>
					<td  class="total"  style="text-align: right" 
						v-if="$parent.cols.includes('6')"
						@click="$parent.activeTR(item.id)"
					>
						${{ item.total | formatMoney }}
					</td>
					<td  class="tax"  style="text-align: right" 
						v-if="$parent.cols.includes('7')"
						@click="$parent.activeTR(item.id)"
					>
						<span v-if="item.tax !== 0">
							${{item.tax | formatMoney}}
						</span>
						<span v-else>--</span>
					</td>
					<td  class="balance"  style="text-align: right; cursor: pointer;" 
						v-if="$parent.cols.includes('8')"
						@click="$parent.statement(item.id, item.customer_id)"
					>
					<span v-if="item.balance!=null">
						${{ item.balance | formatMoney }}
					</span>
					<span v-else>$0.00</span>
					</td>
					<td  class="status"  style="text-align: center"  
						v-if="$parent.cols.includes('9')" @click="$parent.activeTR(item.id)"
					>
						<span  v-for="(i, idx) in $parent.statusdata"
							:key="idx"
						>
							<span v-if="i.value == item.idxstatus"
								v-bind:class="[
									'badge',
									(item.idxstatus==1) ? 'badge-secondary pending' : 
									(item.idxstatus==2) ? 'badge-primary' : 
									(item.idxstatus==3) ? 'badge-info' : 
									(item.idxstatus==4) ? 'badge-danger overdue' : 
									(item.idxstatus==5) ? 'badge-danger void' : '',
								]"
							>
								{{i.name}}
							</span>
						</span>
					</td>
					<td  class="email" 
						v-if="$parent.cols.includes('11')"
						@click="$parent.actionEmail(item.id, item.email,item.numdoc)"
					>
						<button style="
								border: 0;
								background: transparent;
								cursor: pointer;
								font-size: 13px;
								color: #212529;
							"
						>
							{{ item.numemaienv }}
							<i class="fas fa-envelope" style="color: #f89406;font-size: 18px;"></i>
						</button>
					</td>
					<td class="jobs" v-if="$parent.cols.includes('12') && $parent.accessJobsApp && $parent.JobsInt==1">
						<button v-if="item.job==undefined || item.job.id==0"
							class="text-left textAction btn btn-light btn-sm tooltip-show" 
							style="border: 1px solid #dcdcdc;background-color: #fff;position: relative;"
							@click="$parent.convertToJobs(item.id,item.numdoc,item.total,item.customer_id,item)"
						>
							<i class="fas fa-arrow-right"></i>
							<!-- <span class="tooltipInvo">Convert to Job</span> -->
						</button>
						<span v-else>
							<span v-if="item.job.id_status==4"
								style="position: relative;"
								v-bind:class="[
									'tooltipJob-show badge',
									(item.job.id_status==4) ? 'badge-success' : '',
								]"
							>
								{{item.job.statusName}}
								<!-- <span class="tooltipJob">Job ID #{{item.job.id}}</span> -->
							</span>
							<span v-else
								class="cdropdown tooltipJob-show">
								<button 
										v-bind:class="[
											'btn btn-sm',
											(item.job.id_status==1) ? 'btn-secondary pending textW' : 
											(item.job.id_status==2) ? 'btn-info info textW' : '',
										]"
									>
										{{item.job.statusName}}
								</button>
								<label>
									<input 
										type="checkbox" 
										class="submenu" 
										@click="$parent.checkSubmenu($event)" 
									/>
									<ul>
										<li @click="$parent.jobstoCompleted(item.job.id, item)">
											<button>
												<i class="fas fa-check"></i>
												Completed
											</button>
										</li>
									</ul>
								</label>
								<!-- <span class="tooltipJob">Job ID #{{item.job.id}}</span> -->
							</span>
						</span>
					</td>
					<td class="actions">
						<span class="cdropdown">
							<button><i class="fas fa-cog"></i></button>
							<label>
								<input 
									type="checkbox" 
									v-model="item.actions"
									:class="'submenu submenu-'+item.id" 
									@click="$parent.checkSubmenu($event)" 
								/>
								<ul>									
									<li @click="$parent.actionPdf(item.id)">
										<button>
											<i class="fas fa-file-pdf"></i>
											Open PDF
										</button>
									</li>
									<li @click="$parent.actionPDFforEnvelope(item.id)" v-if="$parent.valOpAddi.includes('1')">
										<button>
											<i class="fas fa-file-pdf"></i>
											Open PDF for
											Envelope
										</button>
									</li>
									<li @click="$parent.actionPrint(item.id)" v-if="$parent.validEprint">
										<button class="text-left textAction">
											<i class="fas fa-print"></i>
											ePrint
										</button>
									</li>									
									<li v-if="$parent.showSMS==1"
										@click="$parent.SendPDFbySMS(item, item.customer_id, 'invoice')"
										v-bind:class="[
											'action',
											($parent.PlanPremium) ? 'active' : 'inactive',
										]"
									>
										<button
											class="text-left textAction">
											<i class="fas fa-envelope"></i>
											Send PDF by SMS
											<!-- <span class="premium pre">
                                                <img src="@/core/assets/img/premium.png" alt="premium">
                                            </span> -->
											</button>
									</li>									
									<li v-if="item.idxstatus !== 3 && item.idxstatus !== 5"
										@click="$parent.actionPay(item.id,item.numdoc,item.total,item.customer_id,item.firstname,item.lastname,item.bussinesname,item.balance,item)"
									>
										<button class="text-left textAction">
											<i class="fas fa-dollar-sign"></i>
											Make a Payment
										</button>
									</li>
									<li v-if="item.idxstatus !== 3 && item.idxstatus !== 5"
										@click="$parent.SquarePay(item.id,item.numdoc,item.total,item.customer_id,item.firstname,item.lastname,item.bussinesname,item.balance)"
										v-bind:class="[
											'action',
											($parent.PlanPremium && $parent.validAccountSquare) ? 'active' : 'inactive',
										]"
									>
										<button class="text-left textAction">
											<iconSquareUp></iconSquareUp>
											Pay with Square Up
											<!-- <span class="premium pre">
                                                <img src="@/core/assets/img/premium.png" alt="premium">
                                            </span> -->
										</button>
									</li>
									<li @click="$parent.statement(item.id, item.customer_id)" v-if="$parent.valOpAddi.includes('2')">
										<button>
											<i class="fas fa-file-invoice-dollar"></i>
											Statement
										</button>
									</li>
									<li v-if="$parent.valOpAddi.includes('3') && item.idxstatus !== 5"
										@click="$parent.actionDuplicate(item.id)"
									>
										<button>
											<i class="fas fa-copy"></i>
											Duplicate
										</button>
									</li>
									<li
										v-if="$parent.valOpAddi.includes('4') && item.idxstatus !==5 && $parent.PlanPremium"
										@click="$parent.convertRecurring(item.id)"
									>
										<button>
											<i class="fas fa-file-alt"></i>
											Convert to
											Recurring
											<!-- <span class="premium pre">
                                                <img src="@/core/assets/img/premium.png" alt="premium">
                                            </span> -->
										</button>
									</li>
									<li
										v-else-if="$parent.valOpAddi.includes('4') && item.idxstatus !==5 && !$parent.PlanPremium"
										@click="$parent.llamarPopPremium()"
										v-bind:class="[
											'action',
											($parent.PlanPremium) ? 'active' : 'inactive',
										]"
									>
										<button>
											<i class="fas fa-file-alt"></i>
											Convert to
											Recurring
											<!-- <span class="premium pre">
                                                <img src="@/core/assets/img/premium.png" alt="premium">
                                            </span> -->
										</button>
									</li>
									<li @click="$parent.actionEdit(item.id)" 
										v-if="item.idxstatus !== 2 &&
										item.idxstatus !== 3 &&
										item.idxstatus !== 5 "
									>
										<button class="text-left textAction">
											<i class="fas fa-edit"></i>
											Edit
										</button>
									</li>
									<li @click="$parent.actionVoid(item.id)"
										v-if="item.idxstatus !==5"
									>
										<button class="text-left textAction">
											<i class="fas fa-trash-alt"></i>
											Void
										</button>
									</li>
									<li @click="$parent.MetDeleteInvoice(item.id)" v-if=" item.idxstatus == 1 || item.idxstatus == 4 || 
										item.idxstatus == 5 "
									>
										<button
											class="text-left textAction">
											<i class="fas fa-times"></i>
											Delete
											</button>
									</li>
									</ul>									
								</label>
							</span>
						</td>
					</tr>
				</tbody>
				<tfoot >
					<tr>
						<td :colspan="$parent.cols.length+1">
							<ul 
								class="pagination justify-content-between" 
								style="borde: 1px solid blue"
							>
								<li>
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text">Show: </div>
										</div>
										<select 
											class="form-control selectRowList"
											v-model="$parent.rowNum" 
										>
											<option v-for="i in $parent.rowList" :key="i" :value="i">{{i}}</option>
										</select>
									</div>
								</li>
								<li class="page-car">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text">Pag: </div>
										</div>
										<select 
											v-model="$parent.page" 
											class="form-control selectRowList" 
											v-on:change="$parent.changePage($parent.page)"
										>
											<option v-for="i in $parent.numPag" :key="i" :value="i">{{i}}</option>
										</select>
									</div>	
								</li>
								<li>
									<span class="page-view">View {{ $parent.pagination.to}} of {{ $parent.pagination.total}}</span>
								</li>
							</ul>
						</td>
					</tr>
				</tfoot>
			</table>
			<div class="text-center d-block w-100 p20"
				v-else-if="$parent.InvoicesList.length == 0 && $parent.isFilter == true"
			>
				<div class="text-center" style="padding: 80px; background: #fff">
					No record
				</div>
			</div>
			<div class="text-center d-block w-100 p20" v-else>
				<div class="text-center" style="padding: 80px; background: #fff">
					<span>Its time to create your first Invoice! </span><br />
					<router-link
						v-if="!$parent.freePlan"
						:to="{ name: 'InvoicesNew' }"
						class="btn btn-success"
						style="margin-top: 7px"
						>Create New Invoice</router-link>
						<button type="button" 
                            v-else
                            @click="$parent.llamarPopPremium()"
                            class="btn btn-success disabled"
                        >
                            <i class="fas fa-plus"></i>
                            Create New Invoice
                        </button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import iconSquareUp from '@/apps/billing/components/iconSquareUp'
export default {
	name: 'tablaInvo',
	components: {
		Loader,
		iconSquareUp,
	}
}
</script>
<style lang="scss" scoped>
@import '@/apps/billing/views/invoice/css/tableinvo.scss';
</style>
