<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="page-title"><i class="fas fa-cog"></i> Setup</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                General Setting
                            </h4>  
                            <router-link :to="{ name: 'RoofCalZipSetup' }" 
                                class="list-group-item list-group-item-action"
                            >
                                Zip Code List
                            </router-link>  
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('textsms')"
                            >
                                Text message customization
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('newbtn')"
                            >
                                Show "New Estimate"
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('redirect')"
                            >
                                Redirect
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('calnoti')"
                            >
                                Notification list
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="openLateral('callogo')"
                            >
                                Header Customization
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Titles and descriptions
                            </h4>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox2('titledes', 0)"
                            >
                                Step: Zip Code
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox2('titledes', 1)"
                            >
                                Step: Address
                            </div> 
                            <router-link :to="{ name: 'RoofCalQuest' }" 
                                class="list-group-item list-group-item-action"
                            >
                                Custom Step's
                            </router-link>
                             
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox2('titledes', 2)"
                            >
                                Step: Other Details
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox2('titledes', 3)"
                            >
                                Step: Data User
                            </div> 
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox2('titledes', 4)"
                            >
                                Step: Thank you
                            </div> 
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Widget
                            </h4>   
                              
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('btnw')"
                            >
                                Text button widget
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('widgetcode')"
                            >
                                Copy widget Code
                            </div>                                                    
                        </div>
                    </div>                                        
                </div>
            </div>
        </div>
        <modal name="modal-setting" height="auto" :width="widthWidget" @before-close="ClosedModal">
            <div class="card">
                <button @click="closeModal" class="closeIcon"><span></span></button>
                <component :is="SettingBox"></component>
            </div>
        </modal>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
    </div>
</template>
<script>
import widgetcode from "@/apps/RoofCal/components/RoofCalwidgetCode.vue";
import calnoti from "@/apps/RoofCal/components/RoofCalNoti.vue";
import sender from "@/apps/RoofCal/components/RoofCalSender.vue";
import btnw from "@/apps/RoofCal/components/RoofCalBtnW.vue";
import titledes from "@/apps/RoofCal/components/RoofCalTitleDes.vue";
import textsms from "@/apps/RoofCal/components/RoofCalTextSmsCustom.vue";
import newbtn from "@/apps/RoofCal/components/RoofCalNewBtn.vue";
import redirect from "@/apps/RoofCal/components/RoofCalRedirect.vue";
import callogo from "@/apps/RoofCal/components/RoofCalLogo.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
export default {
  name: 'RoofCalSetup',
  components: {
    widgetcode,
    calnoti,
    sender,
    btnw,
    titledes,
    textsms,
    newbtn,
    redirect,
    callogo,
    modalLateral
  },
  data() {
    return {
        SettingBox: "",
        widthWidget: 1000,
        m: {
            show: false,
            component: '',
            data: []
        }, 
    };
  },
	methods: {    
    openLateral(c){           
        this.m= {
            show: true,
            component: c,
            data: []
        };
        this.$store.commit('core/setOpenModal', this.m);
    },
    ModalBox2(i, e) {
        this.SettingBox = i;
        this.widthWidget = 1000;
        this.$store.commit('ModApps/RoofCal/settypeStep', e);
        this.$modal.show("modal-setting");
    },
    ModalBox(i) {
        this.SettingBox = i;
        this.widthWidget = 600;
        this.$modal.show("modal-setting");
    },
    closeModal() {
        this.$modal.hide("modal-setting");
    },
    ClosedModal() {
        this.widthWidget = 600;
        this.SettingBox = "";
    },
    resfresh(res) {
        console.log(res.open);
        if (res.open == 1) {
            this.ClosedModal();
            this.closeModal();
        }
    },
  },
};
</script>
<style scoped lang="scss">
.vm--container { 
    z-index: 9999;
}
</style>
