<template>
	<div :class="['task_actions', ($parent.showActions)? 'show' : 'none']">
		<Loader v-if="isLoading" class="isLoading"></Loader>
		<div class="card">
			<div class="card-header">
				<h4>Actions <span class="badge badge-success" v-if="itemSelected!=null">#{{itemSelected.idx}}</span></h4>
				<button @click="$parent.closeModal()" class="closeIcon"><span></span></button>
			</div>
			<div class="card-body" style="padding-top:0px;">
				<form class="row" @submit.prevent="saveAction()">
						<div class="form-group col-md-8" style="margin-top:10px;">
							<input type="text" class="form-control" v-model="f.accion_name" placeholder="Name action" required>
						</div>
						<div class="form-group col-md-4 text-right" style="margin-top:10px;">
							<button type="submit" class="btn btn-primary">Save</button>
							<button type="button" class="btn btn-info" @click="saveAction(1)" style="margin-left: 15px;">Save & New</button>
							<button type="button" class="btn btn-light" @click="$parent.closeModal()">Cancel</button>
						</div>
						<div class="form-group  col-md-12">
							<label>Description or instructions:</label>
							<vue-editor
							v-model="f.accion_descrip"
							:editor-toolbar="customToolbar"
							class="descripcion"
							/>
						</div>
						<input type="hidden" v-model="f.task_id" >
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader.vue';
import { VueEditor } from 'vue2-editor'
export default {
	name: 'task_actions',
	components: {
		VueEditor,
		Loader,
	},
	props: {
		itemSelected: {
			type: Object,
			required: true,
		default: () => {},
		},
		setItemAction: {
			type: Object,
			required: true,
		default: () => {},
		},
	},
	data () {
		return {
			isLoading: false,
			f: {
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
				id: 0,
				task_id: 0,
				accion_name: '',
				accion_descrip: '',
				date_action: '',
				action_status_id: 0,
			},
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],  
		}
	},
	methods: {
		saveAction(newItem){
			let t = this;
			t.isLoading=true;
			console.log('saveAction');
			var url = 'setActionsTask';
			if(t.f.id==0){ 
				window.billing
				.post(url, t.f)
				.then((response) => {
					t.isLoading=false;
					if(newItem == undefined){
						t.$parent.closeModal();
					}else{
						t.clearForm();
					}
					console.log(url);
				})
				.catch((error) => {
					t.isLoading=false;
					console.log(error);
				});
			}else{
				url ='updateActionTask';
				window.billing
				.put(url, t.f)
				.then((response) => {
					t.isLoading=false;
					if(newItem == undefined){
						t.$parent.closeModal();
					}else{
						t.clearForm();
					}
					console.log(url);
				})
				.catch((error) => {
					t.isLoading=false;
					console.log(error);
				});
			}
		},
		clearForm(){
			this.f= {
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
				id: 0,
				task_id: this.itemSelected.id,
				accion_name: '',
				accion_descrip: '',
				date_action: '',
				action_status_id: 0,
			};
		},
	},
	watch: {
		"itemSelected": function(){
			let t = this;
			t.f.task_id = t.itemSelected.id;
		},
		"setItemAction": function(){
			let t = this;
			if(t.setItemAction.id!=undefined){	
				console.log('setItemAction', t.setItemAction)
				t.f.id = t.setItemAction.id;
				t.f.accion_name = t.setItemAction.accion_name;
				t.f.accion_descrip = t.setItemAction.accion_descrip;
				t.f.date_action = t.setItemAction.date_action;
				t.f.action_status_id = t.setItemAction.action_status_id;
			}else{
				t.f.id = 0;
				t.f.accion_name = null;
				t.f.accion_descrip = '';
				t.f.date_action = '';
				t.f.action_status_id = 0;
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.task_actions{
	position: fixed;
    top: 40px;
    max-width: 1000px;
    width: 100%;
    min-height: 100px;
    height: calc(100% - 45px);
    display: block;
    overflow: hidden;
    margin: auto;
    background: #fff;
    color: #000;
    filter: drop-shadow(0 3px 5px #ccc);
    border-radius: 4px;
    z-index: 999;
    right: 0px;
	transition: all 0.5s;
    &.show{
		transform: translateX(0px);
	}
	&.none{
		transform: translateX(1000px);
	}
}
.btn-light{
	margin-left: 15px;
	background: #fff;
	border-color:#ddd;
}
.descripcion{
	height: 100%;
}
.isLoading {
	position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(255 255 255 / 46%);
    z-index: 1;
}
</style>
