<template>
	<div v-bind:class="['barTools', !navEditor ? 'noneBar' : '', hideNav ? 'hideNav' : '']">
    <div v-bind:class="['btnShowAside', hideNav ? 'showbtn' : '']" @click.prevent="$parent.showAsideEditor()">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div v-if="menuBox">
      <div class="navtools">    
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item" >
            <a v-bind:class="['nav-link', (menu==1) ? 'active' : '']" href="#" @click.prevent="activeMenu(1)">
              Insert
            </a>
          </li>
          <li class="nav-item">
            <a v-bind:class="['nav-link', (menu==2) ? 'active' : '']" href="#" @click.prevent="activeMenu(2)">
              Pages
            </a>
          </li>
          <li class="nav-item">
            <a v-bind:class="['nav-link', (menu==3) ? 'active' : '']" href="#" @click.prevent="activeMenu(3)">
              More
            </a>
          </li>
          <li class="nav-item close">
            <a v-bind:class="['nav-link']" href="#" @click.prevent="closeAsideBar()">
              <i class="fas fa-times"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="components-edit">
        <div class="insert-elements" v-if="menu==1">
          <insert></insert>
          <sectioncontent></sectioncontent>
        </div>
      </div>
    </div>
    <div v-if="optionsEdicion">
      <div class="navtools" >    
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item " >
            <a v-bind:class="['nav-link', (menu==4) ? 'active' : '']" href="#" @click.prevent="activeMenu(4)">
              Slider<!-- {{titleOpc}} -->
            </a>
          </li>
          <li class="nav-item " >
            <a v-bind:class="['nav-link', (menu==5) ? 'active' : '']" href="#" @click.prevent="activeMenu(5)">
              Layout
            </a>
          </li>
          <li class="nav-item " >
            <a v-bind:class="['nav-link', (menu==6) ? 'active' : '']" href="#" @click.prevent="activeMenu(6)">
              More
            </a>
          </li>
          <li class="nav-item close">
            <a v-bind:class="['nav-link']" href="#" @click.prevent="closeAsideBar()">
              <i class="fas fa-times"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="components-edit">
        <component v-bind:is="componentActive" v-if="componentActive!=null && componentActive!=''"></component>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import insert from "./elements/insertElements.vue";
import sectioncontent from "./elements/widgetContents.vue";
import opcHeader from "@/apps/Sites/components/sitebuilder/sections/opcHeader.vue";
import slideLayout from "@/apps/Sites/components/sitebuilder/sections/slideLayout.vue";
export default {
  components: {
    insert,
    sectioncontent,
    opcHeader,
    slideLayout,
  },
  name: 'barTools',
  props: {
    msg: String
  },
  data() {
    return {
      navEditor: true,
      hideNav: true,
      menu: 1,
      menuBox: false,
      optionsEdicion: false,
      titleOpc: '',
      componentActive: null,
    }
  },
  created(){
    this.menuBox=true;
    //console.log('created', this.menuBox)
  },
  methods : {
    activeMenu(i){
      this.menu = i;
      if(i==4){this.componentActive= this.getSideBarOpc.component;}
      if(i==5){this.componentActive= 'slideLayout';}
      if(i==6){this.componentActive= '';}
    },
    closeAsideBar(){
      let t = this;
      t.$parent.showAsideEditor();
      setTimeout(function(){
        t.$store.commit("ModApps/Sites/setSideBarOpc", {active: false});
      }, 500);
    },
    closeOptionsEdicion(){   
        this.menuBox=true;
        this.optionsEdicion=false;
        this.titleOpc='';
        this.componentActive='';      
    },
  },
  watch: {
    "$parent.navEditor": function () {
      this.navEditor = this.$parent.navEditor;
    },
    "$parent.hideNav": function () {
      this.hideNav = this.$parent.hideNav;
      this.menu=1;
    },
    getcloseBarTools: function(){
      if(this.getcloseBarTools>0){
        this.$parent.showAsideEditor();
      }
    },
    getSideBarOpc: function(){
      if(this.getSideBarOpc.active){    
        this.menuBox=false;
        this.optionsEdicion=true;
        this.titleOpc='Header Options';
        this.componentActive= this.getSideBarOpc.component;
        this.menu = 4;
      }
      else{ 
        this.closeOptionsEdicion();
      }
    },
  },
  computed: {
    ...mapGetters("ModApps/Sites", { 
      getcloseBarTools: "getcloseBarTools",
      getSideBarOpc: "getSideBarOpc",
    }),

  }
};
</script>
<style scoped lang="scss">
  .barTools{
    width: 100%;
    max-width: 256px;
    height: calc(100vh - 83px);
    background-color: #fff;
    position: absolute;
    top: 40px;
    right: 1px;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%), 0px 1px 3px 0px rgb(0 0 0 / 20%);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    transform: translateX(0px);
    z-index: 5;
  }
  .barTools.noneBar{display: none !important;}
@media (max-width: 1190px){
  .barTools{
    max-width: 240px;
  }
}
ul.nav.nav-pills.nav-fill{
  /*display: flex;
   flex-wrap: wrap;
  */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: block;
  width: 100%;
  position: relative;
}
li.nav-item {
    border-left: 0px solid;
    border-top: 0px solid;
    border-bottom: 1px solid #dadce0;
    width: 75px;
    display: inline-block;
}
li.nav-item a.nav-link{
  position: relative;
  color: #555;
  line-height: 1.25rem;
  font-size: .875rem;
  letter-spacing: .0178571429em;
  font-weight: bold;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
  display: block;
  width: 100%;
  &:after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 1px;
    width:  60%;
    height: 1px;
    margin: 0px 20%;
    border-top: 3px solid #031099;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: 250ms transform cubic-bezier(0.4,0,0.2,1);
    opacity: 0;
  }
  &:hover{
    background-color: #efefef;
  }
  &.active{
    background: none;
  }
  &.active:after{
    opacity: 1; 
  }
}
li.nav-item.full {width:225px;}
li.nav-item.full a.nav-link{
  text-align: left;
  padding-left: 15px;
  &:after{
    margin: 0px;
    width:  100%;
  }
}
li.nav-item.close {
    width: 30px;
}
li.nav-item.close  a.nav-link{
  font-size: 30px;
  border-left: 1px solid #ccc;
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.components-edit{
  border: 0px solid;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: 90%;
}
.hideNav{
  transform: translateX(256px);
}
.btnShowAside{
  background-color: #fff;
  padding: 10px 10px 10px 15px;
  width: 50px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: none;
  margin-top: 20px;
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%), 0px 1px 3px 0px rgb(0 0 0 / 20%);
}
.btnShowAside:hover{cursor: pointer;}
.showbtn{
  transform: translateX(-35px);
  display: block;
}
</style>
