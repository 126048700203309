<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row">
                                <div class="col-md-6">
                                    <router-link
                                        :to="{ name: 'EstimatesNew' }"
                                        class="btn btn-success"
                                        ><i class="fas fa-plus"></i> Add
                                        Estimate</router-link
                                    >
                                    <!--   <div style="display:flex;">
                                        <div >Pending <span class="badge badge-secondary" style="margin-right:4px;"> <b>{{Pending.length}}</b></span></div>
                                        <div>Accepted <span class="badge badge-success" style="margin-right:4px;"> <b>{{Accepted.length}}</b></span></div>
                                        <div>Rejected <span class="badge badge-primary" style="margin-right:4px;"> <b>{{Rejected.length}}</b></span></div>
                                        <div>Void <span class="badge badge-danger"> <b>{{Void.length}}</b></span></div>
                                    </div> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-10">                                    
                                            <div class="flex-container">
                                                <div style="width: 90%;">
                                                    <input
                                                    type="text"
                                                    class="form-control searchbox-input"
                                                    placeholder="Search"
                                                    v-model="inputFilter"
                                                    v-on:keypress.enter="searchFilter"    
                                                    />   
                                                </div>
                                                <div>
                                                    <span
                                                    class="btn btn-info"
                                                    @click="searchFilter"
                                                    style="border: 0px"
                                                    >Search</span>  
                                                </div>
                                                <div>
                                                    <div class="input-group-prepend btn-filter-search" @click="searchAll">
                                                        <span class="input-group-text">All</span>
                                                    </div>    
                                                </div>  
                                            </div>
                                        </div>                                    
                                        <div class="col-md-2">
                                            <div
                                                class="basic_dropdown"
                                                v-click-outside="hideBtnstatus"
                                            >
                                                <button
                                                    class="btn-status"
                                                    @click="btnstatus()"
                                                >
                                                    Status
                                                    <i class="fas fa-caret-down"></i>
                                                </button>
                                                <ul
                                                    class="reset dropdStatus"
                                                    v-if="statusdropd"
                                                >
                                                    <li>
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input"
                                                                id="check12"
                                                                @click="checkAll()"
                                                                v-model="isCheckAll"
                                                            />
                                                            <label
                                                                class="custom-control-label"
                                                                for="check12"
                                                                ><b>All</b></label
                                                            >
                                                        </div>
                                                    </li>
                                                    <li
                                                        v-for="(
                                                            item, index
                                                        ) in statusdata"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input"
                                                                :id="index"
                                                                v-bind:value="item.value"
                                                                v-model="statuslist"
                                                                @change="searchFilter"
                                                            />
                                                            <label
                                                                class="custom-control-label"
                                                                :for="index"
                                                                >{{ item.name }}</label
                                                            >
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-2">
                                            <select v-model="paginador" class="form-control paginacion" @change="searchFilterPaginador">
                                            <option value="0">Display</option>
                                            <option value="200">200</option>
                                            <option value="300">300</option>
                                            <option value="500">500</option>
                                            </select>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <Loader v-if="isLoading"></Loader>
                                <div class="contenedor-estimate" v-else>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        v-if="EstimatesList.length > 0"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Est #</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Name</th>
                                                <th scope="col" style="text-align: right">
                                                    Total
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    v-if="accountSMS == 2"
                                                    style="text-align: center"
                                                >
                                                    Sign
                                                </th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in EstimatesList"
                                                :key="item.id"
                                            >
                                                <th scope="row">
                                                    {{ item.numdoc }}
                                                    <button 
                                                        v-if='item.notes > 0'
                                                        @click="actionNotes(item.id, item.notes)"                                                    
                                                        type="button" 
                                                        title="Edit Notes"                                                        
                                                        class="btn btn-warning"
                                                        style="float: right; padding: 0px 3px; font-size: 10px; height: 16px;">
                                                        <i class="far fa-sticky-note"></i>
                                                        <span class="textIcon" style="display: none;"> Edit Note</span>
                                                    </button>
                                                    <button
                                                        v-else
                                                        @click="actionNotes(item.id, item.notes)"                                                    
                                                        type="button" 
                                                        title="Edit Notes" 
                                                        class="btn btn-secundary"
                                                        style="float: right; padding: 0px 3px; font-size: 10px; height: 16px;">
                                                        <i class="far fa-sticky-note"></i>
                                                        <span class="textIcon" style="display: none;"> Edit Note</span>
                                                    </button> 
                                                </th>
                                                <td>{{ item.date_create }}</td>
                                                <td>
                                                    {{ item.firstname }}
                                                    {{ item.lastname }} <br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.bussinesname }}</span
                                                    >
                                                </td>
                                                <td>{{ item.estimate_optional }}</td>
                                                <td style="text-align: right">
                                                    ${{ item.total | formatMoney }}
                                                </td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.status == 1"
                                                        class="badge badge-secondary"
                                                        style="background-color: #f89406"
                                                        >Pending</span
                                                    >
                                                    <span
                                                        v-if="item.status == 2"
                                                        class="badge badge-success"
                                                        >Accepted</span
                                                    >
                                                    <span
                                                        v-if="item.status == 3"
                                                        class="badge badge-danger"
                                                        style="background-color: #f70d0d;"
                                                        >Rejected</span
                                                    >
                                                    <span
                                                        v-if="item.status == 4"
                                                        class="badge badge-danger"
                                                        style="background-color: #b94a48"
                                                        >Void</span
                                                    >
                                                    <span
                                                        v-if="item.status == 5"
                                                        class="badge badge-info"
                                                        >Signed</span
                                                    >
                                                </td>
                                                <td style="text-align: center; padding: 0px;">
                                                    <button
                                                    v-if="item.status != 5"
                                                        @click="
                                                            actionEmail(
                                                                item.id,
                                                                item.email,
                                                                item.numdoc
                                                            )
                                                        "
                                                        style="
                                                            border: 0;
                                                            background: transparent;
                                                            cursor: pointer;
                                                            font-size: 13px;
                                                        "
                                                    >
                                                        {{ item.email_send }}
                                                        <i
                                                            class="fas fa-envelope"
                                                            style="
                                                                color: #f89406;
                                                                font-size: 18px;
                                                            "
                                                        ></i>
                                                    </button>
                                                </td>
                                                <td style="text-align: center; padding: 0px;" v-if="accountSMS == 2">
                                                    <button
                                                        v-if="item.signe==1 && item.status == 2 && item.status != 5"
                                                        @click="OpenSMS(item)"
                                                        style="
                                                            border: 0;
                                                            background: transparent;
                                                            cursor: pointer;
                                                            font-size: 13px;
                                                        "
                                                    > {{item.countSmsEst}}
                                                        <i
                                                            class="fas fa-sms"
                                                            style="
                                                                color: #137eff;
                                                                font-size: 20px;
                                                            "
                                                        ></i>
                                                    </button>
                                                    <button
                                                        v-else-if="item.signe==1 && item.status != 2 && item.status != 5"
                                                        @click="InfoSMS(item)"
                                                        style="
                                                            border: 0;
                                                            background: transparent;
                                                            cursor: pointer;
                                                            font-size: 13px;
                                                        "
                                                    > 
                                                        <i
                                                            class="fas fa-sms"
                                                            style="
                                                                color: #888;
                                                                font-size: 20px;
                                                            "
                                                        ></i>
                                                    </button>
                                                </td>
                                                <td style="width: 85px">
                                                    <span class="cdropdown">
                                                        <button>Actions</button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                                <li
                                                                    @click="
                                                                        actionPdf(item.id)
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-file-pdf"
                                                                        ></i>
                                                                        Open PDF
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    v-if="item.signe==0 && item.status != 5"
                                                                    @click="
                                                                        actionPDFforEnvelope(
                                                                            item.id
                                                                        )
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-file-pdf"
                                                                        ></i>
                                                                        Open PDF for
                                                                        Envelope
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        actionPrint(
                                                                            item.id
                                                                        )
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-print"
                                                                        ></i>
                                                                        ePrint 
                                                                    </button>
                                                                </li>
                                                                <li 
                                                                    v-if="showSMS==1" 
                                                                    @click="sendSMSaction(item, 'estimate')"
                                                                    v-bind:class="[
                                                                        'action',
                                                                        (PlanPremium) ? 'active' : 'inactive',
                                                                    ]"
                                                                >
                                                                    <button
                                                                        class="text-left textAction">
                                                                        <i class="fas fa-envelope"></i>
                                                                        Send PDF by SMS
                                                                        </button>
                                                                </li>   
                                                                <li
                                                                    @click="
                                                                        actionDuplicate(
                                                                            item.id
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        item.status != 4
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-copy"
                                                                        ></i>
                                                                        Duplicate
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        convertToInvoice(
                                                                            item.id
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        item.status == 2 ||
                                                                        item.status == 5
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="far fa-file-alt"
                                                                        ></i>
                                                                        Convert To Invoice
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        actionEdit(
                                                                            item.id
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        item.status != 4  && item.status != 5
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-edit"
                                                                        ></i>
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        actionVoid(
                                                                            item.id
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        item.status != 4
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-trash-alt"
                                                                        ></i>
                                                                        Void
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        MetDeleteEstimate(
                                                                            item.id
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        item.status !== 2 && item.status != 5
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-times"
                                                                        ></i>
                                                                        Delete
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        class="text-center d-block w-100 p20"
                                        v-else-if="
                                            EstimatesList.length == 0 && isFilter == true
                                        "
                                    >
                                        <div class="text-center" style="padding: 80px">
                                            No record
                                        </div>
                                    </div>
                                    <div class="text-center d-block w-100 p20" v-else>
                                        <div class="text-center" style="padding: 80px">
                                            <span style="padding-bottom: 30px"
                                                >Its time to create your first Estimate! </span
                                            ><br />
                                            <router-link
                                                :to="{ name: 'EstimatesNew' }"
                                                class="btn btn-success"
                                                style="margin-top: 7px"
                                                >Create New Estimate</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"  :items="DataMailEstimate"></component>
        </modalLateral>          
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import Taxes from '@/apps/billing/components/setting/Taxes'
import Terms from '@/apps/billing/components/setting/Terms'
import modalEmail from '@/apps/billing/components/modalEmail'
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import ClickOutside from 'vue-click-outside'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import sendSMSCustomer from '@/apps/billing/components/sendSMSCustomer'
import { mapGetters } from 'vuex'
export default {
    name: "Estimates",
    components: {
        Loader,
        Terms,
        Taxes,
        modalEmail,
        NotesAdmin,
        sendSMSCustomer,
        modalLateral,
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },            
            isLoading: false,
            showSMS: 1,
            showModal: false,
            SettingBox: "",
            EstimatesList: [],
            DataMailEstimate: {
                isLoading: false,
                idinv: null,
                IDitem: null, // numdoc
                email: null,
                EmailCheck: false,
                view: "estimate",
            },
            emailsE: [],
            inputFilter: "",
            OldEstimatesList: [],
            invStatus: 0,
            isFilter: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Accepted" },
                { value: "3", name: "Rejected" },
                { value: "4", name: "Void" },
                { value: "5", name: "Signed" },
            ],
            statuslist: [1, 2, 3, 4],
            statusdropd: false,
            paginador: 0,
            accountSMS: 0,
            PlanPremium : (window.OrgPlan > 1),
            validEprint: false,
        };
    },
    created () {
        this.accountSMS = (!isNaN(localStorage.getItem('accountSMS'))) ? parseInt(localStorage.getItem('accountSMS')) : false;
        console.log('accountSMS', this.accountSMS)
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);        
        if(this.PlanPremium){
            //this.$router.push({ name: "estpro" });
        }
        this.getShowSMS();
        this.getEstimates();
    },
    mounted () {},
    directives: {
        ClickOutside,
    },
    methods: {
        InfoSMS(item){
            this.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                title: "To use SMS",
                text: "You must change the STATUS of the item to ACCEPTED to use this function.",
                show: true,
                duration: 5000
            }); 
        },
        OpenSMS(item){
            let t = this;
            if(!t.PlanPremium){
                t.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
            }
            else {
                t.m = {
                    show: true,
                    component: 'sendSMSCustomer',
                    data: {
                        item: item, 
                        typeDocumentID: 0, 
                    }
                }
                t.$store.commit('core/setOpenModal', this.m);  
            }
        }, 
        searchAll(){
            this.getEstimates();
        },
        sendSMSaction(item, type){
            let t = this;
            let data = {};
            let keys = Object.keys(item);
            for (let i in keys) {
                if (item[keys[i]]) {
                    data[keys[i]]=item[keys[i]];
                    if(keys[i] == 'need_contract' || keys[i] == 'signe'){
                        data[keys[i]]=0;
                    }
                }
            }
            //console.log('sendSMSaction', item, data)
            t.detailCustomerSMS(data, type);
        },
        detailCustomerSMS(item, type){
            if(!this.PlanPremium){
                this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
            }
            else {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Sending sms",
                    text: "Loading...",
                    show: true,
                    duration: 500
                }); 
                let obj = {
                    id: item.id,
                    idNumdoc: item.numdoc,
                    icCustomer: item.customer_id,
                    customerName: item.firstname+' '+item.lastname,
                    need_contract: item.need_contract,
                    signe: item.signe
                };
                window.billing
                .post("getShellpdf", {      
                    user_id: window.localuserdata, 
                    org_id: window.localorgdata,           
                    oauth_m: window.localStorage.getItem("oauth_m"),
                    AccountID: window.localAccountID,
                    app_name: window.localStorage.getItem('nameApp'),
                    app_id: window.localStorage.getItem('appIDactual'),
                    id:item.id,
                    idcustomer: item.customer_id,
                    type:type,
                    parameters:JSON.stringify(obj),            
                })
                .then((response) => {
                    let r = response.data.status; 
                    if(r == false){
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text: "You must add mobile phone",
                        });
                    } else {                
                        this.$notify({
                            group: "noti",
                            title: "Send PDF by SMS",
                            type: "success",
                            text: "Send PDF by SMS Successful",
                        });    
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            }
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        hideBtnstatus() {
            this.statusdropd = false;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
                this.searchFilter();
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        searchFilter() {
            if (this.inputFilter.length > 1 || this.statuslist.length > 0) {
                this.isLoading = true;
                window.billing
                    .post("filterestimate", {
                        user_id: window.localuserdata,
                        filter: this.inputFilter,
                        status: this.statuslist,
                        pag: this.paginador,
                    })
                    .then((response) => {
                        //console.log('filter ',response.data.result);
                        let listFilter = response.data.result;
                        let e = listFilter;
                        for (var i = 0; i < e.length; i++) {
                            let date_create = (new Date(e[i].date_create) + "").slice(
                                4,
                                15
                            );
                            let date_create_new = date_create.split(" ");
                            listFilter[i].date_create =
                                date_create_new[0] +
                                " " +
                                date_create_new[1] +
                                ", " +
                                date_create_new[2];
                        }
                        this.EstimatesList = listFilter;
                        this.isFilter = true;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            } else {
                this.EstimatesList = this.OldEstimatesList;
                this.isLoading = false;
                this.isFilter = false;
            }
        },
        searchFilterPaginador() {
            if (this.paginador > 0) {
                this.searchFilter();
            } else {
                this.EstimatesList = this.OldEstimatesList;
                this.isLoading = false;
                this.isFilter = false;
            }
        },
        actionPdf(id) {
            window.open(window.urlbase + "api/v2/estimate/pdf/" + id, "_blank");
        },
        actionPDFforEnvelope(id) {
            window.open(window.urlbase + "api/v2/estimateenvelopes/pdf/" + id, "_blank");
        },
        actionEdit(id) {
            this.$router.push({ name: "EstimatesDet", params: { idest: id } });
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        getEstimates() {
            this.isLoading = true;
            window.billing
                .get("estimates/" + window.localuserdata)
                .then((response) => {
                    //console.log('estimates list');
                    this.validEprint = (response.data.ePrint && response.data.ePrint.meta_value);
                    this.EstimatesList = response.data.result;
                    let e = this.EstimatesList;
                    for (var i = 0; i < e.length; i++) {
                        let date_create = (new Date(e[i].date_create) + "").slice(4, 15);
                        let date_create_new = date_create.split(" ");
                        this.EstimatesList[i].date_create =
                            date_create_new[0] +
                            " " +
                            date_create_new[1] +
                            ", " +
                            date_create_new[2];
                            this.EstimatesList[i].accountSMS = window.accountSMS
                    }
                    this.OldEstimatesList = this.EstimatesList;
                    //console.log(this.EstimatesList);
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        actionEmail(id, email, numdoc) {
            this.DataMailEstimate.idinv = id;
            this.DataMailEstimate.IDitem = numdoc;
            this.DataMailEstimate.email = email;
            this.DataMailEstimate.validEprint = this.validEprint;
            let t = this;
            this.m = {
                show: true,
                component: 'modalEmail',
                data: {DataMailEstimate:t.DataMailEstimate}
            }  
            this.$store.commit('core/setOpenModal', this.m);  
        },
        actionNotes(id, notes){
            let edit = false
            if (notes > 0){
                edit = true
            }             
            let t = this;
            this.m = {
                show: true,
                component: 'NotesAdmin',
                data: {
                    id:id, 
                    type:"Estimate",
                    edit: edit,
                    transac_type: "Estimate",
                    idNote: id
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);  
        },        
        sendMailEstimate(item) {
            if (item.DataItem.send == 1) {
                item.DataItem.send = 0;
                this.$store.commit("core/setCloseModal", {
                    action: 'sendMailEstimate',                     
                });
                this.getEstimates();
                this.$notify({
                    group: "noti",
                    title: "Success",
                    type: "success",
                    text: "Send Mail!",
                });
                this.$store.commit("ModApps/billing/SetResponseMail", null);
            }
        },
        actionPrint(idest) {
            this.isLoading = true;
            window.billing
                .post("eprintest/" + idest, {
                    user_id: window.localuserdata,
                    eprint: 1,
                })
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    if (response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "success",
                            text: "Send Successful",
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "error",
                            text: "Send error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send Mail!",
                        type: "error",
                        text: "Send error",
                    });
                });
        },
        MetDeleteEstimate(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/delete/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getEstimates();
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Estimate Successful",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        actionVoid(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Void this Estimate?",
                text: "You will not be able to recover it",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Void it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/void/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getEstimates();
                                this.$notify({
                                    group: "noti",
                                    title: "Void!",
                                    type: "success",
                                    text: "Void Estimate Successful",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        actionDuplicate(id) {
            this.$router.push({
                name: "EstimatesDuplicate",
                params: { idest: id, duplicate: "duplicate" },
            });
        },
        convertToInvoice(id) {
            this.$router.push({
                name: "EstimatesConvertInvoice",
                params: { idest: id, convert: "convert" },
            });
        },
        pusherEst(p){
            console.log('pusherEst', p.action)
            let old = this.EstimatesList;
            let list =[];
            for (const i in old) { list.push(old[i]);}
            // actualizar status
            if(p.action == "estimate-status"){
                for (const i in list) { 
                   if(list[i].id == p.data.id_est){
                        list[i].status = p.data.status;
                        if(p.data.updateNotes){
                            if(p.data.updateNotes.original.result){
                                list[i].notes = 1;
                            }
                        }
                   }
                }
            }
            this.EstimatesList = list;
        },
        getShowSMS(){
            window.billing
            .post('getKeyValSetting', {
                user_id: window.master_client,
                org_id: window.localorgdata,
                meta_key: 'showSMS',
            })
            .then((response) => {
                console.log(response.data);
                if(response.data.status){
                    this.$store.commit('ModApps/billing/setShowSMS', response.data.r.meta_value);
                    this.showSMS = this.$store.getters['ModApps/billing/getShowSMS'];
                }else{this.showSMS = 1;} 
                console.log('showSMS', this.showSMS);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        pusherCountSmsEst(p){
            console.log('updateCountSMS', p.action)
            let old = this.EstimatesList;
            let idEst = 0;
            let countSMs = 0;
            let d = p.accountSMS.split('-');
            idEst = d[0];
            countSMs = d[1];
            let list =[];
            for (const i in old) { list.push(old[i]);}
            // actualizar status
            if(p.action == "updateCountSMS"){
                for (const i in list) { 
                   if(list[i].id == idEst){
                        list[i].countSmsEst = countSMs;
                        console.log('pusherCountSmsEst', list[i])
                   }
                }
            }
            this.EstimatesList = list;
        },
    },
    watch: {
        SettingBox: function () {
            if (!this.SettingBox == "") {
                this.$modal.show("hello-world");
            }
        },
        getResponseMail: function () {
            console.log("getResponseMail", this.getResponseMail);
            if (this.getResponseMail && this.getResponseMail.fEventBus === "estimate-email") {
                this.sendMailEstimate(this.getResponseMail);
            }
        },
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data;
                    if (t.methods === 'invoNote') {
                        let id = t.id
                        let value = t.value
                        let g = this.EstimatesList
                        let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                            } 
                            a.push(g[i]);
                        }
                        this.EstimatesList = a;
                    }                     
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        'getActionRefresh': function() {
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                    let t = this.getActionRefresh.data;
                    if (t.methods === 'invoNote') {
                        let id = t.id
                        let value = t.value
                        let g = this.EstimatesList
                        let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                            } 
                            a.push(g[i]);
                        }
                        this.EstimatesList = a;
                    }                     
                }
        },
		accitonPusher: function () {
			if (this.accitonPusher != undefined) {
				let p = this.accitonPusher;
                if (p.action == "serviceSMS") { 
                    this.isLoading = true;                   
                    let y = this.EstimatesList
                    for (const i in y) {
                        this.EstimatesList[i].accountSMS = p.accountSMS
                    }
                    localStorage.setItem('accountSMS', p.accountSMS);
                    this.accountSMS = (!isNaN(localStorage.getItem('accountSMS'))) ? parseInt(localStorage.getItem('accountSMS')) : false;
                    this.isLoading = false;
				}
                if(window.master_client == p.user_id && window.localorgdata == p.org_id){
                    this.pusherEst(p);
                }
                if(window.master_client == p.user_id && window.localorgdata == p.org_id && p.action == "showSMS"){
                    this.getShowSMS();
                }
                if(window.master_client == p.user_id && window.localorgdata == p.org_id && p.action == "updateCountSMS"){
                    this.pusherCountSmsEst(p);
                }
			}
		},
        getPlanId(){
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        }, 
        getStoreShowSMS(){
            this.showSMS = this.getStoreShowSMS;
            console.log('showSMS', this.showSMS);
        }, 
    },
    computed: {
        ...mapGetters("core", { 
            getOpenModal: "getOpenModal", 
            getActionRefresh: "getActionRefresh", 
            accitonPusher: "get_accitonPusher", 
        }),
        ...mapGetters("ModApps/billing", { getResponseMail: "getResponseMail" }),
        ...mapGetters("ModApps/billing", { getStoreShowSMS: "getShowSMS" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        Pending: function () {
            return this.EstimatesList.filter((r) => r.status === 1);
            //return this.requests.filter(r => r.status === "APPROVED");
        },
        Accepted: function () {
            return this.EstimatesList.filter((r) => r.status === 2);
        },
        Rejected: function () {
            return this.EstimatesList.filter((r) => r.status === 3);
        },
        Void: function () {
            return this.EstimatesList.filter((r) => r.status === 4);
        },
        datecreate: function (date) {
            return "hola";
        },
    },
};
</script>
<style lang="scss" scoped>
@media (max-width: 991px) {
    .contenedor-estimate {
        width: 100%;
        overflow-x: scroll;
    }
}
.contenedor-estimate table {
    width: 100%;
    min-width: 767px;
    margin-bottom: 250px;
}
.contenedor-estimate table thead tr th:nth-child(1) {
    min-width: 60px;
}
.contenedor-estimate table thead tr th:nth-child(2) {
    min-width: 120px;
}
.contenedor-estimate table thead tr th:nth-child(3),
.contenedor-estimate table thead tr th:nth-child(4) {
    min-width: 150px;
}
.contenedor-estimate table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
.cdropdown ul li {
    padding: 3px 10px;
}
.cdropdown ul li button {
    font-size: 0.76rem;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.contenedor-estimate tbody tr td .badge {
    width: 90%;
}
.flex-container {
    display: flex;
}
.flex-container > div {
    background-color: #f1f1f1;
    margin: 2px;
}
.action.inactive button i {
    color: #000;
}
.action.inactive button {
    color: #c9caca;
}
</style>
