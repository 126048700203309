var RoofCal = "roofcal/";
export default {  
	getZip(){
		return RoofCal+"getZip";
	},
	setZip(){
		return RoofCal+"setZip";
	},
	delZip(){
		return RoofCal+"delZip";
	},  
	getBtn(){
		return RoofCal+"getBtn";
	},
	setBtn(){
		return RoofCal+"setBtn";
	},
	getNoti(){
		return RoofCal+"getNoti";
	},
	delNoti(){
		return RoofCal+"delNoti";
	},
	setNoti(){
		return RoofCal+"setNoti";
	},
	getSender(){
		return RoofCal+"getSender";
	},
	setSender(){
		return RoofCal+"setSender";
	},
	getTitleDes(){
		return RoofCal+"getTitleDes";
	},
	setTitleDes(){
		return RoofCal+"setTitleDes";
	},
	getTextSms(){
		return RoofCal+"getTextSms";
	},
	setTextSms(){
		return RoofCal+"setTextSms";
	},
	getNewBtn(){
		return RoofCal+"getNewBtn";
	},
	setNewBtn(){
		return RoofCal+"setNewBtn";
	},
	getRedirect(){
		return RoofCal+"getRedirect";
	},
	setRedirect(){
		return RoofCal+"setRedirect";
	},
	setAllZip(){
		return RoofCal+"setAllZip";
	},
	getCalLogo(){
		return RoofCal+"getCalLogo";
	},
	setCalLogo(){
		return RoofCal+"setCalLogo";
	},
	getLog(){
		return RoofCal+"getLog";
	},
	getServ(){
		return RoofCal+"getServ";
	},
	createServ(){
		return RoofCal+"createServ";
	},
	updateServ(){
		return RoofCal+"updateServ";
	},
	deleteServ(){
		return RoofCal+"deleteServ";
	},
	hideServ(){
		return RoofCal+"hideServ";
	},
	createAnswers(){
		return RoofCal+"createAnswers";
	},
	updateAnswers(){
		return RoofCal+"updateAnswers";
	},
	deleteAnswers(){
		return RoofCal+"deleteAnswers";
	},
	checkDefault(){
		return RoofCal+"checkDefault";
	},
};
