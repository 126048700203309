<template>
    <div class="container">
        <div class="card" style="margin-top: 60px">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <form
                @submit.prevent="saveExpenses"
                enctype="multipart/form-data"
                id="formExpenses"
            >
                <div
                    class="card-header alert alert-info"
                    style="margin-bottom: 0rem"
                >
                    <div class="row">
                        <div class="col-md-6">
                            <h4>
                                <b>{{ formExp.titlePage }}</b>
                                <strong v-if="formExp.numdoc != null">
                                    #{{
                                        formExp.numdoc
                                            .toString()
                                            .padStart(5, "0000")
                                    }}
                                </strong>
                            </h4>
                        </div>
                        <div class="col-md-6 text-right">
                            <button
                                type="submit"
                                class="btn btn-success"
                                :disabled="isDisabled"
                            >
                                <i class="fas fa-save"></i> SAVE
                            </button>
                            <button
                                type="button"
                                class="btn btn-secundary"
                                style="margin-right: 5px; margin-left: 5px"
                                @click="$router.push({ name: 'Expenses' })"
                            >
                                <i class="fas fa-times"></i> CANCEL
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger"
                                v-if="formExp.action == 'update'"
                                @click="delE(formExp.id)"
                            >
                                <i class="fas fa-trash-alt"></i> DELETE
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-5 col-sm-12">
                            <div class="row">
                                <div
                                    class="form-group col-12"
                                    style="margin-top: 20px"
                                >
                                    <span
                                        style="
                                            position: absolute;
                                            right: 5px;
                                            top: -21px;
                                        "
                                    >
                                        <button
                                            type="button"
                                            class="btnlink"
                                            @click="addAccountNew"
                                        >
                                            <i class="fas fa-plus"></i> New
                                            Account
                                        </button>
                                    </span>
                                    <model-select
                                        required
                                        :options="AccountList"
                                        v-model="formExp.Account"
                                        placeholder="Choose a Account"
                                    >
                                    </model-select>
                                </div>
                                <div class="form-group col-4" style="padding:0px;">
                                    <div class="form-check">
                                        <label class="radio-inline" style="padding-right:15px;">
                                            <input
                                                type="radio"
                                                value="1"
                                                v-model="formExp.type"
                                            />
                                            Vendor 
                                        </label>
                                        <label class="radio-inline">
                                            <input
                                                type="radio"
                                                value="2"
                                                v-model="formExp.type"
                                            />
                                            Subcontractor
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-8">
                                    <div v-if="formExp.type == 1" style="margin-top:-10px;">
                                        <span
                                            style="
                                                display:block;
                                                position: relative;
                                                right: 5px;
                                                top: 0x;
                                                text-align: right;
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="btnlink"
                                                @click="addVendorNew"
                                            >
                                                <i class="fas fa-plus"></i> New
                                                Vendor
                                            </button>
                                        </span>
                                        <model-select
                                            required
                                            class="VendorList"
                                            :options="VendorList"
                                            v-model="formExp.Vendor"
                                            placeholder="Choose a Vendor"
                                        >
                                        </model-select>
                                    </div>
                                    <div v-else style="margin-top:10px;">
                                        <model-select
                                            required
                                            :options="EmployeesList"
                                            v-model="formExp.Employees"
                                            placeholder="Choose a Subcontractor"
                                        >
                                        </model-select>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label style="margin-bottom:10px;">SELECT FORM OF PAYMENT</label
                                    >
                                </div>
                                <div class="form-group col-4" style="padding-left:0px;">
                                    <div class="form-check">
                                        <label class="radio-inline" style="padding-right:15px;">
                                            <input
                                                type="radio"
                                                value="1"
                                                v-model="formExp.tipformapago"
                                            />
                                            Banking 
                                        </label>
                                        <label class="radio-inline">
                                            <input
                                                type="radio"
                                                value="2"
                                                v-model="formExp.tipformapago"
                                            />
                                            Cash & Others
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-8">
                                    <div v-if="formExp.tipformapago == 1">
                                        <span
                                            style="
                                                position: absolute;
                                                right: 5px;
                                                top: -21px;
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="btnlink"
                                                @click="addBankingNew"
                                            >
                                                <i class="fas fa-plus"></i> New
                                                Banking
                                            </button>
                                        </span>
                                        <model-select
                                            required
                                            :options="BankingList"
                                            v-model="formExp.Banking"
                                            placeholder="Choose a Bank"
                                        >
                                        </model-select>
                                    </div>
                                    <div v-else>
                                        <model-select
                                            required
                                            :options="CashOthers"
                                            v-model="formExp.Cash"
                                            placeholder="Cash & Others"
                                        >
                                        </model-select>
                                    </div>
                                </div>
                                <div
                                    class="form-group row"
                                    v-if="
                                        formExp.tipformapago == 1 &&
                                        formExp.Banking > 0
                                    "
                                >
                                    <div class="col-12">
                                        <label>TYPE OF PAYMENT:</label
                                    >
                                    </div>
                                    <div class="col-6" style="padding-left:0;">
                                        <div class="form-check">
                                            <label class="radio-inline" style="padding-right:15px;">
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    v-model="formExp.PmtType"
                                                />Bank Transaction &nbsp;&nbsp;
                                            </label>
                                            <label class="radio-inline">
                                                <input
                                                    type="radio"
                                                    value="2"
                                                    v-model="formExp.PmtType"
                                                />Check
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="formExp.PmtType == 1" class="col-6">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-xs"
                                            @click="modalTransac"
                                            v-if="formExp.transac_id == 0"
                                        >
                                            <i class="fas fa-unlink"></i> SELECT
                                            BANK TRANSACTION (Optional)
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-info btn-xs"
                                            @click="modalTransac"
                                            v-else
                                        >
                                            <i class="fas fa-link"></i> EDIT BANK
                                            TRANSACTION
                                        </button>
                                    </div>
                                    <div v-if="formExp.PmtType == 2" class="col-6">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-xs"
                                            @click="modalwriteCheck"
                                            v-if="formExp.check_id == 0"
                                        >
                                            <i class="fas fa-unlink"></i> SELECT
                                            CHECK (Optional)
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-info btn-xs"
                                            @click="modalwriteCheck"
                                            v-else
                                        >
                                            <i class="fas fa-link"></i> EDIT CHECK
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group col-4">
                                    <label
                                        class="form-check-label"
                                        style="padding-right: 10px"
                                        >Date</label
                                    >
                                    <datetime
                                        type="date"
                                        v-model="inputFecha"
                                        format="MMM dd, yyyy"
                                        input-class="form-control"
                                        value-zone="America/New_York"
                                    ></datetime>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Ref # (Opt.)</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="formExp.ref"
                                    />
                                </div>
                                <div class="form-group col-md-5">
                                    <label for="inputZip">Amount</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                >$</span
                                            >
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="0.00"
                                            required
                                            v-model="formExp.amount"
                                            @keyup="validP"
                                        />
                                        <span
                                            class="error"
                                            style="color: red"
                                            v-if="amountError"
                                            >{{ amountErrorMsg }}</span
                                        >
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label for="">Description</label>
                                    <textarea
                                        class="form-control"
                                        rows="2"
                                        v-model="formExp.description"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12" >
                            <div class="row" v-if="PlanPremium">
                                <div class="form-group col-12">
                                    <div v-if="Showimg1" style="margin-top: 10px">
                                        <img
                                            v-bind:src="preview1"
                                            id="img1"
                                            style="width: 100%; max-width: 600px"
                                        />
                                    </div>
                                    <div
                                        v-else
                                        class="file-drag-drop"
                                        ref="Drop1"
                                        @click="$refs.img1.click()"
                                    >
                                        <span class="drop-files"
                                            >Drag and drop the image here!</span
                                        >
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-danger bntFile"
                                        @click="delImg1"
                                        v-if="Showimg1"
                                    >
                                        <i class="fa fa-trash"></i> Delete
                                        Attachment 1
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-info bntFile"
                                        @click="$refs.img1.click()"
                                        v-else
                                    >
                                        <i class="fas fa-upload"></i> Attachment 1
                                        <span class="premium pre">
                                            <img src="@/core/assets/img/premium.png" alt="premium">
                                        </span>
                                    </button>
                                    <input
                                        type="file"
                                        style="display:none;"
                                        ref="img1"
                                        accept="image/*"
                                        @change="fileImg1"
                                    />
                                </div>
                                <div class="form-group col-12">
                                    <div v-if="Showimg2" style="margin-top: 10px">
                                        <img
                                            v-bind:src="preview2"
                                            id="img2"
                                            style="width: 100%; max-width: 600px"
                                        />
                                    </div>
                                    <div
                                        v-else
                                        class="file-drag-drop"
                                        ref="Drop2"
                                        @click="$refs.img2.click()"
                                    >
                                        <span class="drop-files"
                                            >Drag and drop the image here!</span
                                        >
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-danger bntFile"
                                        @click="delImg2"
                                        v-if="Showimg2"
                                    >
                                        <i class="fa fa-trash"></i> Delete
                                        Attachment 2
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-info bntFile"
                                        @click="$refs.img2.click()"
                                        v-else
                                    >
                                        <i class="fas fa-upload"></i> Attachment 2
                                        <span class="premium pre">
                                            <img src="@/core/assets/img/premium.png" alt="premium">
                                        </span>
                                    </button>
                                    <input
                                        type="file"
                                        style="display:none;"
                                        ref="img2"
                                        accept="image/*"
                                        @change="fileImg2"
                                    />
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="form-group col-12">
                                    <div
                                        class="file-drag-drop"
                                        @click="llamarPopPremium()"
                                    >
                                        <span class="drop-files"
                                            >Drag and drop the image here!</span
                                        >
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-info bntFile"
                                        @click="llamarPopPremium()"
                                    >
                                        <i class="fas fa-upload"></i> Attachment 1
                                        <span class="premium pre">
                                            <img src="@/core/assets/img/premium.png" alt="premium">
                                        </span>
                                    </button>
                                </div>
                                <div class="form-group col-12">
                                    <div
                                        class="file-drag-drop"
                                        @click="llamarPopPremium()"
                                    >
                                        <span class="drop-files"
                                            >Drag and drop the image here!</span
                                        >
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-info bntFile"
                                        @click="llamarPopPremium()"
                                    >
                                        <i class="fas fa-upload"></i> Attachment 2
                                        <span class="premium pre">
                                            <img src="@/core/assets/img/premium.png" alt="premium">
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px">
                        <div class="col-12 text-right">
                            <button
                                type="submit"
                                class="btn btn-success btn-lg"
                                :disabled="isDisabled"
                            >
                                <i class="fas fa-save"></i> SAVE
                            </button>
                            <button
                                type="button"
                                class="btn btn-secundary btn-lg"
                                style="margin-left: 10px"
                                @click="$router.push({ name: 'Expenses' })"
                            >
                                <i class="fas fa-times"></i> CANCEL
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger btn-lg"
                                v-if="formExp.action == 'update'"
                                @click="delE(formExp.id)"
                                style="margin-left: 10px"
                            >
                                <i class="fas fa-trash-alt"></i> DELETE
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>           
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import { ModelSelect } from 'vue-search-select'
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formExpensesVendor from '@/apps//billing/components/expenses/formExpensesVendor'
import formExpensesAccount from '@/apps//billing/components/expenses/formExpensesAccount'
import formExpensesBanking from '@/apps//billing/components/expenses/formExpensesBanking'
import formExpensesBankingTransac from '@/apps//billing/components/expenses/formExpensesBankingTransac'
import formExpensesWriteCheck from '@/apps//billing/components/expenses/formExpensesWriteCheck'
export default {
    components: {
        Loader,
        ModelSelect,
        modalLateral,
        formExpensesVendor,
        formExpensesAccount,
        formExpensesBanking,
        formExpensesBankingTransac,
        formExpensesWriteCheck
    },
    name: "ExpensesNew",
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },              
            isLoading: false,
            formExp: {
                id: null,
                titlePage: "New Expenses",
                action: "create",
                fecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                type: 1,
                PmtType: 1,
                Vendor: 0,
                Employees: 0,
                Account: 0,
                tipformapago: 1,
                Banking: 0,
                Cash: 0,
                ref: null,
                amount: 0.1,
                description: null,
                img1: null,
                img2: null,
                imgOld1: null,
                imgOld2: null,
                numdoc: null,
                check_id: 0,
                transac_id: 0,
            },
            VendorList: [],
            EmployeesList: [],
            AccountList: [],
            BankingList: [],
            CashOthers: [
                { value: 1, text: "Cash" },
                { value: 2, text: "Money Order" },
            ],
            validimg1: false,
            validimgOld1: false,
            Showimg1: false,
            validimg2: false,
            validimgOld2: false,
            Showimg2: false,
            preview1: "",
            preview2: "",
            folder_user: "",
            amountError: false,
            amountErrorMsg: null,
            botton: false,
            dragAndDropCapable: false,
            files: {
                img1: null,
                img2: null,
            },
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            addVendor: { company_name: null },
            addAccount: {
                account_type: 0,
                name_account: null,
                description: null,
            },
            addBanking: {
                user_id: window.localuserdata,
                account_type: true,
                account_name: "",
                account_code: "",
                account_number: "",
                bank_name: "",
                routing_number: "",
                description: "",
                activo: 1,
                primary: false,
            },
            inputFecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            CheckList: [],
            move: true,
            modalError: false,
            modalErrorMjs: "",
            check_id: 0,
            transac_id: 0,
            bCheck: "",
            PmtType: 1,
            SelectOne: 2,
            dateFrom: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            dateTo: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            listTransactionLoading: false,
            listTransaction: [],
            columna: "col-sm-6",
            columna2: "col-6 text-right",
            PlanPremium : (window.OrgPlan > 1),
        };
    },
    created () {
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        this.getVendors();
        // this.getEmployees(); // cargando al seleccionar
        this.getchartaccount();
        this.getBanking();
        if (this.$route.params.ide) {
            this.formExp.titlePage = "Edit Expenses";
            this.formExp.action = "update";
            this.getExpensesByid();
        }
    },
    mounted () {
        this.dragAndDropCapable = this.determineDragAndDropCapable();
        if (this.dragAndDropCapable) {
            let eventosDragDrop = [
                "drag",
                "dragstart",
                "dragend",
                "dragover",
                "dragenter",
                "dragleave",
                "drop",
            ];
            let t = this;
            eventosDragDrop.forEach(function (evt) {
                t.$refs.Drop1.addEventListener(evt, function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                });
                t.$refs.Drop2.addEventListener(evt, function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });
            t.$refs.Drop1.addEventListener("drop", function (e) {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    //agregando solo 1
                    t.files.img1 = e.dataTransfer.files[i];
                }
                t.fileImg1();
            });
            t.$refs.Drop2.addEventListener("drop", function (e) {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    //agregando solo 1
                    t.files.img2 = e.dataTransfer.files[i];
                }
                t.fileImg2();
            });
        }
    },
    methods: {
        llamarPopPremium(){
            this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
        },
        addVendorNew() {
            this.modal.title = "Add Vendor";
            this.modal.vendor = true
            this.modal.account = false
            this.modal.banking = false
            this.modal.bankingTransac = false
            this.modal.writeCheck = false            
            let t = this;
            this.m = {
                show: true,
                component: 'formExpensesVendor',
                data: {
                    modal:t.modal,               
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        }, 
        addAccountNew() {
            this.modal.title = "Add Account";
            this.modal.vendor = false
            this.modal.account = true
            this.modal.banking = false
            this.modal.bankingTransac = false
            this.modal.writeCheck = false                      
            let t = this;
            this.m = {
                show: true,
                component: 'formExpensesAccount',
                data: {
                    modal:t.modal,
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);              
        },
        addBankingNew() {
            this.modal.title = "Add Banking";
            this.modal.vendor = false
            this.modal.account = false
            this.modal.banking = true
            this.modal.bankingTransac = false
            this.modal.writeCheck = false             
            let t = this;
            this.m = {
                show: true,
                component: 'formExpensesBanking',
                data: {
                    modal:t.modal,
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);
        },
        modalTransac() {
            this.modal.title = "SELECT BANK TRANSACTION";            
            this.modal.vendor = false
            this.modal.account = false
            this.modal.banking = false
            this.modal.bankingTransac = true
            this.modal.writeCheck = false             
            let t = this;
            this.m = {
                show: true,
                component: 'formExpensesBankingTransac',
                data: {
                    modal:t.modal,
                    formExp:t.formExp,
                    transac_id:t.transac_id,
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);            
        },
        modalwriteCheck() {
            this.modal.title = "SELECT CHECK";            
            this.modal.vendor = false
            this.modal.account = false
            this.modal.banking = false
            this.modal.bankingTransac = false
            this.modal.writeCheck = true             
            let t = this;
            this.m = {
                show: true,
                component: 'formExpensesWriteCheck',
                data: {
                    modal:t.modal,
                    formExp:t.formExp,
                    transac_id:t.transac_id,
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);
        },                       
        formatearFecha() {
            // console.log("fecha seleccionada ", this.inputFecha);
            this.inputFecha = (this.inputFecha.toString() + "").slice(0, 10);
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T13:31:04.000Z"
            ).toISOString();
        },
        getVendors() {
            var self = this;
            this.isLoading = true;
            window.billing
                .get("vendors/" + window.localuserdata)
                .then((response) => {
                    if (response.data.success) {
                        let Vendor = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].company_name;
                            let n = response.data.result[item].firstname;
                            if (n != null && n != "") {
                                t = t + " - " + n;
                            }
                            Vendor.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        self.VendorList = Vendor;
                    }
                    self.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getEmployees() {
            var self = this;
            window.billing
                .get("employees/" + window.localuserdata)
                .then((response) => {
                    if (response.data.success) {
                        let EmployeesList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].printoncheck;
                            EmployeesList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        EmployeesList.sort((a, b) =>
                            a.text < b.text ? -1 : a.text > b.text ? 1 : 0
                        );
                        self.EmployeesList = EmployeesList;
                    }
                })
                .catch((error) => console.log(error));
        },
        getchartaccount() {
            this.isLoading = true;
            window.billing
                .get("expenses/account/" + window.localuserdata)
                .then((response) => {
                    if (response.data.result.length > 0) {
                        let AccountList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].name_account;
                            AccountList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.AccountList = AccountList;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getBanking() {
            this.isLoading = true;
            window.billing
                .get("bankings/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    if (response.data.result.length > 0) {
                        let BankingList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].bank_name + " ";
                            t +=
                                "xxxx" +
                                response.data.result[item].account_number.slice(-4);
                            BankingList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.BankingList = BankingList;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getExpensesByid() {
            window.billing
                .post("expense", {
                    user_id: window.localuserdata,
                    id: this.$route.params.ide,
                })
                .then((response) => {
                    let i = response.data.result;
                    // console.log(i);
                    //this.formExp.fecha=i.fecha;
                    this.inputFecha = i.fecha;
                    this.formatearFecha();
                    this.formExp.type = i.type;
                    this.formExp.Vendor = i.type == 1 ? i.vendor_id : 0;
                    this.formExp.Employees = i.type == 2 ? i.employees_id : 0;
                    this.formExp.tipformapago = i.tipformapago;
                    this.formExp.Banking = i.tipformapago == 1 ? i.formapago_id : 0;
                    this.formExp.Cash = i.tipformapago == 2 ? i.formapago_id : 0;
                    this.formExp.Account = i.account_id;
                    this.formExp.ref = i.ref == "null" ? "" : i.ref;
                    this.formExp.amount = i.amount;
                    this.formExp.description =
                        i.description == "null" ? "" : i.description;
                    this.formExp.numdoc = i.numdoc;
                    this.formExp.id = i.id;
                    this.formExp.PmtType = i.PmtType;
                    this.PmtType = i.PmtType;
                    this.transac_id = i.transac_id;
                    this.formExp.transac_id = i.transac_id;
                    this.check_id = i.check_id;
                    this.formExp.check_id = i.check_id;
                    if (i.img1 != "" && i.img1 != null) {
                        //console.log(i.img1)
                        this.Showimg1 = true;
                        this.preview1 = response.data.url + i.img1;
                        this.formExp.imgOld1 = i.img1;
                    }
                    if (i.img2 != "" && i.img2 != null) {
                        //console.log(i.img2)
                        this.Showimg2 = true;
                        this.preview2 = response.data.url + i.img2;
                        this.formExp.imgOld2 = i.img2;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        determineDragAndDropCapable() {
            var div = document.createElement("div");
            return (
                ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
                "FormData" in window &&
                "FileReader" in window
            );
        },
        fileImg1(event) {
            let file = "";
            if (this.files.img1 != null) {
                file = this.files.img1;
            } else {
                file = event.target.files[0];
            }
            this.formExp.img1 = file;
            this.validimg1 = true;
            this.Showimg1 = true;
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    document.querySelector("#img1").src = e.target.result;
                };
            }
        },
        fileImg2(event) {
            let file = "";
            if (this.files.img2 != null) {
                file = this.files.img2;
            } else {
                file = event.target.files[0];
            }
            this.formExp.img2 = file;
            this.validimg2 = true;
            this.Showimg2 = true;
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    document.querySelector("#img2").src = e.target.result;
                };
            }
        },
        delImg1() {
            this.files.img1 = null;
            this.validimg1 = false;
            this.validimgOld1 = true;
            this.Showimg1 = false;
            this.preview1 = "";
            this.formExp.img1 = null;
        },
        delImg2() {
            this.files.img2 = null;
            this.validimg2 = false;
            this.validimgOld2 = true;
            this.Showimg2 = false;
            this.preview2 = "";
            this.formExp.img2 = null;
        },
        validP() {
            let vatIdRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
            if (!vatIdRegex.test(this.formExp.amount) && event.key != ".") {
                this.amountError = true;
                this.amountErrorMsg =
                    "Enter the valid number, with a maximum of two decimal places.";
                this.botton = true;
            } else if (this.formExp.amount == 0) {
                this.amountError = true;
                this.amountErrorMsg = "Must be greater than zero.";
                this.botton = true;
            } else {
                this.amountError = false;
                this.botton = false;
                this.amountErrorMsg = "";
            }
        },
        delE(id) {
            this.$swal({
                title: "Delete this Expense?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .post("expense/del", { id: id })
                        .then((response) => {
                            this.$notify({
                                group: "noti",
                                title: "Deleted",
                                type: "success",
                                text: "Deleted Estimate Successful",
                            });
                            this.$router.push({ name: "Expenses" });
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });
                }
            });
        },
        saveExpenses() {
            this.subirIMGs();
        },
        subirIMGs() {
            let t = this;
            t.isLoading = true;
            let f = new FormData();
            let contIMG = 0;
            let contDel = 0;
            for (let i in t.formExp) {
                if (i == "img1" && t.formExp[i] !== null && t.validimg1 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.formExp[i], t.formExp[i].name);
                }
                if (i == "img2" && t.formExp[i] !== null && t.validimg2 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.formExp[i], t.formExp[i].name);
                }
                if (i == "imgOld1" && t.formExp[i] !== null && t.validimgOld1 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.formExp[i]);
                }
                if (i == "imgOld2" && t.formExp[i] !== null && t.validimgOld2 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.formExp[i]);
                }
            }
            f.append("nombreIMG", "img");
            f.append("contIMG", contIMG);
            f.append("delIMG", "imgOld");
            f.append("contDel", contDel);
            f.append("folder", "expenses");
            f.append("org_id", window.localorgdata);
            window.master
                .post("upload", f)
                .then((response) => {
                    let imgs = response.data.imgs;
                    this.saveData(imgs);
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        saveData(imgs) {
            var t = this;
            var f = new FormData();
            for (let i in t.formExp) {
                if (i == "img1" && t.formExp[i] !== null && t.validimg1 == true) {
                    f.append(i, imgs[1]);
                } else if (
                    i == "img2" &&
                    t.formExp[i] !== null &&
                    t.validimg2 == true &&
                    t.validimg1 == true
                ) {
                    f.append(i, imgs[2]);
                } else if (
                    i == "img2" &&
                    t.formExp[i] !== null &&
                    t.validimg2 == true &&
                    t.validimg1 == false
                ) {
                    f.append(i, imgs[1]);
                } else if (i == "type") {
                    if (t.formExp[i] == 1) {
                        f.append("vendor_id", t.formExp.Vendor);
                        f.append("employees_id", 0);
                    } else {
                        f.append("vendor_id", 0);
                        f.append("employees_id", t.formExp.Employees);
                    }
                    f.append(i, t.formExp[i]); //type
                } else if (i == "Account") {
                    f.append("account_id", t.formExp[i]);
                } else if (i == "tipformapago") {
                    let formapago_id = 0;
                    if (t.formExp[i] == 1) {
                        formapago_id = t.formExp.Banking;
                    } else {
                        formapago_id = t.formExp.Cash;
                    }
                    f.append("formapago_id", formapago_id);
                    f.append(i, t.formExp[i]); //tipformapago
                } else {
                    f.append(i, t.formExp[i]);
                }
            }
            if (t.validimg1 == true) {
                f.append("validimg1", 1);
            } else {
                f.append("validimg1", 0);
            }
            if (t.validimg2 == true) {
                f.append("validimg2", 1);
            } else {
                f.append("validimg2", 0);
            }
            if (t.validimgOld1 == true) {
                f.append("validimgOld1", 1);
            } else {
                f.append("validimgOld1", 0);
            }
            if (t.validimgOld2 == true) {
                f.append("validimgOld2", 1);
            } else {
                f.append("validimgOld2", 0);
            }
            f.append("user_id", window.localuserdata);
            let urlAxios = "";
            let title = "Created";
            let text = "Successful registration";
            if (t.formExp.action == "create") {
                urlAxios = "expenses/add";
            } else if (t.formExp.action == "update") {
                urlAxios = "expenses/update";
                title = "Updated";
                text = "Successful Updated";
            }
            window.billing
                .post(urlAxios, f)
                .then((response) => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        title: title,
                        text: text,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.$router.push({ name: "Expenses" });
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
    },
    watch: {
        'formExp.type': function () {
            if(this.formExp.type == 2 && this.EmployeesList.length == 0) {
                this.getEmployees();
            }
        },
        'getActionRefresh': function() {
            if (Object.keys(this.getOpenModal.data).length > 0) {
                let t = this.getOpenModal.data; 
                if (t.methods === 'vendor') {
                    this.formExp.Vendor = t.value
                    this.VendorList.push({
                        value:t.value,
                        text:t.text
                    });
                    this.getVendors();
                }
                if (t.methods === 'account') {
                    this.formExp.Account = t.value
                    this.AccountList.push({
                        value:t.value,
                        text:t.text
                    });
                    this.getchartaccount();
                }
                if (t.methods === 'baking') {
                    this.formExp.Banking = t.value
                    this.AccountList.push({
                        value:t.value,
                        text:t.text
                    });
                    this.getBanking();
                }
            }
        },
        inputFecha: function () {
            this.formatearFecha();
            this.formExp.fecha = this.inputFecha;
            // console.log("cambio de fecha ", this.formExp.fecha);
        },
        "formExp.tipformapago": function (i) {
            if (i == 1) {
                this.formExp.PmtType = this.PmtType;
                this.formExp.check_id =
                    this.formExp.check_id == 0 ? 0 : this.formExp.check_id;
                this.formExp.transac_id =
                    this.formExp.transac_id == 0 ? 0 : this.formExp.transac_id;
            } else {
                this.formExp.PmtType = 3;
            }
        },
        dateFrom: function () {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        dateTo: function () {
            this.dateTo = new Date(
                (new Date(this.dateTo).toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
        },
        SelectOne: function (i) {
            if (i > 3) {
                this.columna = "col-sm-12";
                this.columna2 = "col-12 text-right";
            } else {
                this.columna = "col-sm-6";
                this.columna2 = "col-6 text-right";
            }
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {
        ...mapGetters("core", {
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh",
        }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        isDisabled: function () {
            if (this.formExp.type == 1 && this.formExp.Vendor == 0) {
                return true;
            } else if (this.formExp.type == 2 && this.formExp.Employees == 0) {
                return true;
            } else if (this.formExp.tipformapago == 1 && this.formExp.Banking == null) {
                return true;
            } else if (this.formExp.tipformapago == 2 && this.formExp.Cash == 0) {
                return true;
            } else if (this.formExp.Account == null || this.formExp.Account == 0) {
                return true;
            } else if (
                this.formExp.amount == 0 ||
                this.formExp.amount == "" ||
                this.formExp.amount == null
            ) {
                return true;
            } else if (this.botton) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.file-drag-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    background: #fff;
    margin: auto;
    margin-top: 0px;
    text-align: center;
    border-radius: 0px;
    border: 1px dashed #ccc;
}
.drop-files {
    font-size: 24px;
    color: #ccc;
}
.bntFile {
    border-radius: 0px;
    width: 100%;
}
.inputDateFilter {
    width: 120px !important;
    border-right: 1px solid #dedede;
    overflow: hidden;
}
.table-Transaction {
    height: 65vh;
    overflow-y: scroll;
}
</style>
