<template>
	<div class="widgets-basic">
		<draggable
      class="list-elements"
      :list="listElements"
      :group="{ name: 'widgets', pull: 'clone', put: false }"
      :clone="cloneElements"
      @change="log"
    >
    	<div class="box-widgets" v-for="(i, index) in listElements" :key="index">
    		<div class="iconWidget ">
    			<svg v-if="i.type=='text'" width="24" height="24" viewBox="0 0 24 24" focusable="false" class="iconTexto"><path d="M2 4v3h5v13h3V7h5V4H2zm20 5h-9v3h3v8h3v-8h3V9z"></path></svg>
    			<svg v-if="i.type=='img'" width="24" height="24" viewBox="0 0 24 24" focusable="false" class="iconImg"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-5-7l-3 3.72L9 13l-3 4h12l-4-5z"></path></svg>
    			<svg v-if="i.type=='btn'" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" focusable="false" class="iconButton"><g><rect fill="none" height="24" width="24"></rect></g><g><g><path d="M19,7H5C3.9,7,3,7.9,3,9v6c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9C21,7.9,20.1,7,19,7z M19,15H5V9h14V15z"></path></g></g></svg>
    			<svg v-if="i.type=='code'" width="24" height="24" viewBox="0 0 24 24" focusable="false" class="iconinsert"><path d="M16 6l-1.41 1.41L19.17 12l-4.58 4.59L16 18l6-6zM8 18l1.41-1.41L4.83 12l4.58-4.59L8 6l-6 6z"></path></svg>
    		</div>
    		<div v-if="i.type=='text'" class="textWidget">Box text</div>
    		<div v-if="i.type=='img'" class="textWidget">Images</div>
    		<div v-if="i.type=='btn'" class="textWidget">Button</div>
    		<div v-if="i.type=='code'" class="textWidget">Insert</div>
    	</div>
    </draggable>
	</div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
	components: {
		draggable,
	},
	props: {
	  title: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: 'Lorem ipsum dolor sit amet',
	  },
	  parrafo: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	  },
	  img1: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: require('@/core/assets/img/no-image.png'),
	  },
	  btn: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: 'Read more',
	  },
	},
  name: 'insertElements',
  data() {
    return {
    	elements: [
    		{
	    		type: 'text', //text, img, btn, code
					htmlCode: '<h3>'+this.title+'</h3><p>'+this.parrafo+' '+this.parrafo+'</p>',
					moreClass: 'textContent',
	    	},
	    	{
	    		type: 'img', //text, img, btn, code
					htmlCode: '<img src="'+this.img1+'" alt="img" class="img-responsive"/>',
					moreClass: 'img-thumbnail'
	    	},
	    	{
	    		type: 'btn', //text, img, btn, code
					htmlCode: '<a href="#" class="btn-builder">'+this.btn+'</a>',
					moreClass: ''
	    	},
	    	{
	    		type: 'code', //text, img, btn, code
					htmlCode: null,
					moreClass: ''
	    	}
    	],
    	listElements: [],
    };
  },
	created(){
		localStorage.setItem('listCloneElements', JSON.stringify(this.elements))
		this.initElements();
	},
	methods:{
		initElements(){
			this.listElements=[];
			if(localStorage.getItem('listCloneElements')!=null){
				let list = JSON.parse(localStorage.getItem('listCloneElements'));
				for (let i in list) {
					if(list[i]){
						this.listElements.push(list[i]);
					}
				}
			}
			//window.console.log('initElements');
		},
		log: function(evt) {
			window.console.log('log box-Elements', evt);
		},
		cloneElements(elem){
			let t = this;
			//window.console.log('cloneSection', elem);
			let itemClone = {};
			let list = JSON.parse(localStorage.getItem('listCloneElements'));
			for (let i in list) {
				if(list[i].type == elem.type){
					itemClone = list[i];
				}
			}
			t.$store.commit("ModApps/Sites/setcloseBarTools", 1);
			return itemClone;
		},
	},
};
</script>
<style lang="scss" scoped>
.list-elements{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.box-widgets{
	display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 66px;
    border-radius: 5px;
    &:hover{
	    background-color: #efefef;
	    cursor: pointer;
	  }
}
.iconWidget{
	svg.iconTexto{
		fill: #4285f4;
	}
	svg.iconImg{
		fill: #ea4335;
	}
	svg.iconinsert{
		fill: #fbbc04;
	}
	svg.iconButton{
		fill: #019720;
	}
}
</style>
