<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="card">
                        <div class="content-default-customer">
                            <div class="card-header">
                                <h5 class="card-title">{{ formB.title }}</h5>
                            </div>
                            <div class="card-body">
                                <Loader v-if="isLoading"></Loader>
                                <form
                                    v-else
                                    @submit.prevent="saveBanking"
                                    class="form-horizontal"
                                    role="form"
                                    style="margin-top: 25px"
                                >
                                    <div class="form-group row">
                                        <div class="col-md-4 col-md-offset-2">
                                            <button class="btn btn-success" type="submit">
                                                Save</button
                                            >&nbsp;&nbsp;
                                            <button
                                                class="btn btn-secundary"
                                                @click="btnCancel"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="form-group row">
                                                <label
                                                    class="control-label required col-md-2"
                                                    >Select Account Type</label
                                                >
                                                <div class="col-md-7">
                                                    <label class="radio-inline">
                                                        <input
                                                            name="bankorcard"
                                                            type="radio"
                                                            :value="true"
                                                            v-model="formB.account_type"
                                                        />
                                                        Bank &nbsp;&nbsp;
                                                    </label>
                                                    <label class="radio-inline">
                                                        <input
                                                            name="bankorcard"
                                                            type="radio"
                                                            :value="false"
                                                            v-model="formB.account_type"
                                                        />
                                                        Credit Card
                                                    </label>
                                                </div>
                                            </div>

                                            <div
                                                class="form-group row"
                                                v-if="formB.account_type"
                                            >
                                                <label class="control-label col-md-2">
                                                    <span class="text-dashed-underline">
                                                        Transaction Type:
                                                    </span>
                                                </label>
                                                <div class="col-md-3">
                                                    <select
                                                        class="form-control"
                                                        v-model="
                                                            formB.account_type_transac
                                                        "
                                                    >
                                                        <option value="1">
                                                            Checking Account
                                                        </option>
                                                        <option value="2">
                                                            Saving Account
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label
                                                    class="control-label required col-md-2"
                                                >
                                                    <span class="text-dashed-underline"
                                                        >Account Name:
                                                    </span>
                                                </label>
                                                <div class="col-md-3">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        required
                                                        v-model="formB.account_name"
                                                    />
                                                </div>
                                            </div>
                                            <!-- 	<div class="form-group row">
												<label class="control-label col-md-2">
													<span class="text-dashed-underline"> Account Code </span>
												</label> 
												<div class="col-md-3">
													<input class="form-control" type="text" required v-model="formB.account_code"> 
												</div>
											</div> -->
                                            <div class="form-group row">
                                                <label class="control-label col-md-2"
                                                    >Account Number:</label
                                                >
                                                <div class="col-md-3">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        required
                                                        v-model="formB.account_number"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-md-2">
                                                    <span class="text-dashed-underline">
                                                        Bank Name:
                                                    </span>
                                                </label>
                                                <div class="col-md-3">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        required
                                                        v-model="formB.bank_name"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-md-2">
                                                    <span class="text-dashed-underline">
                                                        Routing Number:
                                                    </span>
                                                </label>
                                                <div class="col-md-3">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        required
                                                        v-model="formB.routing_number"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-md-2"
                                                    >Description:</label
                                                >
                                                <div class="col-md-3">
                                                    <textarea
                                                        rows="3"
                                                        placeholder="Max 500 characters"
                                                        class="form-control"
                                                        v-model="formB.description"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-md-2">
                                                    <span class="text-dashed-underline">
                                                        Status:
                                                    </span>
                                                </label>
                                                <div class="col-md-3">
                                                    <select
                                                        class="form-control"
                                                        v-model="formB.activo"
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">
                                                            Inactive
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-3 col-md-offset-2">
                                                    <div class="checkbox">
                                                        <label>
                                                            <input
                                                                class="ember-checkbox"
                                                                type="checkbox"
                                                                v-model="formB.primary"
                                                            />
                                                            Make this primary
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-4 col-md-offset-2">
                                            <button class="btn btn-success" type="submit">
                                                Save</button
                                            >&nbsp;&nbsp;
                                            <button
                                                class="btn btn-secundary"
                                                @click="btnCancel"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    name: "BankingNew",
    data() {
        return {
            isLoading: false,
            formB: {
                user_id: window.localuserdata,
                title: "Add Bank or Credit Card",
                action: "create",
                account_type: true,
                account_type_transac: 1,
                account_name: "",
                account_code: "",
                account_number: "",
                bank_name: "",
                routing_number: "",
                description: "",
                activo: 1,
                primary: false,
            },
        };
    },
    created() {
        if (this.$route.params.idb) {
            this.formB.title = "Edit Bank or Credit Card";
            this.getBankingByid();
        }
    },
    mounted() {},
    methods: {
        btnCancel() {
            this.$router.push("/billing/banking/");
        },
        getBankingByid() {
            this.isLoading = true;
            window.billing
                .get("banking/" + this.$route.params.idb)
                .then((response) => {
                    this.isLoading = false;
                    console.log(response.data);
                    this.formB.action = "update";
                    if (response.data.result.account_type == 1) {
                        this.formB.account_type = true;
                    } else {
                        this.formB.account_type = false;
                    }
                    this.formB.account_type_transac =
                        response.data.result.account_type_transac;
                    this.formB.account_name = response.data.result.account_name;
                    this.formB.account_code = response.data.result.account_code;
                    this.formB.account_number = response.data.result.account_number;
                    this.formB.bank_name = response.data.result.bank_name;
                    this.formB.routing_number = response.data.result.routing_number;
                    this.formB.description = response.data.result.description;
                    this.formB.activo = response.data.result.activo;
                    if (response.data.result.primary == 1) {
                        this.formB.primary = true;
                    } else {
                        this.formB.primary = false;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        saveBanking() {
            console.log(this.formB);
            let urlAxios = "";
            let title = "Created";
            let text = "Successful registration";

            if (this.formB.action == "create") {
                urlAxios = "banking/add";
            } else if (this.formB.action == "update") {
                urlAxios = "banking/update/" + this.$route.params.idb;
                title = "Updated";
                text = "Successful Updated";
            }
            window.billing
                .post(urlAxios, this.formB)
                .then((response) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: title,
                        type: "success",
                        text: text,
                    });

                    this.btnCancel();
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.flexArea {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
</style>
