<template>
  <div>
    <div id="main-wrapper" >
      <Header-Partial></Header-Partial>
        <div v-if="getHeader" class="row" style="width:100%;">
          <router-view></router-view>
        </div>
        <div v-else>
          getHeader = {{getHeader}}
        </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HeaderPartial from '@/core/views/Header'
  export default {
    name:'DefaultLayout',
    components:{
      HeaderPartial
    },
    data(){
      return {
        show : false,
        accessAccount : false,
      }
    },
    created(){
      localStorage.setItem('appIDactual', 0);
      this.$store.commit('core/apps/SetAppIDactual');
    },
    methods: {
    },
    watch: {
      getHeader: function(){
        if(this.getHeader){
          let t= this;
          if(localStorage.getItem('appStart')){
              setTimeout(function(){
                  t.$store.commit('core/setNotiPush', {
                      show: true,
                      group: "notiPush",
                      type: '2', // 1=success|2=info|3=error|4=loading|5=warning
                      title: 'Please wait!',
                      // text: 'Accessing your account data.',
                      duration: 3000
                  });
              }, 0);
          }
        }
      }
    },
    computed: {
      ...mapGetters({get_isLoading: 'get_isLoading'}),
      ...mapGetters('core/session', {access: 'IngresoAccount'}),
      ...mapGetters('core', {getHeader: 'getHeader'})
    }
  };
</script>
