<template>
    <div>
        <div class="card-header">Edit Billing Info</div>
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="">Company name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_company_name"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="">First name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_firstname"
                                />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="">Last name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_lastname"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Address</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="p.bill_address"
                            />
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputCity">City</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_city"
                                />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputCity">State</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_state"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="">Zip code</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_zip_code"
                                />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="">Telephone</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.bill_telephone"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Website</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="p.bill_website"
                            />
                        </div>
                        <button type="submit" class="btn btn-primary float-right">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'editAccount',
    components: { },
    data () {
        return {
            p: {
                user_id: window.localAccountID,
                bill_company_name: '',
                bill_firstname: '',
                bill_lastname: '',
                bill_address: '',
                bill_city: '',
                bill_state: '',
                bill_zip_code: '',
                bill_telephone: '',
                bill_website: ''
            },
            user_id: 0,
        };
    },
    created () {
        let data = this.$store.getters['core/getOpenModal'].data;
        console.log('data modal', data);
        this.getEdit(data);
    },
    methods: {
        getEdit(d) {
            this.isLoading = true;
            this.user_id = d.userID;
            this.p.user_id = d.userID;
            this.p.bill_company_name = d.businnes;
            this.p.bill_firstname = d.name;
            this.p.bill_lastname = d.lastname;
            this.p.bill_address = d.address;
            this.p.bill_city = d.city;
            this.p.bill_state = d.state;
            this.p.bill_zip_code = d.zip_code;
            this.p.bill_telephone = d.telephone;
            this.p.bill_website = d.website;
            this.isLoading = false;
        },
        form() {
            this.$store.commit("core/setLoadingModal", true);
            let url = '/editbillinginfo';
            window.reselleR2
            .post(url, this.p)
            .then((response) => {
                console.log(response.data)
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                    title: "Update",
                    text: "Successful.",
                    show: true,
                    duration: 5000
                });
                this.$store.commit("core/setLoadingModal", false);
                this.$store.commit("core/setCloseModal", {action: 'saved', method: 'refresh-edit'});                         
            })
            .catch((error) => {
                console.log(error)
                this.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                        title: "Error",
                        text: "updating data.",
                        show: true,
                        duration: 10000
                    });
                this.$store.commit("core/setLoadingModal", false);
            })
        },
    },
};
</script>
