<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">Project #{{ numdoc }}</h5>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding: 0.5em !important;max-height: 400px;min-height: 200px;overflow: hidden;overflow-y: auto;">
            <div class="row">
                <div class="col-md-12">
                    <input type="file"
                        id="imgNotes"
                        accept=".jpg,.jpeg,.png"
                        multiple
                        style="display: none"
                        @change="subirIMG($event)"
                        ref="addGalley"
                        v-if="editGallery"
                    />
                    <div class="btn-group" v-if="editGallery">
                        <button type="button" class="btn btn-secondary" @click="displayRef" v-if="galleryList.length<20">
                            <i class="fas fa-paperclip"></i> Upload Images
                        </button>
                        <button type="button" class="btn btn-danger" @click="deleteAllIMG" v-if="galleryList.length > 0" style="margin-left: 10px">
                            <i class="fas fa-trash"></i> Delete All
                        </button>
                    </div>
                    <gallerysales css="h-300"
                        :items="galleryList"
                        :url="galleryURL"
                        :typez="typez"
                        :cells="5"
                        :salesID="salesID"
                        :cover="cover"
                        v-if="ifGallery == 1"
                    ></gallerysales>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { mapGetters } from 'vuex'
import gallerysales from '@/apps/Marketea/plugins/gallery/gallerySales'
export default {
    name: "galleryBox",
    components: {
        gallerysales,
    },
    data () {
        return {
            typez: 0,
            numdoc: 0,
            salesID: 0,
            jobsNote: "",
            editGallery: true,
            ifGallery: 0,
            galleryList: [],
            galleryURL: "",       
            cover: 0,       
        }
    },
    created () {
        this.init();
    },
    methods: {
        init() {
            let t = this;
            let urlApis = t.$store.getters['ModApps/jobs/urlApis'];
            t.apis = urlApis.jobBox;            
            let g = t.$store.getters['core/getOpenModal'];
            let r = g.data;
            t.numdoc = r.id
            t.salesID = r.id
            t.cover = (r.cover) ? r.cover.id : 0;
            t.$store.commit("core/setLoadingModal", true);
            window.master.post('getSalesGallery', {    
                user_id: window.master_client,
                org_id: window.localorgdata,
                sales_id: t.salesID,
            })
            .then((response) => {
                console.log('getSalesGallery', response.data)
                if(response.data.status){
                    t.ifGallery = 1;
                    t.galleryURL = response.data.url;
                    t.galleryList = response.data.g;
                    for (let e in t.galleryList) {
                        if(t.galleryList[e]){
                            if(t.galleryList[e].cover > 0){
                                t.cover = t.galleryList[e].id;
                            }
                        }
                    }
                }else{
                    t.ifGallery = 0;
                }
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
        },
        subirIMG(event) {
            let t = this;
            if(event.target.files.length){
                t.$store.commit("core/setLoadingModal", true);
                let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
                let formData = new FormData();
                let cont = 0;
                let total = t.galleryList.length;
                for (let i in event.target.files) {
                    if (event.target.files.hasOwnProperty(i)) {
                        total++;
                        if(total<=20){
                            cont++;
                            formData.append("image" + cont, event.target.files[i]);
                        }
                    }
                }
                formData.append("nombreIMG", "image");
                formData.append("contIMG", cont);
                formData.append("sales_id", this.salesID);
                formData.append("org_id", window.localuserdata);
                formData.append("user_id", window.master_client);
                formData.append("folder", 'sales');
                formData.append("delIMG", "image");
                formData.append("contDel", 0);
                window.master
                .post('salesGallery', formData, fileSendHeader)
                .then((response) => {
                    console.log('salesGallery', response.data)
                    t.$store.commit("core/setLoadingModal", false);
                    if(total>20){
                        t.$notify({
                            group: 'noti',
                            type: 'error',
                            title: 'ERROR',
                            text: 'Maximum limit 20 images',
                        });
                    }
                    t.init();
                    t.$refs.addGalley.value=null;
                })
                .catch((error) => {
                    console.log(error);
                    t.$refs.addGalley.value=null;
                    t.$store.commit("core/setLoadingModal", false);
                });
            }
        }, 
        displayRef() {
            this.$refs.addGalley.click();
        },    
        deleteAllIMG() {
            let t = this;
            this.$swal({
                title: "Delete all image?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.$store.commit("core/setLoadingModal", true);
                    window.master
                        .post('delSalesGallery', {
                            user_id: window.master_client,
                            org_id: window.localorgdata,
                            sales_id: t.salesID,
                            delete: 'all',
                        })
                        .then((response) => {
                            if (response.data.status) {
                                t.init();
                            }
                            t.$store.commit("core/setLoadingModal", false);
                        })
                        .catch((error) => {
                            console.log(error);
                            t.$store.commit("core/setLoadingModal", false);
                        });
                }
            });
        },
    },
    watch: {
        getOpenModal: function() {
            /*if (this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data;
                    if (t.methods === 'gallerySales') {
                        this.init();
                    }
                }
            }*/
        },
    },
    computed: {
        ...mapGetters("core", {getOpenModal: "getOpenModal"})
    }
};
</script>
<style lang="scss" scoped>
.jobsNote img {
    width: 50%;
}
</style>
