let get_actList = function() {
	//refresh
	let nameRouter = this.$router.currentRoute.name;
	if (nameRouter == "Jobs" || nameRouter == "JobsComppleted")
	{
		this.active = 0;
		this.id_tags = [];
		this.getJobsDash();
	} else {
		if (this.$route.params.id > 0) {
			this.active = this.$route.params.id;
			this.id_tags = [];
			this.id_tags.push(this.$route.params.id);
			this.getJobsDash();
		}
	}
};
export default get_actList;
