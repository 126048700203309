let AssignUserModal = function(num, id) {
    let df = [];
    df.push({ ids: id });
    this.idJobs = df;
    this.userID = id;            
    this.closeModals = 0;
    this.titleModalTaks = "Job #" + num;
    let g = {
        userID: this.userID,
        taksID: this.taksID,
        idJobs: this.idJobs,
        titleModalTaks: this.titleModalTaks,
        closeModals: this.closeModals,
        typez:this.typez,
        methods:"assignUser",
        action:"modal",
    }
    this.$store.commit("ModApps/jobs/set_jobsDash", g);      
};
export default AssignUserModal;
