export default { 
    setAuthResller(state, val){state.auth = val},
    setLoggedInResller(state, val) {
        if(!localStorage.getItem('oauth_r')){
            console.log('sessionTemp', state.sessionTemp); 
            state.token_r = state.sessionTemp.accessToken; 
            localStorage.setItem('access_token_r', state.sessionTemp.accessToken);
            localStorage.setItem('resellerId', state.sessionTemp.user_id);
            localStorage.setItem('oauth_r', state.sessionTemp.oauth);
            localStorage.setItem('ui', 'Reseller');
        }else {state.token_r = val;}
    },
    setSessionTemp(state, val){
        state.sessionTemp = val
        console.log('setSessionTemp', state.sessionTemp);
    },
    destroyTokenResller(state) {
        state.auth = false; 
        state.token = null; 
        state.sessionTemp = null; 
        // localStorage.clear(); 
        let excluir = ["manifestJSON", "versionCache"];
        Object.keys(localStorage).forEach(arr => {
            if (excluir.indexOf(arr) < -1) {
                localStorage.removeItem(arr);
                console.log('clear', arr)
            }
        });
    },
    ActiveLateral(state, val) { state.lateralShow = val; },
    setProfile(state, val) { state.profile = val; },
}
