<template>
    <div>
        <div class="card-header">
            <h3>Forms Code</h3>
        </div>
        <div class="card-body" style="min-height: 200px">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <div class="row" v-if="isCode">
                <div class="col-sm-12">
                    <h4>Short Code</h4>
                </div>
                <div class="col-sm-12">
                    <textarea
                        id="shortCode"
                        style="height: 30px; width: 350px; display: block; text-align: center;"
                        readonly
                        onClick="this.select()"
                        v-model="shortCode"
                    ></textarea>
                </div>
                <div class="col-12" style="margin-top: 10px;">
                    <button class="btn btn-primary" @click="copyShortCode">Copy Short code</button>
                </div>
                <div class="col-sm-12" style="margin-top: 20px;">
                    <h4>Code JavaScript</h4>
                </div>
                <div class="col-sm-12">
                    <textarea
                        id="codeHtml"
                        style="height: 200px; width: 100%; display: block"
                        readonly
                        onClick="this.select()"
                        v-model="codeHtml"
                    ></textarea>
                </div>
                <div class="col-12" style="margin-top: 10px;">
                    <button class="btn btn-primary" @click="copyhtml">Copy code JavaScript</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "widgetCode",
    components: {
        Loader,
    },
    props: {
      form_id: {
        type: Number,
        required: true,
        default: 0
      }
    },
    data() {
        return {
            url: window.urlmaster + "api/forms/app/" + this.form_id +"/",
            org_id: window.localuserdata,
            user_id: window.master_client,
            isLoading: false,
            isCode: true,
            codeHtml: "",
            shortCode: "",
        };
    },
    created() {
        this.changeTextarea();
    },
    methods: {
        changeTextarea() {
            var i = "";
            i += '<div id="appForms"></div>';
            i += '<script id="appScriptForms">';
            i += "var d=document,appForms=" + this.org_id;
            i +=',IdDiv="appForms",IdForms="widgetForms", idScript="widgetFormsScript", f=new Date,';
            i += 'urlForms="' + this.url + '",';
            i += "t=d.querySelector('#appScriptForms'), s=d.createElement('script');";
            i += 's.type="text/javascript",s.id=idScript,';
            i += "s.defer=!0,s.src=urlForms+appForms+'/'+f.getTime(),t.after(s);";
            i += "</" + "script>";
            this.codeHtml = i;
            // < = &#60; > = &#62;
            let e = "[";
            e+= "shortCode ";
            if(window.urlmaster == "https://master.marketeabeta.com/"){
                e+= "appB=forms ";
            }
            else{
                e+= "app=forms ";
            }
            e+= "id="+this.form_id+" ";
            e+= "org="+this.org_id+" ";
            e+= "user="+this.user_id+" ";
            e+= "/]";
            this.shortCode = e;
        },
        copyhtml() {
            var element = document.querySelector("#codeHtml").select();
            try {
                document.execCommand("copy");
                this.$swal({
                    icon: "success",
                    title: "Code copied",
                    timer: 1000,
                });
            } catch (err) {
                console.log("unable to copy code");
            }
        },
        copyShortCode() {
            var element = document.querySelector("#shortCode").select();
            try {
                document.execCommand("copy");
                this.$swal({
                    icon: "success",
                    title: "Code copied",
                    timer: 1000,
                });
            } catch (err) {
                console.log("unable to copy code");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
