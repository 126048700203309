<template>
    <div class="container-fluid container_dimenu_widget">
        <article :class="['container-menu_widget', (isModeDark==1) ? 'modeDark' : '']">
            <nav-category
                @clickItem="showSection($event)"
                @selectCat="changeCatActive($event)"
                @liOffsetWidth="menuLiOffsetWidth($event)"
                :listCat="listCat"
                :menuCatActivo="categoryActive"
                v-if="listCat.length>0"></nav-category>
            <food-list
                v-if="listCat.length>0"
                @followItem="indicadorMenu($event)"
                @sectionActive="sectBg($event)"
                :urlMedia="urlMedia+path_base"
                :listCat="listCat"
                :getCurrency="currency"
                :menuCatActivo="categoryActive"
                @selectCat="changeCatActive($event)"
            ></food-list>
        </article>
    </div>
</template>
<script>
import "./stylesGlobal.scss"
import "./modeDark.scss"
import nav_bMenu from "./components/nav_bMenu.vue"
import foodList from "./components/foodList.vue"
export default {
    props: ['modeDark'],
    components:{
        'nav-category': nav_bMenu,
        'food-list': foodList,
    },
    data(){
        return {
            isLoading: false,
            apiProgess: 0,
            listCat: [],
            categoryActive: null,
            apis: null,
            pagRows: 0,
            searchCategory: null,
            filter_status: [1],
            ordenKey: 'orden',
            ordenVal: 'ASC',
            filter_group: [],
            urlMedia: null,
            path_base: null,
            isModeDark: null,
            currency: null,
        };
    },
    created(){
        let t = this;
        t.isModeDark = t.modeDark;
        let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
        t.apis = urlApis.bMenu;
        let listCat = t.$store.getters['ModApps/Marketea/widget_listCat'];
        let categoryMain = t.$store.getters['ModApps/Marketea/widget_categoryMain'];
        let urlMedia = t.$store.getters['ModApps/Marketea/widget_urlMedia'];
        let path_base = t.$store.getters['ModApps/Marketea/widget_path_base'];
        let currency = t.$store.getters['ModApps/Marketea/bmenu_currency'];
        if(listCat!=null && categoryMain!=null && urlMedia!=null && path_base!=null){
            t.urlMedia = urlMedia;
            t.path_base = path_base;
            t.listCat = listCat;
            t.categoryActive = categoryMain;
            t.currency = currency;
        }
        else{
            t.getList();
        }
    },
    mounted(){
        let t = this;
    },
    watch: {
        modeDark(){
            let t = this;
            t.isModeDark = t.modeDark;
        },
    },
    methods: {
        getList(noLoad){
            let t = this;
            let urlService = t.apis.get(0); //setCat
            if(noLoad==undefined){t.isLoading = true;}
            // @ts-ignore
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                rows: t.pagRows,
                filter_group: t.filter_group,
                filter_name: t.searchCategory,
                filter_status: t.filter_status,
                ordenKey: t.ordenKey,
                ordenVal: t.ordenVal,
            }, {
                headers: {
                    common: {
                    // @ts-ignore
                    Authorization: 'Bearer ' + window.localtoken_M
                    },
                    // post: { 'Content-Type': 'multipart/form-data' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                // @ts-ignore
                // console.log(urlService, response.data);
                t.urlMedia = response.data.path.url;
                t.path_base = response.data.path.path_base;
                if(response.data.list.total>0){
                    t.listCat = response.data.list.data;
                    console.log(urlService, t.listCat)
                    t.categoryActive = t.listCat[0];
                    t.setStore('widget_listCat', t.listCat);
                    t.setStore('widget_categoryMain', t.listCat[0]);
                    t.setStore('widget_urlMedia', t.urlMedia);
                    t.setStore('widget_path_base', t.path_base);
                }
                if(response.data.currency){
                    t.$store.commit('ModApps/Marketea/set_bmenu_currency', response.data.currency.meta_value);
                    t.currency =response.data.currency.meta_value;
                }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        setStore(key, val){
            let t = this;
            t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key, val});
        },
        showSection($event){
            // console.log('showSection', $event)
            let t = this;
            t.foodScrolling($event);
            t.sectBg("#section-"+$event);
            setTimeout(() => {t.indicadorMenu();}, 200);
        },
        indicadorMenu(elemtId){
            const menu = document.querySelector('.nav-category');
            const indicador = document.getElementById('indicador');
            let listNav = menu.querySelectorAll('ul.menu-categores li');
            if(listNav.length > 2){
                let itemActivo = menu.querySelector('ul.menu-categores li a.active');
                const food_list = document.querySelector('.container_food_list');
                const secciones = document.querySelectorAll('.food_seccion');
                if(itemActivo){
                    let tamañoIndicador = itemActivo.offsetWidth;
                    indicador.style.width = tamañoIndicador + 'px';
                    // Volvemos a posicionar el indicador.
                    let indexSeccionActiva = itemActivo.offsetLeft
                    indicador.style.transform = `translateX(${indexSeccionActiva}px)`;
                    console.log('indicador width', indicador.style.width);
                    console.log('indicador translateX', indexSeccionActiva);
                    // console.log('menu.offsetWidth', menu.offsetWidth);
                    let leftScroll = menu.offsetWidth - (indexSeccionActiva+tamañoIndicador);
                    console.log('leftScroll', leftScroll)
                    if(leftScroll < 0){
                        menu.scrollLeft = (leftScroll * -1) + 40;
                        console.log('scrollLeft --', menu.scrollLeft)
                    }else if(leftScroll > 0){
                        menu.scrollLeft-= leftScroll;
                        console.log('scrollLeft ++', menu.scrollLeft)
                    }
                }else{
                    console.log('no hay a.active')
                    indicador.style.width = '0px';
                    indicador.style.transform = `translateX(0px)`;
                }
            }
        },
        foodScrolling(elemtId){
            const food_list = document.querySelector('.container_food_list');
            const food_seccions = document.querySelectorAll('.food_seccion').length;
            if(food_seccions>1){
                if(document.querySelector("#section-"+elemtId)){
                    food_list.scrollTop = document.querySelector("#section-"+elemtId).offsetTop - 80;
                }
            }
        },
        sectBg(idS){
            // console.log('sectBg', idS)
            if(document.querySelectorAll('.food_seccion').length>2 && document.getElementById(idS)){
                document.querySelectorAll('.food_seccion').forEach(s => {document.getElementById(s.id).style.opacity='0.5';});
                document.getElementById(idS).style.opacity='1';
            }
        },
        changeCatActive(event){
            this.categoryActive = event;
        },
        menuLiOffsetWidth(event){
            const mainNav = document.querySelector(".main-nav-category");
            if(mainNav){
                const ulMenuCat = document.querySelector("ul.menu-categores");
                if(ulMenuCat){
                    let navCat = 0;
                    ulMenuCat.querySelectorAll('li').forEach(li => {navCat += li.offsetWidth});
                    if(navCat < mainNav.offsetWidth){
                        ulMenuCat.offsetWidth
                        let liWidth = mainNav.offsetWidth / ulMenuCat.querySelectorAll('li').length;
                        if(liWidth>0 && ((liWidth * ulMenuCat.querySelectorAll('li').length)>=mainNav.offsetWidth)){
                            console.log('ok', liWidth)
                            ulMenuCat.querySelectorAll('li').forEach(li => {
                                li.style.flex='auto';
                                li.style.display='flex';
                                li.style.justifyContent='center';
                            });
                            ulMenuCat.style.width='100%';
                            ulMenuCat.style.justifyContent='space-around';
                        }else{console.log('error', liWidth)}
                    }
                    this.indicadorMenu();
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
