<template>
    <div>
        <TimesheetHeader></TimesheetHeader>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" id="card">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row">
                                <div class="botonera-filtros">
                                    <div class="btn-add-invo">
                                        <button
                                            class="btn btn-success"
                                            @click="addTimesheettask"
                                            style="color: white"
                                        >
                                            <i class="fas fa-plus"></i>
                                            Add Task
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div
                                class="row"
                                v-if="isLoading"
                                style="display: block; margin: 0 auto"
                            >
                                <Loader></Loader>
                            </div>
                            <div class="row" v-else style="padding-bottom: 130px">
                                <div class="col-md-12">
                                    <table
                                        width="90%"
                                        class="table table-bordered table-striped table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col" style="text-align: end">
                                                    Hours
                                                </th>
                                                <th scope="col" style="text-align: end">
                                                    Amount
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Edit
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Delete
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="task.length > 0">
                                            <tr
                                                v-for="(item, index) in task"
                                                :key="index"
                                            >
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.description }}</td>
                                                <td style="text-align: right">
                                                    {{ item.horas }}
                                                </td>
                                                <td style="text-align: right">
                                                    {{ item.amount }}
                                                </td>
                                                <td style="text-align: center">
                                                    <button
                                                        @click="editTimesheettask(item)"
                                                        class="btn btn-default"
                                                    >
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                </td>
                                                <td style="text-align: center">
                                                    <button
                                                        @click="deleteTask(item)"
                                                        class="btn btn-danger"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" align="center"></td>
                                                <td style="text-align: right">
                                                    <strong>{{ StotalHoras }} hrs</strong>
                                                </td>
                                                <td style="text-align: right">
                                                    <strong>${{ StotalPagos }}</strong>
                                                </td>
                                                <td colspan="2" align="center"></td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="6" align="center">
                                                    NO RECORD
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TimesheetHeader from '@/apps/billing/views/Timesheetheader'
import Loader from '@/core/components/shared/Loader'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formTask from '@/apps/billing/components/timesheet/formTask'
export default {
    name: "TimesheetTask",
    components: {
        Loader,
        TimesheetHeader,
        modalLateral,
        formTask
    },
    data() {
        return {
            isLoading: false,
            isDisabled: false,
            f: {
                id: 0,
                user_id: window.localorgdata,
                name: "",
                description: "",
            },
            task: [],
            StotalHoras: 0,
            StotalPagos: 0,
            m: {
                show: false,
                component: '',
                data: []
            }            
        }
    },
    created() {
        this.getTask();
    },
    methods: {
        getTask() {
            this.isLoading = true;
            window.billing
                .post("timesheettask/get", this.f)
                .then((response) => {
                    console.log(response.data);
                    this.task = response.data.result;
                    this.StotalHoras = response.data.totalhoras;
                    this.StotalPagos = response.data.totalamount;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        editTimesheettask(item) {
            this.f.id = item.id;
            this.f.name = item.name;
            this.f.description = item.description;
            let t = this;
            this.m = {
                show: true,
                component: 'formTask',
                data: {f:t.f}
            }  
            this.$store.commit('core/setOpenModal', this.m);   
            this.addTimesheettask();          
        },
        addTimesheettask() {
            let t = this;
            this.m = {
                show: true,
                component: 'formTask',
                data: {f:t.f}
            }  
            this.$store.commit('core/setOpenModal', this.m);   
        },        
        deleteTask(item) {
            this.$swal({
                title: "Delete this Task?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                this.isLoading = true;
                if (result.value) {
                    window.billing
                        .post("timesheettask/del", item)
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            this.closeModal();
                            this.getTask();
                            this.refresTimesheettask();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        closeModal() {
            this.f.id = 0;
            this.f.name = "";
            this.f.description = "";
        },
        refresTimesheettask() {
            this.f.id = 0;
            this.f.name = "";
            this.f.description = "";
            this.task = [];
            this.StotalHoras = 0;
            this.StotalPagos = 0;
        },
    },
    watch: {
        'getOpenModal': function() {
            // console.log('timesheet getOpenModal', this.getOpenModal.show);
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.getTask();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        }
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    }    
}
</script>
<style lang="scss" scoped>
.botonera-filtros {
    padding: 0px 15px;
    display: flex;
    width: 100%;
}
.btn-add-invo,
.btn-sync {
    margin-right: 10px;
}
.btn-add-invo {
    width: 10%;
    max-width: 115px;
    min-width: 115px;
}
.btn-sync {
    width: 4px;
    max-width: 35px;
    min-width: 35px;
}
.filtros {
    width: 70%;
    margin-left: 16%;
}
@media (min-width: 1320px) {
    .mobile_display {
        justify-content: flex-end;
    }
}
.uno {
    width: 550px;
    margin-right: 10px;
}
.dos {
    width: 70px;
    margin-right: 10px;
}
.tres {
    width: 102px;
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 10%;
        width: 76%;
    }
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 5%;
        width: 81%;
    }
}
@media (max-width: 1100px) {
    .filtros {
        max-width: 924px;
    }
    .uno {
        width: 400px;
        margin-right: 10px;
    }
}
@media (max-width: 1000px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 300px;
    }
}
@media (max-width: 875px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
    .botonera-filtros {
        display: block;
    }
    .btn-add-invo,
    .btn-sync {
        display: inline-block;
        margin-bottom: 10px;
    }
    .filtros {
        margin-left: 0%;
        margin-bottom: 10px;
        width: 100%;
        padding-left: 15px;
    }
}
.btn-filter-search:hover {
    cursor: pointer;
}

.tres button.btn,
.cuatro button.btn {
    padding: 7px 10px;
}

.cdropdown ul li {
    padding: 3px 10px;
}
.cdropdown ul li button {
    font-size: 0.76rem;
}
#card-invoice {
    background: transparent;
}
.dropdStatus {
    list-style: none;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    @media (max-width: 767px) {
        right: 0;
    }
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.scroll-right {
    display: none;
}

.btnDropActions {
    border-color: #ccc;
    padding: 0;
}
.btnDropActions label input {
    display: none;
}
.btnDropActions label:hover {
    cursor: pointer;
}
.btnDropActions label {
    padding: 0.375rem 0.5rem;
    margin-bottom: 0.1rem;
    font-size: 12px;
}
.textAction {
    font-size: 12px;
}
.dropdown-item.action-item {
    padding: 3px 5px 3px 10px;
}

/* ************************************* */

.invoList tbody tr td {
    font-size: 0.775rem;
}

.invoList thead tr th:nth-child(1),
.invoList tbody tr td:nth-child(1) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(2),
.invoList tbody tr td:nth-child(2) {
    min-width: 92px;
    width: 7.937877480586713%;
}
.invoList thead tr th:nth-child(3),
.invoList tbody tr td:nth-child(3) {
    min-width: 103px;
    width: 8.886971527178602%;
}
.invoList thead tr th:nth-child(4),
.invoList tbody tr td:nth-child(4) {
    min-width: 200px;
    width: 17.25625539257981%;
}
.invoList thead tr th:nth-child(5),
.invoList tbody tr td:nth-child(5) {
    min-width: 178px;
    width: 15.35806729939603%;
}
.invoList thead tr th:nth-child(6),
.invoList tbody tr td:nth-child(6) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(7),
.invoList tbody tr td:nth-child(7) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(8),
.invoList tbody tr td:nth-child(8) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(9),
.invoList tbody tr td:nth-child(9) {
    min-width: 73px;
    width: 6.298533218291631%;
}
.invoList thead tr th:nth-child(10),
.invoList tbody tr td:nth-child(10) {
    min-width: 40px;
    width: 3.451251078515962%;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 71px;
    width: 6.125970664365833%;
}
.invoList thead tr th:nth-child(12),
.invoList tbody tr td:nth-child(12) {
    min-width: 110px;
    width: 9.490940465918896%;
}

.invoList thead {
    width: 100%;
    display: block;
    background: #fff;
}
.invoList tbody {
    width: 100%;
    display: block;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 290px;
}
.invoList thead tr,
.invoList tbody tr {
    display: block;
}
.invoList thead tr th {
    display: inline-block;
    height: 34px;
}
.invoList tbody tr td {
    display: inline-block;
    height: 50px;
}
.table-bordered th,
.table-bordered td {
    border: 0px solid transparent !important;
}

.contenedor-invoice table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 70px;
    width: 70px;
}
table.table.table-striped.table-bordered.table-hover.invoList {
    border: 0px;
}
.contenedor-invoice table {
    width: 100%;
    min-width: 1180px;
}
.contenedor-invoice {
    padding: 0px 10px 0px 10px;
    overflow-x: auto;
    width: calc(100vw - 170px);
}
@media (max-width: 1230px) {
    .scroll-right {
        display: block;
    }
}
@media (max-width: 1100px) {
    .invoList thead tr th:nth-child(1),
    .invoList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    .invoList thead tr th:nth-child(2),
    .invoList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(3),
    .invoList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(4),
    .invoList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    .invoList thead tr th:nth-child(5),
    .invoList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    .invoList thead tr th:nth-child(6),
    .invoList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(7),
    .invoList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    .invoList thead tr th:nth-child(8),
    .invoList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(9),
    .invoList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(10),
    .invoList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    .invoList thead tr th:nth-child(11),
    .invoList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-invoice {
        max-width: 924px;
    }
}
.invoList tbody tr:nth-of-type(odd) {
    background-color: #e9ecef;
}
.invoList tbody tr:nth-of-type(even) {
    background-color: #fff;
}
</style>
