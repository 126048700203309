<template>
    <div>
        <div class="form-group">
            <textarea
                v-model="customCSS"
                required="required"
                class="form-control"
                placeholder="/*Add css code*/"
                style="height: 72vh"
            ></textarea>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "customCss",
    data() {
        return {
            customCSS: "",
            form: [],
            styleForm: [],
            designs: [],
            inputs: [],
        };
    },
    created() {
        this.getForms();
    },
    methods: {
        getForms() {
            if (this.$store.getters["ModApps/Marketea/get_formBuilt"]) {
                let e = this.$store.getters["ModApps/Marketea/get_formBuilt"];
                this.form = e.result;
                this.customCSS = e.s.custom_code;
                this.styleForm = e.s;
                this.designs = e.s;
                this.inputs = JSON.parse(this.form.inputs);
            } else {
                this.$notify({
                    group: "noti",
                    title: "ERROR",
                    type: "error",
                    text: "An error has occurred",
                });
            }
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/Marketea/set_builtForm", k);
        },
    },
    watch: {
        get_builtForm: function () {
            if (this.get_builtForm) {
                let g = this.$store.getters["ModApps/Marketea/get_builtForm"];
                let fun = g[0].function;
                if (fun == "SaveCustomCss") {
                    let data = [];
                    data.push({
                        inputs: this.inputs,
                        form: this.form,
                        designs: this.designs,
                        custom_code: this.customCSS,
                    });
                    this.arrays("RSaveCustomCss", data);
                }
            }
        },
        customCSS: {
            handler: function (after, before) {
                if (before != "") {
                    let r = [];
                    r.push({
                        customCSS: after,
                    });
                    this.$store.commit("ModApps/Marketea/set_customCssStorage", r);
                } else {
                    let g = this.$store.getters["ModApps/Marketea/get_customCssStorage"];
                    let count = g.length;
                    if (count > 0) {
                        this.customCSS = g[0].customCSS;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_builtForm"]),
    },
};
</script>
<style lang="scss" scoped></style>
