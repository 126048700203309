import { render, staticRenderFns } from "./foodSlider.vue?vue&type=template&id=25c2779a&scoped=true&"
import script from "./foodSlider.vue?vue&type=script&lang=js&"
export * from "./foodSlider.vue?vue&type=script&lang=js&"
import style0 from "./foodSlider.vue?vue&type=style&index=0&id=25c2779a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c2779a",
  null
  
)

export default component.exports