let dashEditTask = function(i, a, b, c, d, e) {
    let idTask = i;
    let idJobs = a;
    let idStatus = b;
    let ActStatus = c;
    let idx = d;
    let taksIdx = e;
    this.f.id_status = 1;
    if (idStatus == 1) {
        this.f.id_status = 3;
    }
    this.f.id = idTask;
    this.f.id_jobs = idJobs;
    if (idx == 0) {
        //comprobar idx sea autentico
        for (let j in this.listTaks) {
            if (this.listTaks.hasOwnProperty(j)) {
                if (this.listTaks[j].id_i == idJobs && idx != j) {
                    idx = j;
                }
            }
        }
    }
    this.listTaksResult[idx].taks[taksIdx].isLoading = true;
    let v = this;
    let url = this.apis.dashEditTask()
    window.master
        .post(url, {
            data: this.f,
        })
        .then((response) => {
            let s = response.data.s;
            v.listTaks[idx].id_status = s.act_status;
            v.listTaks[idx].taks[taksIdx].id_status = s.taks_status;
            v.listTaks[idx].taks[taksIdx].isLoading = false;
            if (
                s.act_status == 4 &&
                v.$router.currentRoute.name != "JobsComppleted"
            ) {
                v.listTaks.splice(idx, 1);
                /*v.$notify({
                    group: "noti",
                    type: "success",
                    title: "Success",
                    text: "Job moved to completed",
                });*/
            } else if (
                s.act_status < 4 &&
                v.$router.currentRoute.name == "JobsComppleted"
            ) {
                v.listTaks.splice(idx, 1);
                /*v.$notify({
                    group: "noti",
                    type: "success",
                    title: "Success",
                    text: "Job moved to main list",
                });*/
            }
            v.reEscribirLista(idx);
            v.starGrid();
            v.$store.commit("ModApps/jobs/set_editTask", {});
            let tag_id = v.id_tags.length > 0 ? v.id_tags[0] : 0;
            v.update_JOPending(tag_id);
        })
        .catch((error) => {
            console.log(error);
            v.listTaksResult[idx].taks[taksIdx].isLoading = false;
            v.$store.commit("ModApps/jobs/set_editTask", {});
        });
};
export default dashEditTask;
