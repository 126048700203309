<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">
                    Search by Date
                </h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <form @submit.prevent="SearchDate" class="col-md-6" style="padding:15px;background-color: #f2f2f2;border-radius: 4px;">
                        <div class="form-group">
                            <h3>Date</h3>
                        </div>
                        <div class="form-group">
                            <span style="padding-right: 4px; padding-left: 8px"
                                ><b>From:</b></span
                            >
                            <datetime
                                type="date"
                                v-model="dateFrom"
                                format="MMM dd, yyyy"
                                input-class="form-control"
                                value-zone="America/New_York"
                            ></datetime>
                        </div>
                        <div class="form-group">
                            <span style="padding-right: 4px; padding-left: 8px"
                                ><b>To:</b></span
                            >
                            <datetime
                                type="date"
                                v-model="dateTo"
                                format="MMM dd, yyyy"
                                input-class="form-control"
                                value-zone="America/New_York"
                            ></datetime>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-success">
                                Search Date
                            </button>
                        </div>
                    </form>
                    <form @submit.prevent="SearchDueDate" class="col-md-6" style="padding:15px;background-color: #fdfdfd;border-radius: 4px;">
                        <div class="form-group">
                            <h3>Due Date</h3>
                        </div>
                        <div class="form-group">
                            <span style="padding-right: 4px; padding-left: 8px"
                                ><b>From:</b></span
                            >
                            <datetime
                                type="date"
                                v-model="duedateFrom"
                                format="MMM dd, yyyy"
                                input-class="form-control"
                                value-zone="America/New_York"
                            ></datetime>
                        </div>
                        <div class="form-group">
                            <span style="padding-right: 4px; padding-left: 8px"
                                ><b>To:</b></span
                            >
                            <datetime
                                type="date"
                                v-model="duedateTo"
                                format="MMM dd, yyyy"
                                input-class="form-control"
                                value-zone="America/New_York"
                            ></datetime>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-success">
                                Search Due Date
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { },
    name: "formFilterFechas",
    data () {
        return {
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),            
            duedateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            duedateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            sDate: false,
            inputFilter: "",
            statuslist: [1, 2, 3, 4, 5],
            paginador: 0,
            InvoicesList: [],
            InvoicesListResult: [],
            isFilter: false,
            query: "",
            rowNum: 100,
            jobs: 0,
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.query = r.query
            this.inputFilter = r.inputFilter
            this.fieldsFilter = r.fieldsFilter
            this.paginador = r.paginador
            this.rowNum = r.rowNum
            this.statuslist = r.statuslist
            this.jobs = r.jobs
        },
        SearchDate() {
            this.sDate = true
            this.searchFilter();
        },
        SearchDueDate() {
            this.sDate = false
            this.searchFilter();
        },
        searchFilter() {
            this.$store.commit("core/setLoadingModal", true);   
            let duedesde
            let duehasta
            let desde
            let hasta            
            if (this.sDate) {
                desde = this.dateFrom
                hasta = this.dateTo
                duedesde = ''
                duehasta = ''
            } else {
                desde = ''
                hasta = ''
                duedesde = this.duedateFrom
                duehasta = this.duedateTo
            }     
            window.billing
                .post("filterinvoice" + this.query, {
                    user_id: window.localuserdata,
                    filter: this.inputFilter,
                    fieldsFilter: this.fieldsFilter,
                    status: this.statuslist,
                    pag: this.paginador,
                    rowNum: this.rowNum,
                    desde: desde,
                    hasta: hasta,
                    duedesde: duedesde,
                    duehasta: duehasta,
                    jobs: this.jobs,
                })
                .then((response) => {
                    // console.log(response.data);
                    this.pagination = response.data.lista;
                    let listFilter = Object.values(response.data.lista.data);
                    let e = listFilter;
                    let listOverdue = [];
                    let listStatus = [];
                    let soloFilter = [];
                    for (var i = 0; i < e.length; i++) {
                        let fecdoc = new Date(e[i].fecdoc).toDateString();
                        let fecdue = new Date(e[i].fecdue).toDateString();
                        let f_a = new Date();
                        fecdue = new Date(e[i].fecdue);
                        listFilter[i].actions = false;
                        if (f_a > fecdue && e[i].idxstatus < 2) {
                            listFilter[i].idxstatus = 4;
                            listOverdue.push(listFilter[i]);
                        } else {
                            listStatus.push(listFilter[i]);
                        }
                        if (this.statuslist.includes("" + listFilter[i].idxstatus + "")) {
                            soloFilter.push(listFilter[i]);
                        }
                    }
                    if (this.statuslist.length == 1 && this.statuslist.includes("4")) {
                        this.InvoicesList = listOverdue;
                    } else if (
                        this.statuslist.length > 0 &&
                        this.statuslist.length < 5 &&
                        this.statuslist.includes("4")
                    ) {
                        this.InvoicesList = soloFilter;
                    } else if (
                        this.statuslist.length > 0 &&
                        this.statuslist.length < 5 &&
                        !this.statuslist.includes("4")
                    ) {
                        this.InvoicesList = listStatus;
                    } else {
                        this.InvoicesList = listFilter;
                    }
                    this.InvoicesListResult = this.InvoicesList;
                    if (
                        this.inputFilter.length > 0 ||
                        this.statuslist.length != 5 ||
                        desde.length > 0 ||
                        hasta.length > 0 ||
                        duedesde.length > 0 ||
                        duehasta.length > 0
                    ) {
                        this.isFilter = true;
                    }
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", true);
                    this.$store.commit("core/setActionRefresh", {
                        action: 'saved',
                        data: {
                            methods:'invoFilter',
                            isFilter:t.isFilter,
                            InvoicesListResult:t.InvoicesListResult,
                            statuslist:t.statuslist,
                            inputFilter:t.inputFilter,
                            InvoicesList:t.InvoicesList
                        }
                    });
                    this.cancelSearchDate();
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },
        cancelSearchDate() {
            this.dateFrom = new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
            this.dateTo = new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
            this.duedateFrom = new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
            this.duedateTo = new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
        },                      
        formatearDateFrom() {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formateardateTo() {
            this.dateTo = new Date(
                (new Date(this.dateTo).toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
        },
        formatearduedateFrom() {
            this.duedateFrom = new Date(
                (new Date(this.duedateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formatearduedateTo() {
            this.duedateTo = new Date(
                (new Date(this.duedateTo).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },           
    },
    watch: {
        dateFrom: function () {
            this.formatearDateFrom();
        },
        dateTo: function () {
            this.formateardateTo();
        },
        duedateFrom: function () {
            this.formatearduedateFrom();
        },
        duedateTo: function () {
            this.formatearduedateTo();
        },       
    },
    computed: {},
};
</script>

<style lang="css" scoped>
.avisoCompany {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 80px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
