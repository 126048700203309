<template>
	<div class="newSection">
		<button type="button"><i class="fa fa-plus"></i></button>
	</div>
</template>
<script>
export default {
  name: 'addSection',
  data () {
    return {
    	newSection: []
    }
  }
}
</script>
<style lang="css" scoped>
.newSection{
	display: block;
    text-align: center;
    width: 100%;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    border: 1px dashed #000;
    border-radius: 4px;
    max-width: 800px;
    margin: auto;
    padding: 10px;
}
.newSection button {
    border: 1px solid #000;
    background-color: transparent;
    box-shadow: none;
}
</style>
