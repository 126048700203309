<template>
	<div class="tdynamic btnCallList">
		<tfilters></tfilters>
		<div class="row">
			<div v-show="isLoading" class="bgloading" ><Loader></Loader></div>
			<div class="content-table-tdynamic" >
				<table class="table-tdynamic btnCallList">
					<thead>
						<tr>
							<th class="check">
								<input 
									type="checkbox" 
									v-model="selectAll"
									@click="selectAlls()"
								/>
							</th>
							<th class="name">Name</th>
							<th class="phone">Phone</th>
							<th class="link">Link</th>
							<th class="status">Status</th>
							<!-- <th class="clicks">Clicks</th> -->
							<th class="code">Code</th>
							<th class="delete">Actions</th>
						</tr>
					</thead>
					<tbody v-if="dataT.length > 0" class="list">
						<tr 
							v-for="(i, index) in dataT" 
							:key="index" 
							:data-ref='index'
							:data-id='i.id'
							v-bind:class="[i.new ? 'new-add' : '', i.del ? 'del-item' : '' ]"
						> 
							<td class="check">
								<input 
									type="checkbox" 
									v-model="selected"
									:value="i.id"
								/>
							</td>
							<td class="name">
								<input 
									type="text" 
									v-model="i.name" 
									placeholder="Name Btn"
									class="form-control"
									@change="editRow(index, i)"
								/>
							</td>
							<td class="phone">
								<input 
									type="text" 
									v-model="i.tlf" 
									placeholder="Phone" 
									class="form-control"
									@change="editRow(index, i)"
								/>
							</td>
							<td class="link">
								<input 
									type="text" 
									v-model="i.link" 
									placeholder="Tel:5555555" 
									class="form-control"
									@change="editRow(index, i)"
								/>
							</td>
							<td class="status">
								<div class="boton swich" >
                                    <div class="onoffswitch">
                                        <input
                                            type="checkbox"
                                            :id="'cat' + i.id"
                                            :checked="i.status"
                                            class="onoffswitch-checkbox"
                                            @click="changeStatus(i)"
                                        />
                                        <label
                                            class="onoffswitch-label"
                                            :for="'cat' + i.id"
                                        >
                                            <span class="onoffswitch-inner"></span>
                                            <span class="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
								<!-- <span v-if="i.status==1" class="badge badge-pill badge-success">Active</span>
								<span v-else class="badge badge-pill badge-danger">Inactive</span> -->

							</td>
							<!-- <td class="clicks">
								<button 
								type="button" 
								class="btn btn-light" 
								@click="visits(i.id)">
									View
									<span class="badge badge-pill badge-info">{{i.visits}}</span>
								</button>
							</td> -->
							<td class="code">
								<button 
								type="button" 
								class="btn btn-light" 
								@click="code(i)">
									Code
								</button>
							</td>
							<td class="delete">
								<button type="button" class="btn btn-group-sm btn-danger" @click="deleteRow(i)">
									<i class="fas fa-times"></i>
								</button>							
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr><td colspan="7" class="text-center">No record</td></tr>
					</tbody>
				</table>
			</div>
			<div></div>
		</div>
		<!-- modal -->
		<modalLateral>
			<component v-bind:is="m.component" :btn_id="m.data.id"></component>
		</modalLateral>
	</div>
</template>
<script>
import {variables, components, methods, watch, computed} from './tdynamic_js';
export default {
	name: 'tdynamic',
	data(){
		return variables;
	},
	components,
	methods,
	watch,
	computed,
	created(){ 
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.btnCall;
		this.getList();
		// console.log('tdynamicFilters', this.$parent)
	},
	mounted() {	
	},
}
</script>
<style lang="css" scoped>
@import './tdynamic_css/custom.css';
</style>
