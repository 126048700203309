<template>
    <div>
        <div class="card-header">
            <h3>Btn Call Link Code</h3>
        </div>
        <div class="card-body" style="min-height: 200px">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <div class="row" v-if="isCode">
                <div class="col-sm-12">
                    <textarea
                        id="codeHtml"
                        style="height: 200px; width: 100%; display: block"
                        readonly
                        onClick="this.select()"
                        v-model="codeHtml"
                    ></textarea>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary" @click="copyhtml">Copy code</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "btnCallCode",
    components: {
        Loader,
    },
    props: {
      btn_id: {
        type: Number,
        required: true,
        default: 0
      }
    },
    data() {
        return {
            url: window.urlmaster + "api/crmApp/btnCall/" + window.localuserdata +"/" + this.btn_id,
            org_id: window.localuserdata,
            isLoading: false,
            isCode: true,
            codeHtml: "",
        };
    },
    created() {
        this.changeTextarea();
    },
    methods: {
        changeTextarea() {
            var i = "";
            i += '<a id="appBC-'+this.btn_id+'"></a>';
            i += '<script id="appSBC">';
            i += "var d=document,appBC=" + this.org_id;
            i +=',idBC="appBC-'+this.btn_id+'",idS="wgBCS", f=new Date,';
            i += 'urlBC="' + this.url + '",';
            i += "t=d.querySelector('#appSBC'), s=d.createElement('script');";
            i += 's.type="text/javascript",s.id=idS,';
            i += "s.defer=!0,s.src=urlBC+'/'+f.getTime(),t.after(s);";
            i += "</" + "script>";
            this.codeHtml = i;

            // &#60;div id="appForms"&#62;&#60;/div&#62;
            // &#60;script id="appScriptForms"&#62;
            // var d=document,appForms={{
            //     org_id
            // }},IdDiv="appForms",IdForms="widgetForms", idScript="widgetFormsScript",f=new Date,
            // urlForms="{{
            //     url
            // }}", t=d.querySelector("#appScriptForms"),s=d.createElement("script");
            // s.type="text/javascript",s.id=idScript,s.defer=!0, s.src=urlForms+appForms+"/"+f.getTime(),t.after(s);&#60;/script&#62;
        },
        copyhtml() {
            var element = document.querySelector("#codeHtml").select();
            try {
                document.execCommand("copy");
                this.$swal({
                    icon: "success",
                    title: "Code copied",
                    timer: 1000,
                });
            } catch (err) {
                console.log("unable to copy code");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
