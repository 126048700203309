let newTags = function(i, index) {
    this.tagsList = [];
    this.tags = [];
    let tagsJobs = i.tags;
    let tags = this.$store.getters['ModApps/jobs/get_menuTags'];
    console.log("menu tags", tags);
    for (let i in tags) {
        let op = {
            id: tags[i].id,
            name: tags[i].name,
            id_jobs: i.id_i,
            id_tags: tags[i].id,
        };
        this.tagsList.push(op);
        for (let j in tagsJobs) {
            if (tags[i].id == tagsJobs[j].id_tags) {
                this.tags.push(op);
            }
        }
    }
    let e = {
        idx: index,
        idJob: i.id_i,
        active: true,
        option: this.tagsList,
        values: this.tags,
    };
    this.$store.commit("ModApps/jobs/set_editTags", e);
};
export default newTags;
