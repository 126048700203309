import { render, staticRenderFns } from "./chartAds.vue?vue&type=template&id=ceb3f734&scoped=true&"
import script from "./chartAds.vue?vue&type=script&lang=js&"
export * from "./chartAds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceb3f734",
  null
  
)

export default component.exports