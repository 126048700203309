let getDataBilling = function(i, op) {
    this.itemNameCustomers = [];
    this.sub_id = 0;
    this.customer_id = 0;
    this.invoice_id = 0;
    this.sub = [];
    this.customer = [];
    this.invoice = [];
    this.editCusInv = op;
    this.customer_id = i.id_customer;
    this.invoice_id = i.id_invoice;
    this.m = {
        show: true,
        component: 'mCustomer',
        data: i
    };
    this.$store.commit('core/setOpenModal', this.m);
    this.$store.commit("core/setLoadingModal", true);
    let t = this;
    let url = this.apis.getDataBilling()
    window.master
        .post(url, {
            user_id: t.user_id,
            org_id: t.org_id,
            billing: 1,
        })
        .then((response) => {
            if (response.data.status) {
                let result = response.data.result;
                // this.dataBilling = result;
                this.ordenate(response);
                let none = { value: 0, text: "None" };
                let r = [];
                let f = [];
                //subcontractor
                if (result.sub.length > 0) {
                    f = [none];
                    r = result.sub;
                    for (const i in r) {
                        f.push({ value: r[i].id, text: r[i].name });
                    }
                    t.sub = f;
                }
                //customer
                if (result.cus.length > 0) {
                    f = [none];
                    r = result.cus;
                    for (const i in r) {
                        f.push({ value: r[i].id, text: r[i].name });
                    }
                    t.customer = f;
                    if (t.customer_id > 0) {
                        t.getinvoice();
                    }
                }
            }
            t.$store.commit("core/setLoadingModal", false);
        })
        .catch((error) => {
            console.log(error);
            t.$store.commit("core/setLoadingModal", false);
        });
};
export default getDataBilling;
