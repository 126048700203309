<template>
    <div class="cardListApp">
        <template v-for="(i, index) in items">
            <div class="cardApp" v-if="isVisible(i)" v-bind:key="index" v-bind:ref="i">
                <div v-bind:class="[
                        'cardBodyApp',
                        (OrgPlan && typeEnter==1) ? 'disabledApp' : ((i.statusApp==2 && typeEnter==2) ? 'disabledApp' : ''),
                        classPlanStatus(i),
                    ]"
                    :data-text="textPlanStatus(i)"
                >
                    <div class="headerAction" v-if="typeEnter==2">
                        <span class="app_default">
                            <label>
                                <span style="margin-right: 5px;">Default</span>
                                <input type="radio" :value="i.id" :checked="appDefault==i.id" @click="actionEventApp('setAppDefault', i, index)" :disabled="i.statusApp==2"/>
                            </label>
                        </span>
                    </div>
                    <div class="headerApp" @click="clickIcon(i, index)">
                        <img class="iconapp" :alt="i.name" :src="imgIcon(i)"/>
                        <h3 class="nameApp" v-if="i.id==8" style="text-transform: initial;">bSite</h3>
                        <h3 class="nameApp" v-else>{{ i.name }}</h3>
                    </div>
                    <!-- <div class="descriptionApp" v-if="typeEnter==1">{{ i.description }}</div> -->
                    <div class="footerApp">
                        <div class="accessApp" v-if="typeEnter==2">
                            <button class="btn btn-success btnInstallApp" @click="actionEventApp('linkApps', i, index)" data-text="Enter App">Enter App</button>
                        </div>
                        <div class="installApp" v-else>
                            <button v-if="emailVerify" class="btn btn-success btnInstallApp" @click="actionEventApp('install', i, index)" data-text="INSTALL">INSTALL</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        typeEnter: {
            type: String,
            default: '1',
        },
        items: {
            type: Array,
            default: [],
        },
        appDefault: {
            type: Number,
            default: 0,
            require: false,
        },
    },
    data() {
        return {
			OrgPlan : (window.OrgPlan > 1),
        };
    },
    methods: {
        clickIcon(i, index){
            let t = this;
            if(t.typeEnter=='1'){
                t.actionEventApp('install', i, index);
            }
            else{
                t.actionEventApp('linkApps', i, index);
            }
        },
        actionEventApp(ejeAction, i, index){
            let t = this;
            if(t.typeEnter=='1'){
                t.$emit('clickAction', i);
            }else{
                let d = {
                    nameAction: ejeAction,
                    app_id: i.id,
                    index: index,
                }
                console.log(d)
                t.$emit('clickAction', d);
            }
        },
        imgIcon(item){
            let t = this;
            let src=null;
            if(item.id==1){src=require("@/core/assets/img/icon_billing.svg");}
            else if(item.id==2){src=require("@/core/assets/img/icon_sites.svg");}
            else if(item.id==3){src=require("@/core/assets/img/icon_project.svg");}
            else if(item.id==4){src=require("@/core/assets/img/icon_jobs.svg");}
            else if(item.id==5){src=require("@/core/assets/img/icon_calculator.svg");}
            else if(item.id==7){src=require("@/core/assets/img/icon_slabs.svg");}
            else if(item.id==8){src=require("@/core/assets/img/icon_bsite_white.svg");}
            else if(item.id==9){src=require("@/core/assets/img/icon_display.svg");}
            else if(item.id==10){src=require("@/core/assets/img/icon_dimenu.svg");}
            else{src=require("@/core/assets/img/icon_auxiliar.svg");}
            return src;
        },
        isVisible(i){
            let t = this;
            let v= true;
            if(t.typeEnter==1){
                if(i.visible==1){v=false;}
            }
            else{
                if(i.assingStatus==0 || i.statusApp==3){
                    v=false;
                    console.log('isVisible', i.id, i.assingStatus, i.statusApp)
                }
            }
            return v;
        },
        classPlanStatus(i){
            let t = this;
            if(t.typeEnter==2){
                let c = null;
                if(i.id==1 && i.statusPay==1 && i.plan_id==1){ c='ppt standardSO';}
                if(i.id==1 && i.statusPay==1 && i.plan_id==2){ c='ppt premiumSO';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==5){ c='ppt standard';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==6){ c='ppt premium';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==9){ c='ppt team';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==10){ c='ppt pro';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==11){ c='ppt google';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==12){ c='ppt bOrganic';}
                if(i.statusPay==0 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='ppt unpaid';}
                if(i.statusPay==2 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='ppt cancelled';}
                if(i.statusPay==3 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='ppt declined';}
                if(i.id==1 && i.plan_id==3){ c='ppt sponsoredSO';}
                if(i.id==1 && i.plan_id==4){ c='ppt freeSO';}
                if(i.id==8 && i.plan_id==7){ c='ppt sponsored';}
                if(i.id==8 && i.plan_id==8){ c='ppt free';}
                if(i.statusApp==2){ c='ppt suspended';}
                return c;
            }
            return '';
        },
        textPlanStatus(i){
            let t = this;
            if(t.typeEnter==2){
                let c = null;
                if(i.id==1 && i.statusPay==1 && i.plan_id==1){ c='Standard';}
                if(i.id==1 && i.statusPay==1 && i.plan_id==2){ c='Premium';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==5){ c='Standard';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==6){ c='Premium';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==9){ c='Team';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==10){ c='Pro';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==11){ c='Google';}
                if(i.id==8 && i.statusPay==1 && i.plan_id==12){ c='bOrganic';}
                if(i.statusPay==0 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='UnPaid';}
                if(i.statusPay==2 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='Cancelled';}
                if(i.statusPay==3 && (i.plan_id!=3 && i.plan_id!=4 && i.plan_id!=7 && i.plan_id!=8)){ c='CC Declined';}
                if(i.id==1 && i.plan_id==3){ c='Sponsored';}
                if(i.id==1 && i.plan_id==4){ c='Free';}
                if(i.id==8 && i.plan_id==7){ c='Sponsored';}
                if(i.id==8 && i.plan_id==8){ c='Free';}
                if(i.statusApp==2){ c='Suspended';}
                return c;
            }
            return '';
        },
    },
    watch: {
        getPlanId: function () {
            this.OrgPlan = (this.getPlanId > 1);
        },
    },
    computed: {
        ...mapGetters('core/session', { emailVerify: 'getEmailVerify' }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId" }),
    },
}
</script>
<style lang="scss" scoped>
.cardListApp{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    margin: auto;
}
.cardApp {
    padding: 0px 15px;
    min-width: 280px;
    width: 33.333%;
}
.cardBodyApp {
    background: #fff;
    border: 1px solid #eee;
    margin: 10px auto;
    padding:10px;
    border-radius: 6px;
    filter:  drop-shadow(-1.8369701987210297E-16px 3.0px 5px rgba(0,0,0,0.1));
    transition: all 0.5s;
}
.cardBodyApp:hover {
    margin-top: 0px;
}
.cardApp .cardBodyApp:hover img.iconapp {cursor: pointer;}
.cardApp .headerApp .iconapp {
    width: 100px;
    height: auto;
}
.cardApp .cardBodyApp.disabledApp img.iconapp,
.cardApp .cardBodyApp:hover.disabledApp img.iconapp {
    opacity: 0.4 !important;
    background-color: transparent !important;
}
.nameApp {
    font-size: 1.5rem;
    color:#000;
    margin: 10px auto;
    text-align: center;
}
.cardApp .descriptionApp {
    font-size: 0.8rem;
    color: #ADADAD;
    text-align: center;
}
.footerApp {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.footerApp .btnInstallApp {
    display: inline-block;
    position: relative;
    font-size: 1.3em; /* Tamaño del texto */
    border-radius: 40px;
    color:#fff;
    background-color: #019720 !important;
    border: 1px solid #019720!important;
}
.btnInstallApp::before,
.btnInstallApp::after {
    content: attr(data-text); /* Usa el atributo 'data-text' del elemento */
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    overflow: hidden;
}
.cardBodyApp:hover .btnInstallApp {color: #019720;}
.cardBodyApp:hover .btnInstallApp::before {
    top: 3;
    color:#ccc; /* Color de la parte superior (Tomato) */
    transform-origin: bottom;
    animation: hinge-top 2s infinite;
    z-index: 2;
}
.cardBodyApp:hover .btnInstallApp::after {
    bottom: 10;
    color: #fff; /* Color de la parte inferior (SteelBlue) */
    transform-origin: top;
    animation: hinge-bottom 2s infinite;
    z-index: 1;
}
@keyframes hinge-top {
    0%, 100% {
        transform: rotateY(0deg);
        opacity: 0;
    }
    50% {
        transform: rotateY(-20deg);
    }
}
@keyframes hinge-bottom {
    0%, 100% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(20deg);
    }
}

.cardBodyApp.ppt{overflow: hidden;}
.cardBodyApp.ppt:before{
    content: attr(data-text);
    position: absolute;
    top: 28px;
    left: -35px;
    z-index: 3;
    height: 0px;
    width: 150px;
    display: block;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    letter-spacing: 3px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    background: transparent;
    border: 6px solid transparent;
    border-top: 10px solid;
    border-bottom: 20px solid;
    border-color: #031099;
    line-height: 1;
    transform: rotate(-45deg);
    box-shadow: 0px 3px 5px -3px #000;
}
.cardBodyApp.ppt.standardSO:before{border-color: #6c757d;}
.cardBodyApp.ppt.standard:before{border-color: #137eff;}
.cardBodyApp.ppt.premiumSO:before{border-color: #ffbc34;color: #000;}
.cardBodyApp.ppt.premium:before{border-color: #ffbc34;color: #000;}
.cardBodyApp.ppt.sponsoredSO:before{border-color: #137eff;}
.cardBodyApp.ppt.sponsored:before{border-color: #7460ee;}
.cardBodyApp.ppt.freeSO:before{border-color: #7460ee;}
.cardBodyApp.ppt.free:before{border-color: #6c757d;}
.cardBodyApp.ppt.unpaid:before{border-color: #fa5838;}
.cardBodyApp.ppt.cancelled:before{border-color: #c52506;}
.cardBodyApp.ppt.declined:before{border-color: #e7a115; color: #555;}
.cardBodyApp.ppt.suspended:before{border-color: #c52506;}
.cardBodyApp.ppt.team:before{border-color: #ff8720; color: #fff;}
.cardBodyApp.ppt.pro:before{border-color: #5ac146; color: #fff;}
.cardBodyApp.ppt.google:before{border-color: #fea510; color: #555;}
.cardBodyApp.ppt.bOrganic:before{border-color: #34b10b;}
@media (max-width: 768px) {
    .cardApp { width: 100%; max-width: 280px;}
}
</style>
