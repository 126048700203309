let validJobsApp = function(id) {
	let appsorg =this.$store.getters['core/apps/applistorgs'];
	console.log('validJobsApp', appsorg)
	if(appsorg.length > 0)
	{
		for (let j in appsorg) {
			if(appsorg[j].id==4 && appsorg[j].privilegies){
				this.accessJobsApp=true;
			}
		}
	}
};
export default validJobsApp;
