let selectAlls = function() {
	if (this.selectAll === false) {
		this.selectAll = true;
		const b = this.dataT
		const r = []
		for (const i in b) {
			r.push(b[i].id)
		}
		this.selected = r
	} else {
		this.selectAll = false
		this.selected = [];
	}
	// console.log('selectAll', this.selected)
};
export default selectAlls;
