<template>
	<div class="card ">
		<div class="card-header" style="padding: 0.25rem 1.25rem;">
			<h3 style="margin-bottom: 0px;">
				<strong class="text-info" style="font-size: 32px;"> Conversion</strong>
			</h3>
		</div>
		<div class="container-fluid card-body" style=" padding: 0px;">
			<chartAds></chartAds>
			<tdynamic></tdynamic>
		</div>
		
	</div>
</template>
<script>
import tdynamic from '@/apps/Marketea/components/Ads/tableAds';
import chartAds from '@/apps/Marketea/components/Ads/chartAds';
export default {
	name: 'AdsConversion',
	components: {
		tdynamic,
		chartAds,		
	},
	data () {
		return {	
		}
	}
};
</script>
<style lang="css" scoped>
.card.card-body{
	padding-left: 0px;
	padding-right: 0px;
}
</style>
