<template>
	<div class="table-productos">
        <table
            class="table table-striped"
            style="margin-top: 10px"
        >
            <thead
                style="background-color: #cfd8dc"
            >
                <tr>
                    <th scope="col">
                        PRODUCT/SERVICE
                    </th>
                    <th scope="col">
                        DESCRIPTION
                    </th>
                    <th
                        scope="col"
                        style="text-align: right; width: 100px;"
                    >
                        QTY
                    </th>
                    <th
                        scope="col"
                        style="text-align: right"
                    >
                        UNIT COST
                    </th>
                    <th
                        scope="col"
                        style="text-align: center"
                    >
                        TAX
                    </th>
                    <th
                        scope="col"
                        style="text-align: center"
                    >
                        PRICE
                    </th>
                </tr>
            </thead>
            <draggable
                v-model="$parent.formList.items"
                tag="tbody"
                class="tbody"
                v-bind="dragOptions"
                @start="isDragging(true)"
                @end="isDragging(false)"
                handle=".handle"
            >
                <tr
                    v-for="(
                        item, index
                    ) in $parent.formList.items"
                    :key="index"
                    :class="'item' + index"
                >
                    <th
                        scope="row"
                        style="position: relative"
                    >
                        <div
                            class="btnmove handle"
                            v-if="$parent.formList.items[
                                    index
                                ].model.value!=idseparador"
                        >
                            <i
                                class="fas fa-ellipsis-v"
                            ></i>
                        </div>
                        <div
                            v-else
                            class="btnmove handle"
                            style="line-height: 50px;"
                        >
                            <i
                                class="fas fa-ellipsis-v"
                            ></i>
                        </div>
                        <model-select
                            :options="
                                item.options
                            "
                            v-model="
                                $parent.formList.items[
                                    index
                                ].model
                            "
                            placeholder="Choose a Products"
                            class="productos"
                            v-if="$parent.formList.items[
                                    index
                                ].model.value!=idseparador"
                        ></model-select>
                        <!-- <br><br>
                        {{$parent.formList.items[index].model}}<br><br>
                        {{item.options}} -->
                        <div
                            class="btninv"
                            @click="
                                $parent.NewProducts(
                                    item,
                                    index
                                )
                            "
                            v-if="$parent.formList.items[index].model.value!=idseparador && prodnew"
                        >
                            +
                        </div>
                    </th>
                    <td>
                        <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="2"
                            class="form-control"
                            v-model="
                                $parent.formList.items[index].model
                                    .descripcion
                            "
                            v-if="$parent.formList.items[
                                    index
                                ].model.value!=idseparador"
                        ></textarea>
                        <input 
                            v-else
                            type="text" 
                            class="form-control"
                            v-model="
                                $parent.formList.items[
                                    index
                                ].model
                                    .descripcion"
                        >
                    </td>                                        
                    <td style="width: 70px; text-align: right;">
                        <input type="text" name="text" placeholder="0.00" style="text-align: right;"
                        v-model="item.model.qty" 
                        @keypress="isNumber($event, 'qty', index, item.model.qty)"
                        @focus="isNumber($event, 'qty', index, item.model.qty)"
                        @focusout="isNumber($event, 'qty', index, item.model.qty)"
                        v-bind:class="[{error:item.model.qty == null}, 'form-control']"
                        v-if="item.model.value!=idseparador"
                        >                                                                    
                    </td>
                    <td style="width: 120px">
                        <input type="text" name="text" placeholder="0.00" style="text-align: right;"
                        v-model="item.model.unitcost" 
                        @keypress="isNumber($event, 'unitcost', index, item.model.unitcost)"
                        @focus="isNumber($event, 'qty', index, item.model.qty)"
                        @focusout="isNumber($event, 'unitcost', index, item.model.unitcost)"
                        v-bind:class="[{error:item.model.unitcost == null}, 'form-control']"
                        v-if="item.model.value!=idseparador"
                        >   
                    </td>                                                            
                    <td>
                        <select class="form-control"        v-model="item.model.taxOpc"
                            v-if="$parent.formList.items[index].model.value!=idseparador"
                        >
                            <!-- <option :value="0" selected>
                                --None--
                            </option> -->
                            <option v-for="(t, index) in item.taxlistOption" :key="index" :value="t">
                                {{ t.initialstate }} {{ t.name }}%
                            </option>
                        </select>
                        <!-- {{item.model.taxOpc}} -->
                    </td>                                                            
                    <td style="position: relative;text-align: right;">
                        <span v-if="$parent.formList.items[index].model.value!=idseparador">
                            {{ item.model.prices | formatMoney }}
                        </span>
                        <span v-if="$parent.formList.items.length>1" class="deletebtn" @click="removeItem(index)">x</span>
                    </td>
                </tr>
                <tr>
                    <th
                        scope="row"
                        class="text-left"
                    >
                        <button
                            type="button"
                            class="btn btn-success btn-sm"
                            @click="addNewLine"
                        >
                            Add new line
                        </button>
                    </th>
                    <th
                        scope="row"
                        class="text-left"
                    >
                        <button
                            type="button"
                            class="btn btn-success btn-sm"
                            @click="lineSeparator"
                        >
                            Line Separator
                        </button>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </draggable>
        </table>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { ModelSelect } from 'vue-search-select'
export default {
	name: 'tableProductos',
	components: {
        draggable,
        ModelSelect,
    },
    props: {
        prodnew: { type: Boolean, required: false, default: true },
    },
	data () {
		return {
			PlanPremium: false,
            idseparador: 999999999,
		}
	},
	created(){
		this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        console.log('prodnew', this.prodnew)
	},
	methods : {
        isDragging(t){
            console.log('move')
            return t;
        },
        isNumber(evt, type, index, mnto) {
            if(mnto==undefined){mnto='0.00';}
            mnto = this.validAlert(type, index, mnto)
            if (!/\d/.test(evt.key) && evt.key !== '.' && evt.key !== ',' && evt.key !== '-' && evt.key !== undefined) {
                evt.preventDefault();
                // console.log(evt.key);
                this.msjAlert("only numbers are allowed.")
            } else {
                if (evt.key == undefined) {
                    if (mnto == '' || mnto == 'null') {
                        mnto = '0.00'
                    } 
                    let g
                    if (mnto.length > 0 && mnto.indexOf("-") > -1){
                        g = mnto.indexOf('-');
                    } else {
                        g = 0
                    }                   
                    if (g == 0 || g == '-1') {
                        let vatIdRegex = new RegExp(/^[0-9.,-]+$/);      
                        let val = 0.0;              
                        if (vatIdRegex.test(mnto)) {
                            mnto = this.quitarComas(mnto)    
                            let ops = {
                                style: 'currency', 
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            };                                                  
                            let amount = new Intl.NumberFormat('en-US', ops).format(mnto)
                            let p = amount.indexOf('$');                            
                            if (p == 0 || p == 1) {
                                amount = amount.replace("$", "");
                            }
                            if (amount.indexOf(".") > -1) {
                                val = amount;
                            } else {
                                val = amount + ".00";
                            }                            
                        } else {
                            this.msjAlert("only numbers are allowed.")                       
                        }                        
                        this.$parent.formList.items[index].model[type] = val
                    } else {
                        let y = this.quitarNegativosX(mnto, index, type)
                        // let y = mnto.replace("-", "");
                        this.$parent.formList.items[index].model[type] = y                        
                        this.msjAlert("the negative character must go to the beginning.")
                    }
                }
            }
        },    
        quitarComas(special){
            let vatIdComas = new RegExp(/,/);       
            if (vatIdComas.test(special)) {
                special = special.replace(",", "");
                special = this.quitarComas(special)
            }
            return special           
        },
        validAlert(type, index, mnto){
            let iChars = "!@#$%^&*()+=[]\\';/{}|\":<>?";
            for (let i = 0; i < mnto.length; i++) {
                if (iChars.indexOf(mnto.charAt(i)) != -1) {
                    this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
                    return mnto;
                }
            }
            let countPunto = mnto.split('.').length - 1;           
            if (countPunto == 2) {
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
                let y = mnto.replace(".", "");
                this.$parent.formList.items[index].model[type] = y;
                return y
            }  
       
            let l = mnto.indexOf('.');
            if (l == 0) {
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
                let y = mnto.slice(1)
                this.$parent.formList.items[index].model[type] = y;
                return y
            }       
            let c = mnto.indexOf(',');
            if (c == 0) {
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
                let y = mnto.slice(1)
                this.$parent.formList.items[index].model[type] = y                 
                return y
            } 
            if(c > -1){
                let err = false;
                let vr = mnto.split(',')
                for (let i in vr) {
                    if(vr[i] && i>0){
                        if(vr[i].length < 3){
                            err = true;
                            console.log('error de comas', mnto, i, vr[i]);
                        }
                    }
                }
                if(err){
                    this.msjAlert("Incorrect amount, you may be adding disallowed symbology.");
                    this.$parent.formList.items[index].model[type] = 0;
                    return 0;
                }
            }
            return mnto
        },         
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },    
        quitarNegativosX(special){
            if (special == 0) {
                special = '0.00'
            }
            let countNegativeUnitcost = (special.match(/-/g) || []).length;            
            if (countNegativeUnitcost == 2) {
                if (special.slice(-1) == '-') {
                    special = special.slice(0, -1)
                }                    
                special = special.replaceAll("-", "");
                special = '-'+special           
                special = this.quitarNegativosX(special)
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
            }
            return special
        }, 
        prices(index){
            let qty = this.quitarComas(this.$parent.formList.items[index].model.qty)
            let unitcost = this.quitarComas(this.$parent.formList.items[index].model.unitcost)             
            let prices = parseFloat((qty * unitcost).toFixed(2))
            if(isNaN(prices)){
                this.$parent.formList.items[index].model= {
                    value: "",
                    text: "",
                    description: "",
                    unitcost: "1.00",
                    qty: "1.00",
                    taxOpc: {
                        id: 0,
                        name: '--None--',
                        porcentaje: '',
                        initialstate: '',
                        statename: '',
                        count: 0,
                        total: 0,
                    },
                    applytax: 0,
                    prices: 0
                }
            }else{
                this.$parent.formList.items[index].model.prices = prices;
            }
        },
        lineSeparator() {
            // console.log("add item");
            if(this.PlanPremium) 
            {
                let taxOpcID = {
                    id: 0,
                    name: '--None--',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                const ProdList = [];
                ProdList.push({
                        value: this.idseparador,
                        text: '',
                        unitcost: 0,
                        descripcion: '',
                        qty: 0,
                        taxOpc: taxOpcID,
                        prices: 0
                    });
                localStorage.setItem('formList', JSON.stringify(this.$parent.formList.items));
                let formList = localStorage.getItem('formList'); 
                formList = JSON.parse(formList);
                formList.push({
                    options: ProdList,
                    taxlistOption: [],
                    model: {
                        value: this.idseparador,
                        text: "",
                        description: "",
                        unitcost: 0,
                        qty: 0,
                        taxOpc: taxOpcID,
                        applytax: 0,
                        prices: 0
                    },
                });
                this.$parent.formList.items = formList; 
            }else{
                this.llamarPopPremium();
            }
        },
        addNewLine() {
            // console.log("add item");
            const ProdList = [];
            for (let item in this.$parent.ProdServiceLists) {
                var id = this.$parent.ProdServiceLists[item].value;
                var prodname = this.$parent.ProdServiceLists[item].text;
                var quantity = this.$parent.ProdServiceLists[item].qty;
                var unitcost = this.$parent.ProdServiceLists[item].unitcost;
                var descripcion = this.$parent.ProdServiceLists[item].descripcion;
                var applytax = this.$parent.ProdServiceLists[item].applytax;
                let taxOpcID = {
                    id: 0,
                    name: 'None',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                for (let i in this.$parent.TaxesLists) {
                    if (this.$parent.TaxesLists[i].id == this.$parent.ProdServiceLists[item].applytax) {
                        taxOpcID = this.$parent.TaxesLists[i];
                    }
                }
                let price = parseFloat((quantity * unitcost).toFixed(2))
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                    prices: price
                });
            }
            let price = parseFloat((1 * 1).toFixed(2))
            localStorage.setItem('formList', JSON.stringify(this.$parent.formList.items));
            let formList = localStorage.getItem('formList'); 
            formList = JSON.parse(formList);
            formList.push({
                options: ProdList,
                taxlistOption: this.$parent.TaxesLists,
                model: {
                    value: 0,
                    text: "Uncategorized",
                    description: "",
                    unitcost: "1.00",
                    qty: "1.00",
                    taxOpc: {
                        id: 0,
                        name: '--None--',
                        porcentaje: '',
                        initialstate: '',
                        statename: '',
                        count: 0,
                        total: 0,
                    },
                    applytax: 0,
                    prices: 0
                },
            });
            this.$parent.formList.items = formList; 
        },
        removeItem(index) {
            this.$parent.formList.items.splice(index, 1);
        },
        llamarPopPremium() {
            this.$parent.m = {
                show: true,
                component: 'availablePremium',
                data: {
                    title: '',
                    type: 'bill',
                    name: '',
                    app_id: 0,
                    description: ''
                }
            };
            this.$store.commit('core/session/setShowPopPremium', this.$parent.m);
        },
	},
	watch: {
        '$parent.formList.items': {
            handler: function (after, before) {
                let m = after
                //debugger;
                for (let i in m) {
                    let a
                    if(m[i].model.unitcost != undefined){
                        if(m[i].model.unitcost.length>0){
                            a = this.quitarNegativosX(m[i].model.unitcost)  
                            this.$parent.formList.items[i].model.unitcost = a
                        }
                    }
                    let b
                    if(m[i].model.qty != undefined){
                        if(m[i].model.qty.length>0){
                            b = this.quitarNegativosX(m[i].model.qty)  
                            console.log('quitarNegativosX model.qty', b)
                            this.$parent.formList.items[i].model.qty = b   
                        }
                    }

                    this.prices(i)                                      
                }                         
            },
            deep: true
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
	},
	computed: { 
		...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
	}
}
</script>
<style lang="scss" scoped>
.table-productos {
    width: 100%;
}
@media (max-width: 1100px) {
    .table-productos {
        position: relative;
        display: block;
        padding-left: 15px;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-productos table {
        width: 860px;
    }
}

@media (max-width: 800px) {
    .table-productos {
        max-width: 800px;
    }
}
.btninv {
    position: relative;
    left: initial;
    top: 0;
    width: 20px;
    height: 38px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    color: #333;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    font-weight: 400;
    border: 1px solid #dedede;
    float: right;
    display: inline-block;
    margin-left: -6px;
    &:hover {
        background-color: #e0e0e0;
    }
}
.btnmove {
    position: absolute;
    left: -13px;
    top: -3px;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.deletebtn {
    position: absolute;
    right: -24px;
    top: 22px;
    width: 24px;
    height: 20px;
    background-color: #f12e2e;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    line-height: 0px;
    &:hover {
        background-color: #c12727;
    }
}
.ui.fluid.search.selection.dropdown.productos {
    width: 90%;
    float: left;
    display: inline-block;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.error {
    border-color: red !important;
}
</style>
