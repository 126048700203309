<template>
    <BillingRestore>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-5 align-self-center">
                        <h4 class="page-title">Restore Payments Received</h4>
                    </div>
                    <div class="col-7 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link
                                            :to="{ name: 'Setting' }"
                                            class="btn btn-info btn-sm mr-2"
                                            ><i class="fas fa-arrow-left"></i>
                                            Back</router-link
                                        >
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <div class="row">
                        <div class="card card-body estilosform">
                            <Loader v-if="isLoading"></Loader>
                            <div class="row" v-else>
                                <table
                                    class="table table-striped table-bordered table-hover"
                                    id="restorePayments"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">Payment #</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Invoice #</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in paymentsList" :key="item.id">
                                            <td scope="row">
                                                {{
                                                    item.idx.toString().padStart(4, "000")
                                                }}
                                            </td>
                                            <td>{{ item.fecha }}</td>
                                            <td>
                                                {{ item.firstname }} {{ item.lastname }}
                                            </td>
                                            <td>#nro</td>
                                            <td>${{ item.price }}</td>
                                            <td>
                                                <span
                                                    v-if="item.status == 1"
                                                    class="badge badge-success"
                                                    >Accepted</span
                                                >
                                                <span
                                                    v-if="item.status == 0"
                                                    class="badge badge-danger"
                                                    style="background-color: #f70d0d"
                                                    >Void</span
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </BillingRestore>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import BillingRestore from "@/apps/billing/Layouts/BillingRestore";
export default {
    components: {
        BillingRestore,
        Loader,
    },
    data() {
        return {
            isLoading: false,
            paymentsList: [],
        };
    },
    methods: {
        getRestore() {
            this.isLoading = true;
            window.billing
                .get("payments/getrestore/" + window.localuserdata)
                .then((response) => {
                    console.log("payments list");
                    this.paymentsList = response.data.result;
                    let e = this.paymentsList;
                    for (var i = 0; i < e.length; i++) {
                        let date_create = new Date(e[i].fecha).toDateString();
                        let date_create_new = date_create.split(" ");
                        this.paymentsList[i].fecha =
                            date_create_new[0] +
                            ", " +
                            date_create_new[1] +
                            " " +
                            date_create_new[2] +
                            ", " +
                            date_create_new[3];
                    }
                    console.log(this.paymentsList);
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        setRestore(id) {
            this.$swal({
                title: "Restore this payments?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes restore!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .put("payments/setrestore/" + id)
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.result) {
                                this.getRestore();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "payments restore successfully",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error, (this.isLoading = false));
                        });
                }
            });
        },
    },
    created() {
        this.getRestore();
    },
};
</script>

<style lang="css" scoped>
#restorePayments {
    width: 100%;
}
#restorePayments thead tr th {
    font-weight: bold;
    font-size: 16px;
}
#restorePayments thead tr th:nth-child(1) {
    width: 10%;
    text-align: center;
}
#restorePayments thead tr th:nth-child(2) {
    width: 13%;
    text-align: center;
}
#restorePayments thead tr th:nth-child(3) {
    width: 28%;
    text-align: left;
}
#restorePayments thead tr th:nth-child(4) {
    width: 10%;
    text-align: center;
}
#restorePayments thead tr th:nth-child(5) {
    width: 14%;
    text-align: center;
}
#restorePayments thead tr th:nth-child(6) {
    width: 15%;
    text-align: center;
}
#restorePayments thead tr th:nth-child(7) {
    width: 10%;
    text-align: center;
}
#restorePayments tbody tr td {
    text-align: center;
}
#restorePayments tbody tr td:nth-child(3) {
    text-align: left;
}
</style>
