<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <Reviews></Reviews>
            </div>
        </div>
    </div>
</template>
<script>
import Reviews from '@/apps/Marketea/components/google/Reviews'
export default {
    name: "Review",
    components: {
        Reviews
    },
    data () {
        return {
            isLoading: false,
        }
    },
}
</script>
