export default {  
    "label_option_name" : "option name",
    "label_name_preview" : "Name to preview",
    "label_category_name" : "Category Name",
    "label_records" : "Records",
    "label_assigned" : "Assigned",
    "label_amount_extras" : "Amount of extras",
    "label_allocated_amount" : "Allocated amount",
    "label_categorys" : "Categorys",
    "label_additionals" : "Additionals",
    "label_extra_name" : "Extra Name",
    "label_addition_name" : "Addition name",    
    "label_variants" : "Variants",
    "label_extras" : "Extra",    
    "label_crop_img" : "Crop image",
    "label_complete": "Completed",
    "label_expire": "Expired",
    "label_temail": "Delete this E-mail",
    "label_website_menu_link": "Website Menu Link",
    "label_add_row": "Add Row",
    "label_notifications": "Notifications",
    "label_reservation": "Book a Table",
    "label_pending": "Pending",
    "label_reserved": "Reserved",
    "label_canceled": "Canceled",
    "label_detail": "Details",
    "label_edit_reserve": "Edit Reservation",
    "label_del_reserve": "Delete this reservation",    
    "label_r_m_wget": "Widget to Book a Table",
    "label_r_m": "Book a Table",
    "label_del_img": "You want to delete this image",
    "label_max_caracter": "Max: 155 characters",
    "label_maxr_caracter": "Max: 90 characters, 30 recommended",
    "label_fimage": "Enter an image in one of the following formats: .jpeg/ .jpg/ .png",
    "label_tm": "Maximum size is 1200 Widthe and 628 hight", 
    "label_mt" : "Metas Tags",    
    "label_gmc_webd" : "Code to Show Menu of the Day",      
    "label_gmc_web" : "Code to Display Menu",
    "label_sw_info_spcl" : "Show Menu Special",       
    "label_sw_info_res" : "Show Info Restaurant",         
    "label_dl_cat_asg" : "The group could not be eliminated, it may have categories assigned.",
    "label_itis_empty" : "It is Empty",
    "label_name_empty" : "Name Empty",
    "label_success_send" : "Success Send",
    "label_success" : "Success",
    "label_d_g" : "Delete Group",
    "label_g" : "Group",
    "label_ungrouped" : "Ungrouped",
    "label_n_g" : "Name Group",
    "label_nn_g" : "New Menu Group",
    "label_m_g" : "Menu Group",
    "label_date" : "Date",
    "label_time" : "Time",
    "label_foods" : "Foods",
    "label_nc" : "Name/Category",
    "label_default" : "Default",
    "label_nrecord" : "NO RECORD",
    "label_restore" : "Restore",
    "label_yrestore" : "Yes, Restore",
    "label_rcat" : "Restore Category",
    "label_rt_cat" : "Restore this Category",
    "msj_del_ctgory_specific" : "Delete this Category? if you delete that item you will lose the related foods with the.",
    "label_edit" : "Edit",
    "label_add" : "Add",
    "label_save" : "Save",
    "label_saved" : "Saved",
    "label_del" : "Delete",
    "label_deld" : "Deleted", 
    "label_back" : "Back",
    "label_up" : "Update",
    "label_upd" : "Updated",
    "label_new" : "New",
    "msj_info_upd_sful" : "Information updated successful",
    "msj_up_sful" : "Update successful",
    "msj_img_err" : "Image error, max 30MB",
    "msj_img_err_excd" : "Error. size exceeded 30Mb",
    "msj_upting" : "Updating",
    "label_show" : "Show",
    "label_hidden" : "Hidden",
    "label_cancel" : "Cancel",
    "label_title" : "Title",
    "label_keywords" : "Keywords",
    "label_poster" : "Poster",
    "label_action" : "Action",
    "label_numberG" : "Guests",
    "label_name" : "Name",
    "label_fname" : "First Name",
    "label_lname" : "Last Name",
    "label_telephone" : "Telephone",
    "label_notes" : "Notes",
    "label_adress" : "Adress",
    "label_city" : "City",
    "label_state" : "State",
    "label_zip" : "Zip code",
    "label_gmap_c" : "Google map code",
    "label_cover" : "Cover",
    "label_hours" : "Hours",
    "label_closed" : "Closed",    
    "label_close" : "Close",  
    "label_open" : "Open", 
    "msj_info_wget" : "Show information widget?", 
    "label_mn_wget" : "Menu Widget",
    "label_mn_wtget" : "Menu Widget 2",
    "label_cc_mn" : "Copy code Menu",
    "label_cc_spcls" : "Copy code Specials",
    "label_cc" : "Copy code",
    "label_mn_spcl_wget" : "Menu special widget",
    "label_gnrl_setting" : "General setting",
    "label_rtrant_info" : "Restaurant information",
    "msj_ccd" : "Code copied",    
    "label_act_multisize" : "Active Multisize",
    "label_add_ctgory" : "Add Category",
    "msj_del_ctgory" : "Delete this category?",
    "msj_you_sure" : "Are you sure",
    "msj_del_it" : "Yes, delete it!",
    "label_ingedientes" : "ingedientes",
    "label_add_food" : "Add food",
    "label_ctgory" : "Category",
    "label_orders" : "Orders",
    "label_mn" : "Menu",    
    "label_spcl" : "Special",
    "label_spcls" : "Specials",   
    "label_report" : "Reports", 
    "label_marketing" : "Marketing",
    "label_setup" : "Setup",
    "label_dashboar" : "Dashboar",
    "label_lst" : "List",
    "label_list_group" : "List Group",
    "label_my_apps" : "My Apps",
    "label_primary" : "Primary",
    "label_my_orgs" : "My Organizations",
    "label_add_new" : "Add new",
    "label_my_acct" : "My account",    
    "label_users" : "Users",
    "label_user" : "User",
    "label_security" : "Security",
    "label_sign_out" : "Sign out",
    "msj_slted_org_not_app" : "The selected organization does not have this app available, try another one.",
    "label_price" : "Price",
    "label_description" : "Description",
    "msj_del_cover" : "Delete this cover?",
    "label_sign_in" : "Sign In",
    "label_login" : "Login",    
    "label_fgot_pass" : "Forgot your password?",   
    "label_all_rgts_rsrvd_by" : "All rights reserved by", 
    "msj_err_server" : "error server",
    "label_send" : "Send",
    "label_go_login" : "Go to login",
    "msj_loading" : "Loading",
    "msj_inct_usern_pass" : "Incorrect username / password",
    "msj_click_cont" : "Click to continue as", 
    "msj_click_enter" : "Click to enter",   
    "msj_cold_gnrat_tken" : "could not generate a recovery token, your account may be misspelled or not affiliated.",   
    "msj_rcovry_tken" : "a recovery token has been sent to your account.", 
    "msj_sometg_wrong" : "something went wrong, try again later.",
    "label_website" : "Website",
    "msj_valid_email" : "Please enter a valid email format",  
    "label_app" : "App",
    "label_intl" : "Install",  
    "msj_add_org_sful" : "Add organization successful", 
    "label_learn_more" : "Learn more",    
    "label_app_availatable" : "Available Apps",  
    "msj_org_nt_app_yet" : "This organization does not have any applications yet, you can install it in the list below.",
    "msj_no_org_asgnd_acct" : "There are no organizations assigned to this account, contact your system administrator.", 
    "msj_time_intl" : "Its time to INSTALL your prefered App! Just click on install below.",
    "msj_verify_email" : "Please verify your email!.",
    "msj_email_vrifiction" : "Resend email verification",
    "msj_agree_intl" : "AGREE & INSTALL",
    "msj_vrifiction_email" : "Verification email sent.",
    "msj_err_sndng_email" : "error sending email, try again later.",
    "msj_internal_err" : "Error internal,  try again later.", 
    "label_setting_orgs" : "Setting Organizations", 
    "label_add_org" : "Add Organization",
    "label_org" : "Organization",
    "label_name_org" : "Company name",
    "label_primary_acct" : "Set Primary Account",
    "label_name_app" : "Name App",
    "label_change" : "Change",
    "label_org_asgnd_sful" : "Organization assigned Successful",
    "label_org_asgnd" : "Organization assigned to",
    "label_asgn_new_org" : "Assign new Org",
    "label_new_org" : "New Org",
    "label_asgn_priv" : "Assign Privileges",
    "label_asgn_org" : "Assign Organizations",
    "label_edit_priv" : "Edit Privileges",
    "msj_org_apps_intled" : "This organization does not have apps installed",
    "msj_crte_user_sful" : "Create User Successful", 
    "label_priv" : "Privilegies",
    "label_priv_for" : "Privilegies for",
    "label_slte_all" : "Select All",
    "label_money_in" : "Money In",
    "label_money_out" : "Money Out",
    "label_customer" : "Customer",
    "label_setting" : "Setting",
    "label_proyect" : "Proyect",
    "label_Timesheet" : "Timesheet",
    "label_labor_orders" : "Labor orders",
    "label_warranty_list" : "Warranty list",
    "label_bling_inf" : "Billing Information",     
    "label_manage_bling_dtls" : "Manage your billing details, contact information, language, country and timezone settings.",
    "msj_up_prof_sful" : "Update Profile Successful",
    "msj_err_upting_data" : "Error updating data",
    "label_pass" : "Password",
    "label_change_pass" : "Change Password",
    "label_up_pass" : "Update your account password. Always set a strong password, which helps to prevent unauthorized access to your account.",
    "label_current_pass" : "Current Password",
    "label_new_pass" : "New Password",
    "label_reenter_pass" : "Re-enter Password",
    "msj_up_pass_sful" : "Update Password Successful",
    "label_crte_accts" : "Create user accounts",
    "label_crte_accts_managt" : "Create user accounts for the management of your organizations.",
    "msj_avatar" : "Avatar",
    "msj_email" : "E-mail",
    "msj_status" : "Status",
    "msj_active" : "Active",
    "msj_inactive" : "Inactive",
    "msj_users_lst" : "Users List",
    "label_edit_prof" : "Edit profile",
    "label_asgn" : "Assign",
    "label_lst_asgnd_org" : "List of Assigned Organizations",
    "msj_email_exists" : "An account with that email already exists",
    "label_mn_lst" : "Menu List",
    "label_ctgory_lst" : "Category List",
    "label_optiones_lst" : "Optiones List",
    "label_imptd" : "Imported",
    "label_verify_imptd" : "Successful import to verify enter the customers",
    "label_english" : "English",
    "label_spain" : "Spanish",
    "label_IE" : "0",
    "label_additional" : "Add Extras or Additional",
    "label_valcover" : "The height and width must be greater than 400px",
    "label_new_extra" : "New Extra",
    "label_cop_extra" : "Copy Extra",
    "label_add_opcions" : "Add optiones",    
    "label_del_extra" : "You want to delete this Item",
    "label_currency" : "Currency",
    "label_save_currency" : "Save Currency",
    "label_duplicate" : "Duplicate",
    "label_change_group" : "Change group",
    "label_selected" : "Selected",
    "del_image" : "Delete this image?",
};
