import { mapGetters } from 'vuex'
import { ModelSelect } from 'vue-search-select'
import DropdownMenu from '@innologica/vue-dropdown-menu'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import modalEmail from '@/apps/billing/components/modalEmail'
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import formPayment from '@/apps/billing/components/invoices/formPayment'
import formFilterFechas from '@/apps/billing/components/invoices/formFilterFechas'
import formSquarepay from '@/apps/billing/components/invoices/formSquarepay'
import filtros from '@/apps/billing/views/invoice/filtros'
import tablaInvo from '@/apps/billing/views/invoice/tablaInvo'
export default {
	ModelSelect,
	DropdownMenu,
	modalLateral,
	modalEmail,
	NotesAdmin,
	formPayment,
	formFilterFechas,
	formSquarepay,
	filtros,
	tablaInvo,
};
