<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting TERMS</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <component :is="SettingBox"></component>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import Terms from "@/apps/billing/components/setting/Terms.vue";
export default {
    components: {
        Loader,
        Terms,
    },
    data() {
        return {
            isLoadingTerms: false,
            configtermsList: [],
            TermsLists: [],
            term_id: 0,
            NoRecordTerms: false,
            SettingBox: "Terms",
        };
    },
};
</script>
<style lang="scss" scoped>
@media (min-width: 1279px) {
    .ctrlbtn {
        width:100px !important;
    }
    .ctrlbtnCustom {
        width:100px !important;
    }    
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}
</style>
