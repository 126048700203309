<template>
    <div>
        <div class="card">
            <div class="card-header">
                {{ modal.title }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" v-if="modalError">
                    {{ modalErrorMjs }}
                </div>
                <div v-if="modal.writeCheck">
                    <div class="row">
                        <div :class="columna">
                            <div class="form-inline" style="flex-wrap: nowrap">
                                <div class="form-group mb-2">
                                    <select class="form-control" v-model="SelectOne">
                                        <option value="1">This Year</option>
                                        <option value="2">Last Year</option>
                                        <option value="3">This Month</option>
                                        <option value="4">Calendar</option>
                                    </select>
                                </div>
                                <div class="form-group mb-2" v-if="SelectOne == 4">
                                    <div style="display: flex; align-items: center">
                                        <span
                                            style="
                                                padding-right: 4px;
                                                padding-left: 8px;
                                            "
                                            ><b>From:</b></span
                                        >
                                        <span class="inputDateFilter">
                                            <datetime
                                                type="date"
                                                v-model="dateFrom"
                                                format="MMM dd, yyyy"
                                                input-class="form-control"
                                            ></datetime>
                                        </span>
                                        <span
                                            style="
                                                padding-right: 4px;
                                                padding-left: 8px;
                                            "
                                            ><b>To:</b></span
                                        >
                                        <span class="inputDateFilter">
                                            <datetime
                                                type="date"
                                                v-model="dateTo"
                                                format="MMM dd, yyyy"
                                                input-class="form-control"
                                            ></datetime>
                                        </span>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-success mb-2"
                                    @click="filtrosCheck()"
                                    style="margin-left: 10px"
                                >
                                    <i class="fas fa-search"></i> Filter
                                </button>
                            </div>
                        </div>
                        <div :class="columna2" style="padding-bottom: 20px">
                            <button
                                type="button"
                                class="btn btn-success btn-xs"
                                @click="SelectCheckID"
                            >
                                <i class="fas fa-check"></i> SELECT CHECK
                            </button>
                            <button
                                type="button"
                                class="btn btn-secundary btn-xs"
                                style="margin-left: 10px"
                                @click="resetCheckID"
                            >
                                <i class="fas fa-times"></i> CANCEL
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Check Date</th>
                                <th scope="col">Check #</th>
                                <th scope="col">Pay to</th>
                                <th scope="col">Memo</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">Select</th>
                            </tr>
                        </thead>
                        <tbody v-if="CheckList.length > 0">
                            <tr v-for="i in CheckList" :key="i.id">
                                <td>{{ i.check_date }}</td>
                                <td>{{ i.check_number }}</td>
                                <td>{{ i.pay_to }}</td>
                                <td>{{ i.memo }}</td>
                                <td>${{ i.amount | formatMoney }}</td>
                                <td style="text-align: center">
                                    <span
                                        class="badge badge-info"
                                        v-if="i.status == 2"
                                        >Printed</span
                                    >
                                </td>
                                <td style="width: 50px; text-align: center">
                                    <input
                                        type="radio"
                                        :value="i.id"
                                        v-model="check_id"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7" align="center">No record</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "formExpensesWriteCheck",
    data () {
        return {
            columna: "col-sm-6",
            columna2: "col-6 text-right",            
            CheckList:[],
            check_id: 0,
            transac_id: 0,
            SelectOne: 2,
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),           
            modalError: false,
            modalErrorMjs: "",
            modal: {
                title: "",
                vendor: false,
                account: false,
                banking: false,
                bankingTransac: false,
                writeCheck: false,
                isLoading: false,
            },
            formExp: {
                id: null,
                titlePage: "New Expenses",
                action: "create",
                fecha: new Date().toISOString().slice(0, 10),
                type: 1,
                PmtType: 1,
                Vendor: 0,
                Employees: 0,
                Account: 0,
                tipformapago: 1,
                Banking: 0,
                Cash: 0,
                ref: null,
                amount: 0.1,
                description: null,
                img1: null,
                img2: null,
                imgOld1: null,
                imgOld2: null,
                numdoc: null,
                check_id: 0,
                transac_id: 0,
            },
            statusdropd: false,                 
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;   
            this.modal.title = r.modal.title
            this.modal.vendor = r.modal.vendor
            this.modal.account = r.modal.account 
            this.modal.banking = r.modal.banking
            this.modal.bankingTransac = r.modal.bankingTransac
            this.modal.writeCheck = r.modal.writeCheck     
            this.formExp.Banking = r.formExp.Banking 
            this.formExp.amount = r.formExp.amount
            this.formExp.description = r.formExp.description
            this.transac_id = r.transac_id                   
            if (this.modal.writeCheck) {
                this.SelectOne = 1;
                this.filtrosCheck()
            }            
        },
        filtrosCheck() {
            this.statusdropd = false;
            let datos = {
                user_id: window.localuserdata,
                bank_id: this.formExp.Banking,
                filter: this.SelectOne,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };
            this.getCheckList(datos);
        },
        getCheckList(datos) {
            if (this.formExp.Banking === 0) {
                this.modalError = true;
                this.modalErrorMjs = "Error, You must select bank.";
            } else {
                this.modalError = false;
                this.modal.isLoading = true;
                this.modal.writeCheck = false;
                window.billing
                    .post("writecheckbybank", datos)
                    .then((response) => {
                        let checkList = response.data.result;
                        for (let i in checkList) {
                            // solo chech en status printed y no vinculados con transac
                            if (checkList[i].status === 2 && checkList[i].link === 0) {
                                this.CheckList.push(checkList[i]);
                            }
                        }
                        this.modal.isLoading = false;
                        this.modal.writeCheck = true;
                    })
                    .catch((error) => {
                        this.modal.isLoading = false;
                        console.log(error);
                        this.modalError = true;
                        this.modalErrorMjs = "Error";
                    });
            }
        },
        resetCheckID() {
            this.check_id = 0;
            this.formExp.check_id = this.check_id;
            this.formExp.ref = "";
            this.formExp.amount = "";
            this.formExp.description = "";
        },
        SelectCheckID() {
            if (this.check_id === 0) {
                this.modalError = true;
                this.modalErrorMjs = "Error, you must select a check.";
            } else {
                this.formExp.transac_id = 0;
                this.transac_id = 0;
                for (let i in this.CheckList) {
                    if (this.check_id === this.CheckList[i].id) {
                        this.formExp.check_id = this.check_id;
                        this.formExp.ref = this.CheckList[i].check_number;
                        this.formExp.amount = this.CheckList[i].amount;
                        let desc = "";
                        desc +=
                            this.CheckList[i].pay_to !== null &&
                            this.CheckList[i].pay_to !== "" &&
                            this.CheckList[i].pay_to !== "null"
                                ? this.CheckList[i].pay_to + "\n"
                                : "";
                        desc +=
                            this.CheckList[i].memo !== null &&
                            this.CheckList[i].memo !== "" &&
                            this.CheckList[i].memo !== "null"
                                ? this.CheckList[i].memo
                                : "";
                        this.formExp.description = desc;
                    }
                }
            }
        },                            
    },
    watch: {
    },
    computed: {},
};
</script>
