let cancelSearchDate = function() {
	this.dateFrom = new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString();
	this.dateTo = new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString();
	this.duedateFrom = new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString();
	this.duedateTo = new Date(
		(new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
		).toISOString();
};
export default cancelSearchDate;
