<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-2">
                                    <h2 class="page-title">Remanents</h2>
                                </div>
                                <div class="col-md-2">
                                    <div class="basic_dropdown">
                                        <button
                                            class="btn-status text-left"
                                            @click="btnCatg()"
                                            style="width: 100%"
                                        >
                                            Materials Filter
                                            <i
                                                class="fas fa-caret-down"
                                                style="float: right"
                                            ></i>
                                        </button>
                                        <ul
                                            class="reset dropdStatus"
                                            v-if="catdropd"
                                            style="width: 90%"
                                        >
                                            <li>
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="check12"
                                                        @click="checkAll()"
                                                        v-model="isCheckAll"
                                                    />
                                                    <label
                                                        class="custom-control-label"
                                                        for="check12"
                                                        ><b>All</b></label
                                                    >
                                                </div>
                                            </li>
                                            <li
                                                v-for="(i, index) in listMaterials"
                                                :key="index"
                                            >
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        :id="index"
                                                        v-bind:value="i.id"
                                                        v-model="materialFilter"
                                                        @change="updateCheckall()"
                                                    />
                                                    <label
                                                        class="custom-control-label"
                                                        :for="index"
                                                        >{{ i.name }}</label
                                                    >
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search by name"
                                        v-model="inputFilter"
                                        v-on:keyup="searchFilter"
                                    />
                                </div>
                                <div class="col-md-2">
                                    <router-link
                                        :to="{ name: 'SlabsRemanentsNew' }"
                                        class="btn btn-success float-right"
                                    >
                                        <i class="fas fa-plus"></i> Add New
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="contenedor-payments">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <table
                                        class="table table-striped"
                                        style="margin-bottom: 120px"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 60px">
                                                    ID
                                                </th>
                                                <th scope="col" style="width: 60px">
                                                    Cover
                                                </th>
                                                <th scope="col" style="width: 150px">
                                                    Material
                                                </th>
                                                <th scope="col" style="width: 180px">
                                                    Manufacturer
                                                </th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Share</th>
                                                <th scope="col" style="width: 95px">
                                                    Photos
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="
                                                        width: 95px;
                                                        text-align: center;
                                                    "
                                                >
                                                    Status
                                                </th>
                                                <th scope="col" style="width: 95px"></th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="list.length == 0">
                                            <tr>
                                                <td
                                                    colspan="9"
                                                    style="text-align: center; color: red"
                                                >
                                                    No record
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(i, index) in list" :key="i.id">
                                                <td scope="row">#{{ i.id }}</td>

                                                <td>
                                                    <div
                                                        v-if="
                                                            i.cover != null &&
                                                            i.cover != 'null' &&
                                                            i.cover != ''
                                                        "
                                                        style="
                                                            border: 1px solid #ccc;
                                                            padding: 4px;
                                                            width: 50px;
                                                            border-radius: 5px;
                                                        "
                                                    >
                                                        <img
                                                            :src="urlImg + i.cover"
                                                            @click="coverEdit(i)"
                                                            style="
                                                                width: 100%;
                                                                height: 40px;
                                                                object-fit: cover;
                                                                cursor: pointer;
                                                            "
                                                        />
                                                    </div>
                                                    <div
                                                        style="
                                                            border: 1px solid #ccc;
                                                            padding: 4px;
                                                            width: 50px;
                                                            height: 42px;
                                                            border-radius: 5px;
                                                        "
                                                        v-else
                                                    >
                                                        <i
                                                            class="fas fa-4x fa-image"
                                                            style="
                                                                width: 100%;
                                                                height: 40px;
                                                                font-size: 40px;
                                                                line-height: 30px;
                                                            "
                                                        ></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <model-select
                                                        :options="categoriesList"
                                                        v-model="list[index].material_id"
                                                        placeholder="Choose a Material Type"
                                                        @input="
                                                            handleOnInput($event, index)
                                                        "
                                                    >
                                                    </model-select>
                                                </td>
                                                <td>
                                                    <model-select
                                                        :options="ManufacturerList"
                                                        v-model="list[index].manufacturer"
                                                        placeholder="Choose a Manufacturer"
                                                        @input="
                                                            handleOnInput($event, index)
                                                        "
                                                    >
                                                    </model-select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="list[index].name"
                                                        class="form-control editables cbase"
                                                        @change="
                                                            handleOnInput($event, index)
                                                        "
                                                    />
                                                </td>
                                                <td>
                                                    <button class="fb_icon">
                                                        <i class="fab fa-facebook-f"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <div
                                                        style="
                                                            border: 1px solid #ccc;
                                                            padding: 4px;
                                                            background-color: #fff;
                                                            display: inline-block;
                                                            border-radius: 5px;
                                                            cursor: pointer;
                                                        "
                                                        @click="ViewGallery(i.id)"
                                                    >
                                                        {{ i.num_gallery }}
                                                        <i
                                                            class="fas fa-lg fa-images"
                                                        ></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="action_order_btn">
                                                        <span
                                                            @click="activeStatus(index)"
                                                            style="cursor: pointer"
                                                        >
                                                            <span
                                                                v-if="i.status == 1"
                                                                class="badge badge-pill badge-success"
                                                                >Online
                                                                <i
                                                                    class="fas fa-angle-down"
                                                                ></i
                                                            ></span>
                                                            <span
                                                                v-else-if="i.status == 0"
                                                                class="badge badge-pill badge-danger"
                                                                >Offline
                                                                <i
                                                                    class="fas fa-angle-down"
                                                                ></i
                                                            ></span>
                                                        </span>
                                                        <span
                                                            class="status_list"
                                                            v-if="statusActive == index"
                                                        >
                                                            <ul
                                                                class="reset"
                                                                style="padding-left: 0"
                                                            >
                                                                <li
                                                                    @click="
                                                                        statusChange(
                                                                            1,
                                                                            i.id,
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-success"
                                                                        >Online</span
                                                                    >
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        statusChange(
                                                                            0,
                                                                            i.id,
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-danger"
                                                                        >Offline</span
                                                                    >
                                                                </li>
                                                            </ul>
                                                        </span>
                                                    </div>
                                                    <!--  <model-select :options="StatusList"
                                                    v-model="list[index].status"
                                                    placeholder="Choose a Status" @input="handleOnInput($event,index)">
                                                    </model-select> -->
                                                    <!-- <span v-if="i.status==1" class="badge badge-success" >Online</span>
                                                    <span v-else class="badge badge-danger" style="background-color:#b94a48">Offline</span> -->
                                                </td>
                                                <td>
                                                    <span class="cdropdown">
                                                        <button>Actions</button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                                <li
                                                                    @click="
                                                                        editItem(i.id)
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-edit"
                                                                        ></i>
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        elimItem(i.id)
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-times"
                                                                        ></i>
                                                                        Delete
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <modal
                                name="modal-setting"
                                @before-close="CloseComponent"
                                width="800px"
                                height="auto"
                                :resizable="true"
                                :adaptive="true"
                                :scrollable="true"
                            >
                                <div class="card-header" style="position: relative">
                                    <span style="font-size: 18px">{{
                                        dataItem.name
                                    }}</span>
                                    <i
                                        class="fas fa-times"
                                        style="
                                            position: absolute;
                                            top: 9px;
                                            right: 11px;
                                            font-size: 24px;
                                            cursor: pointer;
                                        "
                                        @click="CloseModal"
                                    ></i>
                                </div>
                                <div class="card">
                                    <component
                                        :is="SettingBox"
                                        :item="dataItem"
                                        :url="urlImg"
                                    ></component>
                                </div>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import modalcover from "@/apps/slabs/components/modalCover.vue";
import { ModelSelect } from "vue-search-select";
import { mapGetters } from "vuex";
export default {
    name: "SlabsRemanents",
    components: {
        Loader,
        modalcover,
        ModelSelect,
    },
    data() {
        return {
            isLoading: false,
            list: [],
            listMaterials: [],
            categoriesList: [],
            ManufacturerList: [],
            StatusList: [
                { value: 1, text: "Online" },
                { value: 0, text: "Offline" },
            ],
            catdropd: false,
            isCheckAll: true,
            materialFilter: [],
            inputFilter: "",
            paginador: 1,
            urlApi: null,
            urlImg: null,
            dataItem: [],
            SettingBox: "",
            statusActive: null,
        };
    },
    created() {
        this.getCategories();
        this.getManufacturers();
        this.getcategory();
    },
    methods: {
        getCategories() {
            this.isLoading = true;
            window.SlabsV2.get("getcategory")
                .then((response) => {
                    this.isLoading = false;
                    let m = response.data.result;
                    let data = [];
                    for (let i in m) {
                        data.push({ value: m[i].id, text: m[i].name });
                    }
                    this.categoriesList = data;
                })
                .catch((error) => console.log(error));
        },
        getManufacturers() {
            this.isLoading = true;
            window.SlabsV2.get("manufacturers")
                .then((response) => {
                    this.isLoading = false;

                    let l = response.data.result;
                    let data = [];
                    for (let i in l) {
                        data.push({ value: l[i].id, text: l[i].name });
                    }
                    this.ManufacturerList = data;
                })
                .catch((error) => console.log(error));
        },
        activeStatus(index) {
            if (this.statusActive == index) {
                this.statusActive = null;
            } else {
                this.statusActive = index;
            }
        },
        coverEdit(i) {
            this.dataItem = i;
            this.$modal.show("modal-setting");
            this.SettingBox = "modalcover";
        },
        CloseComponent() {
            this.SettingBox = "";
        },
        CloseModal() {
            this.$modal.hide("modal-setting");
        },
        updateCoverList(res) {
            if (res.open == 1) {
                res.open = 0;
                this.$modal.hide("modal-setting");
                this.SettingBox = "";
                this.getcategory();
                this.$notify({
                    group: "noti",
                    type: "success",
                    title: "Success",
                });
            }
        },
        getcategory() {
            this.isLoading = true;
            window.SlabsV2.get("getcategory")
                .then((response) => {
                    //console.log(response.data)
                    this.listMaterials = response.data.result;
                    if (this.isCheckAll) {
                        for (var key in this.listMaterials) {
                            this.materialFilter.push(this.listMaterials[key].id);
                        }
                    }
                    this.searchFilter();
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        btnCatg() {
            this.catdropd = !this.catdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.materialFilter = [];
            if (this.isCheckAll) {
                for (var key in this.listMaterials) {
                    this.materialFilter.push(this.listMaterials[key].id);
                }
                this.searchFilter();
            }
        },
        ViewGallery(id) {
            this.$router.push({
                name: "SlabsRemanentsGallery",
                params: { material_id: id },
            });
        },
        updateCheckall: function () {
            if (this.materialFilter.length == this.listMaterials.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
            this.searchFilter();
        },
        searchFilter() {
            this.isLoading = true;
            window.SlabsV2.post("getremanents", {
                org_id: window.localuserdata,
                filter: this.inputFilter,
                material: this.materialFilter,
                pag: this.paginador,
            })
                .then((response) => {
                    this.list = response.data.result;
                    this.urlImg = response.data.url;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        editItem(id) {
            this.$router.push({
                name: "SlabsRemanentsEdit",
                params: { id: id, edit: "edit" },
            });
        },
        elimItem(id) {
            this.$swal({
                title: "Delete this Item?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.SlabsV2.put("deletematerial/" + id)
                        .then((response) => {
                            //console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.searchFilter();
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Item Successful",
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        statusChange(status, id, index) {
            this.statusActive = null;
            this.list[index].status = status;
            window.SlabsV2.put("update/item/material/" + id, {
                id: id,
                material_id: this.list[index].material_id,
                manufacturer: this.list[index].manufacturer,
                name: this.list[index].name,
                status: status,
            })
                .then((response) => {
                    //this.getSlabs();
                    if (!response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Error!",
                            type: "error",
                            text: "Updated Item Error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        handleOnInput($event, index) {
            window.SlabsV2.put("update/item/material/" + this.list[index].id, {
                id: this.list[index].id,
                material_id: this.list[index].material_id,
                manufacturer: this.list[index].manufacturer,
                name: this.list[index].name,
                status: this.list[index].status,
            })
                .then((response) => {
                    if (!response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Error!",
                            type: "error",
                            text: "Updated Item Error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
    },
    watch: {
        get_slabsStorage: function () {
            if (this.get_slabsStorage) {
                let g = this.$store.getters["ModApps/slabs/get_slabsStorage"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "coverEdit") {
                    this.updateCoverList(r);
                }
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/slabs", ["get_slabsStorage"]),
    },
};
</script>
<style lang="scss" scoped>
.status_list {
    padding: 8px 7px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    width: 120px;
    font-size: 14px;
    z-index: 9;
    ul {
        li {
            display: block;
            position: relative;
            margin: 1px 0;
            padding: 5px;
            span {
                cursor: pointer;
            }
            &:hover {
            }
        }
    }
    .inprogress,
    .scheduling,
    .onhold,
    .delayed,
    .cancelled,
    .completed {
        width: 10px;
        height: 10px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        color: #fff;
    }
}
.action_order_btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.fb_icon {
    background: #3f51b5;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    i {
        color: #fff;
    }
}
.ui.selection.dropdown {
    border: 0;
    background: transparent;
}
tbody tr:hover {
    background-color: #f1ffff;
}
.form-control.editables {
    border: 0px;
    box-shadow: none;
    background: transparent;
    font-size: 0.8rem;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style-type: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
</style>
