<template>
    <div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card" id="listExpenses">
                <div class="content-default">
                    <div class="card-header justify-content-sm-between" style="display: flex;" v-if="ExpensesList.length > 0">
                        <h5 class="card-title">
                            <router-link
                                :to="{ name: 'ExpensesNew' }"
                                class="btn btn-success"
                                ><i class="fas fa-plus"></i> Add Expenses</router-link
                            >
                        </h5>
                        <div class="input-group" style="max-width: 600px;width: 100%;">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                    v-model="search"
                                />
                                    <div
                                        class="input-group-prepend btn-filter-search"
                                    >
                                    <span
                                        class="btn btn-info"
                                        style="border: 0px"
                                    ><i class="fa fa-search"></i></span>
                                </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <Loader v-if="isLoading"></Loader>
                        <div id="ResposivetableExpenses" v-else>
                            <table
                                class="table table-striped table-bordered table-hover"
                                id="tableExpenses"
                                v-if="ExpensesList.length > 0"
                            >
                                <thead>
                                    <tr>
                                        <th>Exp #</th>
                                        <th>Date</th>
                                        <th>Vendor</th>
                                        <th>Subcontractor</th>
                                        <th>Bank</th>
                                        <th>Payment Type</th>
                                        <th><i class="fas fa-link fa-lg"></i></th>
                                        <th>Amount</th>
                                        <th><i class="fas fa-paperclip fa-lg"></i></th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(i, index) in filterExpensesList"
                                        v-bind:key="index"
                                    >
                                        <td>{{ i.numdoc }}</td>
                                        <td>{{ i.fecha }}</td>
                                        <td>{{ i.vendor }}</td>
                                        <td>{{ i.subcon }}</td>
                                        <td>{{ i.formapago }}</td>
                                        <td>
                                            <span v-if="i.PmtType == 1">Debit</span>
                                            <span v-if="i.PmtType == 2">Check</span>
                                            <span v-if="i.PmtType == 3"
                                                >Cash & Others</span
                                            >
                                        </td>
                                        <td>
                                            <span v-if="i.linkTransac > 0"
                                                ><i class="fas fa-university fa-lg"></i
                                            ></span>
                                            <span v-else-if="i.linkCheck > 0"
                                                ><i
                                                    class="fas fa-money-check-alt fa-lg"
                                                ></i
                                            ></span>
                                            <span v-else-if="i.PmtType == 3"
                                                ><i
                                                    class="fas fa-money-bill-wave fa-lg"
                                                ></i
                                            ></span>
                                        </td>
                                        <td>${{ i.amount | formatMoney }}</td>
                                        <td>
                                            <i
                                                class="fas fa-paperclip fa-lg"
                                                v-if="i.atta == 1"
                                            ></i>
                                        </td>
                                        <td>
                                            <span
                                                class="badge badge-success"
                                                v-if="i.status == 1"
                                                >PAID</span
                                            >
                                            <span
                                                class="badge badge-danger"
                                                v-else-if="i.status == 0"
                                                >VOID</span
                                            >
                                        </td>
                                        <td>
                                            <span class="cdropdown">
                                                <button class="ActionExpenses">
                                                    <span class="textActionExpenses"
                                                        >Actions</span
                                                    >
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li @click="edit(i.id)">
                                                            <button>
                                                                <i
                                                                    class="fas fa-edit"
                                                                ></i>
                                                                Edit
                                                            </button>
                                                        </li>
                                                        <li
                                                            @click="voidE(i.id)"
                                                            v-if="i.status == 1"
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fas fa-trash-alt"
                                                                ></i>
                                                                Void
                                                            </button>
                                                        </li>
                                                        <li @click="delE(i.id)">
                                                            <button>
                                                                <i
                                                                    class="fas fa-times"
                                                                ></i>
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center d-block w-100 p20" v-else>
                                <div
                                    class="text-center"
                                    style="padding: 30px; background: #fff"
                                >
                                    <span style="padding-bottom: 30px"
                                        >Its time to create your first Expenses! </span
                                    ><br />
                                    <router-link
                                        :to="{ name: 'ExpensesNew' }"
                                        class="btn btn-success"
                                        style="margin-top: 7px"
                                    >
                                        Create New Expenses
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    name: "Expenses",
    data() {
        return {
            isLoading: false,
            ExpensesList: [],
            search: '',
        };
    },
    created() {
        this.getListExpenses();
    },
    methods: {
        getListExpenses() {
            this.isLoading = true;
            window.billing
                .get("expenses/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    this.ExpensesList = response.data.result;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        edit(ide) {
            this.$router.push({ name: "ExpensesEdit", params: { ide: ide } });
        },
        voidE(id) {
            this.isLoading = true;
            window.billing
                .post("expense/void", { id: id })
                .then((response) => {
                    this.isLoading = false;
                    this.getListExpenses();
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        delE(id) {
            this.$swal({
                title: "Delete this Expense?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .post("expense/del", { id: id })
                        .then((response) => {
                            this.$notify({
                                group: "noti",
                                title: "Deleted!",
                                type: "success",
                                text: "Deleted Estimate Successful",
                            });

                            this.isLoading = false;
                            this.getListExpenses();
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });
                }
            });
        },
    },
    computed: {
        filterExpensesList: function () {
            var t = this;
            if(t.search.length == 0) {
                return t.ExpensesList;    
            }
            else{
                let opStatus = ['paid', 'void'];
                let valStatus = [1, 0];
                let opPmtType = ['debit', 'check', 'cash & others'];
                let valPmtType = [1, 2, 3];
                return t.ExpensesList.filter(function (i) {
                    if (i.fecha && i.fecha.toLowerCase().indexOf(t.search.toLowerCase()) >= 0) {
                        return i;
                    }
                    else if (i.vendor && i.vendor.toLowerCase().indexOf(t.search.toLowerCase()) >= 0) {
                        return i;
                    }
                    else if (i.subcon && i.subcon.toLowerCase().indexOf(t.search.toLowerCase()) >= 0) {
                        return i;
                    }
                    else if (i.formapago && i.formapago.toLowerCase().indexOf(t.search.toLowerCase()) >= 0) {
                        return i;
                    }
                    else if (i.numdoc && i.numdoc.indexOf(t.search) >= 0) {
                        return i;
                    }
                    else if (i.amount && i.amount.indexOf(t.search) >= 0) {
                        return i;
                    }
                    else if (i.PmtType == valPmtType[opPmtType.indexOf(t.search.toLowerCase())]) {
                        return i;
                    }
                    else if (i.status == valStatus[opStatus.indexOf(t.search.toLowerCase())]) {
                        return i;
                    }
                });
            }
        },
    }
};
</script>
<style lang="scss" scoped>
#listExpenses {
    margin-bottom: 50px;
}
#tableExpenses thead tr th:nth-child(1),
#tableExpenses tbody tr td:nth-child(1) {
    min-width: 50px;
}
#tableExpenses thead tr th:nth-child(2),
#tableExpenses tbody tr td:nth-child(2) {
    min-width: 100px;
}
#tableExpenses thead tr th:nth-child(3),
#tableExpenses tbody tr td:nth-child(3),
#tableExpenses thead tr th:nth-child(4),
#tableExpenses tbody tr td:nth-child(4) {
    min-width: 180px;
}
#tableExpenses thead tr th:nth-child(5),
#tableExpenses tbody tr td:nth-child(5) {
    min-width: 140px;
}
#tableExpenses thead tr th:nth-child(6),
#tableExpenses tbody tr td:nth-child(6) {
    min-width: 100px;
    text-align: center;
}
#tableExpenses tbody tr td:nth-child(8) {
    text-align: right;
}
#tableExpenses thead tr th:nth-child(7),
#tableExpenses tbody tr td:nth-child(7),
#tableExpenses thead tr th:nth-child(9),
#tableExpenses tbody tr td:nth-child(9),
#tableExpenses thead tr th:nth-child(10),
#tableExpenses tbody tr td:nth-child(10) {
    text-align: center;
}
#ResposivetableExpenses {
    min-height: 250px;
}
@media (max-width: 1024px) {
    #ResposivetableExpenses {
        max-width: 1004px;
        overflow-x: scroll;
    }
    .textActionExpenses {
        display: none;
    }
    .ActionExpenses {
        padding: 10px 12px 10px 10px;
    }
}
</style>
