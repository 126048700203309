let subirIMG = function(event) {
    this.isLoadingImgNotes = true;
    let t = this;
    let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();
    let cont = [];
    for (let i in event.target.files) {
        if (event.target.files.hasOwnProperty(i)) {
            cont++;
            formData.append("image" + cont, event.target.files[i]);
        }
    }
    formData.append("nombreIMG", "image");
    formData.append("cont", cont);
    formData.append("jobs_id", this.jobsNoteID);
    formData.append("org_id", window.localuserdata);
    formData.append("user_id", window.master_client);
    let url = this.apis.uploadIMG()
    window.master
        .post(url, formData, fileSendHeader)
        .then((response) => {
            // console.log('upload', response.data);
            if (response.data.status) {
                for (let i in t.listTaks) {
                    if (t.listTaks.hasOwnProperty(i)) {
                        if (t.listTaks[i].id_i == this.jobsNoteID) {
                            t.listTaks[i].gallery = 1;
                            for (let prop in response.data.insert) {
                                if (response.data.insert.hasOwnProperty(prop)) {
                                    t.listTaks[i].galleryList.push(
                                        response.data.insert[prop]
                                    );
                                }
                            }
                            t.listTaks[i].url = response.data.url;
                            console.log("galleryList", t.listTaks[i].galleryList);
                            t.JobsIFGallery = t.listTaks[i].gallery;
                            t.JobsGalleryList = t.listTaks[i].galleryList;
                            t.JobsGalleryURL = t.listTaks[i].url;
                        }
                    }
                }
                t.reEscribirLista();
            }
            t.isLoadingImgNotes = false;
        })
        .catch((error) => {
            console.log(error);
            t.isLoadingImgNotes = false;
        });
};
export default subirIMG;
